import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";

import { H2, Button } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import * as PropTypes from "prop-types";
import classes from "./Join.module.scss";
import routes from "../../routes/index";

class Join extends PureComponent {
  company = () => {
    const { history } = this.props;
    history.push(routes.HbLayoutRoutes.Login.path);
  };

  freelancer = () => {
    const { history } = this.props;
    history.push(routes.HbfLayoutRoutes.MissionsPage.path);
  };

  render() {
    return (
      <div className={classes.container}>
        <H2 className={classes.heading}>
          <Trans>How can we help?</Trans>
        </H2>
        <br />
        <div className={classes.buttons}>
          <Button variant="primary" icon="chevron-right" onClick={this.company}>
            <Trans>I'm looking for Talent</Trans>
          </Button>
          <Button
            variant="secondary"
            icon="chevron-right"
            onClick={this.freelancer}
          >
            <Trans>I'm looking for Jobs</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

Join.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(Join);
