import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react/index";
import { Tab, Tabs, CvCard, OpinionCard } from "cf-neo-ui";
import { t } from "@lingui/macro";
import DownloadIcon from "./icons/DownloadIcon";
import CloseIcon from "./icons/CloseIcon";
import classes from "./styles.module.scss";
import History from "../dashboard-offers/single-offer/history";

const avatarClick = () => {};

@inject("jobSubmissionStore", "modalStore", "talentStore")
@observer
class SingleCandidate extends Component {
  getAvailability = date => {
    const { i18n } = this.props;
    const today = new Date();
    const dateAvailable = new Date(date);
    const diffYears = this.yearsDiff(today, dateAvailable);
    if (diffYears >= 1) return i18n._(t`disponible dans ${diffYears} ans`);
    if (diffYears === 0) {
      const diffMonths = this.monthsDiff(today, dateAvailable);
      if (diffMonths >= 1) return i18n._(t`disponible dans ${diffMonths} mois`);
      if (diffMonths === 0) {
        const diffDays = this.daysDiff(today, dateAvailable);
        if (diffDays >= 2) return i18n._(t`disponible dans ${diffDays} jours`);
        if (diffDays === 1) return i18n._(t`disponible dans un jour`);
        return i18n._(t`disponible immédiatement`);
      }
    }

    return i18n._(t`Disponibilité à confirmer`);
  };

  yearsDiff = (d1, d2) => {
    return d2.getFullYear() - d1.getFullYear();
  };

  monthsDiff = (d1, d2) => {
    return d2.getMonth() - d1.getMonth();
  };

  daysDiff = (d1, d2) => {
    const secondsDiff = Math.floor((d2 - d1) / 1000);
    const minutesDiff = Math.floor(secondsDiff / 60);
    const hoursDiff = Math.floor(minutesDiff / 60);
    return Math.floor(hoursDiff / 24);
  };

  getFileType = file => {
    const type = file.substr(file.length - 4);
    return type.toLowerCase();
  };

  displayedName = (firstName, lastName) => {
    const { i18n } = this.props;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    if (firstName) {
      return firstName;
    }
    if (lastName) {
      return lastName;
    }
    return i18n._(t`Profil anonymisé`);
  };

  getCandidateStatus = candidateStatus => {
    const { i18n } = this.props;
    const lang = localStorage.getItem("language");
    if (!lang || lang !== "en") return i18n._(t`${candidateStatus}`);

    switch (candidateStatus) {
      case "Disponible":
        return "Available";
      case "Ouvert aux opportunités":
        return "Open to New Opportunity";
      default:
        return "Availability to be confirmed";
    }
  };

  render() {
    const { i18n, modalStore, jobSubmissionStore, talentStore } = this.props;
    const { jobSubmission, reset } = jobSubmissionStore;
    const { talent, resetTalent } = talentStore;
    const { changeIsVisible2 } = modalStore;
    const activeTab = "1";
    const h = window.innerHeight;

    return (
      <div
        className={classes.content}
        style={{ height: h, overflow: "scroll" }}
      >
        <div
          style={{
            width: "20px",
            float: "right",
            marginBottom: "10px",
            cursor: "pointer"
          }}
          onClick={() => {
            reset();
            resetTalent();
            changeIsVisible2(false);
          }}
        >
          <CloseIcon />
        </div>
        {jobSubmission && (
          <CvCard
            candidate={{
              id: jobSubmission.candidateID,
              avatar: "/defaultAvatar.webp",
              name: this.displayedName(
                jobSubmission.candidatefirstName,
                jobSubmission.candidatelastName
              ),
              position: jobSubmission.candidateOccupation,
              location:
                jobSubmission.candidateAddress.city ||
                jobSubmission.candidateAddress.address1 ||
                "N/A",
              disponibility: this.getCandidateStatus(
                jobSubmission.candidateStatus
              ),
              experience: `${i18n._(t`indéterminé`)}`,
              payRate: `${jobSubmission.payRate} ${i18n._(t`euros / jour`)}`
            }}
            buttonLabel=""
          />
        )}
        {talent && (
          <CvCard
            candidate={{
              id: talent.id,
              avatar: "/defaultAvatar.webp",
              name: this.displayedName(talent.firstName, talent.lastName),
              position: talent.occupation,
              location: talent.address.city || talent.address.address1 || "N/A",
              disponibility: this.getAvailability(talent.dateAvailable),
              experience: `${i18n._(t`indéterminé`)}`,
              payRate: `${talent.payRate} ${i18n._(t`euros / jour`)}`
            }}
            buttonLabel=""
          />
        )}

        {jobSubmission && (
          <Tabs
            defaultActiveTab={activeTab}
            onChange={this.changeTabHandler}
            type="transparentV2"
          >
            <Tab name="1" tab={i18n._(t`CV`)}>
              {jobSubmission && jobSubmission.candidateCv && (
                <div>
                  {this.getFileType(jobSubmission.candidateCv) === ".pdf" ? (
                    <iframe
                      src={jobSubmission.candidateCv}
                      width="100%"
                      height="800px"
                    >
                      <p>Sorry, PDF cannot be displayed.</p>
                    </iframe>
                  ) : (
                    <div>
                      <div
                        style={{
                          float: "right",
                          marginRight: "30px",
                          marginBottom: "15px"
                        }}
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          href={jobSubmission.candidateCv}
                          download="cv"
                        >
                          <DownloadIcon />
                        </a>
                      </div>

                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${jobSubmission.candidateCv}`}
                        width="100%"
                        height="800px"
                        frameBorder="0"
                      />
                    </div>
                  )}
                </div>
              )}
            </Tab>
            <Tab name="2" tab={i18n._(t`Brief profil`)}>
              <div style={{ position: "relative", width: "100%" }}>
                <br />
                {jobSubmission && jobSubmission.comment && (
                  <OpinionCard
                    avatar="/defaultAvatar.webp"
                    title={
                      (jobSubmission && jobSubmission.commentingSourcer) ||
                      i18n._(t`CF Sourceur`)
                    }
                    subTitle={i18n._(t`Talent Community Specialist`)}
                    description={jobSubmission.comment}
                    avatarClick={avatarClick}
                  />
                )}
                <br />
              </div>
            </Tab>
            <Tab name="3" tab={i18n._(t`Références`)}>
              <div style={{ position: "relative", width: "100%" }}>
                <br />
                {jobSubmission &&
                  jobSubmission.reference &&
                  jobSubmission.reference.map(ref => {
                    if (ref)
                      return (
                        <div key={ref.id}>
                          <OpinionCard
                            title={`${ref.referenceFirstName} ${ref.referenceLastName}`}
                            subTitle={ref.referenceTitle}
                            description={ref.outcome}
                          />
                          <br />
                        </div>
                      );
                    return null;
                  })}
              </div>
            </Tab>
            <Tab name="4" tab={i18n._(t`Historique`)}>
              <History idOfCandidate={jobSubmission.candidateID} />
            </Tab>
          </Tabs>
        )}
        {talent && (
          <Tabs
            defaultActiveTab={activeTab}
            onChange={this.changeTabHandler}
            type="transparentV2"
          >
            <Tab name="1" tab={i18n._(t`CV`)}>
              {talent && talent.candidateCv && (
                <div>
                  {this.getFileType(talent.candidateCv) === ".pdf" ? (
                    <iframe
                      src={talent.candidateCv}
                      width="100%"
                      height="800px"
                    >
                      <p>Sorry, PDF cannot be displayed.</p>
                    </iframe>
                  ) : (
                    <div>
                      <div
                        style={{
                          float: "right",
                          marginRight: "30px",
                          marginBottom: "15px"
                        }}
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          href={talent.candidateCv}
                          download="cv"
                        >
                          <DownloadIcon />
                        </a>
                      </div>

                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${talent.candidateCv}`}
                        width="100%"
                        height="800px"
                        frameBorder="0"
                      />
                    </div>
                  )}
                </div>
              )}
            </Tab>
            <Tab name="2" tab={i18n._(t`Brief profil`)}>
              <div
                className={classes.lineBreaks}
                style={{ position: "relative", width: "100%" }}
              >
                <br />
                {talent && talent.comments && (
                  <OpinionCard
                    avatar="/defaultAvatar.png"
                    title={
                      talent.comments.commenter || "Talent Community Specialist"
                    }
                    subTitle="Talent Community Specialist"
                    description={talent.comments.comment}
                    avatarClick={avatarClick}
                  />
                )}
                <br />
              </div>
            </Tab>
            <Tab name="3" tab={i18n._(t`Références`)}>
              <div style={{ position: "relative", width: "100%" }}>
                <br />
                {talent &&
                  talent.reference &&
                  talent.reference.map(ref => {
                    if (ref)
                      return (
                        <div key={ref.id}>
                          <OpinionCard
                            avatar="/defaultAvatar.png"
                            title={`${ref.referenceFirstName} ${ref.referenceLastName}`}
                            subTitle={ref.referenceTitle}
                            description={ref.outcome}
                          />
                          <br />
                        </div>
                      );
                    return null;
                  })}
              </div>
            </Tab>
          </Tabs>
        )}
      </div>
    );
  }
}

SingleCandidate.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible2: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    resetTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  jobSubmissionStore: PropTypes.shape({
    changeJobSubmission: PropTypes.func,
    reset: PropTypes.func,
    jobSubmission: PropTypes.shape
  }).isRequired
};

export default withI18n()(withRouter(SingleCandidate));
