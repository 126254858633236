// import oAuthDone from "../bLayout/oAuthDone";
import UnderConstructionPage from "../../../components/error-pages/UnderConstructionPage";
import EmailValidation from "../bLayout/emailValidation";

/** !!! For any modification in this file, please re-do it in src/utils/urls.js */

const BLayoutRoutes = {
  // oAuthDone: { path: "/oauth-done", component: oAuthDone },
  validation: { path: "/validation", component: EmailValidation },
  consultantTestimonial: {
    path: "/consultant/testimonials",
    component: UnderConstructionPage
  },
  consultantFaq: { path: "/consultant/faq", component: UnderConstructionPage }
  // client: { path: "/client", component: UnderConstructionPage },
  // about: { path: "/about", component: UnderConstructionPage }
};

export default BLayoutRoutes;
