import gql from "graphql-tag";

const ACCSSIBLE_CLIENT_ACTIONS_STATS_QUERY = gql`
  query AccessibleActionsStats($id: Int!, $dateStart: Float, $dateEnd: Float) {
    accessibleActionsStats(
      id: $id
      filter: { dateStart: $dateStart, dateEnd: $dateEnd }
    ) {
      vacanciesCount
      shortListedCount
      cvSentCount
      interviewsCount
      placementsCount
    }
  }
`;

export default ACCSSIBLE_CLIENT_ACTIONS_STATS_QUERY;
