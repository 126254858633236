import React, { Component } from "react";
import * as PropTypes from "prop-types";

class BLayout extends Component {
  render() {
    const { component } = this.props;
    const RenderComponent = component;
    return <RenderComponent />;
  }
}

BLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
};

export default BLayout;
