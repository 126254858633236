import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Row, Col, TagList, Icon } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./alertModal.module.scss";
import theme from "../../configs/theme";

class AlertModal extends Component {
  render() {
    const {
      onCancel,
      onSubmit,
      data,
      primaryButtonLabel,
      secondaryButtonLabel
    } = this.props;
    return (
      <div className={classes.alertModal}>
        <div className={classes.header}>
          <div className={classes.headerIcon}>
            <Icon
              size="tiny"
              type="bell"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
          </div>
          <Trans>Alerte Mission</Trans>
        </div>
        <div className={classes.body}>
          <Row>
            {data.length > 1
              ? data.map(col => (
                  <Col sm={6} xs={6} key={new Date().valueOf() * Math.random()}>
                    <div className={classes.subtitle}>{col.title}</div>
                    <div className={classes.tagList}>
                      <TagList
                        tags={col.tags}
                        variant="secondary"
                        onClose={this.closeTagHandler}
                      />
                    </div>
                  </Col>
                ))
              : data.map(col => (
                  <Col key={new Date().valueOf() * Math.random()}>
                    <div className={classes.subtitle}>{col.title}</div>
                    <div className={classes.tagList}>
                      <TagList
                        tags={col.tags}
                        variant="secondary"
                        onClose={this.closeTagHandler}
                      />
                    </div>
                  </Col>
                ))}
          </Row>
        </div>
        <div className={classes.footer}>
          <Button size="small" variant="secondary" onClick={onCancel}>
            {secondaryButtonLabel}
          </Button>
          <Button
            size="small"
            style={{ marginLeft: "10px" }}
            onClick={onSubmit}
          >
            {primaryButtonLabel}
          </Button>
        </div>
      </div>
    );
  }
}

AlertModal.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  primaryButtonLabel: PropTypes.string.isRequired,
  secondaryButtonLabel: PropTypes.string.isRequired
};

AlertModal.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {}
};

export default AlertModal;
