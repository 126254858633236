import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import { Mutation, Query } from "react-apollo";
import "react-animated-slider/build/horizontal.css";
import { USER_PROFILE_STRENGTH } from "../../../layout/lpbLayout/queries";
import "../styles.scss";
import ResumeGenerationDisallowed from "./resumeGeneration/ResumeGenerationDisallowed";
import ResumeGenerationAllowed from "./resumeGeneration/ResumeGenerationAllowed";
import staticMetaTags from "../../../../configs/staticPagesMetaTags";
import metaTags from "../../../../utils/editMetaData";
import urls from "../../../../utils/urls";
import classes from "./myProfileResumeDownloader.module.scss";

@inject("sessionStore", "appStore")
@observer
class MyProfileResumeDownloader extends Component {
  render() {
    const { i18n, sessionStore } = this.props;
    const BHId = sessionStore.candidate.id;
    const meta = staticMetaTags(i18n).resume;
    return (
      <div className="profile profile-profile">
        {metaTags(
          urls.myResumeDownloader(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <Query query={USER_PROFILE_STRENGTH} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className={classes.center_loading_logo}>
                  <div className="lds-ripple">
                    <div />
                    <div />
                  </div>
                </div>
              );

            if (error) return <div>Error</div>;

            const { candidate } = data;

            if (candidate.profileStrength.resumeGeneration && BHId) {
              return <ResumeGenerationAllowed />;
            } else return <ResumeGenerationDisallowed />;
          }}
        </Query>
      </div>
    );
  }
}

MyProfileResumeDownloader.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeCv: PropTypes.func,
    isAuthenticatedByLinkedin: PropTypes.bool,
    _id: PropTypes.string,
    id: PropTypes.number,
    email: PropTypes.string,
    pictureUrl: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address: PropTypes.string,
    searchAddress: PropTypes.string,
    candidateWorkHistory: PropTypes.shape,
    cv: PropTypes.shape({
      name: PropTypes.string
    }),
    authToken: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    refreshLayout: PropTypes.func,
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(MyProfileResumeDownloader);
