/* eslint-disable */ module.exports = {
  languageData: {
    plurals: function(n, ord) {
      var s = String(n).split("."),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n10 = t0 && s[0].slice(-1),
        n100 = t0 && s[0].slice(-2);
      if (ord)
        return n10 == 1 && n100 != 11
          ? "one"
          : n10 == 2 && n100 != 12
          ? "two"
          : n10 == 3 && n100 != 13
          ? "few"
          : "other";
      return n == 1 && v0 ? "one" : "other";
    }
  },
  messages: {
    '"Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin"':
      '"Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin"',
    "(where you are a candidate) clients for the purpose of introducing candidates to them and providing information during the recruitment process;":
      "(where you are a candidate) clients for the purpose of introducing candidates to them and providing information during the recruitment process",
    "(where you are a client) candidates for the purpose of arranging interviews and engagements;":
      "(where you are a client) candidates for the purpose of arranging interviews and engagements;",
    ", mis \xE0 jour le": ", updated on",
    "...ou deposer votre fiche de poste ici":
      "...or include your job description here",
    "...ou simplement deposer votre CV ici": "... or simply drop your CV here",
    "1 min": "1 min",
    "2 \xE8me signataire": "second signatory",
    "30 jours": "30 days",
    "<0>A partir {0}</0>": function(a) {
      return ["<0>From ", a("0"), "</0>"];
    },
    "<0>Back to website</0>": "<0>Back to Mindquest</0>",
    "<0>\xC0 partir de {0}</0>": function(a) {
      return ["<0>From ", a("0"), "</0>"];
    },
    "A copy of your driving license and/or passport/identity card; Photograph;":
      "A copy of your driving license and/or passport/identity card; Photograph;",
    "A fin de faciliter les recherches pensez \xE0 indiquer dans votre fiche de poste:":
      "In order to facilitate research, please indicate in your job description:",
    "A l\u2019\xE9tude": "Pending confirmation",
    "A password policy is currently in place to ensure that the data stored on our systems is only accessible to authorised personnel. The passwords are changed on a regular basis. We regularly make backups of our data to ensure that it is readily available in the event of technical problems or disaster recovery.":
      "A password policy is currently in place to ensure that the data stored on our systems is only accessible to authorised personnel. The passwords are changed on a regular basis. We regularly make backups of our data to ensure that it is readily available in the event of technical problems or disaster recovery.",
    "Acceder \xE0 votre espace entreprise": "Access your employer dashboard",
    Accepter: "Accept",
    "Account Managers locaux": "Local account manager",
    Accueil: "Home",
    "Acc\xE9der \xE0 la ressource": "Access the document",
    "Acc\xE9der \xE0 mon espace client": "Access client space",
    "Acc\xE9der \xE0 mon espace personel": "Access your personal space",
    "Activit\xE9 sur toutes les offres": "Activity for all the offers",
    "Activit\xE9 sur vos offres": "Activity on your offers",
    "Actual and potential candidates:": "Actual and potential candidates:",
    Actualité: "News",
    "Actualit\xE9s, conseils pratiques, d\xE9cryptages, et points de vue d\u2019experts pour freelances informatiques et d\xE9cideurs IT en qu\xEAte de r\xE9ussite.":
      "News, practical advice, insights, and expert views for IT freelancers and IT decision-makers looking for success.",
    Adresse: "Location",
    "Adresse email non valide": "Invalid email address",
    "Afin d'organiser un entretien avec le candidat, pourriez-vous nous donner des cr\xE9neaux de disponibilit\xE9?":
      "In order to organize an interview with the candidate, could you please provide your availability?",
    "Afin de mieux adapter nos recherches merci de nous indiquer les raisons de ce refus en commentaire?":
      "In order to better target our search, please tell us the reasons for this refusal in the comments section",
    Ajouter: "Add",
    "Ajouter Club Freelance \xE0 l\u2019\xE9cran d\u2019accueil":
      "Add Mindquest to your home screen",
    "Ajouter le code postal permettra de mettre en avant les profils les plus proches":
      "The ZIP Code allows us to select the consultants the closest to you",
    "Ajouter les environnements": "Add the technical",
    "Ajouter mon CV": "Add my resume",
    "Ajouter quand m\xEAme": "Add anyway",
    "Ajouter un commentaire pour indiquer votre activit\xE9 au client":
      "Add a comment to indicate your activity to the client",
    "Ajouter une Certification": "Add Certification",
    "Ajouter une Formation": "Add Training",
    "Ajouter une exp\xE9rience": "Add experience",
    "Ajouter une ligne": "Add a row",
    "Ajouter vos Comp\xE9tences": "Add your skills",
    "Ajouter vos soft skills": "Add your soft skills",
    "Ajouter votre/vos Zone(s) g\xE9ographique(s) souhait\xE9e(s)":
      "Add your desired geographic Zone (s)",
    "Ajoutez un nom de projet pour retrouver facilement les offres associ\xE9es":
      "Add a project name to easily find related offers",
    "Ajoutez vos derni\xE8res exp\xE9riences pour nous aider \xE0 vous trouver un job qui vous correspond. N\u2019oubliez pas de renseigner vos r\xE9f\xE9rences pour un process plus rapide":
      "Adding your past experiences will help us find you a suitable job. Don\u2019t forget to provide references to speed up the process",
    "Ajoutez votre": "Add your",
    Alerte: "Alerte",
    "Alerte !": "warning !",
    "Alerte Mission": "Job alert",
    "Alerte Suppression": "Deletion Alert",
    "Alerte cr\xE9\xE9e avec succ\xE8s": "Alert successfully created",
    "All the endpoints of the company are protected with antivirus and firewalls.":
      "All the endpoints of the company are protected with antivirus and firewalls.",
    Allemand: "German",
    "An insider's look at the tech industry through interviews with expert from all areas of it.":
      "An insider's look at the tech industry through interviews with expert from all areas of it.",
    "An insider's look at the tech industry through interviews with expert from all areas of it..":
      "An insider's look at the tech industry through interviews with expert from all areas of it..",
    "Analyse les tendances et \xE9volutions du monde des talents tech & IT.":
      "Mission Control Center is your one-stop shop for IT recruitment and career  advice",
    Anglais: "English",
    Annuler: "Cancel",
    "Annuler le filtrage par projet": "Reset filter by project",
    Appels: "Calls",
    "Application de filtre": "Applying filter",
    Appliquer: "Apply",
    "Applying for jobs through a job aggregator, which may then redirect you to the Club Freelance website;":
      "Applying for jobs through a job aggregator, which may then redirect you to the Mindquest website;",
    "Apr\xE8s l\u2019importation, Vos donn\xE9es seront remplac\xE9es par les donn\xE9es LinkedIn. Voulez vous continuer?":
      "After importing, your data will be replaced by LinkedIn data. Would you like to continue?",
    Arabe: "Arabic",
    Arabic: "Arabic",
    "As a recruitment business and recruitment agency we introduce candidates to clients for independent professional contracts. The exchange of personal data of our candidates and our client contacts is a fundamental, essential part of this process.":
      "As a recruitment business and recruitment agency we introduce candidates to clients for independent professional contracts. The exchange of personal data of our candidates and our client contacts is a fundamental, essential part of this process.",
    Astreintes: "On-call duties",
    "Attention !": '"Warning !',
    "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9 en":
      "The selected day is bank holiday in",
    Au: "To",
    "Aucun compte ne correspond \xE0 cet email":
      "The email you\u2019ve entered doesn\u2019t match any account",
    "Aucun contact indiqu\xE9": "No contact indicated",
    "Aucun itin\xE9raire n\u2019a pu \xEAtre trouv\xE9 entre l\u2019origine et la destination.":
      "No itinerary was found between origin and destination",
    "Aucun pr\xE9paiements": "No prepayments",
    "Aucun r\xE9sultat pour votre filtre.<0/>":
      "No result for your filter.<0/>",
    "Aucun r\xE9sultat pour votre recherche. Essayez avec d\u2019autres mots-cl\xE9s.":
      "No results for your search. Pelase try with other keywords.",
    "Aucune donn\xE9e \xE0 exporter": "No data to export",
    "Aujourd\u2019hui": "Today",
    Autoentrepreneu: "Auto entrepreneur",
    Autoentrepreneur: "Auto entrepreneur",
    Autre: "Other",
    "Autres comp\xE9tences du candidat": "Other skills of the candidate",
    "Avant de soumettre votre Timesheet {0} au client, veuillez s'il vous plait indiquer en quelques lignes les actions effectu\xE9es ce mois.": function(
      a
    ) {
      return [
        "Before sending your Timesheet for ",
        a("0"),
        " to the client, please indicate briefly your tasks achieved this month."
      ];
    },
    Avantages: "Benefits",
    Avertissement: "Warning",
    "Back to website": "Back to website",
    "Bank account details your company;":
      "Bank account details for your company;",
    Barcelone: "Barcelona",
    "Bas\xE9s au sein de notre Sourcing Hub \xE0 Barcelone, nos Talent Community Specialists sont en contact permanent avec les meilleurs profils tech et IT. Ils sont en mesure d\u2019identifier et d\u2019accompagner les meilleurs talents dans toute l\u2019Europe.":
      "Based in our Sourcing Hub in Barcelona, our Talent Community Specialists are in constant contact with the best professionals within their technological domain. They are able to identify and accompany Europe\u2019s most brilliant minds",
    "Bas\xE9s localement et sp\xE9cialis\xE9s par secteur d\u2019activit\xE9, nos Key Account Managers accompagnent leurs entreprises clientes dans la recherche des meilleures talents pour leurs projets IT.":
      "Based in each region, our sector-specialized Key Account Managers help companies secure the best talent for all their IT projects across Europe.",
    "Be in the know": "Be in the know",
    "Behind the Screens": "Behind the Screens",
    "Besoin d'un partenaire pour le recrutement de vos ressources IT ?":
      "Besoin d'un partenaire pour le recrutement de vos ressources IT ?",
    "Besoin d\u2019un partenaire pour le recrutement de vos ressources IT ?":
      "Looking for an IT recruitment partner?",
    'Bien que la tendance \xE9volue, le travail en r\xE9gie est toujours la norme dans de nombreuses entreprises. Choissiez "indifferent" pour ne manquer aucune opportunit\xE9.':
      "Although the market is changing, working on-site is still the standard for many companies. Don\u2019t miss out any opportunities, select Indifferent.",
    "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour confirmer votre inscription":
      "Welcome to the club, An email has been sent to confirm your registration",
    "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour finaliser votre inscription et acc\xE9der \xE0 votre Dashboard":
      "Welcome to the quest! We have sent you an email to complete the registration process and access your personal dashboard",
    "Bienvenue au club, Vous pouvez mainetenant acc\xE9der \xE0 votre Dashboard":
      "Welcome to the club, You can now access your Dashboard",
    "Bienvenue au club, Vous pouvez maintenant acc\xE9der \xE0 votre Dashboard":
      "Bienvenue au club, Vous pouvez maintenant acc\xE9der \xE0 votre Dashboard",
    "Bienvenue au club, un Key Account Manager va vous recontacter dans les plus brefs d\xE9lais pour faire le point sur vos recherches":
      "Welcome to Mindquest, a Key Account Manager will contact you as soon as possible to discuss your request",
    "Blah Blah Blah Bloo Bloo Blah Bloo Bloo":
      "Blah Blah Blah Bloo Bloo Blah Bloo Bloo",
    Blog: "Resources",
    "Bon de commande": "Purchase order",
    "Bon \xE0 savoir": "Good to know",
    "Bouche \xE0 oreille": "word of mouth",
    "Brief profil": "Brief profile",
    "Business Lounge": "Business Lounge",
    "B\xE9n\xE9ficiez d\u2019offres s\xE9lectionn\xE9es pour votre profil.":
      "Get recommendations that check all your boxes",
    CDI: "Permanent",
    "CDI / Freelance": "Permanent / Contract",
    "CF Admin peut d\xE9sormais valider les Timesheets du contrat {placementId}": function(
      a
    ) {
      return [
        "CF Admin Can Now Validate Contract Timesheets ",
        a("placementId")
      ];
    },
    "CF Admin peut d\xE9sormais valider vos Timesheets":
      "CF Admin Can Now Validate Contract Timesheet",
    "CF Sourceur": "Mindquest sourcer",
    "CRM / ATS": "CRM / ATS",
    CV: "CV",
    "CV envoy\xE9": "CV sent",
    "CV envoy\xE9s": "CVs sent",
    "CV s\xE9lectionn\xE9s": "Shortlisted CVs",
    Calendrier: "Calendar",
    "Candidate Data": "Candidate Data",
    "Candidate and Client data": "Candidate and Client data",
    Candidates: "Candidates",
    Candidats: "Candidates",
    Candidature: "Application",
    "Candidature cr\xE9\xE9e avec succ\xE8s":
      "Application created successfully",
    Candidatures: "Applications",
    "Captain's Log": "Captain's Log",
    "Career History;": "Career History;",
    "Career Spa": "Career Spa",
    Cdi: "Permanent",
    "Ce champ est requis": "Required field",
    "Ce champ ne contient pas la m\xEAme valeur":
      "This field does not contain the same value",
    "Ce champs est requis": "Required field",
    "Ce champs ne contient pas la m\xEAme valeur":
      "This field does not contain the same value",
    "Ce format de fichier n\u2019est pas pris en charge. Veuillez choisir l\u2019une des options suivantes :":
      "This file format is not supported. Please choose one of the following options:",
    "Ce site utilise des cookies pour offrir une meilleure exp\xE9rience de navigation. D\xE9couvrez comment nous utilisons les cookies dans notre":
      "This site uses cookies to offer a better browsing experience. Find out more about how we use cookies in our",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t":
      "This type of account is suitable for professionals on a mission or for candidates seeking research. If you wish to recruit or you are an ordering party, create instead ",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1/>":
      "This type of account is suitable for professionals on a mission or for candidates seeking research. If you wish to recruit or you are an ordering party, create instead ",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>":
      "This type of account is suitable for professionals on a mission or for candidates seeking research. If you wish to recruit or you are an ordering party, create a company account instead.",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherhche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>":
      "This type of account is suitable for professionals on a mission or for candidates seeking research. If you wish to recruit or you are an ordering party, create a company account instead.",
    "Certaines donn\xE9es n'ont pas \xE9t\xE9 enregistr\xE9es seront perdues. Etes-vous s\xFBr de vouloir passer \xE0 l\xE9tape suivante ?":
      "\u201CSome data that was not saved will be lost. Are you sure you want to go to the next step?",
    Certifications: "Certifications",
    "Ces statistiques correspondent aux actions r\xE9alis\xE9es par nos \xE9quipes sur chaque offre ajout\xE9e sur la periode s\xE9lectionn\xE9e.":
      "These statistics correspond to all actions carried by our teams on each offer posted over the selected period",
    "Ces statistiques correspondent \xE0 l\u2019ensemble des actions r\xE9alis\xE9es par nos \xE9quipes sur la p\xE9riode s\xE9lectionn\xE9e":
      "These statistics correspond to the total of the actions carried by our teams over the selected period",
    "Cette date ne peut pas \xEAtre dans le future":
      "This date cannot be in the future",
    "Cette offre est ferm\xE9e et la disponibilit\xE9 de ce profil n'est plus d'actualit\xE9":
      "This offer is closed and this profile is no longer available",
    "Champ requis": "required field",
    "Champs requis": "Required field",
    "Changer votre mot de passe": "Change password",
    "Chaque offre de mission est prise en charge par un bin\xF4me de deux experts, qui entretiennent des relations de long-terme avec entreprises et consultants":
      "Every vacancy is managed by a two-person team, allowing for greater agility and deeper focus",
    Chargement: "Loading",
    "Chercher des comp\xE9tences": "Search by skills",
    "Chercher des sp\xE9cialit\xE9s": "Search specialties",
    "Chercher une offre...": "Search for an offer",
    Chinese: "Chinese",
    Chinois: "Chinese",
    "Choisissez au moins un domaine": "Choose at least 1 area",
    "Choisissez au moins un secteur d\u2019activit\xE9":
      "Select at least one activity area",
    "Choisissez au moins une sp\xE9cialit\xE9": "Choose at least one specialty",
    "Choisissez entre deux et dix comp\xE9tences":
      "Choose between 2 and 10 skills",
    "Choisissez entre une et dix comp\xE9tences":
      "Choose between 1 and 10 skills",
    "Choisissez le mod\xE8le adapt\xE9 \xE0 votre situation":
      "Choose the suitable model for your situation",
    "Choissiez le mod\xE8le adapt\xE9 \xE0 votre situation":
      "Choose the model adapted to your situation",
    "Click here for whatever": "Click here for whatever",
    Client: "Client",
    "Client data": "Client data",
    Clients: "Clients",
    "Clients grands comptes qui nous font confiance":
      "Industry-leading companies that trust us",
    "Cliquez i\xE7i pr\xE9ciser le nombre d\u2019heures travaill\xE9s":
      "Click here to specify the number of hours worked",
    'Cliquez sur le cadre "Mots-cl\xE9s indispensables" ou "Mots-cl\xE9s importants" puis s\xE9lectionnez les mots-cl\xE9s IT souhait\xE9s':
      "Click on the \u2018Mandatory Keywords\u2019 or \u2018Desirable Keywords\u2019 box and select the most relevant keywords for your search",
    "Club Freelance": "Mindquest",
    "Club Freelance - La communaut\xE9 des Freelances Informatique":
      "Mindquest - The community where top IT talent meets top jobs",
    "Club Freelance Iberica": "Mindquest Ibe.",
    "Club Freelance Insights | Conseils Pratiques pour D\xE9cideurs Informatiques":
      "Mindquest's Mission Control Center | Career Tips & Tricks",
    "Club Freelance Insights | Conseils Pratiques pour Freelances Informatiques":
      "Mindquest's Mission Control Center | Career Tips & Tricks",
    "Club Freelance Insights | D\xE9cryptages des m\xE9tiers de l\u2019IT":
      "Mindquest's Mission Control Center | Decoding of IT professions",
    "Club Freelance Insights | Le blog des talents IT":
      "Mindquest's Mission Control Center - MCC: IT's resource hub",
    "Club Freelance Insights | L\u2019actualit\xE9 des talents IT":
      "Mindquest's Mission Control Center | IT talent news",
    "Club Freelance Insights | Rechercher un article":
      "Mindquest's Mission Control Center | Search for an article",
    "Club Freelance Insights | T\xE9moignages":
      "Mindquest's Mission Control Center | Testimonials",
    "Club Freelance Limited": "Club Freelance Limited",
    "Club Freelance Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.":
      "Club Freelance Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.",
    "Club Freelance Limited is a staffing and sourcing company specialized in IT.":
      "Mindquest is a brand owned by Club Freelance Limited, a staffing and sourcing company specialized in IT.",
    "Club Freelance SAS": "Club Freelance SAS",
    "Club Freelance est une soci\xE9t\xE9 de placement de consultants ind\xE9pendants dans la technologie et l\u2019informatique.":
      "Mindquest is a specialist human capital consulting firm providing comprehensive IT talent acquisition solutions. We work in partnership with our valued customers to identify, attract and secure high-calibre talent for business-critical assignments.",
    "Club Freelance | Cr\xE9er un compte":
      "Mindquest | Sign up & start your journey towards IT success",
    "Club Freelance | Dashboard": "Club Freelance | Dashboard",
    "Club Freelance | Entourez-vous des meilleurs Talents Informatiques":
      "Mindquest | Top IT talent for all your business needs",
    "Club Freelance | Expert du sourcing IT digitalis\xE9":
      "Mindquest | About Us",
    "Club Freelance | Ma formation": "Club Freelance | Ma formation",
    "Club Freelance | Ma recherche": "Club Freelance | Ma recherche",
    "Club Freelance | Mes Coordonn\xE9es":
      "Club Freelance | Mes Coordonn\xE9es",
    "Club Freelance | Mon CV": "Club Freelance | Mon CV",
    "Club Freelance | Mon exp\xE9rience": "Club Freelance | Mon exp\xE9rience",
    "Club Freelance | Mon m\xE9tier": "Club Freelance | Mon m\xE9tier",
    "Club Freelance | Mon profil": "Mindquest | Mon profil",
    "Club Freelance | Nous contacter": "Mindquest | Contact Us",
    "Club Freelance | Partenaire des entreprises & freelances IT":
      "Mindquest | Your partner for IT jobs and talent recruitment",
    "Club Freelance | Recherche des candidats":
      "Freelance Club | Search for candidates",
    "Club Freelance | Se connecter": "Mindquest | Se connecter",
    "Club Freelance | Votre prochaine mission Freelance Informatique":
      "Mindquest | Find your next contract & boost your IT career",
    "Club Freelance's Mission Control Center  \u2014 Interesting reads":
      "Mindquest's Mission Control Center \u2014 Interesting reads",
    "Club Freelance's Mission Control Center \u2014 Behind the screen":
      "Club Freelance's Mission Control Center \u2014 Behind the screen",
    "Club Freelance's Mission Control Center \u2014 Business Lounge":
      "Mindquest's Mission Control Center \u2014 Business Lounge",
    "Club Freelance's Mission Control Center \u2014 Captain's Log":
      "Mindquest's Mission Control Center \u2014 Captain's Log",
    "Club Freelance's Mission Control Center \u2014 Career Tips & Tricks":
      "Mindquest's Mission Control Center | Career Tips & Tricks",
    "Club Freelance's Mission Control Center \u2014 Horizon 2050":
      "Mindquest's Mission Control Center | Horizon 2050",
    "Club Freelance's Mission Control Center \u2014 IT's Who is Who":
      "Mindquest's Mission Control Center \u2014 IT's Who is Who",
    "Club Freelance's Mission Control Center \u2014 MCC: IT's resource hub":
      "Mindquest's Mission Control Center \u2014 MCC: IT's resource hub",
    "Club Freelance's Mission Control Center \u2014 Newsstand":
      "Mindquest's Mission Control Center \u2014 Newsstand",
    "Club Freelance's Mission Control Center\u2014 Search all articles":
      "Mindquest's Mission Control Center \u2014 Search all articles",
    "Club Freelance,": "Mindquest,",
    "Club Freelance, Freelance informatique, consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP":
      "Mindquest, IT freelance, Data consultants, Development, Cloud, Project management, CRM, Support, BI, Webdesign, ERP, SAP ",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks":
      "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand":
      "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT":
      "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT",
    "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge":
      "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge",
    "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements":
      "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements",
    "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech":
      "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech",
    "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads":
      "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads",
    "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi":
      "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi",
    "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search":
      "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search",
    "Club Freelance, talent management, freelance informatique, trouver un freelance":
      "Mindquest, talent management, IT freelance, find a candidate",
    Club_Freelance: "Club Freelance",
    "Co-fondateurs": "Co-founders",
    "Code postal*": "ZIP Code*",
    "Collaborateurs \xE0 temps plein": "Full-time employees",
    "Coller votre code ici": "Paste your code here",
    "Collez ici le contenu de votre fiche de poste...":
      "Paste your job description here",
    "Collez l\u2019URL de votre profil Linkedin pour pr\xE9remplir votre profil":
      "Paste your LinkedIn profile URL to pre-fill your profile",
    "Comment avez-vous entendu parlez de nous": "How did you hear about us",
    "Comment \xE7a marche ?": "How does it work?",
    Commentaire: "Comments",
    Commentaires: "Comments",
    "Company information available on local company registrars;":
      "Company information available on local company registrars;",
    "Compl\xE9ter mon profil": "Complete my profile",
    "Compte non verifi\xE9": "Unverified account",
    "Compte-Rendu G\xE9n\xE9r\xE9": "Report generated",
    "Compte-rendu G\xE9n\xE9r\xE9": "Generated report",
    "Compte-rendus": "Reports",
    "Comptes rendus": "Reports",
    Compétence: "Skills",
    Compétences: "Skills",
    "Comp\xE9tences de la recherche": "Research skills",
    Confidentialité: "Privacy",
    "Confiez-nous votre prochain recrutement ?": "Let's find your next hire",
    "Confirmation mot de passe": "Confirm password",
    "Confirmed (3 - 7)": "Confirmed (3 - 7)",
    "Confirmed (3-7)": "Confirmed",
    Confirmer: "Confirm",
    "Confirmer votre mot de passe": "Confirm password",
    "Confirmez le nouveau mot de passe": "Confirm the new password",
    "Confirmez votre mot de passe": "Confirm password",
    Confirmé: "Confirmed",
    Congrats: "Congrats",
    "Connectez-vous \xE0 votre profil Club Freelance":
      "Login to your Mindquest profile",
    Connexion: "Login",
    "Conseils pratiques": "Practical advice",
    "Conseils pratiques Consultant": "Practical advice Candidate",
    "Conseils pratiques Entreprise": "Practical advice Company",
    Consultant: "Consultant",
    "Consultant AWS Cloud Devops": "AWS Cloud DevOps Consultant",
    "Consultants IT": "Consultants IT",
    "Consulter le Timesheet": "Check the Timesheet",
    "Consulter le compte-rendu": "Check the report",
    "Consulter le timesheet": "Check the timesheet",
    "Consultez vos emails pour finaliser l\u2019inscription":
      "Check out your email to complete the registration process",
    Contact: "Contact",
    "Contact de r\xE9f\xE9rences": "References contact",
    "Contact me if you are interested !": "Contact me if you are interested !",
    "Contact me to join our community!": "Contact me to join our community!",
    "Contact us": "Contact us",
    Contacter: "Contact",
    "Contacter Nous": "Contact Us",
    "Contactez nous": "Contact us",
    "Contenu orient\xE9 aux candidats": "Content for candidates",
    "Contenu orient\xE9 aux entreprises": "Content for employers",
    Context: "Context",
    "Contexte de recrutement": "Context for recruitment",
    "Contexte projet": "Context for the project",
    Continuer: "Continue",
    "Continuer sans accepter": "Deny & continue",
    "Contrats reconduits par nos clients":
      "Contrats reconduits par nos clients",
    "Cookies policy": "Cookies policy",
    Coopter: "Recommend",
    "Copier et coller l'URL de votre profil LinkedIn":
      "Copy and paste your LinkedIn profile URL",
    Critères: "Criteria",
    "Cr\xE9ation de Club Freelance": "Club Freelance is born",
    Créer: "Create",
    "Cr\xE9er mon espace personnel Talent": "Create my personal Talent space",
    "Cr\xE9er un compte": "Create account",
    "Cr\xE9er un mot de passe": "Create a password",
    "Cr\xE9er un nouveau mot de passe": "Create a new password",
    "Cr\xE9er une alerte sur ces crit\xE8res":
      "Create an alert with these criteria",
    "Cr\xE9er une offre": "Create an offer",
    "Cr\xE9ez votre profil Club Freelance en seulement 3 minutes":
      "Create your Mindquest account in less than 3 minutes",
    "Currently all the data that we collect from you may/will be transferred to, and stored at, a destination inside the European Economic Area (\u201DEEA\u201D). It may be transferred to third parties outside of the EEA for the purpose of our recruitment services. It may also be processed by staff operating outside the EEA who work for one of our suppliers. This includes staff engaged in, among other things, recruitment services and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing.":
      "Currently all the data that we collect from you may/will be transferred to, and stored at, a destination inside the European Economic Area (\u201DEEA\u201D). It may be transferred to third parties outside of the EEA for the purpose of our recruitment services. It may also be processed by staff operating outside the EEA who work for one of our suppliers. This includes staff engaged in, among other things, recruitment services and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing.",
    "Daily rate Range;": "Daily rate Range;",
    "Dans un rayon de": "Within",
    "Dans un rayon max de XX km": "Within XX km",
    Dashboard: "Dashboard",
    "Dashboard Enterprise": "Employer Dashboard",
    "Data Science & BI": "Data Science & BI",
    Date: "Date",
    "Date de d\xE9but": "Start date",
    "Date de fin": "End date",
    "Date of birth;": "Date of birth",
    "Demande de modification": "Change request",
    "Depending on the relevant circumstances and applicable local laws and requirements, we may collect information to enable us to offer you potential employment opportunities which are tailored to your circumstances and your interests. This information may include:":
      "Depending on the relevant circumstances and applicable local laws and requirements, we may collect information to enable us to offer you potential employment opportunities which are tailored to your circumstances and your interests. This information may include:",
    "Des hommes et des algorithmes pour un matching parfait entre <0>entreprises</0> et <1>freelances IT</1>":
      "Where <0>IT talent</0> and <1>business</1> cross paths on their way to success",
    "Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances en informatique. Nous accompagnons les meilleurs talents et les entreprises les plus innovantes.":
      "Where IT talent and business cross paths on their way to success. We support the best talents and the most innovative companies.",
    "Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances informatique.":
      "Where IT talent and business cross paths on their way to success.",
    "Des services": "Our Services",
    "Description de l'offre": "Offer description",
    "Description de la mission": "Job description",
    "Design Graphique & UX/UI": "Design Graphique & UX/UI",
    "Devenez apporteur d\u2019affaire et faites-vous r\xE9mun\xE9rer":
      "Bring in new business and get paid",
    "Disponibile \xE0 partir de:": "Available from",
    Disponibilité: "Availability",
    "Disponibilit\xE9 \xE0 confirmer": "Not available",
    Disponible: "Available",
    "Disponible \xE0 partir de:": "Available from:",
    Distance: "Distance",
    "Dites-nous ce que nous pouvons faire pour vous.":
      "Tell us what we can do for you",
    Documents: "Documents",
    "Doit contenir au moins 8 caract\xE8res": "Must have at least 8 characters",
    "Doit contenir au moins un caract\xE8re sp\xE9cial !*#@...":
      "Must have at least one special character !*#@...",
    "Doit contenir au moins un chiffre": "Must have at least one digit",
    "Doit contenir au moins une majuscule":
      "Must have at least one capital letter",
    "Doit contenir au moins une minuscule":
      "Must have at least one lowercase letter",
    "Domaine d\u2019expertise": "Areas of expertise",
    "Domaines d'expertise": "Areas of expertise",
    "Domaines d\u2019expertise": "Areas of expertise",
    Du: "From",
    Dutch: "Dutch",
    "D\xE9cideurs IT": "D\xE9cideurs IT",
    "D\xE9couvrez Club Freelance": "Discover Club Freelance",
    "D\xE9couvrez Mission Control Center : tous nos conseils pour les professionnels de l'IT":
      "Explore Mission Control Center your one-stop shop for career advice",
    "D\xE9couvrez les m\xE9tiers de l\u2019informatique et du digital d\xE9crypt\xE9s":
      "Discover the IT and digital professions decrypted",
    "D\xE9couvrez les tendances et \xE9volution des m\xE9tiers de l\u2019informatique & du digital \xE0 l\u2019heure du future of work. Fiches m\xE9tiers d\xE9taill\xE9es et analyses.":
      "Discover the trends and developments in IT & digital professions in the age of the future of work. Detailed business files and analyzes",
    "D\xE9couvrez nos mod\xE8les de facture, de CV et template de note de frais":
      "Explore our templates for CVs, invoices, expense reports...",
    "D\xE9couvrez nos ressources pour les entreprises":
      "Explore our resources for employers",
    "D\xE9couvrez notre service de recrutement freelance":
      "Discover our freelancer recruitment service",
    "D\xE9couvrez notre \xE9quipe": "Meet our team",
    "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour recruter les meilleurs talents et travailler efficacement avec des freelances IT.":
      "Discover all our advice and expert points of view to recruit the best talent and work effectively with IT freelancers.",
    "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour r\xE9ussir dans votre activit\xE9 de freelance IT et d\xE9crocher les meilleures missions.":
      "Discover all our advice and expert views to succeed in your IT freelance activity and land the best jobs.",
    "D\xE9couvrez tous nos conseils pour travailler efficacement avec les Freelances IT":
      "Discover all our tips for working effectively with IT Freelancers",
    "D\xE9couvrez tous nos conseils pour une activit\xE9 de Freelance r\xE9ussie":
      "Discover all our advice for a successful Freelance activity",
    "D\xE9couvrez toutes les offres de votre Talent Community Speciallist":
      "Discover all the offers of your Talent Community Speciallist",
    Découvrir: "Discover",
    "D\xE9couvrir Club Freelance": "Discover Mindquest",
    "D\xE9couvrir les donn\xE9es": "D\xE9couvrir les donn\xE9es",
    "D\xE9couvrir les ressources": "See resources",
    "D\xE9couvrir nos offres": "Discover our offers",
    "D\xE9couvrir nos partenaires": "Visit our partnerships page",
    Décryptage: "Decryption",
    Décryptages: "Decryption",
    "D\xE9lai moyen pour recevoir une shortlist de candidats qualifi\xE9s":
      "To get a shortlist of qualified candidates",
    Démarrage: "Start date",
    "D\xE9ploiement international": "D\xE9ploiement international",
    "D\xE9poser un projet": "Post a vacancy",
    "D\xE9scription de la mission": "Job description",
    Désolé: "Sorry",
    "D\xE9sol\xE9, aucun r\xE9sultat trouv\xE9.": "Sorry, no results found",
    "D\xE9sol\xE9, pas de candidats pour ces crit\xE8res":
      "Sorry, no candidates for these criteria",
    "D\xE9sol\xE9, pas de missions pour ces crit\xE8res":
      "Sorry, There is no jobs matching these criteria",
    "D\xE9tails de l'offre": "Offer details",
    "D\xE9veloppement Logiciels & Syst\xE8mes d'Exploitation":
      "Software Development & Operating Systems",
    "D\xE9veloppement Mobile": "Mobile development",
    "D\xE9veloppement Web": "Web development",
    "D\u2019autres ressources qui pourraient vous interesser":
      "Other resources you might be interested in",
    "D\u2019autres {0} qui pourraient vous interesser": function(a) {
      return ["Other ", a("0"), " you might be interested in"];
    },
    "E-mail": "E-mail",
    "ERP / EDI": "ERP / EDI",
    EURL: "EURL",
    Editer: "Edit",
    "Education & Qualifications;": "Education & Qualifications;",
    Email: "Email",
    "Email Envoy\xE9": "Email Sent",
    "Email envoy\xE9 avec succ\xE9es": "Email sent successfully",
    "Email invalide": "Invalid Email",
    "Embark on a journey to discover what lies ahead for IT and science. Short sci-fi stories posted every month for you to engage and especulate on the future of tech.":
      "Embark on a journey to discover what lies ahead for IT and science. Short sci-fi stories posted every month for you to engage and especulate on the future of tech.",
    "En attente": "Waiting",
    "En chargeant votre CV, vos informations seront pr\xE9remplies. Un CV au format Word offrira de meilleurs r\xE9sultats":
      "By uploading your CV, your information will be pre-filled. A CV in Word format will provide better results",
    "En cliquant sur le bouton ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login":
      "By clicking on the button below, you will be disconnected and redirected to the login page",
    "En construction": "Under construction",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils refus\xE9s, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "By moving this candidate to the rejected profiles column, the Mindquest team will be notified",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils \xE0 rencontrer, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "By moving this candidate to the profiles to meet column, the Mindquest team will be notified",
    "En d\xE9pla\xE7ant ce candidat dans la colonne proposition, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "By moving this candidate to the proposal column, the Mindquest team will be notified",
    "En rejetant le timesheet, celui-ci sera renvoy\xE9 au consultant pour modification. Merci d'indiquer en commentaire la raison de ce rejet de la fa\xE7on la plus pr\xE9cise possible.":
      "By rejecting the timesheet, it will be returned to the consultant for modification. Please indicate in your comments the reason for this rejection as precisely as possible.",
    "En savoir plus": "Find out more",
    "En transport en commun": "By public transport",
    "En voiture": "By car",
    "Encore quelques instants...": "Just a couple more minutes...",
    "Encryption prevents data from being easily accessible and recoverable in the event of theft or computer misuse. Our websites are protected by an SSL certificate.":
      '"Encryption prevents data from being easily accessible and recoverable in the event of theft or computer misuse. Our websites are protected by an SSL certificate.',
    "Encryption technology is in use to protect the integrity of the personal data we process.":
      "Encryption technology is in use to protect the integrity of the personal data we process.",
    English: "English",
    "Enlever de favoris": "Remove from favourites",
    "Enregistrement de la feuille de temps": "Saving the timesheet",
    "Enregistrement effectu\xE9 avec succ\xE8s":
      "Your data has been saved successfully",
    Enregistrer: "Save",
    "Enregistrer en brouillon": "Save as draft",
    "Enregistrer en favori": "Add to favorites",
    "Enregistrer en favoris": "Add to favorites",
    "Enter your email address": "Enter your email address",
    "Entering a competition through a social media channel such as Facebook or Twitter.":
      "Entering a competition through a social media channel such as Facebook or Twitter.",
    "Entering your details on the Club Freelance website or registering an account with us;":
      "Entering your details on the Club Freelance website or registering an account with us;",
    "Entering your personal details into a Club Freelance microsite; or":
      "Entering your personal details into a Club Freelance microsite; or",
    Entreprise: "Company",
    "Entreprise ?": "Employer ?",
    Entreprise_: "Employers",
    Entreprises: "Clients",
    "Entrer mon email": "Enter my email",
    "Entrer un email valid": "Enter a valid email address",
    "Entrer un mot de passe": "Enter your password",
    "Entrer votre email": "Enter your email address",
    "Entrer votre mot de passe": "Enter your password",
    Entretien: "Interview",
    Entretiens: "Interviews",
    "Entrez ici vos mots-cl\xE9s IT": "Write your IT keywords here",
    "Environements technique": "Technical environments",
    "Environnement technique": "Technical environment",
    Envoyer: "Send",
    Erreur: "Error",
    "Erreur c\xF4t\xE9 de serveur. Essayez plus tard":
      "Server error. Try again later.",
    "Erreur de cr\xE9ation de besoin": "Error creating your need",
    "Erreur de cr\xE9ation de candidature": "Error while creating application",
    "Erreur de cr\xE9ation d\u2019alerte": "Error while creating alert",
    "Erreur non identifi\xE9e": "Unidentified error",
    "Erreur!": "Error!",
    Espagnol: "Spanish",
    "Essayez la d\xE9tection automatique": "Try the auto detect",
    "Et remplir mon profil \xE0 la main": "And fill out your profile",
    "Et trouvez les meilleurs consultants en informatique pour vos projets":
      "to find your next top performer",
    Etablissement: "Etablissement",
    "Etape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues":
      "Etape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues",
    "Etape suivante": "Next step",
    "Ever wondered what it is that your colleagues in other areas of IT actually do?":
      "Ever wondered what exactly it is that your colleagues in other areas of IT actually do? Interested in a career change ?",
    "Ever wondered what it is that your colleagues in other areas of IT actually do? Curious about exploring new career paths in tech? Impove you awareness of the industy.":
      "Ever wondered what it is that your colleagues in other areas of IT actually do? Curious about exploring new career paths in tech? Impove you awareness of the industy.",
    "Evolutions des Jobs SAP": "SAP Jobs Evolution",
    "Ex : Architecte Java, Consultant SAP":
      "Ex : Java Architect, SAP Consultant",
    "Ex:Architecte Java, Consultant SAP": "Ex: Java Architect, SAP Consultant",
    Experiences: "Experiences",
    Expert: "Expert",
    "Expert (10+)": "Expert (10+)",
    Expertise: "We know tech",
    "Exporter en CSV": "Export CSV",
    Expérience: "Experience",
    Expériences: "Experiences",
    "Extra information that our clients may tell us about you / Performance information Extra information that you choose to tell us;":
      "Extra information that our clients may tell us about you / Performance information Extra information that you choose to tell us;",
    "Extra information that your referees choose to tell us about you. Please note that the above list of categories of personal data we may collect is not exhaustive.":
      "Extra information that your referees choose to tell us about you. Please note that the above list of categories of personal data we may collect is not exhaustive.",
    "Extra, extra! IT and tech news from the past week, every week. From Cloud and IoT to SAP's ERP and all things Microsoft, these are news stories you should keep in mind.":
      "Extra, extra! IT and tech news from the past week, every week. From Cloud and IoT to SAP's ERP and all things Microsoft, these are news stories you should keep in mind.",
    "FREELANCE ?": "Candidate ?",
    Favoris: "Favourites",
    "Fiches m\xE9tiers": "Fiches m\xE9tiers",
    "Fichier g\xE9n\xE9r\xE9 avec succ\xE8s": "File generated successfully",
    "Filtrer par projet": "Filter by project",
    "Filtres S\xE9lectionn\xE9s :": "Selected Filters :",
    "Filtres Vide": "Empty filters",
    Filtré: "Filtered",
    "Find freelancer": "Find your candidate",
    "Find the advice and resources you're looking for at the Mission Control Center, your one-stop shop for IT talent management and career advice.":
      "Find the advice and resources you're looking for at the Mission Control Center, your one-stop shop for IT talent management and career advice.",
    "For the purpose of the GDPR, the data controller is, Club Freelance Limited, 100 Drummond Road SE 16 4DG London UK.":
      "For the purpose of the GDPR, the data controller is, Club Freelance Limited, 100 Drummond Road SE 16 4DG London UK.",
    "Format d\u2019image invalide. Formats recommand\xE9s : .jpeg .png":
      "Invalid image format. recommended formats: .jpeg .png",
    "Format non valide": "Invalid format",
    "Formation et Certifications": "Trainings and Certifications",
    Français: "French",
    Freelance: "Freelance",
    "Freelance / Salari\xE9": "Freelance / Permanent",
    "Freelance informatique, consultant IT, mission freelance, mission sap, mission d\xE9veloppement, mission data":
      "IT freelance, IT consultant, freelance mission, sap mission, development mission, data mission",
    "Freelance/Contract": "Freelance/Contract",
    FreelanceType: "Freelance",
    Freelances: "Candidates",
    French: "French",
    "F\xE9licitations!": "Congrats!",
    "F\xE9licitations!! Tous les champs obligatoires de votre profil sont remplis, vous pouvez maintenant g\xE9n\xE9rer votre CV Club Freelance.":
      "Congratulation!! All the mandatory fields of your profile have been completed, you can now generate your Club Freelance CV.",
    "F\xE9licitations!! Votre profil est rempli \xE0 {0}%, vous pouvez maintenant g\xE9n\xE9rer votre CV Club Freelance.": function(
      a
    ) {
      return [
        "Congratulations!! Your profile is ",
        a("0"),
        "% complete, you can now generate your Mindquest CV."
      ];
    },
    "F\xE9licitations!! Votre profil est rempli \xE0 {0}%, vous pouvez maintenant g\xE9n\xE9rer votre CV Mindquest.": function(
      a
    ) {
      return [
        "Congratulations!! Your profile is ",
        a("0"),
        "% complete, you can now generate your Mindquest CV."
      ];
    },
    "F\xE9licitations, votre profil est compl\xE9t\xE9 \xE0 100% ! D\xE9couvrez d\xE8s maintenant nos offres recommand\xE9es pour votre profil.":
      "Congratulations, your profile is 100% complete! Discover now our recommended offers for your profile.",
    German: "German",
    "Gestion de projet & Coaching Agile": "Project management & Agile Coaching",
    "Gouvernance Informatique": "IT governance",
    "Grand Groupe (500+)": "Big group (500+)",
    "Grande entreprise (101-150)": "Big company (101-150)",
    "Grande entreprise (101-500)": "Large company (101-500)",
    Greek: "Greek",
    Greque: "Greek",
    "Gr\xE2ce \xE0 notre \xE9quipe d\u2019experts, nous recueillons quotidiennement les insights des entreprises et des candidats sur le march\xE9 des comp\xE9tences IT":
      "Thanks to our team of dedicated experts, we are able to deliver a perfect match betweeen project requirements and IT talent",
    "G\xE9nerer votre CV": "Generate your CV",
    "G\xE9n\xE9rez d\xE8s maintenant votre CV Club Freelance et diffusez le gr\xE2ce au lien partageable !":
      "G\xE9n\xE9rez d\xE8s maintenant votre CV Club Freelance et diffusez le gr\xE2ce au lien partageable !",
    "G\xE9n\xE9rez d\xE8s maintenant votre CV Mindquest et diffusez le gr\xE2ce au lien partageable !":
      "Generate your Mindquest resume now and share it with our link!",
    "G\xE9rez vos candidatures et feuilles de temps depuis votre espace perso.":
      "Manage all your applications and job alerts from your dashboard",
    "G\xE9rez vos candidatures et feuilles de temps depuis votre espace personel.":
      "Manage all your applications from your Dashboard",
    "G\xE9rez vos documents administratifs":
      "Manage all your paperwork from on place",
    "G\xE9rez vos missions et vos contrats depuis votre espace client.":
      "Manage your missions and contracts from your customer area.",
    "G\xE9rez vos missions et vos contrats depuis votre espace personel.":
      "Manage all your vacancies and contracts from your dashboard",
    "Heures & Astreintes": "Hours & Penalties",
    "Heures (taux normal)": "Hours (normal rate)",
    "Heures suppl\xE9mentaires": "Overtime",
    Hindi: "Hindi",
    Historique: "History",
    "Horizon 2050": "Horizon 2050",
    "Hors Informatique": "Excluding IT",
    "How can we help?": "How can we help?",
    "How can you access, amend, or take back the personal information that you have given us and what are your rights?":
      "How can you access, amend, or take back the personal information that you have given us and what are your rights?",
    "How do we collect your personal data?":
      "How do we collect your personal data?",
    "How do we store and transfer your data internationally?":
      "How do we store and transfer your data internationally?",
    "How do we use your personal data?": "How do we use your personal data?",
    "How long do we keep your personal data for?":
      "How long do we keep your personal data for?",
    "How to contact us about your personal information":
      "How to contact us about your personal information",
    "However, while these measures provide security against unauthorised access, loss, theft or manipulation from third parties, the internet is not a fully secure domain. For this reason, Club Freelance cannot guarantee the security or integrity of any personal information which is transferred from you or to you via email/ the Internet.":
      "However, while these measures provide security against unauthorised access, loss, theft or manipulation from third parties, the internet is not a fully secure domain. For this reason, Club Freelance cannot guarantee the security or integrity of any personal information which is transferred from you or to you via email/ the Internet.",
    "I'm looking for Jobs": "I'm looking for Jobs",
    "I'm looking for Talent": "I'm looking for Talent",
    "INVERIFIED ACCOUNT": "INVERIFIED ACCOUNT",
    "IP address National Insurance number or country equivalent;":
      "IP address National Insurance number or country equivalent;",
    "IT and tech news of the week, every week. These are stories to keep in mind.":
      "Our weekly selection of technology news. These are the stories to keep in mind.",
    "IT career advice at zero price. Get the contract or job you always wanted.":
      "IT career advice at zero price. Be the professional you\u2019ve always wanted to be",
    "IT career advice at zero price. Valuable resources to help you navigate professional growth and get that contract or job you always wanted.":
      "IT career advice at zero price. Valuable resources to help you navigate professional growth and get that contract or job you always wanted.",
    "IT's Who is Who": "IT's Who is Who",
    "Identifiez des nouveaux talents pour votre entreprise.":
      "Find your next top performers",
    "If a Club Freelance entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;":
      "If a Club Freelance entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;",
    "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Club Freelance, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.":
      "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Club Freelance, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.",
    "If you believe, for any reason, that your Personal Data has been breached while in Club Freelance\u2019s care, custody or control please email Club Freelance immediately at contact@club-freelance.com.":
      "If you believe, for any reason, that your Personal Data has been breached while in Club Freelance\u2019s care, custody or control please email Club Freelance immediately at contact@club-freelance.com.",
    "If you fail to provide personal data":
      "If you fail to provide personal data",
    "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Club Freelance at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.":
      "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Club Freelance at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.",
    "If you were referred to us through an (Recruitment process Outsourcing) RPO or an Managed Services providers MSP supplier, they may share personal information about you with us.":
      "If you were referred to us through an (Recruitment process Outsourcing) RPO or an Managed Services providers MSP supplier, they may share personal information about you with us.",
    "If you wish, you have the right in certain circumstances to receive your personal data in a structured, commonly used and machine-readable format and have the right to transmit the data to another controller without hindrance from us.":
      "If you wish, you have the right in certain circumstances to receive your personal data in a structured, commonly used and machine-readable format and have the right to transmit the data to another controller without hindrance from us.",
    "If you \u2019like\u2019 our page on Facebook or \u2019follow\u2019 us on Twitter or LinkedIn, we will receive your personal information from those sites; and":
      "If you \u2019like\u2019 our page on Facebook or \u2019follow\u2019 us on Twitter or LinkedIn, we will receive your personal information from those sites; and",
    "Il n'y a pas de projets pour les filtrer": "No projects to filter yet",
    "Il n\u2019y a pas de projets pour les filtrer":
      "There are no projects to filter them",
    "Il n\u2019y a pas de sourcer avec cet Id":
      "There's no sourcer with this Id",
    "Il n\u2019y a pas de talents qualifi\xE9s par nos \xE9quipes pour le moment.":
      "No candidates have been vetted by our team yet",
    "Il n\u2019y a pas des missions disponible a ce moment pour ce sourceur":
      "This Talent Community Specialist does not have any open positions at the moment",
    "Il n\u2019y a pas des missions similaires":
      "There is no similar open positions at the moment",
    "Il n\u2019y a pas des posts pour le moment dans cette section":
      "No posts in this section yet",
    "Il n\u2019y a pas des ressources disponibles pour le moment":
      "There are no resources available at the moment",
    "Il n\u2019y a pas encore de retour": "There is no feedback yet",
    "Il y'a pas encore des articles pour cette cat\xE9gorie.":
      "There are no articles for this category so far",
    "Ils ont r\xE9alis\xE9s des projets avec nos freelances":
      "They successfully onboarded our candidates",
    "Ils ont r\xE9alis\xE9s des projets avec nos freelances.":
      "These companies trust us with their IT talent needs",
    Importer: "Import",
    "Importer mon CV": "Import my resume",
    "Importer mon fichier": "Import job description",
    "Importer votre profil LinkedIn": "Import your LinkedIn profile",
    "Importer votre profil LinkedIn<0><1/><2>Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci</2><3/></0>":
      "Import your LinkedIn profile <0> <1 /> <2> Import your LinkedIn profile data from its public link </2> <3 /> </0>",
    "Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci":
      "Import your Linkedin profile data from its public link",
    "Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)":
      "Upload a CV in .docx format,. doc or. pdf. Your data will be automatically extracted to quickly complete your profile (recommended)",
    "Impossible de r\xE9cup\xE9rer vos informations":
      "Unable to retrieve your information",
    "In order to support our candidates\u2019 career aspirations and our clients\u2019 resourcing needs we require a database of candidate and client personal data containing historical information as well as current resourcing requirements.":
      "In order to support our candidates\u2019 career aspirations and our clients\u2019 resourcing needs we require a database of candidate and client personal data containing historical information as well as current resourcing requirements.",
    "In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.":
      "In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.",
    "In some circumstances you can ask us to delete your data: see Request erasure below for further information.":
      "In some circumstances you can ask us to delete your data: see Request erasure below for further information.",
    "In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;":
      "In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;",
    Indifferent: "Indifferent",
    Indifférent: "Indifferent",
    "Indiquez des informations de contexte comme la phase du projet, la composition de l\u2019\xE9quipe ou l\u2019organisation interne...":
      "Indicate all possible background information such as the phase of the project, the makeup of the team or your company's internal organisation ...",
    "Ind\xE9pendants : tout savoir sur le pr\xE9l\xE8vement forfaitaire lib\xE9ratoire":
      "How to ace an interview for an IT or tech position",
    "Informations personnelles": "Personal informations",
    "Informations sur le client": "Informations about the client",
    "Informations sur le signataire": "Signatory information",
    "Infrastructure et Cloud": "Infrastructure and Cloud",
    "Inscription compl\xE8te": "Complete registration",
    "Inscription en ligne": "Online registration",
    "Inscription express": "Express sign-up",
    "Inscrivez-vous et recevez tous les articles":
      "Subscribe to our weekly newsletter to get the latest articles and technology news",
    "Insights pour professionels IT et entreprises":
      "Actionable insights for professionals and businesses",
    "Interesting Reads": "Interesting Reads",
    Intitulé: "Job title",
    Introduction: "Introduction",
    Intéressé: "Interested",
    "Int\xE9ress\xE9 par Club Freelance ?": "How can we help?",
    "IoT / AI / Blockchain": "IoT / AI / Blockchain",
    Italian: "Italian",
    Italien: "Italian",
    "J'ai compris": "Understood",
    Japanese: "Japanese",
    Japonais: "Japanese",
    "Je cherche des freelances": "I'm looking for Talent",
    "Je cherche des missions": "I'm looking for Jobs",
    "Je cherche un consultant IT": "I am looking for an IT consultant",
    "Je cherche une mission": "I am looking for a Job",
    "Je confirme n'avoir effectu\xE9 aucun jour pour ce mois":
      "I confirm I did not work any day during this month",
    "Je m\u2019abonne": "Subscribe",
    "Je poste un besoin": "Post a vacancy",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par": "Preferred contact method",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par :":
      "I prefer to be contacted by :",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par:": "I prefer to be contacted by:",
    "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Club Freelance":
      "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Club Freelance",
    "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Club freelance":
      "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Club freelance",
    "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Mindquest Talent":
      "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Mindquest Talent",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Club Freelance (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Club Freelance (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "I would like to receive the latest information and community benefits from Mindquest Talent (webinars, events, blog, discounts, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Club Freelance (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Club Freelance (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations, offres ainsi que les avantages de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "I would like to receive the latest information, offers as well as the benefits of Mindquest Talent (webinars, events, blog, discounts, etc.)",
    "Je suis ESN": "Are you a digital services company?",
    "Je suis un Talent": "I am a Talent",
    "Je suis un freelance": "Candidates",
    "Je suis une Entreprise": "I am a company",
    "Je suis une entreprise": "Employers",
    "Jour f\xE9ri\xE9 s\xE9lectionn\xE9": "Bank holiday selected",
    Junior: "Junior",
    "Junior (0 - 3)": "Junior (0 - 3)",
    "Junior (0-3)": "Junior (0-3)",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s importants peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Up to 10 important keywords can be selected.",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s indispensables peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Add up to 10 Mandatory Keywords.",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s secondaires peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Add up to 10 Desirable Keywords",
    "J\u2019accepte <0>la politique de confidentialit\xE9s </0> Club Freelance":
      "I accept Mindquest's <0>Data Privacy policy</0>",
    "J\u2019accepte la": "I accept",
    "J\u2019occupe actuellement ce poste": "I currently hold this position",
    Kiosk: "Kiosk",
    "La date de d\xE9but doit \xEAtre toujours ant\xE9rieur \xE0 la date de fin":
      "Start date must always be prior to end date",
    "La date de fin doit \xEAtre toujours ult\xE9rieur \xE0 la date de d\xE9but":
      "The end date must always be later than the start date",
    "La garantie de paiement \xE0":
      "30-day payment guarantee for all contract jobs",
    "La garantie de paiement \xE0 30 jours":
      "30-day payment guarantee for all contract jobs",
    "La liste des mots non pris en compte":
      "The list of words not taken into account",
    "La localisation": "Location",
    "La mission se situe \xE0 {0} de cette adresse en {1} selon Google Maps": function(
      a
    ) {
      return [
        "The mission is at ",
        a("0"),
        " from this address in ",
        a("1"),
        " according to Google Maps"
      ];
    },
    "La taille de votre fichier d\xE9passe": "Your file size exceeds",
    "La taille du fichier est trop petite, veuillez s\xE9lectionner un fichier de taille sup\xE9rieur \xE0":
      "The file size is too small, please select a file size larger than",
    "Lancement de Mindquest": "Mindquest launches",
    "Lancer le matching": "Start matching",
    "Languages;": "Languages;",
    "Langues Parl\xE9es": "Spoken languages",
    "Langues parl\xE9es": "Spoken languages",
    "Le Portage salarial": "Le Portage salarial",
    "Le Timesheet de {0} {year} du contrat num\xE9ro: {placementId} a \xE9t\xE9 rejet\xE9 par le client": function(
      a
    ) {
      return [
        "Le Timesheet de ",
        a("0"),
        " ",
        a("year"),
        " du contrat num\xE9ro: ",
        a("placementId"),
        " a \xE9t\xE9 rejet\xE9 par le client"
      ];
    },
    "Le Timesheet de {0} {year} du contrat num\xE9ro: {placementId} a \xE9t\xE9 valid\xE9 par le client": function(
      a
    ) {
      return [
        "Le Timesheet de ",
        a("0"),
        " ",
        a("year"),
        " du contrat num\xE9ro: ",
        a("placementId"),
        " a \xE9t\xE9 valid\xE9 par le client"
      ];
    },
    "Le Timesheet de {0} {year} du contrat num\xE9ro: {placementId} est ouvert": function(
      a
    ) {
      return [
        "Le Timesheet de ",
        a("0"),
        " ",
        a("year"),
        " du contrat num\xE9ro: ",
        a("placementId"),
        " est ouvert"
      ];
    },
    "Le Timesheet du mois de {0} {year} pour le contrat {placementId} attend votre validation": function(
      a
    ) {
      return [
        "Le Timesheet du mois de ",
        a("0"),
        " ",
        a("year"),
        " pour le contrat ",
        a("placementId"),
        " attend votre validation"
      ];
    },
    "Le Timesheet du mois de {month} {year} pour le contrat {placementID} attend votre validation": function(
      a
    ) {
      return [
        "The Timesheet of the month ",
        a("month"),
        " ",
        a("year"),
        " of the contract ",
        a("placementID"),
        " is awaiting your validation"
      ];
    },
    "Le Timesheet du mois de {m}/{y} pour le contrat {pID} attend votre validation": function(
      a
    ) {
      return [
        "The Timesheet of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        " is waiting for your approval"
      ];
    },
    "Le Timesheet {month}/{year} de contrat numero: {placementID} est ouvert au dashboard de votre consultant": function(
      a
    ) {
      return [
        "The Timesheet ",
        a("month"),
        "/",
        a("year"),
        " for the contract number: ",
        a("placementID"),
        " is opened on your candidate\u2019s dashboard"
      ];
    },
    "Le Timesheet {m}/{y} de contrat numero: {pID} est ouvert au dashboard de votre consultant": function(
      a
    ) {
      return [
        "The Timesheet of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        " has been opened on the consultant dashboard"
      ];
    },
    "Le blog": "Resources",
    "Le budget / package": "Budget / package",
    "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs.":
      "The consultant will be qualified by our sourcing team.",
    "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs. Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin. Merci.":
      "The consultant will be qualified by our team of sourcers. We will contact you as soon as possible to discuss your needs. Thank you.",
    "Le contexte du besoin": "Context for this request",
    "Le mois de {0} {year} est termin\xE9, n'oubliez pas d'envoyer votre Timesheet": function(
      a
    ) {
      return [
        "Le mois de ",
        a("0"),
        " ",
        a("year"),
        " est termin\xE9, n'oubliez pas d'envoyer votre Timesheet"
      ];
    },
    "Le nombre d'heures doit \xEAtre entre 0 et 24":
      "The number of hours must be between 0 and 24",
    "Le nombre d'heurs doit etre entre 0 et 24":
      "The number of hours must be between 0 and 24",
    "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes":
      "The number of CVs sent by our account managers to your teams",
    "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes sur la p\xE9riode s\xE9lection\xE9e":
      "The total number of CVs our Key Account Manager sent to your team",
    "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9":
      "The number of candidates sent by our teams whom you have recruited",
    "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9 sur la p\xE9riode s\xE9lectionn\xE9e":
      "The total number of candidates you hired in the selected time period",
    "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 cet offres":
      "The number of candidates pre-selected by our Talent Community Specialists to respond to this offer",
    "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 vos offres, sur la p\xE9riode s\xE9lection\xE9e":
      "The total number of candidates our Talent Community Specialist shortlisted for your positions for the selected time period",
    "Le nombre de minutes doit \xEAtre compris entre 0 et 59":
      "The number of minutes must be between 0 and 59",
    "Le nombre d\u2019appels fait par nos Talent Community Specialist pour r\xE9pondre \xE0 cet offres":
      "The number of calls made by our Talent Community Specialists to respond to this offer",
    "Le nombre d\u2019entretiens organis\xE9es avec vos \xE9quipes et les candidats envoy\xE9s":
      "The number of interviews organised with your teams and candidates",
    "Le nombre d\u2019entretiens organis\xE9es avec vos \xE9quipes et les candidats envoy\xE9s, sur la p\xE9riode s\xE9lectionn\xE9e":
      "The number of interviews organized with your teams and the candidates sent, over the selected period",
    "Le profil recherch\xE9 (comp\xE9tences, s\xE9niorit\xE9, certifications...)":
      "Required profile",
    "Le serveur ne r\xE9pond pas":
      "It seems that our server is taking a break...",
    "Le type de contrat (CDI /Freelance)": "Contract type (Permanent/Contract)",
    "Leaving a hard copy CV at a Club Freelance recruitment event, job fair or office;":
      "Leaving a hard copy CV at a Mindquest recruitment event, job fair or office;",
    "Les dates de d\xE9marrages et de fin contrat":
      "The start and end dates of the job",
    "Les mission \xE0 r\xE9aliser": "Job objective",
    "Les {0} articles les plus lus cette semaine": function(a) {
      return ["The ", a("0"), " most read articles this week"];
    },
    "Les \xE9l\xE9ments de contexte comme la phase du projet, la composition des \xE9quipes, ou encore l\u2019organisation interne nous aident mieux comprendre vos recherches":
      "Contextual information such as the project's phase, the makeup of the team, or your organisational structure help us better understand your needs",
    "Lien non valide": "Invalid link",
    "Lieu de travail": "Work place",
    "Limited company information, their representative\u2019s information and payment and financial information and certificates included but not limited to certificate of incorporation, certificate of professional insurance;":
      "Limited company information, their representative\u2019s information and payment and financial information and certificates included but not limited to certificate of incorporation, certificate of professional insurance;",
    "Lire l\u2019article": "Read the article",
    Localisation: "Location",
    "Localisations strat\xE9giques": "Strategic locations",
    "Location of employment or workplace;":
      "Location of employment or workplace;",
    Logout: "Logout",
    Londres: "London",
    "Longueur non valide": "Invalid length",
    "L\u2019ensemble des offres ajout\xE9s dans notre syst\xE8me pour votre entreprise, sur la p\xE9riode s\xE9lectionn\xE9e":
      "All the offers added to our system for your company, over the selected period",
    "L\u2019exp\xE9rience Club Freelance racont\xE9e par les Freelances informatiques et d\xE9cideurs IT":
      "The Mindquest experience told by IT Freelancers and IT decision-makers",
    "MCC is a one-stop shop for IT career and talent acquisition advice, tech news, and in-depth analysis of industry trends. A guide for professional and business success.":
      "MCC is a one-stop shop for IT career and talent acquisition advice, tech news, and in-depth analysis of industry trends. A guide for professional and business success.",
    "Ma Certification": "My Certification",
    "Ma Formation": "My training",
    "Ma formation": "My training",
    "Ma pr\xE9sentation": "My presentation",
    "Ma recherche": "My search",
    "Marketing activities": "Marketing activities",
    "Maximisez vos chances en indiquant un intitul\xE9 clair et identifiable":
      "Maximise your chances of success by indicating an easily identifiable job title",
    "Maximisez vos changes en indiquant un intitul\xE9 clair et identifiable":
      "Maximize your exchanges by indicating a clear and identifiable title",
    Merci: "Thanks",
    "Merci d'effetuer les modifications n\xE9cessaires":
      "Please make the necessary changes",
    "Merci d'indiquer votre activit\xE9": "Please indicate your activity",
    "Merci de confirmer que vous n'avez pas travaill\xE9 ce mois":
      "Please confirm that you have not worked this month",
    "Merci de consulter votre email": "Please check your email",
    "Merci de v\xE9rifier les champs erron\xE9s":
      "Please make sure the fields are correctly filled",
    "Merci de v\xE9rifier vos informations":
      "Please make sure everything is correct",
    "Merci!": "Thank you!",
    "Merci, c\u2019est tout bon !": "Thank you, you're all set!",
    "Merci, nos \xE9quipes vont \xE9tudier votre demande et revenir vers vous dans les plus brefs d\xE9lais.":
      "Thank you, our team will review your request and get back to you shortly",
    "Mes Certifications": "My Certifications",
    "Mes Coordonnn\xE9es": "My details",
    "Mes Coordonn\xE9es": "My details",
    "Mes Formations": "My trainings",
    "Mes Softskills": "My Softskills",
    "Mes alertes": "My alerts",
    "Mes candidatures": "My applications",
    "Mes coordonn\xE9es": "My details",
    "Mes informations": "My details",
    "Mes offres archiv\xE9es": "My archived offers",
    "Mes offres en cours": "My active offers",
    "Mes talents": "My candidates",
    Message: "Message",
    "Message trop court,plus d\xE9tails sera utile pour nous":
      "Message too short, more details would be useful",
    "Mettre en favori": "Add to favourites",
    Mindquest: "Mindquest",
    "Mindquest accorde une grande importance \xE0 la recherche de partenaires pour vous accompagner dans votre vie de freelance. Pour la cr\xE9ation de votre entreprise, votre gestion comptable ou le d\xE9veloppement votre productivit\xE9, nous vous avons d\xE9nich\xE9 les meilleures offres.":
      "Mindquest places great importance on finding partners to support you in your freelance life. For the launch of your company, the management of your accounting or the development of your productivity, we have found you the best offers.",
    "Mindquest | Dashboard": "Mindquest | Dashboard",
    "Mindquest | Favoris": "Mindquest | Favorites",
    "Mindquest | Ma formation": "Mindquest | My training",
    "Mindquest | Ma recherche": "Mindquest | My search",
    "Mindquest | Mes Coordonn\xE9es": "Mindquest | My details",
    "Mindquest | Mes alertes": "Mindquest | My alerts",
    "Mindquest | Mes candidatures": "Mindquest | My applications",
    "Mindquest | Mon CV": "Mindquest | My resume",
    "Mindquest | Mon exp\xE9rience": "Mindquest | My experience",
    "Mindquest | Mon m\xE9tier": "Mindquest | My job",
    "Mindquest | Offres recommand\xE9es": "Mindquest | Recommended offers",
    Mission: "Mission",
    "Mission pour Freelances":
      "Pairing top IT talent with the most innovative brands",
    Missions: "Jobs",
    Mobilité: "Mobility",
    Modifier: "Edit",
    "Modifier le Timesheet": "Edit Timesheet",
    "Modifier mon alerte avec ces crit\xE8res":
      "Update my alert with these criteria",
    "Modifier votre profil": "Edit your profile",
    "Mod\xE8les de CV Club Freelance": "Club Freelance Resume Templates",
    "Mod\xE8les de CV Mindquest": "Mindquest Resume Templates",
    "Mon CV": "My resume",
    "Mon besoin": "My request",
    "Mon entreprise": "My company",
    "Mon exp\xE9rience": "My experience",
    "Mon m\xE9tier": "My job",
    "Mon profil": "My Resume",
    "More articles": "More articles",
    "Mot de passe": "Password",
    "Mot de passe erron\xE9": "Wrong password",
    "Mot de passe oubli\xE9": "Forgot your password",
    "Moteurs de recherche": "Search engine",
    "Mots-cl\xE9s importants": "Desirable Keywords",
    "Mots-cl\xE9s indispensables*": "Mandatory Keywords*",
    "Moyenne entreprise (51-100)": "Medium company (51-100)",
    "Multidisciplinary pools of high-calibre candidates":
      "Access our multidisciplinary pool of high-calibre candidates",
    "Mutuelle, formation, cr\xE9dit bancaire... d\xE9couvrez les offres privil\xE8gi\xE9es dont vous pouvez b\xE9n\xE9ficier aupr\xE8s de nos partenaires.":
      "Mutual, training, bank credit ... discover the privileged offers you can benefit from with our partners.",
    "M\xE9thode recommand\xE9e": "Recommended method",
    "N/A": "N/A",
    "Ne cherchez plus !": "Look no further",
    Newsletter: "Newsletter",
    Newsstand: "Newsstand",
    "Niveau d'exp\xE9rience": "Level of experience",
    "Niveau de s\xE9curit\xE9": "Security level",
    "No fee usually required": "No fee usually required",
    Nom: "Last name",
    "Nom de famille": "Last name",
    "Nom de la certification": "Certification name",
    "Nom de l\u2019entreprise": "Company's name",
    "Nom du diplome": "Name of diploma",
    "Nom du r\xE9f\xE9rent": "Referrer's last name",
    "Nom et pr\xE9nom": "Name and last name",
    "Nombre non valide": "Invalid number",
    Non: "No",
    "Non Merci": "No, Thanks",
    "Non mentionn\xE9": "Not mentioned",
    "Non pr\xE9cis": "Not mentioned",
    "Non renseign\xE9": "Not specified",
    "Nos Partennaires": "Our Partners",
    "Nos bureaux": "Our offices",
    "Nos chiffres clefs": "Our figures and key data",
    "Nos conseils en vid\xE9o": "Our vide advice",
    "Nos conseils pour les freelance": "Our tips for IT pros",
    "Nos conseils pour recruter en freelance": "Our tips to recruit top talent",
    "Nos partenaires": "Nos partenaires",
    "Nos partenaires et nous d\xE9posons des cookies afin d'assurer la s\xE9curit\xE9, am\xE9liorer votre exp\xE9rience digitale et afficher des publicit\xE9s et contenus personnalis\xE9s Vous pouvez accepter ou refuser ces diff\xE9rentes op\xE9rations. Par ailleurs, conform\xE9ment \xE0 la l\xE9gislation fran\xE7aise, les cookies de mesure d'audience peuvent \xEAtre exempt\xE9s du recueil du consentement de l'internaute. En l'esp\xE8ce et sur nos sites, nos cookies Web Analytics sont dispens\xE9s du recueil du consentement. G\xE9rer vos pr\xE9f\xE9rences AT Internet":
      "Our partners and we place cookies in order to ensure security, improve your digital experience and display personalized advertising and content. You can accept or refuse these various operations. In addition, in accordance with French law, audience measurement cookies may be exempt from the collection of the user's consent. In this case and on our sites, our Web Analytics cookies are exempt from the collection of consent. Manage your AT Internet preferences",
    "Nos \xE9quipes innovation et support travaillent \xE0 l\u2019optimisation constante de nos outils et process pour vous proposer la meilleure exp\xE9rience possible.":
      "Our innovation and support teams are constantly optimising our tools and processes to provide our partners with the best possible experience.",
    "Nos \xE9quipes sont en contact permanent avec entreprises et freelances, permettant une connaissance pointue du march\xE9":
      "Our teams are in constant contact with professionals and businesses, providing both sides with valuable insights",
    "Nos \xE9quipes vous accompagnent pour recruter & int\xE9grer les meilleurs freelances IT pour vos projets. Solution compl\xE8te de Talent Management et conseils personnalis\xE9s":
      "Our teams support you to recruit and integrate the best IT candidates for your projects. Complete Talent Management solution and personalized advice",
    "Not Mentioned": "Not Mentioned",
    "Note: ce bilan d'activit\xE9 sera visible par le client sur votre compte-rendu de Timesheet":
      "Note: this activity report will be visible to the employer on your Timesheet report",
    "Notre accompagnent": "Our support",
    "Notre algorithme de matching SMATCH est en pleine de recherche du profils parfait au sein de nos communaut\xE9s":
      "Our SMATCH matching algorithm is currently searching for the perfect profile within our communities",
    "Notre algorithme de matching SMATCH est en pleine recherche de profils correspondants au sein de nos communaut\xE9s":
      "Notre algorithme de matching SMATCH est en pleine recherche de profils correspondants au sein de nos communaut\xE9s",
    "Notre mission est d\u2019une part d\u2019aider nos entreprises clientes \xE0 attirer et fid\xE9liser les meilleurs talents pour r\xE9pondre \xE0 leurs besoins strat\xE9giques, et d\u2019autre part d\u2019accompagner nos consultants dans leur carri\xE8re en les aidant \xE0 trouver les missions les plus adapt\xE9es \xE0 leur profil et leurs aspirations.":
      "Using a balanced combination of search methodologies and digital tools, we have developed a global talent pipeline that comprises a vast network of cross-functional and specialised candidates from the technology space.",
    "Notre newsletter bimensuelle comporte nos derniers articles, nos plus belles missions et du contenu sp\xE9cialement s\xE9lectionn\xE9 pour vous":
      "Our weekly newsletter comes packed with our latest career resources, a look at the week in tech news and much more",
    "Notre performance en chiffres": "Our performance, in numbers",
    "Notre service de facturation fera correspondre les heures normales et sp\xE9ciales avec les tarifs d\xE9finis dans votre contrat":
      "Our Invoicing service will match normal and special hours with the rates defined in your contract",
    "Nous accordons une grande place \xE0 l\u2019humain et nouons des relations de long terme avec nos clients et nos consultants.":
      "We don\u2019t just build databases, we build communities : driven by candidate and employer insights and fuelled by vision and analytics. This creates an environment which fosters deeper relationships, reduces attrition and bridges the gap between Europe's most brilliant minds and innovative brands.",
    "Nous b\xE9n\xE9ficions d\u2019une connaissance approfondie des process m\xE9tiers par secteur d\u2019activit\xE9, et des comp\xE9tences techniques par domaine d\u2019expertise":
      "We meet both your technical and your industry-specific needs",
    "Nous contacter": "Contact us",
    "Nous couvrons tous les m\xE9tiers de la technologie et de l\u2019informatique":
      "Access our multidisciplinary pool of high-calibre candidates",
    "Nous identifions les esprits brillants et les associons avec les marques les plus innovantes.":
      "Connecting Europe\u2019s top IT talent with the most innovative brands",
    "Nous ne construisons pas des bases de donn\xE9es, nous construisons des communaut\xE9s. D\u2019o\xF9 des relations de proximit\xE9 avec nos consultants ind\xE9pendants, et une connaissance pointue des profils disponibles sur le march\xE9.":
      "We don't build databases, we build communities. It\u2019s thanks to our close relationship with our candidates that we can provide you with unique insight into the state of the IT talent market.",
    "Nous n\u2019avons pas pu g\xE9n\xE9rer le fichier demand\xE9":
      "We couldn't generate the requested file",
    "Nous rejoindre": "Join our team",
    "Nous sommes d\xE9sol\xE9, l'offre que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "We are sorry, but the offer you are looking for doesn't exist or is no longer available",
    "Nous sommes d\xE9sol\xE9, l'opportunit\xE9 que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "We are sorry, but the opportunity you are looking for doesn't exist or is no longer available",
    "Nous sommes d\xE9sol\xE9, la mission que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Apologies. The vacancy you are looking for does not exist or is no longer available",
    "Nous sommes d\xE9sol\xE9, le candidat que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "We are sorry, the candidate you are looking for does not exist or is no longer available",
    "Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin.":
      "We will contact you as soon as possible to discuss your needs.",
    "Nous vous recommandons fortement d\u2019utiliser le format Word.":
      "We strongly recommend using a document in Word format",
    "Nous y r\xE9pondrons dans les plus brefs d\xE9lais":
      "We will respond as soon as possible",
    "Nouveau Timesheet en attente": "New Timesheet pending",
    "Nouveau compte-rendu disponible pour le contrat {placementId}": function(
      a
    ) {
      return [
        "Nouveau compte-rendu disponible pour le contrat ",
        a("placementId")
      ];
    },
    "Nouveau valideur pour le contrat {placementId}": function(a) {
      return ["Nouveau valideur pour le contrat ", a("placementId")];
    },
    "Nouveaux profils": "New profiles",
    Nouvelles: "News",
    "Num\xE9ro non valide": "Invalid number",
    Néerlandais: "Dutch",
    OU: "Or",
    "Obtenir un lien partageable": "Get a shareable link",
    "Obtenir votre lien partageable": "Get your shareable link",
    "Obtenues sur cette mission": "obtained on this mission ",
    Offre: "Offer",
    Offres: "Offers",
    "Offres archiv\xE9es": "Closed Vacancies",
    "Offres en cours": "Open Vacancies",
    "Offres post\xE9es": "Posted Vacancies",
    "Offres recommand\xE9es": "Recommended offers",
    "Offres similaires": "Similar Vacancies",
    Ok: "Ok",
    "On parle de nous": "On parle de nous",
    "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre mission":
      "We guide you through the interview process",
    "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre nouveau job !":
      "We take care of the paperwork, you focus on a fresh start",
    "One of the GDPR\u2019s main objectives is to protect and clarify the rights of EU citizens and individuals in the EU with regards to data privacy. This means that you retain various rights in respect of your data, even once you have given it to us. These are described in more detail below.":
      "One of GDPR\u2019s main objectives is to protect and clarify the rights of EU citizens and individuals in the EU with regards to data privacy. This means that you retain various rights in respect of your data, even once you have given it to us. These are described in more detail below.",
    "Oops!": "Oops!",
    "Organisme de d\xE9livrance": "Training institution",
    "Other information relevant to help us provide recruitment services;":
      "Other relavant information to help us provide recruitment services;",
    Ou: "Or",
    Oui: "Yes",
    "Oups! il semblerait que vos donn\xE9es aient du mal \xE0 se synchroniser. Veuillez rafraichir la page dans un moment":
      "Oups! it seems that data has trouble downloading. Please try refreshing the page in a moment",
    "Our Clients may share personal information about you with us;":
      "Our Clients may share personal information about you with us;",
    "Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.":
      "Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.",
    "Ouvert aux opportunit\xE9s": "Open To new opportunity",
    Ouverte: "Open",
    Ouverts: "Open",
    "Ouverture du Sourcing Hub \xE0 Barcelone":
      "Our Sourcing Hub opens in Barcelona",
    "Ouverture du bureau commercial \xE0 paris":
      "Opening of the Paris sales office",
    "PMO BI": "PMO BI",
    Par: "By",
    "Par an": "Per year",
    "Par jour": "By day",
    Paris: "Paris",
    Partager: "Share",
    "Partager l\u2019article": "Partager l\u2019article",
    "Pas d\u2019activit\xE9 jusqu\u2019\xE0 maintenant": "No activity so far",
    "Pas d\u2019activit\xE9 pendant la periode s\xE9lectionn\xE9e":
      "No activity found for the selected period",
    "Passer cette \xE9tape": "Skip this step",
    "Pensez \xE0 consulter vos courriers ind\xE9sirables":
      "Make sure to check your spam/junk mail folder",
    "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: {pID} de {m}/{y}": function(
      a
    ) {
      return [
        "Make sure to fill out your Timesheet: ",
        a("pID"),
        " of ",
        a("m"),
        "/",
        a("y")
      ];
    },
    "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: {placementID} de {month}/{year}": function(
      a
    ) {
      return [
        "Make sure to fill out your Timesheet: ",
        a("placementID"),
        " of ",
        a("month"),
        "/",
        a("year")
      ];
    },
    "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: {placementId} de {0} {year}": function(
      a
    ) {
      return [
        "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: ",
        a("placementId"),
        " de ",
        a("0"),
        " ",
        a("year")
      ];
    },
    "Pens\xE9s pour les freelances.": "We\u2019ve got you covered",
    Permanent: "Permanent",
    "Personal contact details of company employees we are in contact with such as name, title, work addresses, telephone numbers, work email addresses, languages and skills;":
      "Personal contact details of company employees we are in contact with such as name, title, work addresses, telephone numbers, work email addresses, languages and skills;",
    "Personal contact details such as name, title, addresses, telephone numbers, personal and work email addresses, Skype or other web ID;":
      "Personal contact details such as name, title, addresses, telephone numbers, personal and work email addresses, Skype or other web ID;",
    "Personal data that we receive from other sources.":
      "Personal data that we receive from other sources.",
    "Personal data that you, as the Candidate or Client, give to us; and":
      "Personal data that you, as the Candidate or Client, give to us; and",
    "Personal data we receive from other sources":
      "Personal data we receive from other sources",
    "Personal data you give to us": "Personal data you give to us",
    "Petite entreprise (1-50)": "Small company (1-50)",
    Placement: "Hires",
    Placements: "Hires",
    "Placements r\xE9ussis chez nos clients":
      "Successful hires across more than 100 happy clients",
    "Please read the following carefully to understand our views and practices regarding your personal data, how we will treat it and your rights.":
      "Please read the following information carefully to understand our views and practices regarding your personal data, how we will treat it and your rights.",
    "Plus d\u2019expertise": "More specialties",
    Polish: "Polish",
    Polonais: "Polish",
    "Portage Salarial": "Wage portage ",
    "Portfolio, CV en ligne, site personnel":
      "Portfolio, online resume, personal website",
    Portugais: "Portuguese",
    Portuguese: "Portuguese",
    "Poste actuel": "Current position",
    "Poster un besoin via le formulaire": "Post a vacancy via our form",
    "Poster un besoin via une fiche de poste":
      "Post an offer with a job description",
    "Poster une offre": "Post an offer",
    Postuler: "Apply",
    Postulé: "Applied",
    "Pour activer les fonctionnalit\xE9s de timesheet, vous devez contractualiser avec nos services.":
      "To unlock the timesheet feature, you have to contract on our consultant services.",
    "Pour activer les fonctionnalit\xE9s du Timesheets, vous devez contractualiser avec nos services.":
      "To activate the Timesheets features, you have to deal with our services.",
    "Pour d\xE9bloquer les fonctionnalit\xE9s de timesheet, vous devez d\xE9marrer une mission via nos services.":
      "To unlock the timesheet feature, you have to start a misssion on our consultant services.",
    "Pour d\xE9bloquer les fonctionnalit\xE9s du Timesheets, vous devez d\xE9marrer une mission via nos services.":
      "To unlock the Timesheet feature, you must start a contract job through our services.",
    "Pour d\xE9bloquer les fonctionnalit\xE9s du Timesheets, vous devez red\xE9marrer une mission via nos services.":
      "To unlock the functionality of the Timesheets, you have to restart a mission through our services",
    'Pour enregistrer les donn\xE9es saisies, fermez cette fen\xEAtre en cliquant sur "annuler" puis cliquez sur le bouton "enregistrer" \xE0 la fin du formulaire.':
      "To save the data entered, close this window by clicking on cancel then click on the  save button at the end of the form.",
    "Pour faciliter vos \xE9changes avec nous, n\u2019h\xE9sitez pas \xE0 cr\xE9er un compte":
      "Create an account to stay in touch and up-to-date with the latest developments regarding your processes",
    "Pour indiquer que vous avez effectivement travaill\xE9 ce jour et \xE0 votre TJM habituel, cliquez sur continuer.":
      "To indicate a workday at your usual rate please click on Continue.",
    "Pour ne manquer aucun article": "Be in the know",
    "Pour obtenir imm\xE9diatement des nouveaux talents":
      "Receive new candidate alerts in real time",
    "Pour obtenir imm\xE9diatement des offres personnalis\xE9es.":
      "Receive personalised offers right away",
    "Pour recevoir des profils, soumettez une offre.":
      "Please post an offer to start receiving candidate recommendations",
    "Privacy Policy - Club Freelance": "Privacy Policy - Club Freelance",
    "Privacy policy": "Privacy policy",
    "Prochain rendez-vous": "Other meetings",
    "Profil anonymis\xE9": "Anonymised profile",
    "Profil recherch\xE9": "Required profile",
    "Profils refus\xE9s": "Discarded profiles",
    "Profils \xE0 rencontrer": "Profiles to vet",
    "Profitez des avantages que nous avons n\xE9goci\xE9s pour vous":
      "Take advantage of the benefits we have negotiated for you",
    Projet: "Project",
    Proposition: "Proposal",
    "Providing us with a copy of your CV or by corresponding with us by post, phone, email or otherwise or being interviewed by us;":
      "Providing us with a copy of your CV or by corresponding with us by post, phone, email or otherwise or being interviewed by us;",
    "Pr\xE9ciser la date": "Specify the date",
    "Pr\xE9ciser le nombre d'heures": "Specify the number of hours",
    "Pr\xE9ciser le nombre de minutes": "Specify the number of minutes",
    "Pr\xE9ciser le type": "Specify the type",
    Prénom: "First name",
    "Pr\xE9nom du r\xE9f\xE9rent": "Referrer's first name",
    "Pr\xE9nom et Nom": "Name and Last Name",
    Présentiel: "On-site",
    "Pr\xEAt \xE0 trouver une mission ?": "Ready to explore new opportunities?",
    "Pr\xEAt \xE0 trouver une nouvelle mission ?":
      "Ready to continue the quest?",
    "Publi\xE9 le": "Published",
    Période: "Time range",
    "Quel est votre domaine ?": "What is your area of expertise?",
    "Quel est votre statut": "Quel est votre statut",
    "Quelle est votre statut": "What is your status",
    "Quels contenus aimeriez vous recevoir":
      "What type of content would you like to receive?",
    "Qui sommes nous?": "About us",
    "Qui vous correspond": "We are.",
    "Quick and interesting reads for commuters and curious minds. Tech, IT and pop culture merge in this section for a quick break from work at any time of the day.":
      "Quick and interesting reads for commuters and curious minds. Tech, IT and pop culture merge in this section for a quick break from work at any time of the day.",
    Quicktip: "Quicktip",
    "Rafra\xEEchissez la page": "Refresh the page",
    Rappel: "Reminder",
    "Ravis de vous revoir": "Welcome back",
    "ReCAPCHA non valid": "Invalid ReCAPCHA",
    "Recevez des offres adapt\xE9es \xE0 vos crit\xE8res":
      "Start receiving offers in line with your professional and personal aspirations",
    "Recevoir la newsletter Club Freelance.": "Receive Mindquest's newsletter",
    "Recevoir notre newsletter bimensuelle sp\xE9ciale Tech & IT (conseils pratiques, dossiers sp\xE9ciaux, s\xE9lection de missions, etc.).":
      "Recevoir notre newsletter bimensuelle sp\xE9ciale Tech & IT (conseils pratiques, dossiers sp\xE9ciaux, s\xE9lection de missions, etc.).",
    Recherche: "Search",
    Rechercher: "Search",
    "Rechercher des articles, des vid\xE9os et des ressources":
      "Search for all our articles and resources",
    "Rechercher un consultant": "Find a consultant",
    "Rechercher un secteur d\u2019activit\xE9": "Search for a business sector",
    "Rechercher un talent": "Find a consultant",
    "Rechercher...": "Search...",
    "Recherchez les consultants gr\xE2ce au contenu de votre fiche de poste ou en ajoutant directement votre mots cl\xE9s IT":
      "Look for your future consultant using your job specifications",
    "Recherchez un article au sein de notre s\xE9lection pour Freelance informatiques et D\xE9cideurs IT":
      "Search an article within our selection for IT profesionals",
    "Recrutez les meilleurs talents tech & IT":
      "Engage Europe\u2019s best IT professionals ",
    "References from past employers;": "References from past employers;",
    "Rejeter le timesheet": "Reject the timesheet",
    Rejetée: "Rejected",
    Rejetés: "Rejected",
    "Rejoignez la communaut\xE9 des Freelances IT": "Join the quest",
    "Rejoignez le Club": "Join the quest",
    "Rejoignez le club": "Join the quest",
    "Rejoignez le club et trouvez<0/>votre prochaine mission freelance IT":
      "Join the Mindquest community and <0/>take your career to new heights",
    "Rejoignez le club pour recevoir<0/>des offres adapt\xE9es \xE0 vos envies.":
      "Join the Mindquest community to start receiving<0/>offers that meet all your needs",
    "Rejoindre Club Freelance": "Join the quest",
    "Rejoindre Mission Control Center et trouver les meilleurs id\xE9es de IT professionnel":
      "Join Mission Control Center and beneftit from pointed and actionable insights for IT professionals",
    "Rejoindre le club": "Join the quest",
    "Rejoindre le club en 3 minutes": "Sign up in less than 3 minutes",
    "Rejoindre une communaut\xE9 de freelances":
      "The opportunity to join a unique talent community",
    "Rejoindre une communaut\xE9<0/>de freelances":
      "The opportunity to join <0/>a unique talent community",
    Remote: "Remote",
    "Remote partiel": "Part-time remote",
    "Remote uniquement": "Exclusively remote",
    "Remote/Pr\xE9sentiel": "Remote/On-site",
    "Remote/R\xE9gie": "Remote/On-site",
    "Remplir la timesheet": "Fill in the timesheet",
    "Remplir le Timesheet": "Complete the Timesheet",
    "Renvoyer l\u2019email de validation": "Resend validation email",
    Ressources: "Resources",
    Retour: "Back",
    "Retour au dashboard": "Back to dashboard",
    "Retour au site": "Back to home",
    "Retour d\u2019exp\xE9rience": "Feedback",
    "Retourner a la mission": "Back to Vacancy",
    "Retrouvez des offres s\xE9lectionn\xE9s pour vous":
      "Explore exciting offers selected just for you",
    "Retrouvez des talents s\xE9lectionn\xE9s pour vous":
      "Explore your candidate shortlists",
    "Retrouvez nos offres pour consultants IT : Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...":
      "Find our offers for IT consultants: Data, Development, Cloud, Project management, CRM, Support, BI, Webdesign, ERP, SAP, Oracle ...",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources",
    "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT":
      "Find all the news of Mindquest and our selection of articles for IT Candidates and IT decision-makers",
    "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT.":
      "Find all the news of Club Mindquest and our selection of articles for IT Freelancers and IT decision-makers",
    "Revenir \xE0 mes mots cl\xE9s": "Go back to my keywords",
    Revenue: "In revenue",
    "Right to work status / citizenship;":
      "Right to work status / citizenship;",
    Romanian: "Romanian",
    Roumain: "Romanian",
    Russe: "Russian",
    Russian: "Russian",
    "Rythme de travail": "Work rate",
    Récence: "Work pace",
    "R\xE9cup\xE9rez des mots-cl\xE9s IT": "Extract IT Keywords",
    Référence: "Reference",
    "R\xE9f\xE9rence du bon de commande": "Purchase order reference",
    "R\xE9f\xE9rence mis \xE0 jour avec succ\xE8s":
      "Reference successfully updated",
    Références: "Reference",
    Régie: "On-site",
    "R\xE9gie uniquement": "Exclusively on-site",
    Réinitialiser: "Reset",
    "R\xE9initialiser votre mot de passe": "Reset your password",
    "R\xE9initialisez votre mot de passe": "Reset your password",
    "R\xE9seaux sociaux": "Social networks",
    "S1:": "A1:",
    "S2:": "A2:",
    SAP: "SAP",
    "SAP SD MM Project Manager": "SAP SD MM Project Manager",
    SASU: "SASU",
    SMS: "SMS",
    Salaire: "Salary",
    "Salaire Annuel": "Annual Salary",
    "Salaire annuel": "Annual salary",
    Salarié: "Permanent",
    "Salut {firstName} {lastName}": function(a) {
      return ["Hello ", a("firstName"), " ", a("lastName")];
    },
    Sauvegarder: "save",
    "Score Smatch": "Smatch Score",
    "Se connecter": "Login",
    "Se d\xE9connecter": "Log out",
    "Se souvenir de moi": "Remember me",
    "Search for all our articles and resources":
      "Search for all our articles and resources",
    Secteur: "Industry",
    "Secteur d\u2019activit\xE9": "Business sector",
    "Selected third parties including:": "Selected third parties including:",
    Senior: "Senior",
    "Senior (7 - 10)": "Senior (7 - 10)",
    "Senior (7-10)": "Senior",
    "Sex/Gender;": "Sex/Gender;",
    "Short sci-fi stories posted every month to promote an open debate on the future of tech.":
      "Short sci-fi stories posted every month to promote an open debate on the future of tech.",
    "Si nous ne sommes pas en mesure de vous accompagner dans cette recherche, vous serez inform\xE9s par email.":
      "If we are not able to assist you in this search, we will inform you via email.",
    "Si vous avez travaill\xE9 \xE0 un taux major\xE9 (astreintes, heures suppl\xE9mentaires major\xE9es...), merci d'utiliser l'espace heures et astreintes \xE0 droite.":
      "If you worked under an increased rate, please use the Hours & Penalties on the right-hand side.",
    "Si vous n\u2019\xEAtes pas redirig\xE9 dans 3mn, merci de nous contacter":
      "If you are not redirected in the next 3 minutes, please contact us",
    "Si vous \xEAtes interress\xE9 par ce profil ou un candidat similaire, nous vous invitons \xE0 soumettre une nouvelle offre ou \xE0 nous contacter":
      "If you are interested in this candidate or a similar profile, we invite you to submit a new offer or to contact us",
    "Signataire principal": "Principal Signatory",
    Signataires: "Signatories",
    "Signataires secondaires": "Secondary signatories",
    Signatures: "Signatures",
    "Sinon cliquez sur continuer.": "Otherwise click on continue.",
    "Sit back and relax. We're here to help you build the dream team for business success.":
      "Sit back and relax. We\u2019ve got you covered with the latest recruitment tips and emerging trends.",
    "Sit back, relax and enjoy our in-depth articles on IT talent management and industry trends. We're here to help you build the best possible team for business success.":
      "Sit back, relax and enjoy our in-depth articles on IT talent management and industry trends. We're here to help you build the best possible team for business success.",
    Soumettre: "Submit",
    "Soumettre au client": "Submit to client",
    "Sourcing Hub": "Sourcing Hub",
    "Sourcing Manager": "Sourcing Manager",
    "Soyez aussi complet que possible. La mise en valeur de vos comp\xE9tences et de votre profil augmentera vos chances de rencontrer l\u2019emploi de vos r\xEAves":
      "Be as comprehensive as you can. The more of your skills and talents you can showcase, the greater the chance of us getting you that dream job",
    Spanish: "Spanish",
    Spécialité: "Specialty",
    Spécialités: "Specialties",
    Statistiques: "Statistics",
    Subscribe: "Subscribe",
    "Subscribe to our weekly newsletter to get the latest articles and technology news":
      "Subscribe to our weekly newsletter to get the latest articles and technology news",
    "Success Stories": "Success Stories",
    "Successful professional matches": "Successful placements",
    Succès: "Success",
    "Suite \xE0 la validation du Timesheet, le compte-rendu de mois {m}/{y} est disponible": function(
      a
    ) {
      return [
        "Following the timesheet approval of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        ", the report is available"
      ];
    },
    "Suite \xE0 la validation du Timesheet, le compte-rendu du de mois de {0} {year} est disponible": function(
      a
    ) {
      return [
        "Following the validation of the Timesheet, the report for the month of ",
        a("0"),
        " ",
        a("year"),
        " is available"
      ];
    },
    "Suite \xE0 la validation du Timesheet, le compte-rendu du de mois de {month}/{year} est disponible": function(
      a
    ) {
      return [
        "Following the validation of the Timesheet, the report for the month of ",
        a("month"),
        " / ",
        a("year"),
        " is available"
      ];
    },
    "Suivez toutes vos candidatures":
      "Track the progress of all your applications",
    "Suivez toutes vos missions": "Track all your vacancies",
    "Suivez vos candidatures dans votre espace personel":
      "Track the progress of all your applications",
    Supprimer: "Delete",
    "Syst\xE8mes Embarqu\xE9s": "Embedded systems",
    "S\xE9curit\xE9 des Syst\xE8mes d'Information":
      "Information Systems Security",
    "S\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil. Accompagnement personnalis\xE9. Paiements \xE0 30 jours. Pour tous les Freelances du digital, de la tech et de l\u2019IT":
      "Selection of offers tailored to your profile. Personalized support. Payments at 30 days. For all digital, tech and IT candidates",
    "S\xE9lectionner depuis la liste ou utiliser la detection automatique":
      "Select from list or try the Auto-detect",
    "S\xE9lectionner des certifications": "Select certifications",
    "S\xE9lectionner des comp\xE9tences": "Select skills",
    "S\xE9lectionner des domaines": "Select areas",
    "S\xE9lectionner des langues": "Select languages",
    "S\xE9lectionner des secteurs": "Select sectors",
    "S\xE9lectionner des sp\xE9cialit\xE9s": "Select specialties",
    "S\xE9lectionner l'exp\xE9rience demand\xE9e": "Select required experience",
    "S\xE9lectionner un secteur": "Select industry",
    "S\xE9lectionnez la zone avant d'ajouter les mots-cl\xE9s":
      "Click on the field before selecting keywords",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV":
      "Your Favourite Resume will be accessible directly from your Dashboard",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser":
      "Select your template and generate a CV with a modern design, adapted to the recruiters and easy to distribute",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser.":
      "Select your template and generate a CV with a modern design, adapted to the recruiters and easy to distribute",
    "Taille de l\u2019entreprise": "Company's size",
    "Talent Community Specialist": "Talent Community Specialist",
    Talents: "Talent",
    "Talents recommand\xE9s": "Recommended candidates",
    "Taux journalier moyen": "Average daily rate",
    "Tech and pop culture merge for a quick break from work at any time of the day.":
      "Tech and pop culture meet for a quick break from work at any time of the day.",
    "Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, geographical location, domain name from which you access the internet, browser type, and version;":
      "Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, geographical location, domain name from which you access the internet, browser type, and version;",
    "Technologies Microsoft": "Microsoft Technologies",
    "Temps partiel": "Part-time",
    "Temps plein": "Full-time",
    "Test & Assurance Qualit\xE9": "Testing & Quality Assurance",
    "The GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Club Freelance at contact@club-freelance.com.":
      "GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Club Freelance at contact@club-freelance.com.",
    "The right of access to the personal data Club Freelance processes on you:":
      "The right of access to the personal data Club Freelance processes on you:",
    "The right to complain": "The right to complain",
    "The right to data portability in certain circumstances:":
      "The right to data portability in certain circumstances:",
    "The right to erasure of your personal data in certain circumstances:":
      "The right to erasure of your personal data in certain circumstances:",
    "The right to object to the processing of your personal data that was based on our legitimate interest or for direct marketing purposes:":
      "The right to object to the processing of your personal data that was based on our legitimate interest or for direct marketing purposes:",
    "The right to rectification of your personal data:":
      "The right to rectification of your personal data:",
    "The right to withdraw consent:": "The right to withdraw consent:",
    "Their IT and human expertise led to the birth of Club Freelance. It can also help you.":
      "Their IT and human expertise led to the birth of Mindquest. It can also help you.",
    "There are numerous ways you can share your information with us - it all depends on what suits you. These may include:":
      "There are numerous ways you can share your information with us - it all depends on what suits you. These may include:",
    "These companies trust us with their IT talent needs":
      "These companies trust us with their IT talent needs",
    "Third-party links": "Third-party links",
    "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Club Freelance telephonically, electronically (including email) and in person.":
      "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Club Freelance telephonically, electronically (including email) and in person.",
    "This Privacy Policy was last updated in January 2019. The Privacy policy may be updated from time to time and should be read in the context of any additional specific information such as that provided in privacy policies applicable to specific businesses or local areas as displayed on the relevant Website or distributed to you from time to time.":
      "This Privacy Policy was last updated in January 2019. The Privacy policy may be updated from time to time and should be read in the context of any additional specific information such as that provided in privacy policies applicable to specific businesses or local areas as displayed on the relevant Website or distributed to you from time to time.",
    "This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).":
      "This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).",
    "This enables you to ask us to suspend the processing of personal information about you, in certain circumstances for example if you want us to establish its accuracy or the reason for processing it.":
      "This enables you to ask us to suspend the processing of personal information about you, in certain circumstances for example if you want us to establish its accuracy or the reason for processing it.",
    "This right enables you to object to us processing your personal data including where we do so for our legitimate interests, or for direct marketing purposes.":
      "This right enables you to object to us processing your personal data including where we do so for our legitimate interests, or for direct marketing purposes.",
    "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Club Freelance, and it can also help you.":
      "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Club Freelance, and it can also help you.",
    "Time limit to respond": "Time limit to respond",
    Timesheet: "Timesheet",
    "Timesheet Accept\xE9": "Timesheet Accepted",
    "Timesheet Ouvert": "Timesheet Open",
    "Timesheet Rejet\xE9": "Timesheet Rejected",
    "Timesheet Soumis": "Timesheet Submitted",
    "Timesheet envoy\xE9 au client": "Timesheet submission",
    "Timesheet vide": "Timesheet blank",
    "Timesheet {month}/{year} du contrat num\xE9ro: {placementID} a \xE9t\xE9 rejet\xE9 par le client. Merci d'effetuer les modifications n\xE9cessaires": function(
      a
    ) {
      return [
        "Timesheet ",
        a("month"),
        "/",
        a("year"),
        " of contract number: ",
        a("placementID"),
        " was rejected by the client. Please make the necessary changes"
      ];
    },
    "Timesheet {month}/{year} du contrat num\xE9ro: {placementID} a \xE9t\xE9 valid\xE9 par le client": function(
      a
    ) {
      return [
        "Timesheet ",
        a("month"),
        "/",
        a("year"),
        " of contract number: ",
        a("placementID"),
        " was validated by the client"
      ];
    },
    "Timesheet {m}/{y} du contrat num\xE9ro: {pID} a \xE9t\xE9 rejet\xE9 par le client. Merci d'effetuer les modifications n\xE9cessaires": function(
      a
    ) {
      return [
        "The timesheet of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        " has been rejected, please make the appropriate changes"
      ];
    },
    "Timesheet {m}/{y} du contrat num\xE9ro: {pID} a \xE9t\xE9 valid\xE9 par le client": function(
      a
    ) {
      return [
        "The timesheet of ",
        a("m"),
        "/",
        a("y"),
        " for the contract ",
        a("pID"),
        " has been validated"
      ];
    },
    Timesheets: "Timesheets",
    "Tips & Tricks": "Tips & Tricks",
    Titre: "Title",
    "To answer any questions you have about a job or the job market;":
      "To answer any questions you have about a job or the job market;",
    "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Club Freelance;":
      "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Club Freelance;",
    "To collect any money due, or allegedly due, to Club Freelance or any Club Freelance client (or Club Freelance client\u2019s client);":
      "To collect any money due, or allegedly due, to Club Freelance or any Club Freelance client (or Club Freelance client\u2019s client);",
    "To communicate with you after your company has retained a Club Freelance candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;":
      "To communicate with you after your company has retained a Club Freelance candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;",
    "To communicate with you or your company after you have started a job to make sure all is going well or to remedy, or attempt to remedy, any problems;":
      "To communicate with you or your company after you have started a job to make sure all is going well or to remedy, or attempt to remedy, any problems;",
    "To contact you about candidates for jobs with whom Club Freelance has a relationship;":
      "To contact you about candidates for jobs with whom Club Freelance has a relationship;",
    "To contact you or your company about jobs that Club Freelance is filling or may fill for Club Freelance clients;":
      "To contact you or your company about jobs that Club Freelance is filling or may fill for Club Freelance clients;",
    "To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.":
      "To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.",
    "To establish, exercise or defend any legal claims.":
      "To establish, exercise or defend any legal claims.",
    "To establish, exercise or defend any legal claims; and To assist you or your company if you are dissatisfied or dislike the job, or any aspect of it.":
      "To establish, exercise or defend any legal claims; and To assist you or your company if you are dissatisfied or dislike the job, or any aspect of it.",
    "To fill an open vacancy at your company;":
      "To fill an open vacancy at your company;",
    "To fulfill any aspect your company\u2019s contract with Club Freelance;":
      "To fulfill any aspect your company\u2019s contract with Club Freelance;",
    "To help find you a job;": "To help find you a job;",
    "To maintain, expand and develop our business we need to record the personal data of prospective candidates and client contacts.":
      "To maintain, expand and develop our business we need to record the personal data of prospective candidates and client contacts.",
    "To negotiate and fulfill any aspect of your company\u2019s contract with Club Freelance;":
      "To negotiate and fulfill any aspect of your company\u2019s contract with Club Freelance;",
    "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Club Freelance or any Club Freelance client (or Club Freelance\u2019s client\u2019s client);":
      "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Club Freelance or any Club Freelance client (or Club Freelance\u2019s client\u2019s client);",
    "To provide you or your company with information about the job market;":
      "To provide you or your company with information about the job market;",
    "To provide you with information about the job market;":
      "To provide you with information about the job market;",
    "To resolve any issue with the issuance, payment, collection or enforcement of a Club Freelance invoice;":
      "To resolve any issue with the issuance, payment, collection or enforcement of a Club Freelance invoice;",
    Total: "Total",
    Totale: "Total",
    Tous: "All",
    "Tous les talents": "All Candidates",
    "Tous nos conseils pour les freelances.":
      "All our tips for IT professionals",
    "Toutes les offres archiv\xE9es": "All archived vacancies",
    "Toutes les offres en cours": "All active offers",
    Travaillées: "Worked",
    Trier: "Sort",
    "Triez les comp\xE9tances par ordre d\u2019importance":
      "Sort the skills in order of importance",
    "Trouver un consultant IT": "Find an IT expert",
    "Trouver un freelance": "Find the right candidate",
    "Trouver un talent": "Find a consultant",
    "Trouver une mission": "Find a job",
    "Trouver une mission freelance": "Find your next job",
    "Trouver une offre": "Find an offer",
    "Trouvez d\xE8s maintenant les profils les plus pertinents gr\xE2ce \xE0 notre algorithme de matching SMATCH":
      "Find the most relevant profiles now thanks to our SMATCH matching algorithm",
    "Trouvez les plus belles offres IT et les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...":
      "Your partner for IT jobs and Talent recruitment. Permanent or contract: find the best IT experts to accelerate your business projects. A dedicated account manager and sole point of contact. No prepayments.",
    "Trouvez votre consultant IT avec notre algorithme de matching SMATCH":
      "Find your IT consultant with our SMATCH matching algorithm",
    "Trouvez votre consultant IT avec notre<0/>algorithme de matching SMATCH":
      "Find your IT consultant with our <0/> SMATCH matching algorithm",
    Turkish: "Turkish",
    Turque: "Turkish",
    "Type de contract": "Employment Type",
    "Type de contrat": "Employment type",
    "Type de diplome": "Degree type",
    "T\xE9l\xE9charger ce mod\xE9le": "Download this template",
    "T\xE9l\xE9charger la ressource": "Download resource",
    "T\xE9l\xE9charger le guide d\u2019utilisation": "Download user guide",
    "T\xE9l\xE9charger ton CV favori": "Download your favourite template",
    "T\xE9l\xE9charger votre CV favori":
      "Download your favourite resume template",
    Téléphone: "Phone",
    Témoignages: "Testimonials",
    URGENT: "URGENT",
    "URL LinkedIn": "URL LinkedIn",
    Un: "A",
    "Un Account Manager sp\xE9cialis\xE9 sur votre secteur vous rappelle pour pr\xE9ciser votre besoin":
      "Our Account Manager gets in touch to determine the specifics of your project",
    "Un Key Account Manager d\xE9di\xE9":
      "A dedicated account manager and sole point of contact",
    "Un Key Account Manager d\xE9di\xE9 vous guidera dans vos projets":
      "A dedicated Key Account Manager will guide you through the process",
    "Un accompagnement personnalis\xE9 par nos \xE9quipes":
      "Personalised support by our tech-savvy career experts",
    "Un accompagnement<0/>personnalis\xE9 par nos \xE9quipes":
      "Personalised support by our tech-savvy career experts",
    "Un community specialist vous appellera pour compl\xE9ter votre profil.":
      "A  talent community specialist will call you to complete your profile",
    "Un compte a d\xE9j\xE0 \xE9t\xE9 cr\xE9\xE9 avec cet email":
      "An account has already been created with this email address",
    "Un conseiller vous appelera pour compl\xE9ter votre profil.":
      "An account manager will give you a call back to better understand your needs",
    "Un cv actualis\xE9 au format Word facilitera les \xE9changes avec nos \xE9quipes":
      "An updated CV in a Word format will make it easier for our team to contact you",
    "Un e-mail de validation vous a \xE9t\xE9 envoy\xE9 pour v\xE9rifier votre compte.":
      "Un e-mail de validation vous a \xE9t\xE9 envoy\xE9 pour v\xE9rifier votre compte.",
    "Un intitul\xE9 de poste": "Job title",
    "Un probleme est survenu lors de la creation de votre candidature":
      "An error has occurred while sending your application",
    "Un probleme est survenu, veuillez essayer plus tard":
      "Something went wrong, please try again later",
    "Un probl\xE8me est survenu": "Something went wrong",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de besoin":
      "A problem has occurred while creating your request",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de l'abonnement":
      "An error has occurred while subscribing",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de l\u2019abonnement":
      "An error has occurred while subscribing",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de votre candidature":
      "An error has occurred while creating your application",
    "Un probl\xE8me est survenu lors de la cr\xE9ation d\u2019alerte":
      "An error has occurred while creating the alert",
    "Un responsable de compte vous recontactera":
      "An account manager will contact you shortly",
    "Un r\xE9seau de partenaires pour notre communaut\xE9":
      "A network of partners for our community",
    "Un seul article trouv\xE9": "Only one article found",
    "Under Construction": "Under Construction",
    "Une Solution compl\xE8te de talent management":
      "A comprehensive talent management solution",
    "Une approche unique": "Our unique approach",
    "Une communaut\xE9 au coeur de notre service.":
      "Our talent community is at the very heart of our service",
    "Une double expertise": "Our combined expertise",
    "Une erreur est survenue, veuillez r\xE9essayer ult\xE9rieurement":
      "An error has occurred, please try again later",
    "Une erreur s\u2019est produite, veuillez r\xE9essayer":
      "An error has occurred, please try again",
    "Une fois le candidat choisi, nous vous accompagnons dans le process d\u2019onboarding et tout au long de la mission":
      "Once you have picked your candidate, we assist you with the onboarding",
    "Une fois le timesheet rejet\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.":
      "Once the timesheet has been rejected, you will not be able to go back. Please check the following information carefully.",
    "Une fois le timesheet valid\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.":
      "Once the timesheet has been validated, you will not be able to go back. Please check the following information carefully.",
    "Une organisation agile": "An agile organisation",
    "Une puissante Market Intelligence":
      "Providing valuable market intelligence",
    "Une recherche trop pr\xE9cise peut vous faire passer \xE0 c\xF4t\xE9 d\u2019une opportunit\xE9 en or. Ces informations nous aident \xE9galement \xE0 vous recommander de nouveaux projets.":
      "Don\u2019t be too picky! You never know what golden opportunities you might be missing when you are too narrow in your search  ",
    "Une shortlist des meilleurs candidats pour tous vos besoins IT":
      "A shortlist of top candidates within 48h",
    "Une s\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil":
      "Targeted, high-quality offers that check all your boxes",
    "Une s\xE9lection d\u2019offres<0/>adapt\xE9es \xE0 votre profil":
      "Targeted, high-quality offers that check all your boxes",
    "Une vision 360 du march\xE9": "A 360-degree vision of the market",
    "Uploader ma fiche de poste": "Upload a job decription",
    "Uploader mon CV": "Upload your resume",
    "Uploader mon CV<0><1/><2>Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)</2><3/></0>":
      "Upload my CV <0> <1 /> <2> Upload a CV in .docx format,. doc or. pdf. Your data will be automatically extracted to quickly complete your profile (recommended) </2> <3 /> </0>",
    "Utilisez cette case pour indiquer des heures suppl\xE9mentaires ou un tarif major\xE9 \xE0 votre client et \xE0 notre service de facturation":
      "Use this box to indicate extra-hours or an increased rate to your client and our Invoicing service",
    Valider: "Confirm",
    "Valider le timesheet": "Confirm the timesheet",
    Validés: "Confirmed",
    "Veillez entrer un email valide": "Please enter a valid email address",
    "Veuillez accepter la politique de confidentialit\xE9":
      "Please accept our privacy policy",
    "Veuillez accepter notre politique de confidentialit\xE9":
      "Please accept our privacy policy",
    "Veuillez ajouter une d\xE9scription de la mission":
      "A description is required",
    "Veuillez enregistrer avant de passer \xE0 l\u2019\xE9tape suivante":
      "Please save before moving to the next step",
    "Veuillez entrer une address valide": "Please enter a valid address",
    "Veuillez entrer votre nom": "Please enter your last name",
    "Veuillez entrer votre pr\xE9nom": "Please enter your first name",
    "Veuillez indiquer votre localisation": "Please indicate your location",
    "Veuillez noter que ce commentaire sera visible par le consultant":
      "Please note that this comment will be visible by the consultant",
    "Veuillez selectionner au moin un filtre pour cr\xE9er une alerte":
      "Please select at least one filter to create an alert",
    "Veuillez valider la ReCaptcha": "I am not a robot",
    "Veuillez valider la certification ouverte avant l\u2019enregistrement":
      "Please validate the opened certification before saving",
    "Veuillez valider la formation ouverte avant l\u2019enregistrement":
      "Please validate the opened education before saving",
    "Veuillez valider l\u2019exp\xE9rience ouverte avant l\u2019enregistrement":
      "Please validate the opened experience before saving",
    Vidéos: "Videos",
    Ville: "City",
    "Ville de r\xE9sidence": "City of residence",
    "Voir Mon CV": "View my resume",
    "Voir la FAQ": "FAQ",
    "Voir la video": "See the video",
    "Voir le Timesheet": "See the Timesheet",
    "Voir les mod\xE8les": "See templates",
    "Voir les offres": "See offers",
    "Voir les ressources": "See resources",
    "Voir l\u2019offre": "See offer",
    "Voir plus": "See more",
    "Voir plus de vid\xE9os": "More videos",
    "Voir plus d\u2019articles": "More articles",
    "Voir ses offres": "See offers",
    "Voir tous les talents": "See more candidates",
    "Voir toutes les offres en cours": "View all active offers",
    "Voir toutes mes missions": "See more",
    "Vos coordonn\xE9es nous permettront de vous contacter d\xE8s lors qu\u2019une opportunit\xE9 correspond \xE0 votre profil.":
      "Let us know where to best reach you when we have an opportunity for you",
    "Vos donn\xE9es LinkedIn ont \xE9t\xE9 import\xE9es avec succ\xE8s. Veuillez les enregistrer.":
      "Your LinkedIn data has been successfully imported. Please save your progress",
    "Votre CV est en cours de g\xE9n\xE9ration . . .":
      "Your Resume is being generated . . . ",
    "Votre CV favori sera accessible directement depuis votre Dashboard":
      "Your favourite RESUME will be accessible directly from your Dashboard",
    "Votre Talent Community Specialist vous rappelle pour comprendre vos besoins":
      "One of our talent community specialists will call you to better understand your needs and expectations",
    "Votre Timesheet du mois n\u2019est pas ouvert?":
      "Is your Timesheet of the month not open?",
    "Votre dashboard est en cours de cr\xE9ation et sera accessible dans quelques instants.":
      "Your dashboard is being generated and will be available in a few minutes",
    "Votre demande a bien \xE9t\xE9 envoy\xE9e pour validation!":
      "Your request has been sent for validation!",
    "Votre domaine d\u2019expertise": "Area of expertise",
    "Votre message": "Your message",
    "Votre profil est complet \xE0 {0}%, Pour g\xE9n\xE9rer votre CV Club Freelance, terminez de remplir les informations indispensables.": function(
      a
    ) {
      return [
        "Your profile is ",
        a("0"),
        "% complete. To generate your Mindquest resume, finish filling out the required information."
      ];
    },
    "Votre profil est complet \xE0 {0}%, Pour g\xE9n\xE9rer votre CV Mindquest, terminez de remplir les informations indispensables.": function(
      a
    ) {
      return [
        "Your profile is ",
        a("0"),
        "% complete. To generate your Mindquest resume, finish filling out the required information."
      ];
    },
    "Votre profil est presque complet. Un profil compl\xE9t\xE9 \xE0 100% permettra \xE0 nos recruteurs de vous proposer les offres les plus adapt\xE9s.":
      "Your profile is almost complete. A 100% completed profile will allow our recruiters to propose you the most adapted offers.",
    "Votre profil est rempli \xE0": "Your profile is completed at",
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance.": function(
      a
    ) {
      return [
        "Your profile is ",
        a("0"),
        "% complete. Finish filling it in to generate your Mindquest resume."
      ];
    },
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance. Ajoutez votre": function(
      a
    ) {
      return [
        "Your profile is ",
        a("0"),
        "% complete. Finish filling it in to generate your Mindquest resume. Add your"
      ];
    },
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Mindquest.": function(
      a
    ) {
      return [
        "Your profile is ",
        a("0"),
        "% complete. Finish filling it in to generate your Mindquest resume."
      ];
    },
    "Votre profil n'est rempli termin\xE9 , compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance.":
      "Your profile is not completed, complete it to generate your Club Freelance CV.",
    "Votre statut": "Your status",
    "Votre token est expir\xE9 ou invalide": "Expired or invalid token",
    "Voulez vous contribuer?": "Fancy contributing?",
    "Voulez-vous ajouter des informations sur votre projet?":
      "Do you want to add information about your project?",
    "Vous avez d\xE9j\xE0 postul\xE9 pour cette mission": "Already applied",
    "Vous avez finalis\xE9 votre inscription":
      "You have completed your registration",
    "Vous avez oubli\xE9 d\u2019\xE9crire votre message !":
      "You forgot to write your message!",
    "Vous avez un besoin informatique ? Vous cherchez une mission ? N\u2019h\xE9sitez pas \xE0 nous contacter.":
      "You are looking for an IT position or a top candidate ? Feel free to get in touch.",
    "Vous cr\xE9ez votre profil Club Freelance en seulement 3 minutes":
      "Vous cr\xE9ez votre profil Club Freelance en seulement 3 minutes",
    "Vous cr\xE9ez votre profil et acc\xE9dez \xE0 votre espace personnel pour poster votre premier besoin, en seulement quelques minutes":
      "Create a profile and post your first vacancy, all in just a few minutes",
    "Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin":
      "Action restricted to Company accounts",
    "Vous devez vous connecter avec un compte entreprise pour continuer":
      "You must sign in with a business account to continue",
    "Vous devez vous connecter avec un compte entreprise pour continuer.":
      "Vous devez vous connecter avec un compte entreprise pour continuer.",
    "Vous devez vous connecter pour pouvoir postuler":
      "You need to be logged in to apply",
    "Vous n'avez pas re\xE7u votre code ?": "Haven't received your code?",
    "Vous ne pouvez pas ajouter une exp\xE9rience vide":
      "You cannot add an empty experience",
    "Vous ne pouvez pas d\xE9placer la carte vers cette colonne":
      "You cannot move the map to this column",
    "Vous ne pouvez pas modifier cette opportunit\xE9":
      "You cannot edit this opportunity",
    'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Continuer"':
      'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Continuer"',
    'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Suivant"':
      "You can add a comment after clicking on Next ",
    "Vous pouvez d\xE9sormais remplir votre Timesheet {m}/{y} du contrat num\xE9ro: {pID}": function(
      a
    ) {
      return [
        "You can now fill out the Timesheet ",
        a("m"),
        "/",
        a("y"),
        " for the contract number ",
        a("pID")
      ];
    },
    "Vous pouvez g\xE9rer l\u2019ensemble du process de s\xE9lection et les entretiens via votre espace client, et en mode collaboratif avec votre \xE9quipe":
      "Manage the whole selection process from our platform, with support from our team",
    "Vous pouvez maintenant suivre le candidat":
      "You can now follow the candidate",
    "Vous pouvez maintenant suivre le candidat {candidateBhId}": function(a) {
      return ["Vous pouvez maintenant suivre le candidat ", a("candidateBhId")];
    },
    "Vous pouvez maintenant suivre le candidat {id}": function(a) {
      return ["Vous pouvez maintenant suivre le candidat ", a("id")];
    },
    "Vous pouvez suivre vos candidatures dans votre profil":
      "Track the progress of your applications in your personal space",
    "Vous pouvez voir votre alerte dans votre profil":
      "You can see your alert in your profile",
    "Vous recevez des offres adapt\xE9es \xE0 vos crit\xE8res":
      "Vous recevez des offres adapt\xE9es \xE0 vos crit\xE8res",
    "Vous recevez une shortlist de candidats qualifi\xE9s (en moyenne sous 48h)":
      "You receive a shortlist of qualified candidates within 48h",
    "Vous recherchez des profils de consultants IT exp\xE9riment\xE9s ? Trouvez les d\xE8s maintenant avec notre algorithme de matching SMATCH":
      "Looking for experienced IT consultants? Find them now with our SMATCH matching algorithm",
    "Vous risquez de perdre vos donn\xE9es": "You risk losing your data",
    "Vous serez redig\xE9 automatiquement vers votre dashboard.":
      "You'll be automatically redirected to your dashboard",
    "Vous souhaitez coopter quelqu\u2019un de votre entourage ?":
      "Would you like to recommend us a friend?",
    "Vous souhaitez en savoir plus sur notre fonctionnement ?":
      "Want more information regarding our model and services?",
    "Vous souhaitez rejoindre Club Freelance ?": "How can we help ?",
    "Vous souhaitez \xEAtre accompagn\xE9(e) en portage salarial ? D\xE9couvrez les solutions de nos partenaires.":
      "Do you want to be accompanied by a wage portage? Discover the solutions of our partners.",
    "Vous suivez vos candidatures dans votre espace personel":
      "Vous suivez vos candidatures dans votre espace personel",
    "Vous \xEAtes d\xE9sormais abonn\xE9 aux Mission Control Center":
      "You subscribed to Mission Control Center successfully",
    "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Club Freelance":
      "You are now subscribed to Mindquest's newsletter",
    "Vous \xEAtes freelance ?": "Ready to make a move?",
    "Vous \xEAtes sur le point de soumettre un Timesheet vide.":
      "You are about to submit an empty Timesheet",
    "Vous \xEAtes sur le point de soumettre votre Timesheet {0} au client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le modifier. Merci de v\xE9rifier vos informations avant l\u2019envoi.": function(
      a
    ) {
      return [
        "You are about to submit your Timesheet ",
        a("0"),
        " to the client. Once the Timesheet has been validated, you will no longer be able to modify it. Please verify your information before sending."
      ];
    },
    "Want to join Club Freelance ?": "Want to join Mindquest?",
    "We also receive personal data about Candidates from other sources. Depending on the relevant circumstances and applicable local laws and requirements, these may include personal data received in the following situations:":
      "We also receive personal data about Candidates from other sources. Depending on the relevant circumstances and applicable local laws and requirements, these may include personal data received in the following situations:",
    "We are committed to ensuring that when we collect and use information about Website Users, our Candidates and Clients, we do so in accordance with all data protection legislation including the GDPR.":
      "We are committed to ensuring that when we collect and use information about Website Users, our Candidates and Clients, we do so in accordance with all data protection legislation including the GDPR.",
    "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Club Freelance, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.":
      "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Club Freelance, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.",
    "We collect your personal data in two primary ways:":
      "We collect your personal data in two primary ways:",
    "We do not much collect information about Clients. We generally only need to have your contact details or the details of individual contacts at your organisation (such as their names, telephone numbers and email addresses) to enable us to provide our services to you and to ensure that our relationship runs smoothly.":
      "We do not much collect information about Clients. We generally only need to have your contact details or the details of individual contacts at your organisation (such as their names, telephone numbers and email addresses) to enable us to provide our services to you and to ensure that our relationship runs smoothly.",
    "We have put in place several technical and organisational measures aimed at protecting the confidentiality, the integrity and the availability of your personal data.":
      "We have put in place several technical and organisational measures aimed at protecting the confidentiality, the integrity and the availability of your personal data.",
    "We may also disclose your personal information to third parties:":
      "We may also disclose your personal information to third parties:",
    "We may collect, store, and use the following categories of personal information about you:":
      "We may collect, store, and use the following categories of personal information about you:",
    "We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.":
      "We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.",
    "We may obtain data about you from cookies. These are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Cookies also enable us to deliver more personalised content.":
      "We may obtain data about you from cookies. These are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Cookies also enable us to deliver more personalised content.",
    "We may obtain information about you from searching for potential Candidates from third party sources, such as LinkedIn and other job sites;":
      "We may obtain information about you from searching for potential Candidates from third party sources, such as LinkedIn and other job sites;",
    "We may obtain this information from you, from our Client or from public sources and this includes:":
      "We may obtain this information from you, from our Client or from public sources and this includes:",
    "We may send you updates and information about products and services which may be of interest to you. If you have consented to receive marketing, you may opt out at a later date. You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please unsubscribe from our next email.":
      "We may send you updates and information about products and services which may be of interest to you. If you have consented to receive marketing, you may opt out at a later date. You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please unsubscribe from our next email.",
    "We only bill if you find your freelancer":
      "We only bill you once you find the right candidate ",
    "We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.":
      "We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.",
    "We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests (or other appropriate legal basis) and that you are happy for us to do so. Accordingly, we run data routines to remove data that we no longer have a need to retain.":
      "We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests (or other appropriate legal basis) and that you are happy for us to do so. Accordingly, we run data routines to remove data that we no longer have a need to retain.",
    "We use your data to help us to improve your experience of using our website, we may therefore for example launch a search function and may then analyse your recent job search criteria to help us to present jobs that we think you may be interested in.":
      "We use your data to help us to improve your experience of using our website, we may therefore for example launch a search function and may then analyse your recent job search criteria to help us to present jobs that we think you may be interested in.",
    "We will always ensure that there are adequate levels of protection and a similar legislation with regards to data protection. Each country might have different laws and regulations with regards to data protection. Overseas transfers outside the EEA will only be possible when we have the appropriate safeguards are in place.":
      "We will always ensure that there are adequate levels of protection and a similar legislation with regards to data protection. Each country might have different laws and regulations with regards to data protection. Overseas transfers outside the EEA will only be possible when we have the appropriate safeguards are in place.",
    "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:":
      "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:",
    "We will share your personal information with all entities in our group as part of our global recruitment activities, regular reporting activities on company performance, for system maintenance support and hosting of data.":
      "We will share your personal information with all entities in our group as part of our global recruitment activities, regular reporting activities on company performance, for system maintenance support and hosting of data.",
    "We will usually keep data for a maximum of 5 years and for full details of our retention strategy you can request it by contacting us at contact@club-freelance.com.":
      "We will usually keep data for a maximum of 5 years and for full details of our retention strategy you can request it by contacting us at contact@club-freelance.com.",
    "Webinars & Ressources": "Webinars & Ressources",
    "Website user": "Website user",
    "Website users": "Website users",
    "What are our legitimate business interests in collecting and retaining your personal data?":
      "What are our legitimate business interests in collecting and retaining your personal data?",
    "What kind of Personal Data do we collect?":
      "What kind of Personal Data do we collect?",
    "What measures we put in place to safeguard your personal data?":
      "What measures we put in place to safeguard your personal data?",
    "What we may need from you": "What we may need from you",
    "When you visit our website, there is certain information that we may automatically collect, whether or not you decide to use our services. This includes your IP address, the date and the times and frequency with which you access the website and the way you browse its content. We will also collect data from you when you contact us via the website.":
      "When you visit our website, there is certain information that we may automatically collect, whether or not you decide to use our services. This includes your IP address, the date and the times and frequency with which you access the website and the way you browse its content. We will also collect data from you when you contact us via the website.",
    "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Club Freelance and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Club Freelance will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.":
      "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Club Freelance and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Club Freelance will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.",
    "Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you for the purposes of recruitment services. In this case, we may be unable to provide the recruitment services you have requested but we will notify you if this is the case at the time.":
      "Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you for the purposes of recruitment services. In this case, we may be unable to provide the recruitment services you have requested but we will notify you if this is the case at the time.",
    "Who do we share your personal data with?":
      "Who do we share your personal data with?",
    "You have the right to make a complaint at any time to a supervisory body which in the UK is the Information Commissioner\u2019s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.":
      "You have the right to make a complaint at any time to a supervisory body which in the UK is the Information Commissioner\u2019s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.",
    "You have the right to request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.":
      "You have the right to request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.",
    "You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.":
      'You will not have to pay a fee to access your personal data (or to exercise "any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.',
    "Your referees may disclose personal information about you;":
      "Your referees may disclose personal information about you;",
    "Zone g\xE9ographique souhait\xE9e": "Desired geographic area",
    "Zones g\xE9ographiques souhait\xE9es": "Desired geographic areas",
    actualité: "News",
    "adresse email": "email address",
    "ajout d'un nouveau signataire principal":
      "adding a new principal signatory",
    "ajout d'un nouveau signataire secondaire":
      "adding a new secondary signatory",
    "ajoutez l'email du signataire principal...":
      "Add the email of the principal signatory...",
    "ajoutez l'email du signataire secondaire...":
      "Add the email of the secondary signatory...",
    "ajoutez un commentaire...": "Add a comment...",
    "analytics and search engine providers that assist us in the improvement and optimisation of our site;":
      "analytics and search engine providers that assist us in the improvement and optimisation of our site;",
    août: "august",
    au: "at",
    "aucun r\xE9sultat ne correspond \xE0 vos crit\xE8res de recherche":
      "no results match your search criteria",
    "aucun timesheet disponible": "no timesheet available",
    avril: "april",
    "blog, club freelance, Conseils pratiques, consultant IT, freelance informatique":
      "blog, club freelance, tips, IT consultant, freelance IT",
    "blog, club freelance, Conseils pratiques, entreprise IT, freelance informatique":
      "blog, club freelance, tips, company IT, freelance IT computer, freelance IT",
    "blog, club freelance, D\xE9cryptages": "blog, club freelance, Decryptions",
    "blog, club freelance, Success stories, freelance informatique":
      "blog, club freelance, Success stories, IT freelance",
    "blog, club freelance, actualit\xE9s, freelance informatique, actualit\xE9 freelance":
      "blog, club freelance, news, IT freelance, freelance news",
    "blog, club freelance, recherche, freelance informatique":
      "blog, club freelance, research, IT freelance",
    "blog, club freelance, talents IT, actualit\xE9s, freelances IT":
      "blog, club freelance, IT talents, news, IT freelancers",
    candidat: "candidate",
    "ces mots cl\xE9s seront pris en compte lors de la prochaine mise \xE0 jour.":
      "these keywords will be taken into account in the next update.",
    "champ requis": "required field",
    "charcher par Id, client ou signature": "check by Id, client or signature",
    "chercher par id, consultant": "search by ID, candidate",
    "chercher par id, entreprise ou signataire":
      "search by ID, company or approval",
    "clients, business partners, suppliers and sub-contractors for the performance and compliance with obligations of any contract we enter into with them or you;":
      "clients, business partners, suppliers and sub-contractors for the performance and compliance with obligations of any contract we enter into with them or you;",
    "club freelance, privacy policy": "club freelance, privacy policy",
    "code de confirmation": "confirmation code",
    "code invalide": "invalid code",
    "compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance. Ajoutez votre":
      "complete it to generate your Club Freelance CV. Add your",
    "conseils pratiques": "practical advice",
    consultant: "Candidate",
    "contact, club freelance, freelance informatique":
      "contact, mindquest, freelance IT",
    "credit reference agencies, our insurance broker, compliance partners and other sub-contractors for the purpose of assessing your suitability for a role where this is a condition of us entering into a contract with you.":
      "credit reference agencies, our insurance broker, compliance partners and other sub-contractors for the purpose of assessing your suitability for a role where this is a condition of us entering into a contract with you.",
    "c\u2019est le seul moyen de vous rejoindre!":
      "It is the only way we can reach out to you!",
    "dashboard, club freelance, entreprise, talents, recrutement,":
      "dashboard, club freelance, entreprise, talents, recrutement",
    "de ventes": "earned",
    disponibilite: "availability",
    "disponibilit\xE9 \xE0 confirmer": "availability to be confirmed",
    "disponible dans un jour": "available in a day",
    "disponible dans {diffDays} jours": function(a) {
      return ["available in ", a("diffDays"), " days"];
    },
    "disponible dans {diffMonths} mois": function(a) {
      return ["available in ", a("diffMonths"), " months"];
    },
    "disponible dans {diffYears} ans": function(a) {
      return ["available in ", a("diffYears"), " years"];
    },
    "disponible imm\xE9diatement": "available immediately",
    décembre: "december",
    décryptages: "decryption",
    "email invalide": "invalid email",
    "en attente": "pending",
    entreprise: "employer",
    "entrer la taille de votre entreprise": "Your company's size",
    "entrer votre nom": "enter you last name",
    "entrer votre pr\xE9nom": "enter yout first name",
    "entrez l\u2019email de r\xE9ception": "Enter your email address",
    "envoi du feuille de temps": "Timesheet submission",
    "et trouvez votre prochaine mission IT":
      "and take your career to new heights",
    "euros / jour": "euros / day",
    fermée: "closed",
    filters: "filters",
    fr: "en",
    février: "february",
    heures: "hours",
    "https://monsite.fr": "https://mysite.fr",
    "https://www.linkedin.com/in/user/": "https://www.linkedin.com/in/user/",
    "il n'y a pas d'historique disponible pour le moment":
      "there is no history available at the moment",
    "il n\u2019y a pas d\u2019historique disponible pour le moment":
      "there is no history available at the moment",
    inconnue: "unknown",
    indéterminé: "undetermined",
    "information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), pages you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs).":
      "information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), pages you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs).",
    "informatique et consultants IT": "and match them with innovative brands",
    "int\xE9ress\xE9 par ce candidat": "interested in this candidate",
    j: "j",
    "j'ai compris": "understood",
    janvier: "january",
    "jj/mm/aaaa": "dd/mm/yyyy",
    jours: "days",
    juillet: "july",
    juin: "june",
    "l'ajout du nouveau signataire secondaire a \xE9t\xE9 effectu\xE9 avec succ\xE8s":
      "the new secondary signatory was successfully added",
    "la feuille de temps a \xE9t\xE9 soumise au client pour validation":
      "the timecard is sent to your client for validation",
    "la feuille de temps est enregistr\xE9e en brouillon, vous pouvez la modifier \xE0 tout moment":
      "the timecard is saved in draft, you can edit it at any time",
    "la feuille de temps est rejet\xE9e": "The Timesheet has been rejected",
    "la feuille de temps est valid\xE9e": "the timecard is validated",
    "la feuille de temps n'a pas \xE9t\xE9 enregistr\xE9e":
      "the timecard has not been saved",
    "la suppression du signataire secondaire est faite avec succ\xE8s":
      "The secondary approval has been deleted successfully",
    "le fichier vous a \xE9t\xE9 envoy\xE9 par email":
      "the file has been sent to you via email",
    "le probl\xE8me sera bient\xF4t bient\xF4t r\xE9solu":
      "the problem will soon be solved",
    "le probl\xE8me sera bient\xF4t r\xE9solu":
      "Don't worry, it all will be solved shortly!",
    "login or password invalid": "login or password invalid",
    "login ou mot de passe invalide": "login or password invalid",
    "login, club freelance, account, connexion": "login, mindquest, account",
    "l\u2019ajout d\u2019un nouveau signataire va ecraser l\u2019ancien..":
      "adding a new signer will overwrite the old one..",
    mai: "may",
    mars: "march",
    missions: "Job Opportunities",
    "missions freelance, Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle":
      "missions, Data, Development, Cloud, Project management, CRM, Support, BI, Webdesign, ERP, SAP, Oracle",
    "missions, Talent Community Specialist, Freelance informatique":
      "missions, Talent Community Specialist, IT Freelance",
    mois: "month",
    "monemail@email.com": "myemail@mail.me",
    "monemail@mail.com": "myemail@mail.me",
    "nombre d'heurs doit etre entre 0 et 10":
      "the number of hours should be between 0 and 10",
    "nombre de minutes doit etre entre 0 et 59":
      "number of hours must be between 0 and 10",
    "nombre de minutes doit \xEAtre entre 0 et 59":
      "number of minutes must be between 0 and 59",
    normal: "normal",
    novembre: "november",
    "num\xE9ro de t\xE9l\xE9phone non valide": "Invalid phone number",
    octobre: "october",
    "option 1": "option 1",
    "option 2": "option 2",
    optionnel: "optional",
    ou: "or",
    "ou inscrivez-vous maintenant":
      "Don\u2019t have an account yet? Click on Join Mindquest",
    ouvert: "open",
    "par cat\xE9gorie: {0}": function(a) {
      return ["by category: ", a("0")];
    },
    "par email": "by email",
    "par tag: {0}": function(a) {
      return ["by tag: ", a("0")];
    },
    "plus d\u2019expertises": "More areas of expertise",
    "politique de confidentialit\xE9": "privacy policy",
    "politique de confidentialit\xE9 des donn\xE9es": "privacy policy",
    "pour poursuivre votre inscription.": "to continue your registration.",
    rechercher: "search",
    "rejet du feuille de temps": "timesheet rejection",
    rejeté: "rejected",
    rejetée: "rejected",
    "revenir \xE0 la page d\u2019accueil": "back to home page",
    "r\xE9essayer svp": "try again",
    résultats: "results",
    "s'abonner": "Subscribe",
    "selon vos mots-cl\xE9s": "according to your keywords",
    septembre: "september",
    "sign up, club freelance, communaut\xE9, compte, Freelances IT":
      "sign up, mindquest, communaut\xE9, compte, Freelances IT",
    "signataire principal": "principal signatory",
    soumettre: "Submit",
    special: "special",
    "subcontractors and other service providers including email marketing specialists, event organisers, payment and other financial service providers;":
      "subcontractors and other service providers including email marketing specialists, event organisers, payment and other financial service providers;",
    "suppression du signataire secondaire": "Delete secondary approval",
    "techniques utilis\xE9s sur cette mission":
      "environment used on this mission",
    "tips and tricks": "tips and tricks",
    tous: "All",
    "transport en commun": "public transport",
    type: "type",
    "un compte entreprise.": "a company account.",
    "user dashboard": "user dashboard",
    "validation du feuille de temps": "timesheet validation",
    validé: "validated",
    "veuillez ajouter vos soft skills s\xE9par\xE9es par des virgules.":
      "please add your soft skills separated by commas.",
    voiture: "car",
    "votre compte est maintenant valid\xE9": "Your account is now active",
    "votre mot cl\xE9 doit contenir 3 caract\xE8res au minimum":
      "your keyword must contain at least 3 characters",
    "votre responsable de compte vous contactera dans les plus brefs d\xE9lais.":
      "your account manager will contact you as soon as possible",
    "vous a \xE9t\xE9 envoy\xE9": "has been sent to you",
    "vous devez ajouter un commentaire": "Add a comment",
    "vous devez fixer les champs erron\xE9s avant":
      "you must fix the wrong fields before",
    "vous devez pr\xE9ciser la taille de votre entreprise":
      "Specify your company's size",
    "vous devez pr\xE9ciser le code postal avant de lancer le matching":
      "you must specify the postal code before launching the matching",
    "vous devez pr\xE9ciser le rythme de travail": "specify the rythm of work",
    "vous devez pr\xE9ciser le type du contrat": "specify the type of contract",
    "vous devez s\xE9lectionner des mot-cl\xE9s avant de lancer le matching":
      "you must select keywords before launching the matching",
    "warning!": "warning!",
    "{0, plural, one {# Article \xE0 lire} other {# Articles \xE0 lire}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Article to read"],
          other: ["#", " Articles to read"]
        })
      ];
    },
    "{0, plural, one {# Articles to read} other { # Articles to read}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Articles to read"],
          other: [" ", "#", " Articles to read"]
        })
      ];
    },
    "{0, plural, one {# Articles to read} other {# Articles to read}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Articles to read"],
          other: ["#", " Articles to read"]
        })
      ];
    },
    "{0, plural, one {# Articles to read} other {}}": function(a) {
      return [a("0", "plural", { one: ["#", " Articles to read"], other: "" })];
    },
    "{0, plural, one {# jour} other {# jours}}": function(a) {
      return [a("0", "plural", { one: ["#", " day"], other: ["#", " days"] })];
    },
    "{0, plural, one {# mois} other {# mois}}": function(a) {
      return [
        a("0", "plural", { one: ["#", " month"], other: ["#", " months"] })
      ];
    },
    "{0, plural, one {Resultat} other {resultats}}": function(a) {
      return [a("0", "plural", { one: "Result", other: "results" })];
    },
    "{0, plural, one {R\xE9sultat} other {r\xE9sultats}}": function(a) {
      return [a("0", "plural", { one: "R\xE9sultat", other: "r\xE9sultats" })];
    },
    "{0}": function(a) {
      return [a("0")];
    },
    "{btnText}": function(a) {
      return [a("btnText")];
    },
    "{candidateStatus}": function(a) {
      return [a("candidateStatus")];
    },
    "{days, plural, one {# jour} other {# jours}}": function(a) {
      return [
        a("days", "plural", { one: ["#", " day"], other: ["#", " days"] })
      ];
    },
    "{months, plural, one {# mois} other {# mois}}": function(a) {
      return [
        a("months", "plural", { one: ["#", " month"], other: ["#", " months"] })
      ];
    },
    "{nResults} r\xE9sultats sont trouv\xE9s": function(a) {
      return [a("nResults"), " results found"];
    },
    "\xC0 l\u2019\xE9tude": "Under review",
    "\xC0 partir de {0}": function(a) {
      return ["From ", a("0")];
    },
    "\xC0 plein temps": "Full-time",
    "\xC0 propos de Mindquest": "About Mindquest",
    "\xC0 temps partiel": "Part-time",
    "\xC0 t\xE9l\xE9charger gratuitement": "Free download",
    "\xC9num\xE9rez toutes vos exp\xE9riences professionnelles pertinentes afin que nous puissions nous faire une id\xE9e plus pr\xE9cise de votre profil":
      "Now let\u2019s dive a bit deeper. List all your relevant previous work experiences for us to get a better sense of your profile",
    "\xC9quipes innovation & support": "Innovation & support",
    "\xC9tape 1: Int\xE9grez votre fiche de poste":
      "Step 1: Add your job description",
    "\xC9tape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues":
      "Step 2: Select and add your keywords",
    "\xC9tape suivante": "Next step",
    "\xCAtes-vous s\xFBr de vouloir supprimer ce certificat?":
      "Are you sure you want to delete this certificate?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette exp\xE9rience?":
      "Are you sure you want to delete this experience?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette formation?":
      "Are you sure you want to delete this training?",
    "\xCAtre rappel\xE9 par un conseiller": "Request a call back",
    à: "at",
    "\xE0 partir de": "since",
    "\xE0 partir du": "since",
    "\u201CClub Freelance propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.\u201C":
      "\u201CMindquest offers a clear and straightforward process. Everything can be done online, and you have at your disposal templates for all the required documentation. It\u2019s incredibly easy to raise an invoice and get paid. And always on time.\u201C",
    "\u201CJe suis tr\xE8s satisfaite de Club Freelance. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able.\u201C":
      "\u201CI am really satisfied with Mindquest's services. Fast feedback after interviews, with the contract sent shortly after and no payment issues. The team is always available and working with them is easy and pleasant.\u201C",
    "\u201CLe service du Club Freelance est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.\u201C":
      "\u201CMindquest's service is fast and efficient. The team knows what they are doing and they waste no one's time. They don't call you to build a database of CVs, but to actually offer you interesting jobs. I strongly recommend working with them.\u201C"
  }
};
