import gql from "graphql-tag";

export const FAV_MISSION = gql`
  mutation FavMission($jobOrderId: ID!) {
    favMission(jobOrderId: $jobOrderId) {
      id
    }
  }
`;

export const UNFAV_MISSION = gql`
  mutation UnFavMission($jobOrderId: ID!) {
    unFavMission(jobOrderId: $jobOrderId) {
      id
    }
  }
`;

export const CREATE_MISSION_ALERT = gql`
  mutation CreateMissionAlert($input: MissionAlertInput) {
    createMissionAlert(input: $input) {
      id
    }
  }
`;
