import { t } from "@lingui/macro";
// import monthName from "../../../../../utils/monthName";

const candidateNotisMessages = (i18n, month, year, placementID) => {
  const m = month.toString();
  const y = year.toString();
  const pID = placementID.toString();
  return {
    OPEN: {
      title: i18n._(t`Timesheet Ouvert`),
      message: i18n._(
        t`Vous pouvez désormais remplir votre Timesheet ${m}/${y} du contrat numéro: ${pID}`
      ),
      buttonLabel: i18n._(t`Remplir le Timesheet`)
    },
    REJECTED: {
      title: i18n._(t`Timesheet Rejeté`),
      message: i18n._(
        t`Timesheet ${m}/${y} du contrat numéro: ${pID} a été rejeté par le client. Merci d'effetuer les modifications nécessaires`
      ),
      buttonLabel: i18n._(t`Modifier le Timesheet`)
    },
    APPROVED: {
      title: i18n._(t`Timesheet Accepté`),
      message: i18n._(
        t`Timesheet ${m}/${y} du contrat numéro: ${pID} a été validé par le client`
      ),
      buttonLabel: i18n._(t`Consulter le compte-rendu`)
    },
    REPORT_GENERATED: {
      title: i18n._(t`Compte-Rendu Généré`),
      message: i18n._(
        t`Suite à la validation du Timesheet, le compte-rendu de mois ${m}/${y} est disponible`
      ),
      buttonLabel: i18n._(t`Consulter le compte-rendu`)
    },
    REMINDER: {
      title: i18n._(t`Rappel`),
      message: i18n._(
        t`Pensez à remplir votre Timesheet du contrat numéro: ${pID} de ${m}/${y}`
      ),
      buttonLabel: i18n._(t`Consulter le Timesheet`)
    }
  };
};

export default candidateNotisMessages;
