import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Tabs, Tab } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Query } from "react-apollo";
import { t } from "@lingui/macro";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import Routes from "../../layout/routes/index";
import DesiredProfile from "./DesiredProfile";
import OfferDescription from "./OfferDescription";
import OfferDetails from "./OfferDetails";
import { SINGLE_OPPORTUNITY_QUERY } from "../query";
import NotFoundPage from "../../../components/error-pages/NotFoundPage";
import Skeleton from "./skeleton/Skeleton";

@inject("appStore", "postANeedStore")
@observer
class UpdateOpportunity extends Component {
  changeTabHandler = activeTab => {
    const { history, match } = this.props;
    if (activeTab === "1")
      history.push(
        `/dashboard/company/opportunities/offer-details/edit/${match.params.id}`
      );
    if (activeTab === "2")
      history.push(
        `/dashboard/company/opportunities/offer-description/edit/${match.params.id}`
      );
    if (activeTab === "3")
      history.push(
        `/dashboard/company/opportunities/desired-profile/edit/${match.params.id}`
      );
  };

  fillStore = data => {
    const { postANeedStore } = this.props;
    const opp = data.getOpportunity;
    postANeedStore.changeOpportunity(opp);
  };

  render() {
    const { i18n, location, match } = this.props;
    let activeTab = "1";
    const currentPath = location.pathname;
    if (
      currentPath ===
      `/dashboard/company/opportunities/offer-details/edit/${match.params.id}`
    )
      activeTab = "1";
    if (
      currentPath ===
      `/dashboard/company/opportunities/offer-description/edit/${match.params.id}`
    )
      activeTab = "2";
    if (
      currentPath ===
      `/dashboard/company/opportunities/desired-profile/edit/${match.params.id}`
    )
      activeTab = "3";
    if (!match.params.id)
      return (
        <NotFoundPage
          message={i18n._(
            t`Nous sommes désolé, l'opportunité que vous cherchez n’existe pas ou n’est plus disponible`
          )}
          href={Routes.LpbLayoutRoutes.DashboardCompany.path}
        />
      );
    return (
      <div>
        <Query
          query={SINGLE_OPPORTUNITY_QUERY}
          variables={{ id: match.params.id }}
        >
          {({ loading, error, data }) => {
            if (error)
              return (
                <NotFoundPage
                  message={i18n._(
                    t`Nous sommes désolé, l'offre que vous cherchez n’existe pas ou n’est plus disponible`
                  )}
                  href={Routes.LpbLayoutRoutes.DashboardCompany.path}
                />
              );
            if (loading) return <Skeleton />;

            this.fillStore(data);
            return (
              <Tabs
                defaultActiveTab={activeTab}
                onChange={this.changeTabHandler}
              >
                <Tab name="1" tab={i18n._(t`Détails de l'offre`)}>
                  <OfferDetails />
                </Tab>
                <Tab name="2" tab={i18n._(t`Description de l'offre`)}>
                  <OfferDescription />
                </Tab>
                <Tab name="3" tab={i18n._(t`Profil recherché`)}>
                  <DesiredProfile />
                </Tab>
              </Tabs>
            );
          }}
        </Query>
      </div>
    );
  }
}

UpdateOpportunity.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  postANeedStore: PropTypes.shape({
    changeOpportunity: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(UpdateOpportunity));
