import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Container, Row } from "cf-neo-ui";
import { inject, observer } from "mobx-react/index";
import { Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import { USER_RECOMMENDATION_QUERY } from "./queries";
import { USER_FAVED_MISSIONS_QUERY } from "../missions-page/queries";
import List from "../dashboard/recommended-missions/list";
import classes from "./styles.module.scss";
import LoadingMissions from "../../components/LoadingMissions/LoadingMissions";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";

@inject("appStore")
@observer
class RecommendedMissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skip: 0
    };
  }

  fetchMore = () => {
    const { skip } = this.state;
    this.setState({ skip: skip + 8 });
  };

  render() {
    const { i18n, appStore } = this.props;
    const { skip } = this.state;
    const meta = staticMetaTags(i18n).recommendedMissions;

    return (
      <>
        {metaTags(
          urls.recommendedMissions(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <Query
          query={USER_RECOMMENDATION_QUERY}
          variables={{ skip }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <LoadingMissions
                  cardsCount={4}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={6}
                  colClassName={classes.favedMissionsCard}
                  containerClassName={classes.marTop}
                />
              );

            if (error)
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );

            const recommended = data.recommendedMissions;
            return (
              <Query query={USER_FAVED_MISSIONS_QUERY}>
                {({ loading: secLoading, error: secError, data: secData }) => {
                  if (secLoading)
                    return (
                      <LoadingMissions
                        cardsCount={4}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={6}
                        colClassName={classes.favedMissionsCard}
                        containerClassName={classes.marTop}
                      />
                    );

                  if (secError)
                    return (
                      <div className="error_container">
                        <span>
                          {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                        </span>
                        <img
                          style={{
                            margin: 20
                          }}
                          src={"/assets/svg/download.svg"}
                          alt="error"
                        />
                      </div>
                    );

                  const { userFavedMissions } = secData;
                  return (
                    <Container className={classes.marTop}>
                      <Row>
                        <List
                          recommended={recommended}
                          userFavedMissions={userFavedMissions}
                          fetchMore={this.fetchMore}
                          n={
                            appStore.width >= 1440
                              ? 8
                              : appStore.width >= 1024
                              ? 6
                              : appStore.width >= 768
                              ? 4
                              : 2
                          }
                        />
                      </Row>
                    </Container>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </>
    );
  }
}

RecommendedMissions.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({ width: PropTypes.number }).isRequired
};

export default withI18n()(RecommendedMissions);
