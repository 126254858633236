import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { withI18n } from "@lingui/react/index";
import { inject, observer } from "mobx-react";
import { Trans } from "@lingui/macro";
import {
  Row,
  Col,
  Button,
  FormCard,
  IllustrationIcons,
  Divider
} from "cf-neo-ui";
import Routes from "../layout/routes/index";

import "./styles.scss";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";

@inject("sessionStore")
@observer
class Confirm extends Component {
  handleBackRef = () => {
    const { history, sessionStore } = this.props;
    const { MissionsPage } = Routes.HbfLayoutRoutes;
    // history.push(`${MissionsPage.path}/${sessionStore.backRef}`);
    history.push(`/dashboard`);
    sessionStore.changeAccount(data.getAccountInfo.account);

    // sessionStore.setBackRef(null);
  };

  render() {
    const { Dashboard } = Routes.LpbLayoutRoutes;
    const { sessionStore, i18n } = this.props;
    const meta = staticMetaTags(i18n).signUpThanks;
    return (
      <div className="session container confirm">
        {metaTags(
          urls.signUp(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <br />
        <Row>
          <div className="center">
            <br />
            <IllustrationIcons type="rocket" color2="#d3354a" />
          </div>
        </Row>
        <Row>
          <Col className="content" lg={6} xl={6}>
            <div className="center-txt bold">
              <h1 className="thanks" id="signupcf">
                <Trans>Merci</Trans>!<br />
                <Trans>Vous avez finalisé votre inscription</Trans>
              </h1>
            </div>
            <br />
          </Col>
        </Row>
        <br />
        <FormCard className="formCard no-gutter">
          <Row className="with-padding">
            <Col lg={8} xl={8} className="center">
              <br />
              <span className="center-txt text-bold">
                <Trans>
                  Bienvenue au club, Vous pouvez maintenant accéder à votre
                  Dashboard
                </Trans>
                .
              </span>
              <br />
              <span className="center-txt text-gray-bold">
                {/* <Trans>Pensez à consulter vos courriers indésirables</Trans> */}
              </span>
            </Col>
          </Row>
          <br />
          <Divider />
          <br />
          <Row className="with-padding">
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="targeting"
                    color2="#d3354a"
                    dimension={80}
                  />
                  <span className="text-gray">
                    <Trans>Retrouvez des offres sélectionnés pour vous</Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="documents"
                    color2="#d3354a"
                    dimension={80}
                  />
                  <span className="text-gray">
                    <Trans>Gérez vos documents administratifs</Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="check"
                    color2="#d3354a"
                    dimension={55}
                  />
                  <span className="text-gray">
                    <Trans>Suivez toutes vos candidatures</Trans>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="with-padding">
            <Col>
              <div className="center">
                {sessionStore.backRef && (
                  <Button
                    variant="secondary"
                    className="join-club"
                    onClick={this.handleBackRef}
                    style={{ width: "247px", marginRight: "30px" }}
                  >
                    <Trans>Retourner a la mission</Trans>
                  </Button>
                )}
                <Link to={"/dashboard"}>
                  <Button icon="chevron-right" iconPosition="right">
                    <Trans>Accéder à mon espace personel</Trans>
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <br />
        </FormCard>
      </div>
    );
  }
}

Confirm.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    backRef: PropTypes.string,
    setBackRef: PropTypes.func,
    signUpUserRole: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Confirm));
