import React, { Component } from "react";
import classes from "./errorPage.module.scss";
import UnderConstructionPage from "./UnderConstructionPage";

class DashboardUnderConstruction extends Component {
  render() {
    return (
      <div className={classes.dashboard}>
        <UnderConstructionPage />
      </div>
    );
  }
}

export default DashboardUnderConstruction;
