import { observable, action } from "mobx";
import { computed } from "mobx/lib/mobx";

const isValidName = value => {
  const re = /^[A-Za-z\s]+$/;

  value = value || "";
  const trimmedValue = value.trim();
  return re.test(trimmedValue) && trimmedValue.length >= 3;
};

const isEmailValid = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const isPhoneValid = phone => {
  const re = /^\d+$/;

  phone = phone || "";
  const trimmedPhone = phone.trim();
  return re.test(trimmedPhone) && trimmedPhone.length >= 8;
};

//  TODO change the name of the class to DashboardStore
export default class ReferenceTaskStore {
  @observable fields;

  @observable isSideMenuOpen;

  constructor(initialState = {}) {
    this.isSideMenuOpen = initialState.isSideMenuOpen || false;
    this.fields = {
      firstName: {
        value:
          (initialState.fields &&
            initialState.fields.firstName &&
            initialState.fields.firstName.value) ||
          "",
        isValid:
          (initialState.fields &&
            initialState.fields.firstName &&
            initialState.fields.firstName.isValid) ||
          true
      },
      lastName: {
        value:
          (initialState.fields &&
            initialState.fields.lastName &&
            initialState.fields.lastName.value) ||
          "",
        isValid:
          (initialState.fields &&
            initialState.fields.lastName &&
            initialState.fields.lastName.isValid) ||
          true
      },
      email: {
        value:
          (initialState.fields &&
            initialState.fields.email &&
            initialState.fields.email.value) ||
          "",
        isValid:
          (initialState.fields &&
            initialState.fields.email &&
            initialState.fields.email.isValid) ||
          true
      },
      phone: {
        value:
          (initialState.fields &&
            initialState.fields.phone &&
            initialState.fields.phone.value) ||
          "",
        isValid:
          (initialState.fields &&
            initialState.fields.phone &&
            initialState.fields.phone.isValid) ||
          true
      }
    };
  }

  @computed
  get isFormValid() {
    return (
      this.fields.firstName.isValid &&
      this.fields.lastName.isValid &&
      this.fields.email.isValid &&
      this.fields.phone.isValid
    );
  }

  @action
  changeField = (e, field) => {
    this.fields[field].isValid = true;
    this.fields[field].value = e.target.value;
  };

  @action
  validate = () => {
    this.fields.firstName.isValid = isValidName(this.fields.firstName.value);
    this.fields.lastName.isValid = isValidName(this.fields.lastName.value);
    this.fields.email.isValid = isEmailValid(this.fields.email.value);
    this.fields.phone.isValid = isPhoneValid(this.fields.phone.value);
  };

  @action
  initFields = reference => {
    const {
      referenceEmail,
      referencePhone,
      referenceLastName,
      referenceFirstName
    } = reference || {};

    this.fields.firstName.value = referenceFirstName;
    this.fields.lastName.value = referenceLastName;
    this.fields.email.value = referenceEmail;
    this.fields.phone.value = referencePhone;
  };

  @action
  setSideMenu = () => {
    this.isSideMenuOpen = !this.isSideMenuOpen;
  };
}
