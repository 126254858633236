import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import {
  Row,
  Col,
  TextInput,
  Radio,
  RadioGroup,
  FormGroup,
  FormCard,
  Button,
  Validation,
  DatePicker
} from "cf-neo-ui";

import Routes from "../layout/routes/index";
import validate from "../../utils/validators";
import Location from "../../components/location/location";

import "./styles.scss";
// import metaTags from "../../utils/editMetaData";
// import urls from "../../utils/urls";

@inject("appStore", "postANeedStore")
@observer
class Offer extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      isJobTitleValid: true,
      jobTitleValidationMessage: "",
      isContractTypeValid: true,
      contractTypeValidationMessage: "",
      isPurchaseOrderValid: true,
      purchaseOrderValidationMessage: "",
      isProjectNameValid: true,
      projectNameValidationMessage: "",
      isStartDateValid: true,
      isEndDateValid: true,
      startDateValidationMessage: "",
      endDateValidationMessage: "",
      isAddressValid: true,
      addressValidationMessage: "",
      isWorkRateValid: true,
      workRateValidationMessage: "",
      todayDate: moment(new Date().setHours(0, 0, 0)).valueOf()
    };
    this.startDateChangeHandler = this.startDateChangeHandler.bind(this);
    this.endDateChangeHandler = this.endDateChangeHandler.bind(this);
    this.disableStartDays = this.disableStartDays.bind(this);
    this.disableEndDays = this.disableEndDays.bind(this);
    this.onNextStepHandler = this.onNextStepHandler.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  onNextStepHandler() {
    const valid = this.isFormValid();
    if (!valid) return;
    const { history } = this.props;
    const { Offer2 } = Routes.HbLayoutRoutes;
    history.push(Offer2.path);
  }

  getLocationHandler = (address, formattedAddress) => {
    const { postANeedStore } = this.props;
    postANeedStore.changeAddress({
      address1: address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    });
    this.validateAddress(address);
  };

  addressChangeHandler = v => {
    const { postANeedStore } = this.props;
    postANeedStore.changeAddress({ address1: v });
    this.validateAddress(v);
  };

  jobTitleChangeHandler = e => {
    const { postANeedStore } = this.props;
    postANeedStore.changeJobTitle(e.target.value);
    this.validateJobTitle(e.target.value);
  };

  projectNameChangeHandler = e => {
    const { postANeedStore } = this.props;
    postANeedStore.changeProjectName(e.target.value);
    this.validateProjectName(e.target.value);
  };

  purchaseOrderChangeHandler = e => {
    const { postANeedStore } = this.props;
    postANeedStore.changePurchaseOrder(e.target.value);
    this.validatePurchaseOrder(e.target.value);
  };

  contractTypeChangeHandler = v => {
    const { postANeedStore } = this.props;
    postANeedStore.changeContractType(v);
    if (v === "Cdi") {
      postANeedStore.changeEndDate(null);
      // this.setState({ endDate: null });
    }
    this.validateContractType(v);
  };

  remoteChangeHandler = v => {
    const { postANeedStore } = this.props;
    postANeedStore.changeRemote(v);
  };

  workRateChangeHandler = v => {
    const { postANeedStore } = this.props;
    postANeedStore.changeWorkRate(v);
    this.validateWorkRate(v);
  };

  startDateChangeHandler = value => {
    const { postANeedStore } = this.props;
    const { endDate } = postANeedStore;
    postANeedStore.changeStartDate(value);
    this.validateStartDate(value);
    this.validateEndDate(endDate);
  };

  endDateChangeHandler = value => {
    const { postANeedStore } = this.props;
    const { startDate } = postANeedStore;
    postANeedStore.changeEndDate(value);
    this.validateEndDate(value);
    this.validateStartDate(startDate);
  };

  validateStartDate = value => {
    const { i18n, postANeedStore } = this.props;
    const { endDate } = postANeedStore;
    const { todayDate } = this.state;
    const res = validate(value, ["required"], i18n);
    if (res.isValid === false || isNaN(value) === true) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(t`Champs requis`)
      });
      return false;
    }
    if (endDate === null || isNaN(endDate)) {
      if (value >= todayDate) {
        this.setState({
          isStartDateValid: true,
          startDateValidationMessage: ""
        });
        return true;
      }

      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(t`Champs requis`)
      });
      return false;
    }

    if (value <= endDate && endDate >= todayDate) {
      this.setState({
        isStartDateValid: true,
        startDateValidationMessage: ""
      });
      return true;
    }

    this.setState({
      isStartDateValid: false,
      startDateValidationMessage: i18n._(t`Champs requis`)
    });
    return false;
  };

  validateEndDate = value => {
    const { i18n, postANeedStore } = this.props;
    const { todayDate } = this.state;
    const { startDate } = postANeedStore;

    const res = validate(value, ["required"], i18n);
    if (res.isValid === false || isNaN(value) === true) {
      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(t`Champs requis`)
      });
      return false;
    }
    if (startDate === "" || isNaN(startDate)) {
      if (value >= todayDate) {
        this.setState({
          isEndDateValid: true,
          endDateValidationMessage: ""
        });
        return true;
      }

      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(t`Champs requis`)
      });
      return false;
    }

    if (startDate <= value) {
      this.setState({
        isEndDateValid: true,
        endDateValidationMessage: ""
      });
      return true;
    }

    this.setState({
      isEndDateValid: false,
      endDateValidationMessage: i18n._(t`Champs requis`)
    });
    return false;
  };

  disableStartDays = day => {
    const { todayDate } = this.state;
    return day < todayDate;
  };

  disableEndDays = day => {
    const { postANeedStore } = this.props;
    const { startDate } = postANeedStore;
    const { todayDate } = this.state;
    return day < startDate || day < todayDate;
  };

  isFormValid() {
    const { postANeedStore } = this.props;
    const {
      address,
      jobTitle,
      purchaseOrder,
      contractType,
      projectName,
      workRate,
      startDate,
      endDate
    } = postANeedStore;
    let valid = true;
    //if (!this.validateContractType(contractType)) valid = false;
    if (!this.validateWorkRate(workRate)) valid = false;
    if (!this.validateJobTitle(jobTitle)) valid = false;
    if (!this.validatePurchaseOrder(purchaseOrder)) valid = false;
    if (!this.validateAddress(address.address1)) valid = false;
    if (!this.validateProjectName(projectName)) valid = false;
    if (!this.validateStartDate(startDate)) valid = false;
    if (contractType === "Freelance" || !contractType)
      if (!this.validateEndDate(endDate)) valid = false;
    return valid;
  }

  validateJobTitle(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isJobTitleValid: res.isValid,
      jobTitleValidationMessage: res.message
    });
    return res.isValid;
  }

  validatePurchaseOrder(value) {
    const { i18n } = this.props;
    const res = validate(value, [{ maxlength: 50 }, { minlength: 2 }], i18n);
    this.setState({
      isPurchaseOrderValid: res.isValid,
      purchaseOrderValidationMessage: res.message
    });
    return res.isValid;
  }

  validateAddress(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isAddressValid: res.isValid,
      addressValidationMessage: res.message
    });
    return res.isValid;
  }

  validateProjectName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isProjectNameValid: res.isValid,
      projectNameValidationMessage: res.message
    });
    return res.isValid;
  }

  validateWorkRate(value) {
    const { i18n } = this.props;
    if (!value) {
      this.setState({
        isWorkRateValid: false,
        workRateValidationMessage: i18n._(
          t`vous devez préciser le rythme de travail`
        )
      });
      return false;
    }
    this.setState({
      isWorkRateValid: true,
      workRateValidationMessage: ""
    });
    return true;
  }

  validateContractType(value) {
    const { i18n } = this.props;
    if (!value) {
      this.setState({
        isContractTypeValid: false,
        contractTypeValidationMessage: i18n._(
          t`vous devez préciser le type du contrat`
        )
      });
      return false;
    }
    this.setState({
      isContractTypeValid: true,
      contractTypeValidationMessage: ""
    });
    return true;
  }

  render() {
    const {
      isJobTitleValid,
      jobTitleValidationMessage,
      isProjectNameValid,
      projectNameValidationMessage,
      isStartDateValid,
      isEndDateValid,
      startDateValidationMessage,
      endDateValidationMessage,
      isContractTypeValid,
      contractTypeValidationMessage,
      purchaseOrderValidationMessage,
      isPurchaseOrderValid,
      isAddressValid,
      addressValidationMessage,
      isWorkRateValid,
      workRateValidationMessage
    } = this.state;
    const { i18n, appStore, postANeedStore } = this.props;
    const {
      contractType,
      projectName,
      jobTitle,
      purchaseOrder,
      address,
      remote,
      workRate,
      changeStep,
      startDate,
      endDate
    } = postANeedStore;

    let N;
    if (contractType === "Freelance" || !contractType) {
      N = 6;
    } else {
      N = 12;
    }

    changeStep(2);
    const isMobile = appStore.width <= 498;
    return (
      <div className="session signup-profile">
        <br />

        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Offre</Trans>
            </h1>
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter with-padding">
          <Row>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="input_job_title">
                  <Trans>Intitulé</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Validation
                  errorMessage={jobTitleValidationMessage}
                  valid={isJobTitleValid}
                >
                  <TextInput
                    id="input_job_title"
                    className="form-input"
                    placeholder={i18n._(t`Ex:Architecte Java, Consultant SAP`)}
                    iconColor="#D3354A"
                    value={jobTitle}
                    onChange={this.jobTitleChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            {/*<Col lg={3} xl={3} md={3} sd={3}>*/}
            {/*  <FormGroup>*/}
            {/*    <label className="form-label" htmlFor="remoteSearch">*/}
            {/*      <Trans>Type de contrat</Trans>*/}
            {/*      <span className="asterisc">*</span>*/}
            {/*    </label>*/}
            {/*    <Validation*/}
            {/*      errorMessage={contractTypeValidationMessage}*/}
            {/*      valid={isContractTypeValid}*/}
            {/*    >*/}
            {/*      <RadioGroup*/}
            {/*        id="contractType"*/}
            {/*        name="RadioGrp"*/}
            {/*        defaultSelected={contractType}*/}
            {/*        onChange={this.contractTypeChangeHandler}*/}
            {/*        orientation={isMobile ? "vertical" : "horizontal"}*/}
            {/*        className="remote-radio-group"*/}
            {/*      >*/}
            {/*        <Radio label={i18n._(t`Cdi`)} value="Cdi" />*/}
            {/*        <Radio label={i18n._(t`Freelance`)} value="Freelance" />*/}
            {/*      </RadioGroup>*/}
            {/*    </Validation>*/}
            {/*  </FormGroup>*/}
            {/*</Col>*/}
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="input_purchase_order">
                  <Trans>Bon de commande</Trans>
                </label>
                <Validation
                  errorMessage={purchaseOrderValidationMessage}
                  valid={isPurchaseOrderValid}
                >
                  <TextInput
                    id="input_purchase_order"
                    defaultvalue={null}
                    className="small-input form-input"
                    placeholder={i18n._(t`Référence du bon de commande`)}
                    value={purchaseOrder}
                    onChange={this.purchaseOrderChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={N} xl={N} md={N} sd={N}>
              <FormGroup>
                <label className="form-label" htmlFor="experienceStart">
                  <Trans>Date de début</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Validation
                  errorMessage={startDateValidationMessage}
                  valid={isStartDateValid}
                >
                  <DatePicker
                    id="experienceStart"
                    disabledDaysMatcher={day => this.disableStartDays(day)}
                    dateFormat="DD/MM/YYYY"
                    autoComplete="off"
                    defaultDate={
                      startDate
                        ? moment.unix(startDate / 1000).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={value =>
                      this.startDateChangeHandler(moment(value).valueOf())
                    }
                    locale={i18n._(t`fr`)}
                    acceptWhenOutsideClick
                  />
                </Validation>
              </FormGroup>
            </Col>
            {(contractType === "Freelance" || !contractType) && (
              <Col lg={6} xl={6} md={6} sd={6}>
                <FormGroup>
                  <label className="form-label" htmlFor="experienceEnd">
                    <Trans>Date de fin</Trans>
                    <span className="asterisc">*</span>
                  </label>
                  <Validation
                    errorMessage={endDateValidationMessage}
                    valid={isEndDateValid}
                  >
                    <DatePicker
                      id="experienceEnd"
                      disabledDaysMatcher={day => this.disableEndDays(day)}
                      dateFormat="DD/MM/YYYY"
                      autoComplete="off"
                      defaultDate={
                        endDate
                          ? moment.unix(endDate / 1000).format("DD/MM/YYYY")
                          : ""
                      }
                      onChange={value =>
                        this.endDateChangeHandler(moment(value).valueOf())
                      }
                      locale={i18n._(t`fr`)}
                      acceptWhenOutsideClick
                    />
                  </Validation>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="locationSearch">
                  <Trans>Localisation</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Validation
                  errorMessage={addressValidationMessage}
                  valid={isAddressValid}
                >
                  <Location
                    id="locationSearch"
                    onChange={this.addressChangeHandler}
                    value={address.address1}
                    getLocation={(theAddress, formattedAddress) =>
                      this.getLocationHandler(theAddress, formattedAddress)
                    }
                    valid={isAddressValid}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="remoteSearch">
                  <Trans>Remote/Régie</Trans>
                  <span className="asterisc">*</span>
                </label>
                <RadioGroup
                  id="remoteSearch"
                  name="RadioGrp"
                  defaultSelected={remote}
                  onChange={this.remoteChangeHandler}
                  orientation={isMobile ? "vertical" : "horizontal"}
                  className="remote-radio-group"
                >
                  <Radio label={i18n._(t`Remote`)} value="Remote" />
                  <Radio label={i18n._(t`Régie`)} value="Onsite" />
                  <Radio
                    label={i18n._(t`Remote partiel`)}
                    value="Part-time remote"
                  />
                  <Radio label={i18n._(t`Indifférent`)} value="Indifferent" />
                </RadioGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="project_name">
                  <Trans>Projet</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Validation
                  errorMessage={projectNameValidationMessage}
                  valid={isProjectNameValid}
                >
                  <TextInput
                    id="project_name"
                    className="form-input"
                    placeholder={i18n._(
                      t`Ajoutez un nom de projet pour retrouver facilement les offres associées`
                    )}
                    iconColor="#D3354A"
                    value={projectName}
                    onChange={this.projectNameChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="workRate">
                  <Trans>Rythme de travail</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Validation
                  errorMessage={workRateValidationMessage}
                  valid={isWorkRateValid}
                >
                  <RadioGroup
                    id="workRate"
                    name="RadioGrp"
                    defaultSelected={workRate}
                    onChange={this.workRateChangeHandler}
                    orientation={isMobile ? "vertical" : "horizontal"}
                    className="remote-radio-group"
                  >
                    <Radio label={i18n._(t`Temps plein`)} value="full-time" />
                    <Radio label={i18n._(t`Temps partiel`)} value="part-time" />
                  </RadioGroup>
                </Validation>
              </FormGroup>
            </Col>
          </Row>
        </FormCard>
        <br />

        <div className="next-buttons">
          <Button icon="chevron-right" onClick={this.onNextStepHandler}>
            <Trans>Etape suivante</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

Offer.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  postANeedStore: PropTypes.shape({
    changeStep: PropTypes.func,
    changeContractType: PropTypes.func,
    changePurchaseOrder: PropTypes.func,
    changeJobTitle: PropTypes.func,
    changeProjectName: PropTypes.func,
    changeRemote: PropTypes.func,
    changeWorkRate: PropTypes.func,
    changeAddress: PropTypes.func,
    contractType: PropTypes.string,
    jobTitle: PropTypes.string,
    purchaseOrder: PropTypes.string,
    address: PropTypes.shape,
    remote: PropTypes.string,
    workRate: PropTypes.string,
    projectName: PropTypes.string,
    changeStartDate: PropTypes.func,
    changeEndDate: PropTypes.func,
    startDate: PropTypes.number,
    endDate: PropTypes.number
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};
export default withI18n()(withRouter(Offer));
