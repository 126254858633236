import gql from "graphql-tag";

const SEND_MESSAGE_TIME_SHEET = gql`
  mutation SendMessageTimesheet(
    $timesheetID: Int!
    $date: Float
    $message: String!
    $comment: String
  ) {
    sendMessageTimesheet(
      timesheetID: $timesheetID
      date: $date
      message: $message
      comment: $comment
    ) {
      timesheetID
      dateAdded
      dateLastUpdate
      messages {
        _id
        text
        date
        speaker
      }
      comment
    }
  }
`;

export default SEND_MESSAGE_TIME_SHEET;
