import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react/index";
import { Icon } from "cf-neo-ui";
import classes from "./dashboardHeader.module.scss";

@inject("referenceTask")
@observer
class DashBoardHeader extends Component {
  render() {
    const { referenceTask } = this.props;
    const { setSideMenu } = referenceTask;
    const w = typeof window !== "undefined" ? window.innerWidth : 1024;
    return (
      <div className={classes.dashRespHeader}>
        <div className={classes.actionBtn}>
          <Icon type="bars" width={14} height={12} onClick={setSideMenu} />
        </div>
        <div className={classes.logo}>
          {w > 1024 ? (
            <Link to="/missions">
              <picture>
                <source
                  type="images/webp"
                  srcSet="/assets/images/dashboard-header-logo.webp"
                />
                <img
                  src="/assets/images/dashboard-header-logo.png"
                  alt="club-freelance"
                />
              </picture>
            </Link>
          ) : (
            <Link to="/enterprise">
              <picture>
                <source
                  type="images/webp"
                  srcSet="/assets/images/dashboard-header-logo.webp"
                />
                <img
                  src="/assets/images/dashboard-header-logo.png"
                  alt="club-freelance"
                />
              </picture>
            </Link>
          )}
        </div>
      </div>
    );
  }
}

DashBoardHeader.wrappedComponent.propTypes = {
  referenceTask: PropTypes.shape({
    setSideMenu: PropTypes.func
  }).isRequired
};

export default DashBoardHeader;
