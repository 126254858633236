export default {
  center: true,
  autoWidth: true,
  items: 1,
  dots: true,
  // loop: true,
  margin: 20,
  responsive: {
    550: {
      margin: 60
    },
    680: {
      margin: 120
    },
    768: {
      margin: 20
    },
    1024: {
      margin: 80
    },
    1600: {
      margin: 300
    }
  }
};
