import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react/index";
import { BloggerCard } from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import classes from "./BloggerSection.module.scss";
import routes from "../../layout/routes";

class BloggerSection extends PureComponent {
  toContact = () => {
    const { history } = this.props;
    history.push(routes.HbfLayoutRoutes.about.path);
  };

  render() {
    const { i18n, blogger } = this.props;
    return (
      <div className={classes.cards}>
        <BloggerCard
          className={classes.card}
          avatar={
            blogger.avatar_url ? blogger.avatar_url : "/defaultAvatar.png"
          }
          title={`${i18n._(t`Par`)} ${blogger.name}`}
          description={blogger.description}
        />
        <BloggerCard
          className={classes.card}
          title={i18n._(t`Club Freelance`)}
          description={i18n._(
            t`Nous identifions les esprits brillants et les associons avec les marques les plus innovantes.`
          )}
          buttonLabel={i18n._(t`En savoir plus`)}
          buttonClick={this.toContact}
        />
      </div>
    );
  }
}

BloggerSection.propTypes = {
  blogger: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    avatar_url: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(BloggerSection));
