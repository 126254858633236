export default {
  center: true,
  items: 2,
  loop: true,
  autoWidth: true,
  margin: 40,
  dots: true,
  dotsClass: "owlDots",
  dotClass: "owlDot"
};
