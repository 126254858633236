import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import {
  RadioGroup,
  Radio,
  Col,
  Collapse,
  InputSkeleton,
  Button
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import classes from "./SideFilters.module.scss";
import DateWidget from "./widgets/DateWidget";
import CategoriesWidget from "./widgets/CategoriesWidget";
import EmploymentTypeWidget from "./widgets/EmploymentTypeWidget";
import OnSiteWidget from "./widgets/OnSiteWidget";
import SkillsWidget from "./widgets/SkillsWidget";
import BusinessSectorWidget from "./widgets/BusinessSectorWidget";
import AlertWidget from "./widgets/AlertWidget";
import LocationWidget from "./widgets/LocationWidget";
import SpecialtiesWidget from "./widgets/SpecialtiesWidget";
import {
  GET_CATEGORIES,
  GET_SKILLS,
  GET_BUSINESS_SECTORS,
  GET_SPECIALTIES
} from "../../queries";

@inject("missionsSearchStore")
@observer
class SideFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0
    };
  }

  clearFilters = () => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.clearFilters();
    const { key } = this.state;
    this.setState({ key: key + 1 });
  };

  render() {
    const { i18n, missionsSearchStore } = this.props;
    const { key } = this.state;
    const { isSideFilterOpen } = missionsSearchStore;
    return (
      <Col
        noGutter
        lg={3}
        md={3}
        sm={6}
        xs={6}
        className={`${classes.sideFilter} ${
          isSideFilterOpen ? classes.active : ""
        }`}
      >
        <aside>
          <AlertWidget />
          {/* <Collapse title={i18n._(t`Type de contract`)}> */}
          {/*  <div className={classes.widgetBody}> */}
          {/*    <EmploymentTypeWidget /> */}
          {/*  </div> */}
          {/* </Collapse> */}
          <Collapse title={i18n._(t`Domaine d’expertise`)}>
            <div className={classes.widgetBody}>
              <Query query={GET_CATEGORIES}>
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) return `${i18n._(t`Erreur`)} !`;

                  const { categories } = data;
                  return (
                    <CategoriesWidget
                      key={key}
                      options={categories.map(item => item.name)}
                    />
                  );
                }}
              </Query>
            </div>
          </Collapse>
          <Collapse title={i18n._(t`Spécialités`)}>
            <div className={classes.widgetBody}>
              <Query query={GET_SPECIALTIES}>
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) return `${i18n._(t`Erreur`)} !`;

                  const { specialties } = data;
                  return (
                    <SpecialtiesWidget
                      key={key}
                      options={specialties.map(item => item.name)}
                    />
                  );
                }}
              </Query>
            </div>
          </Collapse>
          <Collapse title={i18n._(t`Compétence`)}>
            <div className={classes.widgetBody}>
              <Query query={GET_SKILLS}>
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) return `${i18n._(t`Erreur`)} !`;

                  const { skills } = data;
                  return (
                    <SkillsWidget
                      key={key}
                      options={skills.map(item => item.name)}
                    />
                  );
                }}
              </Query>
            </div>
          </Collapse>
          <Collapse title={i18n._(t`Localisation`)}>
            <div className={classes.widgetBody}>
              <LocationWidget key={key} />
              <p style={{ display: "none" }} className={classes.widgetSubtitle}>
                <Trans>Lieu de travail</Trans>
              </p>
              <RadioGroup
                style={{ display: "none" }}
                className={classes.collapseRadioGroup}
                name="localisation"
                defaultSelected={3}
              >
                <Radio label={i18n._(t`Régie`)} value={1} />
                <Radio label={i18n._(t`Remote`)} value={2} />
                <Radio label={i18n._(t`Indifférent`)} value={3} />
              </RadioGroup>
            </div>
          </Collapse>
          <DateWidget key={key} />
          <Collapse title={i18n._(t`Remote/Régie`)}>
            <div className={classes.widgetBody}>
              <OnSiteWidget />
            </div>
          </Collapse>
          <Collapse title={i18n._(t`Secteur`)}>
            <div className={classes.widgetBody}>
              <Query query={GET_BUSINESS_SECTORS}>
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) return `${i18n._(t`Erreur`)} !`;

                  const { businessSectors } = data;
                  return (
                    <BusinessSectorWidget
                      key={key}
                      options={businessSectors.map(item => item.name)}
                    />
                  );
                }}
              </Query>
            </div>
          </Collapse>
          <div className={classes.clearAll}>
            <Button variant="secondary" onClick={this.clearFilters}>
              <Trans>Réinitialiser</Trans>
            </Button>
          </div>
        </aside>
      </Col>
    );
  }
}

SideFilters.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  missionsSearchStore: PropTypes.shape({
    isSideFilterOpen: PropTypes.bool,
    clearFilters: PropTypes.func
  }).isRequired
};

export default withI18n()(SideFilters);
