import { PureComponent } from "react";
import * as PropTypes from "prop-types";

class MutateOnDidMount extends PureComponent {
  componentDidMount() {
    const { mutate } = this.props;
    mutate();
  }

  render() {
    return null;
  }
}

MutateOnDidMount.propTypes = {
  mutate: PropTypes.func
};
MutateOnDidMount.defaultProps = {
  mutate: () => {}
};

export default MutateOnDidMount;
