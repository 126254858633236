import React, { Component } from "react";
import PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { AutoComplete, TagList } from "cf-neo-ui";
import { inject, observer } from "mobx-react/index";
import classes from "../SideFilters.module.scss";
import theme from "../../../../../configs/theme";

@inject("missionsSearchStore")
@observer
class SpecialtiesWidget extends Component {
  constructor(props) {
    super(props);
    props.missionsSearchStore.initSpecialtyOptions(props.options);
  }

  selectHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.addSpecialty(val);
    missionsSearchStore.setSpecialtyOptions();
  };

  closeTagHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.removeSpecialty(val);
    missionsSearchStore.setSpecialtyOptions();
  };

  render() {
    const { i18n, missionsSearchStore } = this.props;
    return (
      <div>
        <AutoComplete
          key={missionsSearchStore.specialties.slice().length}
          restrictedToList
          clearOnSelect
          options={missionsSearchStore.specialtyOptions.slice()}
          maxSuggestion={10}
          iconColor={theme.colors.mainColor}
          iconColor2={theme.colors.lightMainColor}
          icon="search"
          placeholder={i18n._(t`Chercher des spécialités`)}
          onSelect={this.selectHandler}
        />
        <TagList
          tags={missionsSearchStore.specialties}
          variant="secondary"
          closable
          className={classes.tagList}
          onClose={this.closeTagHandler}
        />
      </div>
    );
  }
}

SpecialtiesWidget.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.shape({
    initSpecialtyOptions: PropTypes.func,
    addSpecialty: PropTypes.func,
    setSpecialtyOptions: PropTypes.func,
    removeSpecialty: PropTypes.func,
    specialties: PropTypes.arrayOf(PropTypes.string),
    specialtyOptions: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withI18n()(SpecialtiesWidget);
