import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import {
  Button,
  Col,
  Divider,
  FormCard,
  FormGroup,
  Row,
  Textarea,
  Spinner,
  TextInput,
  toaster,
  Validation
} from "cf-neo-ui";
import { Link, withRouter } from "react-router-dom";
import { Mutation } from "react-apollo";
import { t, Trans } from "@lingui/macro";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import "./SingleCandidateModal.scss";
import gql from "graphql-tag";

const CREATE_CLIENT_INTEREST = gql`
  mutation createClientInterestMutation(
    $clientId: Int!
    $candidateBhId: Int!
    $message: String
    $jobDescription: String
  ) {
    createClientInterest(
      clientId: $clientId
      candidateBhId: $candidateBhId
      message: $message
      jobDescription: $jobDescription
    ) {
      note
    }
  }
`;

@inject("sessionStore", "appStore", "modalStore", "candidatesSearchStore")
@observer
class SingleCandidateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDescriptionValid: true,
      descriptionValidationMessage: ""
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  mutationCompleted = () => {
    const { i18n, close, candidateBhId, candidatesSearchStore } = this.props;
    const { changeDescription } = candidatesSearchStore;
    close();
    changeDescription("");
    toaster.success({
      title: i18n._(t`Email envoyé avec succées`),
      description:
        i18n._(t`Vous pouvez maintenant suivre le candidat`) + candidateBhId
    });
  };

  render() {
    const { isDescriptionValid, descriptionValidationMessage } = this.state;
    const {
      i18n,
      sessionStore,
      candidateBhId,
      candidatesSearchStore
    } = this.props;
    const {
      jobDescription,
      description,
      changeDescription
    } = candidatesSearchStore;
    const { id } = sessionStore;
    const clientId = id;
    return (
      <div className="single-candidate-page-modal-session single-candidate-page-modal-container single-candidate-page-modal-signup-selection">
        <FormCard>
          <Row>
            <Col
              className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center"
              style={{ textAlign: "center" }}
            >
              <Trans>
                Le consultant sera qualifié par notre équipe de sourceurs.
              </Trans>
              <br />
              <Trans>
                Nous vous contacteront au plus vite afin d'échanger sur votre
                besoin.
              </Trans>
              <br />
              <Trans>Merci!</Trans>
            </Col>
          </Row>
          <Row className="single-candidate-page-modal-center">
            <Col>
              <FormGroup>
                <Validation
                  errorMessage={descriptionValidationMessage}
                  valid={isDescriptionValid}
                >
                  <Textarea
                    style={{ textAlign: "center" }}
                    size="xlarge"
                    placeholder={i18n._(
                      t`Voulez-vous ajouter des informations sur votre projet?`
                    )}
                    rows="2"
                    onChange={e => changeDescription(e.target.value)}
                    value={description}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="single-candidate-page-modal-form-card-content single-candidate-page-modal-center">
              <div className="single-candidate-page-modal-center">
                <Mutation
                  mutation={CREATE_CLIENT_INTEREST}
                  variables={{
                    clientId,
                    candidateBhId,
                    message: description,
                    jobDescription
                  }}
                  onCompleted={this.mutationCompleted}
                  onError={errors => {
                    errors.graphQLErrors.forEach(({ message }) => {
                      if (message) {
                        this.onErrorHandler(message);
                      }
                    });
                  }}
                >
                  {(mutation, { loading }) => (
                    <Button
                      icon="chevron-right"
                      iconPosition="right"
                      onClick={() => mutation()}
                    >
                      {loading ? (
                        <Spinner
                          type="pointed-circular"
                          color="#FFFFFF"
                          size={12}
                        />
                      ) : (
                        <Trans>Confirmer</Trans>
                      )}
                    </Button>
                  )}
                </Mutation>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

SingleCandidateModal.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  candidateBhId: PropTypes.number,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.number,
    signUpUserRole: PropTypes.string,
    reset: PropTypes.func,
    changeSignUpUserRole: PropTypes.func,
    changeExpressSignUp: PropTypes.func
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    jobDescription: PropTypes.string,
    description: PropTypes.string,
    changeDescription: PropTypes.func
  }).isRequired,
  close: PropTypes.func,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(SingleCandidateModal));
