import React, { Component } from "react";
import cookie from "react-cookies";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import {
  Row,
  Col,
  TextInput,
  Radio,
  RadioGroup,
  FormGroup,
  FormCard,
  Button,
  DatePicker,
  Spinner,
  Checkbox,
  ProgressBar,
  CheckboxGroup
} from "cf-neo-ui";

import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Routes from "../layout/routes/index";
import validate from "../../utils/validators";
import Location from "../../components/location/location";
import "./styles.scss";
import runtimeVars from "../../configs/runTimeVars";
import login from "./login";
import { GET_USER_INFO } from "../../components/hoc/queries";

const UPDATE_MY_SERACH_MUTATION = gql`
  mutation updateMySerachCandidateMutation(
    $remote: String
    $availability: Float
    $searchAddress: AddressInput!
    $dayRate: Float
    $mobility: Float
    $salary: Float
    $employmentPreference: String
  ) {
    updateMySerachCandidate(
      input: {
        remote: $remote
        availability: $availability
        searchAddress: $searchAddress
        dayRate: $dayRate
        mobility: $mobility
        employmentPreference: $employmentPreference
        salary: $salary
      }
    ) {
      account {
        id
        name
        path
        step
        isCompleted
      }
      candidate {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
// const UPDATE_ACCOUNT_MUTATION = gql`
//   mutation UpdateAccountMutation(
//     $_id: String
//     $firstName: String
//     $lastName: String
//     $phonenumber: String
//     $role: String!
//     $title: String
//     $experience: Int
//     $link: String
//     $address: AddressInput
//     $preferredContact: String
//     $receiveNewsletter: Boolean
//     $remote: String
//     $availability: Float
//     $searchAddress: AddressInput!
//     $dayRate: Float
//     $mobility: Float
//     $candidateWorkHistory: [ExperienceCreateInput]
//     $categories: [String]!
//     $skills: [String]!
//     $specialties: [String]!
//     $cv: Upload
//     $profilePhoto: Upload
//     $pictureUrl: String
//     $customTextBlock1: String
//     $spokenLanguages: [String]
//   ) {
//     updateAccount(
//       input: {
//         _id: $_id
//         firstName: $firstName
//         lastName: $lastName
//         phonenumber: $phonenumber
//         role: $role
//         title: $title
//         experience: $experience
//         link: $link
//         address: $address
//         preferredContact: $preferredContact
//         receiveNewsletter: $receiveNewsletter
//         profilePhoto: $profilePhoto
//         pictureUrl: $pictureUrl
//         remote: $remote
//         availability: $availability
//         searchAddress: $searchAddress
//         dayRate: $dayRate
//         candidateWorkHistory: $candidateWorkHistory
//         categories: $categories
//         skills: $skills
//         mobility: $mobility
//         specialties: $specialties
//         cv: $cv
//         customTextBlock1: $customTextBlock1
//         spokenLanguages: $spokenLanguages
//       }
//     ) {
//       status
//     }
//   }
// `;

@inject("sessionStore", "appStore")
@observer
class MySearch extends Component {
  constructor(props) {
    super(props);
    const { sessionStore } = props;
    const { employmentPreference } = sessionStore;

    this.state = {
      isAvailabilityValid: true,
      isSearchAddressValid: true,
      isDayRateValid: true,
      isMobilityValid: true,
      salaryValidationMessage: "",
      availabilityValidationMessage: "",
      searchAddressValidationMessage: "",
      isEmploymentPreferenceValid: true,
      employmentPreferenceValidationMessage: "",
      dayRateValidationMessage: "",
      isSalaryValid: true,
      mobilityValidationMessage: "",
      saveCase: false
    };

    this.isFormValid = this.isFormValid.bind(this);
    let employmentPreferenceArray = [];
    if (employmentPreference)
      employmentPreferenceArray = employmentPreference.split(",");

    let isPermanent = false;
    let isContract = false;
    employmentPreferenceArray.map(item => {
      if (item === "Permanent") isPermanent = true;
      if (item === "Contract") isContract = true;
      return null;
    });
  }

  salaryChangeHandler = event => {
    const { sessionStore } = this.props;
    sessionStore.changeSalary(event.target.value);
    this.validateSalary(event.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  validateSalary(value) {
    const { i18n } = this.props;
    const res = validate(value, ["number", { maxlength: 12 }], i18n);
    this.setState({
      isSalaryValid: res.isValid,
      salaryValidationMessage: res.message
    });
    return res.isValid;
  }

  isFormValid() {
    const { sessionStore } = this.props;
    const {
      availability,
      searchAddress,
      salary,
      dayRate,
      mobility,
      employmentPreference
    } = sessionStore;
    let valid = true;
    const { isPermanent, isContract } = this.state;

    if (!this.validateEmploymentPreference(employmentPreference)) valid = false;
    if (!this.validateAvailability(availability)) valid = false;
    if (!this.validateSearchAddress(searchAddress.address1)) valid = false;
    if (!this.validateMobility(mobility)) valid = false;
    if (isPermanent) if (!this.validateSalary(salary)) valid = false;
    if (isContract) if (!this.validateDayRate(dayRate)) valid = false;

    return valid;
  }
  employmentPreferenceChangeHandler = v => {
    if (Array.isArray(v)) {
      const { sessionStore } = this.props;
      sessionStore.changeEmploymentPreference(v.toString());
      this.validateEmploymentPreference(v);
      this.setState({ isPermanent: false });
      this.setState({ isContract: false });
      v.map(item => {
        if (item === "Permanent") this.setState({ isPermanent: true });
        if (item === "Contract") this.setState({ isContract: true });
        return null;
      });
    }
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  updateAccountMutationCompletedHandler = () => {
    cookie.remove("firstVisitOrigin", { path: "/" });
    const { Confirm } = Routes.HbLayoutRoutes;
    const { history } = this.props;
    localStorage.removeItem("Experiences");
    history.push(Confirm.path);
  };
  // onErrorHandler = msg => {
  //   const { AccountCreation } = Routes.HbLayoutRoutes;
  //   const { i18n, sessionStore, history } = this.props;
  //   if (
  //     msg ===
  //     'Unexpected error value: "Désolé ,mais aucun compte existe avec cet email, Veillez vérifier votre e-mail et refaire le procédure de nouveau"'
  //   ) {
  //     toaster.error({
  //       title: i18n._(t`Erreur`),
  //       description: i18n._(
  //         t`Désolé ,mais aucun compte existe avec cet email, Veillez vérifier votre e-mail et refaire le procédure de nouveau`
  //       )
  //     });
  //     sessionStore.reset();
  //     history.push(AccountCreation.path);
  //   }
  //   if (
  //     msg ===
  //     'Unexpected error value: "Oops , un candidat déjà existe avec ces coordonnées, Veillez refaire le procédure de nouveau"'
  //   ) {
  //     toaster.error({
  //       title: i18n._(t`Erreur`),
  //       description: i18n._(
  //         t`Oops , un candidat déjà existe avec ces coordonnées, Veillez refaire le procédure de nouveau`
  //       )
  //     });
  //     sessionStore.reset();
  //     history.push(AccountCreation.path);
  //   } else {
  //     toaster.error({
  //       title: i18n._(t`Erreur`),
  //       description: msg
  //     });
  //   }
  // };
  availabilityChangeHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.changeAvailability(value);
    this.validateAvailability(value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  remoteChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changeRemote(v);
  };

  getLocationHandler = (address, formattedAddress) => {
    const { sessionStore } = this.props;
    sessionStore.changeSearchAddress({
      address1: address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      zip: formattedAddress.zip,
      state: formattedAddress.state,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    });
    this.validateSearchAddress(address);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  searchAddressChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changeSearchAddress({ address1: v });
    this.validateSearchAddress(v);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  dayRateChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeDayRate(e.target.value);
    this.validateDayRate(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  mobilityChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeMobility(e);
    this.validateMobility(e);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };
  validateEmploymentPreference(value) {
    const { i18n } = this.props;
    if (value && value.length === 0) {
      this.setState({
        isEmploymentPreferenceValid: false,
        employmentPreferenceValidationMessage: i18n._(t`Non renseigné`)
      });
      return false;
    }
    this.setState({
      isEmploymentPreferenceValid: true,
      employmentPreferenceValidationMessage: ""
    });
    return true;
  }

  validateAvailability(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isAvailabilityValid: res.isValid,
      availabilityValidationMessage: res.message
    });
    return res.isValid;
  }

  validateSearchAddress(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isSearchAddressValid: res.isValid,
      searchAddressValidationMessage: i18n._(t`Non renseigné`)
    });
    return res.isValid;
  }

  validateDayRate(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 12 }],
      i18n
    );
    this.setState({
      isDayRateValid: res.isValid,
      dayRateValidationMessage: res.message
    });
    return res.isValid;
  }

  validateMobility(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required", "number", { maxlength: 5 }], i18n);
    this.setState({
      isMobilityValid: res.isValid,
      mobilityValidationMessage: res.message
    });
    return res.isValid;
  }

  render() {
    const customTextBlock1 = cookie.load("firstVisitOrigin") || "direct url";
    const { sessionStore, i18n, appStore } = this.props;
    const {
      changeStep,
      remote,
      availability,
      searchAddress,
      dayRate,
      mobility,
      employmentPreference,
      signUpUserRole
    } = sessionStore;

    changeStep(7);
    const { isSearchAddressValid, isPermanent, isContract } = this.state;
    const { salary, authToken } = sessionStore;
    let employmentPreferenceArray = [];
    if (employmentPreference)
      employmentPreferenceArray = employmentPreference.split(",");

    const role = signUpUserRole;
    const isMobile = appStore.width <= 498;
    return (
      <div className="session signup-profile">
        <br />

        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Ma recherche</Trans>
            </h1>
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter with-padding">
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <FormGroup>*/}
          {/*      <label*/}
          {/*        className="form-label"*/}
          {/*        htmlFor="employmentPreferenceSearch"*/}
          {/*      >*/}
          {/*        <Trans>Type de contrat</Trans>*/}
          {/*      </label>*/}

          {/*      <CheckboxGroup*/}
          {/*        name="CheckGrpContactType"*/}
          {/*        defaultSelected={employmentPreferenceArray}*/}
          {/*        onChange={this.employmentPreferenceChangeHandler}*/}
          {/*        orientation={isMobile ? "vertical" : "horizontal"}*/}
          {/*        className="contract-radio-group"*/}
          {/*      >*/}
          {/*        <Checkbox label={i18n._(t`CDI`)} value="Permanent" />*/}
          {/*        <Checkbox label={i18n._(t`FreelanceType`)} value="Contract" />*/}
          {/*      </CheckboxGroup>*/}
          {/*    </FormGroup>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="disponibilitySearch">
                  <Trans>Disponibilité</Trans>
                </label>
                <DatePicker
                  id="disponibilitySearch"
                  dateFormat="DD/MM/YYYY"
                  defaultDate={
                    availability
                      ? moment.unix(availability / 1000).format("DD/MM/YYYY")
                      : ""
                  }
                  onChange={value =>
                    this.availabilityChangeHandler(moment(value).valueOf())
                  }
                  locale={runtimeVars.APP_LANG}
                />
              </FormGroup>
            </Col>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="remoteSearch">
                  <Trans>Remote/Régie</Trans>
                </label>
                <RadioGroup
                  id="remoteSearch"
                  name="RadioGrp"
                  defaultSelected={remote}
                  onChange={this.remoteChangeHandler}
                  orientation={isMobile ? "vertical" : "horizontal"}
                  className="remote-radio-group"
                >
                  <Radio label={i18n._(t`Remote`)} value="Remote" />
                  <Radio label={i18n._(t`Régie`)} value="Onsite" />
                  <Radio
                    label={i18n._(t`Remote partiel`)}
                    value="Part-time remote"
                  />
                  <Radio label={i18n._(t`Indifférent`)} value="Indifferent" />
                </RadioGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="locationSearch">
                  <Trans>Localisation</Trans>
                </label>

                <Location
                  id="locationSearch"
                  onChange={this.searchAddressChangeHandler}
                  value={searchAddress.address1}
                  getLocation={(address, formattedAddress) =>
                    this.getLocationHandler(address, formattedAddress)
                  }
                  valid={isSearchAddressValid}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="mobilitySearch">
                  <Trans>Mobilité</Trans>
                </label>

                {/* <TextInput
                  id="mobilitySearch"
                  type="number"
                  className="form-input"
                  placeholder={i18n._(t`Dans un rayon max de XX km`)}
                  value={mobility}
                  onChange={this.mobilityChangeHandler}
                /> */}
                <ProgressBar
                  id="mobilitySearch"
                  min={0}
                  max={500}
                  step={10}
                  defaultValue={0}
                  value={mobility}
                  unit="Km"
                  onChange={this.mobilityChangeHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {/*{isPermanent && (*/}
            {/*  <Col>*/}
            {/*    <FormGroup>*/}
            {/*      <label className="form-label" htmlFor="salarySearch">*/}
            {/*        <Trans>Salaire annuel</Trans>*/}
            {/*      </label>*/}

            {/*      <TextInput*/}
            {/*        id="Salaire annuel"*/}
            {/*        type="number"*/}
            {/*        placeholder={i18n._(t`Salaire`)}*/}
            {/*        value={salary}*/}
            {/*        onChange={this.salaryChangeHandler}*/}
            {/*      />*/}
            {/*    </FormGroup>*/}
            {/*  </Col>*/}
            {/*)}*/}
            {/*{isContract && (*/}
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="dayRateSearch">
                  <Trans>Taux journalier moyen</Trans>
                </label>

                <TextInput
                  id="dayRateSearch"
                  type="number"
                  placeholder={i18n._(t`Par jour`)}
                  value={dayRate}
                  onChange={this.dayRateChangeHandler}
                />
              </FormGroup>
            </Col>
            {/*)}*/}
          </Row>
        </FormCard>
        <br />

        <div className="next-buttons">
          <Mutation
            mutation={UPDATE_MY_SERACH_MUTATION}
            variables={{
              remote,
              availability,
              searchAddress,
              dayRate: parseFloat(dayRate),
              mobility: parseFloat(mobility),
              salary: parseFloat(salary),
              employmentPreference
            }}
            refetchQueries={[
              {
                query: GET_USER_INFO,
                variables: { token: authToken }
              }
            ]}
            onCompleted={data => {
              this.updateAccountMutationCompletedHandler(data);
            }}
            onError={errors => {
              errors.graphQLErrors.forEach(({ message }) => {
                if (message) {
                  //this.onErrorHandler(message);
                }
              });
            }}
          >
            {(mutation, { loading }) => (
              <Button
                disabled={loading}
                onClick={() => {
                  return mutation();
                }}
              >
                {loading ? (
                  <Spinner type="pointed-circular" color="#FFFFFF" size={12} />
                ) : (
                  <Trans>Soumettre</Trans>
                )}
              </Button>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}

MySearch.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    reset: PropTypes.func,
    changeRemote: PropTypes.func,
    resetFields: PropTypes.func,
    changeAvailability: PropTypes.func,
    changeMobility: PropTypes.func,
    changeDayRate: PropTypes.func,
    changeSalary: PropTypes.func,
    changeSearchAddress: PropTypes.func,
    changeStep: PropTypes.func,
    availability: PropTypes.number,
    dayRate: PropTypes.number,
    mobility: PropTypes.number,
    remote: PropTypes.string,
    salary: PropTypes.number,
    searchAddress: PropTypes.object,
    spokenLanguages: PropTypes.shape,
    skills: PropTypes.shape,
    categories: PropTypes.shape,
    _id: PropTypes.string,
    firstName: PropTypes.string,
    changeEmploymentPreference: PropTypes.func,
    employmentPreference: PropTypes.arrayOf(PropTypes.string),
    lastName: PropTypes.string,
    address: PropTypes.string,
    phonenumber: PropTypes.string,
    acceptTerms: PropTypes.bool,
    receiveNewsletter: PropTypes.bool,
    preferredContact: PropTypes.arrayOf(PropTypes.string),
    specialties: PropTypes.shape,
    title: PropTypes.string,
    experience: PropTypes.number,
    link: PropTypes.string,
    candidateWorkHistory: PropTypes.shape,
    cv: PropTypes.shape({}),
    signUpUserRole: PropTypes.string,
    pictureUrl: PropTypes.string,
    profilePhoto: PropTypes.string,
    expressSignUp: PropTypes.bool,
    myJobPageFilled: PropTypes.bool,
    addressToShow: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};
export default withI18n()(withRouter(MySearch));
