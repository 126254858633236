import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { t } from "@lingui/macro";
import moment from "moment";
import { Mutation, Query } from "react-apollo";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import singleCandidateDefault from "./SingleCandidateDefaultValues";
import runtimeVars from "../../configs/runTimeVars";
import NotFoundPage from "../../components/error-pages/NotFoundPage";
import Routes from "../layout/routes";
import { GET_CANDIDATE_INFO_BY_BH_ID } from "./queries";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import ldJson from "../single-mission/ldJson";
import SingleCandidateStickyHeaderManager from "./SingleCandidateStickyHeader/SingleCandidateStickyHeaderManager";
import SingleCandidateBody from "./SingleCandidateBody/SingleCandidateBody";
import SingleCandidateHeader from "./SingleCandidateHeader/SingleCandidateHeader";
import staticMetaTags from "../../configs/staticPagesMetaTags";

@inject("sessionStore")
@observer
class SingleCandidatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
  }

  componentDidMount() {
    const { sessionStore } = this.props;
    window.scroll(0, 0);
    sessionStore.setJobOrderReferer(document.referrer);
  }

  clickHandler = () => {
    this.setState({ isVisible: true });
  };

  closeModal = () => {
    this.setState({ isVisible: false });
  };

  formatDuration = (duration, startDate) => {
    const { i18n } = this.props;
    let start = "";
    let dur = "";

    if (duration) {
      if (duration.months) dur = `${duration.months} ${i18n._(t`mois`)}`;
      else if (duration.days) dur = `${duration.days} ${i18n._(t`jours`)}`;
    }

    if (startDate && typeof startDate === "number") {
      start = moment(startDate).format("DD MMM");
    }
    const startSentence = start ? `${i18n._(t`à partir de`)} ${start}` : "";
    const withDurationCase =
      dur && startSentence ? ` (${startSentence})` : startSentence;
    return `${dur}${withDurationCase}`;
  };

  buildHeaderProps = (loading, data) => {
    if (loading) return { loading: true };
    const { i18n } = this.props;
    const { candidate } = data.getCandidateInfoByBHId;
    const occupation = candidate.occupation;
    const city = candidate.address.city;
    const { dateAvailable } = candidate;

    const defaults = singleCandidateDefault(i18n);
    let contractType = defaults.contractType;
    let employmentPreference = defaults.contractType;

    if (candidate.customText21) {
      if (candidate.customText21 === "Remote") contractType = i18n._(t`Remote`);
      else if (candidate.customText21 === "Onsite")
        contractType = i18n._(t`Présentiel`);
      else if (candidate.customText21 === "Part-time remote")
        contractType = i18n._(t`Remote partiel`);
      else if (candidate.customText21 === "Indifferent")
        contractType = i18n._(t`Indifférent`);
    }

    if (candidate.employmentPreference) {
      if (candidate.employmentPreference === "Permanent")
        employmentPreference = i18n._(t`CDI`);
      else if (candidate.employmentPreference === "Contract")
        employmentPreference = i18n._(t`Freelance`);
      else if (
        candidate.employmentPreference === "Contract,Permanent" ||
        candidate.employmentPreference === "Permanent,Contract"
      )
        employmentPreference = i18n._(t`CDI / Freelance`);
    }

    return {
      loading: false,
      id: candidate.id,
      occupation: occupation || i18n._(t`Non précis`),
      city: city || i18n._(t`Non précis`),
      dateAvailable: dateAvailable || i18n._(t`Non précis`),
      employmentPreference: employmentPreference,
      availability: contractType
    };
  };

  buildBodyProps = (loading, data) => {
    if (loading) return { loading: true };
    const { i18n } = this.props;
    const { candidate } = data.getCandidateInfoByBHId;
    const { candidateWorkHistory } = data.getCandidateInfoByBHId;
    const { candidateEducation } = data.getCandidateInfoByBHId;
    const { candidateCertification } = data.getCandidateInfoByBHId;
    const defaults = singleCandidateDefault(i18n);

    return {
      loading: false,
      candidateWorkHistory,
      candidateEducation,
      candidateCertification,
      candidate: {
        id: candidate.id,
        avatar: (candidate && candidate.profilePhoto) || defaults.avatar,
        name: (candidate && candidate.name) || defaults.name,
        occupation: (candidate && candidate.occupation) || defaults.occupation,
        email: (candidate && candidate.email) || defaults.email,
        phoneNumber: (candidate && candidate.mobile) || defaults.phoneNumber,
        linkedIn: candidate && candidate.customText20,
        description:
          (candidate && candidate.customTextBlock3) || defaults.description,
        skills: candidate.skills,
        specialties: candidate.specialties,
        spokenLanguages: candidate.spokenLanguages
      }
    };
  };

  render() {
    const { match, i18n } = this.props;
    const link = match.params.id;
    const lastSep = link.lastIndexOf("-");
    const StringfiedId = link.substring(lastSep + 1);
    const id = parseInt(StringfiedId);
    if (isNaN(id))
      return (
        <NotFoundPage
          message={i18n._(
            t`Nous sommes désolé, le candidat que vous cherchez n’existe pas ou n’est plus disponible`
          )}
          href={Routes.LpbLayoutRoutes.DashboardCompany.path}
        />
      );
    return (
      <div>
        <Query query={GET_CANDIDATE_INFO_BY_BH_ID} variables={{ link }}>
          {({ loading, error, data }) => {
            if (error)
              return (
                <NotFoundPage
                  message={i18n._(
                    t`Nous sommes désolé, le candidat que vous cherchez n’existe pas ou n’est plus disponible`
                  )}
                  href={Routes.LpbLayoutRoutes.DashboardCompany.path}
                />
              );
            const headerProps = this.buildHeaderProps(loading, data);
            const bodyProps = this.buildBodyProps(loading, data);
            return (
              <div>
                {!loading &&
                  metaTags(
                    urls.SingleCandidate(
                      data.getCandidateInfoByBHId.candidate.id
                    ),
                    data.getCandidateInfoByBHId.candidate.name,
                    data.getCandidateInfoByBHId.candidate.about,
                    data.getCandidateInfoByBHId.candidate.address.city,
                    data.getCandidateInfoByBHId.candidate.dateAvailable,
                    data.getCandidateInfoByBHId.candidate.employmentPreference,
                    data.getCandidateInfoByBHId.candidate.customText21,
                    data.getCandidateInfoByBHId.candidate.customTextBlock5,
                    null,
                    ldJson(i18n, data.getCandidateInfoByBHId.candidate)
                  )}
                <SingleCandidateHeader {...headerProps} id={id} />
                <SingleCandidateStickyHeaderManager
                  {...headerProps}
                  id={id}
                  clickHandler={this.clickHandler}
                  closeModal={this.closeModal}
                />
                <SingleCandidateBody
                  {...bodyProps}
                  id={id}
                  isVisible={this.state.isVisible}
                  clickHandler={this.clickHandler}
                  closeModal={this.closeModal}
                />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

SingleCandidatePage.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    setJobOrderReferer: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(SingleCandidatePage));
