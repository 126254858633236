import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Col, Icon, Row, Spinner, toaster } from "cf-neo-ui";
import ResumeTemplatesCarousel from "./resumeTemplates-carousel/ResumeTemplates-carousel";
import ResumeGenerationButtons from "./resumeTemplates-buttons/ResumeTemplates-buttons";

@inject("sessionStore", "appStore")
@observer
class ResumeGenerationAllowed extends Component {
  queue = [];

  constructor(props) {
    super(props);
    this.state = {};
  }

  imageSlider = (FrSlides, EnSlides) => {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    const slides = {};
    if (
      currentLanguage === "fr" ||
      currentLanguage === "" ||
      currentLanguage === " "
    ) {
      slides.template = FrSlides.template;
      slides.title = FrSlides.title;
      slides.description = FrSlides.description;
    } else {
      slides.template = EnSlides.template;
      slides.title = EnSlides.title;
      slides.description = EnSlides.description;
    }
    return slides;
  };

  carousel = () => {
    const { appStore, sessionStore } = this.props;
    const { currentLanguage } = appStore;
    const FrSlides = [
      {
        template:
          "/assets/images/resume_exemples/Template CV _ CF - FR - Classique V1.jpg",

        title: "Modèle Freelance classique",
        description:
          "Mettant en avant vos différentes expériences, ce modèle conviendra parfaitement à vos recherches de mission Freelance"
      },
      {
        template:
          "/assets/images/resume_exemples/Blocked_FR_Template CV _ CF - EN - Creatif V1.png",

        title: "Modèle Permanent Créatif",
        description:
          "Avec ce modèle vos expériences sont mises en avant de manière originale ! Un CV parfait si vous souhaitez vous démarquer et montrer votre talent créatif"
      },
      {
        template:
          "/assets/images/resume_exemples/Blocked_FR_Template CV _ CF - EN - Original V1.png",

        title: "Modèle Freelance Original",
        description:
          "Un modèle qui place vos expériences professionnelles au centre du document. Ce format vous permet de mettre en avant vos compétences et vos références"
      }
    ];
    const EnSlides = [
      {
        template:
          "/assets/images/resume_exemples/Template CV _ CF - FR - Classique V1.jpg",

        title: "Classic Freelance Template",
        description:
          "Highlighting your different experiences, this template will perfectly suit your search for a freelance mission"
      },
      {
        template:
          "/assets/images/resume_exemples/Blocked_Template CV _ CF - EN - Creatif V1.png",

        title: "Creative Permanent Template",
        description:
          "With this template your experiences are highlighted in an original way! A perfect CV if you want to stand out and show your creative talent"
      },
      {
        template:
          "/assets/images/resume_exemples/Blocked_Template CV _ CF - EN - Original V1.png",

        title: "Original Freelance Template",
        description:
          "A template that places your professional experience at the center of the document. This format allows you to highlight your skills and references"
      }
    ];
    if (currentLanguage === "fr") {
      return (
        <ResumeTemplatesCarousel
          slides={FrSlides}
          sessionStore={sessionStore}
        />
      );
    }
    if (currentLanguage === "en") {
      return (
        <ResumeTemplatesCarousel
          slides={EnSlides}
          sessionStore={sessionStore}
        />
      );
    }
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  urlAccess = url => {
    window.open(url, "_blank");
  };

  render() {
    return (
      <div className="profile profile-profile">
        <Row>
          <Col className="center form-card-content_resume">
            <div
              className="center-txt text-bold"
              style={{
                color: "#d3354a"
              }}
            >
              <Trans>Choisissez le modèle adapté à votre situation</Trans>
            </div>
            <br />
            <br />
            <div className="center-txt">
              <div style={{ height: "30vw" }}>{this.carousel()}</div>
              <br />
              <br />
              <ResumeGenerationButtons />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ResumeGenerationAllowed.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeCv: PropTypes.func,
    isAuthenticatedByLinkedin: PropTypes.bool,
    _id: PropTypes.string,
    email: PropTypes.string,
    resumeModel: PropTypes.string,
    pictureUrl: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.number,
    address: PropTypes.string,
    searchAddress: PropTypes.string,
    candidateWorkHistory: PropTypes.shape,
    signUpUserRole: PropTypes.string,
    cv: PropTypes.shape({
      name: PropTypes.string
    }),
    authToken: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
    action: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    refreshLayout: PropTypes.func,
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(ResumeGenerationAllowed);
