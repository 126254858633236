import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import { ApolloConsumer } from "react-apollo";
import { inject, observer } from "mobx-react";
import * as PropTypes from "prop-types";
import Notifications from "./notifications";
import InnerTabs from "./inner-tabs";
import classes from "./styles.module.scss";
import routes from "../../layout/routes";

@inject("sessionStore")
@observer
class Index extends Component {
  render() {
    const { sessionStore } = this.props;
    return (
      <div className={classes.container}>
        <section>
          <ApolloConsumer>
            {client => <Notifications apolloClient={client} />}
          </ApolloConsumer>
        </section>
        <section>
          <InnerTabs />
        </section>
        {sessionStore.account.role === "CANDIDATE" && (
          <div className={classes.ntb}>
            <p>
              <Trans>Votre Timesheet du mois n’est pas ouvert?</Trans>
            </p>
            <Link to={routes.HbLayoutRoutes.contact.path}>
              <Trans>Contactez nous</Trans>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

Index.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired
};

export default withI18n()(Index);
