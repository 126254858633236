import { observable, action } from "mobx";

export default class StatsStore {
  @observable dateStart;

  @observable dateEnd;

  @observable emptyData;

  @observable skip;

  @observable total;

  @observable requestAll;

  constructor(initialState = {}) {
    this.dateStart = initialState.dateStart || null;
    this.dateEnd = initialState.dateEnd || null;
    this.emptyData = initialState.emptyData || false;
    this.skip = initialState.skip || 0;
    this.total = initialState.total || null;
    this.limit = 10;
    this.requestAll = false;
  }

  @action
  setDateStart = value => {
    this.dateStart = value;
    this.skip = 0;
  };

  @action
  setDateEnd = value => {
    this.dateEnd = value;
    this.skip = 0;
  };

  @action
  setEmptyData = value => {
    this.emptyData = value;
  };

  @action
  toPage = page => {
    this.skip = (page - 1) * this.limit;
  };

  @action
  setTotal = value => {
    this.total = value;
  };

  @action
  setRequestAll = value => {
    this.requestAll = value;
  };

  @action
  clear = () => {
    this.dateEnd = null;
    this.dateStart = null;
    this.emptyData = false;
    this.skip = 0;
    this.total = null;
  };
}
