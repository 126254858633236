import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { Trans } from "@lingui/macro";
import classes from "./statisticsCard.module.scss";
import CLIENT_ACTIONS_STATS_QUERY from "./query";

@inject("sessionStore")
@observer
class StatisticsCard extends Component {
  loading = () => {
    return <span style={{ opacity: 0.2 }}>0</span>;
  };

  render() {
    const { sessionStore } = this.props;
    return (
      <div className={classes.statisticsCard}>
        <div className={classes.header}>
          <Trans>Statistiques</Trans>
        </div>
        <Query
          query={CLIENT_ACTIONS_STATS_QUERY}
          variables={{
            id: Number.parseInt(sessionStore.id, 10)
          }}
        >
          {({ loading, error, data }) => {
            if (error || (data && !data.accessibleActionsStats)) return null;
            return (
              <div className={classes.body}>
                <div className={classes.statisCol}>
                  <div className={classes.stat}>
                    {loading
                      ? this.loading()
                      : data.accessibleActionsStats.vacanciesCount}
                  </div>
                  <div className={classes.text}>
                    <Trans>Offres</Trans>
                  </div>
                </div>
                <div className={classes.statisCol}>
                  <div className={classes.stat}>
                    {loading
                      ? this.loading()
                      : data.accessibleActionsStats.cvSentCount}
                  </div>
                  <div className={classes.text}>
                    <Trans>Candidats</Trans>
                  </div>
                </div>
                <div className={classes.statisCol}>
                  <div className={classes.stat}>
                    {loading
                      ? this.loading()
                      : data.accessibleActionsStats.placementsCount}
                  </div>
                  <div className={classes.text}>
                    <Trans>Placement</Trans>
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

StatisticsCard.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default StatisticsCard;
