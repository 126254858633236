import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { Button } from "cf-neo-ui";
import classes from "./SuggestCard.module.scss";

class SuggestCard extends PureComponent {
  render() {
    const { text, onClick, buttonLabel, masked, ...restProps } = this.props;
    return (
      <div className={classes.container} {...restProps}>
        <div className={classes.left}>
          <div className={classes.center}>
            <p>{text}</p>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.center}>
            <Button className={classes.but} onClick={onClick}>
              {buttonLabel}
            </Button>
          </div>
        </div>
        {masked && <div className={classes.overlay} />}
      </div>
    );
  }
}

SuggestCard.propTypes = {
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  masked: PropTypes.bool
};
SuggestCard.defaultProps = {
  masked: true,
  text: "",
  buttonLabel: "",
  onClick: () => {}
};

export default SuggestCard;
