import React, { PureComponent } from "react";
import { TextSkeleton, InputSkeleton } from "cf-neo-ui";
import classes from "./LoadingBookCard.module.scss";

class LoadingBookCard extends PureComponent {
  render() {
    return (
      <div>
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={classes.categories}>
              <TextSkeleton />
              <TextSkeleton />
            </div>
            <div className={classes.header}>
              <TextSkeleton heading />
            </div>
            <div className={classes.description}>
              <TextSkeleton lines={3} />
            </div>
            <div className={classes.input}>
              <InputSkeleton />
            </div>
            <div>
              <InputSkeleton className={classes.button} />
            </div>
          </div>
        </div>
        <div className={classes.bloggerHolder} />
      </div>
    );
  }
}

export default LoadingBookCard;
