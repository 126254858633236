import gql from "graphql-tag";

const FILL_TIME_SHEET_MUTATION = gql`
  mutation FillTimesheet(
    $id: Int!
    $selectedDays: [SelectedDaysInput]
    $specialHours: [SpecialHoursInput]
  ) {
    fillTimesheet(
      id: $id
      body: { selectedDays: $selectedDays, specialHours: $specialHours }
    ) {
      id
      title
      client {
        id
        firstName
        lastName
        name
        email
        companyName
      }
      candidate {
        id
        firstName
        lastName
        name
        email
        city
      }
      placementID
      dateAdded
      month
      year
      dateLastUpdate
      status
      statusMaker
      selectedDays {
        day
        hours
        minutes
      }
      specialHours {
        id
        date
        hours
        minutes
        type
      }
      total
      signature
      conversation {
        timesheetID
        dateAdded
        dateLastUpdate
        messages {
          _id
          text
          date
          speaker
        }
        comment
      }
    }
  }
`;

const UPDATE_TIMESHEET_STATUS_MUTATION = gql`
  mutation UpdateTimesheetStatus($id: Int!, $status: String!, $ip: String) {
    updateTimesheetStatus(id: $id, status: $status, ip: $ip) {
      id
    }
  }
`;

const SAVE_TIMESHEET_AS_DRAFT = gql`
  mutation SaveTimesheetAsDraft($id: Int!, $body: TimesheetBodyInput!) {
    saveTimesheetAsDraft(id: $id, body: $body) {
      id
    }
  }
`;

export {
  FILL_TIME_SHEET_MUTATION,
  UPDATE_TIMESHEET_STATUS_MUTATION,
  SAVE_TIMESHEET_AS_DRAFT
};
