import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { Icon, TagList } from "cf-neo-ui";
import classes from "./Info.module.scss";
import SocialMedia from "../../SocialMedia/SocialMedia";
import theme from "../../../../configs/theme";

class Info extends PureComponent {
  formatTags = arr => arr.map(item => `#${item.trim()}`);

  render() {
    const {
      id,
      place,
      businessSectors,
      duration,
      workType,
      categories
    } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.col}>
          <div className={classes.iconText}>
            <Icon
              type="map-marker"
              width={14}
              height={16}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span>{place}</span>
          </div>
          <div className={classes.iconText}>
            <Icon
              type="briefcase"
              width={14}
              height={16}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span>{businessSectors.join(" ")}</span>
          </div>
        </div>
        <div className={classes.col}>
          <div className={classes.iconText}>
            <Icon
              type="clock"
              width={14}
              height={16}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span>{duration}</span>
          </div>
          <div className={classes.iconText}>
            <Icon
              type="adjust"
              width={16}
              height={16}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span>{workType}</span>
          </div>
        </div>
        <TagList tags={this.formatTags(categories)} variant="secondary" />
        <SocialMedia id={id} />
      </div>
    );
  }
}

Info.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  place: PropTypes.string,
  duration: PropTypes.string,
  workType: PropTypes.string,
  businessSectors: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(PropTypes.string)
};
Info.defaultProps = {
  place: "",
  duration: "",
  workType: "",
  businessSectors: [""],
  categories: [""]
};

export default Info;
