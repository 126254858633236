import React, { PureComponent } from "react";
import { BlogCardDisplay } from "cf-neo-ui";
import * as PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { I18nProvider } from "@lingui/react";
import classes from "./BlogCardsDisplay.module.scss";
import decode from "../../../utils/Utf8Text";
import catalogEn from "../../../locales/en/messages";

const catalogs = {
  en: catalogEn
};
class BlogCardsDisplay extends PureComponent {
  formatDate = date => {
    return moment(date).format("DD MMMM YYYY");
  };

  navigate = (id, category) => {
    const { history } = this.props;
    history.push(`/blog/${category}/${id}`);
  };

  action = categories => {
    for (const item of categories) {
      if (item.name === "video") return "play";
    }
    return "";
  };

  render() {
    const { articles } = this.props;
    return (
      <div className={classes.container}>
        {articles.map(article => (
          <BlogCardDisplay
            className={classes.blogCard}
            key={article.id}
            poster={
              article.featured_media && article.featured_media.source_url
                ? article.featured_media.source_url
                : "/cf.webp"
            }
            caption={
              Array.isArray(article.categories) && article.categories.length
                ? decode(article.categories[0].name)
                : "non précie"
            }
            smallTitle={
              article.date ? this.formatDate(article.date) : "date non precis"
            }
            description={decode(article.title)}
            action={this.action(article.categories)}
            href={`/blog/${
              article.categories[0] &&
              article.categories[0].name == "Ressources"
                ? "resources"
                : "news"
            }/${article.id}`}
            cardClick={() =>
              this.navigate(
                article.id,
                article.categories[0] &&
                  article.categories[0].name == "Ressources"
                  ? "resources"
                  : "news"
              )
            }
          />
        ))}
      </div>
    );
  }
}

BlogCardsDisplay.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      poster: PropTypes.string,
      media: PropTypes.string,
      action: PropTypes.string,
      caption: PropTypes.string,
      smallTitle: PropTypes.string,
      description: PropTypes.string
    })
  ),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

BlogCardsDisplay.defaultProps = {
  articles: []
};

export default withRouter(BlogCardsDisplay);
