import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { Icon } from "cf-neo-ui";
import classes from "./PlusButton.module.scss";

class PlusButton extends PureComponent {
  render() {
    const { onClick, children, ...restProps } = this.props;
    return (
      <div className={classes.showMore} {...restProps}>
        <button
          type="button"
          className={classes.plusButtonIcon}
          onClick={onClick}
        >
          <Icon type="plus" color="#fff" width={16} height={16} />
        </button>
        <br />
        <button
          type="button"
          className={classes.plusButtonText}
          onClick={onClick}
        >
          {children}
        </button>
      </div>
    );
  }
}

PlusButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

PlusButton.defaultProps = {
  onClick: () => {}
};

export default PlusButton;
