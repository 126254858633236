import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Divider, Modal, SourcerContactCard, toaster } from "cf-neo-ui";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react/index";
import { withRouter } from "react-router-dom";
import classes from "./SingleCandidateBodyLeftSlide.module.scss";
import gql from "graphql-tag";
import { t } from "@lingui/macro";
import SingleCandidateModal from "./SingleCandidateModal/SingleCandidateModal";
import Routes from "../../../layout/routes";
import routes from "../../../layout/routes/hbLayoutRoutes";

@inject("sessionStore")
@observer
class SingleCandidateBodyLeftSlide extends Component {
  constructor(props) {
    super(props);
    this.listKey = 0;
    /*this.state = {
      isVisible: false
    };*/
  }

  /*clickHandler = () => {
    this.setState({ isVisible: true });
  };*/

  closeModal = () => {
    this.props.closeModal();
  };

  contact = () => {
    const { sessionStore, candidate, history } = this.props;
    sessionStore.setContactPageEmail({
      email: candidate.email,
      name: candidate.name
    });
    history.push(routes.contact.path);
  };

  toLogin = () => {
    const { id, sessionStore, history } = this.props;
    sessionStore.setBackRef(id);
    history.push(Routes.HbLayoutRoutes.Login.path);
  };

  linkedInIconHandler = url => {
    window.open(url, "_blank");
  };

  displayCandidateCard = i18n => {
    const { sessionStore, candidate, id, loading: loadingGeneral } = this.props;
    let iconClickHandler = null;

    if (candidate.linkedIn) {
      iconClickHandler = () => this.linkedInIconHandler(candidate.linkedIn);
    }

    if (sessionStore.authToken && sessionStore.account.role === "CLIENT") {
      return loadingGeneral ? null : (
        <SourcerContactCard
          name={candidate.id}
          position={candidate.occupation}
          description={candidate.description}
          onApply={this.props.clickHandler}
          applyButtonLabel={i18n._(t`Contacter`)}
        />
      );
    } else if (
      sessionStore.authToken &&
      sessionStore.account.role === "CANDIDATE"
    ) {
      return loadingGeneral ? null : (
        <SourcerContactCard
          avatar={candidate.avatar}
          name={candidate.name}
          email={candidate.email}
          emailClick={this.contact}
          position={candidate.occupation}
          phoneNumber={candidate.phoneNumber}
          description={candidate.description}
          onIconClick={iconClickHandler}
          showButton={false}
        />
      );
    } else {
      return loadingGeneral ? null : (
        <SourcerContactCard
          name={candidate.id}
          position={candidate.occupation}
          description={candidate.description}
          onApply={this.toLogin}
          applyButtonLabel={i18n._(t`Contacter`)}
        />
      );
    }
  };

  render() {
    const { i18n, className, id, isVisible } = this.props;
    return (
      <div className={[classes.slideBar, className].join(" ")}>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          onClose={() => this.closeModal()}
          position="fixed"
        >
          <SingleCandidateModal close={this.closeModal} candidateBhId={id} />
        </Modal>
        <section>{this.displayCandidateCard(i18n)}</section>
      </div>
    );
  }
}

SingleCandidateBodyLeftSlide.wrappedComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  candidate: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    avatar: PropTypes.string,
    occupation: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    description: PropTypes.string,
    linkedIn: PropTypes.string
  }).isRequired,
  id: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  className: PropTypes.string
};

SingleCandidateBodyLeftSlide.defaultProps = {
  className: ""
};

export default withI18n()(withRouter(SingleCandidateBodyLeftSlide));
