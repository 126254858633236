import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { FormCard, Button, Divider, Icon, H2 } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import routes from "../layout/routes";
import classes from "./PendingPage.module.scss";
import theme from "../../configs/theme";

const GET_USER_INFO = gql`
  query getUserInfoQuery($token: String!) {
    getAccountInfo(token: $token) {
      client {
        id
      }
    }
  }
`;

@inject("appStore", "sessionStore")
@observer
class PendingPage extends Component {
  refreshLayout = () => {
    const { appStore } = this.props;
    appStore.refreshLayout();
  };

  isIdReady = () => {
    const { sessionStore, appStore, client, history } = this.props;
    if (sessionStore.id) {
      history.push(routes.LpbLayoutRoutes.DashboardCompany.path);
      return true;
    }

    setTimeout(() => {
      try {
        const res = client.query({
          query: GET_USER_INFO,
          variables: {
            token: sessionStore.authToken
          },
          fetchPolicy: "no-cache"
        });
        res.then(response => {
          const { data } = response;
          if (
            data &&
            data.getAccountInfo &&
            data.getAccountInfo.client &&
            data.getAccountInfo.client.id
          )
            appStore.refreshLayout();
        });
      } catch (error) {}
      this.isIdReady();
    }, 3000);

    return false;
  };

  render() {
    if (this.isIdReady()) return null;
    const { history } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.iconHolder}>
          <Icon
            type="clock"
            size="extra-large"
            color={theme.colors.mainColor}
            color2={theme.colors.lightMainColor}
          />
        </div>
        <H2 className={classes.heading}>
          <Trans>Encore quelques instants...</Trans>
        </H2>
        <FormCard className={classes.card}>
          <div className={classes.info}>
            <p>
              <Trans>
                Votre dashboard est en cours de création et sera accessible dans
                quelques instants.
              </Trans>
              <br />
              <Trans>
                Vous serez redigé automatiquement vers votre dashboard.
              </Trans>
            </p>
            <span>
              <Trans>
                Si vous n’êtes pas redirigé dans 3mn, merci de nous contacter
              </Trans>
            </span>
          </div>
          <Divider />
          <div className={classes.contact}>
            <Button
              variant="secondary"
              onClick={() => {
                history.push(routes.HbLayoutRoutes.contact.path);
              }}
            >
              <Trans>Contacter Nous</Trans>
            </Button>
          </div>
        </FormCard>
      </div>
    );
  }
}

PendingPage.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.number,
    authToken: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
    action: PropTypes.string
  }).isRequired,
  client: PropTypes.shape({
    query: PropTypes.func
  }).isRequired
};

export default withRouter(withApollo(PendingPage));
