import gql from "graphql-tag";

const latestPostsQuery = gql`
  query latestPosts(
    $page: Int
    $perPage: Int
    $categoryIds: String
    $excludeCategories: String
    $tagIds: String
    $search: String
    $excludeBlog: String
    $include: String
    $language: String
  ) {
    posts(
      filter: {
        page: $page
        per_page: $perPage
        orderby: "date"
        categories: $categoryIds
        categories_exclude: $excludeCategories
        tags: $tagIds
        search: $search
        exclude: $excludeBlog
        include: $include
        language: $language
      }
    ) {
      id
      title
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      date
      featured_media {
        id
        source_url
        thumbnail
      }
      featured_image2_url
      featured_media_id
      modified
      total
      totalPages
      related_posts
      meta_keys
      meta_description
    }
  }
`;

const SinglePostQuery = gql`
  query SinglePost($id: ID!, $language: String) {
    post(id: $id, language: $language) {
      id
      title
      author {
        name
        description
        avatar_url
      }
      content
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      date
      modified
      featured_media {
        id
        source_url
      }
      featured_image2_url
      featured_media_id
      download_link
      related_posts
      meta_keys
      meta_description
    }
  }
`;

const categoriesRefFr = {
  featured: "39",
  videos: "35",
  resources: "37",
  successStories: "36",
  advicesConsultant: "15",
  advicesEnterprise: "13",
  decryption: "4",
  news: "21"
};

const categoriesRefEn = {
  featured: "9",
  videos: "10",
  resources: "11",
  "tips-and-tricks": "2",
  "its-who-is-who": "3",
  "business-lounge": "4",
  Newsstand: "5",
  "captains-log": "6",
  "interesting-reads": "7",
  "horizon-2050": "8",
  "behind-screen": "679"
};

// const categoriesRefFr = categoriesRefEn;

export { latestPostsQuery, categoriesRefFr, categoriesRefEn, SinglePostQuery };
