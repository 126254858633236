import React, { Component } from "react";
import { Row } from "cf-neo-ui";
import classes from "./MissionsPageBody.module.scss";
import SideFilters from "./side-filters/SideFilters";
import MissionsList from "./missions-list/MissionsList";
import Reassurance from "./reassurance/Reassurance";

class MissionsPageBody extends Component {
  render() {
    return (
      <section>
        <div className={classes.block}>
          <div>
            <Row noGape>
              <SideFilters />
              <MissionsList />
            </Row>
            <Reassurance />
          </div>
        </div>
      </section>
    );
  }
}

export default MissionsPageBody;
