const format = url => {
  const last = url.length - 1;
  if (url[last] === "/") return url.substring(0, last);
  return url;
};

const runtimeVars = {
  devMode: process.env.NODE_ENV === "development",
  testMode: process.env.RAZZLE_APP_TEST_MODE === "true",
  gtmCode: process.env.GTM_CODE || "",
  TITLE: process.env.SITE_TITLE || "Club Freelance",
  FRONTEND_BASE_URL: format(process.env.RAZZLE_FRONTEND_BASE_URL),
  FRONTEND_BASE_FR_URL: format(process.env.RAZZLE_FRONTEND_BASE_FR_URL),
  APP_GRAPHQL_EP: process.env.RAZZLE_APP_GRAPHQL_EP,
  APP_LANG: process.env.RAZZLE_APP_LANG || "fr",
  APP_CAPTCHA_KEY: process.env.RAZZLE_APP_CAPTCHA_KEY,
  VERSION: process.env.RAZZLE_VERSION,
  AUTH_TOKEN: process.env.RAZZLE_APP_AUTH_TOKEN,
  APP_SOURCE_SITE: process.env.RAZZLE_APP_SOURCE_SITE || "fr", // fr -> cf.com   uk -> cf.co.uk
  APP_WEBSITE: process.env.RAZZLE_APP_WEBSITE || "CF",
  APP_COUNTRY_ID: process.env.RAZZLE_APP_COUNTRY_ID || "2245",
  DOMAIN: process.env.RAZZLE_APP_DOMAIN || ".mindquest.io", // DOMAIN must be the same as resources server [ dev: "localhost", test: .mindquest.io, prod:.mindquest.io]
  CF_URL: process.env.RAZZLE_APP_CF_URL || "https://club-freelance.com", // URL OF club-freelance [ dev: "http://localhost:5800", test: https://test.club-freelance.fr, prod:https://club-freelance.com]
  RESOURCES_URL:
    process.env.RAZZLE_APP_RESOURCES_URL ||
    "https://resources.club-freelance.com", // URL OF RESOURCES club-freelance [ dev: "http://localhost:6002", test: https://test.resources.club-freelance.fr, prod:https://resources.club-freelance.com]
  PARTENAIRES_URL: "https://nos-partenaires.club-freelance.com/"
};

export default runtimeVars;
