import gql from "graphql-tag";

export const GET_CANDIDATE_INFO_BY_BH_ID = gql`
  query getCandidateInfoByBHId($link: String!) {
    getCandidateInfoByBHId(link: $link) {
      candidate {
        id
        profilePhoto
        name
        email
        mobile
        customText20
        email
        occupation
        dateAvailable
        employmentPreference
        customText21
        customTextBlock3
        customTextBlock5
        occupation
        skills
        specialties
        address {
          city
        }
        spokenLanguages
      }
      candidateWorkHistory {
        companyName
        companyAddress {
          countryID
          city
        }
        isCurrent
        title
        startDate
        endDate
        summary
        refName
        refEmail
        refPhone
        skillExperience
        specialty
        BusinessSector
      }
      candidateEducation {
        diplomeType
        degree
        startDate
        endDate
        school
      }
      candidateCertification {
        startDate
        endDate
        certification
        school
      }
    }
  }
`;

export const CLIENT_LIKED_CANDIDATES = gql`
  query clientLikedCandidates {
    clientLikedCandidates {
      id
    }
  }
`;
