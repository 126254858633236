import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import cookie from "react-cookies";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import {
  Row,
  Col,
  TextInput,
  FormGroup,
  FormCard,
  Button,
  Validation,
  ImportPhoto,
  Spinner,
  TagList,
  Tag,
  CheckboxGroup,
  Checkbox
} from "cf-neo-ui";
import Routes from "../layout/routes/index";
import validate from "../../utils/validators";
import Location from "../../components/location/location";
import "./styles.scss";
import ReactGA from "react-ga";
import { GET_USER_INFO } from "../../components/hoc/queries";

const UPDATE_PERSENAL_INFO_MUTATION = gql`
  mutation updatePersenalInfoCandidateMutation(
    $firstName: String!
    $lastName: String!
    $phonenumber: String
    $address: AddressInput
    $preferredContact: String
    $profilePhoto: Upload
  ) {
    updatePersenalInfoCandidate(
      input: {
        firstName: $firstName
        lastName: $lastName
        phonenumber: $phonenumber
        address: $address
        preferredContact: $preferredContact
        profilePhoto: $profilePhoto
      }
    ) {
      account {
        id
        name
        path
        step
      }
      candidate {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class myInformation extends Component {
  constructor(props) {
    super(props);
    this.source = "unknown";

    this.state = {
      isfirstNameValid: true,
      islastNameValid: true,
      isAddressValid: true,
      isPhonenumberValid: true,
      firstNameValidationMessage: "",
      lastNameValidationMessage: "",
      addressValidationMessage: "",
      phonenumberValidationMessage: ""
    };
    this.onNextStepHandler = this.onNextStepHandler.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  componentDidMount() {
    const { host } = window.location;
    const webDomain = host.split(".").pop();
    if (webDomain === "com") this.source = "Website FR";
    if (webDomain === "uk") this.source = "Website UK";

    ReactGA.initialize("UA-56544271-1");
    ReactGA.pageview(window.location.pathname);
  }

  onNextStepHandler(data) {
    const valid = this.isFormValid();
    if (!valid) return;
    const { history, sessionStore } = this.props;
    const { MyJob } = Routes.Hb2LayoutRoutes;
    sessionStore.changePath(data.updatePersenalInfoCandidate.account.path);
    history.push(MyJob.path);
  }

  getLocationHandler = (address, formattedAddress) => {
    const { sessionStore } = this.props;
    sessionStore.changeAddress({
      address1: address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      zip: formattedAddress.zip,
      state: formattedAddress.state,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    });
    this.validateAddress(address);
  };

  addressChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changeAddress({ address1: v });
    this.validateAddress(v);
  };

  firstNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changefirstName(e.target.value);
    this.validatefirstName(e.target.value);
  };

  lastNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changelastName(e.target.value);
    this.validatelastName(e.target.value);
  };

  phonenumberChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changePhonenumber(e.target.value);
    this.validatePhonenumber(e.target.value);
  };

  handleUploadProfilePhoto(fileUploaded) {
    const { sessionStore } = this.props;
    sessionStore.changeProfilePhoto(fileUploaded);
  }

  preferredContactChangeHandler = v => {
    const { sessionStore } = this.props;
    if (Array.isArray(v)) {
      sessionStore.changePreferredContact(v.toString());
    }
  };

  isFormValid() {
    const { sessionStore } = this.props;
    const { firstName, lastName, address, phonenumber } = sessionStore;
    let valid = true;
    if (!this.validatefirstName(firstName)) valid = false;
    if (!this.validatelastName(lastName)) valid = false;
    //if (!this.validateAddress(address.address1)) valid = false;
    //if (!this.validatePhonenumber(phonenumber)) valid = false;
    if (!valid) return false;
    return valid;
  }

  validatefirstName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isfirstNameValid: res.isValid,
      firstNameValidationMessage: res.message
    });
    return res.isValid;
  }

  validatelastName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      islastNameValid: res.isValid,
      lastNameValidationMessage: res.message
    });
    return res.isValid;
  }

  validateAddress(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    let mesg = "";
    if (!res.isValid) mesg = i18n._(t`Non renseigné`);
    this.setState({
      isAddressValid: res.isValid,
      addressValidationMessage: mesg
    });
    return res.isValid;
  }

  validatePhonenumber(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 13 }, { minlength: 8 }],
      i18n
    );
    this.setState({
      isPhonenumberValid: res.isValid,
      phonenumberValidationMessage: res.message
    });
    return res.isValid;
  }

  render() {
    const {
      isfirstNameValid,
      islastNameValid,
      isAddressValid,
      isPhonenumberValid,
      firstNameValidationMessage,
      lastNameValidationMessage,
      phonenumberValidationMessage,
      addressValidationMessage,
      nametest
    } = this.state;
    const { i18n, sessionStore, appStore, onChange } = this.props;
    const customTextBlock1 = cookie.load("firstVisitOrigin") || "direct url";
    const {
      changeStep,
      firstName,
      lastName,
      address,
      phonenumber,
      pictureUrl,
      profilePhoto,
      preferredContact,
      ScrapedLocation,
      scrappedLinkdin,
      authToken,
      addressToShow
    } = sessionStore;

    let preferredContactArray = [];
    if (preferredContact) preferredContactArray = preferredContact.split(",");

    changeStep(4);

    return (
      <div className="session my-information">
        <br />

        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Informations personnelles</Trans>
            </h1>
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter with-padding">
          <Row className="photo-row">
            <Col lg={2} xl={2} className="profile-photo">
              <FormGroup>
                <ImportPhoto
                  className="profile-photo"
                  dimension="114px"
                  src={pictureUrl || ""}
                  errorMessageDescription={i18n._(
                    t`Format d’image invalide. Formats recommandés : .jpeg .png`
                  )}
                  errorMessageTitle={`${i18n._(t`Erreur`)} !`}
                  onChange={(e, fileUploaded) =>
                    this.handleUploadProfilePhoto(e, fileUploaded)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg={4} xl={4}>
              <Row>
                <Col lg={12} xl={12}>
                  <FormGroup>
                    <label className="form-label" htmlFor="lastName">
                      <Trans>Prénom</Trans>
                    </label>
                    <Validation
                      errorMessage={firstNameValidationMessage}
                      valid={isfirstNameValid}
                    >
                      <TextInput
                        id="firstName"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Prénom`)}
                        // defaultValue="Bob"
                        // ref={this.test}
                        value={firstName}
                        onChange={this.firstNameChangeHandler}
                      />
                      <div>
                        {scrappedLinkdin.firstName ? (
                          <Tag
                            text={scrappedLinkdin.firstName}
                            variant="tertiary"
                            className="tag-list"
                            style={{ color: "#8d0417" }}
                            closable={false}
                            clickable="true"
                            onClickTag={() => {
                              sessionStore.changefirstName(
                                scrappedLinkdin.firstName
                              );
                              this.validatefirstName(scrappedLinkdin.firstName);
                            }}
                          />
                        ) : null}
                      </div>
                    </Validation>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label className="form-label" htmlFor="firstName">
                      <Trans>Nom</Trans>
                    </label>
                    <Validation
                      errorMessage={lastNameValidationMessage}
                      valid={islastNameValid}
                    >
                      <TextInput
                        id="lastName"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Nom de famille`)}
                        value={lastName}
                        onChange={this.lastNameChangeHandler}
                      />
                      <div>
                        {scrappedLinkdin.lastName ? (
                          <Tag
                            text={scrappedLinkdin.lastName}
                            variant="tertiary"
                            className="tag-list"
                            style={{ color: "#8d0417" }}
                            closable={false}
                            clickable="true"
                            onClickTag={() => {
                              sessionStore.changelastName(
                                scrappedLinkdin.lastName
                              );
                              this.validatelastName(scrappedLinkdin.lastName);
                            }}
                          />
                        ) : null}
                      </div>
                    </Validation>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="address">
                  <Trans>Ville de résidence</Trans>
                </label>
                <Validation
                  errorMessage={addressValidationMessage}
                  valid={isAddressValid}
                >
                  <div style={{ marginTop: "13px" }}>
                    <Location
                      id="address"
                      onChange={this.addressChangeHandler}
                      value={addressToShow}
                      getLocation={(Address, FormattedAddress) =>
                        this.getLocationHandler(Address, FormattedAddress)
                      }
                      valid={isAddressValid}
                    />
                  </div>

                  <div>
                    {scrappedLinkdin.location ? (
                      <Tag
                        text={scrappedLinkdin.location}
                        variant="tertiary"
                        className="tag-list"
                        style={{ color: "#8d0417" }}
                        closable={false}
                        clickable="true"
                        onClickTag={async () => {
                          let add1 = {
                            city: "Lincoln County",
                            country: "États-Unis",
                            lat: 37.2430548,
                            lng: -115.7930198,
                            state: "Nevada",
                            zip: ""
                          };
                          sessionStore.changeAddressToShow(
                            scrappedLinkdin.location
                          );
                          // this.getLocationHandler(ScrapedLocation, add1);
                          this.addressChangeHandler(scrappedLinkdin.location);
                        }}
                      />
                    ) : null}
                  </div>
                </Validation>
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="phoneNumber"
                      style={{ marginTop: "18px" }}
                    >
                      <Trans>Téléphone</Trans>
                    </label>
                    <Validation
                      errorMessage={phonenumberValidationMessage}
                      valid={isPhonenumberValid}
                    >
                      <TextInput
                        id="phoneNumber"
                        type="tel"
                        className="form-input"
                        placeholder="06XXXXXXXX"
                        value={phonenumber}
                        onChange={this.phonenumberChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              {" "}
              <span className="my-information text-gray-bold">
                <Trans>Je préfère être contacté par:</Trans>
              </span>
            </Col>
            <Col style={{ maxWidth: "117%" }}>
              <CheckboxGroup
                // style={{  marginBottom: "10%" }}
                name="CheckboxGrp"
                defaultSelected={preferredContactArray}
                onChange={this.preferredContactChangeHandler}
                orientation={"horizontal"}
              >
                <Checkbox name="email" label={i18n._(t`Email`)} value="email" />

                <Checkbox name="sms" label={i18n._(t`SMS`)} value="sms" />
                <Checkbox
                  name="phone"
                  label={i18n._(t`Téléphone`)}
                  value="phone"
                />
              </CheckboxGroup>
            </Col>
          </Row>
        </FormCard>
        <br />

        <div className="next-buttons">
          <div className="account-creation-submission-button">
            <Mutation
              mutation={UPDATE_PERSENAL_INFO_MUTATION}
              variables={{
                firstName,
                lastName,
                address,
                phonenumber,
                profilePhoto,
                preferredContact
              }}
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
              onCompleted={data => {
                // history.push(ConfirmCode.path);
                this.onNextStepHandler(data);
              }}
            >
              {(mutation, { loading }) => (
                <span>
                  {loading ? (
                    <Button disabled>
                      <Spinner
                        type="pointed-circular"
                        color="#FFFFFF"
                        size={12}
                      />
                    </Button>
                  ) : (
                    <Button
                      icon="chevron-right"
                      onClick={() => this.isFormValid() && mutation()}
                    >
                      <Trans>Étape suivante</Trans>
                    </Button>
                  )}
                </span>
              )}
            </Mutation>
          </div>
          {/* <span>
            <Button
              icon="chevron-right"
              onClick={() => {
                this.onNextStepHandler();
              }}
            >
              <Trans>Étape suivante</Trans>
            </Button>
          </span> */}
        </div>
      </div>
    );
  }
}

myInformation.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    reset: PropTypes.func,
    changeAuthToken: PropTypes.func,
    changeAddress: PropTypes.func,
    changefirstName: PropTypes.func,
    changelastName: PropTypes.func,
    changePhonenumber: PropTypes.func,
    changeProfilePhoto: PropTypes.func,
    changeStep: PropTypes.func,
    firstName: PropTypes.string,
    changePreferredContact: PropTypes.func,
    lastName: PropTypes.string,
    address: PropTypes.string,
    phonenumber: PropTypes.string,
    searchAddress: PropTypes.shape({}),
    pictureUrl: PropTypes.string,
    profilePhoto: PropTypes.string,
    expressSignUp: PropTypes.bool,
    myJobPageFilled: PropTypes.bool,
    addressToShow: PropTypes.string,
    ScrapedfirstName: PropTypes.string,
    ScrapedlastName: PropTypes.string,
    ScrapedLocation: PropTypes.string,
    searchAddressToShow: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(myInformation));
