import { observable, action } from "mobx";

const defaultValues = {
  step: 0,
  postANeedJustAfterSignUp: 0,
  jobDescription: "",
  contractType: "",
  jobTitle: "",
  purchaseOrder: "",
  address: {},
  remote: "Indifferent",
  workRate: "",
  projectName: "",
  recruitmentContext: "",
  categories: [],
  skills: [],
  specialties: [],
  spokenLanguages: [],
  experience: 1,
  certifications: [],
  dailyRate: null,
  annualSalary: null
};

export default class PostANeedStore {
  /* OBSERVABLE FIELDS */
  @observable step;

  @observable _id;

  @observable id;

  @observable postANeedJustAfterSignUp;

  @observable jobDescription;

  @observable jobDescriptionFile;

  @observable contractType;

  @observable jobTitle;

  @observable address;

  @observable remote;

  @observable workRate;

  @observable projectName;

  @observable startDate;

  @observable endDate;

  @observable recruitmentContext;

  @observable experience;

  @observable categories;

  @observable skills;

  @observable specialties;

  @observable spokenLanguages;

  @observable certifications;

  @observable dailyRate;

  @observable annualSalary;

  @observable opportunity;

  @observable purchaseOrder;

  constructor(initialState = defaultValues) {
    this.opportunity = initialState.opportunity || null;
    this.step = initialState.step || 0;
    this.postANeedJustAfterSignUp = initialState.postANeedJustAfterSignUp || 0;
    this.jobDescriptionFile = initialState.jobDescriptionFile || {};
    this.jobDescription = initialState.jobDescription || "";
    this.contractType = initialState.contractType || "";
    this.jobTitle = initialState.jobTitle || "";
    this.purchaseOrder = initialState.purchaseOrder || "";
    this.address = initialState.address || {};
    this.remote = initialState.remote || "Indifferent";
    this.workRate = initialState.workRate || "";
    this.projectName = initialState.projectName || "";
    this.startDate = initialState.startDate || null;
    this.endDate = initialState.endDate || null;
    this.recruitmentContext = initialState.recruitmentContext || "";
    this.categories = initialState.categories || [];
    this.skills = initialState.skills || [];
    this.specialties = initialState.specialties || [];
    this.spokenLanguages = initialState.spokenLanguages || [];
    this.experience = initialState.experience || 0;
    this.certifications = initialState.certifications || [];
    this.dailyRate = initialState.dailyRate || null;
    this.annualSalary = initialState.annualSalary || null;
  }

  /* start action for post a need */
  @action
  changeStep = step => {
    this.step = step;
  };

  @action
  changePostANeedJustAfterSignUp = postANeedJustAfterSignUp => {
    this.postANeedJustAfterSignUp = postANeedJustAfterSignUp;
  };

  @action
  changeJobDescriptionFile = jobDescriptionFile => {
    this.jobDescriptionFile = jobDescriptionFile;
  };

  @action
  changeJobDescription = jobDescription => {
    this.jobDescription = jobDescription;
  };

  @action
  changeRecruitmentContext = recruitmentContext => {
    this.recruitmentContext = recruitmentContext;
  };

  @action
  changeContractType = contractType => {
    this.contractType = contractType;
  };

  @action
  changeJobTitle = jobTitle => {
    this.jobTitle = jobTitle;
  };

  @action
  changePurchaseOrder = purchaseOrder => {
    this.purchaseOrder = purchaseOrder;
  };

  @action
  changeAddress = address => {
    this.address = address;
  };

  @action
  changeRemote = remote => {
    this.remote = remote;
  };

  changeWorkRate = workRate => {
    this.workRate = workRate;
  };

  @action
  changeProjectName = projectName => {
    this.projectName = projectName;
  };

  @action
  changeStartDate = startDate => {
    this.startDate = startDate;
  };

  @action
  changeEndDate = endDate => {
    this.endDate = endDate;
  };

  @action
  removeSkill = value => {
    this.skills.remove(value);
  };

  @action
  addSkill = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html
    const arr = this.skills.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.skills = arr;
    }
  };

  @action
  changeExperience = experience => {
    this.experience = experience;
  };

  @action
  removeCategory = value => {
    this.categories.remove(value);
  };

  @action
  addCategory = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html

    const arr = this.categories.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.categories = arr;
    }
  };

  @action
  removeSpecialties = value => {
    this.specialties.remove(value);
  };

  @action
  addSpecialties = value => {
    const arr = this.specialties.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.specialties = arr;
    }
  };

  @action
  removeSpokenLanguage = item => {
    for (const element of this.spokenLanguages) {
      if (element.text === item) {
        this.spokenLanguages.remove(element);
      }
    }
  };

  @action
  addSpokenLanguage = item => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html
    const arr = this.spokenLanguages.slice();
    const found = arr.find(element => element.value === item.value);
    if (!found && item.value) {
      arr.push(item);
      this.spokenLanguages = arr;
    }
  };

  @action
  removeCertification = item => {
    for (const element of this.certifications) {
      if (element === item) {
        this.certifications.remove(element);
      }
    }
  };

  @action
  addCertification = item => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html
    const arr = this.certifications.slice();
    const found = arr.find(element => element === item);
    if (!found) {
      arr.push(item);
      this.certifications = arr;
    }
  };

  @action
  changeDailyRate = dailyRate => {
    this.dailyRate = dailyRate;
  };

  @action
  changeAnnualSalary = annualSalary => {
    this.annualSalary = annualSalary;
  };

  @action
  changeCertifications = certifications => {
    this.certifications = certifications;
  };

  @action
  changeOpportunity = opportunity => {
    this.reset();
    this.opportunity = opportunity;
    this.initOpportunity();
  };

  @action
  getCertification = certifications => {
    return certifications.split(",");
  };

  @action
  initOpportunity = () => {
    this.id = this.opportunity.id;
    this._id = this.opportunity._id;
    this.jobDescription = this.opportunity.description;
    this.contractType = this.opportunity.type;
    this.jobTitle = this.opportunity.title;
    this.purchaseOrder = this.opportunity.purchaseOrder;
    this.address = this.opportunity.address;
    if (this.opportunity.address) {
      this.address = { ...this.opportunity.address };
      delete this.address.__typename;
    }
    this.remote = this.opportunity.onSite;
    this.projectName = this.opportunity.project;
    this.startDate = this.opportunity.estimatedStartDate;
    this.endDate = this.opportunity.estimatedEndDate;
    this.workRate = this.opportunity.workRate;
    this.recruitmentContext = this.opportunity.context;
    this.categories = this.opportunity.categories;
    this.skills = this.opportunity.skills;
    this.specialties = this.opportunity.specialties;
    this.spokenLanguages =
      (this.opportunity.spokenLanguages &&
        this.opportunity.spokenLanguages.map(language => {
          return {
            value: language,
            text: language
          };
        })) ||
      [];
    this.experience = this.opportunity.yearsRequired;
    /* this.certifications = this.getCertification(
      this.opportunity.certifications
    ); */
    this.certifications =
      (this.opportunity.certifications &&
        this.getCertification(this.opportunity.certifications).map(
          certification => {
            return certification;
          }
        )) ||
      [];
    this.dailyRate = this.opportunity.expectedBillRate;
    this.annualSalary = this.opportunity.expectedBillRate;
  };

  @action
  reset = () => {
    this.step = 0;
    this.id = null;
    this._id = null;
    this.postANeedJustAfterSignUp = 0;
    this.jobDescription = "";
    this.jobDescriptionFile = {};
    this.contractType = "";
    this.jobTitle = "";
    this.address = {};
    this.remote = "Indifferent";
    this.workRate = "";
    this.projectName = "";
    this.recruitmentContext = "";
    this.experience = 0;
    this.specialties = [];
    this.categories = [];
    this.skills = [];
    this.spokenLanguages = [];
    this.certifications = [];
    this.dailyRate = null;
    this.annualSalary = null;
    this.startDate = null;
    this.endDate = null;
    this.purchaseOrder = "";
    this.opportunity = {};
  };
}
