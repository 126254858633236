import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { DatePicker, TextInput, Select, Icon, Validation } from "cf-neo-ui";
import { t } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import moment from "moment";
import classes from "./Line.module.scss";
import runtimeVars from "../../../../configs/runTimeVars";

@inject("timesheetStore", "sessionStore")
@observer
class Line extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLineValid: true,
      lineValidationMessage: ""
    };
  }

  changeHandler = (key, value) => {
    if (key === "minutes" && !value) {
      value = 0;
    }
    const { specialHour, timesheetStore } = this.props;
    const newSH = { ...specialHour };
    if (key === "type") newSH[key] = value;
    else newSH[key] = Number.parseInt(value, 10);
    timesheetStore.editSpecialHour(newSH);
    this.validateLine(newSH);
  };

  disableDates = day => {
    const { month, year } = this.props;
    const iterator = moment(day);
    return iterator.month() !== month - 1 || iterator.year() !== year;
  };

  validateLine(sh) {
    let valid = true;
    if (!this.validateType(sh.type)) valid = false;
    if (!this.validateDate(sh.date)) valid = false;
    if (!this.validateHours(sh.hours)) valid = false;
    if (!this.validateMinutes(sh.minutes)) valid = false;
    if (valid)
      this.setState({
        isLineValid: true,
        lineValidationMessage: ""
      });
    return valid;
  }

  validateType(v) {
    const { i18n } = this.props;
    if (v) return true;
    this.setState({
      isLineValid: false,
      lineValidationMessage: i18n._(t`Préciser le type`)
    });
    return false;
  }

  validateDate(v) {
    const { i18n } = this.props;
    if (v) return true;
    this.setState({
      isLineValid: false,
      lineValidationMessage: i18n._(t`Préciser la date`)
    });
    return false;
  }

  validateHours(v) {
    const { i18n } = this.props;
    if (v != null) {
      if (v >= 0 && v <= 24) return true;
      this.setState({
        isLineValid: false,
        lineValidationMessage: i18n._(
          t`Le nombre d'heures doit être entre 0 et 24`
        )
      });
      return false;
    }
    this.setState({
      isLineValid: false,
      lineValidationMessage: i18n._(t`Préciser le nombre d'heures`)
    });
    return false;
  }

  validateMinutes(v) {
    const { i18n } = this.props;
    if (v != null) {
      if (v >= 0 && v <= 59) return true;
      this.setState({
        isLineValid: false,
        lineValidationMessage: i18n._(
          t`Le nombre de minutes doit être compris entre 0 et 59`
        )
      });
      return false;
    }
    this.setState({
      isLineValid: false,
      lineValidationMessage: i18n._(t`Préciser le nombre de minutes`)
    });
    return false;
  }

  render() {
    const {
      i18n,
      specialHour,
      timesheetStore,
      month,
      year,
      sessionStore
    } = this.props;
    const { lineValidationMessage, isLineValid } = this.state;
    const { removeSpecialHour, timesheet } = timesheetStore;
    const { account } = sessionStore;
    const date = specialHour.date
      ? moment(specialHour.date).format("DD/MM/YYYY")
      : `01/${month}/${year}`;
    return (
      <Validation errorMessage={lineValidationMessage} valid={isLineValid}>
        <div className={classes.container}>
          <span className={classes.deleteItem}>
            {account.role === "CANDIDATE" &&
              (timesheet.status === "draft" ||
                timesheet.status === "open" ||
                timesheet.status === "rejected") && (
                <Icon
                  type="fat-close"
                  color="#8d0417"
                  color2="#d3354a"
                  width={20}
                  height={20}
                  onClick={() => removeSpecialHour(specialHour.id)}
                />
              )}
          </span>
          <span className={classes.selectItem}>
            <Select
              className={classes.modifySelect}
              options={{
                groupA: [
                  {
                    text: i18n._(t`Heures (taux normal)`),
                    value: "0",
                    selected: specialHour.type === "0"
                  },
                  {
                    text: i18n._(t`Heures supplémentaires`),
                    value: "1",
                    selected: specialHour.type === "1"
                  },

                  {
                    text: i18n._(t`Astreintes`),
                    value: "2",
                    selected: specialHour.type === "2"
                  }
                ]
              }}
              placeholder={i18n._(t`type`)}
              onSelect={v => this.changeHandler("type", v.value)}
              disabled={
                account.role === "CLIENT" ||
                timesheet.status === "approved" ||
                timesheet.status === "pending"
              }
            />
          </span>
          <span className={classes.datePickerItem}>
            <DatePicker
              size="small"
              className={classes.modifyDatePicker}
              dateFormat="DD/MM/YYYY"
              defaultDate={date}
              onChange={v => {
                if (v) this.changeHandler("date", moment(v).valueOf());
              }}
              disabledDaysMatcher={day => this.disableDates(day)}
              locale={runtimeVars.APP_LANG}
              acceptWhenOutsideClick
              disabled={
                account.role === "CLIENT" ||
                timesheet.status === "approved" ||
                timesheet.status === "pending"
              }
            />
          </span>
          <span className={classes.label}>h:</span>
          <span className={classes.numberItem}>
            <TextInput
              type="number"
              max="99"
              min="0"
              size="small"
              defaultValue={specialHour.hours && specialHour.hours.toString()}
              placeholder="h"
              onChange={e => this.changeHandler("hours", e.target.value)}
              className={classes.modifyTextInput}
              disabled={
                account.role === "CLIENT" ||
                timesheet.status === "approved" ||
                timesheet.status === "pending"
              }
            />
          </span>
          <span className={classes.label}>mn:</span>
          <span className={classes.numberItem}>
            <TextInput
              type="number"
              max="59"
              min="0"
              size="small"
              placeholder="mn"
              onChange={e => this.changeHandler("minutes", e.target.value)}
              defaultValue={
                specialHour.minutes && specialHour.minutes.toString()
              }
              className={classes.modifyTextInput}
              disabled={
                account.role === "CLIENT" ||
                timesheet.status === "approved" ||
                timesheet.status === "pending"
              }
            />
          </span>
        </div>
      </Validation>
    );
  }
}

Line.wrappedComponent.propTypes = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    removeSpecialHour: PropTypes.func,
    validateLine: PropTypes.func,
    editSpecialHour: PropTypes.func,
    timesheet: PropTypes.shape({
      status: PropTypes.string
    }),
    isLineValid: PropTypes.bool,
    lineValidationMessage: PropTypes.string
  }).isRequired,
  specialHour: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    date: PropTypes.string,
    hours: PropTypes.number,
    minutes: PropTypes.number,
    type: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired
};

export default withI18n()(Line);
