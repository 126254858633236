import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { Icon } from "cf-neo-ui";

import classes from "./styles.module.scss";

class Info extends Component {
  getTheDate = (d1, d2) => {
    const { i18n } = this.props;
    const dateStart = new Date(d1);
    if (d2) {
      const dateEnd = new Date(d2);
      return `${i18n._(t`Du`)} ${dateStart.getDate()}/${dateStart.getMonth() +
        1}/${dateStart.getFullYear()} ${i18n._(t`
  au`)} ${dateEnd.getDate()}/${dateEnd.getMonth() +
        1}/${dateEnd.getFullYear()}`;
    }
    return `${i18n._(
      t`à partir du`
    )} ${dateStart.getDate()}/${dateStart.getMonth() +
      1}/${dateStart.getFullYear()}`;
  };

  render() {
    const { jobOrder } = this.props;

    return (
      <div className={classes.offerInfo}>
        <span className={classes.element}>
          <span className={classes.info}>{jobOrder.project}</span>
        </span>
        <span className={classes.element}>
          <Icon
            type="briefcase"
            width={14}
            height={16}
            color="#B1B1B1"
            color2="#B1B1B1"
          />
          <span className={classes.info}>{jobOrder.employmentType}</span>
        </span>
        <span className={classes.element}>
          <Icon
            type="clock"
            width={14}
            height={16}
            color="#B1B1B1"
            color2="#B1B1B1"
          />
          <span className={classes.info}>
            {this.getTheDate(jobOrder.startDate, jobOrder.dateEnd)}
          </span>
        </span>
        <span className={classes.element}>
          <Icon
            type="map-marker"
            width={14}
            height={16}
            color="#B1B1B1"
            color2="#B1B1B1"
          />
          <span className={classes.info}>{jobOrder.address.city}</span>
        </span>
      </div>
    );
  }
}

Info.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  jobOrder: PropTypes.object.isRequired
};

export default withI18n()(withRouter(Info));
