import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Button, Row, Col, IllustrationIcons } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";

import Fade from "react-reveal/Fade";
import Routes from "../../layout/routes/index";
import classes from "./ServiceSection1.module.scss";
import theme from "../../../configs/theme";

@inject("appStore")
@observer
class ServiceSection1 extends Component {
  render() {
    const { history, appStore } = this.props;
    const { HbLayoutRoutes } = Routes;
    return (
      <div className={classes.service}>
        <Fade>
          <h1>
            <Trans>Des services</Trans>
          </h1>
        </Fade>
        <Fade>
          <p className={classes.paragraph}>
            <Trans>Pensés pour les freelances.</Trans>
          </p>
        </Fade>
        {appStore.width < 682 ? (
          <div>
            <table>
              <tbody>
                <tr>
                  <td className={classes.leftTd}>
                    <Fade>
                      <IllustrationIcons
                        type="search"
                        color2={theme.colors.lightMainColor}
                        dimension={60}
                      />
                    </Fade>
                  </td>
                  <td>
                    <Fade>
                      <p>
                        <Trans>
                          Une sélection d’offres adaptées à votre profil
                        </Trans>
                      </p>
                    </Fade>
                  </td>
                </tr>
                <tr>
                  <td className={classes.leftTd}>
                    <Fade>
                      <IllustrationIcons
                        type="hands"
                        color2={theme.colors.lightMainColor}
                        dimension={60}
                      />
                    </Fade>
                  </td>
                  <td>
                    <Fade>
                      <p>
                        <Trans>
                          Un accompagnement personnalisé par nos équipes
                        </Trans>
                      </p>
                    </Fade>
                  </td>
                </tr>
                <tr>
                  <td className={classes.leftTd}>
                    <Fade>
                      <IllustrationIcons
                        type="rocket"
                        color2={theme.colors.lightMainColor}
                        dimension={60}
                      />
                    </Fade>
                  </td>
                  <td>
                    <Fade>
                      <p>
                        <Trans>Rejoindre une communauté de freelances</Trans>
                      </p>
                    </Fade>
                  </td>
                </tr>
                <tr>
                  <td className={classes.leftTd}>
                    <Fade>
                      <IllustrationIcons
                        type="stopwatch"
                        color2={theme.colors.lightMainColor}
                        dimension={60}
                      />
                    </Fade>
                  </td>
                  <td>
                    <Fade>
                      <p>
                        <Trans>La garantie de paiement à 30 jours</Trans>
                      </p>
                    </Fade>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Fade big>
                      <Button
                        className={classes.styleButton}
                        icon="award"
                        iconPosition="right"
                        variant="primary"
                        onClick={() =>
                          history.push(
                            `${HbLayoutRoutes.SignUp.path}?type=consultant`
                          )
                        }
                        id="cta_hf_sec2_join"
                      >
                        <Trans>Rejoindre le club</Trans>
                      </Button>
                    </Fade>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <Row className={classes.row}>
            <Col xs={2} sm={4} md={4} lg={4} noGutter>
              <div className={classes.center}>
                <Fade>
                  <IllustrationIcons
                    type="search"
                    color2={theme.colors.lightMainColor}
                    dimension={100}
                  />
                </Fade>
                <Fade>
                  <p>
                    <Trans>
                      Une sélection d’offres adaptées à votre profil
                    </Trans>
                  </p>
                </Fade>
                <Fade>
                  <IllustrationIcons
                    type="hands"
                    color2={theme.colors.lightMainColor}
                    dimension={100}
                  />
                </Fade>
                <Fade>
                  <p>
                    <Trans>
                      Un accompagnement personnalisé par nos équipes
                    </Trans>
                  </p>
                </Fade>
              </div>
            </Col>
            <Col xs={2} sm={4} md={4} lg={4} noGutter>
              <div className={classes.center}>
                <Fade>
                  <IllustrationIcons
                    type="rocket"
                    color2={theme.colors.lightMainColor}
                    dimension={100}
                  />
                </Fade>
                <Fade>
                  <p>
                    <Trans>Rejoindre une communauté de freelances</Trans>
                  </p>
                </Fade>
                <Fade>
                  <IllustrationIcons
                    type="stopwatch"
                    color2={theme.colors.lightMainColor}
                    dimension={100}
                  />
                </Fade>
                <Fade>
                  <p>
                    <Trans>La garantie de paiement à 30 jours</Trans>
                  </p>
                </Fade>
              </div>
            </Col>

            <Col xs={2} sm={4} md={4} lg={4} noGutter>
              <div className={classes.center}>
                <Fade big>
                  <Button
                    icon="award"
                    iconPosition="right"
                    variant="primary"
                    onClick={() =>
                      history.push(
                        `${HbLayoutRoutes.SignUp.path}?type=consultant`
                      )
                    }
                    id="cta_hf_sec2_join"
                  >
                    <Trans>Rejoindre le club</Trans>
                  </Button>
                </Fade>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

ServiceSection1.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(ServiceSection1));
