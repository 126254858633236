import gql from "graphql-tag";

const USER_APPLICATION_QUERY = gql`
  query UserApplication($jobOrderId: ID!) {
    userApplication(jobOrderId: $jobOrderId) {
      id
      status
      jobOrder {
        id
        title
        sourcers {
          id
          name
          phone
          occupation
          customText1
          customText2
          email
          headShot
        }
      }
      reference {
        referenceEmail
        referencePhone
        referenceLastName
        referenceFirstName
      }
      lastNote
    }
  }
`;

export default USER_APPLICATION_QUERY;
