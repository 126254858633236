export default {
  center: false,
  items: 1,
  loop: true,
  autoWidth: true,
  margin: 50,
  dots: false,
  dotsClass: "owlDots",
  dotClass: "owlDot"
};
