import React, { PureComponent } from "react";
import { Avatar } from "cf-neo-ui";
import * as PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import moment from "moment";
import classes from "./Blogger.module.scss";
import decode from "../../../../utils/Utf8Text";
import runtimeVars from "../../../../configs/runTimeVars";

class Blogger extends PureComponent {
  constructor(props) {
    super(props);
    moment.locale(runtimeVars.APP_LANG);
    const mAdd = moment(props.addDate);
    const mUpdate = moment(props.updateDate);
    this.creation = {
      date: mAdd.format("DD MMMM YYYY"),
      time: mAdd.format("HH:mm")
    };
    if (props.updateDate) {
      this.update = {
        date: mUpdate.format("DD MMMM YYYY"),
        time: mUpdate.format("HH:mm")
      };
    } else this.update = null;
  }

  render() {
    const { name, avatar } = this.props;
    return (
      <div className={classes.container}>
        <Avatar dimension="64px" className={classes.avatar} src={avatar} />
        <div>
          <h4 className={classes.name}>
            <span>
              <Trans>Par</Trans>
            </span>
            {decode(name)}
          </h4>
          <p className={classes.description}>
            <span>
              <span>
                <Trans>Publié le</Trans>
              </span>
              {this.creation.date}
              <span>
                <Trans>à</Trans>
              </span>
              {this.creation.time}
            </span>
            {this.update && (
              <span>
                <span>
                  <Trans>, mis à jour le</Trans>
                </span>
                {this.update.date}
                <span>
                  <Trans>à</Trans>
                </span>
                {this.update.time}
              </span>
            )}
          </p>
        </div>
      </div>
    );
  }
}

Blogger.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  addDate: PropTypes.string,
  updateDate: PropTypes.string
};

Blogger.defaultProps = {
  name: "",
  avatar: "",
  addDate: "",
  updateDate: ""
};

export default Blogger;
