import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import SingleCandidateHeaderTitle from "./SingleCandidateHeaderTitle/SingleCandidateHeaderTitle";
import SingleCandidateHeaderInfos from "./SingleCandidateHeaderInfos/SingleCandidateHeaderInfos";
import classes from "./SingleCandidateHeader.module.scss";

class SingleCandidateHeader extends PureComponent {
  render() {
    const {
      id,
      occupation,
      city,
      employmentPreference,
      dateAvailable,
      availability
    } = this.props;
    return (
      <div className={classes.container} id="__SM__FULL__HEADER">
        <div className={classes.headerHolder} />
        <SingleCandidateHeaderTitle occupation={occupation} id={id} />
        <div className={classes.info}>
          <div className={classes.curtain} />
          <SingleCandidateHeaderInfos
            id={id}
            city={city}
            employmentPreference={employmentPreference}
            dateAvailable={dateAvailable}
            availability={availability}
          />
        </div>
      </div>
    );
  }
}

SingleCandidateHeader.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  occupation: PropTypes.string,
  city: PropTypes.string,
  dateAvailable: PropTypes.string,
  employmentPreference: PropTypes.string,
  availability: PropTypes.string
};

SingleCandidateHeader.defaultProps = {
  occupation: "",
  city: "",
  dateAvailable: "",
  employmentPreference: "",
  availability: ""
};

export default SingleCandidateHeader;
