import { observable, action } from "mobx";

export default class timesheetStore {
  @observable specialHours;

  @observable selectedDays;

  @observable timesheet;

  @observable total;

  @observable totalSpecial;

  @observable messages;

  @observable comment;

  @observable isSHValid;

  @observable shValidationMessage;

  @observable activatedTab;

  constructor(initialState = {}) {
    this.isSHValid = true;
    this.shValidationMessage = "";
    this.specialHoursId = 0;
    this.specialHours =
      initialState.specialHours ||
      [
        /* {
        id: 0,
        date: null,
        hours: null,
        minutes: null,
        type: null
      } */
      ];
    this.selectedDays = initialState.selectedDays || [];
    this.messages = initialState.messages || [];
    this.timesheet = initialState.timesheet || null;
    this.comment = initialState.comment || "";
    this.activatedTab = initialState.activatedTab || "timesheets_tab";
    this.updateTotal();
    this.updateTotalSpecial();
  }

  updateTotal = () => {
    const temp = this.selectedDays.slice();
    const total = temp.reduce(
      (a, i) => (i.minutes ? a + i.hours + i.minutes / 60 : a + i.hours),
      0.001
    );
    this.total = total;
  };

  updateTotalSpecial = () => {
    const temp = this.specialHours.slice();
    this.totalSpecial = temp.reduce(
      (a, i) => ({
        hours: (a.hours || 0) + (i.hours || 0),
        minutes: (a.minutes || 0) + (i.minutes || 0)
      }),
      {
        hours: 0,
        minutes: 0
      }
    );
    const resultDiv = this.totalSpecial.minutes / 60;
    const resultMode = this.totalSpecial.minutes % 60;
    this.totalSpecial = {
      hours: this.totalSpecial.hours + Math.floor(resultDiv),
      minutes: resultMode
    };
  };

  @action
  addSpecialHour = () => {
    this.specialHoursId += 1;
    const tempArray = this.specialHours.slice();
    tempArray.push({
      id: this.specialHoursId,
      date: null,
      hours: null,
      minutes: 0,
      type: null
    });

    this.specialHours.replace(tempArray);
    this.updateTotalSpecial();
    this.changeSpecialHours(tempArray);
  };

  @action
  editSpecialHour = specialHour => {
    const tempArray = this.specialHours.slice();
    const idx = tempArray.findIndex(sh => sh.id === specialHour.id);
    tempArray[idx] = specialHour;
    this.specialHours.replace(tempArray);
    this.updateTotalSpecial();
  };

  @action
  removeSpecialHour = id => {
    const tempArray = this.specialHours.filter(sh => sh.id !== id);
    this.specialHours.replace(tempArray);
    this.updateTotalSpecial();
  };

  @action
  updateSelectedDays = selectedDays => {
    this.selectedDays.replace(selectedDays);
    this.updateTotal();
  };

  @action
  addMessage = newMessage => {
    const tempArray = this.messages.slice();
    tempArray.push(newMessage);
    this.messages.replace(tempArray);
  };

  @action
  changeMessages = messages => {
    this.messages = messages;
  };

  @action
  changeTheTab = tab => {
    this.activatedTab = tab;
  };

  @action
  changeComment = comment => {
    this.comment = comment;
  };

  @action
  changeTimesheet = timesheet => {
    this.timesheet = timesheet;
  };

  @action
  changeSpecialHours = specialHours => {
    for (let i = 0; i < specialHours.length; i += 1) {
      specialHours[i].id = i + 1;
    }
    this.specialHours = specialHours;
  };

  @action
  validateLine = sh => {
    let valid = true;
    if (!this.validateType(sh.type)) valid = false;
    if (!this.validateDate(sh.date)) valid = false;
    if (!this.validateHours(sh.hours)) valid = false;
    if (!this.validateMinutes(sh.minutes)) valid = false;
    if (valid) {
      this.isSHValid = true;
      this.shValidationMessage = "";
    } else {
      this.isSHValid = false;
      this.shValidationMessage = "NOT_VALIDATED";
    }
    return valid;
  };

  @action
  validateType = v => {
    if (v) return true;
    return false;
  };

  @action
  validateDate = v => {
    if (v) return true;
    return false;
  };

  @action
  validateHours = v => {
    if (v != null) {
      if (v >= 0 && v <= 24) return true;
      return false;
    }
    return false;
  };

  @action
  validateMinutes = v => {
    if (v != null) {
      if (v >= 0 && v <= 59) return true;
      return false;
    }
    return false;
  };

  @action
  validationSpecialHours = specialHours => {
    for (let i = 0; i < specialHours.length; i += 1) {
      if (!this.validateLine(specialHours[i])) {
        return false;
      }
    }
    return true;
  };

  @action
  resetComment = () => {
    this.comment = "";
  };
}
