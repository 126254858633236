import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Tab, Tabs } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Query } from "react-apollo";
import classes from "./Timesheets.module.scss";
import TimesheetsTab from "./timesheets-tab";
import ReportsTab from "./reports-tab";
import SignaturesTab from "./signature-tab";
import { ISLOCKED } from "./queries";
import OpaqueScreen from "./OpaqueScreen";

@inject("sessionStore", "modalStore", "timesheetStore", "appStore")
@observer
class Timesheets extends Component {
  getQueryVariables = () => {
    const { sessionStore } = this.props;
    if (sessionStore.account && sessionStore.account.role === "CLIENT")
      return { id: parseInt(sessionStore.client.id, 10), role: "CLIENT" };
    if (sessionStore.account && sessionStore.account.role === "CANDIDATE") {
      return { id: parseInt(sessionStore.candidate.id, 10), role: "CANDIDATE" };
    }
    return null;
  };

  changeTab = tab => {
    const { timesheetStore } = this.props;
    const { changeTheTab } = timesheetStore;
    changeTheTab(tab);
  };

  guideLink = () => {
    const { sessionStore, appStore } = this.props;
    const { currentLanguage } = appStore;
    const role =
      sessionStore && sessionStore.account && sessionStore.account.role;
    if (role === "CLIENT") {
      if (currentLanguage === "fr")
        return "https://drive.google.com/file/d/1d4Ue9VyMgLs93_yk7_dCyeNvu5FTxeTu/view";
      return "https://drive.google.com/file/d/1PsZB7VIOO9cBjFu5-uy4cMVOn3g6coc6/view?usp=sharing";
    }
    if (currentLanguage === "fr")
      return "https://drive.google.com/file/d/1cu2y67LzNTcgcKqyaPn4BpaRz6VRRzGe/view";
    return "https://drive.google.com/file/d/1TGysq4bet0XJYAB6s7bJ3nImZ4QosMJA/view?usp=sharing";
  };

  render() {
    const { i18n, sessionStore, timesheetStore } = this.props;
    const { activatedTab } = timesheetStore;

    const client =
      sessionStore &&
      sessionStore.account &&
      sessionStore.account.role === "CLIENT";
    return (
      <div className={classes.container}>
        <a
          className={classes.guidePDF}
          name="download"
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          title={i18n._(t`Télécharger la ressource`)}
          href={this.guideLink()}
          download
        >
          <Trans>Télécharger le guide d’utilisation</Trans>
        </a>
        <Tabs
          activeTab={activatedTab}
          onChange={activeTab => this.changeTab(activeTab)}
        >
          <Tab name="timesheets_tab" tab={i18n._(t`Timesheets`)}>
            <TimesheetsTab />
          </Tab>
          <Tab name="reports_tab" tab={i18n._(t`Comptes rendus`)}>
            <ReportsTab />
          </Tab>
          {client && (
            <Tab name="signatures_tab" tab={i18n._(t`Signataires`)}>
              <SignaturesTab />
            </Tab>
          )}
        </Tabs>
        <Query
          query={ISLOCKED}
          variables={this.getQueryVariables()}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading) return <div>loading</div>;

            if (error) return null;

            const { islocked } = data;
            if (islocked) {
              return <OpaqueScreen />;
            }
            return null;
          }}
        </Query>
      </div>
    );
  }
}

Timesheets.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    }),
    candidate: PropTypes.shape({
      id: PropTypes.number
    }),
    client: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    signatairePopUp: PropTypes.bool,
    changeSignatairePopUp: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    changeTheTab: PropTypes.func,
    activatedTab: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(Timesheets);
