import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import { Divider, H4, P, Radio, RadioGroup, Spinner } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import runtimeVars from "../../../../../configs/runTimeVars";
import DISTANCE_MATRIX from "./query";
import classes from "./DistanceMatrix.module.scss";
import Location from "../../../../../components/location/location";

@inject("appStore")
@observer
class DistanceMatrix extends Component {
  constructor(props) {
    super(props);
    this.listKey = 0;
    this.state = {
      mode: "driving",
      userAddress: null
    };
  }

  RadioHandler = (value, name, e) => {
    this.setState({ mode: value });
  };

  userLocation = (address, formattedLocation) => {
    this.setState({ userAddress: formattedLocation });
  };

  displayTime = text => {
    this.listKey += 1;
    return text.split(" ").reduce((a, i) => {
      if (isNaN(i)) {
        a.push(<span>{`${i} `}</span>);
        return a;
      }
      a.push(`${i} `);
      return a;
    }, []);
  };

  render() {
    const { missionId, i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    const { mode, userAddress } = this.state;
    return (
      <div>
        <div className={classes.calcInput}>
          <H4 className={classes.header}>
            <Trans>Localisation</Trans>
          </H4>
          <Location getLocation={this.userLocation} />
          <RadioGroup
            name="transport_choice"
            onChange={this.RadioHandler}
            defaultSelected="driving"
          >
            <Radio label={i18n._(t`En voiture`)} value="driving" />
            <Radio label={i18n._(t`En transport en commun`)} value="transit" />
          </RadioGroup>
        </div>
        <Divider />
        <div className={classes.calcResult}>
          {!userAddress ? (
            <P>
              <Trans>Veuillez indiquer votre localisation</Trans>
            </P>
          ) : (
            <Query
              query={DISTANCE_MATRIX}
              variables={{
                missionId,
                address: userAddress,
                params: {
                  mode,
                  language: currentLanguage || runtimeVars.APP_LANG
                }
              }}
            >
              {({ loading, error: errorQuery, data }) => {
                if (errorQuery)
                  return (
                    <div className={classes.calcResult}>
                      <Trans>Rafraîchissez la page</Trans>
                    </div>
                  );
                if (loading)
                  return (
                    <Spinner
                      className={classes.spinner}
                      type="circular"
                      color="#C40B24;"
                      size={8}
                      bg="white"
                    />
                  );
                const { distanceMatrix } = data;
                const { status, rows } = distanceMatrix;
                if (status !== "OK") {
                  return (
                    <P>
                      <Trans>
                        Une erreur s’est produite, veuillez réessayer
                      </Trans>
                    </P>
                  );
                }
                if (rows[0].elements[0].status !== "OK") {
                  return (
                    <P>
                      <Trans>
                        Aucun itinéraire n’a pu être trouvé entre l’origine et
                        la destination.
                      </Trans>
                    </P>
                  );
                }
                return (
                  <div>
                    <div className={classes.numText}>
                      {this.displayTime(rows[0].elements[0].duration.text)}
                    </div>
                    <P>
                      <Trans>
                        La mission se situe à{" "}
                        {rows[0].elements[0].duration.text} de cette adresse en
                        {mode === "driving"
                          ? i18n._(t`voiture`)
                          : i18n._(t`transport en commun`)}{" "}
                        selon Google Maps
                      </Trans>
                    </P>
                  </div>
                );
              }}
            </Query>
          )}
        </div>
      </div>
    );
  }
}

DistanceMatrix.propTypes = {
  missionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(DistanceMatrix));
