import gql from "graphql-tag";

const MISSIONS_QUERY = gql`
  query MissionsQuery(
    $limit: Int
    $skip: Int
    $sortBy: JobOrderSortByInput
    $filter: JobOrderFilterInput
  ) {
    jobOrders(limit: $limit, skip: $skip, sortBy: $sortBy, filter: $filter) {
      id
      address {
        city
      }
      title
      duration {
        months
        days
      }
      customText1
      customText10
      total
      employmentType
      onSite
    }
  }
`;

export default MISSIONS_QUERY;
