import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import uniqid from "uniqid";

import {
  Row,
  Col,
  AutoComplete,
  TagList,
  Validation,
  Button,
  FormGroup,
  TextInput,
  Select,
  toaster,
  Spinner,
  InputSkeleton,
  Textarea
} from "cf-neo-ui";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import validate from "../../../utils/validators";
import languageList from "../../../configs/spokenLanguages";
import "./styles.scss";
import Location from "../../../components/location/location";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";

const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidateMutation(
    $_id: ID!
    $title: String
    $link: String
    $categories: [String]
    $skills: [String]
    $specialties: [String]
    $experience: Int
    $spokenLanguages: [String]
    $customTextBlock3: String
    $customTextBlock2: String
    $customTextBlock4: String
    $addressSouhaite: AddressInput
    $status: String
  ) {
    updateCandidate(
      input: {
        _id: $_id
        title: $title
        link: $link
        categories: $categories
        skills: $skills
        specialties: $specialties
        experience: $experience
        customTextBlock2: $customTextBlock2
        customTextBlock4: $customTextBlock4
        addressSouhaite: $addressSouhaite
        spokenLanguages: $spokenLanguages
        customTextBlock3: $customTextBlock3
        status: $status
      }
    ) {
      account {
        id
        name
      }
      candidate {
        title
        occupation
        experience
        categories
        companyURL
        specialties
        skills
        addressSouhaite {
          address1
          city
          countryID
          lat
          lng
          state
        }
        spokenLanguages
        status
        customTextBlock2
        customTextBlock4
        customTextBlock5
      }
    }
  }
`;
const GET_CATEGORIES = gql`
  {
    categories {
      name
    }
  }
`;

const GET_SKILLS = gql`
  {
    skills {
      name
    }
  }
`;

const GET_SPECIALTIES = gql`
  {
    specialties {
      name
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class MyJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTitleValid: true,
      isPresentationValid: true,
      isCategoriesValid: true,
      isSkillsValid: true,
      isLinkValid: true,
      isSpokenLanguagesValid: true,
      // isSpecialtiesValid: true,
      titleValidationMessage: "",
      presentationValidationMessage: "",
      categoriesValidationMessage: "",
      skillsValidationMessage: "",
      linkValidationMessage: "",
      spokenLanguagesValidationMessage: "",
      saveCase: false,
      spokenLanguagesSelectId: "",
      isDesiredLocationValid: true
      // specialtiesValidationMessage: ""
    };

    this.isFormValid = this.isFormValid.bind(this);
  }

  titleChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeTitle(e.target.value);
    this.validateTitle(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  experienceChangeHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.changeExperience(item.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };
  statusChangeHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.changeStatus(item.text);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };
  spokenLanguagesAddHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.addSpokenLanguage(item);
    this.validateSpokenLanguages(
      sessionStore.spokenLanguages.map(language => language.value)
    );
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);

    this.setState({ spokenLanguagesSelectId: uniqid() });
  };

  spokenLanguagesRemoveHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.removeSpokenLanguage(item);
    this.validateSpokenLanguages(
      sessionStore.spokenLanguages.map(language => language.value)
    );
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  categoryAddHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.addCategory(value);
    this.validateCategories(sessionStore.categories);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  categoryRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removeCategory(value);
    this.validateCategories(sessionStore.categories);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };
  skillAddHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.addSkill(value);
    this.validateSkills(sessionStore.skills);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  skillRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removeSkill(value);
    this.validateSkills(sessionStore.skills);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  specialtyAddHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.addSpecialties(value);
    // this.validateSpecialties(sessionStore.specialties);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  specialtyRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removeSpecialties(value);
    // this.validateSkills(sessionStore.specialties);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  linkChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeLink(e.target.value);
    this.validateLink(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  customTextBlock2ChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changecustomTextBlock2(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };

  desiredGeoAreasChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeDesiredGeoAreas(e.target.value);
    this.validateDesiredLocations(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    sessionStore.changeGeneralSaveCase(3);
  };
  presentationChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changePresentation(e.target.value);
    this.validatePresentation(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });

    sessionStore.changeGeneralSaveCase(3);
  };

  isFormValid = () => {
    const { sessionStore } = this.props;
    const {
      title,
      categories,
      skills,
      // specialties,
      customTextBlock3,
      customTextBlock2,
      customTextBlock4,
      link,
      spokenLanguages
    } = sessionStore;

    let valid = true;
    if (!this.validateTitle(title)) valid = false;
    if (!this.validateDesiredLocations(customTextBlock4)) valid = false;
    if (!this.validatePresentation(customTextBlock3)) valid = false;
    if (!this.validateCategories(categories)) valid = false;
    if (!this.validateSpokenLanguages(spokenLanguages)) valid = false;
    if (!this.validateSkills(skills)) valid = false;
    if (!this.validateLink(link)) valid = false;
    // if (!this.validateSpecialties(specialties)) valid = false;
    return valid;
  };

  validateTitle = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 50 }, { minlength: 3 }],
      i18n
    );
    this.setState({
      isTitleValid: res.isValid,
      titleValidationMessage: res.message
    });
    return res.isValid;
  };

  validateDesiredLocations = value => {
    const { i18n } = this.props;
    const res = validate(value, [{ maxlength: 35 }, { minlength: 3 }], i18n);
    this.setState({
      isDesiredLocationValid: res.isValid,
      DesiredLocationValidationMessage: res.message
    });
    return res.isValid;
  };
  validatePresentation = value => {
    const { i18n } = this.props;
    const res = validate(value, [{ maxlength: 300 }], i18n);
    this.setState({
      isPresentationValid: res.isValid,
      presentationValidationMessage: res.message
    });
    return res.isValid;
  };

  validateLink = value => {
    const { i18n } = this.props;
    const res = validate(value, ["link"], i18n);
    this.setState({
      isLinkValid: res.isValid,
      linkValidationMessage: res.message
    });
    return res.isValid;
  };

  validateSkills = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        {
          rule: { minlength: 2, maxlength: 10 },
          msg: i18n._(t`Choisissez entre deux et dix compétences`)
        }
      ],
      i18n
    );
    this.setState({
      isSkillsValid: res.isValid,
      skillsValidationMessage: res.message
    });
    return res.isValid;
  };

  validateSpokenLanguages = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        "required",
        {
          rule: { minlength: 1 },
          msg: i18n._(t`Non renseigné`)
        }
      ],
      i18n
    );
    this.setState({
      isSpokenLanguagesValid: res.isValid,
      spokenLanguagesValidationMessage: res.message
    });

    return res.isValid;
  };
  addressChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changeAddressSouhaite({ address1: v });

    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;

    changeGeneralSaveCase(3);
  };
  getLocationHandler = (address, formattedAddress) => {
    const { sessionStore } = this.props;
    sessionStore.changeAddressSouhaite({
      address1: formattedAddress.address || address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      zip: formattedAddress.zip,
      state: formattedAddress.state,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    });
  };

  validateCategories = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        "required",
        {
          rule: { minlength: 1 },
          msg: i18n._(t`Non renseigné`)
        }
      ],
      i18n
    );
    this.setState({
      isCategoriesValid: res.isValid,
      categoriesValidationMessage: res.message
    });
    return res.isValid;
  };

  /* validateSpecialties = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        {
          rule: { minlength: 1 },
          msg: i18n._(t`Choisissez au moins une spécialité`)
        }
      ],
      i18n
    );
    this.setState({
      isSpecialtiesValid: res.isValid,
      specialtiesValidationMessage: res.message
    });
    return res.isValid;
  }; */

  updateCandidateCompletedHandler = () => {
    const { i18n, appStore, sessionStore } = this.props;
    toaster.success({
      title: i18n._(t`Mon métier`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: false });
    const { changeGeneralSaveCase } = sessionStore;

    changeGeneralSaveCase(null);
    appStore.refreshLayout();
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };
  render() {
    const { i18n, sessionStore } = this.props;
    const {
      _id,
      title,
      link,
      experience,
      categories,
      skills,
      specialties,
      spokenLanguages,
      status,
      addressSouhaite,
      customTextBlock3,
      customTextBlock2,
      customTextBlock4
    } = sessionStore;
    const {
      isTitleValid,
      isPresentationValid,
      isCategoriesValid,
      isSkillsValid,
      isLinkValid,
      isSpokenLanguagesValid,
      titleValidationMessage,
      presentationValidationMessage,
      categoriesValidationMessage,
      skillsValidationMessage,
      linkValidationMessage,
      spokenLanguagesValidationMessage,
      saveCase,
      spokenLanguagesSelectId,
      DesiredLocationValidationMessage,
      isDesiredLocationValid
      // isSpecialtiesValid,
      // specialtiesValidationMessage
    } = this.state;
    const meta = staticMetaTags(i18n).job;
    return (
      <div className="profile">
        {metaTags(
          urls.myJob(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <div className="formCard no-gutter with-padding">
          <Row>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="jobTitle">
                  <Trans>Intitulé</Trans>
                </label>
                <Validation
                  errorMessage={titleValidationMessage}
                  valid={isTitleValid}
                >
                  <TextInput
                    id="jobTitle"
                    type="text"
                    className="form-input"
                    placeholder={i18n._(
                      t`Ex : Architecte Java, Consultant SAP`
                    )}
                    iconColor="#D3354A"
                    value={title}
                    onChange={this.titleChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="jobExperience">
                  <Trans>Expérience</Trans>
                </label>

                <Select
                  id="jobExperience"
                  options={{
                    groupA: [
                      {
                        text: `${i18n._(t`Junior`)}(0-3)`,
                        value: 1,
                        ...(experience === 1 && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`Confirmé`)} (3-7)`,
                        value: 2,
                        ...(experience === 2 && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`Senior`)} (7-10)`,
                        value: 3,
                        ...(experience === 3 && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`Expert`)} (10+)`,
                        value: 4,
                        ...(experience === 4 && {
                          selected: true
                        })
                      }
                    ]
                  }}
                  onSelect={this.experienceChangeHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="jobExpertise">
                  <Trans>Domaines d’expertise</Trans>
                </label>
                <div>
                  <Query query={GET_CATEGORIES}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error)
                        return `${i18n._(t`Erreur`)} !${error.message}`;
                      const allCategories = [];
                      data.categories.map(cat => allCategories.push(cat.name));
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            id="jobExpertise"
                            autoComplete="off"
                            clearOnSelect
                            options={allCategories}
                            iconColor="#FA324A"
                            iconColor2="#BF142A"
                            icon="bars"
                            placeholder={i18n._(t`Sélectionner des domaines`)}
                            onSelect={val => this.categoryAddHandler(val)}
                            valid={isCategoriesValid}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                <Validation
                  errorMessage={categoriesValidationMessage}
                  valid={isCategoriesValid}
                >
                  <TagList
                    tags={categories}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.categoryRemoveHandler(val)}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="jobSpecialities">
                  <Trans>Spécialités</Trans>
                </label>
                <div>
                  <Query query={GET_SPECIALTIES}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error)
                        return `${i18n._(t`Erreur`)} !${error.message}`;

                      const { specialties: specialtiesData } = data;
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            id="jobSpecialities"
                            clearOnSelect
                            autoComplete="off"
                            options={specialtiesData.map(item => item.name)}
                            iconColor="#FA324A"
                            iconColor2="#BF142A"
                            icon="bars"
                            placeholder={i18n._(
                              t`Sélectionner des spécialités`
                            )}
                            onSelect={val => this.specialtyAddHandler(val)}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                {/* <Validation
                  errorMessage={specialtiesValidationMessage}
                  valid={isSpecialtiesValid}
                > */}
                <TagList
                  tags={specialties}
                  variant="secondary"
                  closable
                  className="tag-list"
                  onClose={val => this.specialtyRemoveHandler(val)}
                />
                {/* </Validation> */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="jobSkills">
                  <Trans>Compétences</Trans>
                </label>
                <div>
                  <Query query={GET_SKILLS}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error)
                        return `${i18n._(t`Erreur`)} !${error.message}`;
                      var allSkills = [];
                      data.skills.map(skill => {
                        allSkills.push(skill.name);
                        allSkills = [...new Set(allSkills)];
                      });
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            id="jobSkills"
                            autoComplete="off"
                            clearOnSelect
                            options={allSkills}
                            iconColor="#FA324A"
                            iconColor2="#BF142A"
                            icon="bars"
                            placeholder={i18n._(
                              t`Sélectionner des compétences`
                            )}
                            onSelect={val => this.skillAddHandler(val)}
                            valid={isSkillsValid}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                <Validation
                  errorMessage={skillsValidationMessage}
                  valid={isSkillsValid}
                >
                  <TagList
                    tags={skills}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.skillRemoveHandler(val)}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="soft skills">
                  <Trans>Mes Softskills</Trans>
                  <picture className="tooltip2">
                    <img src="/assets/images/tooltip.png" alt="tooltip" />
                    <div className="right">
                      <Trans>
                        veuillez ajouter vos soft skills séparées par des
                        virgules.
                      </Trans>
                    </div>
                    <i />
                  </picture>
                </label>
                <TextInput
                  id="soft skills"
                  type="text"
                  autoComplete="off"
                  className="form-input"
                  placeholder={i18n._(t`Ajouter vos soft skills`)}
                  iconColor="#D3354A"
                  value={customTextBlock2}
                  onChange={this.customTextBlock2ChangeHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="jobLanguages">
                  <Trans>Langues parlées</Trans>
                </label>
                <div>
                  <Select
                    id="jobLanguages"
                    //key={spokenLanguagesSelectId}
                    options={{
                      groupA: languageList(i18n)
                    }}
                    onSelect={val => this.spokenLanguagesAddHandler(val)}
                    placeholder={i18n._(t`Sélectionner des langues`)}
                    valid={isSpokenLanguagesValid}
                  />
                </div>
                <Validation
                  errorMessage={spokenLanguagesValidationMessage}
                  valid={isSpokenLanguagesValid}
                >
                  <TagList
                    tags={spokenLanguages.map(language =>
                      i18n._(language.text)
                    )}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.spokenLanguagesRemoveHandler(val)}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="jobSkills">
                  <Trans>Zones géographiques souhaitées</Trans>
                </label>
                <Validation
                  errorMessage={DesiredLocationValidationMessage}
                  valid={isDesiredLocationValid}
                >
                  <TextInput
                    id="desired geographic area"
                    type="text"
                    autoComplete="off"
                    className="form-input"
                    placeholder={i18n._(
                      t`Ajouter votre/vos Zone(s) géographique(s) souhaitée(s)`
                    )}
                    iconColor="#D3354A"
                    value={customTextBlock4}
                    onChange={this.desiredGeoAreasChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="jobAttachement">
                  <Trans>Ma présentation</Trans>
                </label>
                <Validation
                  errorMessage={presentationValidationMessage}
                  valid={isPresentationValid}
                >
                  <Textarea
                    id="jobAttachement"
                    type="url"
                    style={{ height: "150px" }}
                    spellCheck="false"
                    className="form-input"
                    value={customTextBlock3}
                    onChange={this.presentationChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="jobAttachement">
                  <Trans>Portfolio, CV en ligne, site personnel</Trans>
                </label>
                <Validation
                  errorMessage={linkValidationMessage}
                  valid={isLinkValid}
                >
                  <TextInput
                    id="jobAttachement"
                    type="url"
                    spellCheck="false"
                    className="form-input"
                    placeholder={i18n._(t`https://monsite.fr`)}
                    value={link}
                    onChange={this.linkChangeHandler}
                  />
                </Validation>
              </FormGroup>
              <FormGroup>
                <label className="form-label" htmlFor="status">
                  <Trans>Votre statut</Trans>
                </label>
                <Select
                  id="status"
                  style={{ marginTop: "15px" }}
                  // placeholder={status}
                  placeholder={i18n._(t`Quel est votre statut`)}
                  //value={status}
                  options={{
                    groupA: [
                      {
                        text: ``,
                        value: 1,
                        ...(status === `` && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`Autoentrepreneur`)}`,
                        value: 1,
                        ...(status === `${i18n._(t`Autoentrepreneur`)}` && {
                          selected: true
                        })
                      },

                      {
                        text: `${i18n._(t`SASU`)}`,
                        value: 2,
                        ...(status === `${i18n._(t`SASU`)}` && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`EURL`)}`,
                        value: 3,
                        ...(status === `${i18n._(t`EURL`)}` && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`Portage Salarial`)}`,
                        value: 4,
                        ...(status === `${i18n._(t`Portage Salarial`)}` && {
                          selected: true
                        })
                      },
                      {
                        text: `${i18n._(t`Autre`)}`,
                        value: 5,
                        ...(status === `${i18n._(t`Autre`)}` && {
                          selected: true
                        })
                      }
                    ]
                  }}
                  onSelect={this.statusChangeHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row style={{ "flex-direction": "row-reverse" }}>
            {" "}
            <div
              className="next-buttons"
              style={{ marginRight: "19px", marginTop: "10px" }}
            >
              <Mutation
                mutation={UPDATE_CANDIDATE_MUTATION}
                variables={{
                  _id,
                  title,
                  experience,
                  categories,
                  specialties,
                  skills,
                  customTextBlock2,
                  customTextBlock4,
                  addressSouhaite,
                  status,
                  link,
                  customTextBlock3,
                  spokenLanguages: spokenLanguages.map(
                    language => language.value
                  )
                }}
                onCompleted={data => this.updateCandidateCompletedHandler(data)}
                onError={errors => {
                  errors.graphQLErrors.forEach(({ message, data }) => {
                    if (data && data.isCustomError) {
                      this.onErrorHandler(message);
                    }
                  });
                }}
              >
                {(mutation, { loading }) => (
                  <Button
                    disabled={loading || !saveCase}
                    onClick={() => {
                      if (this.isFormValid()) return mutation();
                      return null;
                    }}
                  >
                    {loading ? (
                      <Spinner
                        type="pointed-circular"
                        color="#FFFFFF"
                        size={12}
                      />
                    ) : (
                      <Trans>Enregistrer</Trans>
                    )}
                  </Button>
                )}
              </Mutation>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

MyJob.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    changeTitle: PropTypes.func,
    changecustomTextBlock2: PropTypes.func,
    changeDesiredGeoAreas: PropTypes.func,
    changeLink: PropTypes.func,
    isCandidateWorkHistoryValid: PropTypes.bool,
    changeCandidateWorkHistory: PropTypes.func,
    changeStep: PropTypes.func,
    changeExperience: PropTypes.func,
    changeAddressSouhaite: PropTypes.func,
    addSpokenLanguage: PropTypes.func,
    removeSpokenLanguage: PropTypes.func,
    addCategory: PropTypes.func,
    removeCategory: PropTypes.func,
    addSkill: PropTypes.func,
    removeSkill: PropTypes.func,
    addSpecialties: PropTypes.func,
    removeSpecialties: PropTypes.func,
    candidateWorkHistory: PropTypes.arrayOf(PropTypes.number).isRequired,
    spokenLanguages: PropTypes.shape,
    categories: PropTypes.shape,
    skills: PropTypes.shape,
    title: PropTypes.string,
    customTextBlock2: PropTypes.string,
    customTextBlock4: PropTypes.string,
    customTextBlock3: PropTypes.string,
    link: PropTypes.string,
    specialties: PropTypes.shape,
    experience: PropTypes.shape,
    resetFields: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func
  }).isRequired
};

export default withI18n()(MyJob);
