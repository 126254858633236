import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Icon, VoidOfferCard } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import classes from "./PlusHoures.module.scss";
import Line from "./line/Line";
import theme from "../../../configs/theme";

@inject("timesheetStore", "sessionStore")
@observer
class PlusHoures extends Component {
  render() {
    const { i18n, timesheetStore, month, year, sessionStore } = this.props;
    const { specialHours, addSpecialHour, timesheet } = timesheetStore;
    const { account } = sessionStore;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className="tooltip">
            <Trans>Heures & Astreintes</Trans>
            <span className="tooltipText">
              <Trans>
                Utilisez cette case pour indiquer des heures supplémentaires ou
                un tarif majoré à votre client et à notre service de facturation
              </Trans>
            </span>
            &nbsp;
            <Icon
              type="info"
              size="tiny"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
          </div>
        </div>
        <div className={classes.content}>
          {specialHours.length
            ? specialHours.map(sh => (
                <div>
                  <Line
                    key={`special_hour_line_${sh.id}`}
                    specialHour={sh}
                    month={month}
                    year={year}
                  />
                </div>
              ))
            : null}

          <div style={{ textAlign: "center", color: "red" }}>
            {timesheetStore.shValidationMessage === "NOT_VALIDATED"
              ? i18n._(t`Merci de vérifier les champs erronés`)
              : ""}
          </div>
          <div className={classes.plusHolder}>
            <VoidOfferCard
              className={classes.plus}
              onClick={addSpecialHour}
              tooltip={i18n._(t`Ajouter une ligne`)}
              disabled={
                account.role === "CLIENT" ||
                timesheet.status === "approved" ||
                timesheet.status === "pending"
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

PlusHoures.wrappedComponent.propTypes = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    addSpecialHour: PropTypes.func,
    isSHValid: PropTypes.bool,
    shValidationMessage: PropTypes.string,
    specialHours: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        date: PropTypes.string,
        hours: PropTypes.number,
        minutes: PropTypes.number,
        type: PropTypes.string
      })
    ),
    timesheet: PropTypes.shape({
      status: PropTypes.string
    })
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired
};

export default withI18n()(PlusHoures);
