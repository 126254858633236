import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Button, Row, Col } from "cf-neo-ui";
import { withI18n } from "@lingui/react/index";
import { Trans } from "@lingui/macro";
import Fade from "react-reveal/Fade";
import classes from "./HeroSection1.module.scss";
import Routes from "../../layout/routes";

@inject("sessionStore", "modalStore")
@observer
class HeroSection1 extends Component {
  toSignUp = () => {
    const { history } = this.props;
    history.push(`${Routes.HbLayoutRoutes.SignUp.path}?type=consultant`);
  };

  toForm = () => {
    const { sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CLIENT") {
        const { Offer } = Routes.HbLayoutRoutes;
        history.push(Offer.path);
      } else history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    } else history.push(Routes.HbLayoutRoutes.Login.path);
  };

  clickHandler = () => {
    const { modalStore, sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CANDIDATE") {
        history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
      } else if (signUpUserRole === "CLIENT") {
        modalStore.changeIsVisible(true);
      }
    } else {
      history.push(Routes.HbLayoutRoutes.Login.path);
    }
  };

  render() {
    return (
      <div className={classes.hero}>
        <Fade top>
          <h6>
            <Trans>
              Besoin d'un partenaire pour le recrutement de vos ressources IT ?
            </Trans>
          </h6>
        </Fade>
        <Fade bottom>
          <h1>
            <Trans>Rejoignez le club</Trans>
          </h1>
        </Fade>
        <Fade bottom>
          <p className={classes.paragraph}>
            <Trans>
              Et trouvez les meilleurs consultants en informatique pour vos
              projets
            </Trans>
          </p>
        </Fade>

        <div style={{ paddingTop: "40px" }} className={classes.buttonGroup}>
          <Row noGape>
            <Col noGutter>
              <Fade bottom>
                <div className={classes.b1}>
                  <Button
                    className={classes.styleButton1}
                    icon="award"
                    iconPosition="right"
                    variant="primary"
                    onClick={this.clickHandler}
                    // onClick={this.toForm}
                  >
                    <Trans>Je poste un besoin</Trans>
                  </Button>
                </div>
              </Fade>
            </Col>
            <Col noGutter>
              <Fade bottom>
                <div className={classes.b1}>
                  <Button
                    className={classes.styleButton2}
                    variant="secondary"
                    onClick={this.toForm}
                  >
                    <Trans>Voir la video</Trans>
                  </Button>
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

HeroSection1.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    signUpUserRole: PropTypes.string
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    changeIsVisible: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(HeroSection1));
