import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { t, Trans } from "@lingui/macro";
import {
  TextInput,
  Tag,
  Button,
  Modal,
  Col,
  Row,
  Spinner,
  toaster,
  Pagination
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Mutation, Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import classes from "./styles.module.scss";
import { APPROVALS_QUERY } from "./query";
import { DELETE_APPROVAL } from "./mutation";
import PopUpAddSignataire from "./PopUpAddSignataire";
import isEmail from "../../../utils/isEmail";
import { isOnePage } from "../../../utils/helpers";

const isInt = value => value == Number.parseInt(value, 10);

@inject("modalStore", "signataireStore", "sessionStore", "appStore")
@observer
class Index extends Component {
  constructor(props) {
    super(props);
    try {
      this.isClient = props.sessionStore.account.role === "CLIENT";
    } catch (e) {
      this.isClient = false;
    }
    this.state = {
      startDate: null,
      endDate: null,
      search: "",
      skip: 0,
      isSignatoryExist: false,
      ids: null,
      email: null,
      currentPage: 1,
      pageSize: 10
    };
    this.tempSearch = "";
    this.total = 0;
  }

  search = () => {
    if (!this.tempSearch)
      return this.setState({ search: null, ids: null, email: null });
    this.setState({ skip: 0, currentPage: 1 });
    if (isInt(this.tempSearch))
      return this.setState({
        ids: [Number.parseInt(this.tempSearch, 10)],
        email: null,
        search: null
      });
    if (isEmail(this.tempSearch))
      return this.setState({ email: this.tempSearch, search: null, ids: null });
    return this.setState({ search: this.tempSearch, ids: null, email: null });
  };

  renderPagination = length => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: length,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };
    return <Pagination {...paginationProps} />;
  };

  setTotalApprovals = approval => {
    if (approval) this.total = approval.total;
    return this.total;
  };

  getQueryVariables = () => {
    const {
      pageSize,
      skip,
      startDate,
      endDate,
      search,
      ids,
      email
    } = this.state;

    const filter = {};

    if (skip) filter.skip = skip;
    if (pageSize) filter.limit = pageSize;
    if (startDate) filter.periodStart = startDate;
    if (endDate) filter.periodEnd = endDate;
    if (ids) filter.placementIDs = ids;
    if (search) filter.name = search;
    if (email) filter.email = email;

    return filter;
  };

  displaySignatairePopUp = (placementID, signatoryType, signatory) => {
    const { modalStore, signataireStore } = this.props;
    const { changeSignatairePopUp } = modalStore;
    const { changeSignatoryType, changePlacementID } = signataireStore;
    if (signatory) this.setState({ isSignatoryExist: true });
    else this.setState({ isSignatoryExist: false });
    changeSignatoryType(signatoryType);
    changePlacementID(placementID);
    changeSignatairePopUp(true);
  };

  render() {
    const { i18n, modalStore, appStore } = this.props;
    const { signatairePopUp } = modalStore;
    const { search, isSignatoryExist, pageSize } = this.state;
    let longueur = null;
    return (
      <div className={classes.container}>
        <Modal
          centred
          isVisible={signatairePopUp}
          withCloseButton={false}
          position="fixed"
        >
          <PopUpAddSignataire isSignatoryExist={isSignatoryExist} />
        </Modal>
        <div className={appStore.width < 1024 && classes.tableBlock}>
          <Row>
            <Col />
            <Col />
            <Col>
              <span className={classes.searchBar}>
                <TextInput
                  size="small"
                  placeholder={
                    this.isClient
                      ? i18n._(t`chercher par id, consultant`)
                      : i18n._(t`chercher par id, entreprise ou signataire`)
                  }
                  icon="search"
                  radius={50}
                  defaultValue={search || ""}
                  onChange={e => {
                    this.tempSearch = e.target.value;
                  }}
                  onEnterPressed={this.search}
                  clickableIcon
                  onIconClicked={this.search}
                />
              </span>
            </Col>
          </Row>

          <Query
            query={APPROVALS_QUERY}
            variables={this.getQueryVariables()}
            fetchPolicy="cache-and-network"
          >
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <Trans>Mission</Trans>
                        </th>
                        <th>
                          <Trans>Placement</Trans>
                        </th>
                        <th>
                          <Trans>Consultant</Trans>
                        </th>
                        <th>
                          <Trans>Client</Trans>
                        </th>
                        <th>
                          <Trans>Signataire principal</Trans>
                        </th>
                        <th>
                          <Trans>Signataires secondaires</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={6} style={{ textAlign: "center" }}>
                          <Spinner
                            type="pointed-circular"
                            color="red"
                            size={20}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );

              if (error)
                return (
                  <tr>
                    <td>Error</td>
                  </tr>
                );
              const { approvals } = data;
              if (approvals) longueur = this.setTotalApprovals(approvals[0]);
              return (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <Trans>Mission</Trans>
                        </th>
                        <th>
                          <Trans>Placement</Trans>
                        </th>
                        <th>
                          <Trans>Consultant</Trans>
                        </th>
                        <th>
                          <Trans>Client</Trans>
                        </th>
                        <th>
                          <Trans>Signataire principal</Trans>
                        </th>
                        <th>
                          <Trans>Signataires secondaires</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvals.map(approval => {
                        return (
                          <tr key={approval.placementID}>
                            <td>
                              <span
                                className={classes.missionTag}
                                title={approval.title && approval.title}
                              >
                                {approval.title && approval.title}
                              </span>
                            </td>
                            <td>
                              <Tag text={`#${approval.placementID}`} />
                            </td>
                            <td>
                              {approval.candidate
                                ? `${approval.candidate.name}`
                                : ""}
                            </td>
                            <td>
                              {approval.client ? `${approval.client.name}` : ""}
                            </td>
                            <td>
                              <div>
                                {approval.approvingClient
                                  ? `name: ${approval.approvingClient.name}`
                                  : ""}
                              </div>
                              <div>
                                {approval.approvingClient
                                  ? `email: ${approval.approvingClient.email}`
                                  : ""}
                              </div>
                            </td>
                            <td>
                              <Row noGape>
                                <Col noGutter>
                                  {approval.approvingClient2 &&
                                  approval.approvingClient2.isSigned ? (
                                    <div>
                                      <div>
                                        {approval.approvingClient2
                                          ? `name: ${approval.approvingClient2.name}`
                                          : ""}
                                      </div>
                                      <div>
                                        {approval.approvingClient2
                                          ? `email: ${approval.approvingClient2.email}`
                                          : ""}
                                      </div>
                                    </div>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {approval.approvingClient2
                                        ? `email: ${approval.approvingClient2.email}`
                                        : ""}
                                    </div>
                                  )}
                                </Col>
                                <Col noGutter>
                                  <span>
                                    {approval.approvingClient2 ? (
                                      <Button
                                        buttonShape="edituser"
                                        color="#8d0417"
                                        color2="#d3354a"
                                        width={30}
                                        height={30}
                                        onClick={() =>
                                          this.displaySignatairePopUp(
                                            approval.placementID,
                                            2,
                                            approval.approvingClient2
                                          )
                                        }
                                      />
                                    ) : (
                                      <Button
                                        buttonShape="adduser"
                                        color="#8d0417"
                                        color2="#d3354a"
                                        width={30}
                                        height={30}
                                        onClick={() =>
                                          this.displaySignatairePopUp(
                                            approval.placementID,
                                            2,
                                            approval.approvingClient2
                                          )
                                        }
                                      />
                                    )}
                                    {approval.approvingClient2 && (
                                      <Mutation
                                        mutation={DELETE_APPROVAL}
                                        variables={{
                                          placementID: approval.placementID,
                                          approvingClient2:
                                            approval.approvingClient2.email
                                        }}
                                        refetchQueries={["Approvals"]}
                                        onCompleted={() => {
                                          toaster.success({
                                            title: i18n._(
                                              t`suppression du signataire secondaire`
                                            ),
                                            description: i18n._(
                                              t`la suppression du signataire secondaire est faite avec succès`
                                            )
                                          });
                                        }}
                                        onError={errors =>
                                          console.log("error :", errors)
                                        }
                                      >
                                        {(mutation, { loading: loading1 }) =>
                                          loading1 ? (
                                            <Button
                                              disabled
                                              buttonShape="fat-close"
                                              color="#8d0417"
                                              color2="#d3354a"
                                              width={30}
                                              height={30}
                                            />
                                          ) : (
                                            <Button
                                              buttonShape="fat-close"
                                              color="#8d0417"
                                              color2="#d3354a"
                                              width={30}
                                              height={30}
                                              onClick={() => mutation()}
                                            />
                                          )
                                        }
                                      </Mutation>
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {!approvals ||
                  approvals.length === 0 ||
                  isOnePage(pageSize, longueur) ? null : (
                    <div className={classes.pagination}>
                      {this.renderPagination(longueur)}
                    </div>
                  )}
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

Index.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  modalStore: PropTypes.shape({
    changeSignatairePopUp: PropTypes.func,
    signatairePopUp: PropTypes.bool
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  signataireStore: PropTypes.shape({
    changeSignatoryType: PropTypes.func,
    changePlacementID: PropTypes.func
  }).isRequired
};

export default withI18n()(Index);
