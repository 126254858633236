import React from "react";
import { Helmet } from "react-helmet-async";
import CFGlobals from "../configs/club-freelance-globals";
import runtimeVars from "../configs/runTimeVars";

const WEBSITE = CFGlobals.name;
const ADDRESS = runtimeVars.FRONTEND_BASE_URL;
const testMode = runtimeVars.testMode;
const devMode = runtimeVars.devMode;

const defaults = {
  title: WEBSITE,
  description:
    "Trouvez les plus belles offres IT et les meilleurs consultants Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle... Rejoignez Club Freelance, la communauté des talents et recruteurs IT.",
  imageUrl: `${ADDRESS}/mq.png`,
  keywords: "conusuling, missions, club freelance"
};

const metaTags = (url, title, description, imageUrl, keywords, ldJson) => {
  if (imageUrl) {
    defaults.imageUrl = imageUrl;
  }

  if (defaults.title === "Mindquest") {
    defaults.description =
      "Votre partenaire pour donner de l’élan à vos projets IT et à votre carrière dans la tech. CDI ou contrat : recrutez les meilleurs talents IT pour accélérer sur vos projets. Un account manager dédié et aucun prépaiement.";
    defaults.keywords = "conusuling, missions, Mindquest";
  }

  return (
    <Helmet>
      <link rel="canonical" href={url} />

      <title>{title}</title>
      {testMode || devMode ? <meta name="robots" content="none" /> : ""}
      <meta name="description" content={description || defaults.description} />
      <meta name="keywords" content={keywords || defaults.keywords} />
      <meta name="copyright" content={WEBSITE} />
      <meta name="identifier-URL" content={ADDRESS} />

      {/* OpenGraph Meta Tags */}
      <meta property="og:site_name" content={WEBSITE} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || defaults.title} />
      <meta
        property="og:description"
        content={description || defaults.description}
      />
      <meta property="og:image:secure_url" content={url} />
      <meta property="og:image" content={defaults.imageUrl} />
      <meta property="og:url" content={url} />

      {/* twitter cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={WEBSITE} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title || defaults.title} />
      <meta
        name="twitter:description"
        content={description || defaults.description}
      />
      <meta name="twitter:image:alt" content={title || defaults.title} />
      <meta name="twitter:image" content={defaults.imageUrl} />

      {/* google data type */}
      {ldJson && (
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      )}
    </Helmet>
  );
};

export default metaTags;
