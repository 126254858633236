import React from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

const withLogin = Component => {
  // return
  @inject("sessionStore")
  @observer
  class LoginHOC extends React.Component {
    render() {
      const { sessionStore } = this.props;
      const { authToken } = sessionStore;

      return authToken ? <div /> : <Component {...this.props} />;
    }
  }

  LoginHOC.wrappedComponent.propTypes = {
    sessionStore: PropTypes.shape({
      authToken: PropTypes.string
    }).isRequired
  };

  return LoginHOC;
};

export default withLogin;
