import React, { Component } from "react";
import { IconCircle } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./footer.module.scss";
import configs from "../../../configs/club-freelance-globals";
import runtimeVars from "../../../configs/runTimeVars";

class AboutWidget extends Component {
  openPage = provider => {
    window.open(configs.socialMedia[provider], "_blank");
  };

  render() {
    return (
      <div className={classes.widget}>
        <div>
          <div className={classes.logo}>
            <picture>
              <source
                type="images/webp"
                srcSet="/assets/images/cfLogoLight.webp"
              />
              <img
                src="/assets/images/cfLogoLight.png"
                alt="Club Freelance Logo"
              />
            </picture>
          </div>
          <div className={classes.description}>
            <span>
              <Trans>Mission pour Freelances</Trans>
            </span>
            {/* <span> */}
            {/*  <Trans>informatique et consultants IT</Trans> */}
            {/* </span> */}
          </div>
          <div className={classes.contactDetails}>
            <span>{configs.contactEmail}</span>
            <span>{configs.phoneNumber[runtimeVars.APP_LANG]}</span>
          </div>
          <div className={classes.social}>
            <IconCircle
              type="twitter"
              dimension={40}
              innerColor="#fff"
              iconWidth={20}
              iconHeight={16}
              onClick={() => this.openPage("twitter")}
            />
            <IconCircle
              type="linked-in"
              dimension={40}
              innerColor="#fff"
              iconWidth={17}
              iconHeight={16}
              onClick={() => this.openPage("linkedIn")}
            />
            <IconCircle
              type="facebook"
              dimension={40}
              innerColor="#fff"
              iconWidth={17}
              iconHeight={16}
              onClick={() => this.openPage("facebook")}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AboutWidget;
