import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import "../../styles.scss";

@inject("sessionStore", "appStore")
@observer
class ResumeGenerationDisallowed extends Component {
  displayDissalowedInterface = () => {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    if (
      currentLanguage === "fr" ||
      currentLanguage === " " ||
      currentLanguage === ""
    ) {
      return (
        <img
          src="/assets/images/resume_exemples/disallowedFrenchBlocking.png"
          style={{
            display: "block",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto"
          }}
          width="70%"
          alt="non autorisé"
        />
      );
    }
    return (
      <img
        src="/assets/images/resume_exemples/disallowedEnglishBlocking.png"
        style={{
          display: "block",
          marginBottom: "20px",
          marginLeft: "auto",
          marginRight: "auto"
        }}
        width="70%"
        alt="not allowed"
      />
    );
  };

  render() {
    return <div>{this.displayDissalowedInterface()}</div>;
  }
}

ResumeGenerationDisallowed.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeCv: PropTypes.func,
    isAuthenticatedByLinkedin: PropTypes.bool,
    _id: PropTypes.string,
    email: PropTypes.string,
    pictureUrl: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address: PropTypes.string,
    searchAddress: PropTypes.string,
    candidateWorkHistory: PropTypes.shape,
    cv: PropTypes.shape({
      name: PropTypes.string
    }),
    authToken: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    refreshLayout: PropTypes.func,
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(ResumeGenerationDisallowed);
