import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row } from "cf-neo-ui";
import { Query } from "react-apollo";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import * as PropTypes from "prop-types";
import classes from "./recommendedMissions.module.scss";
import { USER_RECOMMENDATION_QUERY } from "../../dashboard-missions/queries";
import Routes from "../../layout/routes";
import List from "./list";
import LoadingMissions from "../../../components/LoadingMissions/LoadingMissions";

class RecommendedMissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skip: 0
    };
  }

  fetchMore = () => {
    const { skip } = this.state;
    this.setState({ skip: skip + 8 });
  };

  render() {
    const { history } = this.props;
    const { i18n } = this.props;
    const { skip } = this.state;
    return (
      <div className={classes.recommendedMissionsSection}>
        <div className={classes.title}>
          <Trans>Offres recommandées</Trans>
        </div>
        <Query
          query={USER_RECOMMENDATION_QUERY}
          variables={{ skip }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className={classes.missionCards}>
                  <LoadingMissions
                    cardsCount={3}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    colClassName={classes.loadingMissionsCard}
                  />
                </div>
              );

            if (error)
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );

            const recommended = data.recommendedMissions;
            return (
              <div className={classes.missionCards}>
                <Row>
                  <List
                    recommended={recommended}
                    fetchMore={this.fetchMore}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={6}
                  />
                </Row>
              </div>
            );
          }}
        </Query>
        <div className={classes.allMissionsLink}>
          <span
            onClick={() =>
              history.push({
                pathname: Routes.LpbLayoutRoutes.Missions.path,
                search: "?active=recommendation"
              })
            }
          >
            <Trans>Voir toutes mes missions</Trans>
          </span>
        </div>
      </div>
    );
  }
}

RecommendedMissions.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(RecommendedMissions));
