import gql from "graphql-tag";

const CLIENT_ACTIONS_STATS_QUERY = gql`
  query ActionsStats($id: Int!, $dateStart: Float, $dateEnd: Float) {
    actionsStats(
      id: $id
      filter: { dateStart: $dateStart, dateEnd: $dateEnd }
    ) {
      vacanciesCount
      shortListedCount
      cvSentCount
      interviewsCount
      placementsCount
    }
  }
`;

export default CLIENT_ACTIONS_STATS_QUERY;
