import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { CountCard, CountCardSkeleton } from "cf-neo-ui";
import { t } from "@lingui/macro";
import { Query } from "react-apollo";
import SINGLE_OFFER_STATS_QUERY from "./query";
import classes from "./styles.module.scss";

class Index extends Component {
  loading = () => {
    return (
      <div className={classes.squares}>
        {[1, 2, 3, 4, 5].map(i => (
          <CountCardSkeleton key={i} className={classes.countCard} />
        ))}
      </div>
    );
  };

  render() {
    const { offerId, i18n } = this.props;
    return (
      <div className={classes.container}>
        <Query
          query={SINGLE_OFFER_STATS_QUERY}
          variables={{
            offerId
          }}
        >
          {({ loading, error, data }) => {
            if (error) return null;
            if (loading) return this.loading();
            const jobOrderStats = data.clientVacancy;
            return (
              <div className={classes.squares}>
                <CountCard
                  className={classes.countCard}
                  number={jobOrderStats.totalNotes}
                  text={i18n._(t`Appels`)}
                  info={i18n._(
                    t`Le nombre d’appels fait par nos Talent Community Specialist pour répondre à cet offres`
                  )}
                />
                <CountCard
                  className={classes.countCard}
                  number={jobOrderStats.totalShortListed}
                  text={i18n._(t`CV sélectionnés`)}
                  info={i18n._(
                    t`Le nombre de candidats pre-sélectionnés par nos Talent Community Specialist pour répondre à cet offres`
                  )}
                />
                <CountCard
                  className={classes.countCard}
                  number={jobOrderStats.totalCVSent}
                  text={i18n._(t`CV envoyés`)}
                  info={i18n._(
                    t`Le nombre de CV envoyés par nos responsables de comptes à vos équipes`
                  )}
                />
                <CountCard
                  className={classes.countCard}
                  number={jobOrderStats.totalInterviews}
                  text={i18n._(t`Entretiens`)}
                  info={i18n._(
                    t`Le nombre d’entretiens organisées avec vos équipes et les candidats envoyés`
                  )}
                />
                <CountCard
                  className={classes.countCard}
                  number={jobOrderStats.totalPlacements}
                  text={i18n._(t`Placements`)}
                  info={i18n._(
                    t`Le nombre de candidats envoyés par nos équipes, que vous avez recruté`
                  )}
                />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Index.propTypes = {
  offerId: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(Index);
