import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { Mutation } from "react-apollo";
import {
  Row,
  Col,
  FormGroup,
  Button,
  Validation,
  Textarea,
  Spinner,
  toaster
} from "cf-neo-ui";
import validate from "../../../utils/validators";

import "./styles.scss";
import { UPDATE_OPPORTUNITY_FROM_FORM } from "../mutations";
import { SINGLE_OPPORTUNITY_QUERY } from "../query";
import Routes from "../../layout/routes";

@inject("sessionStore", "appStore", "postANeedStore")
@observer
class OfferDescription extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      isRecruitmentContextValid: true,
      recruitmentContextValidationMessage: "",
      isJobDescriptionValid: true,
      jobDescriptionValidationMessage: "",
      saveCase: false
    };
    this.isFormValid = this.isFormValid.bind(this);
  }

  jobDescriptionChangeHandler = e => {
    const { postANeedStore } = this.props;
    postANeedStore.changeJobDescription(e.target.value);
    this.validateJobDescription(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  recruitmentContextChangeHandler = e => {
    const { postANeedStore } = this.props;
    postANeedStore.changeRecruitmentContext(e.target.value);
    this.validateRecruitmentContext(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  getMutationVariables = () => {
    const { postANeedStore } = this.props;
    const { _id, jobDescription, recruitmentContext } = postANeedStore;
    const input = {
      _id,
      description: jobDescription,
      context: recruitmentContext
    };
    return { input };
  };

  validateRecruitmentContext(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isRecruitmentContextValid: res.isValid,
      recruitmentContextValidationMessage: res.message
    });
    return res.isValid;
  }

  validateJobDescription(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isJobDescriptionValid: res.isValid,
      jobDescriptionValidationMessage: res.message
    });
    return res.isValid;
  }

  isFormValid() {
    const { postANeedStore } = this.props;
    const { recruitmentContext, jobDescription } = postANeedStore;
    let valid = true;
    if (!this.validateJobDescription(jobDescription)) valid = false;
    if (!this.validateRecruitmentContext(recruitmentContext)) valid = false;
    return valid;
  }

  updateOpportunityCompletedHandler() {
    const { i18n } = this.props;
    toaster.success({
      title: i18n._(t`Détails de l'offre`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: false });
  }

  render() {
    const {
      isRecruitmentContextValid,
      recruitmentContextValidationMessage,
      isJobDescriptionValid,
      jobDescriptionValidationMessage,
      saveCase
    } = this.state;
    const { postANeedStore, history } = this.props;
    const { LpbLayoutRoutes } = Routes;
    const { _id, recruitmentContext, jobDescription } = postANeedStore;

    return (
      <div className="profile my-information">
        <div className="formCard no-gutter with-padding">
          <Row>
            <Col lg={12} xl={12} md={12} sd={12} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="experienceDescription">
                  <Trans>Description de la mission</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Validation
                  errorMessage={jobDescriptionValidationMessage}
                  valid={isJobDescriptionValid}
                >
                  <Textarea
                    id="missionDescription"
                    size="xLarge"
                    className="form-input"
                    defaultValue=""
                    onEnterPressed={this.jobDescriptionChangeHandler}
                    onEscapePressed={this.jobDescriptionChangeHandler}
                    onKeyDown={this.jobDescriptionChangeHandler}
                    onChange={this.jobDescriptionChangeHandler}
                    value={jobDescription}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={12} md={12} sd={12} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="experienceDescription">
                  <Trans>Contexte de recrutement</Trans>
                  <span className="asterisc">*</span>
                </label>
                <Validation
                  errorMessage={recruitmentContextValidationMessage}
                  valid={isRecruitmentContextValid}
                >
                  <Textarea
                    id="recruitmentContext"
                    size="xLarge"
                    className="form-input"
                    defaultValue=""
                    onEnterPressed={this.recruitmentContextChangeHandler}
                    onEscapePressed={this.recruitmentContextChangeHandler}
                    onKeyDown={this.recruitmentContextChangeHandler}
                    onChange={this.recruitmentContextChangeHandler}
                    value={recruitmentContext}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <br />

          <div className="next-buttons">
            <Button
              variant="secondary"
              onClick={() => history.push(LpbLayoutRoutes.Offers.path)}
            >
              <Trans>Annuler</Trans>
            </Button>
            <Mutation
              mutation={UPDATE_OPPORTUNITY_FROM_FORM}
              variables={this.getMutationVariables()}
              refetchQueries={[
                {
                  query: SINGLE_OPPORTUNITY_QUERY,
                  variables: { id: _id }
                }
              ]}
              onCompleted={data => this.updateOpportunityCompletedHandler(data)}
              onError={errors => {
                errors.graphQLErrors.forEach(({ message, data }) => {
                  if (data && data.isCustomError) {
                    this.onErrorHandler(message);
                  }
                });
              }}
            >
              {(mutation, { loading }) => (
                <Button
                  disabled={loading || !saveCase}
                  onClick={() => {
                    if (this.isFormValid()) return mutation();
                    return null;
                  }}
                >
                  {loading ? (
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  ) : (
                    <Trans>Enregistrer</Trans>
                  )}
                </Button>
              )}
            </Mutation>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

OfferDescription.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  postANeedStore: PropTypes.shape({
    changeRecruitmentContext: PropTypes.func,
    changeJobDescription: PropTypes.func,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    recruitmentContext: PropTypes.string,
    jobDescription: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};
export default withI18n()(withRouter(OfferDescription));
