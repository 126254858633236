import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { H2 } from "cf-neo-ui";
import classes from "./MoreArticles.module.scss";
import SugVideo from "./suggestion-display/videos/SugDisplayVideos";
import SugArticle from "./suggestion-display/articles/SugDisplayArticles";
import BloggerSection from "../../../blogger-section/BloggerSection";

class MoreArticles extends Component {
  render() {
    const { blogger, currentBlog, relatedPosts } = this.props;
    return (
      <div className={classes.container}>
        <BloggerSection blogger={blogger} />
        <H2 className={classes.heading}>
          <Trans>
            D’autres {currentBlog.type === "video" ? "videos" : "articles"} qui
            pourraient vous interesser
          </Trans>
        </H2>
        {currentBlog.type === "video" ? (
          <SugVideo currentBlog={currentBlog} relatedPosts={relatedPosts} />
        ) : (
          <SugArticle currentBlog={currentBlog} relatedPosts={relatedPosts} />
        )}
      </div>
    );
  }
}

MoreArticles.propTypes = {
  blogger: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    avatar_url: PropTypes.string
  }),
  relatedPosts: PropTypes.arrayOf(PropTypes.string),
  currentBlog: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(["video", "book", "article"])
  }).isRequired
};
MoreArticles.defaultProps = {
  blogger: {
    name: "",
    description: "",
    avatar_url: ""
  },
  relatedPosts: []
};
export default MoreArticles;
