import { observable, action } from "mobx";

export default class SmatchStore {
  @observable keyWords;

  constructor(initialState = {}) {
    this.keyWords = initialState.keyWords || [];
  }

  @action
  changeKeyWords = keyWords => {
    this.keyWords = keyWords;
  };
}
