import { t } from "@lingui/macro";
import staticPagesMetaTags from "../../../configs/staticPagesMetaTags";

export const links1 = (i18n, currentLanguage) => {
  const metas = staticPagesMetaTags(i18n);
  let linksToBeReturned = [
    {
      url: "/missions",
      title: metas.missions.title,
      name: i18n._(t`Trouver une mission freelance`)
    },
    // {
    //   url: "https://club-freelance.com/blog,https://club-freelance.co.uk/blog",
    //   title: metas.blog.advicesConsultant.title,
    //   name: i18n._(t`Nos conseils pour les freelance`),
    //   external: true
    // },
    {
      url: "/blog/advices/consultant",
      title: metas.blog.advicesConsultant.title,
      name: i18n._(t`Nos conseils pour les freelance`)
    },
    {
      url: "/signup?type=consultant",
      title: metas.signUp.title,
      name: i18n._(t`Rejoindre le club`)
    },
    {
      url: "https://nos-partenaires.club-freelance.com/",
      title: metas.signUp.title,
      name: i18n._(t`Nos partenaires`),
      external: true
    },
    {
      url: "https://visit.club-freelance.com/nos-partenaires-portage",
      title: metas.signUp.title,
      name: i18n._(t`Le Portage salarial`),
      external: true
    }
  ];

  if (currentLanguage === "fr")
    linksToBeReturned.push({
      url:
        "https://nos-partenaires.club-freelance.com/,https://nos-partenaires.club-freelance.com/",
      //title: metas.signUp.title,
      name: i18n._(t`Nos partenaires`),
      external: true
    });

  return linksToBeReturned;
};

export const links2 = i18n => {
  const metas = staticPagesMetaTags(i18n);
  return [
    {
      url: "/signup?type=enterprise",
      title: metas.signUp.title,
      name: i18n._(t`Trouver un freelance`)
    },
    // {
    //   url: "https://club-freelance.com/blog,https://club-freelance.co.uk/blog",
    //   title: metas.blog.advicesEnterprise.title,
    //   name: i18n._(t`Nos conseils pour recruter en freelance`),
    //   external: true
    // },
    {
      url: "/blog/advices/enterprise",
      title: metas.blog.advicesEnterprise.title,
      name: i18n._(t`Nos conseils pour recruter en freelance`)
    },
    {
      url: "conditionalRedirect",
      title: i18n._(t`Déposer un projet`),
      name: i18n._(t`Déposer un projet`)
    }
  ];
};

export const links3 = i18n => {
  const metas = staticPagesMetaTags(i18n);
  return [
    {
      url: "/about",
      title: metas.about.title,
      name: i18n._(t`Qui sommes nous?`)
    },
    {
      url:
        "https://www.welcometothejungle.com/fr/companies/club-freelance,https://www.welcometothejungle.com/en/companies/club-freelance",
      title: metas.signUp.title,
      name: i18n._(t`Nous rejoindre`),
      external: true
    },
    {
      url: "/contact",
      title: metas.contact.title,
      name: i18n._(t`Nous contacter`)
    },
    {
      url: "/blog",
      title: metas.blog.main.title,
      name: i18n._(t`Le blog`)
    },
    {
      url: "/privacy-policy,/privacy-policy",
      title: i18n._(t`Confidentialité`),
      name: i18n._(t`Confidentialité`),
      external: true
    }
  ];
};
