import gql from "graphql-tag";

const SingleMissionQuery = gql`
  query SingleMissionQuery($id: ID!) {
    jobOrder(id: $id) {
      id
      title
      duration {
        months
        weeks
        days
      }
      startDate
      address {
        city
      }
      categories
      businessSectors
      customText1
      customTextBlock1
      customTextBlock2
      customTextBlock3
      customTextBlock4
      customTextBlock5
      correlatedCustomTextBlock1
      skills
      sourcers {
        id
        firstName
        phone
        occupation
        customText1
        customText2
        customText3
        email
        headShot
      }
      employmentType
      dateAdded
    }
  }
`;

export const USER_APPLICATIONS_QUERY = gql`
  query UserApplications {
    userApplications {
      jobOrderId
    }
  }
`;

export default SingleMissionQuery;
