const theme = {
  fontFamily: `'Lato', sans-serif`,
  defaultAvatar: "/defaultAvatar.png",
  colors: {
    mainColor: "#8d0417",
    mainColorGradient: "#d3354a",
    lightMainColor: "#c40b24",
    lightMainColorGradient: "#FA3249"
  }
};

export default theme;
