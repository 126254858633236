/* eslint-disable */ module.exports = {
  languageData: {
    plurals: function(n, ord) {
      if (ord) return n == 1 ? "one" : "other";
      return n >= 0 && n < 2 ? "one" : "other";
    }
  },
  messages: {
    '"Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin"':
      '"Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin"',
    "(where you are a candidate) clients for the purpose of introducing candidates to them and providing information during the recruitment process;":
      "(where you are a candidate) clients for the purpose of introducing candidates to them and providing information during the recruitment process;",
    "(where you are a client) candidates for the purpose of arranging interviews and engagements;":
      "(where you are a client) candidates for the purpose of arranging interviews and engagements;",
    ", mis \xE0 jour le": ", mis \xE0 jour le",
    "...ou deposer votre fiche de poste ici":
      "...ou d\xE9poser ma fiche de poste ici",
    "...ou simplement deposer votre CV ici": "...ou d\xE9poser mon CV ici",
    "1 min": "1 min",
    "2 \xE8me signataire": "2 \xE8me signataire",
    "30 jours": "30 jours",
    "<0>Back to website</0>": "<0>Back to Mindquest</0>",
    "<0>\xC0 partir de {0}</0>": function(a) {
      return ["<0>\xC0 partir de ", a("0"), "</0>"];
    },
    "A copy of your driving license and/or passport/identity card; Photograph;":
      "A copy of your driving license and/or passport/identity card; Photograph;",
    "A fin de faciliter les recherches pensez \xE0 indiquer dans votre fiche de poste:":
      "Afin de faciliter les recherches pensez \xE0 indiquer dans votre fiche de poste :",
    "A l\u2019\xE9tude": "\xC0 l\u2019\xE9tude",
    "A password policy is currently in place to ensure that the data stored on our systems is only accessible to authorised personnel. The passwords are changed on a regular basis. We regularly make backups of our data to ensure that it is readily available in the event of technical problems or disaster recovery.":
      "A password policy is currently in place to ensure that the data stored on our systems is only accessible to authorised personnel. The passwords are changed on a regular basis. We regularly make backups of our data to ensure that it is readily available in the event of technical problems or disaster recovery.",
    "Acceder \xE0 votre espace entreprise":
      "Acc\xE9der \xE0 votre espace entreprise",
    Accepter: "Accepter",
    "Account Managers locaux": "Account Managers locaux",
    Accueil: "Accueil",
    "Acc\xE9der \xE0 la ressource": "En savoir plus",
    "Acc\xE9der \xE0 mon espace client": "Acc\xE9der \xE0 mon espace client",
    "Acc\xE9der \xE0 mon espace perso": "Acc\xE9der \xE0 mon espace perso",
    "Acc\xE9der \xE0 mon espace personel":
      "Acc\xE9der \xE0 mon espace personel",
    "Activit\xE9 sur toutes les offres": "Activit\xE9 sur toutes les offres",
    "Activit\xE9 sur vos offres": "Activit\xE9 sur vos offres",
    "Actual and potential candidates:": "Actual and potential candidates:",
    Actualité: "Actualit\xE9",
    "Actualit\xE9s, conseils pratiques, d\xE9cryptages, et points de vue d\u2019experts pour freelances informatiques et d\xE9cideurs IT en qu\xEAte de r\xE9ussite.":
      "Actualit\xE9s, conseils pratiques, d\xE9cryptages, et points de vue d\u2019experts pour talents et d\xE9cideurs IT en qu\xEAte de r\xE9ussite.",
    Adresse: "Adresse",
    "Adresse email non valide": "Adresse email non valide",
    "Afin d'organiser un entretien avec le candidat, pourriez-vous nous donner des cr\xE9neaux de disponibilit\xE9?":
      "Afin d'organiser un entretien avec le candidat, pourriez-vous nous donner des cr\xE9neaux de disponibilit\xE9 ?",
    "Afin de mieux adapter nos recherches merci de nous indiquer les raisons de ce refus en commentaire?":
      "Afin de mieux adapter nos recherches, pourriez-vous nous indiquer les raisons de ce refus en commentaire ?",
    Ajouter: "Ajouter",
    "Ajouter Club Freelance \xE0 l\u2019\xE9cran d\u2019accueil":
      "Ajouter Club Freelance \xE0 l\u2019\xE9cran d\u2019accueil",
    "Ajouter le code postal permettra de mettre en avant les profils les plus proches":
      "Ajouter le code postal permettra de mettre en avant les profils les plus proches",
    "Ajouter les environnements": "Ajouter les environnements",
    "Ajouter mon CV": "Ajouter mon CV",
    "Ajouter quand m\xEAme": "Ajouter quand m\xEAme",
    "Ajouter un commentaire pour indiquer votre activit\xE9 au client":
      "Ajouter un commentaire pour indiquer votre activit\xE9 au client",
    "Ajouter une Certification": "Ajouter une Certification",
    "Ajouter une Formation": "Ajouter une Formation",
    "Ajouter une exp\xE9rience": "Ajouter une exp\xE9rience",
    "Ajouter une ligne": "Ajouter une ligne",
    "Ajouter vos Comp\xE9tences": "Ajouter vos Comp\xE9tences",
    "Ajouter vos soft skills": "Ajouter vos soft skills",
    "Ajouter votre/vos Zone(s) g\xE9ographique(s) souhait\xE9e(s)":
      "Ajouter votre/vos Zone(s) g\xE9ographique(s) souhait\xE9e(s)",
    "Ajoutez un nom de projet pour retrouver facilement les offres associ\xE9es":
      "Ajoutez un nom de projet pour retrouver facilement les offres associ\xE9es",
    "Ajoutez vos derni\xE8res exp\xE9riences pour nous aider \xE0 vous trouver un job qui vous correspond. N\u2019oubliez pas de renseigner vos r\xE9f\xE9rences pour un process plus rapide":
      "Ajoutez vos derni\xE8res exp\xE9riences pour nous aider \xE0 vous trouver une opportunit\xE9 qui vous correspond. N\u2019oubliez pas de renseigner vos r\xE9f\xE9rences pour un process plus rapide.",
    "Ajoutez votre": "Ajoutez votre",
    "Ajoutez votre {0}": function(a) {
      return ["Ajoutez votre ", a("0")];
    },
    Alerte: "Alerte",
    "Alerte !": "Alerte !",
    "Alerte Mission": "Alerte Mission",
    "Alerte Suppression": "Alerte Suppression",
    "Alerte cr\xE9\xE9e avec succ\xE8s": "Alerte cr\xE9\xE9e avec succ\xE8s",
    "All the endpoints of the company are protected with antivirus and firewalls.":
      "All the endpoints of the company are protected with antivirus and firewalls.",
    Allemand: "Allemand",
    "An insider's look at the tech industry through interviews with expert from all areas of it.":
      "An insider's look at the tech industry through interviews with expert from all areas of it.",
    "An insider's look at the tech industry through interviews with expert from all areas of it..":
      "An insider's look at the tech industry through interviews with expert from all areas of it..",
    "Analyse les tendances et \xE9volutions du monde des talents tech & IT.":
      "Analyse les tendances et \xE9volutions du monde des talents tech & IT.",
    Anglais: "Anglais",
    Annuler: "Annuler",
    "Annuler le filtrage par projet": "Annuler le filtrage par projet",
    Appels: "Appels",
    "Application de filtre": "Application de filtre",
    Appliquer: "Appliquer",
    "Applying for jobs through a job aggregator, which may then redirect you to the Club Freelance website;":
      "Applying for jobs through a job aggregator, which may then redirect you to the Club Freelance website;",
    "Applying for jobs through a job aggregator, which may then redirect you to the Mindquest website;":
      "Applying for jobs through a job aggregator, which may then redirect you to the Mindquest website;",
    "Apr\xE8s l\u2019importation, Vos donn\xE9es seront remplac\xE9es par les donn\xE9es LinkedIn. Voulez vous continuer?":
      "Apr\xE8s l\u2019importation, vos donn\xE9es seront remplac\xE9es par les donn\xE9es LinkedIn. Voulez vous continuer?",
    Arabe: "Arabe",
    Arabic: "Arabe",
    "As a recruitment business and recruitment agency we introduce candidates to clients for independent professional contracts. The exchange of personal data of our candidates and our client contacts is a fundamental, essential part of this process.":
      "As a recruitment business and recruitment agency we introduce candidates to clients for independent professional contracts. The exchange of personal data of our candidates and our client contacts is a fundamental, essential part of this process.",
    Astreintes: "Astreintes",
    "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9 en":
      "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9 en",
    "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9 en {plCountry}.": function(
      a
    ) {
      return [
        "Attention, le jour s\xE9l\xE9ctionn\xE9 est f\xE9ri\xE9 en ",
        a("plCountry"),
        "."
      ];
    },
    Au: "Au",
    "Aucun compte ne correspond \xE0 cet email":
      "Aucun compte ne correspond \xE0 cet email",
    "Aucun contact indiqu\xE9": "Aucun contact indiqu\xE9",
    "Aucun itin\xE9raire n\u2019a pu \xEAtre trouv\xE9 entre l\u2019origine et la destination.":
      "Aucun itin\xE9raire n\u2019a pu \xEAtre trouv\xE9 entre l\u2019origine et la destination",
    "Aucun pr\xE9paiements":
      "Facturation uniquement en cas de recrutement r\xE9ussi",
    "Aucun r\xE9sultat pour votre filtre.<0/>":
      "Aucun r\xE9sultat pour votre filtre.<0/>",
    "Aucun r\xE9sultat pour votre recherche. Essayez avec d\u2019autres mots-cl\xE9s.":
      "Aucun r\xE9sultat pour votre recherche. Essayez avec d\u2019autres mots-cl\xE9s.",
    "Aucune donn\xE9e \xE0 exporter": "Aucune donn\xE9e \xE0 exporter",
    "Aujourd\u2019hui": "Aujourd\u2019hui",
    Autoentrepreneu: "Autoentrepreneu",
    Autoentrepreneur: "Autoentrepreneur",
    Autre: "Autre",
    "Autres comp\xE9tences du candidat": "Autres comp\xE9tences du candidat",
    "Avant de soumettre votre Timesheet {0} au client, veuillez s'il vous plait indiquer en quelques lignes les actions effectu\xE9es ce mois.": function(
      a
    ) {
      return [
        "Avant de soumettre votre Timesheet ",
        a("0"),
        " au client, veuillez s'il vous plait indiquer en quelques lignes les actions effectu\xE9es ce mois."
      ];
    },
    Avantages: "Avantages",
    Avertissement: "Avertissement",
    "Bank account details your company;": "Bank account details your company;",
    Barcelone: "Barcelone",
    "Bas\xE9s au sein de notre Sourcing Hub \xE0 Barcelone, nos Talent Community Specialists sont en contact permanent avec les meilleurs profils tech et IT. Ils sont en mesure d\u2019identifier et d\u2019accompagner les meilleurs talents dans toute l\u2019Europe.":
      "Bas\xE9s au sein de notre Sourcing Hub \xE0 Barcelone, nos Talent Community Specialists sont en contact permanent avec les meilleurs profils tech et IT. Ils sont en mesure d\u2019identifier et d\u2019accompagner les meilleurs talents dans toute l\u2019Europe.",
    "Bas\xE9s localement et sp\xE9cialis\xE9s par secteur d\u2019activit\xE9, nos Key Account Managers accompagnent leurs entreprises clientes dans la recherche des meilleures talents pour leurs projets IT.":
      "Bas\xE9s localement, nos Key Account Managers accompagnent leurs entreprises clientes dans la recherche des meilleurs talents pour leurs projets IT.",
    "Be in the know": "Pour ne manquer aucun article",
    "Behind the Screens": "Behind the Screens",
    "Besoin d'un partenaire pour le recrutement de vos ressources IT ?":
      "Besoin d'accompagnement pour trouver les meilleurs consultants IT ?",
    'Bien que la tendance \xE9volue, le travail en r\xE9gie est toujours la norme dans de nombreuses entreprises. Choissiez "indifferent" pour ne manquer aucune opportunit\xE9.':
      'Bien que la tendance \xE9volue, le travail en r\xE9gie est toujours la norme dans de nombreuses entreprises. Choissiez "indiff\xE9rent" pour ne manquer aucune opportunit\xE9.',
    "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour confirmer votre inscription":
      "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour confirmer votre inscription",
    "Bienvenue au club, Un email vous a \xE9t\xE9 envoy\xE9 pour finaliser votre inscription et acc\xE9der \xE0 votre Dashboard":
      "Bienvenue au club, un email vous a \xE9t\xE9 envoy\xE9 pour finaliser votre inscription et acc\xE9der \xE0 votre Dashboard",
    "Bienvenue au club, Vous pouvez mainetenant acc\xE9der \xE0 votre Dashboard":
      "Bienvenue au club, Vous pouvez mainetenant acc\xE9der \xE0 votre Dashboard",
    "Bienvenue au club, Vous pouvez maintenant acc\xE9der \xE0 votre Dashboard":
      "Bienvenue au club, Vous pouvez maintenant acc\xE9der \xE0 votre Dashboard",
    "Bienvenue au club, un Key Account Manager va vous recontacter dans les plus brefs d\xE9lais pour faire le point sur vos recherches":
      "Bienvenue au club, un Key Account Manager va vous recontacter dans les plus brefs d\xE9lais pour faire le point sur vos recherches",
    "Blah Blah Blah Bloo Bloo Blah Bloo Bloo":
      "Blah Blah Blah Bloo Bloo Blah Bloo Bloo",
    Blog: "Blog",
    "Bon de commande": "Bon de commande",
    "Bon \xE0 savoir": "Bon \xE0 savoir",
    "Bouche \xE0 oreille": "Bouche \xE0 oreille",
    "Brief profil": "Brief profil",
    "Business Lounge": "Business Lounge",
    "B\xE9n\xE9ficiez d\u2019offres s\xE9lectionn\xE9es pour votre profil.":
      "B\xE9n\xE9ficiez d\u2019offres s\xE9lectionn\xE9es pour votre profil.",
    CDI: "CDI",
    "CDI / Freelance": "CDI / Freelance",
    "CF Admin peut d\xE9sormais valider les Timesheets du contrat {placementId}": function(
      a
    ) {
      return [
        "CF Admin peut d\xE9sormais valider les Timesheets du contrat ",
        a("placementId")
      ];
    },
    "CF Admin peut d\xE9sormais valider vos Timesheets":
      "CF Admin peut d\xE9sormais valider vos Timesheets",
    "CF Sourceur": "CF Sourceur",
    "CRM / ATS": "CRM / ATS",
    CV: "CV",
    "CV envoy\xE9": "CV envoy\xE9",
    "CV envoy\xE9s": "CV envoy\xE9s",
    "CV s\xE9lectionn\xE9s": "CV s\xE9lectionn\xE9s",
    Calendrier: "Calendrier",
    "Candidate Data": "Candidate Data",
    "Candidate and Client data": "Candidate and Client data",
    Candidates: "Candidates",
    Candidats: "Candidats",
    Candidature: "Candidature",
    "Candidature cr\xE9\xE9e avec succ\xE8s":
      "Candidature cr\xE9\xE9e avec succ\xE8s",
    Candidatures: "Candidatures",
    "Captain's Log": "Captain's Log",
    "Career History;": "Career History;",
    "Career Spa": "Career Spa",
    Cdi: "CDI",
    "Ce champ est requis": "Ce champ est requis",
    "Ce champ ne contient pas la m\xEAme valeur":
      "Ce champ ne contient pas la m\xEAme valeur",
    "Ce champs est requis": "Ce champ est requis",
    "Ce champs ne contient pas la m\xEAme valeur":
      "Ce champ ne contient pas la m\xEAme valeur",
    "Ce format de fichier n\u2019est pas pris en charge. Veuillez choisir l\u2019une des options suivantes :":
      "Ce format de fichier n\u2019est pas pris en charge. Veuillez choisir l\u2019une des options suivantes :",
    "Ce site utilise des cookies pour offrir une meilleure exp\xE9rience de navigation. D\xE9couvrez comment nous utilisons les cookies dans notre":
      "Ce site utilise des cookies pour offrir une meilleure exp\xE9rience de navigation. D\xE9couvrez comment nous utilisons les cookies dans notre",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t":
      "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1/>":
      "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1/>",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>":
      "Ce type de compte convient aux professionnels en mission ou aux candidats en recherche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>",
    "Ce type de compte convient aux professionnels en mission ou aux candidats en recherhche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>":
      "Ce type de compte convient aux professionnels en mission ou aux candidats en recherhche.<0/>Si vous souhaitez recruter ou que vous \xEAtes donneur d'ordre, cr\xE9ez plut\xF4t <1>un compte entreprise.</1>",
    "Certaines donn\xE9es n'ont pas \xE9t\xE9 enregistr\xE9es seront perdues. Etes-vous s\xFBr de vouloir passer \xE0 l\xE9tape suivante ?":
      "Certaines donn\xE9es n'ont pas \xE9t\xE9 enregistr\xE9es seront perdues. Etes-vous s\xFBr de vouloir passer \xE0 l\xE9tape suivante ?",
    Certifications: "Certifications",
    "Ces statistiques correspondent aux actions r\xE9alis\xE9es par nos \xE9quipes sur chaque offre ajout\xE9e sur la periode s\xE9lectionn\xE9e.":
      "Ces statistiques correspondent aux actions r\xE9alis\xE9es par nos \xE9quipes sur chaque offre ajout\xE9e sur la p\xE9riode s\xE9lectionn\xE9e.",
    "Ces statistiques correspondent \xE0 l\u2019ensemble des actions r\xE9alis\xE9es par nos \xE9quipes sur la p\xE9riode s\xE9lectionn\xE9e":
      "Ces statistiques correspondent \xE0 l\u2019ensemble des actions r\xE9alis\xE9es par nos \xE9quipes sur la p\xE9riode s\xE9lectionn\xE9e",
    "Cette date ne peut pas \xEAtre dans le future":
      "Cette date ne peut pas \xEAtre dans le future",
    "Cette offre est ferm\xE9e et la disponibilit\xE9 de ce profil n'est plus d'actualit\xE9":
      "Cette offre est ferm\xE9e et la disponibilit\xE9 de ce profil n'est plus d'actualit\xE9",
    "Champ requis": "Champ requis",
    "Champs requis": "Champ requis",
    "Changer votre mot de passe": "Changer votre mot de passe",
    "Chaque offre de mission est prise en charge par un bin\xF4me de deux experts, qui entretiennent des relations de long-terme avec entreprises et consultants":
      "Chaque offre de mission est prise en charge par un bin\xF4me de deux experts, qui entretiennent des relations de long-terme avec entreprises et consultants",
    Chargement: "Chargement",
    "Chercher des comp\xE9tences": "Chercher des comp\xE9tences",
    "Chercher des sp\xE9cialit\xE9s": "Chercher des m\xE9tiers",
    "Chercher une offre...": "Chercher une offre...",
    Chinese: "Chinois",
    Chinois: "Chinois",
    "Choisissez au moins un domaine": "Choisissez au moins un domaine",
    "Choisissez au moins un secteur d\u2019activit\xE9":
      "Choisissez au moins un secteur d\u2019activit\xE9",
    "Choisissez au moins une sp\xE9cialit\xE9":
      "Choisissez au moins un m\xE9tier",
    "Choisissez entre deux et dix comp\xE9tences":
      "Choisissez entre deux et dix comp\xE9tences",
    "Choisissez entre une et dix comp\xE9tences":
      "Choisissez entre une et dix comp\xE9tences",
    "Choisissez le mod\xE8le adapt\xE9 \xE0 votre situation":
      "Choisissez le mod\xE8le adapt\xE9 \xE0 votre situation",
    "Choissiez le mod\xE8le adapt\xE9 \xE0 votre situation":
      "Choissiez le mod\xE8le adapt\xE9 \xE0 votre situation",
    "Click here for whatever": "Click here for whatever",
    Client: "Client",
    "Client data": "Client data",
    Clients: "Clients",
    "Clients grands comptes qui nous font confiance":
      "Clients grands comptes qui nous font confiance",
    "Cliquez i\xE7i pr\xE9ciser le nombre d\u2019heures travaill\xE9s":
      "Cliquez ici  pour pr\xE9ciser le nombre d\u2019heures travaill\xE9es",
    'Cliquez sur le cadre "Mots-cl\xE9s indispensables" ou "Mots-cl\xE9s importants" puis s\xE9lectionnez les mots-cl\xE9s IT souhait\xE9s':
      'Cliquez sur le cadre "Mots-cl\xE9s indispensables" ou "Mots-cl\xE9s importants" puis s\xE9lectionnez les mots-cl\xE9s IT souhait\xE9s',
    "Club Freelance": "Club Freelance",
    "Club Freelance - La communaut\xE9 des Freelances Informatique":
      "Club Freelance - La communaut\xE9 des Freelances Informatique",
    "Club Freelance Iberica": "Club Freelance Iberica",
    "Club Freelance Insights | Conseils Pratiques pour D\xE9cideurs Informatiques":
      "Club Freelance Insights | Conseils Pratiques pour D\xE9cideurs Informatiques",
    "Club Freelance Insights | Conseils Pratiques pour Freelances Informatiques":
      "Club Freelance Insights | Conseils Pratiques pour Freelances Informatiques",
    "Club Freelance Insights | D\xE9cryptages des m\xE9tiers de l\u2019IT":
      "Club Freelance Insights | D\xE9cryptages des m\xE9tiers de l\u2019IT",
    "Club Freelance Insights | Le blog des talents IT":
      "Club Freelance Insights | Le blog des talents IT",
    "Club Freelance Insights | L\u2019actualit\xE9 des talents IT":
      "Club Freelance Insights | L\u2019actualit\xE9 des talents IT",
    "Club Freelance Insights | Rechercher un article":
      "Club Freelance Insights | Rechercher un article",
    "Club Freelance Insights | T\xE9moignages":
      "Club Freelance Insights | T\xE9moignages",
    "Club Freelance Limited": "Club Freelance Limited",
    "Club Freelance Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.":
      "Club Freelance Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.",
    "Club Freelance Limited is a staffing and sourcing company specialized in IT.":
      "Club Freelance Limited is a staffing and sourcing company specialized in IT.",
    "Club Freelance SAS": "Club Freelance SAS",
    "Club Freelance est une soci\xE9t\xE9 de placement de consultants ind\xE9pendants dans la technologie et l\u2019informatique.":
      "Club Freelance est une soci\xE9t\xE9 de placement de consultants ind\xE9pendants dans la technologie et l\u2019informatique.",
    "Club Freelance | Cr\xE9er un compte":
      "Club Freelance | Cr\xE9er un compte",
    "Club Freelance | Dashboard": "Club Freelance | Dashboard",
    "Club Freelance | Entourez-vous des meilleurs Talents Informatiques":
      "Club Freelance | Entourez-vous des meilleurs Talents Informatiques",
    "Club Freelance | Expert du sourcing IT digitalis\xE9":
      "Club Freelance | Expert du sourcing IT digitalis\xE9",
    "Club Freelance | Ma formation": "Club Freelance | Ma formation",
    "Club Freelance | Ma recherche": "Club Freelance | Ma recherche",
    "Club Freelance | Mes Coordonn\xE9es":
      "Club Freelance | Mes Coordonn\xE9es",
    "Club Freelance | Mon CV": "Club Freelance | Mon CV",
    "Club Freelance | Mon exp\xE9rience": "Club Freelance | Mon exp\xE9rience",
    "Club Freelance | Mon m\xE9tier": "Club Freelance | Mon m\xE9tier",
    "Club Freelance | Nous contacter": "Club Freelance | Nous contacter",
    "Club Freelance | Partenaire des entreprises & freelances IT":
      "Club Freelance | Partenaire des entreprises & freelances IT",
    "Club Freelance | Recherche des candidats":
      "Club Freelance | Recherche des candidats",
    "Club Freelance | Se connecter": "Club Freelance | Se connecter",
    "Club Freelance | Votre prochaine mission Freelance Informatique":
      "Club Freelance | Votre prochaine mission Freelance Informatique",
    "Club Freelance's Mission Control Center  \u2014 Interesting reads":
      "Club Freelance's Mission Control Center  \u2014 Interesting reads",
    "Club Freelance's Mission Control Center \u2014 Behind the screen":
      "Club Freelance's Mission Control Center \u2014 Behind the screen",
    "Club Freelance's Mission Control Center \u2014 Business Lounge":
      "Club Freelance's Mission Control Center \u2014 Business Lounge",
    "Club Freelance's Mission Control Center \u2014 Captain's Log":
      "Club Freelance's Mission Control Center \u2014 Captain's Log",
    "Club Freelance's Mission Control Center \u2014 Career Tips & Tricks":
      "Club Freelance's Mission Control Center \u2014 Career Tips & Tricks",
    "Club Freelance's Mission Control Center \u2014 Horizon 2050":
      "Club Freelance's Mission Control Center \u2014 Horizon 2050",
    "Club Freelance's Mission Control Center \u2014 IT's Who is Who":
      "Club Freelance's Mission Control Center \u2014 IT's Who is Who",
    "Club Freelance's Mission Control Center \u2014 MCC: IT's resource hub":
      "Club Freelance's Mission Control Center \u2014 MCC: IT's resource hub",
    "Club Freelance's Mission Control Center \u2014 Newsstand":
      "Club Freelance's Mission Control Center \u2014 Newsstand",
    "Club Freelance's Mission Control Center\u2014 Search all articles":
      "Club Freelance's Mission Control Center\u2014 Search all articles",
    "Club Freelance,": "Club Freelance,",
    "Club Freelance, Freelance informatique, consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP":
      "Club Freelance, Freelance informatique, consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks":
      "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand":
      "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand",
    "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT":
      "Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT",
    "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge":
      "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge",
    "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements":
      "Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements",
    "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech":
      "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech",
    "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads":
      "Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads",
    "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi":
      "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi",
    "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search":
      "Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search",
    "Club Freelance, talent management, freelance informatique, trouver un freelance":
      "Club Freelance, talent management, freelance informatique, trouver un freelance",
    Club_Freelance: "Mindquest",
    "Co-fondateurs": "Co-fondateurs",
    "Code postal*": "Code postal*",
    "Collaborateurs \xE0 temps plein": "Collaborateurs \xE0 temps plein",
    "Coller votre code ici": "Coller votre code ici",
    "Collez ici le contenu de votre fiche de poste...":
      "Collez ici le contenu de votre fiche de poste...",
    "Collez l\u2019URL de votre profil Linkedin pour pr\xE9remplir votre profil":
      "Collez l\u2019URL de votre profil Linkedin pour pr\xE9remplir votre profil",
    "Comment avez-vous entendu parlez de nous":
      "Comment avez-vous entendu parlez de nous",
    "Comment \xE7a marche ?": "Comment \xE7a marche ?",
    Commentaire: "Commentaire",
    Commentaires: "Commentaires",
    "Company information available on local company registrars;":
      "Company information available on local company registrars;",
    "Compl\xE9ter mon profil": "Compl\xE9ter mon profil",
    "Compte non verifi\xE9": "Compte non verifi\xE9",
    "Compte-Rendu G\xE9n\xE9r\xE9": "Compte-Rendu G\xE9n\xE9r\xE9",
    "Compte-rendu G\xE9n\xE9r\xE9": "Compte-rendu G\xE9n\xE9r\xE9",
    "Compte-rendus": "Compte-rendus",
    "Comptes rendus": "Comptes rendus",
    Compétence: "Comp\xE9tence",
    Compétences: "Comp\xE9tences",
    "Comp\xE9tences de la recherche": "Comp\xE9tences de la recherche",
    Confidentialité: "Confidentialit\xE9",
    "Confiez-nous votre prochain recrutement ?":
      "A la recherche d'un consultant ?",
    "Confirmation mot de passe": "Confirmation mot de passe",
    "Confirmed (3 - 7)": "Confirmed (3 - 7)",
    "Confirmed (3-7)": "Confirmed (3-7)",
    Confirmer: "Confirmer",
    "Confirmer votre mot de passe": "Confirmer votre mot de passe",
    "Confirmez le nouveau mot de passe": "Confirmez le nouveau mot de passe",
    "Confirmez votre mot de passe": "Confirmez votre mot de passe",
    Confirmé: "Confirm\xE9",
    Congrats: "Congrats",
    "Connectez-vous \xE0 votre profil Club Freelance":
      "Connectez-vous \xE0 votre profil Club Freelance",
    "Connectez-vous \xE0 votre profil Mindquest":
      "Connectez-vous \xE0 votre profil Mindquest",
    Connexion: "Connexion",
    "Conseils pratiques": "Conseils pratiques",
    "Conseils pratiques Consultant": "Conseils pratiques Consultant",
    "Conseils pratiques Entreprise": "Conseils pratiques Entreprise",
    Consultant: "Consultant",
    "Consultant AWS Cloud Devops": "Consultant AWS Cloud Devops",
    "Consultants IT": "Consultants IT",
    "Consulter le Timesheet": "Consulter le Timesheet",
    "Consulter le compte-rendu": "Consulter le compte-rendu",
    "Consulter le timesheet": "Consulter le timesheet",
    "Consultez vos emails pour finaliser l\u2019inscription":
      "Veuillez consulter vos emails pour finaliser l\u2019inscription",
    Contact: "Contact",
    "Contact de r\xE9f\xE9rences": "Contact de r\xE9f\xE9rences",
    "Contact me if you are interested !": "Contact me if you are interested !",
    "Contact me to join our community!": "Contact me to join our community!",
    "Contact us": "Contact us",
    Contacter: "Contacter",
    "Contacter Nous": "Contactez-nous",
    "Contactez nous": "Contactez-nous",
    "Contenu orient\xE9 aux candidats":
      "Contenu \xE0 destination des talents IT",
    "Contenu orient\xE9 aux entreprises":
      "Contenu \xE0 destination des entreprises",
    Context: "Contexte",
    "Contexte de recrutement": "Contexte de recrutement",
    "Contexte projet": "Contexte projet",
    Continuer: "Continuer",
    "Continuer sans accepter": "Continuer sans accepter",
    "Contrats reconduits par nos clients":
      "Contrats reconduits par nos clients",
    "Cookies policy": "Cookies policy",
    Coopter: "Coopter",
    "Copier et coller l'URL de votre profil LinkedIn":
      "Copier et coller l'URL de votre profil LinkedIn",
    Critères: "Crit\xE8res",
    "Cr\xE9ation de Club Freelance": "Cr\xE9ation de Club Freelance",
    "Cr\xE9ation de Mindquest": "Cr\xE9ation de Mindquest",
    Créer: "Cr\xE9er",
    "Cr\xE9er mon espace personnel Talent":
      "Cr\xE9er mon espace personnel Talent",
    "Cr\xE9er un compte": "Cr\xE9er un compte",
    "Cr\xE9er un mot de passe": "Cr\xE9er un mot de passe",
    "Cr\xE9er un nouveau mot de passe": "Cr\xE9er un nouveau mot de passe",
    "Cr\xE9er une alerte sur ces crit\xE8res":
      "Cr\xE9er une alerte sur ces crit\xE8res",
    "Cr\xE9er une offre": "Cr\xE9er une offre",
    "Cr\xE9ez votre profil Mindquest en seulement 3 minutes":
      "Vous cr\xE9ez votre profil Mindquest en seulement 3 minutes",
    "Currently all the data that we collect from you may/will be transferred to, and stored at, a destination inside the European Economic Area (\u201DEEA\u201D). It may be transferred to third parties outside of the EEA for the purpose of our recruitment services. It may also be processed by staff operating outside the EEA who work for one of our suppliers. This includes staff engaged in, among other things, recruitment services and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing.":
      "Currently all the data that we collect from you may/will be transferred to, and stored at, a destination inside the European Economic Area (\u201DEEA\u201D). It may be transferred to third parties outside of the EEA for the purpose of our recruitment services. It may also be processed by staff operating outside the EEA who work for one of our suppliers. This includes staff engaged in, among other things, recruitment services and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing.",
    "Daily rate Range;": "Daily rate Range;",
    "Dans un rayon de": "Dans un rayon de",
    "Dans un rayon max de XX km": "Dans un rayon max de XX km",
    Dashboard: "Dashboard",
    "Dashboard Enterprise": "Dashboard Enterprise",
    "Data Science & BI": "Data Science & BI",
    Date: "Date de d\xE9marrage",
    "Date de d\xE9but": "Date de d\xE9but",
    "Date de fin": "Date de fin",
    "Date of birth;": "Date of birth;",
    "Demande de modification": "Demande de modification",
    "Depending on the relevant circumstances and applicable local laws and requirements, we may collect information to enable us to offer you potential employment opportunities which are tailored to your circumstances and your interests. This information may include:":
      "Depending on the relevant circumstances and applicable local laws and requirements, we may collect information to enable us to offer you potential employment opportunities which are tailored to your circumstances and your interests. This information may include:",
    "Des hommes et des algorithmes pour un matching parfait entre <0>entreprises</0> et <1>freelances IT</1>":
      "Des hommes et des algorithmes pour un matching parfait entre <0>entreprises</0> et <1>freelances IT</1>",
    "Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances en informatique. Nous accompagnons les meilleurs talents et les entreprises les plus innovantes.":
      "Donnez de l\u2019\xE9lan \xE0 vos <0>projets IT</0> et \xE0 votre <1>carri\xE8re</1> dans l\u2019informatique ou la technologie",
    "Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances informatique.":
      "Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances informatique.",
    "Des services": "Des services",
    "Description de l'offre": "Description de l'offre",
    "Description de la mission": "Description de la mission",
    "Design Graphique & UX/UI": "Design Graphique & UX/UI",
    "Devenez apporteur d\u2019affaire et faites-vous r\xE9mun\xE9rer":
      "Devenez apporteur d\u2019affaire et faites-vous r\xE9mun\xE9rer",
    "Disponibile \xE0 partir de:": "Disponible \xE0 partir de :",
    Disponibilité: "Disponibilit\xE9",
    "Disponibilit\xE9 \xE0 confirmer": "Disponibilit\xE9 \xE0 confirmer",
    Disponible: "Disponible",
    "Disponible \xE0 partir de:": "Disponible \xE0 partir de:",
    Distance: "Distance",
    "Dites-nous ce que nous pouvons faire pour vous.":
      "Dites-nous ce que nous pouvons faire pour vous.",
    Documents: "Documents",
    "Doit contenir au moins 8 caract\xE8res":
      "Doit contenir au moins 8 caract\xE8res",
    "Doit contenir au moins un caract\xE8re sp\xE9cial !*#@...":
      "Doit contenir au moins un caract\xE8re sp\xE9cial !*#@...",
    "Doit contenir au moins un chiffre": "Doit contenir au moins un chiffre",
    "Doit contenir au moins une majuscule":
      "Doit contenir au moins une majuscule",
    "Doit contenir au moins une minuscule":
      "Doit contenir au moins une minuscule",
    "Domaine d\u2019expertise": "Domaine d\u2019expertise",
    "Domaines d'expertise": "Domaines d'expertise",
    "Domaines d\u2019expertise": "Domaines d\u2019expertise",
    Du: "Du",
    Dutch: "N\xE9erlandais",
    "D\xE9cideurs IT": "D\xE9cideurs IT",
    "D\xE9couvrez Club Freelance": "D\xE9couvrez Club Freelance",
    "D\xE9couvrez Mission Control Center : tous nos conseils pour les professionnels de l'IT":
      "D\xE9couvrez Mission Control Center : tous nos conseils pour les professionnels de l'IT",
    "D\xE9couvrez les m\xE9tiers de l\u2019informatique et du digital d\xE9crypt\xE9s":
      "D\xE9couvrez nos analyses sur l'\xE9volution du march\xE9 des comp\xE9tences IT",
    "D\xE9couvrez les tendances et \xE9volution des m\xE9tiers de l\u2019informatique & du digital \xE0 l\u2019heure du future of work. Fiches m\xE9tiers d\xE9taill\xE9es et analyses.":
      "D\xE9couvrez les tendances et \xE9volution des m\xE9tiers de l\u2019informatique & du digital \xE0 l\u2019heure du future of work. Fiches m\xE9tiers d\xE9taill\xE9es et analyses.",
    "D\xE9couvrez nos mod\xE8les de facture, de CV et template de note de frais":
      "D\xE9couvrez nos mod\xE8les de facture, de CV et template de note de frais",
    "D\xE9couvrez nos ressources pour les entreprises":
      "D\xE9couvrez nos ressources pour les entreprises",
    "D\xE9couvrez notre service de recrutement freelance":
      "D\xE9couvrez notre service de recrutement IT en freelance",
    "D\xE9couvrez notre \xE9quipe": "D\xE9couvrez notre \xE9quipe",
    "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour recruter les meilleurs talents et travailler efficacement avec des freelances IT.":
      "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour recruter les meilleurs talents et travailler efficacement avec des Talents IT.",
    "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour r\xE9ussir dans votre activit\xE9 de freelance IT et d\xE9crocher les meilleures missions.":
      "D\xE9couvrez tous nos conseils et points de vue d\u2019experts pour r\xE9ussir dans votre activit\xE9 de freelance IT et d\xE9crocher les meilleures missions.",
    "D\xE9couvrez tous nos conseils pour travailler efficacement avec les Freelances IT":
      "D\xE9couvrez tous nos conseils pour travailler efficacement avec des Talents IT",
    "D\xE9couvrez tous nos conseils pour une activit\xE9 de Freelance r\xE9ussie":
      "D\xE9couvrez tous nos conseils pour d\xE9velopper votre activit\xE9 de freelance IT",
    "D\xE9couvrez toutes les offres de votre Talent Community Speciallist":
      "D\xE9couvrez toutes les offres de votre Talent Community Speciallist",
    Découvrir: "D\xE9couvrir",
    "D\xE9couvrir Club Freelance": "D\xE9couvrir Club Freelance",
    "D\xE9couvrir Mindquest": "D\xE9couvrir Mindquest",
    "D\xE9couvrir les donn\xE9es": "D\xE9couvrir les donn\xE9es",
    "D\xE9couvrir les ressources": "Voir les ressources",
    "D\xE9couvrir nos offres": "D\xE9couvrir nos offres",
    "D\xE9couvrir nos partenaires": "D\xE9couvrir nos partenaires",
    Décryptage: "D\xE9cryptage",
    Décryptages: "D\xE9cryptages",
    "D\xE9lai moyen pour recevoir une shortlist de candidats qualifi\xE9s":
      "D\xE9lai moyen pour recevoir une shortlist de candidats qualifi\xE9s",
    Démarrage: "D\xE9marrage",
    "D\xE9ploiement international": "D\xE9ploiement international",
    "D\xE9poser un projet": "D\xE9poser un projet",
    "D\xE9scription de la mission": "Description du poste",
    Désolé: "D\xE9sol\xE9",
    "D\xE9sol\xE9, aucun r\xE9sultat trouv\xE9.":
      "D\xE9sol\xE9, aucun r\xE9sultat trouv\xE9.",
    "D\xE9sol\xE9, pas de candidats pour ces crit\xE8res":
      "D\xE9sol\xE9, pas de candidats pour ces crit\xE8res",
    "D\xE9sol\xE9, pas de missions pour ces crit\xE8res":
      "D\xE9sol\xE9, pas de missions pour ces crit\xE8res",
    "D\xE9tails de l'offre": "D\xE9tails de l'offre",
    "D\xE9veloppement Logiciels & Syst\xE8mes d'Exploitation":
      "D\xE9veloppement Logiciels & Syst\xE8mes d'Exploitation",
    "D\xE9veloppement Mobile": "D\xE9veloppement Mobile",
    "D\xE9veloppement Web": "D\xE9veloppement Web",
    "D\u2019autres ressources qui pourraient vous interesser":
      "D\u2019autres ressources qui pourraient vous int\xE9resser",
    "D\u2019autres {0} qui pourraient vous interesser": function(a) {
      return ["D\u2019autres ", a("0"), " qui pourraient vous int\xE9resser"];
    },
    "E-mail": "E-mail",
    "ERP / EDI": "ERP / EDI",
    EURL: "EURL",
    Editer: "Editer",
    "Education & Qualifications;": "Education & Qualifications;",
    Email: "Email",
    "Email Envoy\xE9": "Email envoy\xE9",
    "Email envoy\xE9 avec succ\xE9es": "Email envoy\xE9 avec succ\xE9es",
    "Email invalide": "Email invalide",
    "Embark on a journey to discover what lies ahead for IT and science. Short sci-fi stories posted every month for you to engage and especulate on the future of tech.":
      "Embark on a journey to discover what lies ahead for IT and science. Short sci-fi stories posted every month for you to engage and especulate on the future of tech.",
    "En attente": "En attente",
    "En chargeant votre CV, vos informations seront pr\xE9remplies. Un CV au format Word offrira de meilleurs r\xE9sultats":
      "En chargeant votre CV, vos informations seront pr\xE9remplies. Un CV au format Word offrira de meilleurs r\xE9sultats",
    "En cliquant sur le bouton ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login":
      "En cliquant sur le bouton ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login",
    "En cliquant sur le button ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login":
      "En cliquant sur le boutton ci-dessous, vous serez d\xE9connect\xE9 et redirig\xE9 vers la page de login",
    "En construction": "En construction",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils refus\xE9s, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "En d\xE9pla\xE7ant ce candidat dans la colonne profils refus\xE9s, vous notifierez l'\xE9quipe Club Freelance de votre retour.",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils refus\xE9s, vous notifierez l'\xE9quipe Mindquest de votre retour.":
      "En d\xE9pla\xE7ant ce candidat dans la colonne profils refus\xE9s, vous notifierez l'\xE9quipe Mindquest de votre retour.",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils \xE0 rencontrer, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "En d\xE9pla\xE7ant ce candidat dans la colonne profils \xE0 rencontrer, vous notifierez l'\xE9quipe Club Freelance de votre retour.",
    "En d\xE9pla\xE7ant ce candidat dans la colonne profils \xE0 rencontrer, vous notifierez l'\xE9quipe Mindquest de votre retour.":
      "En d\xE9pla\xE7ant ce candidat dans la colonne profils \xE0 rencontrer, vous notifierez l'\xE9quipe Mindquest de votre retour.",
    "En d\xE9pla\xE7ant ce candidat dans la colonne proposition, vous notifierez l'\xE9quipe Club Freelance de votre retour.":
      "En d\xE9pla\xE7ant ce candidat dans la colonne proposition, vous notifierez l'\xE9quipe Club Freelance de votre retour.",
    "En d\xE9pla\xE7ant ce candidat dans la colonne proposition, vous notifierez l'\xE9quipe Mindquest de votre retour.":
      "En d\xE9pla\xE7ant ce candidat dans la colonne proposition, vous notifierez l'\xE9quipe Mindquest de votre retour.",
    "En rejetant le timesheet, celui-ci sera renvoy\xE9 au consultant pour modification. Merci d'indiquer en commentaire la raison de ce rejet de la fa\xE7on la plus pr\xE9cise possible.":
      "En rejetant le timesheet, celui-ci sera renvoy\xE9 au consultant pour modification. Merci d'indiquer en commentaire la raison de ce rejet de la fa\xE7on la plus pr\xE9cise possible.",
    "En savoir plus": "En savoir plus",
    "En transport en commun": "En transport en commun",
    "En voiture": "En voiture",
    "Encore quelques instants...": "Encore quelques instants...",
    "Encryption prevents data from being easily accessible and recoverable in the event of theft or computer misuse. Our websites are protected by an SSL certificate.":
      "Encryption prevents data from being easily accessible and recoverable in the event of theft or computer misuse. Our websites are protected by an SSL certificate.",
    "Encryption technology is in use to protect the integrity of the personal data we process.":
      "Encryption technology is in use to protect the integrity of the personal data we process.",
    English: "Anglais",
    "Enlever de favoris": "Enlever des favoris",
    "Enregistrement de la feuille de temps":
      "Enregistrement de la feuille de temps",
    "Enregistrement effectu\xE9 avec succ\xE8s":
      "Enregistrement effectu\xE9 avec succ\xE8s",
    Enregistrer: "Enregistrer",
    "Enregistrer en brouillon": "Enregistrer en brouillon",
    "Enregistrer en favori": "Enregistrer en favori",
    "Enregistrer en favoris": "Enregistrer en favoris",
    "Enter your email address": "Votre email",
    "Entering a competition through a social media channel such as Facebook or Twitter.":
      "Entering a competition through a social media channel such as Facebook or Twitter.",
    "Entering your details on the Club Freelance website or registering an account with us;":
      "Entering your details on the Club Freelance website or registering an account with us;",
    "Entering your details on the Mindquest website or registering an account with us;":
      "Entering your details on the Mindquest website or registering an account with us;",
    "Entering your personal details into a Club Freelance microsite; or":
      "Entering your personal details into a Club Freelance microsite; or",
    "Entering your personal details into a Mindquest microsite; or":
      "Entering your personal details into a Mindquest microsite; or",
    Entreprise: "Entreprise",
    "Entreprise ?": "Entreprise ?",
    Entreprises: "Nos r\xE9f\xE9rences clients",
    "Entrer mon email": "Entrer mon email",
    "Entrer un email valid": "Entrez un email valide",
    "Entrer un mot de passe": "Entrez un mot de passe",
    "Entrer votre email": "Entrez votre email",
    "Entrer votre mot de passe": "Entrez votre mot de passe",
    Entretien: "Entretien",
    Entretiens: "Entretiens",
    "Entrez ici vos mots-cl\xE9s IT": "Entrez ici vos mots-cl\xE9s IT",
    "Environements technique": "Environements technique",
    "Environnement technique": "Environnement technique",
    Envoyer: "Envoyer",
    Erreur: "Erreur",
    "Erreur c\xF4t\xE9 de serveur. Essayez plus tard":
      "Erreur c\xF4t\xE9 de serveur. Essayez plus tard",
    "Erreur de cr\xE9ation de besoin":
      "Erreur : votre besoin n'a pas \xE9t\xE9 cr\xE9\xE9, veuillez r\xE9essayer",
    "Erreur de cr\xE9ation de candidature":
      "Erreur : votre candidature n'a pas \xE9t\xE9 cr\xE9e veuillez r\xE9essayer",
    "Erreur de cr\xE9ation d\u2019alerte":
      "Erreur de cr\xE9ation d\u2019alerte",
    "Erreur non identifi\xE9e": "Erreur non identifi\xE9e",
    "Erreur!": "Erreur !",
    Espagnol: "Espagnol",
    "Essayez la d\xE9tection automatique":
      "Essayez la d\xE9tection automatique",
    "Et remplir mon profil \xE0 la main": "Et remplir mon profil \xE0 la main",
    "Et trouvez les meilleurs consultants en informatique pour vos projets":
      "Et mobilisez les meilleures ressources pour acc\xE9l\xE9rer sur vos projets",
    Etablissement: "Etablissement",
    "Etape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues":
      "Etape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues",
    "Etape suivante": "\xC9tape suivante",
    "Ever wondered what it is that your colleagues in other areas of IT actually do?":
      "Ever wondered what it is that your colleagues in other areas of IT actually do?",
    "Ever wondered what it is that your colleagues in other areas of IT actually do? Curious about exploring new career paths in tech? Impove you awareness of the industy.":
      "Ever wondered what it is that your colleagues in other areas of IT actually do? Curious about exploring new career paths in tech? Impove you awareness of the industy.",
    "Evolutions des Jobs SAP": "Evolution des Jobs SAP",
    "Ex : Architecte Java, Consultant SAP":
      "Ex : Architecte Java, Consultant SAP",
    "Ex:Architecte Java, Consultant SAP":
      "Ex : Architecte Java, Consultant SAP",
    Experiences: "Experiences",
    Expert: "Expert",
    "Expert (10+)": "Expert (10+)",
    Expertise: "Expertise",
    "Exporter en CSV": "Exporter en CSV",
    Expérience: "Exp\xE9rience",
    Expériences: "Exp\xE9riences",
    "Extra information that our clients may tell us about you / Performance information Extra information that you choose to tell us;":
      "Extra information that our clients may tell us about you / Performance information Extra information that you choose to tell us;",
    "Extra information that your referees choose to tell us about you. Please note that the above list of categories of personal data we may collect is not exhaustive.":
      "Extra information that your referees choose to tell us about you. Please note that the above list of categories of personal data we may collect is not exhaustive.",
    "Extra, extra! IT and tech news from the past week, every week. From Cloud and IoT to SAP's ERP and all things Microsoft, these are news stories you should keep in mind.":
      "Extra, extra! IT and tech news from the past week, every week. From Cloud and IoT to SAP's ERP and all things Microsoft, these are news stories you should keep in mind.",
    "FREELANCE ?": "Freelance ?",
    Favoris: "Favoris",
    "Fiches m\xE9tiers": "Fiches m\xE9tiers",
    "Fichier g\xE9n\xE9r\xE9 avec succ\xE8s":
      "Fichier g\xE9n\xE9r\xE9 avec succ\xE8s",
    "Filtrer par projet": "Filtrer par projet",
    "Filtres S\xE9lectionn\xE9s :": "Filtres S\xE9lectionn\xE9s :",
    "Filtres Vide": "Filtres Vide",
    Filtré: "Filtr\xE9",
    "Find freelancer": "Trouver un talent",
    "Find the advice and resources you're looking for at the Mission Control Center, your one-stop shop for IT talent management and career advice.":
      "Find the advice and resources you're looking for at the Mission Control Center, your one-stop shop for IT talent management and career advice.",
    "For the purpose of the GDPR, the data controller is, Club Freelance Limited, 100 Drummond Road SE 16 4DG London UK.":
      "For the purpose of the GDPR, the data controller is, Club Freelance Limited, 100 Drummond Road SE 16 4DG London UK.",
    "For the purpose of the GDPR, the data controller is, Mindquest Limited, 100 Drummond Road SE 16 4DG London UK.":
      "For the purpose of the GDPR, the data controller is, Mindquest Limited, 100 Drummond Road SE 16 4DG London UK.",
    "Format d\u2019image invalide. Formats recommand\xE9s : .jpeg .png":
      "Format d\u2019image invalide. Formats recommand\xE9s : .jpeg .png",
    "Format non valide": "Format non valide",
    "Formation et Certifications": "Formation et Certifications",
    Français: "Fran\xE7ais",
    Freelance: "Freelance",
    "Freelance / Salari\xE9": "Freelance / Salari\xE9",
    "Freelance informatique, consultant IT, mission freelance, mission sap, mission d\xE9veloppement, mission data":
      "Freelance informatique, consultant IT, mission freelance, mission sap, mission d\xE9veloppement, mission data",
    "Freelance/Contract": "Freelance/Contract",
    FreelanceType: "Freelance",
    Freelances: "Freelances",
    French: "Fran\xE7ais",
    "F\xE9licitations!": "F\xE9licitations !",
    "F\xE9licitations!! Tous les champs obligatoires de votre profil sont remplis, vous pouvez maintenant g\xE9n\xE9rer votre CV Mindquest.":
      "F\xE9licitations!! Tous les champs obligatoires de votre profil sont remplis, vous pouvez maintenant g\xE9n\xE9rer votre CV Mindquest.",
    "F\xE9licitations!! Votre profil est rempli \xE0 {0}%, vous pouvez maintenant g\xE9n\xE9rer votre CV Mindquest.": function(
      a
    ) {
      return [
        "F\xE9licitations!! Votre profil est rempli \xE0 ",
        a("0"),
        "%, vous pouvez maintenant g\xE9n\xE9rer votre CV Mindquest."
      ];
    },
    "F\xE9licitations, votre profil est compl\xE9t\xE9 \xE0 100% ! D\xE9couvrez d\xE8s maintenant nos offres recommand\xE9es pour votre profil.":
      "F\xE9licitations, votre profil est compl\xE9t\xE9 \xE0 100% ! D\xE9couvrez d\xE8s maintenant nos offres recommand\xE9es pour votre profil.",
    German: "Allemand",
    "Gestion de projet & Coaching Agile": "Gestion de projet & Coaching Agile",
    "Gouvernance Informatique": "Gouvernance Informatique",
    "Grand Groupe (500+)": "Grand Groupe (500+)",
    "Grande entreprise (101-150)": "Grande entreprise (101-150)",
    "Grande entreprise (101-500)": "Grande entreprise (101-500)",
    Greek: "Greque",
    Greque: "Greque",
    "Gr\xE2ce \xE0 notre \xE9quipe d\u2019experts, nous recueillons quotidiennement les insights des entreprises et des candidats sur le march\xE9 des comp\xE9tences IT":
      "Gr\xE2ce \xE0 notre \xE9quipe d\u2019experts, nous recueillons quotidiennement les insights des entreprises et des candidats sur le march\xE9 des comp\xE9tences IT",
    "G\xE9nerer votre CV": "G\xE9nerer votre CV",
    "G\xE9n\xE9rez d\xE8s maintenant votre CV Club Freelance et diffusez le gr\xE2ce au lien partageable !":
      "G\xE9n\xE9rez d\xE8s maintenant votre CV Club Freelance et diffusez le gr\xE2ce au lien partageable !",
    "G\xE9n\xE9rez d\xE8s maintenant votre CV Mindquest et diffusez le gr\xE2ce au lien partageable !":
      "G\xE9n\xE9rez d\xE8s maintenant votre CV Mindquest et diffusez le gr\xE2ce au lien partageable !",
    "G\xE9rez vos candidatures et feuilles de temps depuis votre espace perso.":
      "G\xE9rez vos alertes et vos candidatures depuis votre espace perso.",
    "G\xE9rez vos candidatures et feuilles de temps depuis votre espace personel.":
      "G\xE9rez vos alertes et vos candidatures depuis votre espace perso.",
    "G\xE9rez vos documents administratifs":
      "G\xE9rez vos documents administratifs",
    "G\xE9rez vos missions et vos contrats depuis votre espace client.":
      "G\xE9rez vos missions et vos contrats depuis votre espace client.",
    "Heures & Astreintes": "Heures & Astreintes",
    "Heures (taux normal)": "Heures (taux normal)",
    "Heures suppl\xE9mentaires": "Heures suppl\xE9mentaires",
    Hindi: "Hindi",
    Historique: "Historique",
    Home: "Home",
    "Horizon 2050": "Horizon 2050",
    "Hors Informatique": "Hors Informatique",
    "How can we help?": "Envie de rejoindre Mindquest ?",
    "How can you access, amend, or take back the personal information that you have given us and what are your rights?":
      "How can you access, amend, or take back the personal information that you have given us and what are your rights?",
    "How do we collect your personal data?":
      "How do we collect your personal data?",
    "How do we store and transfer your data internationally?":
      "How do we store and transfer your data internationally?",
    "How do we use your personal data?": "How do we use your personal data?",
    "How long do we keep your personal data for?":
      "How long do we keep your personal data for?",
    "How to contact us about your personal information":
      "How to contact us about your personal information",
    "However, while these measures provide security against unauthorised access, loss, theft or manipulation from third parties, the internet is not a fully secure domain. For this reason, Club Freelance cannot guarantee the security or integrity of any personal information which is transferred from you or to you via email/ the Internet.":
      "However, while these measures provide security against unauthorised access, loss, theft or manipulation from third parties, the internet is not a fully secure domain. For this reason, Club Freelance cannot guarantee the security or integrity of any personal information which is transferred from you or to you via email/ the Internet.",
    "I'm looking for Jobs": "Je cherche des missions",
    "I'm looking for Talent": "Je cherche des freelances",
    "INVERIFIED ACCOUNT": "INVERIFIED ACCOUNT",
    "IP address National Insurance number or country equivalent;":
      "IP address National Insurance number or country equivalent;",
    "IT and tech news of the week, every week. These are stories to keep in mind.":
      "IT and tech news of the week, every week. These are stories to keep in mind.",
    "IT career advice at zero price. Get the contract or job you always wanted.":
      "IT career advice at zero price. Get the contract or job you always wanted.",
    "IT career advice at zero price. Valuable resources to help you navigate professional growth and get that contract or job you always wanted.":
      "IT career advice at zero price. Valuable resources to help you navigate professional growth and get that contract or job you always wanted.",
    "IT's Who is Who": "IT's Who is Who",
    "Identifiez des nouveaux talents pour votre entreprise.":
      "Identifiez de nouveaux talents pour votre entreprise.",
    "If a Club Freelance entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;":
      "If a Club Freelance entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;",
    "If a Mindquest entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;":
      "If a Mindquest entity or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets;",
    "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Club Freelance, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.":
      "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Club Freelance, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.",
    "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Mindquest, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.":
      "If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of Mindquest, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.",
    "If you believe, for any reason, that your Personal Data has been breached while in Club Freelance\u2019s care, custody or control please email Club Freelance immediately at contact@club-freelance.com.":
      "If you believe, for any reason, that your Personal Data has been breached while in Club Freelance\u2019s care, custody or control please email Club Freelance immediately at contact@club-freelance.com.",
    "If you believe, for any reason, that your Personal Data has been breached while in Mindquest\u2019s care, custody or control please email Mindquest immediately at contact@club-freelance.com.":
      "If you believe, for any reason, that your Personal Data has been breached while in Mindquest\u2019s care, custody or control please email Mindquest immediately at contact@club-freelance.com.",
    "If you fail to provide personal data":
      "If you fail to provide personal data",
    "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Club Freelance at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.":
      "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Club Freelance at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.",
    "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Mindquest at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.":
      "If you have any questions about this privacy notice, including any requests to exercise your legal rights please contact please contact Mindquest at contact@club-freelance.com or 0203 693 7476 or you can write to us at 100 Drummond Road SE 16 4DG London UK.",
    "If you were referred to us through an (Recruitment process Outsourcing) RPO or an Managed Services providers MSP supplier, they may share personal information about you with us.":
      "If you were referred to us through an (Recruitment process Outsourcing) RPO or an Managed Services providers MSP supplier, they may share personal information about you with us.",
    "If you wish, you have the right in certain circumstances to receive your personal data in a structured, commonly used and machine-readable format and have the right to transmit the data to another controller without hindrance from us.":
      "If you wish, you have the right in certain circumstances to receive your personal data in a structured, commonly used and machine-readable format and have the right to transmit the data to another controller without hindrance from us.",
    "If you \u2019like\u2019 our page on Facebook or \u2019follow\u2019 us on Twitter or LinkedIn, we will receive your personal information from those sites; and":
      "If you \u2019like\u2019 our page on Facebook or \u2019follow\u2019 us on Twitter or LinkedIn, we will receive your personal information from those sites; and",
    "Il n\u2019y a pas de projets pour les filtrer":
      "Il n\u2019y a pas de projets pour les filtrer",
    "Il n\u2019y a pas de sourcer avec cet Id":
      "Il n\u2019y a pas de Talent Community Specialist avec cet Id",
    "Il n\u2019y a pas de talents qualifi\xE9s par nos \xE9quipes pour le moment.":
      "Il n\u2019y a pas de talents qualifi\xE9s par nos \xE9quipes pour le moment.",
    "Il n\u2019y a pas des missions disponible a ce moment pour ce sourceur":
      "Il n\u2019y a pas de mission ouverte pour ce Talent Community Specialist",
    "Il n\u2019y a pas des missions similaires":
      "Il n\u2019y a pas de missions similaires",
    "Il n\u2019y a pas des posts pour le moment dans cette section":
      "Il n\u2019y a pas de posts pour le moment dans cette section",
    "Il n\u2019y a pas des ressources disponibles pour le moment":
      "Il n\u2019y a pas de ressource disponible pour le moment",
    "Il n\u2019y a pas encore de retour": "Il n\u2019y a pas encore de retour",
    "Il y'a pas encore des articles pour cette cat\xE9gorie.":
      "Il y'a pas encore d'article pour cette cat\xE9gorie.",
    "Ils ont r\xE9alis\xE9s des projets avec nos freelances":
      "Ces entreprises ont acc\xE9l\xE9r\xE9 sur leurs projets IT gr\xE2ce \xE0 nos freelances",
    "Ils ont r\xE9alis\xE9s des projets avec nos freelances.":
      "Ces entreprises ont acc\xE9l\xE9r\xE9 sur leurs projets IT gr\xE2ce \xE0 nos freelances",
    Importer: "Importer",
    "Importer mon CV": "Importer mon CV",
    "Importer mon fichier": "Importer mon fichier",
    "Importer votre profil LinkedIn": "Importer votre profil LinkedIn",
    "Importer votre profil LinkedIn<0><1/><2>Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci</2><3/></0>":
      "Importer votre profil LinkedIn<0><1/><2>Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci</2><3/></0>",
    "Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci":
      "Importez les donn\xE9es de votre profil Linkedin depuis le lien public de celui-ci",
    "Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)":
      "Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)",
    "Impossible de r\xE9cup\xE9rer vos informations":
      "Impossible de r\xE9cup\xE9rer vos informations",
    "In order to support our candidates\u2019 career aspirations and our clients\u2019 resourcing needs we require a database of candidate and client personal data containing historical information as well as current resourcing requirements.":
      "In order to support our candidates\u2019 career aspirations and our clients\u2019 resourcing needs we require a database of candidate and client personal data containing historical information as well as current resourcing requirements.",
    "In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.":
      "In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.",
    "In some circumstances you can ask us to delete your data: see Request erasure below for further information.":
      "In some circumstances you can ask us to delete your data: see Request erasure below for further information.",
    "In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;":
      "In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;",
    Indifferent: "Indifferent",
    Indifférent: "Indiff\xE9rent",
    "Indiquez des informations de contexte comme la phase du projet, la composition de l\u2019\xE9quipe ou l\u2019organisation interne...":
      "Indiquez des informations de contexte comme la phase du projet, la composition de l\u2019\xE9quipe ou l\u2019organisation interne...",
    "Ind\xE9pendants : tout savoir sur le pr\xE9l\xE8vement forfaitaire lib\xE9ratoire":
      "Ind\xE9pendants : tout savoir sur le pr\xE9l\xE8vement forfaitaire lib\xE9ratoire",
    "Informations personnelles": "Informations personnelles",
    "Informations sur le client": "Informations sur le client",
    "Informations sur le signataire": "Informations sur le signataire",
    "Infrastructure et Cloud": "Infrastructure et Cloud",
    "Inscription compl\xE8te": "Inscription compl\xE8te",
    "Inscription en ligne": "Inscription en ligne",
    "Inscription express": "Inscription express",
    "Inscrivez-vous et recevez tous les articles":
      "Inscrivez-vous et recevez tous nos nouveaux articles",
    "Insights pour professionels IT et entreprises":
      "Insights pour professionels IT et entreprises",
    "Interesting Reads": "Interesting Reads",
    Intitulé: "Intitul\xE9 de poste",
    Introduction: "Introduction",
    Intéressé: "Int\xE9ress\xE9",
    "IoT / AI / Blockchain": "IoT / AI / Blockchain",
    Italian: "Italien",
    Italien: "Italien",
    "J'ai compris": "J'ai compris",
    Japanese: "Japonais",
    Japonais: "Japonais",
    "Je cherche un consultant IT": "Je cherche un consultant IT",
    "Je cherche une mission": "Je cherche une mission",
    "Je confirme n'avoir effectu\xE9 aucun jour pour ce mois":
      "Je confirme n'avoir effectu\xE9 aucun jour pour ce mois",
    "Je m\u2019abonne": "Je m\u2019abonne",
    "Je poste un besoin": "Je poste un besoin",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par":
      "Je pr\xE9f\xE8re \xEAtre contact\xE9 par",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par :":
      "Je pr\xE9f\xE8re \xEAtre contact\xE9 par :",
    "Je pr\xE9f\xE8re \xEAtre contact\xE9 par:":
      "Je pr\xE9f\xE8re \xEAtre contact\xE9 par:",
    "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Club Freelance":
      "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Club Freelance",
    "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Club freelance":
      "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Club freelance",
    "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Mindquest Talent":
      "Je souhaite recevoir les actualit\xE9s, les nouveaux produits et services de Mindquest Talent",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Club Freelance (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Club Freelance (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Club Freelance (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Club Freelance (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations ainsi que les avantages communnaut\xE9s de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je souhaite recevoir les derni\xE8res informations, offres ainsi que les avantages de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)":
      "Je souhaite recevoir les derni\xE8res informations, offres ainsi que les avantages de Mindquest Talent (webinaires, \xE9v\xE9nements, blog, r\xE9ductions, etc.)",
    "Je suis ESN": "Mon entreprise est une ESN / SSII",
    "Je suis un Talent": "Je suis un Talent",
    "Je suis un freelance": "Je suis un freelance",
    "Je suis une Entreprise": "Je suis une Entreprise",
    "Je suis une entreprise": "Je suis une entreprise",
    "Jour f\xE9ri\xE9 s\xE9lectionn\xE9": "Jour f\xE9ri\xE9 s\xE9lectionn\xE9",
    Junior: "Junior",
    "Junior (0 - 3)": "Junior (0 - 3)",
    "Junior (0-3)": "Junior (0-3)",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s importants peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Jusqu\u2019\xE0 10 mots-cl\xE9s importants peuvent \xEAtre s\xE9lectionn\xE9s.",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s indispensables peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Jusqu\u2019\xE0 10 mots-cl\xE9s indispensables peuvent \xEAtre s\xE9lectionn\xE9s.",
    "Jusqu\u2019\xE0 10 mots-cl\xE9s secondaires peuvent \xEAtre s\xE9lectionn\xE9s.":
      "Jusqu\u2019\xE0 10 mots-cl\xE9s secondaires peuvent \xEAtre s\xE9lectionn\xE9s.",
    "J\u2019accepte <0>la politique de confidentialit\xE9s </0> Club Freelance":
      "J\u2019accepte <0>la politique de confidentialit\xE9s </0> Club Freelance",
    "J\u2019accepte <0>la politique de confidentialit\xE9s </0> Mindquest":
      "J\u2019accepte <0>la politique de confidentialit\xE9 </0> Mindquest",
    "J\u2019accepte la": "J\u2019accepte la",
    "J\u2019occupe actuellement ce poste":
      "J\u2019occupe actuellement ce poste",
    Kiosk: "Kiosk",
    "La date de d\xE9but doit \xEAtre toujours ant\xE9rieur \xE0 la date de fin":
      "La date de d\xE9but doit \xEAtre toujours ant\xE9rieur \xE0 la date de fin",
    "La date de fin doit \xEAtre toujours ult\xE9rieur \xE0 la date de d\xE9but":
      "La date de fin doit \xEAtre toujours ult\xE9rieur \xE0 la date de d\xE9but",
    "La garantie de paiement \xE0": "Un coaching tout au long des entretiens",
    "La garantie de paiement \xE0 30 jours":
      "La garantie de paiement \xE0 30 jours",
    "La liste des mots non pris en compte":
      "La liste des mots non pris en compte",
    "La localisation": "La localisation",
    "La mission se situe \xE0 {0} de cette adresse en {1} selon Google Maps": function(
      a
    ) {
      return [
        "La mission se situe \xE0 ",
        a("0"),
        " de cette adresse en ",
        a("1"),
        " selon Google Maps"
      ];
    },
    "La taille de votre fichier d\xE9passe":
      "La taille de votre fichier d\xE9passe",
    "La taille du fichier est trop petite, veuillez s\xE9lectionner un fichier de taille sup\xE9rieur \xE0":
      "La taille du fichier est trop petite, veuillez s\xE9lectionner un fichier de taille sup\xE9rieur \xE0",
    "Lancement de Mindquest": "Lancement de Mindquest",
    "Lancer le matching": "Lancer le matching",
    "Languages;": "Languages;",
    "Langues Parl\xE9es": "Langues Parl\xE9es",
    "Langues parl\xE9es": "Langues parl\xE9es",
    "Le Portage salarial": "Le Portage salarial",
    "Le Timesheet du mois de {m}/{y} pour le contrat {pID} attend votre validation": function(
      a
    ) {
      return [
        "Le Timesheet du mois de ",
        a("m"),
        "/",
        a("y"),
        " pour le contrat ",
        a("pID"),
        " attend votre validation"
      ];
    },
    "Le Timesheet {month}/{year} de contrat numero: {placementID} est ouvert au dashboard de votre consultant": function(
      a
    ) {
      return [
        "Le Timesheet ",
        a("month"),
        "/",
        a("year"),
        " de contrat numero: ",
        a("placementID"),
        " est ouvert au dashboard de votre consultant"
      ];
    },
    "Le Timesheet {m}/{y} de contrat numero: {pID} est ouvert au dashboard de votre consultant": function(
      a
    ) {
      return [
        "Le Timesheet ",
        a("m"),
        "/",
        a("y"),
        " de contrat numero: ",
        a("pID"),
        " est ouvert au dashboard de votre consultant"
      ];
    },
    "Le blog": "Le blog",
    "Le budget / package": "Le budget / package salarial",
    "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs.":
      "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs.",
    "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs. Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin. Merci.":
      "Le consultant sera qualifi\xE9 par notre \xE9quipe de sourceurs. Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin. Merci.",
    "Le contexte du besoin": "Le contexte",
    "Le mois de {0} {year} est termin\xE9, n'oubliez pas d'envoyer votre Timesheet": function(
      a
    ) {
      return [
        "Le mois de ",
        a("0"),
        " ",
        a("year"),
        " est termin\xE9, n'oubliez pas d'envoyer votre Timesheet"
      ];
    },
    "Le nombre d'heures doit \xEAtre entre 0 et 24":
      "Le nombre d'heures doit \xEAtre entre 0 et 24",
    "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes":
      "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes",
    "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes sur la p\xE9riode s\xE9lection\xE9e":
      "Le nombre de CV envoy\xE9s par nos responsables de comptes \xE0 vos \xE9quipes sur la p\xE9riode s\xE9lection\xE9e",
    "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9":
      "Le nombre de candidats envoy\xE9s par nos \xE9quipes que vous avez recrut\xE9s",
    "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9 sur la p\xE9riode s\xE9lectionn\xE9e":
      "Le nombre de candidats envoy\xE9s par nos \xE9quipes, que vous avez recrut\xE9s sur la p\xE9riode s\xE9lectionn\xE9e",
    "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 cet offres":
      "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 cette offre",
    "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 vos offres, sur la p\xE9riode s\xE9lection\xE9e":
      "Le nombre de candidats pre-s\xE9lectionn\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 vos offres, sur la p\xE9riode s\xE9lectionn\xE9e",
    "Le nombre de minutes doit \xEAtre compris entre 0 et 59":
      "Le nombre de minutes doit \xEAtre compris entre 0 et 59",
    "Le nombre d\u2019appels fait par nos Talent Community Specialist pour r\xE9pondre \xE0 cet offres":
      "Le nombre d\u2019appels r\xE9alis\xE9s par nos Talent Community Specialist pour r\xE9pondre \xE0 cette offre",
    "Le nombre d\u2019entretiens organis\xE9es avec vos \xE9quipes et les candidats envoy\xE9s":
      "Le nombre d\u2019entretiens organis\xE9s avec vos \xE9quipes et les candidats envoy\xE9s",
    "Le nombre d\u2019entretiens organis\xE9es avec vos \xE9quipes et les candidats envoy\xE9s, sur la p\xE9riode s\xE9lectionn\xE9e":
      "Le nombre d\u2019entretiens organis\xE9s avec vos \xE9quipes et les candidats envoy\xE9s, sur la p\xE9riode s\xE9lectionn\xE9e",
    "Le profil recherch\xE9 (comp\xE9tences, s\xE9niorit\xE9, certifications...)":
      "Le profil recherch\xE9 (comp\xE9tences, s\xE9niorit\xE9, certifications...)",
    "Le serveur ne r\xE9pond pas": "Le serveur ne r\xE9pond pas",
    "Le type de contrat (CDI /Freelance)":
      "Le type de contrat (CDI /Freelance)",
    "Leaving a hard copy CV at a Club Freelance recruitment event, job fair or office;":
      "Leaving a hard copy CV at a Club Freelance recruitment event, job fair or office;",
    "Leaving a hard copy CV at a Mindquest recruitment event, job fair or office;":
      "Leaving a hard copy CV at a Mindquest recruitment event, job fair or office;",
    "Les dates de d\xE9marrages et de fin contrat": "La date de d\xE9marrage",
    "Les mission \xE0 r\xE9aliser": "Les missions \xE0 r\xE9aliser",
    "Les {0} articles les plus lus cette semaine": function(a) {
      return ["Les ", a("0"), " articles les plus lus cette semaine"];
    },
    "Les \xE9l\xE9ments de contexte comme la phase du projet, la composition des \xE9quipes, ou encore l\u2019organisation interne nous aident mieux comprendre vos recherches":
      "Les \xE9l\xE9ments de contexte comme les types de projets, la composition des \xE9quipes, ou encore les valeurs de l\u2019entreprise nous aident \xE0 mieux comprendre vos recherches",
    "Lien non valide": "Lien non valide",
    "Lieu de travail": "Lieu de travail",
    "Limited company information, their representative\u2019s information and payment and financial information and certificates included but not limited to certificate of incorporation, certificate of professional insurance;":
      "Limited company information, their representative\u2019s information and payment and financial information and certificates included but not limited to certificate of incorporation, certificate of professional insurance;",
    "Lire l\u2019article": "Lire l\u2019article",
    Localisation: "Localisation",
    "Localisations strat\xE9giques": "Localisations strat\xE9giques",
    "Location of employment or workplace;":
      "Location of employment or workplace;",
    Logout: "Se d\xE9connecter",
    Londres: "Londres",
    "Longueur non valide": "Longueur non valide",
    "L\u2019ensemble des offres ajout\xE9s dans notre syst\xE8me pour votre entreprise, sur la p\xE9riode s\xE9lectionn\xE9e":
      "L\u2019ensemble des offres ajout\xE9es dans notre syst\xE8me pour votre entreprise, sur la p\xE9riode s\xE9lectionn\xE9e",
    "L\u2019exp\xE9rience Club Freelance racont\xE9e par les Freelances informatiques et d\xE9cideurs IT":
      "L\u2019exp\xE9rience Club Freelance racont\xE9e par les Freelances informatiques et d\xE9cideurs IT",
    "L\u2019exp\xE9rience Mindquest racont\xE9e par les Freelances informatiques et d\xE9cideurs IT":
      "L\u2019exp\xE9rience Mindquest (Mindquest) racont\xE9e par les talents et d\xE9cideurs IT et les meilleurs t\xE9moignages",
    "MCC is a one-stop shop for IT career and talent acquisition advice, tech news, and in-depth analysis of industry trends. A guide for professional and business success.":
      "MCC is a one-stop shop for IT career and talent acquisition advice, tech news, and in-depth analysis of industry trends. A guide for professional and business success.",
    "Ma Certification": "Ma Certification",
    "Ma Formation": "Ma Formation",
    "Ma formation": "Ma formation",
    "Ma pr\xE9sentation": "Ma pr\xE9sentation",
    "Ma recherche": "Ma recherche",
    "Marketing activities": "Marketing activities",
    "Maximisez vos chances en indiquant un intitul\xE9 clair et identifiable":
      "Maximisez vos chances en indiquant un intitul\xE9 clair et identifiable",
    "Maximisez vos changes en indiquant un intitul\xE9 clair et identifiable":
      "Maximisez vos changes en indiquant un intitul\xE9 clair et identifiable",
    Merci: "Merci",
    "Merci d'indiquer votre activit\xE9": "Merci d'indiquer votre activit\xE9",
    "Merci de confirmer que vous n'avez pas travaill\xE9 ce mois":
      "Merci de confirmer que vous n'avez pas travaill\xE9 ce mois",
    "Merci de consulter votre email": "Merci de consulter votre bo\xEEte mail",
    "Merci de v\xE9rifier les champs erron\xE9s":
      "Merci de v\xE9rifier les champs erron\xE9s",
    "Merci de v\xE9rifier vos informations":
      "Merci de v\xE9rifier vos informations",
    "Merci!": "Merci!",
    "Merci, c\u2019est tout bon !": "Merci, c\u2019est tout bon !",
    "Merci, nos \xE9quipes vont \xE9tudier votre demande et revenir vers vous dans les plus brefs d\xE9lais.":
      "Merci, nos \xE9quipes vont \xE9tudier votre demande et revenir vers vous dans les plus brefs d\xE9lais.",
    "Mes Certifications": "Mes Certifications",
    "Mes Coordonnn\xE9es": "Mes Coordonnn\xE9es",
    "Mes Coordonn\xE9es": "Mes Coordonn\xE9es",
    "Mes Formations": "Mes Formations",
    "Mes Softskills": "Mes Softskills",
    "Mes alertes": "Mes alertes",
    "Mes candidatures": "Mes candidatures",
    "Mes coordonn\xE9es": "Mes coordonn\xE9es",
    "Mes informations": "Mes Coordonn\xE9es",
    "Mes offres archiv\xE9es": "Mes offres archiv\xE9es",
    "Mes offres en cours": "Mes offres en cours",
    "Mes talents": "Mes talents",
    Message: "Message",
    "Message trop court,plus d\xE9tails sera utile pour nous":
      "Merci d'indiquer plus d'informations",
    "Mettre en favori": "Mettre en favori",
    Mindquest: "Club Freelance",
    "Mindquest - La communaut\xE9 des Freelances Informatique":
      "Mindquest - La communaut\xE9 des Talents IT",
    "Mindquest Iberica": "Mindquest Iberica",
    "Mindquest Insights | Conseils Pratiques pour D\xE9cideurs Informatiques":
      "Mindquest Insights | Conseils Pratiques pour D\xE9cideurs Informatiques",
    "Mindquest Insights | Conseils Pratiques pour Freelances Informatiques":
      "Mindquest Insights | Conseils Pratiques pour Talents IT",
    "Mindquest Insights | D\xE9cryptages des m\xE9tiers de l\u2019IT":
      "Mindquest Insights | D\xE9cryptage des m\xE9tiers de l\u2019IT",
    "Mindquest Insights | Le blog des talents IT":
      "Mindquest Insights | Le blog des talents IT",
    "Mindquest Insights | L\u2019actualit\xE9 des talents IT":
      "Mindquest Insights | L\u2019actualit\xE9 des talents IT",
    "Mindquest Insights | Rechercher un article":
      "Mindquest Insights | Rechercher un article",
    "Mindquest Insights | T\xE9moignages":
      "Mindquest Insights | T\xE9moignages",
    "Mindquest Limited": "Mindquest Limited",
    "Mindquest Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.":
      "Mindquest Limited (company number 9050953) and its affiliates respect your privacy and are committed to protecting the privacy and security of your personal information under applicable data privacy laws.",
    "Mindquest Limited is a staffing and sourcing company specialized in IT.":
      "Mindquest is a brand owned by Mindquest Limited, a staffing and sourcing company specialized in IT.",
    "Mindquest SAS": "Mindquest SAS",
    "Mindquest est une soci\xE9t\xE9 de placement de consultants ind\xE9pendants dans la technologie et l\u2019informatique.":
      "Mindquest est le service de recrutement IT en CDI et pr\xE9-embauche, lanc\xE9 par l'\xE9quipe Mindquest, sp\xE9cialistes du placement de consultants ind\xE9pendants dans l'IT. ",
    "Mindquest | Cr\xE9er un compte": "Mindquest | Cr\xE9er un compte",
    "Mindquest | Entourez-vous des meilleurs Talents Informatiques":
      "Mindquest | Entourez-vous des meilleurs Talents Informatiques",
    "Mindquest | Expert du sourcing IT digitalis\xE9":
      "Mindquest | Expert du sourcing IT digitalis\xE9",
    "Mindquest | Favoris": "Mindquest | Favoris",
    "Mindquest | Mes alertes": "Mindquest | Mes alertes",
    "Mindquest | Mes candidatures": "Mindquest | Mes candidatures",
    "Mindquest | Mon profil": "Mindquest | Mon profil",
    "Mindquest | Nous contacter": "Mindquest | Nous contacter",
    "Mindquest | Offres recommand\xE9es": "Mindquest | Offres recommand\xE9es",
    "Mindquest | Partenaire des entreprises & freelances IT":
      "Mindquest | Partenaire des entreprises & talent IT",
    "Mindquest | Se connecter": "Mindquest | Se connecter",
    "Mindquest | Votre prochaine mission Freelance Informatique":
      "Mindquest | Votre prochaine mission Freelance Informatique",
    "Mindquest's Mission Control Center  \u2014 Interesting reads":
      "Mindquest's Mission Control Center  \u2014 Interesting reads",
    "Mindquest's Mission Control Center \u2014 Behind the screen":
      "Mindquest's Mission Control Center \u2014 Behind the screen",
    "Mindquest's Mission Control Center \u2014 Business Lounge":
      "Mindquest's Mission Control Center \u2014 Business Lounge",
    "Mindquest's Mission Control Center \u2014 Captain's Log":
      "Mindquest's Mission Control Center \u2014 Captain's Log",
    "Mindquest's Mission Control Center \u2014 Career Tips & Tricks":
      "Mindquest's Mission Control Center \u2014 Career Tips & Tricks",
    "Mindquest's Mission Control Center \u2014 Horizon 2050":
      "Mindquest's Mission Control Center \u2014 Horizon 2050",
    "Mindquest's Mission Control Center \u2014 IT's Who is Who":
      "Mindquest's Mission Control Center \u2014 IT's Who is Who",
    "Mindquest's Mission Control Center \u2014 MCC: IT's resource hub":
      "Mindquest's Mission Control Center \u2014 MCC: IT's resource hub",
    "Mindquest's Mission Control Center \u2014 Newsstand":
      "Mindquest's Mission Control Center \u2014 Newsstand",
    "Mindquest's Mission Control Center\u2014 Search all articles":
      "Mindquest's Mission Control Center\u2014 Search all articles",
    "Mindquest,": "Mindquest,",
    "Mindquest, Freelance informatique, consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP":
      "Mindquest, Freelance informatique, consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP",
    "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks":
      "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks",
    "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand":
      "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand",
    "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT":
      "Mindquest, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT",
    "Mindquest, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge":
      "Mindquest, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge",
    "Mindquest, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements":
      "Mindquest, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements",
    "Mindquest, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech":
      "Mindquest, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech",
    "Mindquest, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads":
      "Mindquest, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads",
    "Mindquest, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi":
      "Mindquest, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi",
    "Mindquest, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search":
      "Mindquest, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search",
    "Mindquest, privacy policy": "Mindquest, privacy policy",
    "Mindquest, talent management, freelance informatique, trouver un freelance":
      "Mindquest, talent management, freelance informatique, trouver un freelance",
    Mission: "Mission",
    "Mission pour Freelances":
      "Sourcing & placement de consultants IT ind\xE9pendants",
    Missions: "Missions",
    "Missions recommand\xE9es": "Missions recommand\xE9es",
    Mobilité: "Mobilit\xE9",
    Modifier: "Modifier",
    "Modifier le Timesheet": "Modifier le Timesheet",
    "Modifier mon alerte avec ces crit\xE8res":
      "Modifier mon alerte avec ces crit\xE8res",
    "Modifier votre profil": "Modifier votre profil",
    "Mod\xE8les de CV Club Freelance": "Mod\xE8les de CV Club Freelance",
    "Mod\xE8les de CV Mindquest": "Mod\xE8les de CV Mindquest",
    "Mon CV": "Mon CV",
    "Mon besoin": "Mon besoin",
    "Mon entreprise": "Mon entreprise",
    "Mon exp\xE9rience": "Mon exp\xE9rience",
    "Mon m\xE9tier": "Mon m\xE9tier",
    "Mon profil": "Mon CV",
    "More articles": "Voir plus d\u2019articles",
    "Mot de passe": "Mot de passe",
    "Mot de passe erron\xE9": "Mot de passe erron\xE9",
    "Mot de passe oubli\xE9": "Mot de passe oubli\xE9",
    "Moteurs de recherche": "Moteurs de recherche",
    "Mots-cl\xE9s importants": "Mots-cl\xE9s importants",
    "Mots-cl\xE9s indispensables*": "Mots-cl\xE9s indispensables*",
    "Moyenne entreprise (51-100)": "Moyenne entreprise (51-100)",
    "Mutuelle, formation, cr\xE9dit bancaire... d\xE9couvrez les offres privil\xE8gi\xE9es dont vous pouvez b\xE9n\xE9ficier aupr\xE8s de nos partenaires.":
      "Mutuelle, formation, cr\xE9dit bancaire... d\xE9couvrez les offres privil\xE8gi\xE9es dont vous pouvez b\xE9n\xE9ficier aupr\xE8s de nos partenaires.",
    "M\xE9thode recommand\xE9e": "M\xE9thode recommand\xE9e",
    "N/A": "N/A",
    "Ne cherchez plus !": "Ne cherchez plus !",
    Newsletter: "Newsletter",
    Newsstand: "Newsstand",
    "Niveau d'exp\xE9rience": "Niveau d'exp\xE9rience",
    "Niveau de s\xE9curit\xE9": "Niveau de s\xE9curit\xE9",
    "No fee usually required": "No fee usually required",
    Nom: "Nom",
    "Nom de famille": "Nom de famille",
    "Nom de la certification": "Nom de la certification",
    "Nom de l\u2019entreprise": "Nom de l\u2019entreprise",
    "Nom du diplome": "Nom du diplome",
    "Nom du r\xE9f\xE9rent": "Nom du r\xE9f\xE9rent",
    "Nom et pr\xE9nom": "Nom et pr\xE9nom",
    "Nombre non valide": "Nombre non valide",
    Non: "Non",
    "Non Merci": "Non Merci",
    "Non pr\xE9cis": "Non pr\xE9cis\xE9",
    "Non renseign\xE9": "Non renseign\xE9",
    "Nos Partennaires": "Nos Partennaires",
    "Nos bureaux": "Nos bureaux",
    "Nos chiffres clefs": "Nos chiffres clefs",
    "Nos conseils en vid\xE9o": "Nos conseils en vid\xE9o",
    "Nos conseils pour les freelance": "Nos conseils pour les candidats",
    "Nos conseils pour recruter en freelance":
      "Nos conseils pour recruter en freelance",
    "Nos partenaires": "Nos partenaires",
    "Nos partenaires et nous d\xE9posons des cookies afin d'assurer la s\xE9curit\xE9, am\xE9liorer votre exp\xE9rience digitale et afficher des publicit\xE9s et contenus personnalis\xE9s Vous pouvez accepter ou refuser ces diff\xE9rentes op\xE9rations. Par ailleurs, conform\xE9ment \xE0 la l\xE9gislation fran\xE7aise, les cookies de mesure d'audience peuvent \xEAtre exempt\xE9s du recueil du consentement de l'internaute. En l'esp\xE8ce et sur nos sites, nos cookies Web Analytics sont dispens\xE9s du recueil du consentement. G\xE9rer vos pr\xE9f\xE9rences AT Internet":
      "Nos partenaires et nous d\xE9posons des cookies afin d'assurer la s\xE9curit\xE9, am\xE9liorer votre exp\xE9rience digitale et afficher des publicit\xE9s et contenus personnalis\xE9s Vous pouvez accepter ou refuser ces diff\xE9rentes op\xE9rations. Par ailleurs, conform\xE9ment \xE0 la l\xE9gislation fran\xE7aise, les cookies de mesure d'audience peuvent \xEAtre exempt\xE9s du recueil du consentement de l'internaute. En l'esp\xE8ce et sur nos sites, nos cookies Web Analytics sont dispens\xE9s du recueil du consentement. G\xE9rer vos pr\xE9f\xE9rences AT Internet",
    "Nos \xE9quipes innovation et support travaillent \xE0 l\u2019optimisation constante de nos outils et process pour vous proposer la meilleure exp\xE9rience possible.":
      "Nos \xE9quipes innovation et support travaillent \xE0 l\u2019optimisation constante de nos outils et process pour vous proposer la meilleure exp\xE9rience possible.",
    "Nos \xE9quipes sont en contact permanent avec entreprises et freelances, permettant une connaissance pointue du march\xE9":
      "Nos \xE9quipes sont en contact permanent avec entreprises et Talents IT, d'o\xF9 une connaissance pointue du march\xE9",
    "Nos \xE9quipes vous accompagnent pour recruter & int\xE9grer les meilleurs freelances IT pour vos projets. Solution compl\xE8te de Talent Management et conseils personnalis\xE9s":
      "Nos \xE9quipes vous accompagnent pour recruter & int\xE9grer les meilleurs talents IT pour vos projets. Solution compl\xE8te de Talent Management et conseils personnalis\xE9s",
    "Note: ce bilan d'activit\xE9 sera visible par le client sur votre compte-rendu de Timesheet":
      "Note: ce bilan d'activit\xE9 sera visible par le client sur votre compte-rendu de Timesheet",
    "Notre accompagnent": "Notre accompagnent",
    "Notre algorithme de matching SMATCH est en pleine de recherche du profils parfait au sein de nos communaut\xE9s":
      "Notre algorithme de matching SMATCH est en pleine de recherche du profils parfait au sein de nos communaut\xE9s",
    "Notre algorithme de matching SMATCH est en pleine recherche de profils correspondants au sein de nos communaut\xE9s":
      "Notre algorithme de matching SMATCH est en pleine recherche de profils correspondants au sein de nos communaut\xE9s",
    "Notre mission est d\u2019une part d\u2019aider nos entreprises clientes \xE0 attirer et fid\xE9liser les meilleurs talents pour r\xE9pondre \xE0 leurs besoins strat\xE9giques, et d\u2019autre part d\u2019accompagner nos consultants dans leur carri\xE8re en les aidant \xE0 trouver les missions les plus adapt\xE9es \xE0 leur profil et leurs aspirations.":
      "Notre mission est d\u2019une part d\u2019aider nos entreprises clientes \xE0 attirer et fid\xE9liser les meilleurs talents pour r\xE9pondre \xE0 leurs besoins strat\xE9giques, et d\u2019autre part d\u2019accompagner nos consultants dans leur carri\xE8re en les aidant \xE0 trouver les missions les plus adapt\xE9es \xE0 leur profil et leurs aspirations.",
    "Notre newsletter bimensuelle comporte nos derniers articles, nos plus belles missions et du contenu sp\xE9cialement s\xE9lectionn\xE9 pour vous":
      "Inscrivez-vous \xE0 notre newsletter, Mission Control Center, pour recevoir chaque jeudi un r\xE9sum\xE9 des meilleures infos tech & IT, nos derniers articles et nos plus belles offres d\u2019emploi du moment\u202F!",
    "Notre performance en chiffres": "Nos performances en chiffres",
    "Notre service de facturation fera correspondre les heures normales et sp\xE9ciales avec les tarifs d\xE9finis dans votre contrat":
      "Notre service de facturation fera correspondre les heures normales et sp\xE9ciales avec les tarifs d\xE9finis dans votre contrat",
    "Nous accordons une grande place \xE0 l\u2019humain et nouons des relations de long terme avec nos clients et nos consultants.":
      "Nous accordons une grande place \xE0 l\u2019humain et nouons des relations de long terme avec nos clients et nos consultants.",
    "Nous b\xE9n\xE9ficions d\u2019une connaissance approfondie des process m\xE9tiers par secteur d\u2019activit\xE9, et des comp\xE9tences techniques par domaine d\u2019expertise":
      "Nous b\xE9n\xE9ficions d\u2019une connaissance approfondie des process m\xE9tiers par secteur d\u2019activit\xE9, et des comp\xE9tences techniques par domaine d\u2019expertise",
    "Nous contacter": "Nous contacter",
    "Nous couvrons tous les m\xE9tiers de la technologie et de l\u2019informatique":
      "Nous couvrons tous les m\xE9tiers de la technologie et de l\u2019informatique",
    "Nous identifions les esprits brillants et les associons avec les marques les plus innovantes.":
      "Nous accompagnons les meilleurs consultants IT et les entreprises les plus innovantes dans la r\xE9ussite de leurs projets",
    "Nous ne construisons pas des bases de donn\xE9es, nous construisons des communaut\xE9s. D\u2019o\xF9 des relations de proximit\xE9 avec nos consultants ind\xE9pendants, et une connaissance pointue des profils disponibles sur le march\xE9.":
      "Nous ne construisons pas des bases de donn\xE9es, nous construisons des communaut\xE9s. D\u2019o\xF9 des relations de proximit\xE9 avec nos consultants ind\xE9pendants, et une connaissance pointue des profils disponibles sur le march\xE9.",
    "Nous n\u2019avons pas pu g\xE9n\xE9rer le fichier demand\xE9":
      "Nous n\u2019avons pas pu g\xE9n\xE9rer le fichier demand\xE9",
    "Nous rejoindre": "Nous rejoindre",
    "Nous sommes d\xE9sol\xE9, l'offre que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Nous sommes d\xE9sol\xE9s, l'offre que vous cherchez n\u2019existe pas ou n\u2019est plus disponible",
    "Nous sommes d\xE9sol\xE9, l'opportunit\xE9 que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Nous sommes d\xE9sol\xE9s, l'opportunit\xE9 que vous cherchez n\u2019existe pas ou n\u2019est plus disponible",
    "Nous sommes d\xE9sol\xE9, la mission que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Nous sommes d\xE9sol\xE9s, la mission que vous cherchez n\u2019existe pas ou n\u2019est plus disponible",
    "Nous sommes d\xE9sol\xE9, le candidat que vous cherchez n\u2019existe pas ou n\u2019est plus disponible":
      "Nous sommes d\xE9sol\xE9, le candidat que vous cherchez n\u2019existe pas ou n\u2019est plus disponible",
    "Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin.":
      "Nous vous contacteront au plus vite afin d'\xE9changer sur votre besoin.",
    "Nous vous recommandons fortement d\u2019utiliser le format Word.":
      "Nous vous recommandons fortement d\u2019utiliser le format Word",
    "Nous y r\xE9pondrons dans les plus brefs d\xE9lais":
      "Nous y r\xE9pondrons dans les plus brefs d\xE9lais",
    "Nouveau Timesheet en attente": "Nouveau Timesheet en attente",
    "Nouveau compte-rendu disponible pour le contrat {placementId}": function(
      a
    ) {
      return [
        "Nouveau compte-rendu disponible pour le contrat ",
        a("placementId")
      ];
    },
    "Nouveau valideur pour le contrat {placementId}": function(a) {
      return ["Nouveau valideur pour le contrat ", a("placementId")];
    },
    "Nouveaux profils": "Nouveaux profils",
    Nouvelles: "Nouvelles",
    "Num\xE9ro non valide": "Num\xE9ro invalide",
    Néerlandais: "N\xE9erlandais",
    OU: "OU",
    "Obtenir un lien partageable": "Obtenir un lien partageable",
    "Obtenir votre lien partageable": "Obtenir votre lien partageable",
    "Obtenues sur cette mission": "Obtenues sur cette mission",
    Offre: "Offre",
    Offres: "Offres",
    "Offres archiv\xE9es": "Offres archiv\xE9es",
    "Offres en cours": "Offres en cours",
    "Offres post\xE9es": "Offres post\xE9es",
    "Offres recommand\xE9es": "Offres recommand\xE9es",
    "Offres similaires": "Offres similaires",
    Ok: "Ok",
    "On parle de nous": "On parle de nous",
    "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre mission":
      "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre mission",
    "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre nouveau job !":
      "On s\u2019occupe de l\u2019admin. Plus qu\u2019\xE0 d\xE9marrer votre nouveau job !",
    "One of the GDPR\u2019s main objectives is to protect and clarify the rights of EU citizens and individuals in the EU with regards to data privacy. This means that you retain various rights in respect of your data, even once you have given it to us. These are described in more detail below.":
      "One of the GDPR\u2019s main objectives is to protect and clarify the rights of EU citizens and individuals in the EU with regards to data privacy. This means that you retain various rights in respect of your data, even once you have given it to us. These are described in more detail below.",
    "Oops!": "Oops!",
    "Organisme de d\xE9livrance": "Organisme de d\xE9livrance",
    "Other information relevant to help us provide recruitment services;":
      "Other information relevant to help us provide recruitment services;",
    Ou: "Ou",
    Oui: "Oui",
    "Oups! il semblerait que vos donn\xE9es aient du mal \xE0 se synchroniser. Veuillez rafraichir la page dans un moment":
      "Oups! il semblerait que vos donn\xE9es aient du mal \xE0 se synchroniser. Veuillez rafraichir la page dans un moment",
    "Our Clients may share personal information about you with us;":
      "Our Clients may share personal information about you with us;",
    "Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.":
      "Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.",
    "Ouvert aux opportunit\xE9s": "Ouvert aux opportunit\xE9s",
    Ouverte: "Ouverte",
    Ouverts: "Ouverts",
    "Ouverture du Sourcing Hub \xE0 Barcelone":
      "Ouverture du Sourcing Hub \xE0 Barcelone",
    "Ouverture du bureau commercial \xE0 paris":
      "Ouverture du bureau commercial \xE0 Paris",
    "PMO BI": "PMO BI",
    Par: "Par",
    "Par an": "Par an (Format 00 000)",
    "Par jour": "En euros",
    Paris: "Paris",
    Partager: "Partager",
    "Partager l\u2019article": "Partager l\u2019article",
    "Pas d\u2019activit\xE9 jusqu\u2019\xE0 maintenant":
      "Pas d\u2019activit\xE9 jusqu\u2019\xE0 maintenant",
    "Pas d\u2019activit\xE9 pendant la periode s\xE9lectionn\xE9e":
      "Pas d\u2019activit\xE9 pendant la periode s\xE9lectionn\xE9e",
    "Passer cette \xE9tape": "Passer cette \xE9tape",
    "Pensez \xE0 consulter vos courriers ind\xE9sirables":
      "Pensez \xE0 consulter vos courriers ind\xE9sirables",
    "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: {pID} de {m}/{y}": function(
      a
    ) {
      return [
        "Pensez \xE0 remplir votre Timesheet du contrat num\xE9ro: ",
        a("pID"),
        " de ",
        a("m"),
        "/",
        a("y")
      ];
    },
    "Pens\xE9s pour les freelances.": "Pens\xE9s pour les freelances.",
    Permanent: "Permanent",
    "Personal contact details of company employees we are in contact with such as name, title, work addresses, telephone numbers, work email addresses, languages and skills;":
      "Personal contact details of company employees we are in contact with such as name, title, work addresses, telephone numbers, work email addresses, languages and skills;",
    "Personal contact details such as name, title, addresses, telephone numbers, personal and work email addresses, Skype or other web ID;":
      "Personal contact details such as name, title, addresses, telephone numbers, personal and work email addresses, Skype or other web ID;",
    "Personal data that we receive from other sources.":
      "Personal data that we receive from other sources.",
    "Personal data that you, as the Candidate or Client, give to us; and":
      "Personal data that you, as the Candidate or Client, give to us; and",
    "Personal data we receive from other sources":
      "Personal data we receive from other sources",
    "Personal data you give to us": "Personal data you give to us",
    "Petite entreprise (1-50)": "Petite entreprise (1-50)",
    Placement: "Recrutements",
    Placements: "Recrutements",
    "Placements r\xE9ussis chez nos clients":
      "Placements r\xE9ussis chez nos clients",
    "Please read the following carefully to understand our views and practices regarding your personal data, how we will treat it and your rights.":
      "Please read the following carefully to understand our views and practices regarding your personal data, how we will treat it and your rights.",
    "Plus d\u2019expertise": "Plus de domaines d'expertise",
    Polish: "Polonais",
    Polonais: "Polonais",
    "Portage Salarial": "Portage Salarial",
    "Portfolio, CV en ligne, site personnel":
      "Portfolio, CV en ligne, site personnel",
    Portugais: "Portugais",
    Portuguese: "Portugais",
    "Poste actuel": "Poste actuel",
    "Poster un besoin via le formulaire": "Poster un besoin via le formulaire",
    "Poster un besoin via une fiche de poste":
      "Poster un besoin via une fiche de poste",
    "Poster une offre": "Poster une offre",
    Postuler: "Postuler",
    Postulé: "Postul\xE9",
    "Pour activer les fonctionnalit\xE9s de timesheet, vous devez contractualiser avec nos services.":
      "Pour activer les fonctionnalit\xE9s de timesheet, vous devez contractualiser avec nos services.",
    "Pour activer les fonctionnalit\xE9s du Timesheets, vous devez contractualiser avec nos services.":
      "Pour activer les fonctionnalit\xE9s du Timesheets, vous devez contractualiser avec nos services.",
    "Pour d\xE9bloquer les fonctionnalit\xE9s de timesheet, vous devez d\xE9marrer une mission via nos services.":
      "Pour d\xE9bloquer les fonctionnalit\xE9s de timesheet, vous devez d\xE9marrer une mission via nos services.",
    'Pour enregistrer les donn\xE9es saisies, fermez cette fen\xEAtre en cliquant sur "annuler" puis cliquez sur le bouton "enregistrer" \xE0 la fin du formulaire.':
      'Pour enregistrer les donn\xE9es saisies, fermez cette fen\xEAtre en cliquant sur "annuler" puis cliquez sur le bouton "enregistrer" \xE0 la fin du formulaire.',
    "Pour faciliter vos \xE9changes avec nous, n\u2019h\xE9sitez pas \xE0 cr\xE9er un compte":
      "Pour faciliter vos \xE9changes avec nous, n\u2019h\xE9sitez pas \xE0 cr\xE9er un compte",
    "Pour indiquer que vous avez effectivement travaill\xE9 ce jour et \xE0 votre TJM habituel, cliquez sur continuer.":
      "Pour indiquer que vous avez effectivement travaill\xE9 ce jour et \xE0 votre TJM habituel, cliquez sur continuer.",
    "Pour ne manquer aucun article": "Pour ne manquer aucun article",
    "Pour obtenir imm\xE9diatement des nouveaux talents":
      "Pour obtenir tr\xE8s rapidement des profils adapt\xE9s \xE0 mes besoins",
    "Pour obtenir imm\xE9diatement des offres personnalis\xE9es.":
      "Pour obtenir imm\xE9diatement des offres personnalis\xE9es",
    "Pour recevoir des profils, soumettez une offre.":
      "Pour recevoir des profils, soumettez une offre.",
    "Privacy Policy - Club Freelance": "Privacy Policy - Club Freelance",
    "Privacy Policy - Mindquest": "Privacy Policy - Mindquest",
    "Privacy policy": "Privacy policy",
    "Prochain rendez-vous": "Prochain rendez-vous",
    "Profil anonymis\xE9": "Profil anonymis\xE9",
    "Profil recherch\xE9": "Profil recherch\xE9",
    "Profils refus\xE9s": "Profils refus\xE9s",
    "Profils \xE0 rencontrer": "Profils \xE0 rencontrer",
    "Profitez des avantages que nous avons n\xE9goci\xE9s pour vous":
      "Profitez des avantages que nous avons n\xE9goci\xE9s pour vous",
    Projet: "Projet",
    Proposition: "Proposition",
    "Providing us with a copy of your CV or by corresponding with us by post, phone, email or otherwise or being interviewed by us;":
      "Providing us with a copy of your CV or by corresponding with us by post, phone, email or otherwise or being interviewed by us;",
    "Pr\xE9ciser la date": "Pr\xE9ciser la date",
    "Pr\xE9ciser le nombre d'heures": "Pr\xE9ciser le nombre d'heures",
    "Pr\xE9ciser le nombre de minutes": "Pr\xE9ciser le nombre de minutes",
    "Pr\xE9ciser le type": "Pr\xE9ciser le type",
    Prénom: "Pr\xE9nom",
    "Pr\xE9nom du r\xE9f\xE9rent": "Pr\xE9nom du r\xE9f\xE9rent",
    "Pr\xE9nom et Nom": "Pr\xE9nom et Nom",
    Présentiel: "Pr\xE9sentiel",
    "Pr\xEAt \xE0 trouver une mission ?":
      "Pr\xEAt(e) \xE0 trouver votre prochaine mission ?",
    "Pr\xEAt \xE0 trouver une nouvelle mission ?":
      "Pr\xEAt(e) \xE0 acc\xE9l\xE9rer sur vos projets ?",
    "Publi\xE9 le": "Publi\xE9 le",
    Période: "P\xE9riode",
    "Quel est votre domaine ?": "Quel est votre domaine d'expertise ?",
    "Quel est votre statut": "Quel est votre statut",
    "Quelle est votre statut": "Quelle est votre statut",
    "Quels contenus aimeriez vous recevoir":
      "Quels contenus aimeriez vous recevoir ?",
    "Qui sommes nous?": "Qui sommes nous?",
    "Qui vous correspond":
      "Des offres adapt\xE9es \xE0 vos envies et aspirations",
    "Quick and interesting reads for commuters and curious minds. Tech, IT and pop culture merge in this section for a quick break from work at any time of the day.":
      "Quick and interesting reads for commuters and curious minds. Tech, IT and pop culture merge in this section for a quick break from work at any time of the day.",
    Quicktip: "Quicktip",
    "Rafra\xEEchissez la page": "Rafra\xEEchissez la page",
    Rappel: "Rappel",
    "Ravis de vous revoir": "Ravis de vous (re)voir",
    "ReCAPCHA non valid": "ReCAPCHA non valid",
    "Recevez des offres adapt\xE9es \xE0 vos crit\xE8res":
      "Vous recevez uniquement des offres adapt\xE9es \xE0 vos crit\xE8res ",
    "Recevoir la newsletter Club Freelance.":
      "Recevoir la newsletter Club Freelance.",
    "Recevoir la newsletter Mindquest.":
      "Recevoir tous les 15 jours la newsletter tech & IT de Mindquest (en anglais)",
    "Recevoir notre newsletter bimensuelle sp\xE9ciale Tech & IT (conseils pratiques, dossiers sp\xE9ciaux, s\xE9lection de missions, etc.).":
      "Recevoir notre newsletter bimensuelle sp\xE9ciale Tech & IT (conseils pratiques, dossiers sp\xE9ciaux, s\xE9lection de missions, etc.).",
    Recherche: "Recherche",
    Rechercher: "Rechercher",
    "Rechercher des articles, des vid\xE9os et des ressources":
      "Rechercher des articles, des vid\xE9os et des ressources",
    "Rechercher un consultant": "Rechercher un consultant",
    "Rechercher un secteur d\u2019activit\xE9":
      "Rechercher un secteur d\u2019activit\xE9",
    "Rechercher un talent": "Rechercher un talent",
    "Rechercher...": "Rechercher...",
    "Recherchez les consultants gr\xE2ce au contenu de votre fiche de poste ou en ajoutant directement votre mots cl\xE9s IT":
      "Recherchez les consultants gr\xE2ce au contenu de votre fiche de poste ou en ajoutant directement votre mots cl\xE9s IT",
    "Recherchez un article au sein de notre s\xE9lection pour Freelance informatiques et D\xE9cideurs IT":
      "Recherchez un article au sein de notre s\xE9lection pour Talents et D\xE9cideurs IT",
    "Recrutez les meilleurs talents tech & IT":
      "Recrutez les meilleurs talents tech & IT",
    "References from past employers;": "References from past employers;",
    "Rejeter le timesheet": "Rejeter le timesheet",
    Rejetée: "Rejet\xE9e",
    Rejetés: "Rejet\xE9s",
    "Rejoignez la communaut\xE9 des Freelances IT":
      "Rejoindre une communaut\xE9 de top talents IT",
    "Rejoignez le Club": "Rejoignez le Club",
    "Rejoignez le club": "Rejoignez le club",
    "Rejoignez le club et trouvez<0/>votre prochaine mission freelance IT":
      "Rejoignez le club et trouvez<0/>votre prochaine mission freelance IT",
    "Rejoignez le club pour recevoir<0/>des offres adapt\xE9es \xE0 vos envies.":
      "Rejoignez Mindquest pour recevoir<0/>des offres adapt\xE9es \xE0 vos envies.",
    "Rejoindre Club Freelance": "Rejoindre Club Freelance",
    "Rejoindre Mindquest": "Rejoindre Mindquest",
    "Rejoindre Mission Control Center et trouver les meilleurs id\xE9es de IT professionnel":
      "Rejoindre Mission Control Center et trouver les meilleurs id\xE9es de IT professionnel",
    "Rejoindre le club": "Rejoindre le club",
    "Rejoindre le club en 3 minutes": "Rejoindre Mindquest en 3 minutes",
    "Rejoindre une communaut\xE9 de freelances":
      "L\u2019adh\xE9sion \xE0 une communaut\xE9 de freelances talentueux",
    "Rejoindre une communaut\xE9<0/>de freelances":
      "L\u2019adh\xE9sion \xE0 une communaut\xE9 de freelances talentueux",
    Remote: "Remote",
    "Remote partiel": "Remote partiel",
    "Remote uniquement": "Remote uniquement",
    "Remote/Pr\xE9sentiel": "Remote/Pr\xE9sentiel",
    "Remote/R\xE9gie": "Remote/Pr\xE9sentiel",
    "Remplir la timesheet": "Remplir le timesheet",
    "Remplir le Timesheet": "Remplir le Timesheet",
    "Renvoyer l\u2019email de validation":
      "Renvoyer l\u2019email de validation",
    Ressources: "Ressources",
    Retour: "Retour",
    "Retour au dashboard": "Retour au dashboard",
    "Retour au site": "Retour au site",
    "Retour d\u2019exp\xE9rience": "Retour d\u2019exp\xE9rience",
    "Retourner a la mission": "Retourner a la mission",
    "Retrouvez des offres s\xE9lectionn\xE9s pour vous":
      "Retrouvez des offres s\xE9lectionn\xE9es pour vous",
    "Retrouvez des talents s\xE9lectionn\xE9s pour vous":
      "Retrouvez des talents s\xE9lectionn\xE9s pour vous",
    "Retrouvez nos offres pour consultants IT : Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...":
      "Retrouvez nos offres pour consultants IT : Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...",
    "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources":
      "Retrouvez toute l'actualit\xE9 de Mindquest et nos s\xE9l\xE9ctions d'articles pour Webinars & Ressources",
    "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT":
      "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT",
    "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT.":
      "Retrouvez toute l\u2019actualit\xE9 de Club Freelance et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT.",
    "Retrouvez toute l\u2019actualit\xE9 de Mindquest et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT":
      "Retrouvez toute l\u2019actualit\xE9 de Mindquest et nos s\xE9lections d\u2019articles pour Freelances informatiques et d\xE9cideurs IT",
    "Retrouvez toute l\u2019actualit\xE9 de Mindquest et notre s\xE9lection d\u2019articles pour les Freelances informatiques et d\xE9cideurs IT.":
      "Retrouvez toute l\u2019actualit\xE9 de Mindquest et nos s\xE9lections d\u2019articles pour freelances informatiques et d\xE9cideurs IT",
    "Revenir \xE0 mes mots cl\xE9s": "Revenir \xE0 mes mots cl\xE9s",
    Revenue: "Chiffre d'affaire",
    "Right to work status / citizenship;":
      "Right to work status / citizenship;",
    Romanian: "Roumain",
    Roumain: "Roumain",
    Russe: "Russe",
    Russian: "Russe",
    "Rythme de travail": "Rythme de travail",
    Récence: "Date de publication",
    "R\xE9cup\xE9rez des mots-cl\xE9s IT":
      "R\xE9cup\xE9rez des mots-cl\xE9s IT",
    Référence: "Contact de R\xE9f\xE9rence",
    "R\xE9f\xE9rence du bon de commande": "R\xE9f\xE9rence du bon de commande",
    "R\xE9f\xE9rence mis \xE0 jour avec succ\xE8s":
      "R\xE9f\xE9rence mis \xE0 jour avec succ\xE8s",
    Références: "R\xE9f\xE9rences",
    Régie: "Pr\xE9sentiel",
    "R\xE9gie uniquement": "Pr\xE9sentiel uniquement",
    Réinitialiser: "R\xE9initialiser",
    "R\xE9initialiser votre mot de passe":
      "R\xE9initialisez votre mot de passe",
    "R\xE9initialisez votre mot de passe":
      "R\xE9initialisez votre mot de passe",
    "R\xE9seaux sociaux": "R\xE9seaux sociaux",
    "S1:": "S1:",
    "S2:": "S2:",
    SAP: "SAP",
    "SAP SD MM Project Manager": "SAP SD MM Project Manager",
    SASU: "SASU",
    SMS: "SMS",
    Salaire: "Salaire",
    "Salaire Annuel": "Salaire Annuel",
    "Salaire annuel": "Salaire annuel",
    Salarié: "Salari\xE9",
    "Salut {firstName} {lastName}": function(a) {
      return ["Salut ", a("firstName"), " ", a("lastName")];
    },
    Sauvegarder: "Sauvegarder",
    "Score Smatch": "Score Smatch",
    "Se connecter": "Se connecter",
    "Se d\xE9connecter": "Se d\xE9connecter",
    "Se souvenir de moi": "Se souvenir de moi",
    "Search for all our articles and resources":
      "Rechercher des articles, des vid\xE9os et des ressources",
    Secteur: "Secteur",
    "Secteur d\u2019activit\xE9": "Secteur d\u2019activit\xE9",
    "Selected third parties including:": "Selected third parties including:",
    Senior: "Senior",
    "Senior (7 - 10)": "Senior (7 - 10)",
    "Senior (7-10)": "Senior (7-10)",
    "Sex/Gender;": "Sex/Gender;",
    "Short sci-fi stories posted every month to promote an open debate on the future of tech.":
      "Short sci-fi stories posted every month to promote an open debate on the future of tech.",
    "Si nous ne sommes pas en mesure de vous accompagner dans cette recherche, vous serez inform\xE9s par email.":
      "Si nous ne sommes pas en mesure de vous accompagner dans cette recherche, vous serez inform\xE9 par email.",
    "Si vous avez travaill\xE9 \xE0 un taux major\xE9 (astreintes, heures suppl\xE9mentaires major\xE9es...), merci d'utiliser l'espace heures et astreintes \xE0 droite.":
      "Si vous avez travaill\xE9 \xE0 un taux major\xE9 (astreintes, heures suppl\xE9mentaires major\xE9es...), merci d'utiliser l'espace heures et astreintes \xE0 droite.",
    "Si vous n\u2019\xEAtes pas redirig\xE9 dans 3mn, merci de nous contacter":
      "Si vous n\u2019\xEAtes pas redirig\xE9 dans 3mn, merci de nous contacter",
    "Si vous \xEAtes interress\xE9 par ce profil ou un candidat similaire, nous vous invitons \xE0 soumettre une nouvelle offre ou \xE0 nous contacter":
      "Si vous \xEAtes interress\xE9 par ce profil ou un candidat similaire, nous vous invitons \xE0 soumettre une nouvelle offre ou \xE0 nous contacter",
    "Signataire principal": "Signataire principal",
    Signataires: "Signataires",
    "Signataires en attente": "Signataires en attente",
    "Signataires secondaires": "Signataires secondaires",
    Signatures: "Signatures",
    "Sinon cliquez sur continuer.": "Sinon cliquez sur continuer.",
    "Sit back and relax. We're here to help you build the dream team for business success.":
      "Sit back and relax. We're here to help you build the dream team for business success.",
    "Sit back, relax and enjoy our in-depth articles on IT talent management and industry trends. We're here to help you build the best possible team for business success.":
      "Sit back, relax and enjoy our in-depth articles on IT talent management and industry trends. We're here to help you build the best possible team for business success.",
    Soumettre: "Soumettre",
    "Soumettre au client": "Soumettre au client",
    "Sourcing Hub": "Sourcing Hub",
    "Sourcing Manager": "Sourcing Manager",
    "Soyez aussi complet que possible. La mise en valeur de vos comp\xE9tences et de votre profil augmentera vos chances de rencontrer l\u2019emploi de vos r\xEAves":
      "Soyez aussi complet que possible. La mise en valeur de vos comp\xE9tences et de votre profil augmentera vos chances de rencontrer l\u2019emploi de vos r\xEAves",
    Spanish: "Espagnol",
    Spécialité: "M\xE9tier",
    Spécialités: "Sp\xE9cialit\xE9s",
    Statistiques: "Statistiques",
    Subscribe: "Je m\u2019abonne",
    "Subscribe to our weekly newsletter to get the latest articles and technology news":
      "Inscrivez-vous et recevez tous les articles",
    "Success Stories": "T\xE9moignages",
    "Successful professional matches":
      "Placements r\xE9alis\xE9s avec succ\xE8s",
    Succès: "Succ\xE8s",
    "Suite \xE0 la validation du Timesheet, le compte-rendu de mois {m}/{y} est disponible": function(
      a
    ) {
      return [
        "Suite \xE0 la validation du Timesheet, le compte-rendu de mois ",
        a("m"),
        "/",
        a("y"),
        " est disponible"
      ];
    },
    "Suivez toutes vos candidatures": "Suivez toutes vos candidatures",
    "Suivez toutes vos missions": "Suivez tous vos recrutements IT",
    "Suivez vos candidatures dans votre espace personel":
      "Vous suivez vos candidatures dans votre espace personnel",
    Supprimer: "Supprimer",
    "Syst\xE8mes Embarqu\xE9s": "Syst\xE8mes Embarqu\xE9s",
    "S\xE9curit\xE9 des Syst\xE8mes d'Information":
      "S\xE9curit\xE9 des Syst\xE8mes d'Information",
    "S\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil. Accompagnement personnalis\xE9. Paiements \xE0 30 jours. Pour tous les Freelances du digital, de la tech et de l\u2019IT":
      "S\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil. Accompagnement personnalis\xE9. Paiements \xE0 30 jours. Pour tous les talents du digital, de la tech et de l\u2019IT",
    "S\xE9lectionner depuis la liste ou utiliser la detection automatique":
      "S\xE9lectionner depuis la liste ou utiliser la d\xE9tection automatique",
    "S\xE9lectionner des certifications": "S\xE9lectionner des certifications",
    "S\xE9lectionner des comp\xE9tences": "S\xE9lectionner des comp\xE9tences",
    "S\xE9lectionner des domaines": "S\xE9lectionner des domaines",
    "S\xE9lectionner des langues": "S\xE9lectionner des langues",
    "S\xE9lectionner des secteurs": "S\xE9lectionner des secteurs",
    "S\xE9lectionner des sp\xE9cialit\xE9s": "S\xE9lectionner des m\xE9tiers",
    "S\xE9lectionner l'exp\xE9rience demand\xE9e":
      "S\xE9lectionner l'exp\xE9rience demand\xE9e",
    "S\xE9lectionner un secteur": "S\xE9lectionner un secteur",
    "S\xE9lectionnez la zone avant d'ajouter les mots-cl\xE9s":
      "S\xE9lectionnez la zone avant d'ajouter les mots-cl\xE9s",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV":
      "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser":
      "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser",
    "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser.":
      "S\xE9lectionnez votre mod\xE8le et g\xE9n\xE9rez un CV au design moderne, adapt\xE9 aux recuteurs et facile \xE0 diffuser.",
    "Taille de l\u2019entreprise": "Taille de l\u2019entreprise",
    "Talent Community Specialist": "Talent Community Specialist",
    Talents: "Talents",
    "Talents recommand\xE9s": "Talents recommand\xE9s",
    "Taux journalier moyen": "Taux journalier moyen",
    "Tech and pop culture merge for a quick break from work at any time of the day.":
      "Tech and pop culture merge for a quick break from work at any time of the day.",
    "Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, geographical location, domain name from which you access the internet, browser type, and version;":
      "Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, geographical location, domain name from which you access the internet, browser type, and version;",
    "Technologies Microsoft": "Technologies Microsoft",
    "Temps partiel": "Temps partiel",
    "Temps plein": "Temps plein",
    "Test & Assurance Qualit\xE9": "Test & Assurance Qualit\xE9",
    "The GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Club Freelance at contact@club-freelance.com.":
      "The GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Club Freelance at contact@club-freelance.com.",
    "The GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Mindquest at contact@club-freelance.com.":
      "The GDPR gives you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. A subject access request should be submitted to Mindquest at contact@club-freelance.com.",
    "The right of access to the personal data Club Freelance processes on you:":
      "The right of access to the personal data Club Freelance processes on you:",
    "The right of access to the personal data Mindquest processes on you:":
      "The right of access to the personal data Mindquest processes on you:",
    "The right to complain": "The right to complain",
    "The right to data portability in certain circumstances:":
      "The right to data portability in certain circumstances:",
    "The right to erasure of your personal data in certain circumstances:":
      "The right to erasure of your personal data in certain circumstances:",
    "The right to object to the processing of your personal data that was based on our legitimate interest or for direct marketing purposes:":
      "The right to object to the processing of your personal data that was based on our legitimate interest or for direct marketing purposes:",
    "The right to rectification of your personal data:":
      "The right to rectification of your personal data:",
    "The right to withdraw consent:": "The right to withdraw consent:",
    "Their IT and human expertise led to the birth of Club Freelance. It can also help you.":
      "Their IT and human expertise led to the birth of Club Freelance. It can also help you.",
    "Their IT and human expertise led to the birth of Mindquest. It can also help you.":
      "Their IT and human expertise led to the birth of Mindquest. It can also help you.",
    "There are numerous ways you can share your information with us - it all depends on what suits you. These may include:":
      "There are numerous ways you can share your information with us - it all depends on what suits you. These may include:",
    "These companies trust us with their IT talent needs":
      "Ces entreprises ont acc\xE9l\xE9r\xE9 sur leurs projets IT gr\xE2ce \xE0 nos freelances",
    "Third-party links": "Third-party links",
    "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Club Freelance telephonically, electronically (including email) and in person.":
      "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Club Freelance telephonically, electronically (including email) and in person.",
    "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Mindquest telephonically, electronically (including email) and in person.":
      "This Privacy Notice sets out the basis on which we collect, store, use and disclose personal data we receive in writing, through our Websites, or through the recruitment services that we provide. It therefore applies to personal data that you provide to Mindquest telephonically, electronically (including email) and in person.",
    "This Privacy Policy was last updated in January 2019. The Privacy policy may be updated from time to time and should be read in the context of any additional specific information such as that provided in privacy policies applicable to specific businesses or local areas as displayed on the relevant Website or distributed to you from time to time.":
      "This Privacy Policy was last updated in January 2019. The Privacy policy may be updated from time to time and should be read in the context of any additional specific information such as that provided in privacy policies applicable to specific businesses or local areas as displayed on the relevant Website or distributed to you from time to time.",
    "This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).":
      "This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).",
    "This enables you to ask us to suspend the processing of personal information about you, in certain circumstances for example if you want us to establish its accuracy or the reason for processing it.":
      "This enables you to ask us to suspend the processing of personal information about you, in certain circumstances for example if you want us to establish its accuracy or the reason for processing it.",
    "This right enables you to object to us processing your personal data including where we do so for our legitimate interests, or for direct marketing purposes.":
      "This right enables you to object to us processing your personal data including where we do so for our legitimate interests, or for direct marketing purposes.",
    "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Club Freelance, and it can also help you.":
      "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Club Freelance, and it can also help you.",
    "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Mindquest, and it can also help you.":
      "Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Mindquest, and it can also help you.",
    "Time limit to respond": "Time limit to respond",
    Timesheet: "Timesheet",
    "Timesheet Accept\xE9": "Timesheet Accept\xE9",
    "Timesheet Ouvert": "Timesheet Ouvert",
    "Timesheet Rejet\xE9": "Timesheet Rejet\xE9",
    "Timesheet Soumis": "Timesheet Soumis",
    "Timesheet envoy\xE9 au client": "Timesheet envoy\xE9 au client",
    "Timesheet vide": "Timesheet vide",
    "Timesheet {m}/{y} du contrat num\xE9ro: {pID} a \xE9t\xE9 rejet\xE9 par le client. Merci d'effetuer les modifications n\xE9cessaires": function(
      a
    ) {
      return [
        "Timesheet ",
        a("m"),
        "/",
        a("y"),
        " du contrat num\xE9ro: ",
        a("pID"),
        " a \xE9t\xE9 rejet\xE9 par le client. Merci d'effetuer les modifications n\xE9cessaires"
      ];
    },
    "Timesheet {m}/{y} du contrat num\xE9ro: {pID} a \xE9t\xE9 valid\xE9 par le client": function(
      a
    ) {
      return [
        "Timesheet ",
        a("m"),
        "/",
        a("y"),
        " du contrat num\xE9ro: ",
        a("pID"),
        " a \xE9t\xE9 valid\xE9 par le client"
      ];
    },
    Timesheets: "Timesheets",
    "Tips & Tricks": "Tips & Tricks",
    Titre: "Intitul\xE9 de poste",
    "To answer any questions you have about a job or the job market;":
      "To answer any questions you have about a job or the job market;",
    "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Club Freelance;":
      "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Club Freelance;",
    "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Mindquest;":
      "To answer any questions you have about a vacancy or a candidate or your company\u2019s contract with Mindquest;",
    "To collect any money due, or allegedly due, to Club Freelance or any Club Freelance client (or Club Freelance client\u2019s client);":
      "To collect any money due, or allegedly due, to Club Freelance or any Club Freelance client (or Club Freelance client\u2019s client);",
    "To collect any money due, or allegedly due, to Mindquest or any Mindquest client (or Mindquest client\u2019s client);":
      "To collect any money due, or allegedly due, to Mindquest or any Mindquest client (or Mindquest client\u2019s client);",
    "To communicate with you after your company has retained a Club Freelance candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;":
      "To communicate with you after your company has retained a Club Freelance candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;",
    "To communicate with you after your company has retained a Mindquest candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;":
      "To communicate with you after your company has retained a Mindquest candidate to make sure all is going well and to remedy, or attempt to remedy, any problems;",
    "To communicate with you or your company after you have started a job to make sure all is going well or to remedy, or attempt to remedy, any problems;":
      "To communicate with you or your company after you have started a job to make sure all is going well or to remedy, or attempt to remedy, any problems;",
    "To contact you about candidates for jobs with whom Club Freelance has a relationship;":
      "To contact you about candidates for jobs with whom Club Freelance has a relationship;",
    "To contact you about candidates for jobs with whom Mindquest has a relationship;":
      "To contact you about candidates for jobs with whom Mindquest has a relationship;",
    "To contact you or your company about jobs that Club Freelance is filling or may fill for Club Freelance clients;":
      "To contact you or your company about jobs that Club Freelance is filling or may fill for Club Freelance clients;",
    "To contact you or your company about jobs that Mindquest is filling or may fill for Mindquest clients;":
      "To contact you or your company about jobs that Mindquest is filling or may fill for Mindquest clients;",
    "To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.":
      "To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.",
    "To establish, exercise or defend any legal claims.":
      "To establish, exercise or defend any legal claims.",
    "To establish, exercise or defend any legal claims; and To assist you or your company if you are dissatisfied or dislike the job, or any aspect of it.":
      "To establish, exercise or defend any legal claims; and To assist you or your company if you are dissatisfied or dislike the job, or any aspect of it.",
    "To fill an open vacancy at your company;":
      "To fill an open vacancy at your company;",
    "To fulfill any aspect your company\u2019s contract with Club Freelance;":
      "To fulfill any aspect your company\u2019s contract with Club Freelance;",
    "To fulfill any aspect your company\u2019s contract with Mindquest;":
      "To fulfill any aspect your company\u2019s contract with Mindquest;",
    "To help find you a job;": "To help find you a job;",
    "To maintain, expand and develop our business we need to record the personal data of prospective candidates and client contacts.":
      "To maintain, expand and develop our business we need to record the personal data of prospective candidates and client contacts.",
    "To negotiate and fulfill any aspect of your company\u2019s contract with Club Freelance;":
      "To negotiate and fulfill any aspect of your company\u2019s contract with Club Freelance;",
    "To negotiate and fulfill any aspect of your company\u2019s contract with Mindquest;":
      "To negotiate and fulfill any aspect of your company\u2019s contract with Mindquest;",
    "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Club Freelance or any Club Freelance client (or Club Freelance\u2019s client\u2019s client);":
      "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Club Freelance or any Club Freelance client (or Club Freelance\u2019s client\u2019s client);",
    "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Mindquest or any Mindquest client (or Mindquest\u2019s client\u2019s client);":
      "To obtain or inquire about any property (including computers and confidential business information) owned, or allegedly owned, by Mindquest or any Mindquest client (or Mindquest\u2019s client\u2019s client);",
    "To provide you or your company with information about the job market;":
      "To provide you or your company with information about the job market;",
    "To provide you with information about the job market;":
      "To provide you with information about the job market;",
    "To resolve any issue with the issuance, payment, collection or enforcement of a Club Freelance invoice;":
      "To resolve any issue with the issuance, payment, collection or enforcement of a Club Freelance invoice;",
    "To resolve any issue with the issuance, payment, collection or enforcement of a Mindquest invoice;":
      "To resolve any issue with the issuance, payment, collection or enforcement of a Mindquest invoice;",
    Total: "Total",
    Tous: "Tous",
    "Tous les talents": "Tous les talents",
    "Tous nos conseils pour les freelances.":
      "Tous nos conseils pour les Talents IT",
    "Toutes les offres archiv\xE9es": "Toutes les offres archiv\xE9es",
    "Toutes les offres en cours": "Toutes les offres en cours",
    Travaillées: "Travaill\xE9es",
    Trier: "Trier",
    "Triez les comp\xE9tances par ordre d\u2019importance":
      "Triez les comp\xE9tences par ordre d\u2019importance",
    "Trouver un consultant IT": "Trouver un consultant IT",
    "Trouver un freelance": "Trouver un freelance",
    "Trouver un talent": "Trouver un talent",
    "Trouver une mission": "Trouver une mission",
    "Trouver une mission freelance": "Consulter nos offres",
    "Trouver une offre": "Consulter nos offres",
    "Trouvez d\xE8s maintenant les profils les plus pertinents gr\xE2ce \xE0 notre algorithme de matching SMATCH":
      "Trouvez d\xE8s maintenant les profils les plus pertinents gr\xE2ce \xE0 notre algorithme de matching SMATCH",
    "Trouvez les plus belles offres IT et les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...":
      "Trouvez les plus belles offres IT et les meilleurs consultants Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...",
    "Trouvez votre consultant IT avec notre algorithme de matching SMATCH":
      "Trouvez votre consultant IT avec notre algorithme de matching SMATCH",
    "Trouvez votre consultant IT avec notre<0/>algorithme de matching SMATCH":
      "Trouvez votre consultant IT avec notre<0/>algorithme de matching SMATCH",
    Turkish: "Turque",
    Turque: "Turque",
    "Type de contract": "Type de contract",
    "Type de contrat": "Type de contrat",
    "Type de diplome": "Type de diplome",
    "T\xE9l\xE9charger ce mod\xE9le": "T\xE9l\xE9charger ce mod\xE9le",
    "T\xE9l\xE9charger la ressource": "T\xE9l\xE9charger la ressource",
    "T\xE9l\xE9charger le guide d\u2019utilisation":
      "T\xE9l\xE9charger le guide d\u2019utilisation",
    "T\xE9l\xE9charger ton CV favori": "T\xE9l\xE9charger your CV favori",
    "T\xE9l\xE9charger votre CV favori": "T\xE9l\xE9charger votre CV favori",
    Téléphone: "T\xE9l\xE9phone",
    Témoignages: "T\xE9moignages",
    URGENT: "URGENT",
    "URL LinkedIn": "URL LinkedIn",
    Un: "Un",
    "Un Account Manager sp\xE9cialis\xE9 sur votre secteur vous rappelle pour pr\xE9ciser votre besoin":
      "Un Account Manager vous rappelle pour pr\xE9ciser votre besoin",
    "Un Key Account Manager d\xE9di\xE9": "Un Key Account Manager d\xE9di\xE9",
    "Un Key Account Manager d\xE9di\xE9 vous guidera dans vos projets":
      "Un Key Account Manager d\xE9di\xE9 vous guidera dans vos projets",
    "Un accompagnement personnalis\xE9 par nos \xE9quipes":
      "Un accompagnement personnalis\xE9 par nos \xE9quipes",
    "Un accompagnement<0/>personnalis\xE9 par nos \xE9quipes":
      "Un accompagnement<0/>personnalis\xE9 par nos \xE9quipes",
    "Un community specialist vous appellera pour compl\xE9ter votre profil.":
      "Un Talent community specialist vous appellera pour compl\xE9ter votre profil.",
    "Un compte a d\xE9j\xE0 \xE9t\xE9 cr\xE9\xE9 avec cet email":
      "Un compte a d\xE9j\xE0 \xE9t\xE9 cr\xE9\xE9 avec cet email",
    "Un conseiller vous appelera pour compl\xE9ter votre profil.":
      "Un conseiller vous appellera pour compl\xE9ter votre profil.",
    "Un cv actualis\xE9 au format Word facilitera les \xE9changes avec nos \xE9quipes":
      "Un CV actualis\xE9 au format Word facilitera les \xE9changes avec nos \xE9quipes",
    "Un e-mail de validation vous a \xE9t\xE9 envoy\xE9 pour v\xE9rifier votre compte.":
      "Un e-mail de validation vous a \xE9t\xE9 envoy\xE9 pour v\xE9rifier votre compte.",
    "Un intitul\xE9 de poste": "Un intitul\xE9 de poste clair",
    "Un probleme est survenu, veuillez essayer plus tard":
      "Un probl\xE8me est survenu, veuillez essayer plus tard",
    "Un probl\xE8me est survenu": "Un probl\xE8me est survenu",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de besoin":
      "Un probl\xE8me est survenu lors de la cr\xE9ation de besoin",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de l'abonnement":
      "Un probl\xE8me est survenu lors de la cr\xE9ation de l'abonnement",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de l\u2019abonnement":
      "Un probl\xE8me est survenu lors de la cr\xE9ation de l\u2019abonnement",
    "Un probl\xE8me est survenu lors de la cr\xE9ation de votre candidature":
      "Un probl\xE8me est survenu lors de la cr\xE9ation de votre candidature",
    "Un probl\xE8me est survenu lors de la cr\xE9ation d\u2019alerte":
      "Un probl\xE8me est survenu lors de la cr\xE9ation d\u2019alerte",
    "Un responsable de compte vous recontactera":
      "Un responsable de compte vous recontactera",
    "Un r\xE9seau de partenaires pour notre communaut\xE9":
      "Un r\xE9seau de partenaires pour notre communaut\xE9",
    "Un seul article trouv\xE9": "Un seul article trouv\xE9",
    "Under Construction": "En cours de construction",
    "Une Solution compl\xE8te de talent management":
      "Une solution compl\xE8te de talent management digitalis\xE9e",
    "Une approche unique": "Une approche unique",
    "Une communaut\xE9 au coeur de notre service.":
      "Notre communaut\xE9, au c\u0153ur de notre service",
    "Une double expertise": "Une double expertise",
    "Une erreur est survenue, veuillez r\xE9essayer ult\xE9rieurement":
      "Une erreur est survenue, veuillez r\xE9essayer ult\xE9rieurement",
    "Une erreur s\u2019est produite, veuillez r\xE9essayer":
      "Une erreur s\u2019est produite, veuillez r\xE9essayer",
    "Une fois le candidat choisi, nous vous accompagnons dans le process d\u2019onboarding et tout au long de la mission":
      "Une fois le candidat choisi, nous vous accompagnons dans le process d\u2019onboarding et tout au long de la mission",
    "Une fois le timesheet rejet\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.":
      "Une fois le timesheet rejet\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.",
    "Une fois le timesheet valid\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.":
      "Une fois le timesheet valid\xE9, vous ne pourrez plus revenir en arri\xE8re. Merci de v\xE9rifier les informations suivantes avec attention.",
    "Une organisation agile": "Une organisation agile",
    "Une puissante Market Intelligence": "Une puissante Market Intelligence",
    "Une recherche trop pr\xE9cise peut vous faire passer \xE0 c\xF4t\xE9 d\u2019une opportunit\xE9 en or. Ces informations nous aident \xE9galement \xE0 vous recommander de nouveaux projets.":
      "Une recherche trop pr\xE9cise peut vous faire passer \xE0 c\xF4t\xE9 d\u2019une opportunit\xE9 en or. Ces informations nous aident \xE9galement \xE0 vous recommander de nouveaux projets.",
    "Une shortlist des meilleurs candidats pour tous vos besoins IT":
      "Une shortlist des meilleurs candidats pour tous vos besoins IT",
    "Une s\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil":
      "Une s\xE9lection d\u2019offres adapt\xE9es \xE0 votre profil",
    "Une s\xE9lection d\u2019offres<0/>adapt\xE9es \xE0 votre profil":
      "Une s\xE9lection d\u2019offres<0/>adapt\xE9es \xE0 votre profil",
    "Une vision 360 du march\xE9": "Une vision 360 du march\xE9",
    "Uploader ma fiche de poste": "Charger ma fiche de poste",
    "Uploader mon CV": "Charger mon CV",
    "Uploader mon CV<0><1/><2>Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)</2><3/></0>":
      "Uploader mon CV<0><1/><2>Importez un CV au format .docx, . doc ou . pdf. Vos donn\xE9es seront automatiquement extraites pour compl\xE9ter rapidement votre profil (recommand\xE9)</2><3/></0>",
    "Utilisez cette case pour indiquer des heures suppl\xE9mentaires ou un tarif major\xE9 \xE0 votre client et \xE0 notre service de facturation":
      "Utilisez cette case pour indiquer des heures suppl\xE9mentaires ou un tarif major\xE9 \xE0 votre client et \xE0 notre service de facturation",
    Valider: "Valider",
    "Valider le timesheet": "Valider le timesheet",
    Validés: "Valid\xE9s",
    "Veillez entrer un email valide": "Veillez entrer un email valide",
    "Veuillez accepter la politique de confidentialit\xE9":
      "Veuillez accepter la politique de confidentialit\xE9",
    "Veuillez accepter notre politique de confidentialit\xE9":
      "Veuillez accepter notre politique de confidentialit\xE9",
    "Veuillez ajouter une d\xE9scription de la mission":
      "Veuillez ajouter une description de la mission",
    "Veuillez enregistrer avant de passer \xE0 l\u2019\xE9tape suivante":
      "Veuillez enregistrer avant de passer \xE0 l\u2019\xE9tape suivante",
    "Veuillez entrer une address valide": "Veuillez entrer une adresse valide",
    "Veuillez entrer votre nom": "Veuillez entrer votre nom",
    "Veuillez entrer votre pr\xE9nom": "Veuillez entrer votre pr\xE9nom",
    "Veuillez indiquer votre localisation":
      "Veuillez indiquer votre localisation",
    "Veuillez noter que ce commentaire sera visible par le consultant":
      "Veuillez noter que ce commentaire sera visible par le consultant",
    "Veuillez selectionner au moin un filtre pour cr\xE9er une alerte":
      "Veuillez s\xE9lectionner au moin un filtre pour cr\xE9er une alerte",
    "Veuillez valider la ReCaptcha": "Veuillez valider la ReCaptcha",
    "Veuillez valider la certification ouverte avant l\u2019enregistrement":
      "Veuillez valider la certification ouverte avant l\u2019enregistrement",
    "Veuillez valider la formation ouverte avant l\u2019enregistrement":
      "Veuillez valider la formation ouverte avant l\u2019enregistrement",
    "Veuillez valider l\u2019exp\xE9rience ouverte avant l\u2019enregistrement":
      "Veuillez valider l\u2019exp\xE9rience ouverte avant l\u2019enregistrement",
    Vidéos: "Vid\xE9os",
    Ville: "Ville",
    "Ville de r\xE9sidence": "Ville de r\xE9sidence",
    "Voir Mon CV": "Voir mon CV",
    "Voir la FAQ": "Voir la FAQ",
    "Voir la video": "Voir la vid\xE9o",
    "Voir le Timesheet": "Voir le Timesheet",
    "Voir les mod\xE8les": "Voir les mod\xE8les",
    "Voir les offres": "Voir les offres",
    "Voir les ressources": "Voir les ressources",
    "Voir l\u2019offre": "Voir l\u2019offre",
    "Voir plus": "Voir plus",
    "Voir plus de vid\xE9os": "Voir plus de vid\xE9os",
    "Voir plus d\u2019articles": "Voir plus d\u2019articles",
    "Voir ses offres": "Voir ses offres",
    "Voir tous les talents": "Voir tous les talents",
    "Voir toutes les offres en cours": "Voir toutes les offres en cours",
    "Voir toutes les talents": "Voir tous les talents",
    "Voir toutes mes missions": "Voir toutes mes offres recommand\xE9es",
    "Vos coordonn\xE9es nous permettront de vous contacter d\xE8s lors qu\u2019une opportunit\xE9 correspond \xE0 votre profil.":
      "Vos coordonn\xE9es nous permettront de vous contacter d\xE8s lors qu\u2019une opportunit\xE9 correspond \xE0 votre profil.",
    "Vos donn\xE9es LinkedIn ont \xE9t\xE9 import\xE9es avec succ\xE8s. Veuillez les enregistrer.":
      "Vos donn\xE9es LinkedIn ont \xE9t\xE9 import\xE9es avec succ\xE8s. Veuillez les enregistrer.",
    "Votre CV est en cours de g\xE9n\xE9ration . . .":
      "Votre CV est en cours de g\xE9n\xE9ration . . .",
    "Votre CV favori sera accessible directement depuis votre Dashboard":
      "Votre CV favori sera accessible directement depuis votre Dashboard",
    "Votre Cv Favori sera accessible directement depuis votre Dashboard":
      "Votre Cv Favori sera accessible directement depuis votre Dashboard",
    "Votre Talent Community Specialist vous rappelle pour comprendre vos besoins":
      "Un Talent Community Specialist vous rappelle pour pr\xE9ciser votre recherche ",
    "Votre Timesheet du mois n\u2019est pas ouvert?":
      "Votre Timesheet du mois n\u2019est pas ouvert ?",
    "Votre dashboard est en cours de cr\xE9ation et sera accessible dans quelques instants.":
      "Votre dashboard est en cours de cr\xE9ation et sera accessible dans quelques instants.",
    "Votre demande a bien \xE9t\xE9 envoy\xE9e pour validation!":
      "Votre demande a bien \xE9t\xE9 envoy\xE9e pour validation !",
    "Votre domaine d\u2019expertise": "Domaine d\u2019expertise",
    "Votre message": "Votre message",
    "Votre profil est complet \xE0 {0}%, Pour g\xE9n\xE9rer votre CV Club Freelance, terminez de remplir les informations indispensables.": function(
      a
    ) {
      return [
        "Votre profil est complet \xE0 ",
        a("0"),
        "%, Pour g\xE9n\xE9rer votre CV Club Freelance, terminez de remplir les informations indispensables."
      ];
    },
    "Votre profil est complet \xE0 {0}%, Pour g\xE9n\xE9rer votre CV Mindquest, terminez de remplir les informations indispensables.": function(
      a
    ) {
      return [
        "Votre profil est complet \xE0 ",
        a("0"),
        "%, Pour g\xE9n\xE9rer votre CV Mindquest, terminez de remplir les informations indispensables."
      ];
    },
    "Votre profil est presque complet. Un profil compl\xE9t\xE9 \xE0 100% permettra \xE0 nos recruteurs de vous proposer les offres les plus adapt\xE9s.":
      "Votre profil est presque complet. Un profil compl\xE9t\xE9 \xE0 100% permettra \xE0 nos recruteurs de vous proposer les offres les plus adapt\xE9s.",
    "Votre profil est rempli \xE0": "Votre profil est rempli \xE0",
    "Votre profil est rempli \xE0 {0}%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance.": function(
      a
    ) {
      return [
        "Votre profil est rempli \xE0 ",
        a("0"),
        "%, compl\xE9tez-le pour g\xE9n\xE9rer votre CV Club Freelance."
      ];
    },
    "Votre statut": "Votre statut",
    "Votre token est expir\xE9 ou invalide":
      "Votre token est expir\xE9 ou invalide",
    "Voulez vous contribuer?": "Voulez-vous contribuer ?",
    "Voulez-vous ajouter des informations sur votre projet?":
      "Voulez-vous ajouter des informations sur votre projet?",
    "Vous avez d\xE9j\xE0 postul\xE9 pour cette mission":
      "Vous avez d\xE9j\xE0 postul\xE9 pour cette mission",
    "Vous avez finalis\xE9 votre inscription":
      "Vous avez finalis\xE9 votre inscription",
    "Vous avez oubli\xE9 d\u2019\xE9crire votre message !":
      "Vous avez oubli\xE9 d\u2019\xE9crire votre message !",
    "Vous avez un besoin informatique ? Vous cherchez une mission ? N\u2019h\xE9sitez pas \xE0 nous contacter.":
      "Vous avez un besoin en comp\xE9tences informatiques ? Vous cherchez une mission ? N\u2019h\xE9sitez pas \xE0 nous contacter.",
    "Vous cr\xE9ez votre profil Club Freelance en seulement 3 minutes":
      "Vous cr\xE9ez votre profil Club Freelance en seulement 3 minutes",
    "Vous cr\xE9ez votre profil et acc\xE9dez \xE0 votre espace personnel pour poster votre premier besoin, en seulement quelques minutes":
      "Vous cr\xE9ez votre profil et acc\xE9dez \xE0 votre espace client pour poster votre premier besoin, en seulement quelques quelques minutes",
    "Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin":
      "Vous devez vous connecter avec un compte entreprise pour pouvoir poster un besoin",
    "Vous devez vous connecter avec un compte entreprise pour continuer":
      "Vous devez vous connecter avec un compte entreprise pour continuer",
    "Vous devez vous connecter avec un compte entreprise pour continuer.":
      "Vous devez vous connecter avec un compte entreprise pour continuer.",
    "Vous devez vous connecter pour pouvoir postuler":
      "Vous devez vous connecter pour pouvoir postuler",
    "Vous n'avez pas re\xE7u votre code ?":
      "Vous n'avez pas re\xE7u votre code ?",
    "Vous ne pouvez pas ajouter une exp\xE9rience vide":
      "Vous ne pouvez pas ajouter une exp\xE9rience vide",
    "Vous ne pouvez pas d\xE9placer la carte vers cette colonne":
      "Vous ne pouvez pas d\xE9placer la carte vers cette colonne",
    "Vous ne pouvez pas modifier cette opportunit\xE9":
      "Vous ne pouvez pas modifier cette opportunit\xE9",
    'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Continuer"':
      'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Continuer"',
    'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Suivant"':
      'Vous pourrez ajouter un commentaire apr\xE8s avoir cliqu\xE9 sur "Suivant"',
    "Vous pouvez d\xE9sormais remplir votre Timesheet {m}/{y} du contrat num\xE9ro: {pID}": function(
      a
    ) {
      return [
        "Vous pouvez d\xE9sormais remplir votre Timesheet ",
        a("m"),
        "/",
        a("y"),
        " du contrat num\xE9ro: ",
        a("pID")
      ];
    },
    "Vous pouvez g\xE9rer l\u2019ensemble du process de s\xE9lection et les entretiens via votre espace client, et en mode collaboratif avec votre \xE9quipe":
      "Vous pouvez g\xE9rer l\u2019ensemble du process de s\xE9lection et les entretiens via votre espace personnel, et en mode collaboratif avec votre \xE9quipe",
    "Vous pouvez maintenant suivre le candidat":
      "Vous pouvez maintenant suivre le candidat",
    "Vous pouvez maintenant suivre le candidat {candidateBhId}": function(a) {
      return ["Vous pouvez maintenant suivre le candidat ", a("candidateBhId")];
    },
    "Vous pouvez maintenant suivre le candidat {id}": function(a) {
      return ["Vous pouvez maintenant suivre le candidat ", a("id")];
    },
    "Vous pouvez suivre vos candidatures dans votre profil":
      "Vous pouvez suivre vos candidatures dans votre profil",
    "Vous pouvez voir votre alerte dans votre profil":
      "Retrouvez et modifiez votre alerte dans votre profil",
    "Vous recevez des offres adapt\xE9es \xE0 vos crit\xE8res":
      "Vous recevez des offres adapt\xE9es \xE0 vos crit\xE8res",
    "Vous recevez une shortlist de candidats qualifi\xE9s (en moyenne sous 48h)":
      "Vous recevez une shortlist de candidats qualifi\xE9s (en moyenne sous 48h)",
    "Vous recherchez des profils de consultants IT exp\xE9riment\xE9s ? Trouvez les d\xE8s maintenant avec notre algorithme de matching SMATCH":
      "Vous recherchez des profils de consultants IT exp\xE9riment\xE9s ? Trouvez les d\xE8s maintenant avec notre algorithme de matching SMATCH",
    "Vous risquez de perdre vos donn\xE9es":
      "Vous risquez de perdre vos donn\xE9es",
    "Vous serez redig\xE9 automatiquement vers votre dashboard.":
      "Vous serez redig\xE9 automatiquement vers votre dashboard",
    "Vous souhaitez coopter quelqu\u2019un de votre entourage ?":
      "Vous souhaitez coopter quelqu\u2019un de votre entourage ?",
    "Vous souhaitez en savoir plus sur notre fonctionnement ?":
      "Vous souhaitez recevoir des profils de consultants IT exp\xE9riment\xE9s ?",
    "Vous souhaitez rejoindre Club Freelance ?":
      "Vous souhaitez rejoindre Club Freelance ?",
    "Vous souhaitez \xEAtre accompagn\xE9(e) en portage salarial ? D\xE9couvrez les solutions de nos partenaires.":
      "Vous souhaitez \xEAtre accompagn\xE9(e) en portage salarial ? D\xE9couvrez les solutions de nos partenaires.",
    "Vous suivez vos candidatures dans votre espace personel":
      "Vous suivez vos candidatures dans votre espace personel",
    "Vous \xEAtes d\xE9sormais abonn\xE9 aux Mission Control Center":
      "Vous \xEAtes d\xE9sormais abonn\xE9 aux Mission Control Center",
    "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Club Freelance":
      "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Club Freelance",
    "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Mindquest":
      "Vous \xEAtes d\xE9sormais abonn\xE9 \xE0 la newsletter de Mindquest",
    "Vous \xEAtes freelance ?": "Vous \xEAtes freelance ?",
    "Vous \xEAtes sur le point de soumettre un Timesheet vide.":
      "Vous \xEAtes sur le point de soumettre un Timesheet vide.",
    "Vous \xEAtes sur le point de soumettre votre Timesheet {0} au client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le modifier. Merci de v\xE9rifier vos informations avant l\u2019envoi.": function(
      a
    ) {
      return [
        "Vous \xEAtes sur le point de soumettre votre Timesheet ",
        a("0"),
        " au client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le modifier. Merci de v\xE9rifier vos informations avant l\u2019envoi."
      ];
    },
    "Vous \xEAtes sur le point de soumettre votre Timesheet {0} client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le le modifier. Merci de v\xE9rifier vos in formations avant l\u2019envoi.": function(
      a
    ) {
      return [
        "Vous \xEAtes sur le point de soumettre votre Timesheet ",
        a("0"),
        " client. Une fois le Timesheet valid\xE9, vous ne pourrez plus le le modifier. Merci de v\xE9rifier vos in formations avant l\u2019envoi."
      ];
    },
    "Want to join Mindquest ?": "Envie de rejoindre Mindquest ?",
    "We also receive personal data about Candidates from other sources. Depending on the relevant circumstances and applicable local laws and requirements, these may include personal data received in the following situations:":
      "We also receive personal data about Candidates from other sources. Depending on the relevant circumstances and applicable local laws and requirements, these may include personal data received in the following situations:",
    "We are committed to ensuring that when we collect and use information about Website Users, our Candidates and Clients, we do so in accordance with all data protection legislation including the GDPR.":
      "We are committed to ensuring that when we collect and use information about Website Users, our Candidates and Clients, we do so in accordance with all data protection legislation including the GDPR.",
    "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Club Freelance, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.":
      "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Club Freelance, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.",
    "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Mindquest, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.":
      "We collect your data automatically via cookies, in line with cookie settings in your browser. If you are also a Candidate of Mindquest, we may use data from your use of our websites to enhance other aspects of our communications with or service to you.",
    "We collect your personal data in two primary ways:":
      "We collect your personal data in two primary ways:",
    "We do not much collect information about Clients. We generally only need to have your contact details or the details of individual contacts at your organisation (such as their names, telephone numbers and email addresses) to enable us to provide our services to you and to ensure that our relationship runs smoothly.":
      "We do not much collect information about Clients. We generally only need to have your contact details or the details of individual contacts at your organisation (such as their names, telephone numbers and email addresses) to enable us to provide our services to you and to ensure that our relationship runs smoothly.",
    "We have put in place several technical and organisational measures aimed at protecting the confidentiality, the integrity and the availability of your personal data.":
      "We have put in place several technical and organisational measures aimed at protecting the confidentiality, the integrity and the availability of your personal data.",
    "We may also disclose your personal information to third parties:":
      "We may also disclose your personal information to third parties:",
    "We may collect, store, and use the following categories of personal information about you:":
      "We may collect, store, and use the following categories of personal information about you:",
    "We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.":
      "We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.",
    "We may obtain data about you from cookies. These are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Cookies also enable us to deliver more personalised content.":
      "We may obtain data about you from cookies. These are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Cookies also enable us to deliver more personalised content.",
    "We may obtain information about you from searching for potential Candidates from third party sources, such as LinkedIn and other job sites;":
      "We may obtain information about you from searching for potential Candidates from third party sources, such as LinkedIn and other job sites;",
    "We may obtain this information from you, from our Client or from public sources and this includes:":
      "We may obtain this information from you, from our Client or from public sources and this includes:",
    "We may send you updates and information about products and services which may be of interest to you. If you have consented to receive marketing, you may opt out at a later date. You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please unsubscribe from our next email.":
      "We may send you updates and information about products and services which may be of interest to you. If you have consented to receive marketing, you may opt out at a later date. You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please unsubscribe from our next email.",
    "We only bill if you find your freelancer": "Facturation au succ\xE8s",
    "We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.":
      "We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.",
    "We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests (or other appropriate legal basis) and that you are happy for us to do so. Accordingly, we run data routines to remove data that we no longer have a need to retain.":
      "We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests (or other appropriate legal basis) and that you are happy for us to do so. Accordingly, we run data routines to remove data that we no longer have a need to retain.",
    "We use your data to help us to improve your experience of using our website, we may therefore for example launch a search function and may then analyse your recent job search criteria to help us to present jobs that we think you may be interested in.":
      "We use your data to help us to improve your experience of using our website, we may therefore for example launch a search function and may then analyse your recent job search criteria to help us to present jobs that we think you may be interested in.",
    "We will always ensure that there are adequate levels of protection and a similar legislation with regards to data protection. Each country might have different laws and regulations with regards to data protection. Overseas transfers outside the EEA will only be possible when we have the appropriate safeguards are in place.":
      "We will always ensure that there are adequate levels of protection and a similar legislation with regards to data protection. Each country might have different laws and regulations with regards to data protection. Overseas transfers outside the EEA will only be possible when we have the appropriate safeguards are in place.",
    "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:":
      "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:",
    "We will share your personal information with all entities in our group as part of our global recruitment activities, regular reporting activities on company performance, for system maintenance support and hosting of data.":
      "We will share your personal information with all entities in our group as part of our global recruitment activities, regular reporting activities on company performance, for system maintenance support and hosting of data.",
    "We will usually keep data for a maximum of 5 years and for full details of our retention strategy you can request it by contacting us at contact@club-freelance.com.":
      "We will usually keep data for a maximum of 5 years and for full details of our retention strategy you can request it by contacting us at contact@club-freelance.com.",
    "Webinars & Ressources": "Webinars & Ressources",
    "Website user": "Website user",
    "Website users": "Website users",
    "What are our legitimate business interests in collecting and retaining your personal data?":
      "What are our legitimate business interests in collecting and retaining your personal data?",
    "What kind of Personal Data do we collect?":
      "What kind of Personal Data do we collect?",
    "What measures we put in place to safeguard your personal data?":
      "What measures we put in place to safeguard your personal data?",
    "What we may need from you": "What we may need from you",
    "When you visit our website, there is certain information that we may automatically collect, whether or not you decide to use our services. This includes your IP address, the date and the times and frequency with which you access the website and the way you browse its content. We will also collect data from you when you contact us via the website.":
      "When you visit our website, there is certain information that we may automatically collect, whether or not you decide to use our services. This includes your IP address, the date and the times and frequency with which you access the website and the way you browse its content. We will also collect data from you when you contact us via the website.",
    "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Club Freelance and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Club Freelance will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.":
      "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Club Freelance and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Club Freelance will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.",
    "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Mindquest and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Mindquest will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.":
      "Where we have obtained your consent to process your personal data for certain activities (for example, for our marketing arrangements or automatic profiling), you may withdraw this consent at any time by contacting Mindquest and we will cease to carry out the particular activity that you previously consented. There may be circumstances where Mindquest will still need to process your data for legal or official reasons. Where this is the case, we will tell you and we will restrict the data to only what is necessary for those specific reasons.",
    "Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you for the purposes of recruitment services. In this case, we may be unable to provide the recruitment services you have requested but we will notify you if this is the case at the time.":
      "Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you for the purposes of recruitment services. In this case, we may be unable to provide the recruitment services you have requested but we will notify you if this is the case at the time.",
    "Who do we share your personal data with?":
      "Who do we share your personal data with?",
    "You have the right to make a complaint at any time to a supervisory body which in the UK is the Information Commissioner\u2019s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.":
      "You have the right to make a complaint at any time to a supervisory body which in the UK is the Information Commissioner\u2019s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.",
    "You have the right to request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.":
      "You have the right to request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.",
    "You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.":
      "You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.",
    "Your referees may disclose personal information about you;":
      "Your referees may disclose personal information about you;",
    "Zone g\xE9ographique souhait\xE9e": "Zone g\xE9ographique souhait\xE9e",
    "Zones g\xE9ographiques souhait\xE9es":
      "Zones g\xE9ographiques souhait\xE9es",
    actualité: "Actualit\xE9",
    "adresse email": "adresse email",
    "ajout d'un nouveau signataire principal":
      "ajout d'un nouveau signataire principal",
    "ajout d'un nouveau signataire secondaire":
      "ajout d'un nouveau signataire secondaire",
    "ajoutez l'email du signataire principal...":
      "ajoutez l'email du signataire principal...",
    "ajoutez l'email du signataire secondaire...":
      "ajoutez l'email du signataire secondaire...",
    "ajoutez un commentaire...": "ajoutez un commentaire...",
    "analytics and search engine providers that assist us in the improvement and optimisation of our site;":
      "analytics and search engine providers that assist us in the improvement and optimisation of our site;",
    août: "ao\xFBt",
    au: "au",
    "aucun r\xE9sultat ne correspond \xE0 vos crit\xE8res de recherche":
      "aucun r\xE9sultat ne correspond \xE0 vos crit\xE8res de recherche",
    "aucun timesheet disponible": "aucun timesheet disponible",
    avril: "avril",
    "blog, Mindquest, Conseils pratiques, consultant IT, freelance informatique":
      "blog, Mindquest, Conseils pratiques, consultant IT, freelance informatique",
    "blog, Mindquest, Conseils pratiques, entreprise IT, freelance informatique":
      "blog, Mindquest, Conseils pratiques, entreprise IT, freelance informatique",
    "blog, Mindquest, D\xE9cryptages": "blog, Mindquest, D\xE9cryptages",
    "blog, Mindquest, Success stories, freelance informatique":
      "blog, Mindquest, Success stories, freelance informatique",
    "blog, Mindquest, actualit\xE9s, freelance informatique, actualit\xE9 freelance":
      "blog, Mindquest, actualit\xE9s, freelance informatique, actualit\xE9 freelance",
    "blog, Mindquest, recherche, freelance informatique":
      "blog, Mindquest, recherche, freelance informatique",
    "blog, Mindquest, talents IT, actualit\xE9s, freelances IT":
      "blog, Mindquest, talents IT, actualit\xE9s, talents IT",
    "blog, club freelance, Conseils pratiques, consultant IT, freelance informatique":
      "blog, club freelance, Conseils pratiques, consultant IT, freelance informatique",
    "blog, club freelance, Conseils pratiques, entreprise IT, freelance informatique":
      "blog, club freelance, Conseils pratiques, entreprise IT, freelance informatique",
    "blog, club freelance, D\xE9cryptages":
      "blog, club freelance, D\xE9cryptages",
    "blog, club freelance, Success stories, freelance informatique":
      "blog, club freelance, Success stories, freelance informatique",
    "blog, club freelance, actualit\xE9s, freelance informatique, actualit\xE9 freelance":
      "blog, club freelance, actualit\xE9s, freelance informatique, actualit\xE9 freelance",
    "blog, club freelance, recherche, freelance informatique":
      "blog, club freelance, recherche, freelance informatique",
    "blog, club freelance, talents IT, actualit\xE9s, freelances IT":
      "blog, club freelance, talents IT, actualit\xE9s, freelances IT",
    candidat: "Candidat",
    "ces mots cl\xE9s seront pris en compte lors de la prochaine mise \xE0 jour.":
      "ces mots cl\xE9s seront pris en compte lors de la prochaine mise \xE0 jour.",
    "champ requis": "champ requis",
    "chercher par id, consultant": "chercher par id, consultant",
    "chercher par id, entreprise ou signataire":
      "chercher par id, entreprise ou signataire",
    "clients, business partners, suppliers and sub-contractors for the performance and compliance with obligations of any contract we enter into with them or you;":
      "clients, business partners, suppliers and sub-contractors for the performance and compliance with obligations of any contract we enter into with them or you;",
    "club freelance, privacy policy": "club freelance, privacy policy",
    "code de confirmation": "code de confirmation",
    "code invalide": "code invalide",
    "conseils pratiques": "Conseils pratiques",
    consultant: "Consultant",
    "contact, Mindquest, freelance informatique":
      "contact, Mindquest, freelance informatique",
    "contact, club freelance, freelance informatique":
      "contact, club freelance, freelance informatique",
    "credit reference agencies, our insurance broker, compliance partners and other sub-contractors for the purpose of assessing your suitability for a role where this is a condition of us entering into a contract with you.":
      "credit reference agencies, our insurance broker, compliance partners and other sub-contractors for the purpose of assessing your suitability for a role where this is a condition of us entering into a contract with you.",
    "c\u2019est le seul moyen de vous rejoindre!":
      "C\u2019est le seul moyen de vous joindre !",
    "dashboard, Mindquest, entreprise, talents, recrutement,":
      "dashboard, Mindquest, entreprise, talents, recrutement,",
    "dashboard, club freelance, entreprise, talents, recrutement,":
      "dashboard, club freelance, entreprise, talents, recrutement,",
    "de ventes": "de ventes",
    disponibilite: "disponibilit\xE9",
    "disponibilit\xE9 \xE0 confirmer": "disponibilit\xE9 \xE0 confirmer",
    "disponible dans un jour": "disponible dans un jour",
    "disponible dans {diffDays} jours": function(a) {
      return ["disponible dans ", a("diffDays"), " jours"];
    },
    "disponible dans {diffMonths} mois": function(a) {
      return ["disponible dans ", a("diffMonths"), " mois"];
    },
    "disponible dans {diffYears} ans": function(a) {
      return ["disponible dans ", a("diffYears"), " ans"];
    },
    "disponible imm\xE9diatement": "disponible imm\xE9diatement",
    décembre: "d\xE9cembre",
    décryptages: "d\xE9cryptages",
    "email invalide": "Email invalide",
    "en attente": "en attente",
    entreprise: "entreprise",
    "entrer la taille de votre entreprise":
      "S\xE9lectionnez la taille de votre entreprise",
    "entrer votre nom": "Entrez votre nom",
    "entrer votre pr\xE9nom": "Entrez votre pr\xE9nom",
    "entrez l\u2019email de r\xE9ception": "Votre email",
    "envoi du feuille de temps": "envoi du feuille de temps",
    "et trouvez votre prochaine mission IT":
      "et trouvez votre prochaine mission IT",
    "euros / jour": "euros / jour",
    fermée: "ferm\xE9e",
    filters: "filters",
    fr: "fr",
    février: "f\xE9vrier",
    heures: "heures",
    "https://monsite.fr": "https://monsite.fr",
    "https://www.linkedin.com/in/user/": "https://www.linkedin.com/in/user/",
    "il n\u2019y a pas d\u2019historique disponible pour le moment":
      "il n\u2019y a pas d\u2019historique disponible pour le moment",
    inconnue: "inconnue",
    indéterminé: "ind\xE9termin\xE9",
    "information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), pages you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs).":
      "information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), pages you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs).",
    "informatique et consultants IT": "informatique et consultants IT",
    "int\xE9ress\xE9 par ce candidat": "int\xE9ress\xE9 par ce candidat",
    j: "j",
    "j'ai compris": "j'ai compris",
    janvier: "janvier",
    "jj/mm/aaaa": "jj/mm/aaaa",
    jours: "jours",
    juillet: "juillet",
    juin: "juin",
    "l'ajout du nouveau signataire secondaire a \xE9t\xE9 effectu\xE9 avec succ\xE8s":
      "l'ajout du nouveau signataire secondaire a \xE9t\xE9 effectu\xE9 avec succ\xE8s",
    "la feuille de temps a \xE9t\xE9 soumise au client pour validation":
      "la feuille de temps a \xE9t\xE9 soumise au client pour validation",
    "la feuille de temps est enregistr\xE9e en brouillon, vous pouvez la modifier \xE0 tout moment":
      "la feuille de temps est enregistr\xE9e en brouillon, vous pouvez la modifier \xE0 tout moment",
    "la feuille de temps est rejet\xE9e": "la feuille de temps est rejet\xE9e",
    "la feuille de temps est valid\xE9e": "la feuille de temps est valid\xE9e",
    "la feuille de temps n'a pas \xE9t\xE9 enregistr\xE9e":
      "la feuille de temps n'a pas \xE9t\xE9 enregistr\xE9e",
    "la suppression du signataire secondaire est faite avec succ\xE8s":
      "la suppression du signataire secondaire est faite avec succ\xE8s",
    "le fichier vous a \xE9t\xE9 envoy\xE9 par email":
      "Le fichier vous a \xE9t\xE9 envoy\xE9 par email",
    "le probl\xE8me sera bient\xF4t bient\xF4t r\xE9solu":
      "le probl\xE8me sera bient\xF4t bient\xF4t r\xE9solu",
    "le probl\xE8me sera bient\xF4t r\xE9solu":
      "Le probl\xE8me sera bient\xF4t r\xE9solu",
    "login or password invalid": "login or password invalid",
    "login ou mot de passe invalide": "login ou mot de passe invalide",
    "login, Mindquest, account, connexion":
      "login, Mindquest, account, connexion",
    "login, club freelance, account, connexion":
      "login, club freelance, account, connexion",
    "l\u2019ajout d\u2019un nouveau signataire va ecraser l\u2019ancien..":
      "l\u2019ajout d\u2019un nouveau signataire va ecraser l\u2019ancien..",
    mai: "mai",
    mars: "mars",
    missions: "missions",
    "missions freelance, Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle":
      "missions freelance, Data, D\xE9veloppement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle",
    "missions, Talent Community Specialist, Freelance informatique":
      "missions, Talent Community Specialist, Freelance informatique",
    mois: "mois",
    "monemail@email.com": "monemail@email.com",
    "monemail@mail.com": "monemail@mail.com",
    normal: "normal",
    novembre: "novembre",
    "num\xE9ro de t\xE9l\xE9phone non valide":
      "num\xE9ro de t\xE9l\xE9phone non valide",
    octobre: "octobre",
    optionnel: "optionnel",
    ou: "ou",
    "ou inscrivez-vous maintenant": "ou inscrivez-vous maintenant",
    ouvert: "ouvert",
    "par cat\xE9gorie: {0}": function(a) {
      return ["par cat\xE9gorie: ", a("0")];
    },
    "par email": "par email",
    "par tag: {0}": function(a) {
      return ["par tag: ", a("0")];
    },
    "plus d\u2019expertises": "plus d\u2019expertises",
    "politique de confidentialit\xE9": "politique de confidentialit\xE9",
    "politique de confidentialit\xE9 des donn\xE9es":
      "politique de confidentialit\xE9 des donn\xE9es",
    "pour poursuivre votre inscription.": "pour poursuivre votre inscription.",
    rechercher: "rechercher",
    "rejet du feuille de temps": "rejet du feuille de temps",
    rejeté: "rejet\xE9",
    rejetée: "rejet\xE9e",
    "revenir \xE0 la page d\u2019accueil":
      "Revenir \xE0 la page d\u2019accueil",
    "r\xE9essayer svp": "R\xE9essayez svp",
    résultats: "r\xE9sultats",
    "s'abonner": "s'abonner",
    "selon vos mots-cl\xE9s": "selon vos mots-cl\xE9s",
    septembre: "septembre",
    "sign up, Mindquest, communaut\xE9, compte, Freelances IT":
      "sign up, Mindquest, communaut\xE9, compte, talents IT",
    "sign up, club freelance, communaut\xE9, compte, Freelances IT":
      "sign up, club freelance, communaut\xE9, compte, Freelances IT",
    "signataire principal": "signataire principal",
    soumettre: "Soumettre",
    special: "sp\xE9cial",
    "subcontractors and other service providers including email marketing specialists, event organisers, payment and other financial service providers;":
      "subcontractors and other service providers including email marketing specialists, event organisers, payment and other financial service providers;",
    "suppression du signataire secondaire":
      "suppression du signataire secondaire",
    "techniques utilis\xE9s sur cette mission":
      "techniques utilis\xE9s sur cette mission",
    "tips and tricks": "tips and tricks",
    tous: "tous",
    "transport en commun": "transport en commun",
    type: "type",
    "un compte entreprise.": "un compte entreprise.",
    "user dashboard": "user dashboard",
    "validation du feuille de temps": "validation du feuille de temps",
    validé: "valid\xE9",
    "veuillez ajouter vos soft skills s\xE9par\xE9es par des virgules.":
      "veuillez ajouter vos soft skills s\xE9par\xE9es par des virgules.",
    voiture: "voiture",
    "votre compte est maintenant valid\xE9":
      "Votre compte est maintenant valid\xE9",
    "votre mot cl\xE9 doit contenir 3 caract\xE8res au minimum":
      "votre mot cl\xE9 doit contenir 3 caract\xE8res au minimum",
    "votre responsable de compte vous contactera dans les plus brefs d\xE9lais.":
      "Votre responsable de compte vous contactera dans les plus brefs d\xE9lais",
    "vous a \xE9t\xE9 envoy\xE9": "vous a \xE9t\xE9 envoy\xE9",
    "vous devez ajouter un commentaire": "vous devez ajouter un commentaire",
    "vous devez fixer les champs erron\xE9s avant":
      "vous devez fixer les champs erron\xE9s avant",
    "vous devez pr\xE9ciser la taille de votre entreprise":
      "Vous devez pr\xE9ciser la taille de votre entreprise",
    "vous devez pr\xE9ciser le code postal avant de lancer le matching":
      "vous devez pr\xE9ciser le code postal avant de lancer le matching",
    "vous devez pr\xE9ciser le rythme de travail":
      "Vous devez pr\xE9ciser le rythme de travail",
    "vous devez pr\xE9ciser le type du contrat":
      "Vous devez pr\xE9ciser le type de contrat",
    "vous devez s\xE9lectionner des mot-cl\xE9s avant de lancer le matching":
      "vous devez s\xE9lectionner des mot-cl\xE9s avant de lancer le matching",
    "warning!": "warning!",
    "{0, plural, one {# Article \xE0 lire} other {# Articles \xE0 lire}}": function(
      a
    ) {
      return [
        a("0", "plural", {
          one: ["#", " Article \xE0 lire"],
          other: ["#", " Articles \xE0 lire"]
        })
      ];
    },
    "{0, plural, one {# jour} other {# jours}}": function(a) {
      return [
        a("0", "plural", { one: ["#", " jour"], other: ["#", " jours"] })
      ];
    },
    "{0, plural, one {# mois} other {# mois}}": function(a) {
      return [a("0", "plural", { one: ["#", " mois"], other: ["#", " mois"] })];
    },
    "{0, plural, one {Resultat} other {resultats}}": function(a) {
      return [a("0", "plural", { one: "Resultat", other: "resultats" })];
    },
    "{0, plural, one {R\xE9sultat} other {r\xE9sultats}}": function(a) {
      return [a("0", "plural", { one: "R\xE9sultat", other: "r\xE9sultats" })];
    },
    "{0}": function(a) {
      return [a("0")];
    },
    "{btnText}": function(a) {
      return [a("btnText")];
    },
    "{candidateStatus}": function(a) {
      return [a("candidateStatus")];
    },
    "{days, plural, one {# jour} other {# jours}}": function(a) {
      return [
        a("days", "plural", { one: ["#", " jour"], other: ["#", " jours"] })
      ];
    },
    "{months, plural, one {# mois} other {# mois}}": function(a) {
      return [
        a("months", "plural", { one: ["#", " mois"], other: ["#", " mois"] })
      ];
    },
    "{nResults} r\xE9sultats sont trouv\xE9s": function(a) {
      return [a("nResults"), " r\xE9sultats sont trouv\xE9s"];
    },
    "\xC0 l\u2019\xE9tude": "\xC0 l\u2019\xE9tude",
    "\xC0 partir de {0}": function(a) {
      return ["\xC0 partir de ", a("0")];
    },
    "\xC0 plein temps": "\xC0 temps plein",
    "\xC0 temps partiel": "\xC0 temps partiel",
    "\xC0 t\xE9l\xE9charger gratuitement":
      "\xC0 t\xE9l\xE9charger gratuitement",
    "\xC9num\xE9rez toutes vos exp\xE9riences professionnelles pertinentes afin que nous puissions nous faire une id\xE9e plus pr\xE9cise de votre profil":
      "\xC9num\xE9rez toutes vos exp\xE9riences professionnelles pertinentes afin que nous puissions nous faire une id\xE9e plus pr\xE9cise de votre profil",
    "\xC9quipes innovation & support": "\xC9quipes innovation & support",
    "\xC9tape 1: Int\xE9grez votre fiche de poste":
      "\xC9tape 1: Int\xE9grez votre fiche de poste",
    "\xC9tape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues":
      "\xC9tape 2: S\xE9lectionnez vos mots-cl\xE9s IT dans les zones pr\xE9vues",
    "\xC9tape suivante": "\xC9tape suivante",
    "\xCAtes-vous s\xFBr de vouloir supprimer ce certificat?":
      "\xCAtes-vous s\xFBr de vouloir supprimer ce certificat?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette exp\xE9rience?":
      "\xCAtes-vous s\xFBr de vouloir supprimer cette exp\xE9rience?",
    "\xCAtes-vous s\xFBr de vouloir supprimer cette formation?":
      "\xCAtes-vous s\xFBr de vouloir supprimer cette formation?",
    "\xCAtre rappel\xE9 par un conseiller":
      "\xCAtre rappel\xE9 par un conseiller",
    à: "\xE0",
    "\xE0 partir de": "\xE0 partir de",
    "\xE0 partir du": "\xE0 partir du",
    "\u201CClub Freelance propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.\u201C":
      "\u201CClub Freelance propose un service simple et efficace, facilit\xE9 par un espace personnel virtuel et la possibilit\xE9 de g\xE9rer toute sa documentation en ligne. Pour moi, c\u2019est la mani\xE8re la plus simple pour \xE9mettre une facture et se faire payer. En temps et en heure.\u201C",
    "\u201CJe suis tr\xE8s satisfaite de Club Freelance. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able.\u201C":
      "\u201CJe suis tr\xE8s satisfaite de Club Freelance. Retour rapide sur la mission suite \xE0 un entretien, contrat envoy\xE9 dans la foul\xE9e et aucun probl\xE8me de facturation. L\u2019\xE9quipe est tr\xE8s disponible et la communication avec l\u2019ensemble des interlocuteurs est facile et agr\xE9able.\u201C",
    "\u201CLe service du Club Freelance est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.\u201C":
      "\u201CLe service du Club Freelance est rapide et efficace. Les membres de l\u2019\xE9quipe savent ce qu\u2019ils recherchent avec leurs partenaires: aucun contact n\u2019est destin\xE9 \xE0 cr\xE9er une base de donn\xE9es de CV mais \xE0 proposer des emplois int\xE9ressants. Je recommande fortement.\u201C"
  }
};
