import React, { PureComponent } from "react";
import { BlogPosterSkeleton, TextSkeleton } from "cf-neo-ui";
import classes from "./Loading.module.scss";

class Loading extends PureComponent {
  render() {
    return (
      <div className={classes.limitedBody}>
        <div className={classes.categories}>
          <span>
            <TextSkeleton />
          </span>
          <span>
            <TextSkeleton />
          </span>
        </div>
        <div className={classes.title}>
          <TextSkeleton heading />
        </div>
        <BlogPosterSkeleton className={classes.blogger} />
        <BlogPosterSkeleton className={classes.cover} />
        <section className={classes.body}>
          <div className={classes.text}>
            <TextSkeleton lines={4} />
          </div>
          <div className={classes.header}>
            <TextSkeleton heading />
          </div>
          <div className={classes.text}>
            <TextSkeleton lines={4} />
          </div>
          <div className={classes.text}>
            <TextSkeleton lines={3} />
          </div>
          <div className={classes.text}>
            <TextSkeleton lines={4} />
          </div>
        </section>
      </div>
    );
  }
}

export default Loading;
