import React, { Component } from "react";
import { Container, Row, Col, IllustrationIcons } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./Reassurance.module.scss";

class Reassurance extends Component {
  render() {
    return (
      <section className={classes.reassurance}>
        <Container>
          <Row>
            <Col xs={6} sm={3} md={3} lg={3}>
              <div className={classes.reasCol}>
                <div>
                  <IllustrationIcons
                    dimension={60}
                    type="search"
                    color="#b50921"
                    color2="#d3354a"
                  />
                </div>
                <div className={classes.text}>
                  <span>
                    <Trans>
                      Une sélection d’offres
                      <br />
                      adaptées à votre profil
                    </Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={6} sm={3} md={3} lg={3}>
              <div className={classes.reasCol}>
                <div>
                  <IllustrationIcons
                    dimension={60}
                    type="rocket"
                    color="#b50921"
                    color2="#d3354a"
                  />
                </div>
                <div className={classes.text}>
                  <span>
                    <Trans>
                      Rejoindre une communauté
                      <br />
                      de freelances
                    </Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={6} sm={3} md={3} lg={3}>
              <div className={classes.reasCol}>
                <div>
                  <IllustrationIcons
                    dimension={60}
                    type="hands"
                    color="#b50921"
                    color2="#d3354a"
                  />
                </div>
                <div className={classes.text}>
                  <span>
                    <Trans>
                      Un accompagnement
                      <br />
                      personnalisé par nos équipes
                    </Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={6} sm={3} md={3} lg={3}>
              <div className={classes.reasCol}>
                <div>
                  <IllustrationIcons
                    dimension={60}
                    type="stopwatch"
                    color="#b50921"
                    color2="#d3354a"
                  />
                </div>
                <div className={classes.text}>
                  <span>
                    <Trans>La garantie de paiement à 30 jours</Trans>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Reassurance;
