import { observable, action } from "mobx";

const defaultValues = {
  talentDropped: null,
  newParent: null,
  lastParent: null
};

export default class TalentStore {
  @observable talent;

  @observable talentDropped;

  @observable lastParent;

  @observable newParent;

  constructor(initialState = defaultValues) {
    this.talent = null;
    this.talentDropped = initialState.talentDropped || null;
    this.lastParent = initialState.lastParent || null;
    this.newParent = initialState.newParent || null;
  }

  @action
  changeTalent = talent => {
    this.talent = talent;
  };

  @action
  changeTalentDropped = talentDropped => {
    this.talentDropped = talentDropped;
  };

  @action
  changeLastParent = lastParent => {
    this.lastParent = lastParent;
  };

  @action
  changeNewParent = newParent => {
    this.newParent = newParent;
  };

  @action
  reset = () => {
    this.talentDropped = null;
    this.lastParent = null;
    this.newParent = null;
  };

  @action
  resetTalent = () => {
    this.talent = null;
  };
}
