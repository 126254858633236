import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Tabs, Tab } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import "./styles.scss";
import Routes from "../../layout/routes/index";
import MyProfileResumeUploader from "./CV/myProfileResumeUploader";
import MyProfileResumeDownloader from "./CV/myProfileResumeDownloader";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";

class myProfile extends Component {
  changeTabHandler(activeProfileTab) {
    const { history } = this.props;
    const routes = Routes.LpbLayoutRoutes;
    if (activeProfileTab === "upload")
      history.push(routes.MyProfileResumeUploader.path);
    if (activeProfileTab === "download")
      history.push(routes.MyProfileResumeDownloader.path);
  }

  render() {
    const { i18n, location } = this.props;
    const routes = Routes.LpbLayoutRoutes;
    let activeProfileTab = "upload";
    const currentPath = location.pathname;
    if (currentPath === routes.MyProfileResumeUploader.path)
      activeProfileTab = "upload";
    if (currentPath === routes.MyProfileResumeDownloader.path)
      activeProfileTab = "download";
    const meta = staticMetaTags(i18n).resume;
    return (
      <div>
        {metaTags(
          urls.myProfile(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <Tabs
          defaultActiveTab={activeProfileTab}
          onChange={activeProfileTab => this.changeTabHandler(activeProfileTab)}
        >
          <Tab name="upload" tab={i18n._(t`Ajouter mon CV`)}>
            <MyProfileResumeUploader />
          </Tab>
          <Tab name="download" tab={i18n._(t`Modèles de CV Club Freelance`)}>
            <MyProfileResumeDownloader />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

myProfile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(myProfile));
