import React, { Component } from "react";
import { withI18n } from "@lingui/react";

import SearchCandidatesHeader from "./SearchCandidatesHeader/SearchCandidatesHeader";
import SearchCandidatesBody from "./SearchCandidatesBody/SearchCandidatesBody";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import * as PropTypes from "prop-types";
class SearchCandidatesPage extends Component {
  componentDidMount() {
    if (typeof window !== "undefined") window.scroll(0, 0);
  }

  render() {
    const { i18n } = this.props;
    const meta = staticMetaTags(i18n).SearchCandidatesPage;
    return (
      <div>
        {metaTags(
          urls.SearchCandidatesPage(),
          meta.title,
          `${meta.description}`,
          meta.imageUrl,
          `${meta.keywords}`
        )}
        <SearchCandidatesHeader />
        <SearchCandidatesBody />
      </div>
    );
  }
}

SearchCandidatesPage.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(SearchCandidatesPage);
