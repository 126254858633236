import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";
import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import carouselConfig from "./CarouselConfigs";
import "./Slider.scss";
import decode from "../../../../utils/Utf8Text";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false
    };
  }

  componentDidMount() {
    this.setState({ render: true });
  }

  render() {
    const { slides } = this.props;
    const { render } = this.state;
    if (!render) return null;
    if (typeof window !== "undefined") {
      window.$ = $;
      window.jQuery = $;
    }
    /* eslint-disable global-require */
    const OwlCarousel = require("react-owl-carousel");
    /* eslint-enable global-require */
    return (
      <OwlCarousel
        {...carouselConfig}
        className="carousel_styling_featured_Blog_slider"
      >
        {slides.map(slide => (
          <div className="item" key={slide.id}>
            <div className="blog_featured_cards">
              <Link
                to={`/blog/news/${slide.id}`}
                style={{
                  backgroundImage: `url("${
                    slide.featured_image2_url
                      ? slide.featured_image2_url
                      : slide.featured_media && slide.featured_media.source_url
                      ? slide.featured_media.source_url
                      : "/public/cf.png"
                  }")`
                }}
              />
              <span>{decode(slide.title)}</span>
            </div>
          </div>
        ))}
      </OwlCarousel>
    );
  }
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      featured_media: PropTypes.shape({
        source_url: PropTypes.string
      })
    })
  ).isRequired
};

export default Slider;
