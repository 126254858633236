import { t } from "@lingui/macro";
import urls from "../../../utils/urls";

const ldJson = (i18n, id, name) => ({
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "blog",
      item: urls.blog()
    },
    {
      "@type": "ListItem",
      position: 2,
      name: i18n._(t`Ressources`),
      item: `${urls.blog()}/books`
    },
    {
      "@type": "ListItem",
      position: 3,
      name,
      item: urls.resources
    }
  ]
});

export default ldJson;
