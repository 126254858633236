import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { BlogPosterSkeleton } from "cf-neo-ui";
import classes from "./VideosDisplay.module.scss";

class LoadingVideosDisplay extends PureComponent {
  render() {
    const { cardsNumber } = this.props;
    const res = [];
    for (let i = 0; i < cardsNumber; i += 1)
      res.push(
        <BlogPosterSkeleton
          key={i}
          className={classes.videoCard}
          action="play"
        />
      );
    return <div className={classes.container}>{res}</div>;
  }
}

LoadingVideosDisplay.propTypes = {
  cardsNumber: PropTypes.number.isRequired
};

export default LoadingVideosDisplay;
