import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { BlogPoster } from "cf-neo-ui";

import $ from "jquery";
import carouselConfigs from "./CarouselConfigs";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Styles.scss";

class SugCarousel extends PureComponent {
  state = {
    render: false
  };

  componentDidMount() {
    this.setState({ render: true });
  }

  navigate = id => {
    const { history } = this.props;
    history.push(`/blog/news/${id}`);
  };

  carousel = () => {
    const { items } = this.props;
    const { render } = this.state;
    if (!render) return null;
    if (typeof window !== "undefined") {
      window.$ = $;
      window.jQuery = $;
    }
    /* eslint-disable global-require */
    const OwlCarousel = require("react-owl-carousel");
    /* eslint-enable global-require */
    return (
      <div className="Suggestion-Carousel-Blog-Videos">
        <OwlCarousel {...carouselConfigs}>
          {items.map(item => (
            <div className="item" key={item.id}>
              <BlogPoster
                key={item.id}
                poster={
                  item.featured_media && item.featured_media.source_url
                    ? item.featured_media.source_url
                    : "/cf.webp"
                }
                action="play"
                href={`/blog/news/${item.id}`}
                cardClick={() => this.navigate(item.id)}
              />
            </div>
          ))}
        </OwlCarousel>
      </div>
    );
  };

  render() {
    const { items } = this.props;
    return !Array.isArray(items) || !items.length ? null : this.carousel();
  }
}

SugCarousel.propTypes = {
  items: PropTypes.arrayOf({}),
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

SugCarousel.defaultProps = {
  items: []
};

export default withRouter(SugCarousel);
