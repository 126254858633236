import { observable, action } from "mobx";

export default class JobSubmissionStore {
  @observable jobSubmission;

  @observable candidatesNumber;

  constructor() {
    this.jobSubmission = null;
    this.candidatesNumber = 0;
  }

  @action
  changeJobSubmission = jobSubmission => {
    this.jobSubmission = jobSubmission;
  };

  @action
  changeCandidatesNumber = candidatesNumber => {
    this.candidatesNumber = candidatesNumber;
  };

  @action
  reset = () => {
    this.jobSubmission = null;
    this.candidatesNumber = 0;
  };
}
