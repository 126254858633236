import React, { Component } from "react";

class Email extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
      >
        <path d="M0,64v384h512V64H0z M455.438,96L256,266.938L56.578,96H455.438z M32,416V117.063l224,192l224-192V416H32z" />
      </svg>
    );
  }
}

export default Email;
