import HbfLayoutRoutes from "./hbfLayoutRoutes";
import HbLayoutRoutes from "./hbLayoutRoutes";
import Hb2LayoutRoutes from "./hb2LayoutRoutes";
import LpbLayoutRoutes from "./lpbLayoutRoutes";
import BLayoutRoutes from "./bLayoutRoutes";
import BlogLayoutRoutes from "./blogLayoutRoutes";

const Routes = {
  HbfLayoutRoutes,
  HbLayoutRoutes,
  Hb2LayoutRoutes,
  LpbLayoutRoutes,
  BLayoutRoutes,
  BlogLayoutRoutes
};

export default Routes;
