import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import * as queryString from "query-string/index";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import routes from "../routes/hbLayoutRoutes";
import MutateOnDidMount from "../../../components/mutateOnDidMount";

const VALIDATE_ACCOUNT_MUTATION = gql`
  mutation ValidateAccount($token: String!) {
    validateAccount(token: $token) {
      email
      role
    }
  }
`;

class EmailValidation extends Component {
  constructor(props) {
    super(props);
    const queryObj = queryString.parse(props.location.search);
    this.token = queryObj.token;
  }

  redirectTo = dest => {
    const { history } = this.props;
    history.push(dest);
  };

  render() {
    return (
      // TODO after redirection display a toast for success or error
      <Mutation
        mutation={VALIDATE_ACCOUNT_MUTATION}
        variables={{ token: this.token }}
        onCompleted={() =>
          this.redirectTo(`${routes.Login.path}?validation=success`)
        }
        onError={() =>
          this.redirectTo(`${routes.Login.path}?validation=failed`)
        }
      >
        {mutation => <MutateOnDidMount mutate={mutation} />}
      </Mutation>
    );
  }
}

EmailValidation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default withRouter(EmailValidation);
