import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import {
  Button,
  FormCard,
  Icon,
  Row,
  Col,
  Spinner,
  toaster,
  Textarea
} from "cf-neo-ui";
import moment from "moment";
import publicIp from "public-ip";
import { UPDATE_TIMESHEET_STATUS_MUTATION } from "../mutations";
import SEND_MESSAGE_TIME_SHEET from "../messenger/mutation";
import "./styles.scss";
import classes from "../conclusion-section/ConclusionSection.module.scss";
import Ok from "./icons/Ok";
import Routes from "../../layout/routes";

@inject("modalStore", "timesheetStore", "sessionStore")
@observer
class PopUpValidationTimeSheet extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps
    };
    publicIp
      .v4()
      .then(res => {
        this.ip = res;
      })
      .catch(() => {});
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  clickHandler = () => {
    const { modalStore, history, timesheetStore, i18n } = this.props;
    const { changeTimesheetPopUp } = modalStore;
    timesheetStore.resetComment();
    changeTimesheetPopUp(0);
    history.push(Routes.LpbLayoutRoutes.Timesheets.path);
    toaster.success({
      title: i18n._(t`validation du feuille de temps`),
      description: i18n._(t`la feuille de temps est validée`)
    });
  };

  clickHandler2 = () => {
    const { modalStore } = this.props;
    const { changeTimesheetPopUp } = modalStore;
    changeTimesheetPopUp(0);
  };

  formatTotal = () => {
    const { timesheetStore } = this.props;
    return {
      days: Math.floor(timesheetStore.total / 8),
      hours: Math.floor(timesheetStore.total % 8),
      minutes: Math.floor(((timesheetStore.total % 8) % 1) * 60)
    };
  };

  sendMessage = () => {
    const { timesheetStore } = this.props;
    const { comment, addMessage } = timesheetStore;
    if (comment != "" && comment != " " && comment != null) {
      addMessage({
        text: comment,
        date: moment().format("DD/MM/YY HH:mm"),
        speaker: null
      });
    }
  };

  getName = card => {
    const { sessionStore } = this.props;
    const { account } = sessionStore;
    if (account.role === "CLIENT") {
      return `${card.candidate.name}`;
    }
    if (account.role === "CANDIDATE") {
      return `${card.client.name}`;
    }
    return "";
  };

  render() {
    const { timesheetStore, match, i18n } = this.props;
    const { comment, totalSpecial, timesheet, changeComment } = timesheetStore;
    const total = this.formatTotal();
    return (
      <div className="pop-up-container">
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className="form-card-content center">
              <span className="center">
                <span className="icon">
                  <Icon
                    className="icon"
                    type="triangle-exclamation"
                    color="#8D0417"
                    color2="#D3354A"
                    width={25}
                    height={25}
                  />
                </span>
                <span className="title">
                  Merci de vérifier les informations
                </span>
              </span>
              <p>
                <Trans>
                  Une fois le timesheet validé, vous ne pourrez plus revenir en
                  arrière. Merci de vérifier les informations suivantes avec
                  attention.
                </Trans>
              </p>
              <div>
                <Row>
                  <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                    <a className={classes.labelStyle}>
                      <Trans>Consultant</Trans>
                    </a>
                    {timesheet && (
                      <div style={{ marginTop: "10px" }}>
                        <div>
                          <span
                            style={{
                              verticalAlign: "sub",
                              marginRight: "20px"
                            }}
                          >
                            <Ok />
                          </span>
                          <span>{this.getName(timesheet)}</span>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                    <a className={classes.labelStyle}>
                      <Trans>Timesheet</Trans>
                    </a>
                    <div className={classes.conclusionBox1}>
                      <div className={classes.content}>
                        <div className={classes.item}>
                          <span>
                            <span>{total.days}</span>
                            <span>
                              <Trans>j</Trans>
                            </span>
                            <span>{total.hours}</span>
                            <span>h</span>
                            {total.minutes ? (
                              <span>{total.minutes}</span>
                            ) : null}
                            {total.minutes ? <span>mn</span> : null}
                          </span>
                          <p>
                            <Trans>normal</Trans>
                          </p>
                        </div>
                        <div className={classes.item}>
                          <span>
                            <span>{totalSpecial.hours}</span>
                            <span>h</span>
                            <span>{totalSpecial.minutes}</span>
                            <span>mn</span>
                          </span>
                          <p>
                            <Trans>special</Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                    <a className={classes.labelStyle}>
                      <Trans>Commentaire</Trans>
                    </a>
                    <div style={{ marginTop: "10px", width: "100%" }}>
                      <Textarea
                        size="xLarge"
                        defaultValue={timesheetStore.comment}
                        onChange={e => changeComment(e.target.value)}
                        placeholder={`${i18n._(
                          t`ajoutez un commentaire... `
                        )}(${i18n._(t`optionnel`)})`}
                        resize={false}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <br />
              <div className="center">
                <span className="button-left">
                  <Mutation
                    mutation={UPDATE_TIMESHEET_STATUS_MUTATION}
                    variables={{
                      id: Number.parseInt(match.params.id, 10),
                      status: "approved",
                      ip: this.ip
                    }}
                    refetchQueries={["myTsNotifs"]}
                    onCompleted={() => this.clickHandler()}
                    onError={() =>
                      toaster.error({
                        title: i18n._(t`Erreur`),
                        description: i18n._(t`Un problème est survenu`)
                      })
                    }
                  >
                    {(mutation3, { loading: loading3 }) => (
                      <Mutation
                        mutation={SEND_MESSAGE_TIME_SHEET}
                        variables={{
                          timesheetID: Number.parseInt(match.params.id, 10),
                          date: new Date().getTime(),
                          message: comment
                        }}
                        onCompleted={() => {}}
                        onError={() => {}}
                      >
                        {(mutation2, { loading: loading2 }) => (
                          <span className="button-left">
                            <span className="submitButton">
                              {loading2 || loading3 ? (
                                <Button
                                  disabled
                                  icon="chevron-right"
                                  iconPosition="right"
                                  size="small"
                                >
                                  <div style={{ display: "inline-block" }}>
                                    <Spinner
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px"
                                      }}
                                      type="pointed-circular"
                                      color="#FFFFFF"
                                      size={8}
                                    />
                                    <Trans>Continuer</Trans>
                                  </div>
                                </Button>
                              ) : (
                                <Button
                                  icon="chevron-right"
                                  iconPosition="right"
                                  size="small"
                                  onClick={() => {
                                    this.sendMessage();
                                    mutation2();
                                    mutation3();
                                  }}
                                >
                                  <Trans>Continuer</Trans>
                                </Button>
                              )}
                            </span>
                          </span>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                </span>
                <span className="button-right">
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={() => this.clickHandler2()}
                  >
                    <Trans>Annuler</Trans>
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

PopUpValidationTimeSheet.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    total: PropTypes.number,
    totalSpecial: PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number
    }),
    changeComment: PropTypes.func,
    comment: PropTypes.string,
    addMessage: PropTypes.func,
    resetComment: PropTypes.func,
    updateSelectedDays: PropTypes.func,
    timesheet: PropTypes.shape({
      status: PropTypes.string
    }),
    specialHours: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        date: PropTypes.number,
        hours: PropTypes.number,
        minutes: PropTypes.number,
        type: PropTypes.string
      })
    ),
    selectedDays: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.number,
        hours: PropTypes.number
      })
    )
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    changeTimesheetPopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(PopUpValidationTimeSheet));
