import gql from "graphql-tag";

const CLIENT_STATS_CSV_QUERY = gql`
  query clientStatsCSV($id: Int!, $dateStart: Float, $dateEnd: Float) {
    clientStatsCSV(
      id: $id
      filter: { dateStart: $dateStart, dateEnd: $dateEnd }
    )
  }
`;

const ACCESSIBLE_CLIENT_STATS_CSV_QUERY = gql`
  query AccessibleclientStatsCSV(
    $id: Int!
    $dateStart: Float
    $dateEnd: Float
  ) {
    accessibleClientStatsCSV(
      id: $id
      filter: { dateStart: $dateStart, dateEnd: $dateEnd }
    )
  }
`;

const CLIENT_RECOMMENDED_TALENTS_QUERY = gql`
  query ClientRecommendedTalents(
    $id: Int!
    $limit: Int
    $skip: Int
    $employmentType: String
  ) {
    clientRecommendedTalents(
      id: $id
      filter: { limit: $limit, skip: $skip, employmentType: $employmentType }
    ) {
      id
      firstName
      lastName
      occupation
      dateAvailable
      address {
        address1
        city
      }
      candidateCv
      comments {
        comment
        commenter
        jobOrder
      }
      status
      payRate
      reference {
        id
        referenceFirstName
        referenceLastName
        referenceTitle
        outcome
      }
      accessible
      employmentPreference
    }
  }
`;

const ACCESSIBLE_CLIENT_RECOMMENDED_TALENTS_QUERY = gql`
  query AccessibleClientRecommendedTalents(
    $id: Int!
    $limit: Int
    $skip: Int
    $employmentType: String
  ) {
    accessibleClientRecommendedTalents(
      id: $id
      filter: { limit: $limit, skip: $skip, employmentType: $employmentType }
    ) {
      id
      firstName
      lastName
      occupation
      dateAvailable
      address {
        address1
        city
      }
      candidateCv
      comments {
        comment
        commenter
        jobOrder
      }
      status
      payRate
      reference {
        id
        referenceFirstName
        referenceLastName
        referenceTitle
        outcome
      }
      accessible
      employmentPreference
    }
  }
`;

const CLIENT_VACANCIES_QUERY = gql`
  query ClientVacancies(
    $id: Int!
    $limit: Int
    $skip: Int
    $search: String
    $status: [String]
  ) {
    clientVacancies(
      id: $id
      filter: { limit: $limit, skip: $skip, search: $search, status: $status }
    ) {
      id
      title
      address {
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalShortListed
      totalCVSent
      totalInterviews
      totalPlacements
    }
  }
`;

const CLIENT_OPPORTUNITIES_QUERY = gql`
  query ClientOpportunities($id: Int!, $limit: Int, $skip: Int) {
    clientOpportunities(id: $id, filter: { limit: $limit, skip: $skip }) {
      _id
      id
      title
      type
      onSite
      address {
        address1
      }
      estimatedStartDate
      estimatedEndDate
      project
      context
      description
      categories
      specialties
      skills
      certifications
      expectedBillRate
      spokenLanguages
      yearsRequired
      duration {
        months
        days
      }
      status
    }
  }
`;

const SINGLE_OPPORTUNITY_QUERY = gql`
  query GetOpportunity($id: Int!) {
    getOpportunity(id: $id) {
      _id
      id
      title
      type
      onSite
      address {
        address1
        city
      }
      estimatedStartDate
      estimatedEndDate
      project
      context
      description
      categories
      specialties
      skills
      certifications
      expectedBillRate
      spokenLanguages
      yearsRequired
      duration {
        months
        weeks
        days
      }
      status
      total
    }
  }
`;

const CLIENT_OPENED_OFFERS_QUERY = gql`
  query(
    $id: Int!
    $limit: Int
    $skipVac: Int
    $skipOpp: Int
    $search: String
    $projects: [String]
    $status: [String]
    $isOpen: Boolean
  ) {
    clientVacancies(
      id: $id
      filter: {
        limit: $limit
        skip: $skipVac
        search: $search
        isOpen: $isOpen
        projects: $projects
        status: $status
      }
    ) {
      id
      title
      address {
        address1
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalCVSent
      total
      dateAdded
      project
      isOpen
      employmentType
      __typename
    }

    clientOpportunities(
      id: $id
      filter: {
        limit: $limit
        search: $search
        skip: $skipOpp
        excludeStatus: ["closed", "converted", "rejected"]
        projects: $projects
      }
    ) {
      _id
      title
      type
      onSite
      address {
        address1
        city
      }
      estimatedStartDate
      estimatedEndDate
      project
      context
      description
      categories
      specialties
      skills
      workRate
      certifications
      expectedBillRate
      spokenLanguages
      yearsRequired
      duration {
        months
        days
      }
      status
      dateAdded
      total
      __typename
    }
  }
`;

const CLIENT_ARCHIVED_OFFERS_QUERY = gql`
  query(
    $id: Int!
    $limit: Int
    $skipVac: Int
    $skipOpp: Int
    $search: String
    $projects: [String]
    $status: [String]
    $isOpen: Boolean
  ) {
    clientVacancies(
      id: $id
      filter: {
        limit: $limit
        skip: $skipVac
        search: $search
        isOpen: $isOpen
        projects: $projects
        status: $status
      }
    ) {
      id
      title
      address {
        address1
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalCVSent
      total
      dateAdded
      project
      isOpen
      employmentType
      __typename
    }

    clientOpportunities(
      id: $id
      filter: {
        limit: $limit
        search: $search
        skip: $skipOpp
        status: ["closed"]
        projects: $projects
      }
    ) {
      _id
      title
      type
      onSite
      address {
        address1
        city
      }
      estimatedStartDate
      estimatedEndDate
      project
      context
      workRate
      description
      categories
      specialties
      skills
      certifications
      expectedBillRate
      spokenLanguages
      yearsRequired
      duration {
        months
        days
      }
      status
      dateAdded
      total
      __typename
    }
  }
`;

const ACCESSIBLE_CLIENT_OPENED_OFFERS_QUERY = gql`
  query(
    $id: Int!
    $limit: Int
    $skipVac: Int
    $search: String
    $projects: [String]
    $status: [String]
    $isOpen: Boolean
  ) {
    accessibleClientVacancies(
      id: $id
      filter: {
        limit: $limit
        skip: $skipVac
        search: $search
        isOpen: $isOpen
        projects: $projects
        status: $status
      }
    ) {
      id
      title
      address {
        address1
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalCVSent
      total
      dateAdded
      project
      isOpen
      employmentType
      __typename
    }
  }
`;

const ACCESSIBLE_CLIENT_ARCHIVED_OFFERS_QUERY = gql`
  query(
    $id: Int!
    $limit: Int
    $skipVac: Int
    $search: String
    $projects: [String]
    $status: [String]
    $isOpen: Boolean
  ) {
    accessibleClientVacancies(
      id: $id
      filter: {
        limit: $limit
        skip: $skipVac
        search: $search
        isOpen: $isOpen
        projects: $projects
        status: $status
      }
    ) {
      id
      title
      address {
        address1
        city
      }
      duration {
        months
        days
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalCVSent
      total
      dateAdded
      project
      isOpen
      employmentType
      __typename
    }
  }
`;

export {
  CLIENT_STATS_CSV_QUERY,
  ACCESSIBLE_CLIENT_STATS_CSV_QUERY,
  CLIENT_VACANCIES_QUERY,
  CLIENT_OPPORTUNITIES_QUERY,
  SINGLE_OPPORTUNITY_QUERY,
  CLIENT_OPENED_OFFERS_QUERY,
  CLIENT_ARCHIVED_OFFERS_QUERY,
  ACCESSIBLE_CLIENT_OPENED_OFFERS_QUERY,
  ACCESSIBLE_CLIENT_ARCHIVED_OFFERS_QUERY,
  CLIENT_RECOMMENDED_TALENTS_QUERY,
  ACCESSIBLE_CLIENT_RECOMMENDED_TALENTS_QUERY
};
