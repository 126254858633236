import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Radio, RadioGroup } from "cf-neo-ui";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import classes from "../SideFilters.module.scss";

@inject("missionsSearchStore")
@observer
class EmploymentTypeWidget extends Component {
  render() {
    const { i18n, missionsSearchStore } = this.props;
    const { changeEmploymentType, employmentType } = missionsSearchStore;
    const orientation = "vertical";
    return (
      <div>
        <RadioGroup
          className={classes.radioEmploymentType}
          orientation={orientation}
          name="employmentType"
          defaultSelected={employmentType}
          onChange={changeEmploymentType}
        >
          <Radio label={i18n._(t`CDI`)} value="Permanent" />
          <Radio label={i18n._(t`FreelanceType`)} value="Contract" />
          <Radio label={i18n._(t`Indifferent`)} value="" />
        </RadioGroup>
      </div>
    );
  }
}

EmploymentTypeWidget.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  missionsSearchStore: PropTypes.shape({
    changeEmploymentType: PropTypes.func,
    employmentType: PropTypes.string
  }).isRequired
};

export default withI18n()(EmploymentTypeWidget);
