import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { Container, Row, Col, MissionDisplay } from "cf-neo-ui";
import FaveUnfave from "../../../../components/fave-unfave";
import routes from "../../../layout/routes/hbfLayoutRoutes";
import classes from "./MissionsList.module.scss";
import { setUrgent } from "../../../../utils/helpers";
import formatMissionDuration from "../../../../utils/formatMissionDuration";
import getContractType from "../../../../utils/getContractType";

class UserMissions extends Component {
  toDetails = id => {
    const { history } = this.props;
    history.push(`${routes.MissionsPage.path}/${id}`);
  };

  render() {
    const { i18n, jobOrders } = this.props;
    return (
      <Container className={classes.missionsListing}>
        <Row>
          {jobOrders.map(mission => (
            <Col
              xl={3}
              lg={4}
              md={4}
              sm={4}
              xs={6}
              key={mission.id}
              className={classes.missionCard}
            >
              <FaveUnfave missionId={mission.id}>
                <MissionDisplay
                  title={mission.title}
                  location={mission.address.city}
                  duration={formatMissionDuration(
                    mission.duration,
                    i18n,
                    mission.employmentType
                  )}
                  contractType={getContractType(i18n, mission.employmentType)}
                  field={mission.customText1}
                  urgent={setUrgent(mission.customText10)}
                  onShowDetails={() => this.toDetails(mission.id)}
                  showDetailsLabel={i18n._(t`Voir l’offre`)}
                  href={`${routes.MissionsPage.path}/${mission.id}`}
                />
              </FaveUnfave>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

UserMissions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  jobOrders: PropTypes.arrayOf(PropTypes.shape({}))
};

UserMissions.defaultProps = {
  jobOrders: []
};

export default withI18n()(withRouter(UserMissions));
