import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { IconCircle, H5 } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import classes from "./SocialMedia.module.scss";
import urls from "../../../utils/urls";
import theme from "../../../configs/theme";

class SocialMedia extends PureComponent {
  render() {
    const { id } = this.props;
    const sharedUrl = urls.singleMission(id);
    return (
      <div className={classes.social}>
        <H5 className={classes.header}>
          <Trans>Partager</Trans>
        </H5>
        <TwitterShareButton className={classes.socialItem} url={sharedUrl}>
          <IconCircle
            color={theme.colors.mainColor}
            color2={theme.colors.lightMainColor}
            type="twitter"
            dimension={24}
            innerColor="#fff"
            iconWidth={15}
            iconHeight={12}
          />
        </TwitterShareButton>
        <LinkedinShareButton className={classes.socialItem} url={sharedUrl}>
          <IconCircle
            color={theme.colors.mainColor}
            color2={theme.colors.lightMainColor}
            type="linked-in"
            dimension={24}
            innerColor="#fff"
            iconWidth={12.57}
            iconHeight={11.97}
          />
        </LinkedinShareButton>
        <FacebookShareButton className={classes.socialItem} url={sharedUrl}>
          <IconCircle
            color={theme.colors.mainColor}
            color2={theme.colors.lightMainColor}
            type="facebook"
            dimension={24}
            innerColor="#fff"
            iconWidth={6.49}
            iconHeight={12}
          />
        </FacebookShareButton>
      </div>
    );
  }
}

SocialMedia.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default SocialMedia;
