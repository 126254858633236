import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { IconCircle, H5 } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import classes from "./Share.module.scss";
import urls from "../../../../utils/urls";

class Share extends PureComponent {
  render() {
    const { id } = this.props;
    const sharedUrl = urls.singleBlog(id);
    return (
      <div className={classes.social}>
        <H5 className={classes.header}>
          <Trans>Partager l’article</Trans>
        </H5>
        <TwitterShareButton className={classes.socialItem} url={sharedUrl}>
          <IconCircle
            color="#8D0417"
            color2="#D3354A"
            type="twitter"
            dimension={42}
            innerColor="#fff"
            iconWidth={26.5}
            iconHeight={21}
          />
        </TwitterShareButton>
        <LinkedinShareButton className={classes.socialItem} url={sharedUrl}>
          <IconCircle
            color="#8D0417"
            color2="#D3354A"
            type="linked-in"
            dimension={42}
            innerColor="#fff"
            iconWidth={22.16}
            iconHeight={20.95}
          />
        </LinkedinShareButton>
        <FacebookShareButton className={classes.socialItem} url={sharedUrl}>
          <IconCircle
            color="#8D0417"
            color2="#D3354A"
            type="facebook"
            dimension={42}
            innerColor="#fff"
            iconWidth={11.43}
            iconHeight={21}
          />
        </FacebookShareButton>
      </div>
    );
  }
}

Share.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
export default Share;
