import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import {
  Tabs,
  Tab,
  TextInput,
  Button,
  Container,
  Row,
  Col,
  Validation,
  toaster
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import classes from "../dashboardSingleMission.module.scss";
import { UPDATE_APPLICATION_REFERENCE } from "../mutations";

@inject("referenceTask")
@observer
class Tasks extends Component {
  constructor(props) {
    super(props);
    const { referenceTask, reference } = this.props;
    referenceTask.initFields(reference);
  }

  changeHandler = (e, field) => {
    const { referenceTask } = this.props;
    referenceTask.changeField(e, field);
  };

  saveHandler = mutation => {
    const { referenceTask } = this.props;

    referenceTask.validate();
    if (referenceTask.isFormValid) {
      mutation();
    }
  };

  getMutationVariables = () => {
    const { referenceTask, missionId } = this.props;
    const { fields } = referenceTask;
    const input = {
      jobOrderId: missionId,
      referenceEmail: fields.email.value,
      referencePhone: fields.phone.value,
      referenceFirstName: fields.firstName.value,
      referenceLastName: fields.lastName.value
    };

    return { input };
  };

  render() {
    const { reference, referenceTask, i18n } = this.props;
    const {
      referenceEmail,
      referencePhone,
      referenceLastName,
      referenceFirstName
    } = reference || {};
    const { fields } = referenceTask;
    return (
      <Tabs defaultActiveTab="tasks">
        <Tab name="tasks" tab="Tasks">
          <Container className={classes.tasksTab}>
            <Row>
              <Col md={6} sm={6} xs={6}>
                <Validation
                  valid={fields.lastName.isValid}
                  errorMessage={i18n._(t`Ce champ est requis`)}
                >
                  <TextInput
                    placeholder={i18n._(t`Prénom du référent`)}
                    defaultValue={referenceLastName}
                    onChange={e => this.changeHandler(e, "lastName")}
                  />
                </Validation>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <Validation
                  valid={fields.firstName.isValid}
                  errorMessage={i18n._(t`Ce champ est requis`)}
                >
                  <TextInput
                    placeholder={i18n._(t`Nom du référent`)}
                    defaultValue={referenceFirstName}
                    onChange={e => this.changeHandler(e, "firstName")}
                  />
                </Validation>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <Validation
                  valid={fields.email.isValid}
                  errorMessage={i18n._(t`Email invalide`)}
                >
                  <TextInput
                    style={{ marginTop: "20px" }}
                    placeholder={i18n._(t`Email`)}
                    defaultValue={referenceEmail}
                    onChange={e => this.changeHandler(e, "email")}
                  />
                </Validation>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <Validation
                  valid={fields.phone.isValid}
                  errorMessage={i18n._(t`Numéro non valide`)}
                >
                  <TextInput
                    style={{ marginTop: "20px" }}
                    placeholder={i18n._(t`Téléphone`)}
                    defaultValue={referencePhone}
                    onChange={e => this.changeHandler(e, "phone")}
                  />
                </Validation>
              </Col>
            </Row>
            <div className={classes.saveBtn}>
              <Mutation
                mutation={UPDATE_APPLICATION_REFERENCE}
                variables={this.getMutationVariables()}
                onCompleted={() =>
                  toaster.success({
                    title: i18n._(t`Succès`),
                    description: i18n._(t`Référence mis à jour avec succès`)
                  })
                }
                onError={() =>
                  toaster.error({
                    title: `${i18n._(t`Erreur`)} !`,
                    description: i18n._(
                      t`Un probleme est survenu, veuillez essayer plus tard`
                    )
                  })
                }
              >
                {updateReference => (
                  <Button
                    variant="primary"
                    onClick={() => this.saveHandler(updateReference)}
                  >
                    <Trans>Enregistrer</Trans>
                  </Button>
                )}
              </Mutation>
            </div>
          </Container>
        </Tab>
      </Tabs>
    );
  }
}

Tasks.wrappedComponent.propTypes = {
  reference: PropTypes.shape({
    referenceEmail: PropTypes.string,
    referencePhone: PropTypes.string,
    referenceLastName: PropTypes.string,
    referenceFirstName: PropTypes.string
  }),
  missionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  referenceTask: PropTypes.shape({
    initFields: PropTypes.func,
    changeField: PropTypes.func,
    validate: PropTypes.func,
    isFormValid: PropTypes.bool,
    fields: PropTypes.shape({
      email: PropTypes.shape({
        value: PropTypes.string,
        isValid: PropTypes.bool
      }),
      phone: PropTypes.shape({
        value: PropTypes.string,
        isValid: PropTypes.bool
      }),
      firstName: PropTypes.shape({
        value: PropTypes.string,
        isValid: PropTypes.bool
      }),
      lastName: PropTypes.shape({
        value: PropTypes.string,
        isValid: PropTypes.bool
      })
    })
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

Tasks.defaultProps = {
  reference: {}
};

export default withI18n()(Tasks);
