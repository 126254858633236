import gql from "graphql-tag";

const CLIENT_ACTIONS_STATS_QUERY = gql`
  query ActionsStats($id: Int!) {
    accessibleActionsStats(id: $id) {
      vacanciesCount
      cvSentCount
      placementsCount
    }
  }
`;

export default CLIENT_ACTIONS_STATS_QUERY;
