import React, { Component } from "react";

class Ok extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 512 512"
      >
        <path
          d="M26.636 280.832l51.12-51.090 102.225 102.282-51.12 51.091-102.225-102.282z"
          fill="#000000"
        />
        <path
          d="M179.996 331.976l254.25-254.25 51.12 51.12-254.25 254.25-51.12-51.12z"
          fill="#000000"
        />
        <path
          d="M180.006 434.245l-51.141-51.141 51.12-51.12 51.141 51.141-51.119 51.12z"
          fill="#000000"
        />
      </svg>
    );
  }
}

export default Ok;
