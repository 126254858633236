import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react/index";
import { Query } from "react-apollo";
import { CandidateStateCardGroup, CandidateStateCard, Talent } from "cf-neo-ui";
import { t } from "@lingui/macro";
import { validatedJobSubmissionsQuery } from "../query";
import NotFoundPage from "../../../../components/error-pages/NotFoundPage";
import Routes from "../../../layout/routes";
import runtimeVars from "../../../../configs/runTimeVars";

@inject("appStore", "modalStore", "talentStore", "jobSubmissionStore")
@observer
class Columns extends Component {
  onDrop = (e, jobSubmissions) => {
    const { modalStore, talentStore, jobSubmissionStore } = this.props;
    e.preventDefault();
    let { target } = e;
    const data = e.dataTransfer.getData("text");
    const jobSubmission = jobSubmissions.find(
      item => item.candidateID === Number.parseInt(data, 10)
    );
    jobSubmissionStore.changeJobSubmission(jobSubmission);
    while (target.className.indexOf("dropper") === -1) {
      target = target.parentNode;
    }
    const element = document.getElementById(data);
    talentStore.changeTalentDropped(element);

    const parent = target.parentNode;
    talentStore.changeNewParent(parent);

    let action = null;
    if (parent) {
      if (parent.id === "1") action = "new";
      if (parent.id === "2") action = "rejected";
      if (parent.id === "3") action = "to meet";
      if (parent.id === "4") action = "proposal";
    }

    if (
      action === "to meet" ||
      action === "rejected" ||
      action === "proposal" ||
      action === "new"
    ) {
      modalStore.changeIsVisible(true);
    }
    modalStore.changeModalType(action);
  };

  onDragOver = () => {};

  drag = e => {
    const { talentStore } = this.props;
    talentStore.changeLastParent(e.target.parentNode.parentNode);
  };

  toCV = jobSubmission => {
    const { jobSubmissionStore, modalStore, isOpen } = this.props;
    const { changeIsVisible2, changeIsVisible } = modalStore;
    if (isOpen) {
      jobSubmissionStore.changeJobSubmission(jobSubmission);
      changeIsVisible2(true);
    } else {
      changeIsVisible(true);
    }
  };

  getAvailability = date => {
    const { i18n, isOpen } = this.props;
    if (isOpen === false) return i18n._(t`disponibilité à confirmer`);
    const today = new Date();
    const dateAvailable = new Date(date);
    const diffYears = this.yearsDiff(today, dateAvailable);
    if (diffYears >= 1) return i18n._(t`disponible dans ${diffYears} ans`);
    if (diffYears === 0) {
      const diffMonths = this.monthsDiff(today, dateAvailable);
      if (diffMonths >= 1) return i18n._(t`disponible dans ${diffMonths} mois`);
      if (diffMonths === 0) {
        const diffDays = this.daysDiff(today, dateAvailable);
        if (diffDays >= 2) return i18n._(t`disponible dans ${diffDays} jours`);
        if (diffDays === 1) return i18n._(t`disponible dans un jour`);
      }
    }

    return i18n._(t`disponible immédiatement`);
  };

  getPointColor = date => {
    const { isOpen } = this.props;
    if (isOpen === false) return "blue";
    const today = new Date();
    const dateAvailable = new Date(date);
    if (dateAvailable - today >= 0) return "red";
    return "green";
  };

  yearsDiff = (d1, d2) => {
    return d2.getFullYear() - d1.getFullYear();
  };

  monthsDiff = (d1, d2) => {
    return d2.getMonth() - d1.getMonth();
  };

  daysDiff = (d1, d2) => {
    const secondsDiff = Math.floor((d2 - d1) / 1000);
    const minutesDiff = Math.floor(secondsDiff / 60);
    const hoursDiff = Math.floor(minutesDiff / 60);
    return Math.floor(hoursDiff / 24);
  };

  getCandidateStatus = candidateStatus => {
    const { i18n } = this.props;
    const lang = localStorage.getItem("language");
    if (!lang || lang !== "en") return i18n._(t`${candidateStatus}`);

    switch (candidateStatus) {
      case "Disponible":
        return "Available";
      case "Ouvert aux opportunités":
        return "Open to New Opportunity";
      default:
        return "Availability to be confirmed";
    }
  };

  render() {
    const { i18n, match, appStore, isOpen } = this.props;

    const isMobile = appStore.width <= 1024;
    const pageSize = appStore.width - 340;
    return (
      <div
        style={{
          width: `${!isMobile ? `${pageSize}px` : "100%"}`
        }}
      >
        <Query
          query={validatedJobSubmissionsQuery}
          variables={{ jobOrderID: Number.parseInt(match.params.id, 10) }}
          fetchPolicy="no-cache"
        >
          {({ loading, error, data }) => {
            if (loading) return <div>loading</div>;
            if (error)
              return (
                <NotFoundPage
                  message={i18n._(
                    t`Nous sommes désolé, la mission que vous cherchez n’existe pas ou n’est plus disponible`
                  )}
                  href={Routes.HbfLayoutRoutes.MissionsPage.path}
                />
              );
            const { validatedJobSubmissions } = data;
            return (
              <div>
                <CandidateStateCardGroup>
                  <CandidateStateCard
                    className="candidate-state-card"
                    id="1"
                    name="new"
                    text={i18n._(t`Nouveaux profils`)}
                    candidateNumber={0}
                    onDrop={e => this.onDrop(e, validatedJobSubmissions)}
                    onDragOver={() => this.onDragOver()}
                    isOpen={isOpen}
                  >
                    {validatedJobSubmissions.map(jobSubmission => {
                      if (jobSubmission.status === "cv sent")
                        return (
                          <Talent
                            key={jobSubmission.candidateID}
                            talent={{
                              id: jobSubmission.candidateID,
                              image: "/defaultAvatar.webp",
                              name: `${i18n._(t`candidat`)}  #${
                                jobSubmission.candidateID
                              }`,
                              position: jobSubmission.candidateOccupation,
                              disponibility: this.getCandidateStatus(
                                jobSubmission.candidateStatus
                              ),
                              pointColor: this.getPointColor(
                                jobSubmission.candidateAvailableDate
                              )
                            }}
                            withFav={false}
                            ondragstart={e => this.drag(e)}
                            onClick={() => this.toCV(jobSubmission)}
                            draggable={isOpen}
                            className="talent"
                          />
                        );
                      return null;
                    })}
                  </CandidateStateCard>
                  <CandidateStateCard
                    className="candidate-state-card"
                    id="2"
                    name="rejected"
                    text={i18n._(t`Profils refusés`)}
                    candidateNumber={0}
                    onDrop={e => this.onDrop(e, validatedJobSubmissions)}
                    onDragOver={() => this.onDragOver()}
                    isOpen={isOpen}
                  >
                    {validatedJobSubmissions.map(jobSubmission => {
                      if (jobSubmission.status === "rejected")
                        return (
                          <Talent
                            key={jobSubmission.candidateID}
                            talent={{
                              id: jobSubmission.candidateID,
                              image: "/defaultAvatar.webp",
                              name: `${i18n._(t`candidat`)}  #${
                                jobSubmission.candidateID
                              }`,
                              position: jobSubmission.candidateOccupation,
                              disponibility: this.getCandidateStatus(
                                jobSubmission.candidateStatus
                              ),
                              pointColor: this.getPointColor(
                                jobSubmission.candidateAvailableDate
                              )
                            }}
                            withFav={false}
                            ondragstart={e => this.drag(e)}
                            onClick={() => this.toCV(jobSubmission)}
                            draggable={isOpen}
                            className="talent"
                          />
                        );
                      return null;
                    })}
                  </CandidateStateCard>
                  <CandidateStateCard
                    className="candidate-state-card"
                    id="3"
                    name="to meet"
                    text={i18n._(t`Profils à rencontrer`)}
                    candidateNumber={0}
                    onDrop={e => this.onDrop(e, validatedJobSubmissions)}
                    onDragOver={() => this.onDragOver()}
                    isOpen={isOpen}
                  >
                    {validatedJobSubmissions.map(jobSubmission => {
                      if (jobSubmission.status === "interview")
                        return (
                          <Talent
                            key={jobSubmission.candidateID}
                            talent={{
                              id: jobSubmission.candidateID,
                              image: "/defaultAvatar.webp",
                              name: `${i18n._(t`candidat`)}  #${
                                jobSubmission.candidateID
                              }`,
                              position: jobSubmission.candidateOccupation,
                              disponibility: this.getCandidateStatus(
                                jobSubmission.candidateStatus
                              ),
                              pointColor: this.getPointColor(
                                jobSubmission.candidateAvailableDate
                              )
                            }}
                            withFav={false}
                            ondragstart={e => this.drag(e)}
                            onClick={() => this.toCV(jobSubmission)}
                            draggable={isOpen}
                            className="talent"
                          />
                        );
                      return null;
                    })}
                  </CandidateStateCard>
                  <CandidateStateCard
                    className="candidate-state-card"
                    id="4"
                    name="proposal"
                    text={i18n._(t`Proposition`)}
                    candidateNumber={0}
                    onDrop={e => this.onDrop(e, validatedJobSubmissions)}
                    onDragOver={() => this.onDragOver()}
                    isOpen={isOpen}
                  >
                    {validatedJobSubmissions.map(jobSubmission => {
                      if (jobSubmission.status === "proposal")
                        return (
                          <Talent
                            key={jobSubmission.candidateID}
                            talent={{
                              id: jobSubmission.candidateID,
                              image: "/defaultAvatar.webp",
                              name: `${i18n._(t`candidat`)}  #${
                                jobSubmission.candidateID
                              }`,
                              position: jobSubmission.candidateOccupation,
                              disponibility: this.getCandidateStatus(
                                jobSubmission.candidateStatus
                              ),
                              pointColor: this.getPointColor(
                                jobSubmission.candidateAvailableDate
                              )
                            }}
                            withFav={false}
                            ondragstart={e => this.drag(e)}
                            onClick={() => this.toCV(jobSubmission)}
                            draggable={isOpen}
                            className="talent"
                          />
                        );
                      return null;
                    })}
                  </CandidateStateCard>
                </CandidateStateCardGroup>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Columns.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    modalType: PropTypes.string,
    changeModalType: PropTypes.func,
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalentDropped: PropTypes.func,
    changeLastParent: PropTypes.func,
    changeNewParent: PropTypes.func,
    talentDropped: PropTypes.shape,
    newParent: PropTypes.shape,
    lastParent: PropTypes.shape
  }).isRequired,
  jobSubmissionStore: PropTypes.shape({
    changeJobSubmission: PropTypes.func,
    jobSubmission: PropTypes.shape
  }).isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withI18n()(withRouter(Columns));
