import gql from "graphql-tag";

const CONTACTER = gql`
  mutation Contacter($input: ContactEmailInput) {
    sendContactEmail(input: $input) {
      status
    }
  }
`;

export default CONTACTER;
