/* character string used to distinguish lists */
import React, { PureComponent } from "react";
import { Divider, H4, List, P, TagList, TextSkeleton } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import * as PropTypes from "prop-types";
import classes from "./SingleCandidateDescription.module.scss";
import SingleExperience from "../../../dashboard/profile/singleExperience";
import SingleEducation from "../../../dashboard/profile/formation/singleFormation";
import SingleCertification from "../../../dashboard/profile/certification/singleCertification";

const UL_SEP = "*";

class SingleCandidateDescription extends PureComponent {
  formatTags = arr => arr.map(item => `#${item.trim()}`);

  id = 0;

  formatText = text => {
    const res = [];
    const splitted = text.split("\n");

    for (let i = 0; i < splitted.length; i += 1) {
      splitted[i] = splitted[i].trim();
      if (splitted[i].substr(0, UL_SEP.length) === UL_SEP) {
        const li = [splitted[i].slice(UL_SEP.length)];
        for (let j = i + 1; j < splitted.length; j += 1) {
          if (splitted[j].substr(0, UL_SEP.length) !== UL_SEP) {
            i = j - 1;
            break;
          }
          li.push(splitted[j].slice(UL_SEP.length));
          if (j + 1 === splitted.length) i = j;
        }
        res.push({
          type: "ul",
          content: li
        });
      } else {
        res.push({
          type: "p",
          content: splitted[i]
        });
      }
    }
    return res;
  };

  displayText = (title, text) => {
    const formattedText = this.formatText(text);
    return (
      <div>
        {formattedText.map(item => {
          if (item.type === "ul") {
            this.id += 1;
            return (
              <List className={classes.list} key={this.id}>
                {item.content.map(li => {
                  this.id += 1;
                  return <List.Item key={this.id}>{li}</List.Item>;
                })}
              </List>
            );
          }
          this.id += 1;
          return (
            <P className={classes.description} key={this.id}>
              <span>{title}</span> {item.content}
            </P>
          );
        })}
      </div>
    );
  };

  loadingSkeletons = () => (
    <div className={classes.candidateDescription}>
      {[1, 2, 3, 4].map(i => (
        <section className={classes.skeletonSection} key={i}>
          <TextSkeleton width="200px" heading className={classes.header} />
          <TextSkeleton width="100%" lines={3} className={classes.header} />
        </section>
      ))}
      <section>
        <TextSkeleton width="100%" />
      </section>
    </div>
  );

  render() {
    const {
      loading,
      candidate,
      candidateWorkHistory,
      candidateEducation,
      candidateCertification
    } = this.props;
    return loading ? (
      this.loadingSkeletons()
    ) : (
      <div className={classes.candidateDescription}>
        {candidateWorkHistory && (
          <section className="session">
            <H4 className={classes.header}>
              <Trans>Expériences</Trans>
            </H4>

            {candidateWorkHistory.map((experience, i) => {
              return (
                <>
                  <SingleExperience
                    {...experience}
                    key={experience.id}
                    showButtons="false"
                  />
                  <br />
                </>
              );
            })}
            <Divider />
          </section>
        )}
        {candidateEducation && (
          <section className="session">
            <H4 className={classes.header}>
              <Trans>Formation et Certifications</Trans>
            </H4>

            {candidateEducation.map((formation, i) => {
              return (
                <>
                  <SingleEducation
                    {...formation}
                    key={formation.id}
                    showButtons="true"
                  />
                  <br />
                </>
              );
            })}
            <Divider />
          </section>
        )}
        {candidateCertification && (
          <section className="session">
            {candidateCertification.map((certification, i) => {
              return (
                <>
                  <SingleCertification
                    {...certification}
                    key={certification.id}
                    showButton="false"
                  />
                  <br />
                </>
              );
            })}
            <Divider />
          </section>
        )}
        {candidate.skills && (
          <section>
            <H4 className={classes.header}>
              <Trans>Compétences</Trans>
            </H4>
            <br />
            <TagList
              tags={this.formatTags(candidate.skills)}
              variant="primary"
            />
            <Divider />
          </section>
        )}
        {candidate.specialties && (
          <section>
            <H4 className={classes.header}>
              <Trans>Spécialités</Trans>
            </H4>
            <br />
            <TagList
              tags={this.formatTags(candidate.specialties)}
              variant="primary"
            />
            <Divider />
          </section>
        )}
        {candidate.spokenLanguages && (
          <section>
            <H4 className={classes.header}>
              <Trans>Langues Parlées</Trans>
            </H4>
            <br />
            <TagList
              tags={this.formatTags(candidate.spokenLanguages)}
              variant="primary"
            />
            <Divider />
          </section>
        )}
      </div>
    );
  }
}

SingleCandidateDescription.propTypes = {
  loading: PropTypes.bool.isRequired,
  candidate: PropTypes.shape({
    about: PropTypes.string,
    skills: PropTypes.string,
    specialties: PropTypes.string,
    spokenLanguages: PropTypes.string
  }).isRequired,
  candidateWorkHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  candidateEducation: PropTypes.arrayOf(PropTypes.object).isRequired,
  candidateCertification: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SingleCandidateDescription;
