import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import {
  Button,
  FormCard,
  Icon,
  Row,
  Col,
  toaster,
  Spinner,
  Textarea,
  Validation
} from "cf-neo-ui";
import "./styles.scss";
import { Mutation } from "react-apollo";
import moment from "moment";
import classes from "../conclusion-section/ConclusionSection.module.scss";
import Ok from "./icons/Ok";
import { UPDATE_TIMESHEET_STATUS_MUTATION } from "../mutations";
import SEND_MESSAGE_TIME_SHEET from "../messenger/mutation";
import Routes from "../../layout/routes";

@inject("modalStore", "timesheetStore", "sessionStore")
@observer
class PopUpRejectTimeSheet extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      isCommentValid: true,
      commentValidationMessage: "",
      buttonClicked: false
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  changeHandler = v => {
    const { timesheetStore, i18n } = this.props;
    const { changeComment } = timesheetStore;
    changeComment(v);
    if (v)
      this.setState({
        isCommentValid: true,
        commentValidationMessage: ""
      });
    else
      this.setState({
        isCommentValid: false,
        commentValidationMessage: i18n._(t`vous devez ajouter un commentaire`)
      });
  };

  clickHandler = () => {
    const { modalStore, history, timesheetStore, i18n } = this.props;
    const { changeTimesheetPopUp } = modalStore;
    timesheetStore.resetComment();
    changeTimesheetPopUp(0);
    history.push(Routes.LpbLayoutRoutes.Timesheets.path);
    toaster.success({
      title: i18n._(t`rejet du feuille de temps`),
      description: i18n._(t`la feuille de temps est rejetée`)
    });
  };

  clickHandler2 = () => {
    const { modalStore, timesheetStore } = this.props;
    const { changeTimesheetPopUp } = modalStore;
    const { resetComment } = timesheetStore;
    this.setState({ buttonClicked: false });
    resetComment();
    changeTimesheetPopUp(0);
  };

  formatTotal = () => {
    const { timesheetStore } = this.props;
    return {
      days: Math.floor(timesheetStore.total / 8),
      hours: Math.floor(timesheetStore.total % 8),
      minutes: Math.floor(((timesheetStore.total % 8) % 1) * 60)
    };
  };

  sendMessage = () => {
    const { timesheetStore } = this.props;
    const { comment, addMessage } = timesheetStore;
    addMessage({
      text: comment,
      date: moment().format("DD/MM/YY HH:mm"),
      speaker: null
    });
  };

  isCommentValid = () => {
    const { timesheetStore, i18n } = this.props;
    const { comment } = timesheetStore;
    if (comment) {
      this.setState({
        isCommentValid: true,
        commentValidationMessage: ""
      });
      return true;
    }
    this.setState({
      isCommentValid: false,
      commentValidationMessage: i18n._(t`vous devez ajouter un commentaire`)
    });
    return false;
  };

  getName = card => {
    const { sessionStore } = this.props;
    const { account } = sessionStore;
    if (account.role === "CLIENT") {
      return `${card.candidate.name}`;
    }
    if (account.role === "CANDIDATE") {
      return `${card.client.name}`;
    }
    return "";
  };

  getEmail = card => {
    const { sessionStore } = this.props;
    const { account } = sessionStore;
    if (account.role === "CLIENT") {
      return `${card.candidate.email}`;
    }
    if (account.role === "CANDIDATE") {
      return `${card.client.email}`;
    }
    return "";
  };

  render() {
    const { timesheetStore, match, i18n } = this.props;
    const {
      commentValidationMessage,
      isCommentValid,
      buttonClicked
    } = this.state;
    const { comment, totalSpecial, timesheet } = timesheetStore;
    const total = this.formatTotal();
    return (
      <div className="pop-up-container">
        <FormCard className="formCard no-gutter">
          {!buttonClicked ? (
            <Row>
              <Col className="form-card-content center">
                <span className="center">
                  <span className="icon">
                    <Icon
                      className="icon"
                      type="triangle-exclamation"
                      color="#8D0417"
                      color2="#D3354A"
                      width={25}
                      height={25}
                    />
                  </span>
                  <span className="title">
                    Merci d'indiquer le raison du rejet
                  </span>
                </span>
                <p>
                  <Trans>
                    En rejetant le timesheet, celui-ci sera renvoyé au
                    consultant pour modification. Merci d'indiquer en
                    commentaire la raison de ce rejet de la façon la plus
                    précise possible.
                  </Trans>
                </p>
                <p>
                  <Trans>
                    Veuillez noter que ce commentaire sera visible par le
                    consultant
                  </Trans>
                </p>
                <div>
                  <Row>
                    <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                      <a className={classes.labelStyle}>
                        <Trans>Commentaire</Trans>
                      </a>
                      <div style={{ marginTop: "10px", width: "100%" }}>
                        <Validation
                          errorMessage={commentValidationMessage}
                          valid={isCommentValid}
                        >
                          <Textarea
                            size="xLarge"
                            defaultValue={comment}
                            onChange={e => this.changeHandler(e.target.value)}
                            placeholder={i18n._(t`ajoutez un commentaire...`)}
                            resize={false}
                          />
                        </Validation>
                      </div>
                    </Col>
                  </Row>
                </div>

                <br />
                <div className="center">
                  <span className="button-left">
                    <span className="button-left">
                      <span className="submitButton">
                        <Button
                          icon="chevron-right"
                          iconPosition="right"
                          size="small"
                          onClick={async () => {
                            if (this.isCommentValid()) {
                              this.setState({ buttonClicked: true });
                            }
                          }}
                        >
                          <Trans>Continuer</Trans>
                        </Button>
                      </span>
                    </span>
                  </span>
                  <span className="button-right">
                    <Button
                      variant="tertiary"
                      size="small"
                      onClick={() => this.clickHandler2()}
                    >
                      <Trans>Annuler</Trans>
                    </Button>
                  </span>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="form-card-content center">
                <span className="center">
                  <span className="icon">
                    <Icon
                      className="icon"
                      type="triangle-exclamation"
                      color="#8D0417"
                      color2="#D3354A"
                      width={25}
                      height={25}
                    />
                  </span>
                  <span className="title">
                    Merci de vérifier les informations
                  </span>
                </span>
                <p>
                  <Trans>
                    Une fois le timesheet rejeté, vous ne pourrez plus revenir
                    en arrière. Merci de vérifier les informations suivantes
                    avec attention.
                  </Trans>
                </p>
                <div>
                  <Row>
                    <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                      <a className={classes.labelStyle}>
                        <Trans>Consultant</Trans>
                      </a>
                      {timesheet && (
                        <div style={{ marginTop: "10px" }}>
                          <div>
                            <span
                              style={{
                                verticalAlign: "sub",
                                marginRight: "20px"
                              }}
                            >
                              <Ok />
                            </span>
                            <span>{this.getName(timesheet)}</span>
                          </div>
                          <div>
                            <span
                              style={{
                                verticalAlign: "sub",
                                marginRight: "20px"
                              }}
                            >
                              <Ok />
                            </span>
                            <span>{this.getEmail(timesheet)}</span>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                      <a className={classes.labelStyle}>
                        <Trans>Timesheet</Trans>
                      </a>
                      <div className={classes.conclusionBox1}>
                        <div className={classes.content}>
                          <div className={classes.item}>
                            <span>
                              <span>{total.days}</span>
                              <span>
                                <Trans>j</Trans>
                              </span>
                              <span>{total.hours}</span>
                              <span>h</span>
                              {total.minutes ? (
                                <span>{total.minutes}</span>
                              ) : null}
                              {total.minutes ? <span>mn</span> : null}
                            </span>
                            <p>
                              <Trans>normal</Trans>
                            </p>
                          </div>
                          <div className={classes.item}>
                            <span>
                              <span>{totalSpecial.hours}</span>
                              <span>h</span>
                              <span>{totalSpecial.minutes}</span>
                              <span>mn</span>
                            </span>
                            <p>
                              <Trans>special</Trans>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                      <a className={classes.labelStyle}>
                        <Trans>Commentaire</Trans>
                      </a>
                      <div style={{ marginTop: "10px", width: "100%" }}>
                        "{comment}"
                      </div>
                    </Col>
                  </Row>
                </div>

                <br />
                <div className="center">
                  <span className="button-left">
                    <Mutation
                      mutation={UPDATE_TIMESHEET_STATUS_MUTATION}
                      variables={{
                        id: Number.parseInt(match.params.id, 10),
                        status: "rejected"
                      }}
                      refetchQueries={["myTsNotifs"]}
                      onCompleted={() => this.clickHandler()}
                      onError={() => {}}
                    >
                      {(mutation3, { loading: loading3 }) => (
                        <Mutation
                          mutation={SEND_MESSAGE_TIME_SHEET}
                          variables={{
                            timesheetID: Number.parseInt(match.params.id, 10),
                            date: new Date().getTime(),
                            message: comment
                          }}
                          onCompleted={() => {}}
                          onError={() => {}}
                        >
                          {(mutation2, { loading: loading2 }) => (
                            <span className="button-left">
                              <span className="submitButton">
                                {loading2 || loading3 ? (
                                  <Button
                                    disabled
                                    icon="chevron-right"
                                    iconPosition="right"
                                    size="small"
                                  >
                                    <div style={{ display: "inline-block" }}>
                                      <Spinner
                                        style={{
                                          display: "inline-block",
                                          marginRight: "10px"
                                        }}
                                        type="pointed-circular"
                                        color="#FFFFFF"
                                        size={8}
                                      />
                                      <Trans>Rejeter le timesheet</Trans>
                                    </div>
                                  </Button>
                                ) : (
                                  <Button
                                    icon="chevron-right"
                                    iconPosition="right"
                                    size="small"
                                    onClick={async () => {
                                      if (this.isCommentValid()) {
                                        this.sendMessage();
                                        await mutation2();
                                        mutation3();
                                      }
                                    }}
                                  >
                                    <Trans>Rejeter le timesheet</Trans>
                                  </Button>
                                )}
                              </span>
                            </span>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  </span>
                  <span className="button-right">
                    <Button
                      variant="tertiary"
                      size="small"
                      onClick={() => this.clickHandler2()}
                    >
                      <Trans>Annuler</Trans>
                    </Button>
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </FormCard>
      </div>
    );
  }
}

PopUpRejectTimeSheet.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    total: PropTypes.number,
    totalSpecial: PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number
    }),
    changeComment: PropTypes.func,
    comment: PropTypes.string,
    updateSelectedDays: PropTypes.func,
    timesheet: PropTypes.shape({
      status: PropTypes.string
    }),
    resetComment: PropTypes.func,
    addMessage: PropTypes.func,
    specialHours: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        date: PropTypes.number,
        hours: PropTypes.number,
        minutes: PropTypes.number,
        type: PropTypes.string
      })
    ),
    selectedDays: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.number,
        hours: PropTypes.number
      })
    )
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    changeTimesheetPopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(PopUpRejectTimeSheet));
