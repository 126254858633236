import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Pagination, Talent } from "cf-neo-ui";
import { Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import { CLIENT_RECOMMENDED_TALENTS_QUERY } from "../dashboard-company/queries";
import classes from "./styles.module.scss";
import LoadingMissions from "../../components/LoadingMissions/LoadingMissions";
import { isOnePage } from "../../utils/helpers";
import routes from "../layout/routes/hbfLayoutRoutes";
import Routes from "../layout/routes";

@inject("appStore", "sessionStore", "modalStore", "talentStore")
@observer
class RecommendedTalentsWithPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: this.setPageSize(props.appStore.width),
      currentPage: 1,
      skip: 0,
      limit: 1000
    };
  }

  setPageSize = width => {
    if (width >= 1440) return 8;
    if (width >= 1024) return 8;
    if (width >= 768) return 6;
    return 2;
  };

  widthChangeHandler = () => {
    const { appStore } = this.props;
    appStore.changeWidth();
    this.setState({ pageSize: this.setPageSize(appStore.width) });
  };

  toCV = talent => {
    const { modalStore, talentStore } = this.props;
    const { changeIsVisible, changeIsVisible2 } = modalStore;
    if (talent.accessible) {
      talentStore.changeTalent(talent);
      changeIsVisible2(true);
    } else changeIsVisible(true);
  };

  renderPagination = data => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: data.length,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };

    return <Pagination {...paginationProps} />;
  };

  toDetails = url => {
    const { history } = this.props;
    history.push(url);
  };

  interested = id => {
    const { history } = this.props;
    history.push(`${routes.MissionsPage.path}/${id}`);
  };

  getPointColor = (date, offerStatus) => {
    if (offerStatus) {
      const today = new Date();
      const dateAvailable = new Date(date);
      if (dateAvailable - today >= 0) return "red";
      return "green";
    }
    return "blue";
  };

  getAvailability = (date, offerStatus) => {
    const { i18n } = this.props;
    if (offerStatus) {
      const today = new Date();
      const dateAvailable = new Date(date);
      const diffYears = this.yearsDiff(today, dateAvailable);
      if (diffYears >= 1) return i18n._(t`disponible dans ${diffYears} ans`);
      if (diffYears === 0) {
        const diffMonths = this.monthsDiff(today, dateAvailable);
        if (diffMonths >= 1)
          return i18n._(t`disponible dans ${diffMonths} mois`);
        if (diffMonths === 0) {
          const diffDays = this.daysDiff(today, dateAvailable);
          if (diffDays >= 2)
            return i18n._(t`disponible dans ${diffDays} jours`);
          if (diffDays === 1) return i18n._(t`disponible dans un jour`);
        }
      }

      return i18n._(t`disponible immédiatement`);
    }

    return i18n._(t`Disponibilité à confirmer`);
  };

  yearsDiff = (d1, d2) => {
    return d2.getFullYear() - d1.getFullYear();
  };

  monthsDiff = (d1, d2) => {
    return d2.getMonth() - d1.getMonth();
  };

  daysDiff = (d1, d2) => {
    const secondsDiff = Math.floor((d2 - d1) / 1000);
    const minutesDiff = Math.floor(secondsDiff / 60);
    const hoursDiff = Math.floor(minutesDiff / 60);
    return Math.floor(hoursDiff / 24);
  };

  getEmploymentPreference = employmentPreference => {
    const list = [];
    if (employmentPreference && employmentPreference !== "") {
      const employmentPreferenceArray = employmentPreference.split(",");
      for (let j = 0; j < employmentPreferenceArray.length; j++) {
        if (employmentPreferenceArray[j] === "Permanent") {
          list.push("CDI");
        }
        if (employmentPreferenceArray[j] === "Contract") {
          list.push("Freelance");
        }
      }
      return list.toString();
    }
    return "";
  };

  getTalentsToDisplay = Talents => {
    const { employmentType } = this.props;
    const list = [];
    for (let i = 0; i < Talents.length; i++) {
      if (Talents[i].employmentPreference) {
        const employmentPreferenceArray = Talents[i].employmentPreference.split(
          ","
        );
        for (let j = 0; j < employmentPreferenceArray.length; j++) {
          if (employmentPreferenceArray[j] === employmentType) {
            list.push(Talents[i]);
          }
        }
      } else list.push(Talents[i]);
    }
    return list;
  };

  render() {
    const { sessionStore, appStore, i18n, employmentType } = this.props;
    const { id } = sessionStore;
    let i = 0;
    const clientID = Number(id);
    const { skip, pageSize, currentPage, limit } = this.state;
    return (
      <Query
        query={CLIENT_RECOMMENDED_TALENTS_QUERY}
        variables={{ id: clientID, limit, employmentType }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <LoadingMissions
                cardsCount={this.setPageSize(appStore.width)}
                lg={3}
                md={3}
                sm={4}
                xs={6}
                colClassName={classes.favedMissionsCard}
                containerClassName={classes.marTop}
              />
            );

          if (error)
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );

          const { clientRecommendedTalents } = data;
          const Talents = clientRecommendedTalents.reduce((a, b) => {
            if (a.indexOf(b) < 0) a.push(b);
            return a;
          }, []);

          if (Talents.length === 0)
            return (
              <div className={classes.talentCards}>
                <p className={classes.text1}>
                  <Trans>
                    Il n’y a pas de talents qualifiés par nos équipes pour le
                    moment.
                  </Trans>
                </p>
                <p className={classes.text2}>
                  <Trans>Pour recevoir des profils, soumettez une offre.</Trans>
                </p>
              </div>
            );

          let TalentsToDisplay = [];
          if (employmentType === "") TalentsToDisplay = Talents;
          else TalentsToDisplay = this.getTalentsToDisplay(Talents);
          return (
            <div>
              <Container className={classes.marTop}>
                <Row>
                  {TalentsToDisplay.slice(skip, pageSize * currentPage).map(
                    talent => {
                      i += 1;
                      return (
                        <Col
                          key={i}
                          lg={3}
                          md={3}
                          sm={4}
                          xs={6}
                          className={classes.favedMissionsCard}
                        >
                          <Talent
                            key={i}
                            talent={{
                              id: talent.id,
                              image: "/defaultAvatar.webp",
                              name: `${i18n._(t`candidat`)}  #${talent.id}`,
                              position: talent.occupation,
                              disponibility: i18n._(t`${talent.status}`),
                              pointColor: this.getPointColor(
                                talent.dateAvailable,
                                talent.accessible
                              ),
                              employmentPreference: this.getEmploymentPreference(
                                talent.employmentPreference
                              ),
                              onFave: () => {}
                            }}
                            onClick={() => this.toCV(talent)}
                            withFav={false}
                            draggable={false}
                          />
                        </Col>
                      );
                    }
                  )}
                </Row>
              </Container>
              {isOnePage(pageSize, TalentsToDisplay.length) ? null : (
                <div className={classes.pagination}>
                  {this.renderPagination(TalentsToDisplay)}
                </div>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

RecommendedTalentsWithPagination.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  employmentType: PropTypes.string.isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  appStore: PropTypes.shape({
    changeWidth: PropTypes.func,
    width: PropTypes.number
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalent: PropTypes.func,
    talent: PropTypes.shape
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(RecommendedTalentsWithPagination));
