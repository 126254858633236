import React, { Component } from "react";
import { Container, Row, Col, H2 } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./SearchCandidatesHeader.module.scss";

class SearchCandidatesHeader extends Component {
  render() {
    return (
      <section className={classes.overlape}>
        <div className={classes.block}>
          <div
            data-velocity="-.1"
            style={{
              background: `url(/assets/images/BACKGROUND_IMG.png) repeat scroll 50% 422.28px transparent`
            }}
            className={classes.parallax}
          />
          <Container>
            <Row>
              <Col lg={12}>
                <div className={classes["inner-header"]}>
                  <H2>
                    <Trans>
                      Trouvez votre consultant IT avec notre
                      <br />
                      algorithme de matching SMATCH
                    </Trans>
                    <br />
                    <img
                      src="/beta.png"
                      alt="Beta"
                      width="110px"
                      height="50px"
                    />
                  </H2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

export default SearchCandidatesHeader;
