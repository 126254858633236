import moment from "moment";
import runtimeVars from "../configs/runTimeVars";

const { APP_LANG } = runtimeVars;

const monthName = (n, locale) =>
  moment()
    .locale(locale || APP_LANG)
    .month(n - 1)
    .format("MMMM");

export default monthName;
