import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { DatePicker, Icon, TextInput, Pagination } from "cf-neo-ui";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import moment from "moment";
import classes from "./styles.module.scss";
import runtimeVars from "../../../configs/runTimeVars";
import DisplayReportCards from "./status-cards-list/DisplayReportCards";
import LoadingMissions from "../../../components/LoadingMissions/LoadingMissions";
import { isOnePage } from "../../../utils/helpers";
import REPORTS_QUERY from "./query";
import isEmail from "../../../utils/isEmail";

const isInt = value => value == Number.parseInt(value, 10);

@inject("appStore", "sessionStore")
@observer
class Index extends Component {
  constructor(props) {
    super(props);
    try {
      this.isClient = props.sessionStore.account.role === "CLIENT";
    } catch (e) {
      this.isClient = false;
    }
    this.state = {
      search: null,
      ids: null,
      startDate: null,
      endDate: null,
      skip: 0,
      pageSize: this.setPageSize(props.appStore.width),
      cardsCount: this.setPageSize(props.appStore.width),
      currentPage: 1,
      email: null
    };
    this.tempSearch = "";
    this.total = 0;
  }

  search = () => {
    if (!this.tempSearch)
      this.setState({ search: null, ids: null, email: null });
    if (isInt(this.tempSearch))
      return this.setState({
        ids: [Number.parseInt(this.tempSearch, 10)],
        email: null,
        search: null
      });
    if (isEmail(this.tempSearch))
      return this.setState({ email: this.tempSearch, ids: null, search: null });
    return this.setState({ search: this.tempSearch, email: null, ids: null });
  };

  changePage = () => {};

  reset = () => {
    this.setState({
      startDate: null,
      endDate: null,
      search: null
    });
  };

  renderPagination = length => {
    const { pageSize, currentPage } = this.state;
    const paginationProps = {
      pageSize,
      total: length,
      current: currentPage,
      onChange: current => {
        this.setState({ currentPage: current, skip: (current - 1) * pageSize });
      }
    };
    return <Pagination {...paginationProps} />;
  };

  setPageSize = width => {
    if (width >= 1440) return 4;
    if (width >= 1024) return 3;
    if (width >= 768) return 3;
    return 2;
  };

  setTotalReports = report => {
    if (report) this.total = report.total;
    return this.total;
  };

  getQueryVariables = () => {
    const {
      pageSize,
      skip,
      startDate,
      endDate,
      search,
      ids,
      email
    } = this.state;

    const filter = {};

    if (skip) filter.skip = skip;
    if (pageSize) filter.limit = pageSize;
    if (startDate) filter.periodStart = startDate;
    if (endDate) filter.periodEnd = endDate;
    if (ids) filter.ids = ids;
    if (search) filter.name = search;
    if (email) filter.email = email;

    return filter;
  };

  render() {
    const { startDate, endDate, search, cardsCount, pageSize } = this.state;
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    let longueur = null;
    return (
      <div className={classes.container}>
        <div className={classes.dateFilter}>
          <span>
            <Trans>Période</Trans>:
          </span>
          <div className={classes.dateFilter}>
            <div className={classes.dateFilter}>
              <span className={classes.dateFilterLabel}>
                <Trans>Du</Trans>
              </span>
              <DatePicker
                placeholder={i18n._(t`jj/mm/aaaa`)}
                dateFormat="DD/MM/YYYY"
                defaultDate={
                  startDate ? moment(startDate).format("DD/MM/YYYY") : ""
                }
                onChange={v => {
                  if (v) this.setState({ startDate: moment(v).valueOf() });
                }}
                locale={currentLanguage || runtimeVars.APP_LANG}
                size="small"
              />
            </div>
            <div className={classes.dateFilter}>
              <span className={classes.dateFilterLabel}>
                <Trans>Au</Trans>
              </span>
              <DatePicker
                placeholder={i18n._(t`jj/mm/aaaa`)}
                dateFormat="DD/MM/YYYY"
                defaultDate={
                  endDate ? moment(endDate).format("DD/MM/YYYY") : ""
                }
                onChange={v => {
                  if (v) this.setState({ endDate: moment(v).valueOf() });
                }}
                locale={currentLanguage || runtimeVars.APP_LANG}
                size="small"
              />
            </div>
          </div>
          <div
            className={classes.reset}
            title={i18n._(t`Réinitialiser`)}
            onClick={this.reset}
          >
            <Icon type="fat-close" color="#808080" />
          </div>
          <div className={classes.searchBar}>
            <TextInput
              size="small"
              placeholder={
                this.isClient
                  ? i18n._(t`chercher par id, consultant`)
                  : i18n._(t`chercher par id, entreprise ou signataire`)
              }
              icon="search"
              radius={50}
              defaultValue={search || ""}
              onChange={e => {
                this.tempSearch = e.target.value;
              }}
              onEnterPressed={this.search}
              clickableIcon
              onIconClicked={this.search}
            />
          </div>
        </div>
        <div className={classes.divider} />
        <div>
          <Query
            query={REPORTS_QUERY}
            variables={this.getQueryVariables()}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <LoadingMissions
                    cardsCount={cardsCount}
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={6}
                  />
                );

              if (error) {
                return (
                  <div className="error_container">
                    <span>
                      {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                    </span>
                    <img
                      style={{
                        margin: 20
                      }}
                      src={"/assets/svg/download.svg"}
                      alt="error"
                    />
                  </div>
                );
              }
              const { reports } = data;
              longueur = this.setTotalReports(reports[0]);
              return (
                <div>
                  <DisplayReportCards cards={reports} />
                  {isOnePage(pageSize, longueur) ? null : (
                    <div className={classes.pagination}>
                      {this.renderPagination(longueur)}
                    </div>
                  )}
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

Index.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string,
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired
};

export default withI18n()(Index);
