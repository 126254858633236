import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Button, Spinner, toaster, Icon } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Mutation, Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import classes from "./ConclusionSection.module.scss";
import { SAVE_TIMESHEET_AS_DRAFT } from "../mutations";
import { APPROVALS_AUTH_QUERY } from "../../timesheets/signature-tab/query";
import theme from "../../../configs/theme";

@inject("timesheetStore", "sessionStore", "modalStore")
@observer
class ConclusionSection extends Component {
  saveAsDraft = () => {};

  submit = () => {};

  displayPopUp = action => {
    const { modalStore } = this.props;
    modalStore.changeTimesheetPopUp(action);
  };

  formatTotal = () => {
    const { timesheetStore } = this.props;
    return {
      days: Math.floor(timesheetStore.total / 8),
      hours: Math.floor(timesheetStore.total % 8),
      minutes: Math.floor(((timesheetStore.total % 8) % 1) * 60)
    };
  };

  render() {
    const { timesheetStore, match, i18n, sessionStore } = this.props;
    const {
      selectedDays,
      specialHours,
      totalSpecial,
      timesheet
    } = timesheetStore;
    const selectDaysFormated = [];
    selectedDays.map(async day => {
      const theDay = {};
      theDay.day = day.day;
      theDay.hours = await Math.trunc(day.hours);
      theDay.minutes = day.minutes
        ? (await Math.round((day.hours - Math.trunc(day.hours)) * 60)) +
          day.minutes
        : await Math.round((day.hours - Math.trunc(day.hours)) * 60);
      selectDaysFormated.push(theDay);
    });
    let userType;
    let isDisabledForClient = true;
    let isDisabledForCandidate = true;
    if (sessionStore.account) {
      userType = sessionStore.account.role;
    }
    if (timesheet.status === "pending") {
      isDisabledForClient = false;
    }
    if (
      timesheet.status === "open" ||
      timesheet.status === "rejected" ||
      timesheet.status === "draft"
    ) {
      isDisabledForCandidate = false;
    }
    const total = this.formatTotal();
    return (
      <div className={classes.container}>
        <div className={classes.conclusionBox}>
          <div className={classes.header}>
            <div className="tooltip">
              <Trans>Total</Trans>
              <span className="tooltipText">
                <Trans>
                  Notre service de facturation fera correspondre les heures
                  normales et spéciales avec les tarifs définis dans votre
                  contrat
                </Trans>
              </span>
              &nbsp;
              <Icon
                type="info"
                size="tiny"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.item}>
              <span>
                <span>{total.days}</span>
                <span>
                  <Trans>j</Trans>
                </span>
                <span>{total.hours}</span>
                <span>h</span>
                {total.minutes ? <span>{total.minutes}</span> : null}
                {total.minutes ? <span>mn</span> : null}
              </span>
              <p>
                <Trans>normal</Trans>
              </p>
            </div>
            <div className={classes.item}>
              <span>
                <span>{totalSpecial.hours}</span>
                <span>h</span>
                <span>{totalSpecial.minutes}</span>
                <span>mn</span>
              </span>
              <p>
                <Trans>special</Trans>
              </p>
            </div>
          </div>
        </div>
        {userType === "CANDIDATE" && (
          <div className={classes.actions}>
            <Mutation
              mutation={SAVE_TIMESHEET_AS_DRAFT}
              variables={{
                id: Number.parseInt(match.params.id, 10),
                body: {
                  selectedDays: selectDaysFormated,
                  specialHours
                }
              }}
              onCompleted={() =>
                toaster.success({
                  title: i18n._(t`Enregistrement de la feuille de temps`),
                  description: i18n._(
                    t`la feuille de temps est enregistrée en brouillon, vous pouvez la modifier à tout moment`
                  )
                })
              }
              onError={() =>
                toaster.error({
                  title: i18n._(t`Un problème est survenu`),
                  description: i18n._(
                    t`la feuille de temps n'a pas été enregistrée`
                  )
                })
              }
            >
              {(draftMutation, { loading }) => (
                <span className="button-left">
                  <span className="submitButton">
                    {loading ? (
                      <Button disabled>
                        <Spinner
                          type="pointed-circular"
                          color="#FFFFFF"
                          size={12}
                        />
                      </Button>
                    ) : null}
                    {!loading ? (
                      <Button
                        disabled={isDisabledForCandidate}
                        className={classes.action}
                        variant="secondary"
                        onClick={() => draftMutation()}
                      >
                        <Trans>Enregistrer en brouillon</Trans>
                      </Button>
                    ) : null}
                  </span>
                </span>
              )}
            </Mutation>

            <span className={classes.action}>
              <Trans>Ou</Trans>
            </span>
            <Button
              disabled={isDisabledForCandidate}
              className={classes.action}
              onClick={() => {
                if (
                  timesheetStore.validationSpecialHours(
                    timesheetStore.specialHours
                  )
                )
                  this.displayPopUp(3);
              }}
            >
              <Trans>Continuer</Trans>
            </Button>
          </div>
        )}

        {userType === "CLIENT" && (
          <Query query={APPROVALS_AUTH_QUERY} fetchPolicy="no-cache">
            {({ loading, error, data }) => {
              if (loading) return <div>loading</div>;
              if (error)
                return (
                  <div className="error_container">
                    <span>
                      {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                    </span>
                    <img
                      style={{
                        margin: 20
                      }}
                      src="/assets/svg/download.svg"
                      alt="error"
                    />
                  </div>
                );
              const { approvalAuth } = data;
              if (
                approvalAuth.asApprovingClient2.includes(
                  timesheet.placementID
                ) ||
                approvalAuth.asApprovingClient.includes(timesheet.placementID)
              )
                return (
                  <div className={classes.actions}>
                    <Button
                      disabled={isDisabledForClient}
                      className={classes.action}
                      variant="secondary"
                      onClick={() => this.displayPopUp(1)}
                    >
                      <Trans>Rejeter le timesheet</Trans>
                    </Button>
                    <span className={classes.action}>
                      <Trans>Ou</Trans>
                    </span>
                    <Button
                      disabled={isDisabledForClient}
                      className={classes.action}
                      onClick={() => this.displayPopUp(2)}
                    >
                      <Trans>Valider le timesheet</Trans>
                    </Button>
                  </div>
                );
              return (
                <div className={classes.actions}>
                  <Button
                    disabled
                    className={classes.action}
                    variant="secondary"
                    onClick={() => this.displayPopUp(1)}
                  >
                    <Trans>Rejeter le timesheet</Trans>
                  </Button>
                  <span className={classes.action}>
                    <Trans>Ou</Trans>
                  </span>
                  <Button
                    disabled
                    className={classes.action}
                    onClick={() => this.displayPopUp(2)}
                  >
                    <Trans>Valider le timesheet</Trans>
                  </Button>
                </div>
              );
            }}
          </Query>
        )}
      </div>
    );
  }
}

ConclusionSection.wrappedComponent.propTypes = {
  timesheetStore: PropTypes.shape({
    total: PropTypes.number,
    validationSpecialHours: PropTypes.func,
    comment: PropTypes.string,
    timesheet: PropTypes.shape({
      status: PropTypes.string,
      placementID: PropTypes.number
    }),
    totalSpecial: PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number
    }),
    updateSelectedDays: PropTypes.func,
    specialHours: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        date: PropTypes.number,
        hours: PropTypes.number,
        minutes: PropTypes.number,
        type: PropTypes.string
      })
    ),
    selectedDays: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.number,
        hours: PropTypes.number
      })
    )
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    timesheetPopUp: PropTypes.number,
    changeTimesheetPopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(ConclusionSection));
