import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import {
  Button,
  FormCard,
  Icon,
  Row,
  Col,
  toaster,
  Spinner,
  FormGroup,
  Validation,
  TextInput
} from "cf-neo-ui";
import { Mutation } from "react-apollo";
import { UPDATE_APPROVAL, DELETE_APPROVAL } from "./mutation";

@inject("modalStore", "signataireStore")
@observer
class PopUpAddSignataire extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      isEmailValid: true,
      emailValidationMessage: "",
      email: ""
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  clickHandler2 = () => {
    const { modalStore } = this.props;
    const { changeSignatairePopUp } = modalStore;
    this.setState({ email: "" });
    changeSignatairePopUp(false);
  };

  render() {
    const { i18n, signataireStore, isSignatoryExist, modalStore } = this.props;
    const { changeSignatairePopUp } = modalStore;
    const {
      placementID,
      signatoryType,
      changeSignatoryType,
      changePlacementID
    } = signataireStore;
    const { isEmailValid, emailValidationMessage, email } = this.state;
    return (
      <div className="pop-up-container">
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className="form-card-content center">
              {isSignatoryExist && (
                <div>
                  <span className="center">
                    <span className="icon">
                      <Icon
                        className="icon"
                        type="triangle-exclamation"
                        color="#8D0417"
                        color2="#D3354A"
                        width={25}
                        height={25}
                      />
                    </span>
                    <span className="title">attention</span>
                  </span>
                  <p>
                    <Trans>
                      l’ajout d’un nouveau signataire va ecraser l’ancien..
                    </Trans>
                  </p>
                </div>
              )}
              <div>
                <Row>
                  <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                    <FormGroup>
                      <label className="form-label" htmlFor="agent_email">
                        <Trans>Email</Trans>
                      </label>
                      <Validation
                        errorMessage={emailValidationMessage}
                        valid={isEmailValid}
                      >
                        <TextInput
                          id="agent_email"
                          type="email"
                          spellCheck="false"
                          className="form-input"
                          placeholder={
                            signatoryType === 2
                              ? i18n._(
                                  t`ajoutez l'email du signataire secondaire...`
                                )
                              : i18n._(
                                  t`ajoutez l'email du signataire principal...`
                                )
                          }
                          value={email}
                          onChange={e =>
                            this.setState({
                              email: e.target.value.toLowerCase()
                            })
                          }
                        />
                      </Validation>
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <br />
              <div className="center">
                <Mutation
                  mutation={UPDATE_APPROVAL}
                  variables={
                    signatoryType === 1
                      ? {
                          placementID,
                          approvingClient: email
                        }
                      : {
                          placementID,
                          approvingClient2: email
                        }
                  }
                  onCompleted={() => {
                    changeSignatairePopUp(false);
                    changeSignatoryType(null);
                    changePlacementID(null);
                    this.setState({ email: "" });
                    if (signatoryType === 2)
                      toaster.success({
                        title: i18n._(
                          t`ajout d'un nouveau signataire secondaire`
                        ),
                        description: i18n._(
                          t`l'ajout du nouveau signataire secondaire a été effectué avec succès`
                        )
                      });
                    if (signatoryType === 1)
                      toaster.success({
                        title: i18n._(
                          t`ajout d'un nouveau signataire principal`
                        ),
                        description: i18n._(
                          t`l'ajout du nouveau signataire secondaire a été effectué avec succès`
                        )
                      });
                  }}
                  onError={() =>
                    toaster.error({
                      title: i18n._(t`Erreur`),
                      description: i18n._(t`Un problème est survenu`)
                    })
                  }
                  refet
                  refetchQueries={["Approvals"]}
                >
                  {(mutation1, { loading: loading1 }) => (
                    <span className="button-left">
                      <span className="submitButton">
                        {loading1 ? (
                          <Button
                            disabled
                            icon="chevron-right"
                            iconPosition="right"
                            size="small"
                          >
                            <div style={{ display: "inline-block" }}>
                              <Spinner
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px"
                                }}
                                type="pointed-circular"
                                color="#FFFFFF"
                                size={8}
                              />
                              {isSignatoryExist ? (
                                <Trans>Ajouter quand même</Trans>
                              ) : (
                                <Trans>Ajouter</Trans>
                              )}
                            </div>
                          </Button>
                        ) : (
                          <Button
                            icon="chevron-right"
                            iconPosition="right"
                            size="small"
                            onClick={() => mutation1()}
                          >
                            {isSignatoryExist ? (
                              <Trans>Ajouter quand même</Trans>
                            ) : (
                              <Trans>Ajouter</Trans>
                            )}
                          </Button>
                        )}
                      </span>
                    </span>
                  )}
                </Mutation>

                <span className="button-right">
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={() => this.clickHandler2()}
                  >
                    <Trans>Annuler</Trans>
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

PopUpAddSignataire.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    signatairePopUp: PropTypes.bool,
    changeSignatairePopUp: PropTypes.func
  }).isRequired,
  signataireStore: PropTypes.shape({
    signatoryType: PropTypes.number,
    placementID: PropTypes.number,
    changeSignatoryType: PropTypes.func,
    changePlacementID: PropTypes.func
  }).isRequired,
  isSignatoryExist: PropTypes.bool.isRequired
};

export default withI18n()(withRouter(PopUpAddSignataire));
