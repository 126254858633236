import React, { PureComponent } from "react";
import { Icon } from "cf-neo-ui";
import * as PropTypes from "prop-types";
import classes from "./SingleCandidateHeaderInfos.module.scss";
import theme from "../../../../configs/theme";
import SocialMedia from "../../../single-mission/SocialMedia/SocialMedia";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

class SingleCandidateHeaderInfos extends PureComponent {
  timeConverter = timestamp => {
    const a = new Date(timestamp);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const humanDate = `${date} ${month} ${year} `;
    return humanDate;
  };

  render() {
    const {
      id,
      city,
      employmentPreference,
      dateAvailable,
      availability,
      i18n
    } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.col}>
          <div
            title={i18n._(t`Ville de résidence`)}
            className={classes.iconText}
          >
            <Icon
              type="map-marker"
              width={14}
              height={16}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span>{city}</span>
          </div>
          <div
            title={i18n._(t`Remote/Présentiel`)}
            className={classes.iconText}
          >
            <Icon
              type="briefcase"
              width={14}
              height={16}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span>{availability}</span>
          </div>
        </div>
        <div className={classes.col}>
          <div title={i18n._(t`Disponibilité`)} className={classes.iconText}>
            <Icon
              type="clock"
              width={14}
              height={16}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <Trans>
              <span>À partir de {this.timeConverter(dateAvailable)}</span>
            </Trans>
          </div>
          <div title={i18n._(t`Type de contrat`)} className={classes.iconText}>
            <Icon
              type="adjust"
              width={16}
              height={16}
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
            <span>{employmentPreference}</span>
          </div>
        </div>
        <br />
        <SocialMedia id={id} />
      </div>
    );
  }
}

SingleCandidateHeaderInfos.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  city: PropTypes.string,
  dateAvailable: PropTypes.string,
  employmentPreference: PropTypes.string,
  availability: PropTypes.string,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};
SingleCandidateHeaderInfos.defaultProps = {
  city: "",
  dateAvailable: "",
  employmentPreference: "",
  availability: ""
};

export default withI18n()(SingleCandidateHeaderInfos);
