import { t } from "@lingui/macro";
import urls from "../../../utils/urls";

const ldJson = i18n => ({
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "blog",
      item: urls.blog()
    },
    {
      "@type": "ListItem",
      position: 2,
      name: i18n._(t`conseils pratiques`),
      item: urls.advices()
    },
    {
      "@type": "ListItem",
      position: 2,
      name: i18n._(t`consultant`),
      item: urls.advicesConsultant()
    }
  ]
});

export default ldJson;
