import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { AutoComplete, TagList } from "cf-neo-ui";
import { inject, observer } from "mobx-react/index";
import classes from "../SideFilters.module.scss";
import theme from "../../../../../configs/theme";

@inject("missionsSearchStore")
@observer
class CategoriesWidget extends Component {
  constructor(props) {
    super(props);
    props.missionsSearchStore.initCategoryOptions(props.options);
  }

  selectHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.addCategory(val);
    missionsSearchStore.setCategoryOptions();
  };

  closeTagHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.removeCategory(val);
    missionsSearchStore.setCategoryOptions();
  };

  render() {
    const { i18n, missionsSearchStore } = this.props;
    return (
      <div>
        <AutoComplete
          key={missionsSearchStore.categories.slice().length}
          restrictedToList
          clearOnSelect
          options={missionsSearchStore.categoryOptions.slice()}
          maxSuggestion={16}
          iconColor={theme.colors.mainColor}
          iconColor2={theme.colors.lightMainColor}
          icon="chevron-down"
          placeholder={i18n._(t`Sélectionner des domaines`)}
          onSelect={this.selectHandler}
        />
        <TagList
          tags={missionsSearchStore.categories}
          variant="secondary"
          closable
          className={classes.tagList}
          onClose={this.closeTagHandler}
        />
      </div>
    );
  }
}

CategoriesWidget.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.PropTypes.shape({}).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withI18n()(CategoriesWidget);
