import gql from "graphql-tag";

const CorporateUserQuery = gql`
  query CorporateUserQuery($id: ID!) {
    corporateUser(id: $id) {
      id
      firstName
      phone
      occupation
      customText1
      customText2
      customText3
      email
      headShot
      jobOrders {
        id
        title
        address {
          city
        }
        duration {
          days
          weeks
          months
          years
        }
        customText1
        customText10
      }
    }
  }
`;

export default CorporateUserQuery;
