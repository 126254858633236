import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Button } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react/index";
import Fade from "react-reveal/Fade";
import Routes from "../../layout/routes";
import classes from "./ServiceSection1.module.scss";

@inject("sessionStore", "modalStore")
@observer
class ServiceSection1 extends Component {
  /*
  toForm = () => {
    const { sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CLIENT") {
        const { Offer } = Routes.HbLayoutRoutes;
        history.push(Offer.path);
      } else history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    } else history.push(Routes.HbLayoutRoutes.Login.path);
  };
  */

  clickHandler = () => {
    const { history } = this.props;
    const { HbfLayoutRoutes } = Routes;
    const { SearchCandidatesPage } = HbfLayoutRoutes;
    history.push(SearchCandidatesPage.path);
    // const { modalStore, sessionStore, history } = this.props;
    // const { signUpUserRole, authToken } = sessionStore;
    // if (authToken) {
    //   if (signUpUserRole === "CANDIDATE") {
    //     history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    //   } else if (signUpUserRole === "CLIENT") {
    //     modalStore.changeIsVisible(true);
    //   }
    // } else {
    //   history.push(Routes.HbLayoutRoutes.Login.path);
    // }
  };

  render() {
    return (
      <div className={classes.service}>
        <Fade>
          <h1 className={classes.title}>
            <Trans>Recrutez les meilleurs talents tech & IT</Trans>
          </h1>
        </Fade>
        <Fade>
          <p className={classes.paragraph}>
            <a>«</a>
            <Trans>
              Nous ne construisons pas des bases de données, nous construisons
              des communautés. D’où des relations de proximité avec nos
              consultants indépendants, et une connaissance pointue des profils
              disponibles sur le marché.
            </Trans>
            <a>»</a>
          </p>
        </Fade>

        <Fade big>
          <Button
            variant="primary"
            onClick={this.clickHandler}
            // onClick={this.toForm}
          >
            <Trans>Rechercher un talent</Trans>
          </Button>
        </Fade>
      </div>
    );
  }
}

ServiceSection1.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    signUpUserRole: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    changeIsVisible: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(ServiceSection1));
