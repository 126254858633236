import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { withI18n } from "@lingui/react/index";
import { inject, observer } from "mobx-react";
import { Trans } from "@lingui/macro";
import {
  Row,
  Col,
  Button,
  FormCard,
  IllustrationIcons,
  // TestimonialCard,
  // TestimonialCardV2,
  Divider
} from "cf-neo-ui";
import Routes from "../layout/routes/index";

import "./styles.scss";
import configs from "../../configs/club-freelance-globals";

@inject("sessionStore", "appStore", "postANeedStore")
@observer
class CreateOfferThanks extends Component {
  handleBackRef = () => {
    const { history, sessionStore } = this.props;
    const { MissionsPage } = Routes.HbfLayoutRoutes;
    history.push(`${MissionsPage.path}/${sessionStore.backRef}`);
    sessionStore.setBackRef(null);
  };

  onClickButton = () => {
    return null;
  };

  toForm = () => {
    const { i18n } = this.props;
    if (i18n.language === "fr") window.open(configs.postNeed.link_fr);
    else window.open(configs.postNeed.link_uk);
  };

  render() {
    const { DashboardCompany } = Routes.LpbLayoutRoutes;
    const { MyNeed, Offer } = Routes.HbLayoutRoutes;
    const { postANeedStore } = this.props;
    postANeedStore.reset();
    return (
      <div className="session container confirm">
        <br />
        <Row>
          <div className="center">
            <br />
            <IllustrationIcons type="check" color2="#d3354a" />
          </div>
        </Row>
        <Row>
          <Col className="content" lg={6} xl={6}>
            <div className="center-txt bold">
              <h1 className="thanks">
                <Trans>Votre demande a bien été envoyée pour validation!</Trans>
              </h1>
            </div>
            <br />
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter">
          <Row className="with-padding">
            <Col lg={8} xl={8} className="center">
              <br />
              <span className="center-txt text-bold">
                <Trans>
                  Merci, nos équipes vont étudier votre demande et revenir vers
                  vous dans les plus brefs délais.
                </Trans>
                <br />
                <Trans>
                  Si nous ne sommes pas en mesure de vous accompagner dans cette
                  recherche, vous serez informés par email.
                </Trans>
              </span>
            </Col>
          </Row>
          <br />
          <Divider />
          <br />
          <Row className="with-padding">
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="targeting"
                    color2="#d3354a"
                    dimension={80}
                  />
                  <span className="text-gray">
                    <Trans>Retrouvez des talents sélectionnés pour vous</Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="documents"
                    color2="#d3354a"
                    dimension={80}
                  />
                  <span className="text-gray">
                    <Trans>Gérez vos documents administratifs</Trans>
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={6}>
              <div className="centerIcon">
                <div className="illustration">
                  <IllustrationIcons
                    type="check"
                    color2="#d3354a"
                    dimension={55}
                  />
                  <span className="text-gray">
                    <Trans>Suivez toutes vos missions</Trans>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className="center">
              <div className="buttonsBlock">
                <Link to={Offer.path}>
                  <Button
                    icon="chevron-right"
                    iconPosition="right"
                    // onClick={this.toForm}
                  >
                    <Trans>Poster un besoin via le formulaire</Trans>
                  </Button>
                </Link>
              </div>
              <Link to={MyNeed.path}>
                <Button
                  icon="chevron-right"
                  iconPosition="right"
                  variant="secondary"
                >
                  <Trans>Poster un besoin via une fiche de poste</Trans>
                </Button>
              </Link>
            </div>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <div className="center">
              <Trans>ou</Trans>&nbsp;&nbsp;
              <Link to={DashboardCompany.path}>
                <Trans>Accéder à mon espace client</Trans>
              </Link>
            </div>
          </Row>
          <br /> <br /> <br />
          <br />
          <br />
        </FormCard>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

CreateOfferThanks.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    backRef: PropTypes.string,
    setBackRef: PropTypes.func,
    signUpUserRole: PropTypes.string
  }).isRequired,
  postANeedStore: PropTypes.shape({
    reset: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(CreateOfferThanks));
