import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Container, Row, Col, Button, TextInput, toaster } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
// import { Mutation } from "react-apollo";
import { t, Trans } from "@lingui/macro";
// import SUB_TO_NEWSLETTER from "./mutations";
import AboutWidget from "./AboutWidget";
import LinkWidget from "./LinkWidget";
import { links1, links2, links3 } from "./links";
import classes from "./footer.module.scss";
import configs from "../../../configs/club-freelance-globals";
import { inject, observer } from "mobx-react";

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("appStore")
@observer
class Footer extends Component {
  constructor(props) {
    super(props);
    this.email = "";
  }

  subscribe = mutation => {
    const { i18n } = this.props;
    if (!EMAIL_REG.test(this.email))
      return toaster.warning({
        title: i18n._(t`Avertissement`),
        description: i18n._(t`Veillez entrer un email valide`)
      });
    return mutation({
      variables: {
        input: {
          email: this.email,
          list: ["consultant"]
        }
      }
    });
  };

  render() {
    const { i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    return (
      <section className={classes.footer}>
        <footer>
          <div className={classes.bgImg}>
            <picture>
              <source
                type="images/webp"
                srcSet="/assets/images/footerbg.webp"
              />
              <img src="/assets/images/footerbg.png" alt="footer" />
            </picture>
          </div>
          <Container className={classes.container}>
            <div className={classes.content}>
              {/* <div className={classes.newsSubscription}> */}
              {/*  <h5 className={classes.subscribeHeader}> */}
              {/*    <Trans> */}
              {/*      Rejoindre Mission Control Center et trouver les meilleurs */}
              {/*      idées de IT professionnel */}
              {/*    </Trans> */}
              {/*  </h5> */}
              {/*  <Mutation */}
              {/*    mutation={SUB_TO_NEWSLETTER} */}
              {/*    onCompleted={() => */}
              {/*      toaster.success({ */}
              {/*        title: i18n._(t`Merci`), */}
              {/*        description: i18n._( */}
              {/*          t`Vous êtes désormais abonné aux Mission Control Center` */}
              {/*        ) */}
              {/*      }) */}
              {/*    } */}
              {/*    onError={() => */}
              {/*      toaster.error({ */}
              {/*        title: i18n._(t`Erreur`), */}
              {/*        description: i18n._( */}
              {/*          t`Un problème est survenu lors de la création de l'abonnement` */}
              {/*        ) */}
              {/*      }) */}
              {/*    } */}
              {/*    ignoreResults */}
              {/*  > */}
              {/*    {subToNewsletter => ( */}
              {/*      <div className={classes.subscriptionInput}> */}
              {/*        <TextInput */}
              {/*          placeholder={i18n._(t`adresse email`)} */}
              {/*          type="email" */}
              {/*          spellCheck="false" */}
              {/*          className={classes.emailInput} */}
              {/*          onChange={e => (this.email = e.target.value)} */}
              {/*        /> */}
              {/*        <Button */}
              {/*          type="button" */}
              {/*          variant="secondary" */}
              {/*          className={classes.subscribeButton} */}
              {/*          onClick={() => this.subscribe(subToNewsletter)} */}
              {/*        > */}
              {/*          <Trans>s&apos;abonner</Trans> */}
              {/*        </Button> */}
              {/*      </div> */}
              {/*    )} */}
              {/*  </Mutation> */}
              {/* </div> */}
              <Row>
                <Col
                  className={classes.aboutWidget}
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                >
                  <AboutWidget />
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <LinkWidget
                    title={i18n._(t`Freelances`)}
                    links={links1(i18n, currentLanguage)}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <LinkWidget
                    title={i18n._(t`Entreprise`)}
                    links={links2(i18n)}
                  />
                </Col>
                <Col xs={12} sm={3} md={3} lg={2}>
                  <LinkWidget
                    title={i18n._(t`Mindquest`)}
                    links={links3(i18n)}
                  />
                </Col>
                {/* <Col xs={12} sm={3} md={3} lg={2}> */}
                {/*  <LinkWidget */}
                {/*    title={i18n._(t`Club_Freelance`)} */}
                {/*    OptionText={i18n._( */}
                {/*      t`Découvrez notre service de recrutement freelance` */}
                {/*    )} */}
                {/*    btnText={i18n._(t`Découvrez Club Freelance`)} */}
                {/*    btnLink={configs.cf_website} */}
                {/*  /> */}
                {/* </Col> */}
              </Row>
            </div>
          </Container>
        </footer>
      </section>
    );
  }
}

Footer.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(Footer);
