import React, { Component } from "react";

class DownloadIcon extends Component {
  render() {
    return (
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="7.79688"
          y="1"
          width="2.89655"
          height="7.27273"
          rx="1.39636"
          fill="#C40B24"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.88437 13.1906C9.07019 13.4084 9.40664 13.4084 9.59246 13.1906L14.3764 7.5852C14.6342 7.28308 14.4196 6.81759 14.0224 6.81759H4.45448C4.05728 6.81759 3.84258 7.28308 4.10043 7.5852L8.88437 13.1906Z"
          fill="#C40B24"
        />
        <path
          d="M15.7586 16.758H2.24138"
          stroke="#C40B24"
          strokeWidth="2.79273"
          strokeLinecap="round"
        />
      </svg>
    );
  }
}

export default DownloadIcon;
