import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Select } from "cf-neo-ui";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";

@inject("candidatesSearchStore")
@observer
class ExperienceWidget extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { i18n, candidatesSearchStore } = this.props;
    const { experience, changeExperience } = candidatesSearchStore;
    return (
      <div>
        <Select
          style={{ "margin-top": "14px" }}
          id="experienceYears"
          options={{
            groupA: [
              {
                text: i18n._(t`Indifferent`),
                value: null,
                selected: !experience
              },
              {
                text: `${i18n._(t`Junior`)}(0-3)`,
                value: 1,
                selected: experience === 1
              },
              {
                text: `${i18n._(t`Confirmé`)} (3-7)`,
                value: 2,
                selected: experience === 2
              },
              {
                text: `${i18n._(t`Senior`)} (7-10)`,
                value: 3,
                selected: experience === 3
              },
              {
                text: `${i18n._(t`Expert`)} (10+)`,
                value: 4,
                selected: experience === 4
              }
            ]
          }}
          onSelect={changeExperience}
        />
      </div>
    );
  }
}

ExperienceWidget.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    changeExperience: PropTypes.func,
    experience: PropTypes.number
  }).isRequired
};

export default withI18n()(ExperienceWidget);
