import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { Container, Row, Col, CandidateCardV2, Modal } from "cf-neo-ui";
import FaveUnfave from "../../../../components/fave-unfave";
import routes from "../../../layout/routes/hbfLayoutRoutes";
import classes from "./CandidatesList.module.scss";
import SingleCandidateModal from "../../../single-Candidate-page/SingleCandidateBody/SingleCandidateBodyLeftSlide/SingleCandidateModal/SingleCandidateModal";
import Routes from "../../../layout/routes";
import { inject, observer } from "mobx-react";

@inject("sessionStore", "candidatesSearchStore")
@observer
class CandidatesListForUsers extends Component {
  constructor(props) {
    super(props);
    this.listKey = 0;
    this.state = {
      isVisible: false,
      candidateID: null
    };
  }
  toDetails = id => {
    const { history } = this.props;
    history.push(`${routes.MissionsPage.path}/${id}`);
  };

  closeModal = () => {
    this.setState({ isVisible: false });
  };

  onShowDetailsHandler = candidateID => {
    const { sessionStore, history } = this.props;
    const {
      signUpUserRole,
      authToken,
      changeMessage,
      setBackRefCandidateSearch
    } = sessionStore;
    const { i18n } = this.props;

    if (authToken) {
      if (signUpUserRole === "CANDIDATE") {
        changeMessage(
          i18n._(
            t`Vous devez vous connecter avec un compte entreprise pour continuer.`
          )
        );
        setBackRefCandidateSearch("2");
        history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
      } else if (signUpUserRole === "CLIENT") {
        this.setState({
          isVisible: true,
          candidateID: parseInt(candidateID)
        });
      }
    } else {
      setBackRefCandidateSearch("1");
      history.push(Routes.HbLayoutRoutes.Login.path);
    }
  };

  getExperience = ex => {
    const { i18n } = this.props;
    switch (ex) {
      case 1:
        return i18n._(t`Junior (0 - 3)`);
      case 2:
        return i18n._(t`Confirmed (3 - 7)`);
      case 3:
        return i18n._(t`Senior (7 - 10)`);
      default:
        return i18n._(t`Expert (10+)`);
    }
  };

  render() {
    const { i18n, candidates, candidatesSearchStore } = this.props;
    const { changeDescription } = candidatesSearchStore;
    const { isVisible, candidateID } = this.state;
    return (
      <Container className={classes.missionsListing}>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={true}
          onClose={() => {
            this.setState({ isVisible: false });
            changeDescription("");
          }}
          position="fixed"
        >
          <SingleCandidateModal
            close={this.closeModal}
            candidateBhId={candidateID}
          />
        </Modal>
        <Row>
          {candidates.map(candidate => (
            <Col
              xl={3}
              lg={4}
              md={4}
              sm={6}
              xs={6}
              key={candidate.candidate_id}
              className={classes.missionCard}
            >
              <FaveUnfave candidateId={candidate.candidate_id}>
                <CandidateCardV2
                  id={candidate.candidate_id}
                  experience={this.getExperience(candidate.experience)} // "10 ans d'experience"
                  address={`${candidate.countryCode} ${
                    !candidate.zipCode ||
                    candidate.zipCode === "0" ||
                    candidate.zipCode === 0
                      ? ""
                      : candidate.zipCode
                  }`}
                  score={candidate.value}
                  occupation={candidate.occupation}
                  skills={candidate.skills}
                  required_skills={candidate.required_skills}
                  onShowDetails={() =>
                    this.onShowDetailsHandler(candidate.candidate_id)
                  }
                  href="https://www.club-freelance.com"
                  showDetailsLabel={i18n._(t`intéressé par ce candidat`)}
                  requiredSkillsTitle={i18n._(t`Compétences de la recherche`)}
                  secondarySkillsTitle={i18n._(
                    t`Autres compétences du candidat`
                  )}
                />
              </FaveUnfave>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

CandidatesListForUsers.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  candidates: PropTypes.arrayOf(PropTypes.shape({})),
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    changeMessage: PropTypes.func,
    setBackRefCandidateSearch: PropTypes.func,
    signUpUserRole: PropTypes.string
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    changeDescription: PropTypes.func
  }).isRequired
};

CandidatesListForUsers.defaultProps = {
  candidates: []
};

export default withI18n()(withRouter(CandidatesListForUsers));
