import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { Row, Col, Pagination, TagList } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import MISSIONS_QUERY from "./query";
import SearchBar from "../search-bar/SearchBar";
import GuestMissions from "./GuestMissions";
import UserMissions from "./UserMissions";
import classes from "./MissionsList.module.scss";
import LoadingMissions from "../../../../components/LoadingMissions/LoadingMissions";
import runtimeVars from "../../../../configs/runTimeVars";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";

const isOnePage = (pageSize, total) => {
  const pagesNbr = Math.floor((total - 1) / pageSize) + 1;
  return pagesNbr > 1;
};

@inject("missionsSearchStore", "sessionStore", "appStore")
@observer
class MissionsList extends Component {
  getQueryVariables = () => {
    const { missionsSearchStore, appStore } = this.props;
    const {
      sortBy,
      skip,
      limit,
      search,
      id,
      categories,
      skills,
      businessSectors,
      specialties,
      startDate,
      startDateRange,
      workType,
      employmentType,
      onSite,
      locations,
      radius
    } = missionsSearchStore;
    const { currentLanguage } = appStore;
    const temp = [];
    locations.map(location => {
      const theOb = {
        address1: location.address,
        city: location.city,
        countryID: location.country,
        lat: location.lat,
        lng: location.lng,
        state: location.state,
        zip: location.zip
      };
      temp.push(theOb);
      return null;
    });

    locations.map(location => {
      const theOb = {
        address1: location.address,
        city: location.city,
        countryID: location.country,
        lat: location.lat,
        lng: location.lng,
        state: location.state,
        zip: location.zip
      };
      temp.push(theOb);
      return null;
    });

    const filter = {
      search: search || null,
      id: id || null,
      categories: categories.length ? categories.slice() : null,
      skills: skills.length ? skills.slice() : null,
      businessSectors: businessSectors.length ? businessSectors.slice() : null,
      specialties: specialties.length ? specialties.slice() : null,
      startDate: startDate || null,
      startDateRange: parseInt(startDateRange, 10),
      workType,
      locations: locations.length ? temp.slice() : null,
      radius: parseInt(radius, 10) || 0,
      employmentType,
      onSite,
      site: runtimeVars.APP_WEBSITE,
      siteLanguage: currentLanguage
    };

    return { limit, skip, sortBy, filter };
  };

  componentWillUnmount() {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.clearFilters();
  }

  closeTagHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.removeSkill(val);
    missionsSearchStore.setSkillOptions();
  };

  render() {
    const { missionsSearchStore, sessionStore, i18n } = this.props;
    return (
      <Col noGutter lg={9} md={9} sm={12} style={{ height: "max-content" }}>
        <SearchBar />
        {missionsSearchStore.skills.length ? (
          <div className={classes.filterMissions}>
            <Trans>Filtres Sélectionnés :</Trans>
            <TagList
              tags={missionsSearchStore.skills}
              className={classes.tagList}
              variant="secondary"
              closable
              onClose={this.closeTagHandler}
            />
          </div>
        ) : (
          <div />
        )}
        <Query
          query={MISSIONS_QUERY}
          variables={this.getQueryVariables()}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <LoadingMissions
                  cardsCount={12}
                  colClassName={classes.missionCard}
                  containerClassName={classes.missionsListing}
                />
              );

            if (error)
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );

            const { jobOrders } = data;
            const resultsCount = jobOrders.length ? jobOrders[0].total : 0;

            // Refactor this, because it is the origin of the error "Cannot update during an existing state transition..."
            missionsSearchStore.changeResultsCount(resultsCount);

            const paginationProps = {
              pageSize: missionsSearchStore.limit,
              total: missionsSearchStore.resultsCount,
              current: missionsSearchStore.page,
              onChange: current => {
                missionsSearchStore.changePage(current);
                // scrollIntoView is web api function, read more here https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
                this.node.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest"
                });
              }
            };

            if (!missionsSearchStore.resultsCount)
              return (
                <p className={classes.noMission}>
                  <Trans>Désolé, pas de missions pour ces critères</Trans>
                </p>
              );

            return (
              <div
                // eslint-disable-next-line no-return-assign
                ref={node => (this.node = node)}
              >
                {sessionStore.authToken && sessionStore.candidate ? (
                  <UserMissions jobOrders={jobOrders} />
                ) : (
                  <GuestMissions jobOrders={jobOrders} />
                )}
                {isOnePage(
                  missionsSearchStore.limit,
                  missionsSearchStore.resultsCount
                ) ? (
                  <Row noGape>
                    <Col className={classes.pagination}>
                      <Pagination {...paginationProps} />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            );
          }}
        </Query>
      </Col>
    );
  }
}

MissionsList.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.shape({
    resultsCount: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
    changePage: PropTypes.func,
    changeResultsCount: PropTypes.func,
    sortBy: PropTypes.string,
    skip: PropTypes.number,
    search: PropTypes.string,
    id: PropTypes.string,
    categories: PropTypes.shape,
    skills: PropTypes.shape,
    businessSectors: PropTypes.shape,
    specialties: PropTypes.shape,
    startDate: PropTypes.string,
    startDateRange: PropTypes.number,
    workType: PropTypes.string,
    employmentType: PropTypes.string,
    onSite: PropTypes.string,
    locations: PropTypes.shape,
    radius: PropTypes.number,
    removeSkill: PropTypes.func,
    setSkillOptions: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    candidate: PropTypes.shape
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withI18n()(MissionsList);
