import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Query } from "react-apollo";
import { HistoricalComponent } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { VACANCY_HISTORY } from "../query";

@inject("jobSubmissionStore")
@observer
class History extends Component {
  render() {
    const { match, idOfCandidate } = this.props;
    return (
      <Query
        query={VACANCY_HISTORY}
        variables={{
          jobOrderID: Number.parseInt(match.params.id, 10),
          candidateID: idOfCandidate
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading) return <div>loading</div>;
          if (error)
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          const { vacancyHistory } = data;
          if (vacancyHistory.length > 0) {
            return (
              <div>
                {vacancyHistory.map(history => {
                  return (
                    <HistoricalComponent
                      idCandidate={history.candidateID}
                      date={history.modifiedAt}
                      userName={history.modifiedBy.name}
                      newStatus={history.status}
                      oldStatus=""
                    />
                  );
                })}
              </div>
            );
          }
          return (
            <div>
              <Trans>il n’y a pas d’historique disponible pour le moment</Trans>
            </div>
          );
        }}
      </Query>
    );
  }
}

History.wrappedComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape
  }).isRequired,
  idOfCandidate: PropTypes.number.isRequired
};

export default withI18n()(withRouter(History));
