import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import Zoom from "react-reveal/Zoom";
import classes from "./CompanySection1.module.scss";

class CompanySection1 extends Component {
  render() {
    return (
      <div className={classes.company}>
        <p>
          <Trans>Ils ont réalisés des projets avec nos freelances</Trans>
        </p>
        <div className={classes.comapniesLogos}>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/microsoft.webp"
                />
                <img
                  src="/assets/images/companies/microsoft.png"
                  alt="Microsoft"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/saintGobain.webp"
                />
                <img
                  src="/assets/images/companies/saintGobain.png"
                  alt="Saint Gobain"
                />
              </picture>
            </Zoom>
          </div>
          <div className={classes.logo}>
            <Zoom>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/companies/wynd.webp"
                />
                <img src="/assets/images/companies/wynd.png" alt="Wynd" />
              </picture>
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default withI18n()(CompanySection1);
