import gql from "graphql-tag";

const SINGLE_OPPORTUNITY_QUERY = gql`
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      _id
      title
      type
      onSite
      address {
        address1
        city
      }
      estimatedStartDate
      estimatedEndDate
      project
      workRate
      context
      description
      categories
      specialties
      skills
      certifications
      expectedBillRate
      spokenLanguages
      yearsRequired
      duration {
        months
        weeks
        days
      }
      status
      total
      purchaseOrder
    }
  }
`;

export { SINGLE_OPPORTUNITY_QUERY };
