import { ApolloLink, InMemoryCache } from "apollo-boost";
import ApolloClient from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import cookie from "react-cookies";
import fetch from "isomorphic-unfetch";
import runtimeVars from "../configs/runTimeVars";

const { AUTH_TOKEN } = runtimeVars;

const authLink = new ApolloLink((operation, forward) => {
  const token = cookie.load(AUTH_TOKEN) || localStorage.getItem(AUTH_TOKEN);

  operation.setContext({
    headers: {
      sourcesite: runtimeVars.APP_SOURCE_SITE,
      authorization: token || "",
      webSite: runtimeVars.APP_WEBSITE
    }
  });

  return forward(operation);
});

const link = ApolloLink.from([
  authLink,
  createUploadLink({
    ssr: false,
    uri: runtimeVars.APP_GRAPHQL_EP,
    fetch
  })
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default client;
