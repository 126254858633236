import { t } from "@lingui/macro";
import urls from "../../utils/urls";

const ldJson = i18n => ({
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: i18n._(t`entreprise`),
      item: urls.client()
    }
  ]
});

export default ldJson;
