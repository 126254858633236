import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import uniqid from "uniqid";
import { Row, Col, Button, toaster } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import SingleCertification from "./singleCertification";
import "../styles.scss";

@inject("sessionStore", "appStore")
@observer
class MyCertification extends Component {
  constructor(props) {
    super(props);
    this.onAddHandler = this.onAddHandler.bind(this);
    this.state = { saveCase: false };
    const { sessionStore } = props;
    const { changeIscandidateCertificationValid } = sessionStore;
    changeIscandidateCertificationValid(false);
  }

  onAddHandler() {
    const { sessionStore } = this.props;
    const {
      candidateCertification,
      changecandidateCertification
    } = sessionStore;
    const newcandidateCertification = candidateCertification.slice();
    this.setState({ saveCase: true });
    newcandidateCertification.push({
      id: uniqid(),
      isEditing: true,
      isNew: true
    });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(5);
    changecandidateCertification(newcandidateCertification);
  }

  updateCandidateCompletedHandler = () => {
    const { i18n, appStore } = this.props;
    toaster.success({
      title: i18n._(t`Ma Certification`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: true });
    appStore.refreshLayout();
  };

  isFormValid = () => {
    const { sessionStore, i18n } = this.props;
    const { iscandidateCertificationValid } = sessionStore;
    // the Certification has to be saved before submitted
    if (!iscandidateCertificationValid) {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Veuillez valider la certification ouverte avant l’enregistrement`
        )
      });
      return false;
    }
    return true;
  };

  turnSaveOn = () => {
    const { sessionStore } = this.props;
    const { changeSaveCaseEducation } = sessionStore;
    changeSaveCaseEducation(true);
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  render() {
    const { sessionStore } = this.props;
    const {
      _id,
      candidateCertification,
      iscandidateCertificationValid
    } = sessionStore;

    return (
      <div className="profile my-experience">
        <div className="formCard no-gutter with-padding">
          <Row>
            <Col />
            <Col>
              <div className="add-buttons">
                <Button
                  style={{ marginRight: "35px", "min-width": "221px" }}
                  disabled={!iscandidateCertificationValid}
                  onClick={this.onAddHandler}
                >
                  <Trans>Ajouter une Certification</Trans>
                </Button>
              </div>
            </Col>
          </Row>

          {candidateCertification.map(Certification => (
            <div>
              <SingleCertification
                {...Certification}
                key={Certification.id}
                showButton="true"
                onValidate={this.turnSaveOn}
              />
              <br />
            </div>
          ))}
        </div>
        <br />
      </div>
    );
  }
}

MyCertification.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    candidateWorkHistory: PropTypes.shape,
    changeCandidateWorkHistory: PropTypes.shape,
    changeIsCandidateWorkHistoryValid: PropTypes.func,
    _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isCandidateWorkHistoryValid: PropTypes.bool
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func
  }).isRequired
};

export default withI18n()(MyCertification);
