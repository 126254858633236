import gql from "graphql-tag";

const FAVE_MISSION = gql`
  mutation FavMission($jobOrderId: ID!) {
    favMission(jobOrderId: $jobOrderId) {
      id
    }
  }
`;

const UNFAVE_MISSION = gql`
  mutation UnFavMission($jobOrderId: ID!) {
    unFavMission(jobOrderId: $jobOrderId) {
      id
    }
  }
`;

export { FAVE_MISSION, UNFAVE_MISSION };
