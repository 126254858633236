import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Notification, Modal } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import classes from "./dashboard.module.scss";
import Offers from "./offers";
import RecommendedTalents from "./recommended-talents";
import CalendarCard from "./calendar-card";
import StatisticsCard from "./statistics-card";
import SuggestCard from "../../components/SuggestCardClient/SuggestCard";
import Routes from "../layout/routes";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import configs from "../../configs/club-freelance-globals";
import PopUpClosedOffer from "../dashboard-offers/single-offer/pop-up/PopUpClosedOffer";
import SingleCandidate from "../dashboard-single-candidate";

@inject("sessionStore", "appStore", "modalStore", "postANeedStore")
@observer
class DashboardCompany extends Component {
  constructor(props) {
    super(props);
    const { id } = props.sessionStore;
    props.postANeedStore.reset();
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  completeButtonHandler = () => {
    const { history } = this.props;
    history.push(Routes.LpbLayoutRoutes.MyInformation.path);
  };

  toDocs = () => {
    const { i18n } = this.props;
    if (i18n.language === "fr") window.open(configs.advicesEnterprise_fr);
    else window.open(configs.advicesEnterprise_uk);
  };

  render() {
    const { i18n, modalStore, appStore, history } = this.props;
    const { isVisible, isVisible2 } = modalStore;
    const meta = staticMetaTags(i18n).dashboardCompany;
    return (
      <div className={classes.dashboard}>
        {metaTags(
          urls.dashboardCompany(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <Container>
          <Modal
            centred
            isVisible={isVisible}
            withCloseButton={false}
            position="fixed"
          >
            <PopUpClosedOffer />
          </Modal>
          <Modal
            isVisible={isVisible2}
            withCloseButton={false}
            position="fixed"
          >
            <div
              style={{
                width: "80%",
                margin: "auto"
              }}
            >
              <SingleCandidate />
            </div>
          </Modal>
          <Row>
            <Col lg={8} md={8} sm={12} xs={6}>
              <div className={classes.alert}>
                <SuggestCard
                  text={i18n._(
                    t`Vous recherchez des profils de consultants IT expérimentés ?
                    Trouvez les dès maintenant avec notre algorithme de matching SMATCH`
                  )}
                  buttonLabel={i18n._(t`Rechercher un consultant`)}
                  masked={false}
                  onClick={() =>
                    history.push(
                      Routes.HbfLayoutRoutes.SearchCandidatesPage.path
                    )
                  }
                  withBeta={true}
                />
                {/*{appStore.width < 768 ? (*/}
                {/*  <Notification*/}
                {/*    notificationTitle={i18n._(t`Ressources`)}*/}
                {/*    text={i18n._(*/}
                {/*      t`Découvrez nos ressources pour les entreprises`*/}
                {/*    )}*/}
                {/*    buttonLabel={i18n._(t`Découvrir les ressources`)}*/}
                {/*    onClickButton={this.toDocs}*/}
                {/*    h={150}*/}
                {/*  />*/}
                {/*) : (*/}
                {/*  <Notification*/}
                {/*    notificationTitle={i18n._(t`Ressources`)}*/}
                {/*    text={i18n._(*/}
                {/*      t`Découvrez nos ressources pour les entreprises`*/}
                {/*    )}*/}
                {/*    buttonLabel={i18n._(t`Découvrir les ressources`)}*/}
                {/*    onClickButton={this.toDocs}*/}
                {/*    h={80}*/}
                {/*  />*/}
                {/*)}*/}
              </div>
              <Offers />
              <RecommendedTalents />
            </Col>
            <Col lg={4} md={4} sm={12} xs={6}>
              <CalendarCard />
              <StatisticsCard />
              <div style={{ borderRadius: "5px", marginTop: "25px" }}>
                {appStore.width < 768 ? (
                  <Notification
                    notificationTitle={i18n._(t`Ressources`)}
                    text={i18n._(
                      t`Découvrez nos ressources pour les entreprises`
                    )}
                    buttonLabel={i18n._(t`Voir les ressources`)}
                    onClickButton={this.toDocs}
                    h={150}
                  />
                ) : (
                  <Notification
                    notificationTitle={i18n._(t`Ressources`)}
                    text={i18n._(
                      t`Découvrez nos ressources pour les entreprises`
                    )}
                    buttonLabel={i18n._(t`Voir les ressources`)}
                    onClickButton={this.toDocs}
                    h={80}
                  />
                )}
              </div>
              {/*<SuggestCard*/}
              {/*  text={i18n._(*/}
              {/*    t`Vous souhaitez coopter quelqu’un de votre entourage ?`*/}
              {/*  )}*/}
              {/*  buttonLabel={i18n._(t`Coopter`)}*/}
              {/*/>*/}
              <SuggestCard
                text={i18n._(
                  t`Devenez apporteur d’affaire et faites-vous rémunérer`
                )}
                buttonLabel={i18n._(t`Coopter`)}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

DashboardCompany.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    isVisible2: PropTypes.bool,
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeModalType: PropTypes.func,
    modalType: PropTypes.string
  }).isRequired,
  postANeedStore: PropTypes.shape({
    reset: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(DashboardCompany));
