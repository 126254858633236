import moment from "moment";
import cfConfig from "../../configs/club-freelance-globals";
import urls from "../../utils/urls";
import runtimeVars from "../../configs/runTimeVars";

const employemntTypes = (customText1, employmentType) => {
  const res = [];
  if (customText1)
    res.push(
      customText1.toLowerCase() === "full time" ? "FULL_TIME" : "PART_TIME"
    );

  if (employmentType)
    res.push(
      employmentType.toLowerCase() === "contract" ? "CONTRACTOR" : "TEMPORARY"
    );
  return res;
};

const ldJson = (i18n, jobOrder) => ({
  "@context": "https://schema.org/",
  "@type": "JobPosting",
  title: jobOrder.title,
  description: `<p>${jobOrder.customTextBlock1 ||
    "club freelance mission"}</p>`,
  identifier: {
    "@type": "PropertyValue",
    name: cfConfig.title,
    value: jobOrder.id
  },
  datePosted: moment(jobOrder.dateAdded).format("YYYY-MM-DD"),
  jobLocation: {
    "@type": "Place",
    address: {
      "@type": "PostalAddress",
      streetAddress: "n/a",
      addressRegion: jobOrder.address.city,
      addressLocality: jobOrder.address.city,
      postalCode: "n/a",
      addressCountry: runtimeVars.APP_LANG
    }
  },
  employmentType: employemntTypes(
    jobOrder.customText1,
    jobOrder.employmentType
  ),
  hiringOrganization: {
    "@type": "Organization",
    name: "MagsRUs Wheel Company",
    logo: "http://www.example.com/images/logo.png",
    sameAs: [
      urls.home(),
      cfConfig.socialMedia.linkedIn,
      cfConfig.socialMedia.twitter,
      cfConfig.socialMedia.facebook
    ]
  }
});

export default ldJson;
