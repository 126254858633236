import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { ThemeProvider } from "cf-neo-ui";
import { I18nProvider } from "@lingui/react";
import { inject, observer } from "mobx-react";
import catalogEn from "./locales/en/messages";
import catalogFr from "./locales/fr/messages";
import theme from "./configs/theme";
import "./App.scss";
import ServerTest from "./components/ServerTest/ServerTest";
import NotFoundPage from "./components/error-pages/NotFoundPage";

const catalogs = {
  en: catalogEn,
  fr: catalogFr
};

@inject("appStore", "sessionStore")
@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingContent: true };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    window.location.replace(`https://fr.mindquest.io${pathname}`);
  }

  render() {
    const { loadingContent } = this.state;
    const { forcedError, appStore } = this.props;
    // const { currentLanguage } = appStore;
    let currentLanguage = "fr";
    return (
      <div>
        {loadingContent && (
          <div className="center-loading_logo">
            <div className="lds-ripple">
              <div />
              <div />
            </div>
            <picture>
              <source
                type="images/webp"
                srcSet="/assets/icons/android/icon-72.webp"
              />
              <img
                src="/assets/icons/android/icon-72.png"
                alt="club freelance"
                className="loading_logo"
              />
            </picture>
          </div>
        )}
        <div
          style={{
            visibility: loadingContent ? "hidden" : "visible"
          }}
        >
          <ThemeProvider theme={theme}>
            <I18nProvider language={currentLanguage} catalogs={catalogs}>
              {forcedError ? (
                <NotFoundPage message={forcedError.toString()} />
              ) : (
                <ServerTest />
              )}
            </I18nProvider>
          </ThemeProvider>
        </div>
      </div>
    );
  }
}

App.wrappedComponent.propTypes = {
  forcedError: PropTypes.shape({}),
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

App.defaultProps = {
  forcedError: null
};

export default App;
