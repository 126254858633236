import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Row, Col } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import $ from "jquery";

import FreelanceCard from "../../../components/FreelanceCard";
import EntrepriseCard from "../../../components/EntrepriseCard";
import "./style.scss";
import classes from "./CardSection.module.scss";
import carouselConfig from "./CarouselConfig";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

@inject("appStore")
@observer
class CardSection extends Component {
  state = {
    render: false
  };

  componentDidMount() {
    this.setState({ render: true });
  }

  render() {
    const { render } = this.state;
    if (!render) return null;
    const { appStore } = this.props;
    if (typeof window !== "undefined") {
      window.$ = $;
      window.jQuery = $;
    }

    /* eslint-disable global-require */
    const OwlCarousel = require("react-owl-carousel");
    /* eslint-enable global-require */
    return (
      <div className={classes.card}>
        <Row>
          <img
            src="/assets/images/banner_cf_mq.gif"
            alt="club freelance"
            className={classes.banner_cf_to_mq}
          />
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} xl={6} noGutter>
            <Fade>
              <p className={classes.text}>
                <Trans>
                  Des hommes et des algorithmes pour un matching parfait entre{" "}
                  <span>entreprises</span> et <span>freelances IT</span>
                </Trans>
              </p>
            </Fade>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6} noGutter />
        </Row>

        {appStore.width < 840 ? (
          <div className={classes.carousel}>
            <OwlCarousel {...carouselConfig}>
              <div className="item" key={this.key}>
                <FreelanceCard />
              </div>
              <div className="item" key={this.key}>
                <EntrepriseCard />
              </div>
            </OwlCarousel>
          </div>
        ) : (
          <Row className={classes.row}>
            <div className={classes.cardleft}>
              <FreelanceCard />
            </div>

            <div className={classes.cardright}>
              <EntrepriseCard />
            </div>
          </Row>
        )}
      </div>
    );
  }
}

export default withI18n()(CardSection);
