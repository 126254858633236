import React, { Component } from "react";
import { Col, Container, Row } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./banner.module.scss";

class Banner extends Component {
  render() {
    return (
      <footer>
        <div className={classes.bgImg}>
          <picture>
            <source type="image/webp" srcSet="/assets/images/footerbg.webp" />
            <img src="/assets/images/footerbg.png" alt="footer" />
          </picture>
        </div>
        <Container className={classes.container}>
          <div className={classes.content}>
            <h1>
              <Trans>Notre performance en chiffres</Trans>
            </h1>
            <Row>
              <Col xs={6} sm={6} md={3} lg={3} className={classes.colone}>
                <span>
                  48 <Trans>heures</Trans>
                </span>
                <p>
                  <Trans>
                    Délai moyen pour recevoir une shortlist de candidats
                    qualifiés
                  </Trans>
                </p>
              </Col>
              <Col xs={6} sm={6} md={3} lg={3} className={classes.colone}>
                <span>+1000</span>
                <p>
                  <Trans>Placements réussis chez nos clients</Trans>
                </p>
              </Col>
              <Col xs={6} sm={6} md={3} lg={3} className={classes.colone}>
                <span>77%</span>
                <p>
                  <Trans>Contrats reconduits par nos clients</Trans>
                </p>
              </Col>
              <Col xs={6} sm={6} md={3} lg={3} className={classes.colone}>
                <span>130</span>
                <p>
                  <Trans>Clients grands comptes qui nous font confiance</Trans>
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Banner;
