import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";
import classes from "./errorPage.module.scss";

class ServerStoppedPage extends PureComponent {
  render() {
    const { history } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.breakLogo}>
          <img src="/assets/svg/ServerStopped.svg" alt="server stopped" />
        </div>
        <div className={classes.text}>
          <p>
            <Trans>Le serveur ne répond pas</Trans>
          </p>
          <button
            name="goback"
            type="button"
            onClick={history.goBack}
            className={classes.link}
          >
            <Trans>le problème sera bient&ocirc;t bientôt résolu</Trans>
          </button>
        </div>
      </div>
    );
  }
}

ServerStoppedPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired
};

export default withRouter(ServerStoppedPage);
