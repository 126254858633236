import gql from "graphql-tag";

const CLIENT_VAC_STATS_QUERY = gql`
  query VacanciesStats(
    $id: Int!
    $dateStart: Float
    $dateEnd: Float
    $skip: Int
    $limit: Int
  ) {
    vacanciesStats(
      id: $id
      filter: {
        dateStart: $dateStart
        dateEnd: $dateEnd
        skip: $skip
        limit: $limit
      }
    ) {
      vacancies {
        id
        title
        totalNotes
        totalShortListed
        totalCVSent
        totalInterviews
        totalPlacements
      }
    }
  }
`;

export default CLIENT_VAC_STATS_QUERY;
