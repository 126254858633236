import { t } from "@lingui/macro";

const languageList = i18n => [
  {
    text: i18n._(t`English`),
    value: "English"
  },
  {
    text: i18n._(t`French`),
    value: "French"
  },
  {
    text: i18n._(t`Spanish`),
    value: "Spanish"
  },
  {
    text: i18n._(t`German`),
    value: "German"
  },
  {
    text: i18n._(t`Portuguese`),
    value: "Portuguese"
  },
  {
    text: i18n._(t`Arabic`),
    value: "Arabic"
  },
  {
    text: i18n._(t`Romanian`),
    value: "Romanian"
  },
  {
    text: i18n._(t`Russian`),
    value: "Russian"
  },
  {
    text: i18n._(t`Dutch`),
    value: "Dutch"
  },
  {
    text: i18n._(t`Japanese`),
    value: "Japanese"
  },
  {
    text: i18n._(t`Chinese`),
    value: "Chinese"
  },
  {
    text: i18n._(t`Turkish`),
    value: "Turkish"
  },
  {
    text: i18n._(t`Italian`),
    value: "Italian"
  },
  {
    text: i18n._(t`Hindi`),
    value: "Hindi"
  },
  {
    text: i18n._(t`Polish`),
    value: "Polish"
  },
  {
    text: i18n._(t`Greek`),
    value: "Greek"
  }
];

export default languageList;
