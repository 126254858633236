import React, { Component } from "react";
import { IllustrationIcons } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import "./styles.scss";
import theme from "../../../../configs/theme";

class LeftSide extends Component {
  render() {
    return (
      <div className="left-side">
        <div className="illustration-icons">
          <br />
          <IllustrationIcons
            type="search"
            color2={theme.colors.mainColorGradient}
          />
        </div>
        <div className="center-txt text-bold">
          <Trans>Bon à savoir</Trans>!{" "}
        </div>
        <br />
        <div className="center-txt text-normal">
          <div>
            <Trans>
              Trouvez dès maintenant les profils les plus pertinents grâce à
              notre algorithme de matching SMATCH
            </Trans>
          </div>
        </div>
      </div>
    );
  }
}

export default LeftSide;
