import gql from "graphql-tag";

export const UPDATE_APPLICATION_REFERENCE = gql`
  mutation UpdateApplicationReference($input: UpdateApplicationReferenceInput) {
    updateApplicationReference(input: $input) {
      reference {
        referenceEmail
        referenceFirstName
        referenceLastName
        referencePhone
      }
    }
  }
`;
