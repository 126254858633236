import React, { Component } from "react";
import { Icon } from "cf-neo-ui";
import classes from "./HeaderMenu.module.scss";
import HeaderMenu from "./HeaderMenu";

class menuManager extends Component {
  constructor(props) {
    super(props);
    this.nodeRef = null;
    this.state = { toggle: false };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickOutsideHandler, {
      passive: true
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickOutsideHandler);
  }

  clickOutsideHandler = e => {
    if (this.nodeRef && !this.nodeRef.contains(e.target))
      this.setState({ toggle: false });
  };

  menuHandler = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };

  render() {
    const { toggle } = this.state;
    return (
      <span>
        <button
          type="button"
          className={classes.barsButton}
          onClick={this.menuHandler}
        >
          <Icon type="bars" width={23} height={23} color="#BBBABA" />
        </button>
        <span
          ref={node => {
            this.nodeRef = node;
          }}
        >
          {toggle && <HeaderMenu onClose={this.menuHandler} />}
        </span>
      </span>
    );
  }
}

menuManager.propTypes = {};

export default menuManager;
