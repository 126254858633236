import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { Container, Row, Col, MissionCardSkeleton } from "cf-neo-ui";

class LoadingMissions extends PureComponent {
  render() {
    const {
      cardsCount,
      xl,
      lg,
      md,
      sm,
      xs,
      colClassName,
      containerClassName,
      rowClassName
    } = this.props;
    const cards = [];
    for (let i = 0; i < cardsCount; i += 1) {
      cards.push(
        <Col
          xl={xl}
          lg={lg}
          md={md}
          sm={sm}
          xs={xs}
          key={i}
          className={colClassName}
        >
          <MissionCardSkeleton />
        </Col>
      );
    }

    return (
      <Container className={containerClassName}>
        <Row className={rowClassName}>{cards}</Row>
      </Container>
    );
  }
}

LoadingMissions.propTypes = {
  cardsCount: PropTypes.number.isRequired,
  xl: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  colClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  containerClassName: PropTypes.string
};

LoadingMissions.defaultProps = {
  xl: 3,
  lg: 3,
  md: 4,
  sm: 6,
  xs: 6,
  colClassName: "",
  rowClassName: "",
  containerClassName: ""
};

export default LoadingMissions;
