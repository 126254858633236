import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import {
  Row,
  Col,
  AutoComplete,
  TagList,
  Validation,
  Button,
  FormCard,
  FormGroup,
  TextInput,
  Select,
  InputSkeleton,
  Radio,
  RadioGroup
} from "cf-neo-ui";
import Routes from "../layout/routes/index";
import validate from "../../utils/validators";
import "./styles.scss";
import theme from "../../configs/theme";

const GET_AREA_ACTIVITIES = gql`
  {
    businessSectors {
      name
    }
  }
`;

@inject("sessionStore")
@observer
class MyCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompanyNameValid: true,
      isActivityAreaValid: true,
      isSizeOfTheCompanyValid: true,
      companyNameValidationMessage: "",
      activityAreaValidationMessage: "",
      sizeOfTheCompanyValidationMessage: ""
    };
    this.onNextStepHandler = this.onNextStepHandler.bind(this);
    // this.onIgnoreStepHandler = this.onIgnoreStepHandler.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  /* onIgnoreStepHandler() {
    const { history, sessionStore } = this.props;

    sessionStore.resetFields([
      "companyName",
      "activityArea",
      "sizeOfTheCompany",
      "isESN"
    ]);
    history.push(Routes.HbLayoutRoutes.MyInformationClient.path);
  }
  */

  onNextStepHandler() {
    const valid = this.isFormValid();
    if (!valid) return;
    const { history } = this.props;
    history.push(Routes.HbLayoutRoutes.MyInformationClient.path);
  }

  companyNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeCompanyName(e.target.value);
    this.validateCompanyName(e.target.value);
  };

  sizeOfTheCompanyChangeHandler = item => {
    const { sessionStore } = this.props;
    sessionStore.changeSizeOfTheCompany(item.value);
    this.validateSizeOfTheCompany(item.value);
  };

  activityAreaAddHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.addActivityArea(value);
    this.validateActivityArea(sessionStore.activityArea);
  };

  activityAreaRemoveHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.removeActivityArea(value);
    this.validateActivityArea(sessionStore.activityArea);
  };

  isESNChangeHandler = value => {
    const { sessionStore } = this.props;
    sessionStore.changeIsESN(value);
  };

  isFormValid = () => {
    const { sessionStore } = this.props;
    const { companyName, activityArea, sizeOfTheCompany } = sessionStore;
    let valid = true;
    if (!this.validateCompanyName(companyName)) valid = false;
    if (!this.validateActivityArea(activityArea)) valid = false;
    if (!this.validateSizeOfTheCompany(sizeOfTheCompany)) valid = false;

    return valid;
  };

  validateCompanyName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 100 }, { minlength: 3 }],
      i18n
    );
    this.setState({
      isCompanyNameValid: res.isValid,
      companyNameValidationMessage: res.message
    });
    return res.isValid;
  }

  validateSizeOfTheCompany(value) {
    const { i18n } = this.props;
    if (value === 0) {
      this.setState({
        isSizeOfTheCompanyValid: false,
        sizeOfTheCompanyValidationMessage: i18n._(
          t`vous devez préciser la taille de votre entreprise`
        )
      });
      return false;
    }
    this.setState({
      isSizeOfTheCompanyValid: true,
      sizeOfTheCompanyValidationMessage: ""
    });

    return true;
  }

  validateActivityArea(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      [
        "required",
        {
          rule: { minlength: 1 },
          msg: i18n._(t`Choisissez au moins un secteur d’activité`)
        }
      ],
      i18n
    );
    this.setState({
      isActivityAreaValid: res.isValid,
      activityAreaValidationMessage: res.message
    });
    return res.isValid;
  }

  render() {
    const { sessionStore, i18n } = this.props;
    const {
      isCompanyNameValid,
      isActivityAreaValid,
      isSizeOfTheCompanyValid,
      companyNameValidationMessage,
      activityAreaValidationMessage,
      sizeOfTheCompanyValidationMessage
    } = this.state;
    let select0 = false;
    let select1 = false;
    let select2 = false;
    let select3 = false;
    let select4 = false;

    const {
      changeStep,
      companyName,
      activityArea,
      sizeOfTheCompany,
      isESN
    } = sessionStore;
    changeStep(1);

    if (sizeOfTheCompany === 4) {
      select4 = true;
    } else if (sizeOfTheCompany === 3) {
      select3 = true;
    } else if (sizeOfTheCompany === 2) {
      select2 = true;
    } else if (sizeOfTheCompany === 1) {
      select1 = true;
    } else {
      select0 = true;
    }

    return (
      <div className="session ">
        <br />

        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Mon entreprise</Trans>
            </h1>
          </Col>
        </Row>

        <br />

        <FormCard className="formCard no-gutter with-padding">
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="company_name">
                  <Trans>Entreprise</Trans>
                </label>
                <Validation
                  errorMessage={companyNameValidationMessage}
                  valid={isCompanyNameValid}
                >
                  <TextInput
                    id="company_name"
                    className="form-input"
                    type="text"
                    placeholder={i18n._(t`Nom de l’entreprise`)}
                    iconColor="#D3354A"
                    value={companyName}
                    onChange={this.companyNameChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="company_size">
                  <Trans>Taille de l’entreprise</Trans>
                </label>
                <Validation
                  errorMessage={sizeOfTheCompanyValidationMessage}
                  valid={isSizeOfTheCompanyValid}
                >
                  <Select
                    id="company_size"
                    options={{
                      groupA: [
                        {
                          text: i18n._(t`entrer la taille de votre entreprise`),
                          disabled: true,
                          value: 0,
                          selected: select0
                        },
                        {
                          text: i18n._(t`Petite entreprise (1-50)`),
                          value: 1,
                          selected: select1
                        },
                        {
                          text: i18n._(t`Moyenne entreprise (51-100)`),
                          value: 2,
                          selected: select2
                        },
                        {
                          text: i18n._(t`Grande entreprise (101-500)`),
                          value: 3,
                          selected: select3
                        },
                        {
                          text: i18n._(t`Grand Groupe (500+)`),
                          value: 4,
                          selected: select4
                        }
                      ]
                    }}
                    onSelect={this.sizeOfTheCompanyChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="company_activity_area">
                  <Trans>Secteur d’activité</Trans>
                </label>
                <div>
                  <Query query={GET_AREA_ACTIVITIES}>
                    {({ loading, error, data }) => {
                      if (loading) return <InputSkeleton />;
                      if (error)
                        return `${i18n._(t`Erreur`)} !${error.message}`;
                      const allBusinessSectors = data.businessSectors.map(
                        bs => bs.name
                      );
                      return (
                        <div className="withSrcollBar">
                          <AutoComplete
                            id="company_activity_area"
                            clearOnSelect
                            options={allBusinessSectors}
                            iconColor={theme.colors.mainColor}
                            iconColor2={theme.colors.lightMainColor}
                            icon="chevron-down"
                            placeholder={i18n._(
                              t`Rechercher un secteur d’activité`
                            )}
                            onSelect={val => this.activityAreaAddHandler(val)}
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
                <Validation
                  errorMessage={activityAreaValidationMessage}
                  valid={isActivityAreaValid}
                >
                  <TagList
                    tags={activityArea}
                    variant="secondary"
                    closable
                    className="tag-list"
                    onClose={val => this.activityAreaRemoveHandler(val)}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <label className="form-label" htmlFor="company_type">
                  <Trans>Je suis ESN</Trans>
                </label>
                <div className="radio-buttons-esn">
                  <RadioGroup
                    id="company_type"
                    name="RadioGrp"
                    onChange={this.isESNChangeHandler}
                    defaultSelected={isESN}
                    orientation="horizontal"
                  >
                    <Radio label={i18n._(t`Non`)} value={false} />
                    <Radio label={i18n._(t`Oui`)} value />
                  </RadioGroup>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </FormCard>

        <div className="next-buttons">
          {/* <Button variant="secondary" onClick={this.onIgnoreStepHandler}>
            <Trans>Passer cette étape</Trans>
          </Button> */}
          <Button icon="chevron-right" onClick={this.onNextStepHandler}>
            <Trans>Étape suivante</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

MyCompany.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    companyName: PropTypes.string,
    changeCompanyName: PropTypes.func,
    sizeOfTheCompany: PropTypes.number,
    changeSizeOfTheCompany: PropTypes.func,
    changeStep: PropTypes.func,
    isESN: PropTypes.bool,
    changeIsESN: PropTypes.func,
    activityArea: PropTypes.shape,
    addActivityArea: PropTypes.func,
    removeActivityArea: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(MyCompany));
