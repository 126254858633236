import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { MapCard, H2 } from "cf-neo-ui";
import CFConfigs from "../../../configs/club-freelance-globals";
import classes from "./AddressMaps.module.scss";

class AddressMaps extends PureComponent {
  onClickMap = country => {
    const { address } = CFConfigs;
    window.open(`https://goo.gl/maps/${address[country]}`);
  };

  render() {
    const { i18n } = this.props;
    const { barcelone, london, paris } = CFConfigs.locations;
    return (
      <div className={classes.addressMaps}>
        <H2 className={classes.heading}>
          <Trans>Nos bureaux</Trans>
        </H2>
        <div className={classes.mapContainer}>
          <MapCard
            image="/assets/images/maps/london-address.png"
            city={i18n._(t`Londres`)}
            sub1={i18n._(t`Club Freelance Limited`)}
            sub2={london.streetAddress}
            sub3={london.postalCode + london.addressRegion}
            onClickMap={() => this.onClickMap("london")}
            className={classes.mapCard}
          />
          <MapCard
            image="/assets/images/maps/paris-address.png"
            city={i18n._(t`Paris`)}
            sub1={i18n._(t`Club Freelance SAS`)}
            sub2={paris.streetAddress}
            sub3={paris.postalCode + paris.addressRegion}
            onClickMap={() => this.onClickMap("paris")}
            className={classes.mapCard}
          />
          <MapCard
            image="/assets/images/maps/barcelone-address.png"
            city={i18n._(t`Barcelone`)}
            sub1={i18n._(t`Club Freelance Iberica`)}
            sub2={barcelone.streetAddress}
            sub3={barcelone.postalCode}
            onClickMap={() => this.onClickMap("barcelone")}
            className={classes.mapCard}
          />
        </div>
      </div>
    );
  }
}

AddressMaps.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(AddressMaps);
