import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Container, Row } from "cf-neo-ui";

class MainContent extends Component {
  render() {
    const { component } = this.props;
    const RenderComponent = component;

    return (
      <Container className="main-content">
        <Row>
          <Col lg={12}>
            <RenderComponent />
          </Col>
        </Row>
      </Container>
    );
  }
}

MainContent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
};

export default MainContent;
