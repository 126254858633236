import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import classes from "./tooltip.module.scss";

class Tooltip extends PureComponent {
  render() {
    const { message, autoDetect } = this.props;
    return (
      <div className={autoDetect ? classes.containerR : classes.containerL}>
        <div className={autoDetect ? classes.tooltipR : classes.tooltipL}>
          <div className={classes.text}>{message}</div>
        </div>
      </div>
    );
  }
}

Tooltip.propTypes = {
  message: PropTypes.string,
  autoDetect: PropTypes.bool
};
Tooltip.defaultProps = {
  message: "",
  autoDetect: false
};

export default Tooltip;
