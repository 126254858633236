import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Radio, RadioGroup } from "cf-neo-ui";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import classes from "../SideFilters.module.scss";

@inject("missionsSearchStore")
@observer
class OnSiteWidget extends Component {
  render() {
    const { i18n, missionsSearchStore } = this.props;
    const { changeOnSite, onSite } = missionsSearchStore;
    const orientation = "vertical";
    return (
      <div>
        <RadioGroup
          className={classes.radioOnSite}
          orientation={orientation}
          name="onSite"
          defaultSelected={onSite}
          onChange={changeOnSite}
        >
          <Radio label={i18n._(t`Remote`)} value="Remote" />
          <Radio label={i18n._(t`Présentiel`)} value="Onsite" />
          <Radio label={i18n._(t`Remote partiel`)} value="Part-time remote" />
          <Radio label={i18n._(t`Indifferent`)} value="" />
        </RadioGroup>
      </div>
    );
  }
}

OnSiteWidget.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  missionsSearchStore: PropTypes.shape({
    changeOnSite: PropTypes.func,
    onSite: PropTypes.string
  }).isRequired
};

export default withI18n()(OnSiteWidget);
