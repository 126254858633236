import ConfirmCode from "../../session/confirmCode";
import MyProfile from "../../session/myProfile";
import MyJob from "../../session/myJob";
import MyExperience from "../../session/myExperience";
import MySearch from "../../session/mySearch";
import MyInformation from "../../session/myInformation";
/** !!! For any modification in this file, please re-do it in src/utils/urls.js */

const Hb2LayoutRoutes = {
  ConfirmCode: {
    path: "/signup-confirm-code",
    component: ConfirmCode,
    logoutRequired: false
  },
  MyProfile: {
    path: "/signup-my-profile",
    component: MyProfile,
    logoutRequired: true
  },
  MyInformation: {
    path: "/signup-my-information",
    component: MyInformation,
    logoutRequired: true
  },
  MyExperience: {
    path: "/signup-my-experience",
    component: MyExperience,
    logoutRequired: true
  },
  MySearch: {
    path: "/signup-my-search",
    component: MySearch,
    logoutRequired: true
  },
  MyJob: { path: "/signup-my-job", component: MyJob, logoutRequired: true }
};

export default Hb2LayoutRoutes;
