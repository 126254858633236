import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import {
  Col,
  Row,
  Button,
  StepsBarV2,
  BlocGraphs,
  SchemaOrganisationV2,
  SchemaApproach
} from "cf-neo-ui";
import Banner from "./banner/Banner";
import Routes from "../layout/routes";
import classes from "./AboutPage.module.scss";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import CompanySection1 from "./CompanySection/CompanySection1";
import configs from "../../configs/club-freelance-globals";
import ldJson from "./ldJson";
import HbLayoutRoutes from "../layout/routes/hbLayoutRoutes";

@inject("sessionStore", "appStore")
@observer
class AboutPage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  onClickButton = () => {
    window.open(configs.marketIntel, "_blank");
  };

  openPage = provider => {
    window.open(configs.socialMedia[provider], "_blank");
  };

  render() {
    const { history, i18n, appStore } = this.props;
    const { currentLanguage } = appStore;
    const { HbLayoutRoutes, HbfLayoutRoutes } = Routes;
    const { SignUp } = HbLayoutRoutes;
    const { SearchCandidatesPage } = HbfLayoutRoutes;
    const meta = staticMetaTags(i18n).about;
    const text1 = i18n._(
      t`Basés au sein de notre Sourcing Hub à Barcelone, nos Talent Community Specialists sont en contact permanent avec les meilleurs profils tech et IT. Ils sont en mesure d’identifier et d’accompagner les meilleurs talents dans toute l’Europe.`
    );
    const text2 = i18n._(
      t`Nos équipes innovation et support travaillent à l’optimisation constante de nos outils et process pour vous proposer la meilleure expérience possible.`
    );
    const text3 = i18n._(
      t`Basés localement et spécialisés par secteur d’activité, nos Key Account Managers accompagnent leurs entreprises clientes dans la recherche des meilleures talents pour leurs projets IT.`
    );
    const title1 = i18n._(t`Sourcing Hub`);
    const title2 = i18n._(t`Équipes innovation & support`);
    const title3 = i18n._(t`Account Managers locaux`);

    return (
      <div className={classes.container}>
        {metaTags(
          urls.about(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords,
          ldJson
        )}
        <div className={classes.text}>
          <div>
            <img
              src="/assets/images/banner_cf_mq.gif"
              alt="club freelance"
              className={classes.banner_cf_to_mq}
            />
          </div>

          <p className={classes.title}>
            <Trans>Club Freelance,</Trans>
          </p>
          <p className={classes.slogan}>
            <Trans>
              Des hommes et des algorithmes pour un matching parfait entre
              entreprises et freelances informatique.
            </Trans>
          </p>
          <div className={classes.description}>
            <p>
              <Trans>
                Club Freelance est une société de placement de consultants
                indépendants dans la technologie et l’informatique.
              </Trans>
            </p>
            <p>
              <Trans>
                Notre mission est d’une part d’aider nos entreprises clientes à
                attirer et fidéliser les meilleurs talents pour répondre à leurs
                besoins stratégiques, et d’autre part d’accompagner nos
                consultants dans leur carrière en les aidant à trouver les
                missions les plus adaptées à leur profil et leurs aspirations.
              </Trans>
            </p>
            <p>
              <Trans>
                Nous accordons une grande place à l’humain et nouons des
                relations de long terme avec nos clients et nos consultants.
              </Trans>
            </p>
          </div>
          <div className={classes.foot}>
            <span>
              - Thomas Delfort, Manuela Garampon – <Trans>Co-fondateurs</Trans>
            </span>
          </div>
        </div>
        <Row />

        <div className={classes.bar}>
          <StepsBarV2
            stepsNumber={4}
            events={[
              {
                year: "2014",
                event: i18n._(t`Création de Club Freelance`)
              },
              {
                year: "2016",
                event: i18n._(t`Ouverture du bureau commercial à paris`)
              },
              {
                year: "2017",
                event: i18n._(t`Ouverture du Sourcing Hub à Barcelone`)
              },
              {
                year: "2019",
                event: i18n._(t`Déploiement international`)
              }
            ]}
          />
        </div>

        <Banner />

        <div className={classes.approach}>
          <h1 className={classes.styleText}>
            <Trans>Une approche unique</Trans>
          </h1>
          <p>
            <Trans>
              Grâce à notre équipe d’experts, nous recueillons quotidiennement
              les insights des entreprises et des candidats sur le marché des
              compétences IT
            </Trans>
          </p>
          <div className={classes.bgImg}>
            <SchemaApproach language={currentLanguage} />
          </div>
        </div>

        <div className={classes.organisation}>
          <h1 className={classes.tit}>
            <Trans>Une organisation agile</Trans>
          </h1>
          <p className={classes.para}>
            <Trans>
              Nos équipes innovation et support travaillent à l’optimisation
              constante de nos outils et process pour vous proposer la meilleure
              expérience possible.
            </Trans>
          </p>
          <Button
            className={classes.but}
            onClick={() => this.openPage("jungle")}
          >
            <Trans>Découvrez notre équipe</Trans>
          </Button>
          <div className={classes.bgImg}>
            <SchemaOrganisationV2
              text1={text1}
              text2={text2}
              text3={text3}
              title1={title1}
              title2={title2}
              title3={title3}
            />
          </div>
        </div>

        <div className={classes.market}>
          <h1 className="title">
            <Trans>Une puissante Market Intelligence</Trans>
          </h1>
          <p className="slogan">
            <Trans>Insights pour professionels IT et entreprises</Trans>
          </p>

          <div className={classes.graph}>
            <BlocGraphs
              image="/assets/images/graph.png"
              title={i18n._(t`Une vision 360 du marché`)}
              GraphTitle={i18n._(t`Evolutions des Jobs SAP`)}
              paragraph={i18n._(
                t`Nos équipes sont en contact permanent avec entreprises et freelances, permettant une connaissance pointue du marché`
              )}
              buttonText={i18n._(t`Découvrir les données`)}
              onClickButton={this.onClickButton}
            />
          </div>
        </div>

        <div className={classes.joinClub}>
          <h1>
            <Trans>Vous souhaitez rejoindre Club Freelance ?</Trans>
          </h1>
          <div className={classes.button}>
            <Row className={classes.classRow}>
              <Col
                xs={6}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.classCol}
              >
                <Button
                  icon="chevron-right"
                  // onClick={() => history.push(`${SignUp.path}?type=enterprise`)}
                  onClick={() => history.push(SearchCandidatesPage.path)}
                >
                  <Trans>Je cherche un consultant IT</Trans>
                </Button>
              </Col>
              <Col
                xs={6}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.classCol}
              >
                <Button
                  icon="chevron-right"
                  variant="secondary"
                  onClick={() => history.push(`${SignUp.path}?type=consultant`)}
                >
                  <Trans>Je cherche une mission</Trans>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <CompanySection1 />
      </div>
    );
  }
}

AboutPage.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(AboutPage));
