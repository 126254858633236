import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react/index";
import { withI18n } from "@lingui/react";
import { Plural, Trans } from "@lingui/macro";
import * as queryString from "query-string";
import classes from "../SideFilters.module.scss";
import { Button, Icon } from "cf-neo-ui";

@inject("candidatesSearchStore")
@observer
class SearchResultWidget extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { candidatesSearchStore } = this.props;
    return (
      <div className={classes.alertWidget}>
        <div style={{ float: "left", width: "100%" }}>
          <div className={classes.respTitle}>
            <Trans>Critères</Trans>
          </div>
          <div className={classes.closeBtn}>
            <Icon
              type="close"
              width={12}
              height={11}
              onClick={candidatesSearchStore.setSideFilter}
            />
          </div>
        </div>
        <div>
          <div>
            <p className="center">
              <span style={{ color: "#C40B24" }}>
                {`${candidatesSearchStore.resultsCount} `}
              </span>
              <Plural
                value={candidatesSearchStore.resultsCount}
                one="Résultat"
                other="résultats"
              />
            </p>
          </div>
          <div>
            <Trans>selon vos mots-clés</Trans>
          </div>
        </div>
      </div>
    );
  }
}

SearchResultWidget.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(SearchResultWidget));
