import gql from "graphql-tag";

export const USER_FAVED_MISSIONS_QUERY = gql`
  query UserFavedMissions {
    userFavedMissions {
      id
      address {
        city
      }
      title
      duration {
        months
      }
      customText1
      customText10
      total
      employmentType
      onSite
    }
  }
`;

export const USER_APPLICATIONS_QUERY = gql`
  query UserApplications {
    userApplications {
      id
      status
      jobOrder {
        id
        address {
          city
        }
        title
        duration {
          months
        }
        customText1
        customText10
        employmentType
        onSite
      }
    }
  }
`;

export const USER_RECOMMENDATION_QUERY = gql`
  query RecommendedMissions($skip: Int) {
    recommendedMissions(skip: $skip) {
      id
      address {
        city
      }
      title
      duration {
        months
      }
      customText1
      customText10
      total
      employmentType
      onSite
    }
  }
`;

export const USER_ALERT_QUERY = gql`
  query UserMissionAlert {
    userMissionAlert {
      categories
      skills
      businessSectors
      specialties
      address {
        city
      }
      countryId
      workType
      employmentType
      onSite
    }
  }
`;
