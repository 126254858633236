import gql from "graphql-tag";

const CREATE_MISSION_APPLICATION = gql`
  mutation CreateMissionApplication($jobOrderId: ID!, $origin: String) {
    createApplication(jobOrderId: $jobOrderId, origin: $origin) {
      id
      jobOrderId
    }
  }
`;

const ADD_TO_OPEN_RECENT = gql`
  mutation AddToOpenRecent($jobOrderId: String!) {
    addToOpenRecent(jobOrderId: $jobOrderId) {
      status
    }
  }
`;

export { CREATE_MISSION_APPLICATION, ADD_TO_OPEN_RECENT };
