import gql from "graphql-tag";

const ClientVacancyQuery = gql`
  query ClientVacancyQuery($id: Int!) {
    clientVacancy(id: $id) {
      id
      title
      address {
        city
        address1
      }
      startDate
      dateEnd
      customText1
      totalNotes
      totalShortListed
      totalCVSent
      totalInterviews
      totalPlacements
      total
      dateAdded
      project
      isOpen
      employmentType
    }
  }
`;

const validatedJobSubmissionsQuery = gql`
  query validatedJobSubmissionsQuery($jobOrderID: Int!) {
    validatedJobSubmissions(jobOrderID: $jobOrderID) {
      jobSubmissionID
      status
      candidateID
      candidatefirstName
      candidatelastName
      candidateOccupation
      candidateAvailableDate
      candidateStatus
      candidateAddress {
        city
        address1
      }
      candidateCv
      comment
      payRate
      commentingSourcer
      reference {
        id
        referenceFirstName
        referenceLastName
        referenceTitle
        outcome
      }
      modifiedBy {
        id
        name
      }
    }
  }
`;

const VACANCY_HISTORY = gql`
  query VacancyHistory($jobOrderID: Int!, $candidateID: Int) {
    vacancyHistory(jobOrderID: $jobOrderID, candidateID: $candidateID) {
      jobSubmissionID
      jobOrderID
      status
      candidateID
      comment
      modifiedBy {
        id
        name
      }
      clientComment
      createdAt
      modifiedAt
    }
  }
`;

export { validatedJobSubmissionsQuery, ClientVacancyQuery, VACANCY_HISTORY };
