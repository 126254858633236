const Entities = require("html-entities").AllHtmlEntities;

const entities = new Entities();
/*
const decode = htmlText => {
  const e = document.createElement("p");
  e.innerHTML = htmlText;
  return e.innerText;
};
*/
const decode = htmlText => entities.decode(htmlText);

export default decode;
