export default {
  loop: true,
  items: 1,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      dots: true,
      nav: false
    },
    420: {
      dots: false,
      nav: true
    }
  }
};
