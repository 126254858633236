import urls from "../../../utils/urls";
import runtime from "../../../configs/runTimeVars";

const ldJson = (i18n, post) => ({
  "@context": "http://schema.org",
  "@type": "BlogPosting",
  image:
    (post.featured_media && post.featured_media.source_url) ||
    `${urls.home}/mq.png`,
  url: urls.singleBlog(post.id),
  headline: post.title,
  dateCreated: post.date,
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id": urls.singleBlog(post.id)
  },
  datePublished: post.date,
  dateModified: post.modified,
  author: {
    "@type": "Person",
    name: post.author.name
  },
  creator: {
    "@type": "Person",
    name: post.author.name
  },
  inLanguage: runtime.APP_LANG,
  publisher: {
    "@type": "Organization",
    name: "Mindquest",
    logo: {
      "@type": "ImageObject",
      url: `${urls.home()}/mq.png`
    }
  }
});

export default ldJson;
