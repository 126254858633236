import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import uniqid from "uniqid";
import {
  Row,
  Col,
  Button,
  Spinner,
  toaster,
  Modal,
  ToastCard
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import SingleExperience from "./singleExperience";

import "./styles.scss";
import PopUp from "../../session/pop-up";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidateMutation(
    $_id: ID!
    $candidateWorkHistory: [ExperienceCreateInput]
  ) {
    updateCandidate(
      input: { _id: $_id, candidateWorkHistory: $candidateWorkHistory }
    ) {
      account {
        id
        name
      }
      candidate {
        _id
        email
      }
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class MyExperience extends Component {
  constructor(props) {
    super(props);
    this.onAddHandler = this.onAddHandler.bind(this);
    this.state = { saveCase: false };
    const { sessionStore } = props;
    const { changeIsCandidateWorkHistoryValid } = sessionStore;
    changeIsCandidateWorkHistoryValid(false);
  }

  onAddHandler() {
    const { sessionStore } = this.props;
    const { candidateWorkHistory, changeCandidateWorkHistory } = sessionStore;
    const newcandidateWorkHistory = candidateWorkHistory.slice();
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(4);
    newcandidateWorkHistory.push({
      id: uniqid(),
      isEditing: true,
      isNew: true,
      specialty: [],
      skillExperience: [],
      BusinessSector: []
    });

    changeCandidateWorkHistory(newcandidateWorkHistory);
  }

  updateCandidateCompletedHandler = () => {
    const { i18n, appStore, sessionStore } = this.props;
    toaster.success({
      title: i18n._(t`Mon expérience`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;

    appStore.refreshLayout();
    changeGeneralSaveCase(null);
  };

  isFormValid = () => {
    const { sessionStore, i18n } = this.props;
    const { isCandidateWorkHistoryValid } = sessionStore;
    // the experience has to be saved before submitted
    if (!isCandidateWorkHistoryValid) {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Veuillez valider l’expérience ouverte avant l’enregistrement`
        )
      });
      return false;
    }
    return true;
  };

  turnSaveOn = () => {
    const { sessionStore } = this.props;
    this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(5);
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  render() {
    const { saveCase, isVisible } = this.state;
    const { sessionStore, i18n } = this.props;

    const {
      _id,
      candidateWorkHistory,
      isCandidateWorkHistoryValid
    } = sessionStore;

    const candidateWorkHistoryToSend = [];
    for (let key = 0; key < candidateWorkHistory.length; key += 1) {
      const experience = candidateWorkHistory[key];
      candidateWorkHistoryToSend.push({
        ...(experience._id && {
          _id: experience._id
        }),
        id: experience.id.toString(),
        isCurrent: experience.isCurrent,
        candidateId: _id,
        title: experience.title,
        companyName: experience.companyName,
        startDate: experience.startDate,
        endDate: experience.endDate ? experience.endDate : null,
        summary: experience.summary,
        refName: experience.refName,
        refPhone: experience.refPhone,
        refEmail: experience.refEmail,
        skillExperience: experience.skillExperience,
        specialty: experience.specialty,
        BusinessSector: experience.BusinessSector,
        companyAddress: experience.companyAddress,
        contractType: experience.contractType
      });
    }
    const meta = staticMetaTags(i18n).experience;
    return (
      <div className="profile my-experience">
        {metaTags(
          urls.myExperience(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <Modal
          centred
          isVisible={false}
          withCloseButton={false}
          position="fixed"
        >
          <PopUp />
        </Modal>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          position="fixed"
        >
          <ToastCard
            title={i18n._(t`Erreur`)}
            description={i18n._(
              t`Veuillez enregistrer avant de passer à l’étape suivante`
            )}
            expiresIn={0}
            isVisible
            closable
            intent="error"
            showButton
            buttonText={i18n._(t`j'ai compris`)}
            onClose={() => this.setState({ isVisible: false })}
          />
        </Modal>
        <br />
        <div className="formCard no-gutter with-padding">
          <Row>
            <Col />
            <Col>
              <div className="add-buttons">
                <Button
                  disabled={!isCandidateWorkHistoryValid}
                  onClick={this.onAddHandler}
                >
                  <Trans>Ajouter une expérience</Trans>
                </Button>
              </div>
            </Col>
          </Row>
          {candidateWorkHistory.map(experience => (
            <div>
              <SingleExperience
                {...experience}
                key={experience.id}
                onValidate={this.turnSaveOn}
                showButtons="true"
              />
              <br />
            </div>
          ))}
          <br />
          <Row style={{ "flex-direction": "row-reverse" }}>
            {" "}
            <div
              className="next-buttons"
              style={{ marginRight: "19px", marginTop: "10px" }}
            >
              <Mutation
                mutation={UPDATE_CANDIDATE_MUTATION}
                variables={{
                  _id,
                  candidateWorkHistory: candidateWorkHistoryToSend
                }}
                onCompleted={data => this.updateCandidateCompletedHandler(data)}
                onError={errors => {
                  errors.graphQLErrors.forEach(({ message, data }) => {
                    if (data && data.isCustomError) {
                      this.onErrorHandler(message);
                    }
                  });
                }}
              >
                {(mutation, { loading }) => (
                  <Button
                    disabled={loading || !saveCase}
                    onClick={() => {
                      if (this.isFormValid()) {
                        mutation();
                        this.setState({ saveCase: false });
                      }
                    }}
                  >
                    {loading ? (
                      <Spinner
                        type="pointed-circular"
                        color="#FFFFFF"
                        size={12}
                      />
                    ) : (
                      <Trans>Enregistrer</Trans>
                    )}
                  </Button>
                )}
              </Mutation>
            </div>
          </Row>
        </div>
        <br />
      </div>
    );
  }
}

MyExperience.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    candidateWorkHistory: PropTypes.shape,
    changeCandidateWorkHistory: PropTypes.shape,
    changeIsCandidateWorkHistoryValid: PropTypes.func,
    _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isCandidateWorkHistoryValid: PropTypes.bool
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func
  }).isRequired
};

export default withI18n()(MyExperience);
