import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { H2, H5, TextInput, Button, Modal, toaster } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import ConfirmModal from "./ConfirmModal";
import classes from "./NewsBanner.module.scss";
import SUB_TO_NEWSLETTER from "../mutations";

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("sessionStore")
@observer
class NewsBanner extends Component {
  constructor(props) {
    super(props);
    this.email = "";
    this.state = {
      validate: false,
      valid: false,
      showModal: false
    };
  }

  focusHandler = e => {
    const { sessionStore } = this.props;
    if (sessionStore.authToken && sessionStore.email) {
      e.target.value = sessionStore.email;
      this.email = sessionStore.email;
    }
  };

  changeHandler = e => {
    this.email = e.target.value;
    this.setState({ validate: false });
  };

  subscribe = () => {
    const valid = EMAIL_REG.test(this.email);
    if (valid) this.setState({ showModal: true });
    else this.setState({ validate: true, valid });
  };

  register = (consultant, client, mutation) => {
    this.setState({ showModal: false });
    const list = [];
    if (consultant) list.push("consultant");
    if (client) list.push("company");
    const input = {
      email: this.email,
      list
    };

    return mutation({ variables: { input } });
  };

  render() {
    const { i18n } = this.props;
    const { validate, valid, showModal } = this.state;
    const styles = {
      color: "black",
      borderColor: "#dadee3"
    };
    if (validate && !valid) {
      styles.color = "red";
      styles.borderColor = "red";
    }
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          <H2 className={classes.bigHeader}>
            <Trans>Be in the know</Trans>
          </H2>
          <H5 className={classes.header}>
            <Trans>
              Subscribe to our weekly newsletter to get the latest articles and
              technology news
            </Trans>
          </H5>
          <div className={classes.email}>
            <TextInput
              style={styles}
              onChange={this.changeHandler}
              onEnterPressed={this.subscribe}
              placeholder={i18n._(t`Enter your email address`)}
              spellCheck="false"
              onFocus={this.focusHandler}
            />
            <Button
              icon="chevron-right"
              variant="secondary"
              onClick={this.subscribe}
            >
              <Trans>Subscribe</Trans>
            </Button>
          </div>
        </div>
        <Modal
          centred
          isVisible={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <Mutation
            mutation={SUB_TO_NEWSLETTER}
            onCompleted={() =>
              toaster.success({
                title: i18n._(t`Merci`),
                description: i18n._(
                  t`Vous êtes désormais abonné à la newsletter de Club Freelance`
                )
              })
            }
            onError={() =>
              toaster.error({
                title: i18n._(t`Erreur`),
                description: i18n._(
                  t`Un problème est survenu lors de la création de l’abonnement`
                )
              })
            }
            ignoreResults
          >
            {subToNewsletter => (
              <ConfirmModal
                register={(consultant, client) =>
                  this.register(consultant, client, subToNewsletter)
                }
              />
            )}
          </Mutation>
        </Modal>
      </div>
    );
  }
}

NewsBanner.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    email: PropTypes.string,
    isMapsScriptReady: PropTypes.bool
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(NewsBanner);
