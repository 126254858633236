import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import { t } from "@lingui/macro";
import { MonthlyTimesheet } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { Query } from "react-apollo";
import classes from "./TimeSheetCalendar.module.scss";
import runtimeVars from "../../../configs/runTimeVars";
import HOLIDAYS from "./query";

@inject("sessionStore", "appStore", "timesheetStore")
@observer
class TimeSheetCalendar extends Component {
  writingFormat = () => {
    const { appStore } = this.props;
    if (appStore.width > 768) return "long";
    if (appStore.width > 420) return "short";
    return "shortest";
  };

  local = () => {
    const { appStore } = this.props;
    switch (appStore.currentLanguage) {
      case "fr":
        return "fr";
      case "en":
        return "gb";
      default:
        return "gb";
    }
  };

  editable = () => {
    const { sessionStore, timesheetStore } = this.props;
    const { timesheet } = timesheetStore;
    return (
      sessionStore.authToken &&
      sessionStore.account &&
      sessionStore.account.role === "CANDIDATE" &&
      (timesheet.status === "draft" ||
        timesheet.status === "open" ||
        timesheet.status === "rejected")
    );
  };

  render() {
    const { timesheetStore, i18n, month, year, plCountry } = this.props;
    const { selectedDays, updateSelectedDays } = timesheetStore;
    const selectDaysFormated = [];
    selectedDays.map(async day => {
      const theDay = {};
      theDay.day = day.day;
      theDay.hours = day.hours + day.minutes / 60 + 0.001;
      selectDaysFormated.push(theDay);
    });
    let country = "EN";
    if (runtimeVars.APP_LANG === "fr") country = "FR";
    return (
      <div id="my-timesheet" className={classes.calenderContainer}>
        <Query
          query={HOLIDAYS}
          variables={{
            month,
            year,
            country
          }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <MonthlyTimesheet
                  month={month}
                  year={year}
                  id="monthly-timesheet"
                  defaultSelectedDays={selectDaysFormated}
                  iconTooltip={i18n._(
                    t`Cliquez içi préciser le nombre d’heures travaillés `
                  )}
                  holidays={[]}
                  editable={this.editable()}
                  local={this.local()}
                  writingFormat={this.writingFormat()}
                  getDataOnSelect={selected => {
                    if (selected) {
                      updateSelectedDays(selected);
                    }
                  }}
                  title={i18n._(t`Jour férié sélectionné`)}
                  message1={
                    i18n._(t`Attention, le jour séléctionné est férié en `) +
                    " " +
                    plCountry +
                    "."
                  }
                  message2={i18n._(
                    t`Pour indiquer que vous avez effectivement travaillé ce jour et à votre TJM habituel, cliquez sur continuer.`
                  )}
                  message3={i18n._(
                    t`Si vous avez travaillé à un taux majoré (astreintes, heures supplémentaires majorées...), merci d'utiliser l'espace heures et astreintes à droite.`
                  )}
                  buttonText1={i18n._(t`Continuer`)}
                  buttonText2={i18n._(t`Annuler`)}
                />
              );

            if (error) {
              return (
                <MonthlyTimesheet
                  month={month}
                  year={year}
                  id="monthly-timesheet"
                  defaultSelectedDays={selectDaysFormated}
                  iconTooltip={i18n._(
                    t`Cliquez içi préciser le nombre d’heures travaillés `
                  )}
                  holidays={[]}
                  editable={this.editable()}
                  local={this.local()}
                  writingFormat={this.writingFormat()}
                  getDataOnSelect={selected => {
                    if (selected) {
                      updateSelectedDays(selected);
                    }
                  }}
                  title={i18n._(t`Jour férié sélectionné`)}
                  message1={
                    i18n._(t`Attention, le jour séléctionné est férié en `) +
                    " " +
                    plCountry +
                    "."
                  }
                  message2={i18n._(
                    t`Pour indiquer que vous avez effectivement travaillé ce jour et à votre TJM habituel, cliquez sur continuer.`
                  )}
                  message3={i18n._(
                    t`Si vous avez travaillé à un taux majoré (astreintes, heures supplémentaires majorées...), merci d'utiliser l'espace heures et astreintes à droite.`
                  )}
                  buttonText1={i18n._(t`Continuer`)}
                  buttonText2={i18n._(t`Annuler`)}
                />
              );
            }
            const { holidays } = data;
            return (
              <MonthlyTimesheet
                month={month}
                year={year}
                id="monthly-timesheet"
                defaultSelectedDays={selectDaysFormated}
                iconTooltip={i18n._(
                  t`Cliquez içi préciser le nombre d’heures travaillés `
                )}
                holidays={holidays.length ? holidays[0].holidays : []}
                editable={this.editable()}
                local={this.local()}
                writingFormat={this.writingFormat()}
                getDataOnSelect={selected => {
                  if (selected) {
                    updateSelectedDays(selected);
                  }
                }}
                title={i18n._(t`Jour férié sélectionné`)}
                message1={
                  i18n._(t`Attention, le jour séléctionné est férié en `) +
                  " " +
                  plCountry +
                  "."
                }
                message2={i18n._(
                  t`Pour indiquer que vous avez effectivement travaillé ce jour et à votre TJM habituel, cliquez sur continuer.`
                )}
                message3={i18n._(
                  t`Si vous avez travaillé à un taux majoré (astreintes, heures supplémentaires majorées...), merci d'utiliser l'espace heures et astreintes à droite.`
                )}
                buttonText1={i18n._(t`Continuer`)}
                buttonText2={i18n._(t`Annuler`)}
              />
            );
          }}
        </Query>
      </div>
    );
  }
}

TimeSheetCalendar.wrappedComponent.propTypes = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  plCountry: PropTypes.string.isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  timesheetStore: PropTypes.shape({
    timesheet: PropTypes.shape({
      status: PropTypes.string
    }),
    updateSelectedDays: PropTypes.func,
    selectedDays: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.number,
        hours: PropTypes.number,
        minutes: PropTypes.number
      })
    )
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(TimeSheetCalendar));
