import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Trans } from "@lingui/macro";
import classes from "./errorPage.module.scss";

class NotFoundPage extends PureComponent {
  render() {
    const { message, history, href, withButton } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.breakLogo}>
          <img src="/assets/svg/NotFound.svg" alt="not found" />
        </div>
        <div className={classes.text}>
          <p>{message}</p>
          {withButton && (
            <button
              name="goback"
              type="button"
              onClick={() => history.push(href)}
              className={classes.link}
            >
              <Trans>Retour au site</Trans>
            </button>
          )}
        </div>
      </div>
    );
  }
}

NotFoundPage.propTypes = {
  message: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  href: PropTypes.string,
  withButton: PropTypes.bool
};

NotFoundPage.defaultProps = {
  message: "404 Not Found",
  href: "/",
  withButton: true
};

export default withRouter(NotFoundPage);
