import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { BlogPoster } from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import classes from "./VideosDisplay.module.scss";

class VideosDisplay extends Component {
  navigate = id => {
    const { history } = this.props;
    history.push(`/blog/news/${id}`);
  };

  render() {
    const { videos } = this.props;
    return (
      <div className={classes.container}>
        {videos.map(article => (
          <BlogPoster
            key={article.id}
            className={classes.videoCard}
            poster={
              article.featured_media && article.featured_media.source_url
                ? article.featured_media.source_url
                : "/cf.webp"
            }
            title={article.title}
            action="play"
            href={`/blog/news/${article.id}`}
            cardClick={() => this.navigate(article.id)}
          />
        ))}
      </div>
    );
  }
}

VideosDisplay.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      poster: PropTypes.string,
      title: PropTypes.string,
      media: PropTypes.string,
      action: PropTypes.string,
      caption: PropTypes.string,
      captionMode: PropTypes.number
    })
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default withRouter(VideosDisplay);
