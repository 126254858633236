import React, { Component } from "react";
import { Icon } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./calendarCard.module.scss";
import theme from "../../../configs/theme";

class CalendarCard extends Component {
  render() {
    return (
      <div className={classes.calendarCard}>
        <div className={classes.header}>
          <Trans>Calendrier</Trans>
        </div>
        <div className={classes.body}>
          <div className={classes.subtitle}>
            <Trans>Prochain rendez-vous</Trans>
          </div>
          <div className={classes.appointment}>
            <div className={classes.appointmentDay}>
              <Icon
                type="calendar-day"
                size="extra-large"
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
                width={55}
                height={55}
              />
            </div>
            <div className={classes.appointmentDetails}>
              <div className={classes.appointmentText}>Kick off Stuart</div>
              <div className={classes.appointmentTime}>14:00</div>
            </div>
          </div>
          <div className={classes.nextAppointment}>
            <h5>
              <Trans>Prochain rendez-vous</Trans>
            </h5>
            <div className={classes.nextAppointmentBar}>
              <div className={classes.nextAppointmentBarName}>BNP Paribas</div>
              <div className={classes.nextAppointmentBarDate}>
                Jusqu’au 19/03
              </div>
            </div>
            <div
              className={classes.nextAppointmentBar}
              style={{ marginBottom: "0" }}
            >
              <div className={classes.nextAppointmentBarName}>L’Oreal</div>
              <div className={classes.nextAppointmentBarDate}>
                Jusqu’au 08/07
              </div>
            </div>
          </div>
        </div>
        <div className={classes.overlay}>
          <p>Coming Soon</p>
        </div>
      </div>
    );
  }
}

export default CalendarCard;
