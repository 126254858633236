import React, { Component } from "react";
import { TextInput, Button, Validation, Checkbox } from "cf-neo-ui";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import classes from "./BookCard.module.scss";
import routes from "../../../layout/routes";
import SaveDownloadMutation from "./queries";
import runTimeVars from "../../../../configs/runTimeVars";
import validate from "../../../../utils/validators";

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@inject("sessionStore")
@observer
class BookCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      firstNameValid: false,
      firstNameValidationMessage: "",
      lastName: "",
      lastNameValid: false,
      lastNameValidationMessage: "",
      email: "",
      emailValid: false,
      check: false,
      beforeSubmit: true
    };
  }

  changeHandler = e => {
    this.setState({
      email: e.target.value,
      emailValid: EMAIL_REG.test(e.target.value)
    });
  };

  firstNameChangeHandler = e => {
    const { i18n } = this.props;
    const res = validate(
      e.target.value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      firstName: e.target.value,
      firstNameValid: res.isValid,
      firstNameValidationMessage: res.message
    });
  };

  lastNameChangeHandler = e => {
    const { i18n } = this.props;
    const res = validate(
      e.target.value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      lastName: e.target.value,
      lastNameValid: res.isValid,
      lastNameValidationMessage: res.message
    });
  };

  validForm = () => {
    const { firstNameValid, lastNameValid, emailValid, check } = this.state;
    return firstNameValid && lastNameValid && emailValid && check;
  };

  categoryClickHandler = cat => {
    const { history } = this.props;
    history.push(`${routes.BlogLayoutRoutes.Filter.path}?category=${cat.id}`);
  };

  emptyButton = () => {
    this.setState({ beforeSubmit: false });
  };

  errorMessage = () => {
    const { i18n } = this.props;
    const { email } = this.state;
    if (!email) return i18n._(t`Champ requis`);
    return i18n._(t`email invalide`);
  };

  focusHandler = e => {
    const { sessionStore } = this.props;
    if (sessionStore.authToken && sessionStore.email) {
      e.target.value = sessionStore.email;
      this.setState({
        email: sessionStore.email,
        emailValid: EMAIL_REG.test(sessionStore.email)
      });
    }
  };

  render() {
    const {
      categories,
      title,
      description,
      i18n,
      cover,
      downloadLink
    } = this.props;
    const {
      email,
      firstName,
      lastName,
      emailValid,
      firstNameValid,
      lastNameValid,
      firstNameValidationMessage,
      lastNameValidationMessage,
      check,
      beforeSubmit
    } = this.state;
    const styledCover = {
      background: `linear-gradient(-120deg,transparent calc(40% - 2px),transparent 40%,#fff calc(40% + 2px)), url('${cover}') 0 0 / cover no-repeat, #bcbcbc`,
      backgroundSize: "cove",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed"
    };
    return (
      <div className={classes.container} style={styledCover}>
        <div className={classes.content}>
          <div className={classes.categories}>
            {!!categories.length &&
              categories.map(
                cat =>
                  cat.name !== "featured" && (
                    <button
                      type="button"
                      key={cat.id}
                      onClick={() => this.categoryClickHandler(cat)}
                    >
                      {cat.name}
                    </button>
                  )
              )}
          </div>
          <h3 className={classes.header}>{title}</h3>
          {/* <p className={classes.description}>{description}</p> */}
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    );
  }
}

BookCard.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    isMapsScriptReady: PropTypes.bool,
    authToken: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string
    })
  ),
  title: PropTypes.string.isRequired,
  downloadLink: PropTypes.string.isRequired,
  cover: PropTypes.string,
  description: PropTypes.string,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};
BookCard.defaultProps = {
  categories: [],
  description: "",
  cover: "/cf.png"
};

export default withI18n()(withRouter(BookCard));
