import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Icon } from "cf-neo-ui";
import { Query } from "react-apollo";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import classes from "./recommendedTalents.module.scss";
import { ACCESSIBLE_CLIENT_RECOMMENDED_TALENTS_QUERY } from "../queries";
import Routes from "../../layout/routes";
import List from "./list";
import LoadingMissions from "../../../components/LoadingMissions/LoadingMissions";
import theme from "../../../configs/theme";

@inject("sessionStore")
@observer
class RecommendedTalents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skip: 0,
      limit: 3
    };
  }

  fetchMore = () => {
    const { skip } = this.state;
    this.setState({ skip: skip + 8 });
  };

  render() {
    const { history, sessionStore, i18n } = this.props;
    const { id } = sessionStore;
    const clientID = Number(id);
    const { skip, limit } = this.state;
    return (
      <div className={classes.recommendedTalentsSection}>
        <div className={classes.title}>
          <Trans>Talents recommandés</Trans>
        </div>
        <Query
          query={ACCESSIBLE_CLIENT_RECOMMENDED_TALENTS_QUERY}
          variables={{ id: clientID, skip, limit }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className={classes.talentCards}>
                  <LoadingMissions
                    cardsCount={3}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    colClassName={classes.loadingTalentsCard}
                  />
                </div>
              );

            if (error)
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src="/assets/svg/download.svg"
                    alt="error"
                  />
                </div>
              );
            const recommended = data.accessibleClientRecommendedTalents;
            if (recommended.length === 0)
              return (
                <div className={classes.talentCards}>
                  <p className={classes.text1}>
                    <Trans>
                      Il n’y a pas de talents qualifiés par nos équipes pour le
                      moment.
                    </Trans>
                  </p>
                  <p className={classes.text2}>
                    <Trans>
                      Pour recevoir des profils, soumettez une offre.
                    </Trans>
                  </p>
                </div>
              );

            return (
              <div>
                <Row>
                  <List
                    recommended={recommended}
                    fetchMore={this.fetchMore}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={6}
                  />
                </Row>
              </div>
            );
          }}
        </Query>

        <div className={classes.allTalentsLink}>
          <span
            onClick={() =>
              history.push({
                pathname: Routes.LpbLayoutRoutes.Talents.path,
                search: "?active=allTalents"
              })
            }
          >
            <Trans>Voir tous les talents</Trans>
            <Icon
              className={classes.chevronRight}
              type="chevron-right"
              size="tiny"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
          </span>
        </div>
      </div>
    );
  }
}

RecommendedTalents.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default withI18n()(withRouter(RecommendedTalents));
