import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import classes from "./statisticsCard.module.scss";

class StatisticsCard extends Component {
  render() {
    return (
      <div className={classes.statisticsCard}>
        <div className={classes.header}>
          <Trans>Statistiques</Trans>
        </div>
        <div className={classes.body}>
          <div className={classes.statisCol}>
            <div className={classes.stat}>23</div>
            <div className={classes.text}>
              <Trans>Candidatures</Trans>
            </div>
          </div>
          <div className={classes.statisCol}>
            <div className={classes.stat}>183h</div>
            <div className={classes.text}>
              <Trans>Travaillées</Trans>
            </div>
          </div>
          <div className={classes.statisCol}>
            <div className={classes.stat}>21k$</div>
            <div className={classes.text}>
              <Trans>de ventes</Trans>
            </div>
          </div>
        </div>
        <div className={classes.overlay}>
          <p>Coming Soon</p>
        </div>
      </div>
    );
  }
}

export default StatisticsCard;
