import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { Icon, Button } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import routes from "../../../routes";
import classes from "./HeaderMenu.module.scss";
import theme from "../../../../../configs/theme";

class HeaderMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expand: props.location.pathname.split("/")[2] === "advices"
    };
  }

  toggleChildrenList = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };

  render() {
    const { onClose } = this.props;
    const { expand } = this.state;
    return (
      <div className={classes.container}>
        <header className={classes.header}>
          <NavLink to={routes.HbfLayoutRoutes.Home.path}>
            <picture>
              <picture>
                <source
                  type="image/webp"
                  srcSet="/assets/images/le_club_mobile.webp"
                />
                <img src="/assets/images/le_club_mobile.png" alt="Mindquest" />
              </picture>
            </picture>
          </NavLink>
          <Icon
            className={classes.closeIcon}
            type="close"
            width={12}
            height={12}
            color="#BBBABA"
            onClick={onClose}
          />
        </header>
        <div id="header_body">
          <div className={classes.bodyItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeItem}
              exact
              to={routes.BlogLayoutRoutes.Blog.path}
            >
              <Trans>Accueil</Trans>
            </NavLink>
          </div>
          <div className={classes.bodyItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeItem}
              exact
              to={routes.BlogLayoutRoutes.News.path}
            >
              <Trans>Actualité</Trans>
            </NavLink>
          </div>

          <div className={classes.bodyItem}>
            <button
              type="button"
              className={classes.children}
              onClick={this.toggleChildrenList}
            >
              <span>
                <Trans>Conseils pratiques</Trans>
              </span>
              <span
                className={expand ? classes.clockRotate : classes.reverseRotate}
              >
                <Icon
                  type="chevron-left"
                  color={theme.colors.mainColor}
                  color2={theme.colors.lightMainColor}
                  width={20}
                  height={20}
                />
              </span>
            </button>
            {expand && (
              <div className={classes.childrenList}>
                <div className={classes.childItem}>
                  <NavLink
                    className={classes.text}
                    activeClassName={classes.activeItem}
                    exact
                    to={routes.BlogLayoutRoutes.AdvicesConsultant.path}
                  >
                    <Trans>Consultant</Trans>
                  </NavLink>
                </div>
                <div className={classes.childItem}>
                  <NavLink
                    className={classes.text}
                    activeClassName={classes.activeItem}
                    exact
                    to={routes.BlogLayoutRoutes.AdvicesEnterprise.path}
                  >
                    <Trans>Entreprise</Trans>
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          <div className={classes.bodyItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeItem}
              exact
              to={routes.BlogLayoutRoutes.Decryption.path}
            >
              <Trans>Décryptage</Trans>
            </NavLink>
          </div>

          <div className={classes.bodyItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeItem}
              exact
              to={routes.BlogLayoutRoutes.SuccessStories.path}
            >
              <Trans>Success Stories</Trans>
            </NavLink>
          </div>
        </div>
        <NavLink to={routes.BlogLayoutRoutes.Search.path}>
          <Button
            variant="tertiary"
            size="medium"
            icon="search"
            iconColor="#8F8F8F"
          >
            <Trans>Recherche</Trans>
          </Button>
        </NavLink>
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  onClose: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

HeaderMenu.defaultProps = {
  onClose: () => {}
};

export default withRouter(HeaderMenu);
