import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import {
  AutoCompleteV2,
  Button,
  Row,
  Col,
  Sorter,
  InputSkeleton
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import Routes from "../../../layout/routes/index";
import classes from "./SearchBar.module.scss";
import "./autocompleteStyle.scss";
import { GET_CATEGORIES, GET_SKILLS } from "../../queries";

@inject("sessionStore", "missionsSearchStore", "appStore")
@observer
class SearchBar extends Component {
  sorterChangeHandler = (value, direction) => {
    const { missionsSearchStore } = this.props;

    return direction === "up"
      ? missionsSearchStore.changeSortBy(value, "ASC")
      : missionsSearchStore.changeSortBy(value, "DESC");
  };

  renderSearchInput = () => {
    const { missionsSearchStore, i18n } = this.props;

    return (
      <Query query={GET_SKILLS}>
        {({ loading: loadingSkills, error: errorSkills, data: dataSkills }) => (
          <Query query={GET_CATEGORIES}>
            {({
              loading: loadingCategories,
              error: errorCategories,
              data: dataCategories
            }) => {
              if (loadingCategories || loadingSkills)
                return <InputSkeleton radius={10} />;
              if (errorCategories || errorSkills)
                return `Error! ${errorCategories.message}`;

              const suggestions = {};
              suggestions.categories = dataCategories.categories.map(
                item => item.name
              );
              suggestions.skills = dataSkills.skills.map(item => item.name);

              return (
                <AutoCompleteV2
                  id="mi_sb"
                  clearOnSelect
                  options={suggestions}
                  maxSuggestion={6}
                  min={1}
                  radius={10}
                  // value={missionsSearchStore.search}
                  // onChange={(e) => {console.log("papa", e); missionsSearchStore.changeSearch(e.target.value)}}
                  placeholder={i18n._(t`Rechercher...`)}
                  icon="search"
                  onEnterPressed={e =>
                    missionsSearchStore.searchSubmit(e.target.value)
                  }
                  onSelect={(val, filter) =>
                    missionsSearchStore.addToFilters(val, filter)
                  }
                />
              );
            }}
          </Query>
        )}
      </Query>
    );
  };

  renderSignUpBtn = () => {
    const { sessionStore, history } = this.props;
    const { push } = history;
    const { SignUp } = Routes.HbLayoutRoutes;

    return (
      !sessionStore.authToken && (
        <Button
          icon="chevron-right"
          onClick={() => push(`${SignUp.path}?type=consultant`)}
          id="cta_mi_sb_join"
        >
          <Trans>Rejoindre le club</Trans>
        </Button>
      )
    );
  };

  renderSorters = () => {
    const { i18n, appStore } = this.props;

    return (
      <div className={classes.sortersList}>
        <Sorter className={classes.sorter} text={i18n._(t`Distance`)} />

        <Sorter
          className={classes.sorter}
          text={i18n._(t`Date`)}
          onChange={direction =>
            this.sorterChangeHandler("startDate", direction)
          }
        />
        <Sorter
          className={classes.sorter}
          onChange={direction => this.sorterChangeHandler("id", direction)}
          text={i18n._(t`Récence`)}
        />

        {appStore.width >= 1024 && this.renderSignUpBtn()}
      </div>
    );
  };

  renderFilterButton = () => {
    const { missionsSearchStore } = this.props;
    const { setSideFilter } = missionsSearchStore;

    return (
      <Button
        icon="sliders"
        variant="secondary"
        iconPosition="left"
        className={classes.respFiltersBtn}
        onClick={setSideFilter}
      >
        <Trans>Critères</Trans>
      </Button>
    );
  };

  renderSearchBar = () => {
    return (
      <Row className={classes.searchBar}>
        <Col lg={4} className={classes.search}>
          {this.renderSearchInput()}
        </Col>
        <Col lg={8}>{this.renderSorters()}</Col>
      </Row>
    );
  };

  renderResponsiveSearchBar = () => {
    const { sessionStore, appStore } = this.props;

    return appStore.width >= 768 ? (
      <Row className={classes.searchBar}>
        <Col sm={sessionStore.authToken ? 12 : 8} className={classes.search}>
          {this.renderSearchInput()}
        </Col>
        <Col sm={4}>{this.renderSignUpBtn()}</Col>
        <Col sm={12} className={classes.respSorters}>
          {this.renderFilterButton()}
          {this.renderSorters()}
        </Col>
      </Row>
    ) : (
      <Row className={classes.searchBar}>
        <Col xs={6} className={classes.search}>
          {this.renderSearchInput()}
        </Col>
        <Col xs={3}>{this.renderFilterButton()}</Col>
        <Col xs={3} className={classes.respSignUpBtn}>
          {this.renderSignUpBtn()}
        </Col>
        <Col xs={6} className={classes.respSorters}>
          {this.renderSorters()}
        </Col>
      </Row>
    );
  };

  render() {
    const { appStore } = this.props;
    return appStore.width >= 1024
      ? this.renderSearchBar()
      : this.renderResponsiveSearchBar();
  }
}

SearchBar.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string
  }).isRequired,
  missionsSearchStore: PropTypes.shape({
    changeSortBy: PropTypes.func,
    searchSubmit: PropTypes.func,
    addToFilters: PropTypes.func,
    setSideFilter: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({ width: PropTypes.number }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(SearchBar));
