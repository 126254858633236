import React, { PureComponent } from "react";
import { Trans } from "@lingui/macro";
import classes from "./Companies.module.scss";

class Companies extends PureComponent {
  render() {
    return (
      <div className={classes.container}>
        <h5 className={classes.title}>
          <Trans>These companies trust us with their IT talent needs</Trans>
        </h5>
        <div className={classes.companies}>
          <div className={classes.companyLAPEYRE} />
          <div className={classes.companyVINCI} />
          <div className={classes.companyCOYOTO} />
          <div className={classes.companyMICROSOFT} />
          <div className={classes.companyWYND} />
          <div className={classes.companyYOPLAIT} />
        </div>
      </div>
    );
  }
}

export default Companies;
