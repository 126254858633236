import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import cookie from "react-cookies";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import {
  Row,
  Col,
  Divider,
  TextInput,
  FormGroup,
  FormCard,
  Button,
  Validation,
  Checkbox,
  CheckboxGroup,
  PasswordInput,
  toaster,
  Spinner,
  ImportPhoto
} from "cf-neo-ui";

import { Mutation } from "react-apollo";
import { scorePassword } from "../../utils/helpers";
import "./styles.scss";

import Routes from "../layout/routes/index";
import validate from "../../utils/validators";

const SIGNUP_CLIENT_MUTATION = gql`
  mutation SignupClientMutation(
    $email: String!
    $companyName: String!
    $activityArea: [String]!
    $currentPosition: String!
    $sizeOfTheCompany: Int
    $isESN: Boolean
    $source: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phonenumber: String!
    $pictureUrl: String
    $profilePhoto: Upload
    $role: String!
    $preferredContact: String
    $customTextBlock1: String
    $receiveNewsletter: Boolean
    $lastInfo: Boolean
    $siteLanguage: String
  ) {
    signupClient(
      input: {
        email: $email
        companyName: $companyName
        activityArea: $activityArea
        currentPosition: $currentPosition
        sizeOfTheCompany: $sizeOfTheCompany
        isESN: $isESN
        source: $source
        password: $password
        firstName: $firstName
        lastName: $lastName
        phonenumber: $phonenumber
        role: $role
        pictureUrl: $pictureUrl
        profilePhoto: $profilePhoto
        preferredContact: $preferredContact
        customTextBlock1: $customTextBlock1
        receiveNewsletter: $receiveNewsletter
        lastInfo: $lastInfo
        siteLanguage: $siteLanguage
      }
    ) {
      status
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class MyInformationClient extends Component {
  constructor(props) {
    super(props);
    this.source = "unknown";

    this.state = {
      rememberme: false,
      isfirstNameValid: true,
      islastNameValid: true,
      isEmailValid: true,
      isPhonenumberValid: true,
      isPasswordValid: true,
      isCurrentPositionValid: true,
      role: "CLIENT",
      firstNameValidationMessage: "",
      lastNameValidationMessage: "",
      emailValidationMessage: "",
      phonenumberValidationMessage: "",
      passwordValidationMessage: "",
      currentPositionValidationMessage: ""
    };

    this.isFormValid = this.isFormValid.bind(this);
  }

  componentDidMount() {
    const { host } = window.location;
    const webDomain = host.split(".").pop();
    if (webDomain === "com") this.source = "Website FR";
    if (webDomain === "uk") this.source = "Website UK";
  }

  handleEvaluator = v => {
    return scorePassword(v);
  };

  signupClientMutationCompletedHandler = () => {
    cookie.remove("firstVisitOrigin", { path: "/" });
    const { ConfirmCompany } = Routes.HbLayoutRoutes;
    const { sessionStore, history } = this.props;
    sessionStore.reset();
    history.push(ConfirmCompany.path);
  };

  acceptTermsChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeAcceptTerms(!sessionStore.acceptTerms);
  };

  receiveNewsletterChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeReceiveNewsletter(!sessionStore.receiveNewsletter);
  };
  lastInfoChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeLastInfo(!sessionStore.lastInfo);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };
  preferredContactChangeHandler = v => {
    if (Array.isArray(v)) {
      const { sessionStore } = this.props;
      sessionStore.changePreferredContact(v.toString());
    }
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    if (
      msg ===
      'Unexpected error value: "Un compte a déjà été créé avec cet email"'
    ) {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Un compte a déjà été créé avec cet email`)
      });
    } else {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: msg
      });
    }
  };

  firstNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changefirstName(e.target.value);
    this.validatefirstName(e.target.value);
  };

  lastNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changelastName(e.target.value);
    this.validatelastName(e.target.value);
  };

  currentPositionChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeCurrentPosition(e.target.value);
    this.validateCurrentPosition(e.target.value);
  };

  emailChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeEmail(String(e.target.value).toLowerCase());
    this.validateEmail(e.target.value);
  };

  phonenumberChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changePhonenumber(e.target.value);
    this.validatePhonenumber(e.target.value);
  };

  passwordChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changePassword(v);
    this.validatePassword(v);
  };

  handleUploadProfilePhoto(fileUploaded) {
    const { sessionStore } = this.props;
    sessionStore.changeProfilePhoto(fileUploaded);
  }

  validatefirstName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isfirstNameValid: res.isValid,
      firstNameValidationMessage: res.message
    });
    return res.isValid;
  }

  validatelastName(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      islastNameValid: res.isValid,
      lastNameValidationMessage: res.message
    });
    return res.isValid;
  }

  validateCurrentPosition(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isCurrentPositionValid: res.isValid,
      currentPositionValidationMessage: res.message
    });
    return res.isValid;
  }

  isFormValid() {
    const { sessionStore } = this.props;
    const {
      firstName,
      lastName,
      currentPosition,
      email,
      phonenumber,
      password,
      acceptTerms
    } = sessionStore;
    let valid = true;
    if (!this.validatefirstName(firstName)) valid = false;
    if (!this.validatelastName(lastName)) valid = false;
    if (!this.validateCurrentPosition(currentPosition)) valid = false;
    if (!this.validateEmail(email)) valid = false;
    if (!this.validatePhonenumber(phonenumber)) valid = false;
    if (!this.validatePassword(password)) valid = false;
    if (!valid) return false;
    if (!this.validateAcceptTerms(acceptTerms)) valid = false;
    return valid;
  }

  validateEmail(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required", "email"], i18n);
    this.setState({
      isEmailValid: res.isValid,
      emailValidationMessage: res.message
    });
    return res.isValid;
  }

  validatePhonenumber(value) {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 13 }, { minlength: 8 }],
      i18n
    );
    this.setState({
      isPhonenumberValid: res.isValid,
      phonenumberValidationMessage: res.message
    });
    return res.isValid;
  }

  validatePassword(value) {
    const { i18n } = this.props;
    const { confirmPassword } = this.state;
    if (!value && confirmPassword !== "") {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }

    if (!value && confirmPassword === "") {
      this.setState({
        isPasswordValid: true,
        passwordValidationMessage: ""
      });
      return true;
    }

    if (value.length < 8) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins 8 caractères`
        )
      });
      return false;
    }

    if (!/[a-z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une minuscule`
        )
      });
      return false;
    }
    if (!/[A-Z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une majuscule`
        )
      });
      return false;
    }
    if (!/\d/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Doit contenir au moins un chiffre`)
      });
      return false;
    }
    if (!/\W/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins un caractère spécial !*#@...`
        )
      });
      return false;
    }
    this.setState({
      isPasswordValid: true,
      passwordValidationMessage: ""
    });
    return true;
  }

  validateAcceptTerms(value) {
    const { i18n } = this.props;
    if (!value) {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Veuillez accepter notre politique de confidentialité`
        )
      });
    }

    return value;
  }

  render() {
    const { PrivacyPolicy } = Routes.HbLayoutRoutes;
    const customTextBlock1 = cookie.load("firstVisitOrigin") || "direct url";
    const { i18n, sessionStore, appStore } = this.props;
    const orientation = appStore.width <= 348 ? "vertical" : "horizontal";
    const {
      changeStep,
      firstName,
      lastName,
      companyName,
      activityArea,
      sizeOfTheCompany,
      isESN,
      currentPosition,
      email,
      pictureUrl,
      profilePhoto,
      phonenumber,
      lastInfo,
      password,
      preferredContact,
      receiveNewsletter,
      acceptTerms,
      signUpUserRole
    } = sessionStore;
    const { currentLanguage } = appStore;

    const role = signUpUserRole;
    changeStep(2);
    const {
      isfirstNameValid,
      islastNameValid,
      isCurrentPositionValid,
      isEmailValid,
      isPhonenumberValid,
      isPasswordValid,
      firstNameValidationMessage,
      lastNameValidationMessage,
      currentPositionValidationMessage,
      emailValidationMessage,
      phonenumberValidationMessage,
      passwordValidationMessage
    } = this.state;
    const preferredContactArray = preferredContact.split(",");

    const checkBoxLabel = (
      <Trans>
        J’accepte{" "}
        <Link to={PrivacyPolicy.path} target="_blank">
          {" "}
          la politique de confidentialités{" "}
        </Link>{" "}
        Club Freelance
      </Trans>
    );
    return (
      <div className="session my-information">
        <br />

        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Mes informations</Trans>
            </h1>
          </Col>
        </Row>
        <br />
        <FormCard className="formCard no-gutter with-padding">
          <Row className="photo-row">
            <Col lg={2} xl={2} className="profile-photo">
              <FormGroup>
                <ImportPhoto
                  className="profile-photo"
                  dimension="114px"
                  src={pictureUrl || ""}
                  errorMessageDescription={i18n._(
                    t`Format d’image invalide. Formats recommandés : .jpeg .png`
                  )}
                  errorMessageTitle={`${i18n._(t`Erreur`)} !`}
                  onChange={(e, fileUploaded) =>
                    this.handleUploadProfilePhoto(e, fileUploaded)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg={4} xl={4}>
              <Row>
                <Col lg={12} xl={12}>
                  <FormGroup>
                    <label className="form-label" htmlFor="agent_firstName">
                      <Trans>Prénom</Trans>
                    </label>
                    <Validation
                      errorMessage={firstNameValidationMessage}
                      valid={isfirstNameValid}
                    >
                      <TextInput
                        id="agent_firstName"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Prénom`)}
                        value={firstName}
                        onChange={this.firstNameChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label className="form-label" htmlFor="agent_lastName">
                      <Trans>Nom de famille</Trans>
                    </label>
                    <Validation
                      errorMessage={lastNameValidationMessage}
                      valid={islastNameValid}
                    >
                      <TextInput
                        id="agent_lastName"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Nom de famille`)}
                        value={lastName}
                        onChange={this.lastNameChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={6} xl={6}>
              <Row>
                <Col lg={12} xl={12}>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="agent_actual_position"
                    >
                      <Trans>Poste actuel</Trans>
                    </label>
                    <Validation
                      errorMessage={currentPositionValidationMessage}
                      valid={isCurrentPositionValid}
                    >
                      <TextInput
                        id="agent_actual_position"
                        type="text"
                        className="form-input"
                        placeholder={i18n._(t`Poste actuel`)}
                        value={currentPosition}
                        onChange={this.currentPositionChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
                <Col lg={12} xl={12}>
                  <FormGroup>
                    <label className="form-label" htmlFor="agent_phoneNumber">
                      <Trans>Téléphone</Trans>
                    </label>
                    <Validation
                      errorMessage={phonenumberValidationMessage}
                      valid={isPhonenumberValid}
                    >
                      <TextInput
                        id="agent_phoneNumber"
                        type="tel"
                        className="form-input"
                        placeholder="06XXXXXXXX"
                        value={phonenumber}
                        onChange={this.phonenumberChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="agent_email">
                  <Trans>Email</Trans>
                </label>
                <Validation
                  errorMessage={emailValidationMessage}
                  valid={isEmailValid}
                >
                  <TextInput
                    id="agent_email"
                    type="email"
                    spellCheck="false"
                    className="form-input"
                    placeholder={i18n._(t`monemail@mail.com`)}
                    value={email}
                    onChange={this.emailChangeHandler}
                    autoComplete="off"
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-label" htmlFor="agent_pass">
                  <Trans>Créer un mot de passe</Trans>
                </label>
                <Validation
                  errorMessage={passwordValidationMessage}
                  valid={isPasswordValid}
                >
                  <PasswordInput
                    id="agent_pass"
                    className="form-input"
                    message={i18n._(t`Niveau de sécurité`)}
                    placeholder={i18n._(t`Entrer un mot de passe`)}
                    passwordEvaluator={this.handleEvaluator}
                    value={password}
                    onChange={this.passwordChangeHandler}
                    autoComplete="off"
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <span
                className="contact-text"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Trans>Je préfère être contacté par</Trans> :
              </span>
              <CheckboxGroup
                style={{ marginTop: "10px", marginBottom: "10px" }}
                name="CheckboxGrp"
                defaultSelected={preferredContactArray}
                onChange={this.preferredContactChangeHandler}
                orientation={orientation}
              >
                <Checkbox name="email" label={i18n._(t`Email`)} value="email" />
                <Checkbox
                  name="phone"
                  label={i18n._(t`Téléphone`)}
                  value="phone"
                />
                <Checkbox name="sms" label={i18n._(t`SMS`)} value="sms" />
              </CheckboxGroup>
            </Col>
          </Row>

          <Divider />
          <Row className="accept_terms">
            <Col>
              <Checkbox
                name="receive_newsletter"
                value="receive_newsletter"
                checked={receiveNewsletter}
                label={i18n._(
                  t`Recevoir notre newsletter bimensuelle spéciale Tech & IT (conseils pratiques, dossiers spéciaux, sélection de missions, etc.).`
                )}
                onClickButton={this.receiveNewsletterChangeHandler}
                changeableFontSize
              />
            </Col>
          </Row>
          <Row className="accept_terms">
            <Col>
              <Checkbox
                name="receive_newsletter"
                value="receive_newsletter"
                checked={lastInfo}
                label={i18n._(
                  t`Je souhaite recevoir les actualités, les nouveaux produits et services de Club freelance`
                )}
                onClickButton={this.lastInfoChangeHandler}
              />
            </Col>
          </Row>
          <Row className="accept_terms">
            <Col>
              <Checkbox
                name="accept_terms"
                value="accept_terms"
                checked={acceptTerms}
                label={checkBoxLabel}
                onClickButton={this.acceptTermsChangeHandler}
                changeableFontSize
              />
            </Col>
          </Row>
        </FormCard>
        <br />

        <div className="next-buttons">
          <Mutation
            mutation={SIGNUP_CLIENT_MUTATION}
            variables={{
              firstName,
              lastName,
              email,
              phonenumber,
              password,
              companyName,
              activityArea,
              lastInfo,
              sizeOfTheCompany,
              isESN,
              source: this.source || "unknown",
              currentPosition,
              preferredContact,
              receiveNewsletter,
              acceptTerms,
              role,
              pictureUrl,
              profilePhoto,
              customTextBlock1,
              siteLanguage: currentLanguage
            }}
            onCompleted={() => {
              this.signupClientMutationCompletedHandler();
            }}
            onError={errors => {
              errors.graphQLErrors.forEach(({ message }) => {
                if (message) {
                  this.onErrorHandler(message);
                }
              });
            }}
          >
            {(mutation, { loading }) => (
              <span className="submitButton">
                {loading ? (
                  <Button disabled>
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  </Button>
                ) : (
                  <Button
                    icon="chevron-right"
                    onClick={() => this.isFormValid() && mutation()}
                  >
                    <Trans>Soumettre</Trans>
                  </Button>
                )}
              </span>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}

MyInformationClient.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    reset: PropTypes.func,
    changeAuthToken: PropTypes.func,
    changeAcceptTerms: PropTypes.func,
    changeReceiveNewsletter: PropTypes.func,
    changePreferredContact: PropTypes.func,
    changeCurrentPosition: PropTypes.func,
    changefirstName: PropTypes.func,
    changelastName: PropTypes.func,
    changeProfilePhoto: PropTypes.func,
    changeEmail: PropTypes.func,
    changePhonenumber: PropTypes.func,
    changePassword: PropTypes.func,
    changeStep: PropTypes.func,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phonenumber: PropTypes.string,
    password: PropTypes.string,
    acceptTerms: PropTypes.bool,
    receiveNewsletter: PropTypes.bool,
    preferredContact: PropTypes.arrayOf(PropTypes.string),
    signUpUserRole: PropTypes.string,
    companyName: PropTypes.string,
    activityArea: PropTypes.arrayOf(PropTypes.string),
    sizeOfTheCompany: PropTypes.number,
    isESN: PropTypes.bool,
    pictureUrl: PropTypes.string,
    profilePhoto: PropTypes.string,
    currentPosition: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(MyInformationClient));
