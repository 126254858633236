import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react/index";
import { Row } from "cf-neo-ui";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Fade from "react-reveal/Fade";
import classes from "./HomePage-new.module.scss";
import HeroSection1 from "./HeroSection/HeroSection1";
import BulletPointSection from "./BulletPointSection/BulletPointSection";
import FinderSection1 from "./FinderSection/FinderSection1";
import PartnerSection from "./PartnerSection/PartnerSection";
import TestimonySection1 from "./TestimonySection/TestimonySection1";
import ldJson from "./ldJson";

// it is used for the animation
import ServiceSection1 from "./ServiceSection/ServiceSection1";
import ExpertisesSection1 from "./ExpertisesSection/ExpertisesSection1";
import CompanySection1 from "./CompanySection/CompanySection1";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";

const GET_CATEGORIES = gql`
  {
    categories {
      name
    }
  }
`;
class HomePage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { i18n } = this.props;
    const meta = staticMetaTags(i18n).consultant;
    return (
      <div style={{ width: "100%" }}>
        {metaTags(
          urls.consultant(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords,
          ldJson(i18n)
        )}
        <section className={classes.section0}>
          <div className={classes.section0Bg} />
          <Row>
            <div className={classes.block1}>
              <HeroSection1 />
            </div>

            <div className={classes.block2}>
              <Fade big>
                <div className={classes.image1} />
              </Fade>
              <Fade bottom>
                <div className={classes.imageDashboard} />
              </Fade>
            </div>
          </Row>
        </section>
        <section className={classes.section1}>
          <ServiceSection1 />
        </section>

        <section className={classes.section2}>
          <div className={classes.section2Bg} />
          <Fade big>
            <div className={classes.image2} />
          </Fade>
          <BulletPointSection />
          <ExpertisesSection1 />
          <Query query={GET_CATEGORIES}>
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return `Error! ${error.message}`;

              const { categories } = data;
              return <FinderSection1 options={categories} />;
            }}
          </Query>
        </section>

        <section className={classes.section3}>
          <div className={classes.section3Bg}>
            <div className={classes.testimony}>
              <PartnerSection />
              <TestimonySection1 />
            </div>
          </div>
        </section>
        <CompanySection1 />
      </div>
    );
  }
}

HomePage.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(HomePage);
