import React from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { Query } from "react-apollo";

const GET_USER_INFO = gql`
  query getUserInfoQuery($token: String!) {
    getAccountInfo(token: $token) {
      token
      account {
        id
        name
        email
        role
        isCompleted
        isVerified
        path
      }
      candidate {
        id
        _id
        email
        name
        occupation
        status
        firstName
        lastName
        phone
        dayRateLow
        salary
        salaryLow
        companyURL
        dateAvailable
        categories
        skills
        spokenLanguages
        customText15
        customText20
        experience
        customText21
        customTextBlock3
        customTextBlock5
        customInt10
        profilePhoto
        preferredContact
        employmentPreference
        receiveNewsletter
        lastInfo
        cv
        address {
          address1
          city
          countryID
          zip
          state
          lat
          lng
        }
        searchAddress {
          address1
          city
          state
          countryID
          lat
          lng
        }
        specialties
        customTextBlock2
        customTextBlock4
        customTextBlock3
        status
      }
      client {
        _id
        id
        email
        companyName
        activityArea
        currentPosition
        sizeOfTheCompany
        isESN
        password
        firstName
        lastName
        lastInfo
        phonenumber
        profilePhoto
        customTextBlock1
        preferredContact
        receiveNewsletter
      }
      candidateWorkHistory {
        _id
        id
        candidateId
        isCurrent
        companyName
        title
        startDate
        endDate
        summary
        refName
        refPhone
        refEmail
        contractType
        companyAddress {
          address1
          city
          zip
          state
          countryID
          lat
          lng
        }
        BusinessSector
        skillExperience
        specialty
      }
      scrappedLinkdin {
        firstName
        lastName
        occupation
        location
        skills
        spokenLanguages
        educations {
          title
          degree
          url
          fieldOfStudy
          startDate
          endDate
        }
        experiences {
          title
          url
          companyName
          description
          location
          period
          duration
          startDate
          endDate
        }
      }
      candidateEducation {
        _id
        id
        candidateId
        city {
          address1
          city
          state
          countryID
          lat
          lng
        }
        degree
        school
        startDate
        endDate
        diplomeType
        comments
        certification
      }
      candidateCertification {
        _id
        id
        candidateId
        city {
          address1
          city
          state
          countryID
          lat
          lng
        }
        degree
        school
        startDate
        endDate
        diplomeType
        comments
        certification
      }
    }
  }
`;

const withSession = Component => {
  @inject("sessionStore", "appStore", "candidatesSearchStore")
  @observer
  class SessionHOC extends React.Component {
    render() {
      const {
        appStore,
        sessionStore,
        i18n,
        candidatesSearchStore
      } = this.props;
      sessionStore.setI18n(i18n);
      const { authToken, logout } = sessionStore;
      const { clearSearch } = candidatesSearchStore;
      return authToken ? (
        <Query
          query={GET_USER_INFO}
          variables={{
            token: sessionStore.authToken
          }}
          key={appStore.layoutRefreshKey}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error)
              return (
                <div>
                  {() => {
                    logout();
                    clearSearch();
                  }}
                </div>
              );
            const {
              candidate,
              client,
              account,
              candidateWorkHistory,
              scrappedLinkdin,
              candidateEducation,
              candidateCertification
            } = data.getAccountInfo;
            if (!account) {
              logout();
              clearSearch();
            }
            sessionStore.changeAccount(account);
            if (candidate) sessionStore.changeCandidate(candidate);
            if (client) sessionStore.changeClient(client);
            if (scrappedLinkdin) {
              sessionStore.changeScrappedLinkdin(scrappedLinkdin);
            }
            if (candidateWorkHistory)
              sessionStore.changeCandidateWorkHistory(candidateWorkHistory);

            if (candidateEducation)
              sessionStore.changecandidateEducation(candidateEducation);
            if (candidateCertification)
              sessionStore.changecandidateCertification(candidateCertification);
            if (scrappedLinkdin)
              sessionStore.changeScrappedLinkdin(scrappedLinkdin);
            return <Component {...this.props} />;
          }}
        </Query>
      ) : (
        <Component {...this.props} />
      );
    }
  }

  SessionHOC.wrappedComponent.propTypes = {
    sessionStore: PropTypes.shape({
      authToken: PropTypes.string,
      changeAccount: PropTypes.func,
      changeCandidate: PropTypes.func,
      changeClient: PropTypes.func,
      changeCandidateWorkHistory: PropTypes.func,
      setI18n: PropTypes.func,
      candidateWorkHistory: PropTypes.shape,
      logout: PropTypes.func
    }).isRequired,
    candidatesSearchStore: PropTypes.shape({
      clearSearch: PropTypes.func
    }).isRequired,
    appStore: PropTypes.shape({
      layoutRefreshKey: PropTypes.number
    }).isRequired,
    i18n: PropTypes.shape({}).isRequired
  };

  return withI18n()(SessionHOC);
};

export default withSession;
