import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n, I18nProvider } from "@lingui/react";
import { H2, H5, Spinner } from "cf-neo-ui";
import { Trans, plural } from "@lingui/macro";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import Articles from "../blog-cards-display/BlogCardsDisplay";
import classes from "./Advices.module.scss";
import { categoriesRefEn, categoriesRefFr, latestPostsQuery } from "../query";
import staticPagesMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import ldJson from "./ldJson";
import PlusButton from "../plus-button/PlusButton";
import theme from "../../../configs/theme";
import catalogEn from "../../../locales/en/messages";

const catalogs = {
  en: catalogEn
};

@inject("appStore")
@observer
class Advices extends Component {
  constructor(props) {
    super(props);
    this.latestPostsPage = 1;
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  loadingDisplay = () => {
    return (
      <I18nProvider language="en" catalogs={catalogs}>
        <div className={classes.limitedBody}>
          <section>
            <H2 className={classes.heading}>
              <Trans>Conseils pratiques Consultant</Trans>
            </H2>
            <p className={classes.text}>
              <Trans>
                Découvrez tous nos conseils pour une activité de Freelance
                réussie
              </Trans>
            </p>
          </section>
          <section>
            <Spinner
              className={classes.spinner}
              type="pointed-circular"
              color={theme.colors.lightMainColor}
              size={120}
            />
          </section>
          <section />
        </div>
      </I18nProvider>
    );
  };

  showMore = fetchMore => {
    this.latestPostsPage += 1;
    return fetchMore({
      fetchPolicy: "cache-and-network",
      query: latestPostsQuery,
      variables: {
        notifyOnNetworkStatusChange: true,
        page: this.latestPostsPage,
        perPage: 9,
        categoryIds: categoriesRefFr.advicesConsultant
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, posts: [...prev.posts, ...fetchMoreResult.posts] };
      }
    });
  };

  render() {
    const { i18n } = this.props;
    const meta = staticPagesMetaTags(i18n).blog.advicesConsultant;
    return (
      <div>
        {metaTags(
          urls.advicesConsultant(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords,
          ldJson(i18n)
        )}
        <Query
          query={latestPostsQuery}
          variables={{
            page: 1,
            perPage: 9,
            categoryIds: categoriesRefFr.advicesConsultant
          }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data, fetchMore, networkStatus }) => {
            if (error)
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error-at"
                  />
                </div>
              );
            if (networkStatus === 1) return this.loadingDisplay();
            return (
              <div className={classes.limitedBody}>
                <section>
                  <H2 className={classes.heading}>
                    <Trans>Conseils pratiques Consultant</Trans>
                  </H2>
                  <div className={classes.text}>
                    <Trans>
                      Découvrez tous nos conseils pour une activité de Freelance
                      réussie
                    </Trans>
                    <H5 className={classes.articleHeading}>
                      <span>
                        {i18n._(
                          plural({
                            value: data.posts ? data.posts[0].total : 0,
                            one: "# Article à lire",
                            other: "# Articles à lire"
                          })
                        )}
                      </span>
                    </H5>
                  </div>
                </section>
                <section>
                  {data && <Articles articles={data.posts} />}
                  {networkStatus === 3 && (
                    <Spinner
                      className={classes.spinner}
                      type="pointed-circular"
                      color="#c40b24"
                      size={120}
                    />
                  )}
                </section>
                <section style={{ marginBottom: "20px" }}>
                  {!loading &&
                    data.posts.length &&
                    data.posts[0] &&
                    this.latestPostsPage < data.posts[0].totalPages && (
                      <PlusButton onClick={() => this.showMore(fetchMore)}>
                        <Trans>Voir plus</Trans>
                      </PlusButton>
                    )}
                </section>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Advices.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

export default withI18n()(Advices);
