import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { TestimonialCard, TestimonialCardV2 } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import $ from "jquery";
import { inject, observer } from "mobx-react";
import Fade from "react-reveal/Fade";
import classes from "./TestimonySection1.module.scss";
import "./style.scss";

import carouselConfig from "./CarouselConfig";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const getTestimonies = i18n => [
  {
    image: "/assets/images/testimonies/Sophie.jpeg",
    name: "Sophie Duflot",
    job: i18n._(t`SAP SD MM Project Manager`),
    paragraph: i18n._(
      t`“Je suis très satisfaite de Club Freelance. Retour rapide sur la mission suite à un entretien, contrat envoyé dans la foulée et aucun problème de facturation. L’équipe est très disponible et la communication avec l’ensemble des interlocuteurs est facile et agréable.“`
    )
  },
  {
    image: "/assets/images/testimonies/Aymen.jpeg",
    name: "Aymen Fitati",
    job: i18n._(t`Consultant AWS Cloud Devops`),
    paragraph: i18n._(
      t`“Le service du Club Freelance est rapide et efficace. Les membres de l’équipe savent ce qu’ils recherchent avec leurs partenaires: aucun contact n’est destiné à créer une base de données de CV mais à proposer des emplois intéressants. Je recommande fortement.“`
    )
  },
  {
    image: "/assets/images/testimonies/Mark.jpeg",
    name: "Mark Disley",
    job: i18n._(t`PMO BI`),
    paragraph: i18n._(
      t`“Club Freelance propose un service simple et efficace, facilité par un espace personnel virtuel et la possibilité de gérer toute sa documentation en ligne. Pour moi, c’est la manière la plus simple pour émettre une facture et se faire payer. En temps et en heure.“`
    )
  }
];

@inject("appStore")
@observer
class TestimonySection1 extends Component {
  key = 0;

  state = {
    render: false
  };

  componentDidMount() {
    this.setState({ render: true });
  }

  toDetails = () => {
    const { history } = this.props;
    history.push(`/missions`);
  };

  render() {
    const { i18n, appStore } = this.props;
    const { render } = this.state;
    if (!render) return null;
    if (typeof window !== "undefined") {
      window.jQuery = $;
      window.$ = $;
    }
    /* eslint-disable global-require */
    const OwlCarousel = require("react-owl-carousel");
    /* eslint-enable global-require */
    // eslint-disable-next-line no-return-assign
    return (
      <div className="carousel_home_page_testimony">
        <div className={classes.temoignages}>
          <Fade>
            <h1>
              <Trans>Témoignages</Trans>
            </h1>
          </Fade>
          <Fade>
            <p className={classes.paragraph}>
              <Trans>Une communauté au coeur de notre service.</Trans>
            </p>
          </Fade>
          <div className={classes.carousel}>
            <OwlCarousel {...carouselConfig}>
              {getTestimonies(i18n).map(testimony => {
                this.key += 1;
                return (
                  <div className="item" key={this.key}>
                    {appStore.width > 820 ? (
                      <TestimonialCard
                        image={testimony.image}
                        name={testimony.name}
                        job={testimony.job}
                        paragraph={testimony.paragraph}
                        buttonText={i18n._(t`Voir les offres`)}
                        onClickButton={() => this.toDetails()}
                      />
                    ) : (
                      <TestimonialCardV2
                        image={testimony.image}
                        name={testimony.name}
                        job={testimony.job}
                        paragraph={testimony.paragraph}
                        buttonText={i18n._(t`Voir les offres`)}
                        onClickButton={() => this.toDetails()}
                      />
                    )}
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    );
  }
}

// https://github.com/mobxjs/mobx-react/issues/70#issuecomment-231834410
TestimonySection1.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number,
    clicked: PropTypes.bool,
    changeClicked: PropTypes.func,
    changeWidth: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(TestimonySection1));
