import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import * as PropTypes from "prop-types";
import { TextInput, Icon } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import axios from "axios";
import { inject, observer } from "mobx-react";
import classes from "./location.module.scss";

import configs from "../../configs/google-apis-configs";
import Tooltip from "./tooltip";
import runtimeVars from "../../configs/runTimeVars";

@inject("appStore", "sessionStore")
@observer
class Location extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      address: value,
      tooltip: false,
      message: "",
      shake: false
    };
    this.nodeRef = null;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickOutsideHandler, {
      passive: true
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickOutsideHandler);
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.position !== this.props.position) {
      this.moveMap(nextProps.position);
    }
  };

  clickOutsideHandler = e => {
    if (this.nodeRef && !this.nodeRef.contains(e.target))
      this.setState({ tooltip: false, shake: false });
  };

  addressFromCoord = async (lat, lng) => {
    try {
      const res = await axios.get(`${configs.mapsURL}/geocode/json`, {
        params: {
          v: configs.version,
          key: configs.apiKey,
          latlng: `${lat},${lng}`,
          language: runtimeVars.APP_LANG || "en"
        }
      });
      if (res.data && res.data.status === "OK") {
        const address = {
          streetNum: "",
          street: "",
          address: "",
          city: "",
          state: "",
          country: "",
          zip: ""
        };
        const collectAddressTypes = {
          streetNum: ["street_number"],
          street: ["route"],
          subLocality: [
            "sublocality",
            "sublocality_level_1",
            "sublocality_level_2"
          ],
          city: [
            "locality",
            "postal_town",
            "administrative_area_level_2",
            "administrative_area_level_3"
          ],
          zip: ["postal_code"],
          state: ["administrative_area_level_1"],
          country: ["country"]
        };

        if (res.data.results) {
          for (const addressItem in collectAddressTypes) {
            loop2: for (const bigResultForm of res.data.results) {
              for (const resultForm of bigResultForm.address_components)
                for (const criteria of collectAddressTypes[addressItem]) {
                  if (resultForm.types.includes(criteria)) {
                    address[addressItem] = resultForm.long_name;
                    break loop2;
                  }
                }
            }
          }
          if (address.street)
            address.address = `${address.streetNum} ${address.street}`;
          else address.address = res.data.results[0].formatted_address;
          /* delete unused address attributes */
          delete address.street;
          delete address.streetNum;
          delete address.subLocality;
          return address;
        }
        return {};
      }
      return {};
    } catch (e) {
      return {};
    }
  };

  autoDetect = async () => {
    navigator.geolocation.getCurrentPosition(
      async data => {
        const coords = {
          lat: data.coords.latitude,
          lng: data.coords.longitude
        };
        const formattedAddress = await this.addressFromCoord(
          coords.lat,
          coords.lng
        );
        const { getLocation } = this.props;
        getLocation(formattedAddress.address, {
          ...coords,
          ...formattedAddress
        });
        this.setState({ address: formattedAddress.address });
      },
      () => {}
    );
  };

  handleChange = address => {
    this.setState({ address, tooltip: false, shake: false });
    const { onChange, sessionStore } = this.props;
    sessionStore.changeAddressToShow(address);
    // onChange(address);
    onChange("");
  };

  handleSelect = address => {
    this.setState({ address });
    const { getLocation } = this.props;
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(async latLng => {
        const preparedAddress = await this.addressFromCoord(
          latLng.lat,
          latLng.lng
        );
        preparedAddress.lat = latLng.lat;
        preparedAddress.lng = latLng.lng;
        getLocation(address, preparedAddress);
      })
      .catch();
  };

  focusHandler = () => {
    const { address } = this.state;
    const { i18n } = this.props;
    if (!address)
      this.setState({
        tooltip: true,
        message: i18n._(t`Essayez la détection automatique`),
        shake: true
      });
  };

  EnterHandler = (e, sug) => {
    const { i18n } = this.props;
    let { address } = this.state;
    /* format string for comparision  */
    address = address.split(",");
    address = address.map(x => x.trim());
    address = address.join(", ");

    /* check if the input address exist in suggestion */
    const exist = sug.findIndex(
      x => x.description.toLowerCase() === address.toLowerCase()
    );
    const { getLocation } = this.props;
    if (address && exist !== -1) {
      address = sug[exist].description;
      this.setState({ address });
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(async latLng => {
          const preparedAddress = await this.addressFromCoord(
            latLng.lat,
            latLng.lng
          );
          preparedAddress.lat = latLng.lat;
          preparedAddress.lng = latLng.lng;
          getLocation(address, preparedAddress);
        })
        .catch();
    } else {
      this.setState({
        tooltip: true,
        message: i18n._(
          t`Sélectionner depuis la liste ou utiliser la detection automatique`
        )
      });
    }
  };

  handelKeyUp = e => {
    const { submitOnTab } = this.props;
    if (submitOnTab && e.keyCode === 9 /* tab key */) {
      this.handleSelect(e.target.value);
    }
  };

  displaySuggestions = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading
  }) => {
    const { i18n, className, iconColor, iconColor2, valid } = this.props;
    const { tooltip, message, shake } = this.state;
    return (
      <div className={classes.container}>
        <div className={shake ? classes.shakeIcon : ""}>
          <TextInput
            className={className}
            spellCheck="false"
            {...getInputProps()}
            icon="map-marker"
            iconColor={iconColor}
            placeholder={i18n._(t`Adresse`)}
            iconColor2={iconColor2}
            clickableIcon
            onFocus={this.focusHandler}
            onIconClicked={this.autoDetect}
            onEnterPressed={e => this.EnterHandler(e, suggestions)}
            onKeyUp={this.handelKeyUp}
            name="location"
            valid={valid}
          />
        </div>
        {tooltip && <Tooltip message={message} autoDetect={shake} />}
        {!!suggestions.length && (
          <div className={classes.dropdown}>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => (
              <div
                {...getSuggestionItemProps(suggestion)}
                className={classes.dropdownRow}
              >
                <Icon
                  style={{ marginLeft: "4px" }}
                  type="map-marker"
                  width={12}
                  height={12}
                  color={suggestion.active ? "#8d0417" : "#000"}
                  color2={suggestion.active ? "#d3354a" : "#000"}
                />
                <button
                  type="button"
                  className={classes.dropdownItem}
                  style={{
                    background: suggestion.active ? "#E8E9ED" : "transparent"
                  }}
                  onClick={() => this.handleSelect(suggestion.description)}
                >
                  {suggestion.description}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  render() {
    let { address } = this.state;
    const { appStore, i18n, valid, value } = this.props;
    if (!address && value) address = value;

    if (!appStore.isMapsScriptReady)
      return (
        <TextInput
          placeholder={i18n._(t`Rafraîchissez la page`)}
          spellCheck="false"
          disabled
          name="location_not_ready"
          valid={valid}
        />
      );
    return (
      <div
        ref={node => {
          this.nodeRef = node;
        }}
      >
        <PlacesAutocomplete value={address} onChange={this.handleChange}>
          {this.displaySuggestions}
        </PlacesAutocomplete>
      </div>
    );
  }
}

Location.wrappedComponent.propTypes = {
  getLocation: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  submitOnTab: PropTypes.bool,
  iconColor: PropTypes.string,
  iconColor2: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.bool,
  appStore: PropTypes.shape({
    isMapsScriptReady: PropTypes.bool
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeAddressToShow: PropTypes.func,
    addressToShow: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

Location.defaultProps = {
  getLocation: () => {},
  onChange: () => {},
  value: "",
  submitOnTab: false,
  className: "",
  iconColor: "#8d0417",
  iconColor2: "#d3354a",
  valid: true
};

export default withI18n()(Location);
