import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import classes from "./SingleMissionHeader.module.scss";
import Title from "./Title/Title";
import Info from "./Infos/Info";

class SingleMissionHeader extends PureComponent {
  render() {
    const {
      id,
      title,
      place,
      businessSectors,
      duration,
      workType,
      categories
    } = this.props;
    return (
      <div className={classes.container} id="__SM__FULL__HEADER">
        <div className={classes.headerHolder} />
        <Title title={title} />
        <div className={classes.info}>
          <div className={classes.curtain} />
          <Info
            id={id}
            place={place}
            businessSectors={businessSectors}
            duration={duration}
            workType={workType}
            categories={categories}
          />
        </div>
      </div>
    );
  }
}

SingleMissionHeader.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  place: PropTypes.string,
  duration: PropTypes.string,
  workType: PropTypes.string,
  businessSectors: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(PropTypes.string)
};

SingleMissionHeader.defaultProps = {
  title: "",
  place: "",
  duration: "",
  workType: "",
  businessSectors: [""],
  categories: [""]
};

export default SingleMissionHeader;
