import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react/index";
import Article from "./article/Article";
import Loading from "./LoadingSingleBlog/Loading";
import { SinglePostQuery } from "../query";
import decode from "../../../utils/Utf8Text";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import ldJson from "./ldJson";

class BlogSinglePage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { match, i18n } = this.props;
    return (
      <Query query={SinglePostQuery} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          if (error)
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <span>
                  Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment
                </span>
                <img src={"/assets/svg/download.svg"} alt="error" />
              </div>
            );
          if (loading) return <Loading />;
          const { post } = data;
          return (
            <div>
              {metaTags(
                urls.singleBlog(post.id),
                decode(post.title),
                decode(post.meta_description),
                post.featured_media && post.featured_media.source_url,
                decode(post.meta_keys),
                ldJson(i18n, post)
              )}
              <Article
                id={post.id}
                categories={post.categories}
                tags={post.tags}
                title={decode(post.title)}
                cover={
                  post.featured_image2_url ||
                  (post.featured_media && post.featured_media.source_url)
                }
                blogger={post.author}
                addDate={post.date}
                updateDate={post.modified}
                content={post.content}
                relatedPosts={post.related_posts}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

BlogSinglePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(BlogSinglePage));
