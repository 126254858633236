import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { Button, Icon, Col, Row } from "cf-neo-ui";
import classes from "./SuggestCard.module.scss";

class SuggestCard extends PureComponent {
  render() {
    const {
      text,
      onClick,
      buttonLabel,
      masked,
      withBeta,
      ...restProps
    } = this.props;
    return (
      <div className={classes.container} {...restProps}>
        <Row>
          <Col xl={7} lg={6} md={5} sm={6} xs={6} style={{ margin: "5px 0" }}>
            <div className={classes.center}>
              <p>{text}</p>
            </div>
          </Col>
          <Col xl={5} lg={6} md={7} sm={6} xs={6} style={{ margin: "5px 0" }}>
            <div className={classes.center}>
              <Button className={classes.but} onClick={onClick}>
                <span>
                  <span>{buttonLabel} </span>
                  {withBeta && (
                    <img
                      style={{ verticalAlign: "middle" }}
                      src="/beta.png"
                      alt="Beta"
                      width="76.06px"
                      height="34.09px"
                    />
                  )}
                </span>
              </Button>
            </div>
          </Col>
        </Row>
        {masked && <div className={classes.overlay} />}
      </div>
    );
  }
}

SuggestCard.propTypes = {
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  masked: PropTypes.bool,
  withBeta: PropTypes.bool
};
SuggestCard.defaultProps = {
  masked: true,
  text: "",
  buttonLabel: "",
  onClick: () => {},
  withBeta: false
};

export default SuggestCard;
