import { plural, t } from "@lingui/macro";

export default ({ days, months }, i18n, employmentType) => {
  if (
    employmentType &&
    (employmentType.toLowerCase() === "permanent" ||
      employmentType.toLowerCase() === "cdi")
  )
    return i18n._(t`Permanent`);
  if (!days && !months) return i18n._(t`N/A`);
  if (days < 30)
    return i18n._(plural({ value: days, one: "# jour", other: "# jours" }));
  return i18n._(plural({ value: months, one: "# mois", other: "# mois" }));
};
