import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Select } from "cf-neo-ui";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";

@inject("candidatesSearchStore")
@observer
class EmploymentPreferenceWidget extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { i18n, candidatesSearchStore } = this.props;
    const {
      changeEmploymentPreference,
      employmentPreference
    } = candidatesSearchStore;
    return (
      <div>
        <Select
          options={{
            groupA: [
              {
                text: i18n._(t`Indifferent`),
                value: null,
                selected: !employmentPreference
              },
              {
                text: i18n._(t`Permanent`),
                value: "Permanent",
                selected: employmentPreference === "Permanent"
              },
              {
                text: i18n._(t`Freelance/Contract`),
                value: "Contract",
                selected: employmentPreference === "Contract"
              }
            ]
          }}
          onSelect={changeEmploymentPreference}
        />
      </div>
    );
  }
}

EmploymentPreferenceWidget.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    changeEmploymentPreference: PropTypes.func,
    employmentPreference: PropTypes.string
  }).isRequired
};

export default withI18n()(EmploymentPreferenceWidget);
