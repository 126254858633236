import gql from "graphql-tag";

const APPROVALS_QUERY = gql`
  query Approvals(
    $limit: Int
    $skip: Int
    $placementIDs: [Int]
    $name: String
    $email: String
  ) {
    approvals(
      filter: {
        limit: $limit
        skip: $skip
        placementIDs: $placementIDs
        name: $name
        email: $email
      }
    ) {
      title
      placementID
      candidate {
        id
        firstName
        lastName
        name
        email
        city
      }
      client {
        id
        firstName
        lastName
        name
        email
        companyName
      }
      approvingClient {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      approvingClient2 {
        id
        firstName
        lastName
        name
        email
        companyName
        isSigned
      }
      company {
        name
        id
      }
      dateLastUpdate
      total
    }
  }
`;

const APPROVALS_AUTH_QUERY = gql`
  {
    approvalAuth {
      id
      role
      asCandidate
      asClient
      asApprovingClient
      asApprovingClient2
    }
  }
`;

export { APPROVALS_QUERY, APPROVALS_AUTH_QUERY };
