import gql from "graphql-tag";

const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($_id: ID!, $siteLanguage: String) {
    updateAccount(_id: $_id, siteLanguage: $siteLanguage) {
      email
      siteLanguage
    }
  }
`;

export default UPDATE_ACCOUNT;
