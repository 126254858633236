import React, { Component } from "react";
import { IconCircle } from "cf-neo-ui";
import classes from "./chatWidget.module.scss";
import theme from "../../../configs/theme";

class ChatWidget extends Component {
  render() {
    return (
      <div className={classes.chatWidget}>
        <div className={classes.body}>
          <div className={classes.msg}>
            <div className={classes.msgData}>
              <span className={classes.sender}>Club Freelance</span>
              <span className={classes.date}>10:20 AM, Today</span>
            </div>
            <div className={classes.msgBubble}>
              Hello and Welcome Back! We hope you will enjoy your journey with
              Club Freelance.
              <br />
              <strong>The Chat Feature is coming soon!!</strong>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.msgPlaceHolder}>
            <p>Type your message here</p>
          </div>
          <div className={classes.sendBtn}>
            <IconCircle
              type="chevron-right"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
              dimension={30}
              innerColor="#fff"
              iconWidth={8.33}
              iconHeight={8.33}
            />
          </div>
        </div>
        <div className={classes.overlay}>
          <p>Coming Soon</p>
        </div>
      </div>
    );
  }
}

export default ChatWidget;
