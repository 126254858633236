import gql from "graphql-tag";

const REPORTS_QUERY = gql`
  query Reports(
    $limit: Int
    $skip: Int
    $ids: [Int]
    $periodStart: Float
    $periodEnd: Float
    $name: String
    $email: String
  ) {
    reports(
      filter: {
        limit: $limit
        skip: $skip
        placementIDs: $ids
        periodStart: $periodStart
        periodEnd: $periodEnd
        name: $name
        email: $email
      }
    ) {
      id
      placementID
      downloadLink
      total
      sum
      title
      month
      year
    }
  }
`;

export default REPORTS_QUERY;
