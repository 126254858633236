import React, { Component } from "react";
import { Button, Row, Col, Numbering } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";

import Fade from "react-reveal/Fade";
import classes from "./BulletPointSection.module.scss";

class BulletPointSection extends Component {
  render() {
    return (
      <div className={classes.BulletPoint}>
        <Fade>
          <h1>
            <Trans>Club Freelance</Trans>
          </h1>
        </Fade>
        <Fade>
          <p className={classes.paragraph}>
            <Trans>Comment ça marche ?</Trans>
          </p>
        </Fade>
        <Fade bottom>
          <Button
            variant="secondary"
            id="cta_hf_sec3_faq"
            style={{ opacity: "0", cursor: "default" }}
          >
            <Trans>Voir la FAQ</Trans>
          </Button>
        </Fade>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <table>
              <tbody>
                <Fade cascade>
                  <tr>
                    <td>
                      <Numbering dimension={38}>1.</Numbering>
                    </td>
                    <td>
                      <p>
                        <Trans>
                          Vous créez votre profil Club Freelance en seulement 3
                          minutes
                        </Trans>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Numbering dimension={38}>2.</Numbering>
                    </td>
                    <td>
                      <p>
                        <Trans>
                          Votre Talent Community Specialist vous rappelle pour
                          comprendre vos besoins
                        </Trans>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Numbering dimension={38}>3.</Numbering>
                    </td>
                    <td>
                      <p>
                        <Trans>
                          Vous recevez des offres adaptées à vos critères
                        </Trans>
                      </p>
                    </td>
                  </tr>
                </Fade>
              </tbody>
            </table>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <table>
              <tbody>
                <Fade cascade>
                  <tr>
                    <td>
                      <Numbering dimension={38}>4.</Numbering>
                    </td>
                    <td>
                      <p>
                        <Trans>
                          Vous suivez vos candidatures dans votre espace
                          personel
                        </Trans>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Numbering dimension={38}>5.</Numbering>
                    </td>
                    <td>
                      <p>
                        <Trans>
                          On s’occupe de l’admin. Plus qu’à démarrer votre
                          mission
                        </Trans>
                      </p>
                    </td>
                  </tr>
                  {/*<tr>*/}
                  {/*  <td>*/}
                  {/*    <Numbering dimension={38}>6.</Numbering>*/}
                  {/*  </td>*/}
                  {/*  <td>*/}
                  {/*    <p>*/}
                  {/*      <Trans>*/}
                  {/*        On s’occupe de l’admin. Plus qu’à démarrer votre*/}
                  {/*        nouveau job !*/}
                  {/*      </Trans>*/}
                  {/*    </p>*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                </Fade>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withI18n()(BulletPointSection);
