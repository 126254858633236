import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Fade from "react-reveal/Fade";
import * as PropTypes from "prop-types";
import { Button, Row, Col, Schema, SchemaV2 } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import classes from "./TwoFoldExpertiseSection.module.scss";
import Routes from "../../layout/routes";

const getCirclesSpecialists = [
  {
    image: "/assets/images/test/isabel.webp",
    name: "Isabel",
    job: "Talent Aquisition ERP specialist",
    top: 150,
    left: -20
  },
  {
    image: "/assets/images/test/4.webp",
    name: "Nicolas",
    job: "Lead Talent Community Specialist",
    top: 240,
    left: 35
  },
  {
    image: "/assets/images/test/valentin.webp",
    name: "Valentin",
    job: "Talent Aquisition Data & BI specialist",
    top: 260,
    left: 200
  },
  {
    image: "/assets/images/test/marie.webp",
    name: "Marie",
    job: "Talent Aquisition ERP specialist",
    top: 330,
    left: 180
  }
];

const getCirclesManagers = [
  {
    image: "/assets/images/test/5.webp",
    name: "Caroline",
    job: "Key Account Manager",
    top: 190,
    left: 270
  },
  {
    image: "/assets/images/test/jean-philip.webp",
    name: "Jean-Philip",
    job: "Key Account Manager",
    top: 320,
    left: 50
  },
  {
    image: "/assets/images/test/thibaut.webp",
    name: "Thibaut",
    job: "Key Account Manager",
    top: 270,
    left: 200
  }
];

const getCirclesManagers2 = [
  {
    image: "/assets/images/test/5.webp",
    name: "Caroline",
    job: "Key Account Manager",
    top: 140,
    left: 270
  },
  {
    image: "/assets/images/test/jean-philip.webp",
    name: "Jean-Philip",
    job: "Key Account Manager",
    top: 150,
    left: -20
  },
  {
    image: "/assets/images/test/thibaut.webp",
    name: "Thibaut",
    job: "Key Account Manager",
    top: 80,
    left: 40
  }
];

@inject("missionsSearchStore", "appStore", "sessionStore", "modalStore")
@observer
class TwoFoldExpertiseSection extends Component {
  /*
  toForm = () => {
    const { sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CLIENT") {
        const { Offer } = Routes.HbLayoutRoutes;
        history.push(Offer.path);
      } else history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    } else history.push(Routes.HbLayoutRoutes.Login.path);
  };
  */

  clickHandler = () => {
    const { modalStore, sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CANDIDATE") {
        history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
      } else if (signUpUserRole === "CLIENT") {
        modalStore.changeIsVisible(true);
      }
    } else {
      history.push(Routes.HbLayoutRoutes.Login.path);
    }
  };

  render() {
    const { appStore } = this.props;
    return (
      <div className={classes.TwoFoldExpertise}>
        <Fade>
          <h1 className={classes.h11}>
            <Trans>Une double expertise</Trans>
          </h1>
        </Fade>
        <Fade>
          <p className={classes.paragraph}>
            <Trans>
              Nous bénéficions d’une connaissance approfondie des process
              métiers par secteur d’activité, et des compétences techniques par
              domaine d’expertise
            </Trans>
          </p>
        </Fade>
        <Row>
          <Col xs={6} sm={12} md={7} lg={7} xl={7}>
            <table className={classes.table1}>
              <Fade cascade>
                {appStore.width > 768 ? (
                  <tr className={classes.tr1}>
                    <td className={classes.td1}>
                      <Schema
                        circlesSpecialists={getCirclesSpecialists}
                        circlesManagers={getCirclesManagers}
                      />
                    </td>
                  </tr>
                ) : (
                  <tr className={classes.tr1}>
                    <td className={classes.td1}>
                      <SchemaV2
                        circlesSpecialists={getCirclesSpecialists}
                        circlesManagers={getCirclesManagers2}
                      />
                    </td>
                  </tr>
                )}
              </Fade>
            </table>
          </Col>
          <Col xs={6} sm={12} md={5} lg={5} xl={5}>
            <table className={classes.table1}>
              <Fade cascade>
                <tr className={classes.tr1}>
                  <td className={classes.td1}>
                    <p className={classes.p1}>
                      <Trans>
                        Chaque offre de mission est prise en charge par un
                        binôme de deux experts, qui entretiennent des relations
                        de long-terme avec entreprises et consultants
                      </Trans>
                    </p>
                  </td>
                </tr>
              </Fade>

              <Fade cascade>
                <tr className={classes.tr1}>
                  <td className={classes.td1}>
                    <Button
                      onClick={this.clickHandler}
                      // onClick={this.toForm}
                    >
                      <Trans>Je poste un besoin</Trans>
                    </Button>
                  </td>
                </tr>
              </Fade>
            </table>
          </Col>
        </Row>
      </div>
    );
  }
}

// https://github.com/mobxjs/mobx-react/issues/70#issuecomment-231834410
TwoFoldExpertiseSection.wrappedComponent.propTypes = {
  appStore: PropTypes.shape({
    width: PropTypes.number,
    clicked: PropTypes.bool,
    changeClicked: PropTypes.func,
    changeWidth: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    signUpUserRole: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    changeIsVisible: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(TwoFoldExpertiseSection));
