import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Fade from "react-reveal/Fade";
import { Button } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import classes from "./MoreDetails.module.scss";
import Routes from "../../layout/routes";

@inject("sessionStore", "modalStore")
@observer
class MoreDetails extends Component {
  /*
  toForm = () => {
    const { sessionStore, history } = this.props;
    const { signUpUserRole, authToken } = sessionStore;
    if (authToken) {
      if (signUpUserRole === "CLIENT") {
        const { Offer } = Routes.HbLayoutRoutes;
        history.push(Offer.path);
      } else history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    } else history.push(Routes.HbLayoutRoutes.Login.path);
  };
  */

  clickHandler = () => {
    const { history } = this.props;
    const { HbfLayoutRoutes } = Routes;
    const { SearchCandidatesPage } = HbfLayoutRoutes;
    history.push(SearchCandidatesPage.path);
    // const { modalStore, sessionStore, history } = this.props;
    // const { signUpUserRole, authToken } = sessionStore;
    // if (authToken) {
    //   if (signUpUserRole === "CANDIDATE") {
    //     history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
    //   } else if (signUpUserRole === "CLIENT") {
    //     modalStore.changeIsVisible(true);
    //   }
    // } else {
    //   history.push(Routes.HbLayoutRoutes.Login.path);
    // }
  };

  render() {
    return (
      <div className={classes.HowItWorks}>
        <div className={classes.image2} />
        <Fade>
          <h1>
            <Trans>
              Vous souhaitez en savoir plus sur notre fonctionnement ?
            </Trans>
          </h1>
        </Fade>

        <Fade bottom>
          <Button
            variant="primary"
            icon="chevron-right"
            onClick={this.clickHandler}
            // onClick={this.toForm}
          >
            <Trans>Rechercher un talent</Trans>
          </Button>
        </Fade>
      </div>
    );
  }
}

MoreDetails.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    signUpUserRole: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    changeIsVisible: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(MoreDetails));
