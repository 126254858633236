import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Button, FormCard, Icon, Row, Col } from "cf-neo-ui";
import Block from "./Block";
import "./styles.scss";

@inject("modalStore", "talentStore", "jobSubmissionStore")
@observer
class ChoicesWidget extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <div className="pop-up-container">
        <FormCard className="formCard no-gutter">
          <Row style={{ overflow: "auto", maxHeight: "700px" }}>
            <Col className="form-card-content center">
              <span className="center">
                <span className="icon">
                  <Icon
                    className="icon"
                    type="triangle-exclamation"
                    color="#8D0417"
                    color2="#D3354A"
                    width={25}
                    height={25}
                  />
                </span>
                <span className="title">Gérer vos préferences</span>
              </span>
              <p>
                <Trans>
                  Nos partenaires et nous déposons des cookies afin d'assurer la
                  sécurité, améliorer votre expérience digitale et afficher des
                  publicités et contenus personnalisés Vous pouvez accepter ou
                  refuser ces différentes opérations. Par ailleurs, conformément
                  à la législation française, les cookies de mesure d'audience
                  peuvent être exemptés du recueil du consentement de
                  l'internaute. En l'espèce et sur nos sites, nos cookies Web
                  Analytics sont dispensés du recueil du consentement. Gérer vos
                  préférences AT Internet
                </Trans>
              </p>
              <Block
                title="Stocker et/ou accéder à des informations sur un terminal"
                description="Les cookies, identifiants de votre terminal ou autres informations
              peuvent être stockés ou consultés sur votre terminal pour les
              finalités qui vous sont présentées."
                legalInformation="Les partenaires peuvent :
* Stocker des informations et accéder à des informations stockées sur le terminal, comme les cookies et les identifiants du terminal présentés à un utilisateur."
              />
              <br />
              <br />
              <Block
                title="Sélectionner des publicités standard"
                description="Les publicités peuvent vous être présentées en fonction du contenu éditorial que vous consultez, de l’application que vous utilisez, de votre localisation approximative, ou de votre type de terminal"
                legalInformation="Pour sélectionner des publicités standard, les partenaires peuvent :
* Utiliser des informations en temps réel sur le contexte dans lequel la publicité sera affichée, pour afficher la publicité, y compris des informations sur le contenu et le terminal, telles que : type de terminal et capacités, user agent, URL, adresse IP
* Utiliser des données de géolocalisation non-précises d’un utilisateur
* Contrôler la fréquence de diffusion des publicités à un utilisateur.
* Définir l’ordre dans lequel les publicités sont présentées à un utilisateur.
* Empêcher une publicité de s’afficher dans un contexte éditorial inadapté (dangereux pour la marque)
Les partenaires ne peuvent pas :
* Créer un profil publicitaire personnalisé à l’aide de ces informations pour la sélection de publicités futures sans base légale distincte.
N.B. « Non-précises » signifie qu’une géolocalisation approximative dans un rayon d’au moins 500 mètres est autorisée."
              />
              <br />
              <br />
              <Block
                title="Créer un profil personnalisé de publicités"
                description="Un profil peut être créé sur vous et sur vos centres d’intérêt pour vous présenter des publicités personnalisées susceptibles de vous intéresser."
                legalInformation="Pour créer un profil de publicités personnalisées, les partenaires peuvent :
* Collecter des informations sur un utilisateur, notamment son activité, ses centres d’intérêt, les sites ou applications consultés, les données démographiques ou la géolocalisation d’un utilisateur, pour créer ou modifier un profil utilisateur à utiliser dans des publicités personnalisées.
* Combiner ces informations avec d'autres informations précédemment collectées, y compris à partir de sites Web et d'applications, pour créer ou modifier un profil d'utilisateur pour de la publicité personnalisée."
              />
              <br />
              <br />
              <br />
              <div>
                <span className="button-right">
                  <Button
                    icon="chevron-right"
                    iconPosition="right"
                    size="small"
                    onClick={() => {}}
                  >
                    <Trans>Sauvegarder</Trans>
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

ChoicesWidget.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeModalType: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalentDropped: PropTypes.func,
    changeLastParent: PropTypes.func,
    changeNewParent: PropTypes.func,
    talentDropped: PropTypes.shape,
    newParent: PropTypes.shape,
    lastParent: PropTypes.shape
  }).isRequired,
  jobSubmissionStore: PropTypes.shape({
    changeJobSubmission: PropTypes.func,
    jobSubmission: PropTypes.shape
  }).isRequired
};

export default withI18n()(withRouter(ChoicesWidget));
