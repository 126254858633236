import gql from "graphql-tag";

export const LIKE_PROFILE = gql`
  mutation likeProfile($candidateId: String) {
    likeProfile(candidateId: $candidateId) {
      id
    }
  }
`;

export const UNLIKE_PROFILE = gql`
  mutation unLikeProfile($candidateId: String) {
    unLikeProfile(candidateId: $candidateId) {
      id
    }
  }
`;
