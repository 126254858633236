import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Icon, IconCircle, Tag, toaster, H2 } from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import { Mutation, Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import classes from "./Title.module.scss";
import Routes from "../../../layout/routes";
import { FAV_MISSION, UNFAV_MISSION } from "../../../missions-page/mutations";
import { USER_FAVED_MISSIONS_QUERY } from "../../../missions-page/queries";
import theme from "../../../../configs/theme";

@inject("sessionStore")
@observer
class Title extends Component {
  queue = [];

  likeManager = mutation => {
    if (mutation) this.queue.push(mutation);

    /* mutate only when the queue is empty */
    if (this.queue.length === 1) {
      this.favorite(mutation);
    }

    if (this.queue.length > 2) {
      this.queue.splice(
        1,
        (this.queue.length - 1) % 2
          ? this.queue.length - 2
          : this.queue.length - 1
      );
    }
  };

  mutationCompleted = () => {
    this.queue.shift();
    this.likeManager();
  };

  back = () => {
    const { history } = this.props;
    if (history && history.action === "PUSH") history.goBack();
    else history.push({ pathname: Routes.HbfLayoutRoutes.MissionsPage.path });
  };

  favorite = mutation => {
    const { history, sessionStore, match } = this.props;
    if (sessionStore.authToken)
      mutation({ variables: { jobOrderId: match.params.id } });
    else history.push(Routes.HbLayoutRoutes.Login.path);
  };

  isFaved = (favedMissions, missionId) => {
    const { match } = this.props;
    missionId = match.params.id;
    const arr = favedMissions.filter(mission => mission.id === missionId);
    return !!arr.length;
  };

  displayFaveButton = () => {
    const { sessionStore, i18n } = this.props;
    if (sessionStore.authToken && sessionStore.signUpUserRole === "CLIENT")
      return null;
    if (sessionStore.authToken)
      return (
        <Query query={USER_FAVED_MISSIONS_QUERY}>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return null;

            const { userFavedMissions } = data;
            return (
              <Mutation
                mutation={FAV_MISSION}
                onCompleted={this.mutationCompleted}
                onError={() =>
                  toaster.error({
                    title: "Erreur",
                    description: i18n._(
                      t`Erreur côté de serveur. Essayez plus tard`
                    )
                  })
                }
                refetchQueries={[{ query: USER_FAVED_MISSIONS_QUERY }]}
              >
                {favMission => (
                  <Mutation
                    mutation={UNFAV_MISSION}
                    onCompleted={this.mutationCompleted}
                    onError={() =>
                      toaster.error({
                        title: "Erreur",
                        description: i18n._(
                          t`Erreur côté de serveur. Essayez plus tard`
                        )
                      })
                    }
                    refetchQueries={[{ query: USER_FAVED_MISSIONS_QUERY }]}
                  >
                    {unFavMission => (
                      <div
                        className={[classes.iconText, classes.right].join(" ")}
                      >
                        <span
                          style={{ width: "115px" }}
                          onClick={() => {
                            this.isFaved(userFavedMissions)
                              ? this.favorite(unFavMission)
                              : this.favorite(favMission);
                          }}
                        >
                          {this.isFaved(userFavedMissions) ? (
                            <Trans>Enlever de favoris</Trans>
                          ) : (
                            <Trans>Mettre en favori</Trans>
                          )}
                        </span>
                        <Icon
                          type="heart"
                          color={theme.colors.mainColor}
                          color2={theme.colors.lightMainColor}
                          filled={this.isFaved(userFavedMissions)}
                          width={16}
                          height={15}
                          onClick={() => {
                            this.isFaved(userFavedMissions)
                              ? this.likeManager(unFavMission)
                              : this.likeManager(favMission);
                          }}
                        />
                      </div>
                    )}
                  </Mutation>
                )}
              </Mutation>
            );
          }}
        </Query>
      );
    return (
      <div className={[classes.iconText, classes.right].join(" ")}>
        <span onClick={this.favorite}>
          <Trans>Mettre en favori</Trans>
        </span>
        <Icon
          type="heart"
          color={theme.colors.mainColor}
          color2={theme.colors.lightMainColor}
          width={16}
          height={15}
          onClick={this.favorite}
        />
      </div>
    );
  };

  render() {
    const { title, match, history } = this.props;
    return (
      <div className={classes.container}>
        <div
          className={[classes.iconText, classes.left].join(" ")}
          onClick={this.back}
        >
          <IconCircle
            type="chevron-left"
            color={theme.colors.mainColor}
            color2={theme.colors.lightMainColor}
            dimension={30}
            innerColor="#fff"
            iconWidth={8.33}
            iconHeight={8.33}
          />
          {history && history.action === "PUSH" ? (
            <span>
              <Trans>Retour</Trans>
            </span>
          ) : (
            <span>
              <Trans>Missions</Trans>
            </span>
          )}
        </div>
        {this.displayFaveButton()}
        <div className={classes.titleWrapper}>
          <div className={classes.title}>
            <div className={classes.border}>
              <H2 className={classes.titleText}>{title}</H2>
            </div>
            <Tag
              className={classes.tagSpan}
              text={`# ${match.params.id}`}
              variant="tertiary"
            />
          </div>
        </div>
      </div>
    );
  }
}

Title.wrappedComponent.propTypes = {
  title: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    signUpUserRole: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
    action: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Title));
