import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import {
  Container,
  Row,
  Col,
  IconCircle,
  SourcerNoteCard,
  Tag
} from "cf-neo-ui";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import classes from "./dashboardSingleMission.module.scss";
import StatusBar from "./status-bar/StatusBar";
import ChatWidget from "./chat/ChatWidget";
import USER_APPLICATION_QUERY from "./queries";
import Tasks from "./tasks/Tasks";
import Routes from "../layout/routes";
import theme from "../../configs/theme";

class DashboardSingleMission extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  back = () => {
    const { history } = this.props;
    if (history) history.goBack();
  };

  createMarkup = htmlStr => {
    // We are using dangerouslySetInnerHTML here (despite it’s risky) because we
    // are handling data that will be returned by Bullhorn as HTML string
    // eslint-disable-next-line react/no-danger
    return htmlStr ? (
      <div dangerouslySetInnerHTML={{ __html: htmlStr }} />
    ) : null;
  };

  render() {
    const { match, i18n } = this.props;
    return (
      <Query
        query={USER_APPLICATION_QUERY}
        variables={{ jobOrderId: match.params.id }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Loading</div>;
          if (error)
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );

          const { userApplication } = data;
          const { sourcers } = userApplication.jobOrder;
          const sourcer = sourcers[sourcers.length - 1];
          return (
            <div className={classes.dashboardSingleMission}>
              <Container>
                <Row>
                  <Col lg={8} md={12}>
                    <div
                      className={`${classes.iconText} ${classes.backButton}`}
                      style={{ float: "left" }}
                      onClick={this.back}
                    >
                      <IconCircle
                        type="chevron-left"
                        color={theme.colors.mainColor}
                        color2={theme.colors.lightMainColor}
                        dimension={30}
                        innerColor="#fff"
                        iconWidth={8.33}
                        iconHeight={8.33}
                      />
                      <span>Back</span>
                    </div>
                    <div className={classes.title}>
                      <h2>{userApplication.jobOrder.title}</h2>
                      <Tag
                        text={i18n._(t`Voir l’offre`)}
                        variant="tertiary"
                        className={classes.titleBtn}
                        onClick={() => {
                          const { history } = this.props;
                          if (history)
                            history.push(
                              `${Routes.HbfLayoutRoutes.MissionsPage.path}/${match.params.id}`
                            );
                        }}
                      />
                    </div>
                    <div className={classes.statusBar}>
                      <StatusBar status={userApplication.status} />
                    </div>
                    <div className={classes.sourcerCard}>
                      <SourcerNoteCard
                        avatar={sourcer.headShot}
                        name={sourcer.name || ""}
                        email={sourcer.email || ""}
                        phoneNumber={sourcer.phone || ""}
                        note={
                          this.createMarkup(userApplication.lastNote) ||
                          i18n._(t`Il n’y a pas encore de retour`)
                        }
                      />
                    </div>
                    <div className={classes.tabs}>
                      <Tasks
                        reference={userApplication.reference}
                        missionId={userApplication.jobOrder.id}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <ChatWidget />
                  </Col>
                </Row>
              </Container>
            </div>
          );
        }}
      </Query>
    );
  }
}

DashboardSingleMission.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(DashboardSingleMission));
