/* eslint-disable no-unused-expressions,no-plusplus,no-underscore-dangle */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Col,
  Icon,
  MissionOfferCard,
  Modal,
  Row,
  toaster,
  VoidOfferCard
} from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { Query } from "react-apollo";
import { withI18n } from "@lingui/react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import classes from "./offers.module.scss";
import Routes from "../../layout/routes";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import { ACCESSIBLE_CLIENT_OPENED_OFFERS_QUERY } from "../queries";
import LoadingMissions from "../../../components/LoadingMissions/LoadingMissions";
import formatMissionDuration from "../../../utils/formatMissionDuration";
import PostNeedSelection from "../../post-a-need/postNeedSelection";
import theme from "../../../configs/theme";
import getContractType from "../../../utils/getContractType";
import getIconType from "../../../utils/getIconType";

@inject("sessionStore")
@observer
class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skip: 0,
      limit: 3,
      pageSize: 3,
      currentPage: 1,
      isVisible: false
    };
    this.displayedAcc = {
      0: {
        displayedVac: 0,
        displayedOpp: 0
      }
    };
    this.total = 0;
  }

  toDetails = (opportunityId, vacancyId, type, status) => {
    const { history, i18n } = this.props;
    if (opportunityId && type && status === "underView")
      history.push(
        `/dashboard/company/opportunities/offer-details/edit/${opportunityId}`
      );
    else if ((opportunityId && !type) || status === "rejected")
      toaster.info({
        title: i18n._(t`Désolé`),
        description: i18n._(t`Vous ne pouvez pas modifier cette opportunité`),
        expiresIn: 3
      });
    if (vacancyId) history.push(`/dashboard/company/offers/${vacancyId}`);
  };

  toForm = () => {
    const { history } = this.props;
    const { Offer } = Routes.HbLayoutRoutes;
    history.push(Offer.path);
  };

  getQueryVariables = () => {
    const { sessionStore } = this.props;
    const { id } = sessionStore;
    const clientID = Number(id);
    const { skip, limit } = this.state;
    return { id: clientID, limit, skipVac: skip, isOpen: true };
  };

  getStatus = (status, type) => {
    const { i18n } = this.props;
    if (type === "Opportunity") {
      if (status.toLowerCase() === "open") {
        return {
          status: "underView",
          text: i18n._(t`A l’étude`)
        };
      }
      if (status.toLowerCase() === "closed") {
        return {
          status: "rejected",
          text: i18n._(t`rejetée`)
        };
      }
    }

    if (type === "ClientVacancy") {
      if (status === false) {
        return {
          status: "closed",
          text: i18n._(t`fermée`)
        };
      }
    }

    return { status: "open", text: "ouverte" };
  };

  getOffersToDisplay2 = clientVacancies => {
    const { pageSize, currentPage, status } = this.state;

    const offersTemp = status ? [...clientVacancies] : [...clientVacancies];
    const offers = offersTemp
      .sort((a, b) => b.dateAdded - a.dateAdded)
      .slice(0, pageSize);

    if (!this.displayedAcc[currentPage]) {
      this.displayedAcc[currentPage] = {
        ...this.displayedAcc[currentPage - 1]
      };

      offers.reduce((acc, { __typename }) => {
        __typename === "ClientVacancy" && acc.displayedVac++;
        return acc;
      }, this.displayedAcc[currentPage]);
    }

    return offers;
  };

  setTotalOffers2 = clientVacancies => {
    if (this.total) return this.total;
    const vac = clientVacancies[0];
    if (vac) this.total += vac.total;
    return this.total;
  };

  clickHandler = () => {
    this.setState({ isVisible: true });
  };

  getRecommendedOffers = () => {
    const { i18n } = this.props;
    return (
      <Query
        query={ACCESSIBLE_CLIENT_OPENED_OFFERS_QUERY}
        variables={this.getQueryVariables()}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <LoadingMissions
                cardsCount={3}
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={6}
                colClassName={classes.favedMissionsCard}
                containerClassName={classes.marTop}
              />
            );

          if (error) {
            return (
              <div className="error_container">
                <span>
                  {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                </span>
                <img
                  style={{
                    margin: 20
                  }}
                  src={"/assets/svg/download.svg"}
                  alt="error"
                />
              </div>
            );
          }
          const { accessibleClientVacancies } = data;
          const recommended = this.getOffersToDisplay2(
            accessibleClientVacancies
          );
          const totalOffers = this.setTotalOffers2(accessibleClientVacancies);
          // const recommended = clientOpportunities.concat(clientVacancies);
          if (!totalOffers) {
            return (
              <div className={classes.missionCards}>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={6}
                  className={classes.favedMissionsCard}
                >
                  <VoidOfferCard onClick={this.clickHandler} />
                  <div style={{ marginTop: "20px" }}>
                    <Trans>Poster une offre</Trans>
                  </div>
                </Col>
              </div>
            );
          }

          return (
            <Row>
              {recommended.map(offer => {
                let offerStatus = { status: "open", text: "ouverte" };
                offerStatus = this.getStatus(
                  offer.status || offer.isOpen,
                  offer.__typename
                );
                return (
                  <Col
                    key={offer.id || offer._id}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={6}
                    className={classes.favedMissionsCard}
                  >
                    <MissionOfferCard
                      key={offer.id}
                      preferred
                      title={offer.title}
                      location={offer.address.city || offer.address.address1}
                      duration={formatMissionDuration(
                        offer.duration,
                        i18n,
                        offer.employmentType || offer.type
                      )}
                      projectName={offer.project}
                      NumberOfCandidates={offer.totalCVSent}
                      field={offer.customText1 || offer.workRate}
                      contractType={getContractType(
                        i18n,
                        offer.employmentType || offer.type || ""
                      )}
                      iconType={getIconType(
                        offer.employmentType || offer.type || ""
                      )}
                      onPrefer={() => {}}
                      onClick={() =>
                        this.toDetails(
                          offer._id,
                          offer.id,
                          offer.type,
                          offerStatus.status
                        )
                      }
                      href="https://www.club-freelance.com/fr/"
                      label={i18n._(t`candidat`)}
                      onInterested={() => {}}
                      onSkip={() => {}}
                      avatarsNumber={offer.totalCVSent}
                      avatarTable={[
                        { src: "/defaultAvatar.webp" },
                        { src: "/defaultAvatar.webp" }
                      ]}
                      cardState={offerStatus.status}
                      cardStateText={offerStatus.text}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        }}
      </Query>
    );
  };

  render() {
    const { history, i18n } = this.props;
    const { isVisible } = this.state;
    const meta = staticMetaTags(i18n).dashboardCompany;
    return (
      <div className={classes.recommendedMissionsSection}>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          onClose={() => this.setState({ isVisible: false })}
          position="fixed"
        >
          <PostNeedSelection />
        </Modal>
        {metaTags(
          urls.dashboardCompany(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <div className={classes.title}>
          <Trans>Offres en cours</Trans>
        </div>

        {this.getRecommendedOffers()}

        <br />

        <div className={classes.allMissionsLink}>
          <span
            onClick={() => history.push(Routes.LpbLayoutRoutes.Offers.path)}
          >
            <Trans>Voir toutes les offres en cours</Trans>
            <Icon
              className={classes.chevronRight}
              type="chevron-right"
              size="tiny"
              color={theme.colors.mainColor}
              color2={theme.colors.lightMainColor}
            />
          </span>
        </div>
      </div>
    );
  }
}

Offers.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func,
    language: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default withI18n()(withRouter(Offers));
