import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";

import { inject, observer } from "mobx-react";
import { Button, FormCard, Icon, Row, Col } from "cf-neo-ui";
import "./styles.scss";
import theme from "../../../../configs/theme";

@inject("modalStore", "talentStore", "jobSubmissionStore")
@observer
class PopUpForNew extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  clickHandler = () => {
    const { modalStore, talentStore } = this.props;
    const { changeIsVisible, changeModalType } = modalStore;
    const { talentDropped, lastParent } = talentStore;
    let parent = null;
    for (let i = 0; i < lastParent.childNodes.length; i += 1) {
      if (lastParent.childNodes[i].id === "card") {
        parent = lastParent.childNodes[i];
        break;
      }
    }
    parent.appendChild(talentDropped);
    changeIsVisible(false);
    changeModalType("");
  };

  render() {
    return (
      <div className="pop-up-container">
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className="form-card-content center">
              <span className="center">
                <span className="icon">
                  <Icon
                    className="icon"
                    type="triangle-exclamation"
                    color={theme.colors.mainColor}
                    color2={theme.colors.lightMainColor}
                    width={25}
                    height={25}
                  />
                </span>
                <span className="title">Action impossible</span>
              </span>
              <p>
                <Trans>
                  Vous ne pouvez pas déplacer la carte vers cette colonne
                </Trans>
              </p>

              <br />
              <div className="center">
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={() => this.clickHandler()}
                >
                  <Trans>Ok</Trans>
                </Button>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

PopUpForNew.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeModalType: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    talentDropped: PropTypes.shape({}),
    lastParent: PropTypes.shape({})
  }).isRequired
};

export default withI18n()(withRouter(PopUpForNew));
