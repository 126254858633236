import gql from "graphql-tag";

const MY_TS_NOTIFS_QUERY = gql`
  query myTsNotifs($limit: Int, $types: [TimesheetNotificationsTypes]) {
    myTsNotifs(filter: { limit: $limit, types: $types }) {
      _id
      cfUniqueID
      timesheetID
      placementID
      month
      year
      type
      seen
      seenDate
      dateAdded
    }
  }
`;
export default MY_TS_NOTIFS_QUERY;
