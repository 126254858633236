import gql from "graphql-tag";

const TIME_SHEETS_QUERY = gql`
  query Timesheets(
    $limit: Int
    $skip: Int
    $ids: [Int]
    $name: String
    $status: [timesheetSupportedstatus]
    $periodStart: Float
    $periodEnd: Float
    $month: Int
    $year: Int
    $email: String
  ) {
    timesheets(
      filter: {
        limit: $limit
        skip: $skip
        placementIDs: $ids
        name: $name
        status: $status
        periodStart: $periodStart
        periodEnd: $periodEnd
        month: $month
        year: $year
        email: $email
      }
    ) {
      id
      title
      client {
        id
        firstName
        lastName
        name
        email
        companyName
      }
      candidate {
        id
        firstName
        lastName
        name
        email
        city
      }
      placementID
      dateAdded
      month
      year
      dateLastUpdate
      status
      statusMaker
      selectedDays {
        day
        hours
        minutes
      }
      specialHours {
        id
        date
        hours
        minutes
        type
      }
      approvingClient {
        id
        firstName
        lastName
        name
        email
        companyName
      }
      total
      signature
    }
  }
`;

const SINGLE_TIME_SHEETS_QUERY = gql`
  query Timesheet($id: Int!) {
    timesheet(id: $id) {
      id
      title
      client {
        id
        firstName
        lastName
        name
        email
        companyName
      }
      candidate {
        id
        firstName
        lastName
        name
        email
        city
      }
      clientCorporation {
        id
        name
      }
      placementID
      dateAdded
      month
      year
      dateLastUpdate
      status
      statusMaker
      selectedDays {
        day
        hours
        minutes
      }
      specialHours {
        id
        date
        hours
        minutes
        type
      }
      total
      signature
      conversation {
        timesheetID
        messages {
          _id
          text
          date
          speaker
        }
        comment
      }
      approvingClient {
        id
        firstName
        lastName
        name
        email
        companyName
      }
      approvingClient2 {
        id
        firstName
        lastName
        name
        email
        companyName
      }
      country
    }
  }
`;

const ISLOCKED = gql`
  query Islocked($id: Int!, $role: String!) {
    islocked(id: $id, role: $role)
  }
`;

export { TIME_SHEETS_QUERY, SINGLE_TIME_SHEETS_QUERY, ISLOCKED };
