import gql from "graphql-tag";

const CHANGE_VALIDATED_JOB_SUBMISSION_STATUS = gql`
  mutation ChangeVJSStatus(
    $jobOrderID: Int!
    $candidateID: Int!
    $status: String!
    $comment: String
  ) {
    changeVJSStatus(
      jobOrderID: $jobOrderID
      candidateID: $candidateID
      status: $status
      comment: $comment
    )
  }
`;

export { CHANGE_VALIDATED_JOB_SUBMISSION_STATUS };
