import { observable, action } from "mobx";

export default class SignataireStore {
  @observable SignatoryList;

  @observable timesheetId;

  @observable signatoryType;

  @observable placementID;

  @observable email;

  constructor(initialState = {}) {
    this.SignatoryList = initialState.SignatoryList || [];
    this.email = initialState.email || "";
    this.signatoryType = initialState.secondarySignataires || null;
    this.email = initialState.email || "";
    this.placementID = initialState.placementID || null;
  }

  @action
  addSignatory = email => {
    const tempArray = this.SignatoryList;
    tempArray.push({
      id: this.timesheetId,
      firstName: "anonyme",
      lastName: "anonyme",
      email
    });
    this.SignatoryList = tempArray;
  };

  @action
  removeSignatory = email => {
    const tempArray = this.SignatoryList;
    this.SignatoryList = tempArray.filter(obj => {
      return obj.email !== email;
    });
  };

  @action
  changeSignatoryType = signatoryType => {
    this.signatoryType = signatoryType;
  };

  @action
  changePlacementID = placementID => {
    this.placementID = placementID;
  };

  @action
  changeSignatory = SignatoryList => {
    this.SignatoryList = SignatoryList;
  };

  @action
  changeEmail = email => {
    this.email = email;
  };

  @action
  changeTimesheetId = timesheetId => {
    this.timesheetId = timesheetId;
  };

  @action
  reset = () => {
    this.SignatoryList = [];
  };

  @action
  resetTimesheetId = () => {
    this.timesheetId = null;
  };
}
