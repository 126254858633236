import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  Divider,
  CheckboxGroup,
  TextInput,
  FormGroup,
  Button,
  Validation,
  Checkbox,
  ImportPhoto,
  PasswordInput,
  toaster,
  Spinner
} from "cf-neo-ui";
import staticMetaTags from "../../../configs/staticPagesMetaTags";
import validate from "../../../utils/validators";
import { scorePassword } from "../../../utils/helpers";
import Location from "../../../components/location/location";
import "./styles.scss";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";

const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidateMutation(
    $_id: ID!
    $password: String
    $firstName: String
    $lastName: String
    $phonenumber: String
    $customText20: String
    $address: AddressInput
    $preferredContact: String
    $receiveNewsletter: Boolean
    $lastInfo: Boolean
    $profilePhoto: Upload
    $pictureUrl: String
  ) {
    updateCandidate(
      input: {
        _id: $_id
        password: $password
        firstName: $firstName
        lastName: $lastName
        phonenumber: $phonenumber
        customText20: $customText20
        address: $address
        preferredContact: $preferredContact
        receiveNewsletter: $receiveNewsletter
        lastInfo: $lastInfo
        profilePhoto: $profilePhoto
        pictureUrl: $pictureUrl
      }
    ) {
      account {
        id
        name
      }
      candidate {
        _id
        email
        name
        occupation
        firstName
        lastName
        phone
        dayRateLow
        dayRate
        employmentPreference
        companyURL
        dateAvailable
        categories
        experience
        customText21
        customInt10
        profilePhoto
        preferredContact
        salary
        receiveNewsletter
        lastInfo
        cv
        address {
          address1
          city
          countryID
          zip
          state
          lat
          lng
        }
        searchAddress {
          address1
          city
          countryID
          lat
          lng
          state
        }
      }
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class myInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: "",
      isfirstNameValid: true,
      isLinkValid: true,
      islastNameValid: true,
      isAddressValid: true,
      isEmailValid: true,
      isPhonenumberValid: true,
      isPasswordValid: true,
      firstNameValidationMessage: "",
      lastNameValidationMessage: "",
      addressValidationMessage: "",
      emailValidationMessage: "",
      phonenumberValidationMessage: "",
      passwordValidationMessage: "",
      isConfirmPasswordValid: true,
      confirmPasswordValidationMessage: "",
      linkValidationMessage: "",
      saveCase: false
    };
    this.isFormValid = this.isFormValid.bind(this);
  }

  handleEvaluator = v => {
    return scorePassword(v);
  };

  getLocationHandler = (address, formattedAddress) => {
    const { sessionStore } = this.props;
    sessionStore.changeAddress({
      address1: address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      zip: formattedAddress.zip,
      state: formattedAddress.state,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    });
    this.validateAddress(address);
  };

  addressChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changeAddress({ address1: v });
    this.validateAddress(v);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  isFormValid = () => {
    const { sessionStore, i18n } = this.props;
    const {
      firstName,
      lastName,
      address,
      phonenumber,
      password,
      customText20
    } = sessionStore;
    const { confirmPassword } = this.state;

    let valid = true;
    if (!this.validatefirstName(firstName)) valid = false;
    if (!this.validatelastName(lastName)) valid = false;
    if (!this.validateAddress(address.address1)) valid = false;
    if (!this.validatePhonenumber(phonenumber)) valid = false;
    if (!this.validateLink(customText20)) valid = false;
    if (!this.validatePassword(password)) valid = false;
    if (!this.validateConfirmPassword(confirmPassword)) valid = false;
    if (confirmPassword !== password) {
      valid = false;
      this.setState({
        isConfirmPasswordValid: false,
        confirmPasswordValidationMessage: i18n._(
          t`Ce champs ne contient pas la même valeur`
        )
      });
    }
    return valid;
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  validatefirstName = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isfirstNameValid: res.isValid,
      firstNameValidationMessage: res.message
    });
    return res.isValid;
  };
  validateLink(value) {
    const { i18n } = this.props;
    var res = validate(value, ["link"], i18n);
    let verifLink = value ? value.includes("linkedin") : true;
    res.isValid = res.isValid ? verifLink : res.isValid;
    this.setState({
      isLinkValid: res.isValid,
      linkValidationMessage: res.message
    });
    return res.isValid;
  }

  validatelastName = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      islastNameValid: res.isValid,
      lastNameValidationMessage: res.message
    });
    return res.isValid;
  };

  validateAddress = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    let mesg = "";
    if (!res.isValid) mesg = i18n._(t`Non renseigné`);
    this.setState({
      isAddressValid: res.isValid,
      addressValidationMessage: mesg
    });
    return res.isValid;
  };

  validatePhonenumber = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 13 }, { minlength: 8 }],
      i18n
    );
    this.setState({
      isPhonenumberValid: res.isValid,
      phonenumberValidationMessage: res.message
    });
    return res.isValid;
  };

  validatePassword(value) {
    const { i18n } = this.props;
    const { confirmPassword } = this.state;
    if (!value && confirmPassword !== "") {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }

    if (!value && confirmPassword === "") {
      this.setState({
        isPasswordValid: true,
        passwordValidationMessage: ""
      });
      return true;
    }

    if (value.length < 8) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins 8 caractères`
        )
      });
      return false;
    }

    if (!/[a-z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une minuscule`
        )
      });
      return false;
    }
    if (!/[A-Z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une majuscule`
        )
      });
      return false;
    }
    if (!/\d/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Doit contenir au moins un chiffre`)
      });
      return false;
    }
    if (!/\W/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins un caractère spécial !*#@...`
        )
      });
      return false;
    }
    this.setState({
      isPasswordValid: true,
      passwordValidationMessage: ""
    });
    return true;
  }

  receiveNewsletterChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeReceiveNewsletter(!sessionStore.receiveNewsletter);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };
  lastInfoChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeLastInfo(!sessionStore.lastInfo);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  preferredContactChangeHandler = v => {
    if (Array.isArray(v)) {
      const { sessionStore } = this.props;
      sessionStore.changePreferredContact(v.toString());
    }
    const { saveCase } = this.state;
    const { sessionStore } = this.props;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  firstNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changefirstName(e.target.value);
    this.validatefirstName(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  lastNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changelastName(e.target.value);
    this.validatelastName(e.target.value);

    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  emailChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeEmail(String(e.target.value).toLowerCase());
    this.validateEmail(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  phonenumberChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changePhonenumber(e.target.value);
    this.validatePhonenumber(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  passwordChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changePassword(v);
    this.validatePassword(v);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    // changeGeneralSaveCase(2);
  };

  confirmPasswordChangeHandler = v => {
    this.setState({ confirmPassword: v });
    this.validateConfirmPassword(v);
  };

  handleUploadProfilePhoto = fileUploaded => {
    const { sessionStore } = this.props;
    sessionStore.changeProfilePhoto(fileUploaded);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
    const { changeGeneralSaveCase } = sessionStore;
    changeGeneralSaveCase(2);
  };

  linkedInURLChangeHandler = e => {
    const { sessionStore } = this.props;
    const valid = this.validateLink(e.target.value);
    sessionStore.changeLinkedInURL(e.target.value);
  };

  updateCandidateCompletedHandler(data) {
    const { i18n, sessionStore, appStore } = this.props;
    toaster.success({
      title: i18n._(t`Mes coordonnées`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    if (data.updateCandidate) {
      sessionStore.changePictureUrl(
        data.updateCandidate.candidate.profilePhoto
      );
    }
    this.setState({ saveCase: false });
    sessionStore.changeGeneralSaveCase(null);
    appStore.refreshLayout();
  }

  validateConfirmPassword(value) {
    const { i18n, sessionStore } = this.props;
    const res = validate(value, [{ similarTo: sessionStore.password }], i18n);
    this.setState({
      isConfirmPasswordValid: res.isValid,
      confirmPasswordValidationMessage: res.message
    });
    return res.isValid;
  }

  render() {
    const { i18n, sessionStore } = this.props;
    const {
      _id,
      firstName,
      lastName,
      address,
      addressToShow,
      email,
      phonenumber,
      customText20,
      password,
      preferredContact,
      receiveNewsletter,
      lastInfo,
      profilePhoto,
      pictureUrl
    } = sessionStore;
    const {
      confirmPassword,
      isfirstNameValid,
      islastNameValid,
      isAddressValid,
      isEmailValid,
      isLinkValid,
      isPhonenumberValid,
      isPasswordValid,
      isConfirmPasswordValid,
      firstNameValidationMessage,
      lastNameValidationMessage,
      addressValidationMessage,
      emailValidationMessage,
      linkValidationMessage,
      phonenumberValidationMessage,
      passwordValidationMessage,
      confirmPasswordValidationMessage,
      saveCase
    } = this.state;
    const preferredContactArray = preferredContact
      ? preferredContact.split(",")
      : [];
    const meta = staticMetaTags(i18n).details;
    return (
      <div className="profile my-information">
        {metaTags(
          urls.myDetails(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords
        )}
        <div className="formCard no-gutter with-padding">
          <form>
            <Row className="photo-row">
              <Col lg={2} xl={2}>
                <FormGroup>
                  <ImportPhoto
                    className="profile-photo"
                    dimension="114px"
                    src={pictureUrl || profilePhoto || "/defaultAvatar.png"}
                    errorMessageDescription={i18n._(
                      t`Format d’image invalide. Formats recommandés : .jpeg .png`
                    )}
                    errorMessageTitle={`${i18n._(t`Erreur`)} !`}
                    onChange={(e, fileUploaded) =>
                      this.handleUploadProfilePhoto(e, fileUploaded)
                    }
                  />
                </FormGroup>
              </Col>
              <Col lg={4} xl={4} md={4} sm={4} xs={6}>
                <Row>
                  <Col lg={12} xl={12}>
                    <FormGroup>
                      <label className="form-label" htmlFor="user_first_name">
                        <Trans>Prénom</Trans>
                      </label>
                      <Validation
                        errorMessage={firstNameValidationMessage}
                        valid={isfirstNameValid}
                      >
                        <TextInput
                          id="user_first_name"
                          type="text"
                          spellCheck="false"
                          className="form-input"
                          placeholder={i18n._(t`Prénom`)}
                          value={firstName}
                          onChange={this.firstNameChangeHandler}
                        />
                      </Validation>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label className="form-label" htmlFor="user_name">
                        <Trans>Nom</Trans>
                      </label>
                      <Validation
                        errorMessage={lastNameValidationMessage}
                        valid={islastNameValid}
                      >
                        <TextInput
                          id="user_name"
                          type="text"
                          spellCheck="false"
                          className="form-input"
                          placeholder={i18n._(t`Nom de famille`)}
                          value={lastName}
                          onChange={this.lastNameChangeHandler}
                        />
                      </Validation>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} xl={6}>
                <FormGroup>
                  <label className="form-label" htmlFor="user_address">
                    <Trans>Ville de résidence</Trans>
                  </label>
                  <Validation
                    errorMessage={addressValidationMessage}
                    valid={isAddressValid}
                  >
                    <Location
                      id="user_address"
                      onChange={this.addressChangeHandler}
                      value={address.address1}
                      getLocation={(Address, FormattedAddress) =>
                        this.getLocationHandler(Address, FormattedAddress)
                      }
                      valid={isAddressValid}
                    />
                  </Validation>
                </FormGroup>
                <Row>
                  <Col>
                    <FormGroup>
                      <label className="form-label" htmlFor="user_phone">
                        <Trans>Téléphone</Trans>
                      </label>
                      <Validation
                        errorMessage={phonenumberValidationMessage}
                        valid={isPhonenumberValid}
                      >
                        <TextInput
                          id="user_phone"
                          type="tel"
                          className="form-input"
                          placeholder="06XXXXXXXX"
                          value={phonenumber}
                          onChange={this.phonenumberChangeHandler}
                        />
                      </Validation>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                <FormGroup>
                  <label className="form-label" htmlFor="user_email">
                    <Trans>Email</Trans>
                  </label>
                  <Validation
                    errorMessage={emailValidationMessage}
                    valid={isEmailValid}
                  >
                    <TextInput
                      id="user_email"
                      type="email"
                      spellCheck="false"
                      className="form-input"
                      placeholder={i18n._(t`monemail@mail.com`)}
                      value={email}
                      disabled
                    />
                  </Validation>
                </FormGroup>
              </Col>
              <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                <FormGroup>
                  <label className="form-label" htmlFor="user_LinkedIn_URL">
                    <Trans>URL LinkedIn</Trans>
                  </label>
                  <Validation
                    errorMessage={linkValidationMessage}
                    valid={isLinkValid}
                  >
                    <TextInput
                      id="linkedin"
                      type="url"
                      autoComplete="off"
                      className="form-input"
                      placeholder={i18n._(t`https://www.linkedin.com/in/user/`)}
                      value={customText20 ? customText20 : " "}
                      onChange={this.linkedInURLChangeHandler}
                    />
                  </Validation>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                <FormGroup>
                  <label className="form-label" htmlFor="user_password">
                    <Trans>Créer un nouveau mot de passe</Trans>
                  </label>
                  <Validation
                    errorMessage={passwordValidationMessage}
                    valid={isPasswordValid}
                  >
                    <PasswordInput
                      id="user_password"
                      autoComplete="off"
                      className="form-input"
                      message={i18n._(t`Niveau de sécurité`)}
                      placeholder={i18n._(t`Entrer un mot de passe`)}
                      passwordEvaluator={this.handleEvaluator}
                      value={password}
                      onChange={this.passwordChangeHandler}
                    />
                  </Validation>
                </FormGroup>
              </Col>
              <Col lg={6} xl={6} md={6} sm={6} xs={6}>
                <FormGroup>
                  <label className="form-label" htmlFor="user_confirm_pass">
                    <Trans>Confirmez le nouveau mot de passe</Trans>
                  </label>
                  <Validation
                    errorMessage={confirmPasswordValidationMessage}
                    valid={isConfirmPasswordValid}
                  >
                    <PasswordInput
                      id="user_confirm_pass"
                      className="form-input"
                      message={i18n._(t`Niveau de sécurité`)}
                      placeholder={i18n._(t`Entrer un mot de passe`)}
                      value={confirmPassword}
                      onChange={this.confirmPasswordChangeHandler}
                    />
                  </Validation>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <span
                  className="contact-text"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Trans>Je préfère être contacté par :</Trans>
                </span>
                <CheckboxGroup
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  name="CheckboxGrp"
                  defaultSelected={preferredContactArray}
                  onChange={this.preferredContactChangeHandler}
                  orientation="horizontal"
                >
                  <Checkbox
                    name="email"
                    label={i18n._(t`Email`)}
                    value="email"
                  />
                  <Checkbox
                    name="phone"
                    label={i18n._(t`Téléphone`)}
                    value="phone"
                  />
                  <Checkbox name="sms" label={i18n._(t`SMS`)} value="sms" />
                </CheckboxGroup>
              </Col>
            </Row>
          </form>
          <Divider />
          <Row className="accept_terms">
            <Col>
              <Checkbox
                name="receive_newsletter"
                value="receive_newsletter"
                checked={receiveNewsletter}
                label={i18n._(
                  t`Recevoir notre newsletter bimensuelle spéciale Tech & IT (conseils pratiques, dossiers spéciaux, sélection de missions, etc.).`
                )}
                onClickButton={this.receiveNewsletterChangeHandler}
              />
            </Col>
          </Row>
          <Row className="accept_terms">
            <Col>
              <Checkbox
                name="receive_newsletter"
                value="receive_newsletter"
                checked={lastInfo}
                label={i18n._(
                  t`Je souhaite recevoir les dernières informations ainsi que les avantages communautés de Club Freelance (webinaires, événements, blog, réductions, etc.)`
                )}
                onClickButton={this.lastInfoChangeHandler}
              />
            </Col>
          </Row>
          <Row style={{ flexDirection: "row-reverse" }}>
            {" "}
            <div
              className="next-buttons"
              style={{ marginRight: "19px", marginTop: "10px" }}
            >
              <Mutation
                mutation={UPDATE_CANDIDATE_MUTATION}
                variables={{
                  _id,
                  firstName,
                  lastName,
                  address,
                  phonenumber,
                  customText20,
                  password,
                  preferredContact,
                  receiveNewsletter,
                  lastInfo,
                  pictureUrl,
                  ...(typeof profilePhoto === "object" && {
                    profilePhoto
                  })
                }}
                onCompleted={data => this.updateCandidateCompletedHandler(data)}
                onError={errors => {
                  errors.graphQLErrors.forEach(({ message, data }) => {
                    if (data && data.isCustomError) {
                      this.onErrorHandler(message);
                    }
                  });
                }}
              >
                {(mutation, { loading }) => (
                  <Button
                    disabled={loading || !saveCase}
                    onClick={() => {
                      if (this.isFormValid()) return mutation();
                      return null;
                    }}
                  >
                    {loading ? (
                      <Spinner
                        type="pointed-circular"
                        color="#FFFFFF"
                        size={12}
                      />
                    ) : (
                      <Trans>Enregistrer</Trans>
                    )}
                  </Button>
                )}
              </Mutation>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

myInformation.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeAddress: PropTypes.func,
    changeReceiveNewsletter: PropTypes.func,
    changeLastInfo: PropTypes.func,
    changePreferredContact: PropTypes.func,
    changefirstName: PropTypes.func,
    changelastName: PropTypes.func,
    changePhonenumber: PropTypes.func,
    changeLinkedInURL: PropTypes.func,
    changePassword: PropTypes.func,
    changeEmail: PropTypes.func,
    changePictureUrl: PropTypes.func,
    changeProfilePhoto: PropTypes.func,
    receiveNewsletter: PropTypes.bool,
    lastInfo: PropTypes.bool,
    preferredContact: PropTypes.shape,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address: PropTypes.shape,
    addressToShow: PropTypes.string,
    phonenumber: PropTypes.string,
    customText20: PropTypes.string,
    password: PropTypes.string,
    email: PropTypes.string,
    profilePhoto: PropTypes.string,
    pictureUrl: PropTypes.string
  }).isRequired
};
export default withI18n()(myInformation);
