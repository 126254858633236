import React, { Component } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import classes from "./TwitterCard.module.scss";
import globals from "../../../../configs/club-freelance-globals";
import runtimeVars from "../../../../configs/runTimeVars";

class TwitterCard extends Component {
  render() {
    return (
      <div className={classes.container}>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName={globals.socialMedia.twitterScreenName}
          noScrollbar
          autoHeight
          lang={runtimeVars.APP_LANG || "fr"}
        />
      </div>
    );
  }
}

export default TwitterCard;
