import gql from "graphql-tag";

const HOLIDAYS = gql`
  query Holidays($year: Int!, $month: Int!, $country: String!) {
    holidays(filter: { year: $year, month: $month, country: $country }) {
      month
      year
      country
      holidays {
        day
        name
      }
    }
  }
`;

export default HOLIDAYS;
