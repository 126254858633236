import gql from "graphql-tag";

const SIMILAR_MISSIONS_QUERY = gql`
  query MissionsQuery($id: ID) {
    similarJobOrders(id: $id) {
      id
      address {
        city
      }
      title
      duration {
        months
      }
      employmentType
      customText1
      customText10
      total
    }
  }
`;
export default SIMILAR_MISSIONS_QUERY;
