import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { t, Trans } from "@lingui/macro";
import { Icon } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import classes from "./TitleSubInfo.module.scss";

class TitleSubInfo extends PureComponent {
  render() {
    const { id, location, name, email, i18n, role } = this.props;
    return (
      <div id="container" className={classes.container}>
        <div id="content-1" className={classes.container}>
          <span id="child-content-1" className={classes.item}>
            <span id="child-1" className={classes.hashtag}>
              #{id}
            </span>
          </span>
          <span id="child-content-2" className={classes.item}>
            <Icon type="map-marker" width={10} height={10} color="#838383" />
            <span id="child-2" className={classes.text}>
              {location}
            </span>
          </span>
        </div>
        {role === "CANDIDATE" ? (
          <div className={classes.container}>
            <div className={classes.container}>
              <span
                title={i18n._(t`signataire principal`)}
                className={classes.text}
                style={{ color: "white" }}
              >
                <Trans>S1:</Trans>
              </span>
              <span className={classes.item}>
                <span className={classes.item}>
                  <Icon type="user" width={10} height={10} color="#838383" />
                  <span className={classes.text}>{name[0]}</span>
                </span>
                <span className={classes.item}>
                  <Icon type="mail" width={10} height={10} color="#838383" />
                  <span className={classes.text}>{email[0]}</span>
                </span>
              </span>
            </div>
            <div className={classes.container}>
              {name[1] && email[1] && (
                <span
                  title={i18n._(t`2 ème signataire`)}
                  className={classes.text}
                  style={{ color: "white" }}
                >
                  <Trans>S2:</Trans>
                </span>
              )}
              {name[1] && email[1] && (
                <span className={classes.item}>
                  <span className={classes.item}>
                    <Icon type="user" width={10} height={10} color="#838383" />
                    <span className={classes.text}>{name[1]}</span>
                  </span>
                  <span className={classes.item}>
                    <Icon type="mail" width={10} height={10} color="#838383" />
                    <span className={classes.text}>{email[1]}</span>
                  </span>
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className={classes.container}>
            <div className={classes.container}>
              <span
                title="Consultant"
                className={classes.text}
                style={{ color: "white" }}
              >
                Consultant:
              </span>
              <span className={classes.item}>
                <span className={classes.item}>
                  <Icon type="user" width={10} height={10} color="#838383" />
                  <span className={classes.text}>{name[0]}</span>
                </span>
                <span className={classes.item}>
                  <Icon type="mail" width={10} height={10} color="#838383" />
                  <span className={classes.text}>{email[0]}</span>
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TitleSubInfo.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  location: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string.isRequired
};

TitleSubInfo.defaultProps = {
  location: "",
  name: "",
  email: ""
};

export default withI18n()(TitleSubInfo);
