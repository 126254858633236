import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { AutoComplete, TagList } from "cf-neo-ui";
import { inject, observer } from "mobx-react/index";
import classes from "../SideFilters.module.scss";
import theme from "../../../../../configs/theme";

@inject("missionsSearchStore")
@observer
class BusinessSectorWidget extends Component {
  constructor(props) {
    super(props);
    props.missionsSearchStore.initBusinessSectorOptions(props.options);
  }

  selectHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.addBusinessSector(val);
    missionsSearchStore.setBusinessSectorOptions();
  };

  closeTagHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.removeBusinessSector(val);
    missionsSearchStore.setBusinessSectorOptions();
  };

  render() {
    const { i18n, missionsSearchStore } = this.props;
    return (
      <div className={classes.businessSectorAC}>
        <AutoComplete
          key={missionsSearchStore.businessSectors.slice().length}
          restrictedToList
          clearOnSelect
          options={missionsSearchStore.businessSectorOptions.slice()}
          iconColor={theme.colors.mainColor}
          iconColor2={theme.colors.lightMainColor}
          icon="chevron-down"
          placeholder={i18n._(t`Sélectionner un secteur`)}
          onSelect={this.selectHandler}
        />
        <TagList
          tags={missionsSearchStore.businessSectors}
          variant="secondary"
          closable
          className={classes.tagList}
          onClose={this.closeTagHandler}
        />
      </div>
    );
  }
}

BusinessSectorWidget.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.shape({
    businessSectors: PropTypes.shape,
    businessSectorOptions: PropTypes.shape,
    setBusinessSectorOptions: PropTypes.func,
    addBusinessSector: PropTypes.func,
    removeBusinessSector: PropTypes.func,
    initBusinessSectorOptions: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withI18n()(BusinessSectorWidget);
