import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import {
  Row,
  Col,
  Button,
  FormCard,
  Icon,
  FormGroup,
  TextInput,
  Validation,
  DatePicker,
  Modal,
  ToastCard
} from "cf-neo-ui";
import validate from "../../../../utils/validators";
import "../styles.scss";
import theme from "../../../../configs/theme";
import runtimeVars from "../../../../configs/runTimeVars";
import classes from "../../../../components/alert-modal/alertModal.module.scss";
@inject("sessionStore", "appStore")
@observer
class SingleCertification extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    delete initProps.sessionStore;
    this.state = {
      ...initProps,
      iscertificationValid: true,
      isschoolValid: true,
      isStartDateValid: true,
      isEndDateValid: true,
      isSummaryValid: true,
      isRefNameValid: true,
      isRefPhoneValid: true,
      isRefEmailValid: true,
      isAddressValid: true,
      isdiplomeTypeValid: true,
      certificationValidationMessage: "",
      schoolValidationMessage: "",
      startDateValidationMessage: "",
      endDateValidationMessage: "",
      summaryValidationMessage: "",
      refNameValidationMessage: "",
      refPhoneValidationMessage: "",
      refEmailValidationMessage: "",
      isVisible: false,
      isClose: true,
      diplomeTypeValidationMessage: "",
      todayDate: moment(new Date()).valueOf()
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newProps = { ...nextProps };
    delete newProps.sessionStore;
    this.setState({ ...newProps });
  }

  onDeleteHandler = () => {
    this.setState({ isEditing: false });
    const { sessionStore, onValidate } = this.props;
    const {
      candidateCertification,
      changecandidateCertification,
      changeGeneralSaveCase
    } = sessionStore;
    onValidate();
    const { id } = this.state;
    const arr = candidateCertification.slice();

    for (let key = 0; key < arr.length; key += 1) {
      const experience = arr[key];
      if (experience.id === id) {
        arr.splice(key, 1);
        changecandidateCertification(arr);
      }
    }
    changeGeneralSaveCase(5);
  };

  validateAddress(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    let mesg = "";
    if (!res.isValid) mesg = i18n._(t`Non renseigné`);
    this.setState({
      isAddressValid: res.isValid,
      addressValidationMessage: mesg
    });
    return res.isValid;
  }

  onCancelHandler = () => {
    const { isNew } = this.state;
    if (isNew) {
      this.onDeleteHandler();
    } else {
      const { sessionStore } = this.props;
      const {
        candidateCertification,
        changecandidateCertification
      } = sessionStore;
      const arr = candidateCertification.slice();
      changecandidateCertification(arr);
    }
  };

  onEditHandler = () => {
    this.setState({ isEditing: true });
  };
  getLocationHandler = (address, formattedAddress) => {
    let { city } = this.state;
    city = {
      address1: formattedAddress.address || address,
      city: formattedAddress.city,
      countryID: formattedAddress.country,
      zip: formattedAddress.zip,
      state: formattedAddress.state,
      lat: formattedAddress.lat,
      lng: formattedAddress.lng
    };
    this.setState({ city });
    this.forceUpdate();
    this.validateAddress(address);
  };
  companyAdressChangeHandler = v => {
    const { city } = this.state;
    this.setState({ city });
    this.forceUpdate();
  };
  onSaveHandler = () => {
    if (!this.isFormValid()) {
      return;
    }
    const { sessionStore, onValidate } = this.props;

    const {
      candidateCertification,
      changecandidateCertification,
      changeGeneralSaveCase
    } = sessionStore;

    const newcandidateCertification = candidateCertification.slice();
    const { id } = this.state;

    for (let key = 0; key < newcandidateCertification.length; key += 1) {
      const Certification = newcandidateCertification[key];
      if (Certification.id === id) {
        const {
          city,
          certification,
          school,
          startDate,
          endDate,
          diplomeType
        } = this.state;
        const { _id } = Certification;
        newcandidateCertification[key] = {
          _id,
          id,
          city,
          certification,
          school,
          startDate,
          endDate,
          diplomeType,
          isNew: false,
          isEditing: false
        };
      }
      changecandidateCertification(newcandidateCertification);
      changeGeneralSaveCase(5);
      onValidate();
    }
  };

  certificationChangeHandler = e => {
    this.setState({ certification: e.target.value });
    this.validatecertification(e.target.value);
  };

  diplomeTypeChangeHandler = e => {
    this.setState({ diplomeType: e.target.value });
    this.validatediplomeType(e.target.value);
  };

  schoolChangeHandler = e => {
    this.setState({ school: e.target.value });
    this.validateschool(e.target.value);
  };

  startDateChangeHandler = value => {
    this.setState({ startDate: value });
    this.validateStartDate(value);
  };

  endDateChangeHandler = value => {
    this.setState({ endDate: value });
    this.validateEndDate(value);
  };

  summaryChangeHandler = e => {
    this.setState({ summary: e.target.value });
    this.validateSummary(e.target.value);
  };

  refNameChangeHandler = e => {
    this.setState({ refName: e.target.value });
    this.validateRefName(e.target.value);
  };

  refPhoneChangeHandler = e => {
    this.setState({ refPhone: e.target.value });
    this.validateRefPhone(e.target.value);
  };

  validatediplomeType = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isdiplomeTypeValid: res.isValid,
      diplomeTypeValidationMessage: res.message
    });
    return res.isValid;
  };
  isFormValid = () => {
    const { certification, school, startDate, endDate } = this.state;

    let valid = true;
    if (!this.validatecertification(certification)) valid = false;
    if (!this.validateStartDate(startDate)) valid = false;
    // if (!this.validateEndDate(endDate)) valid = false;

    return valid;
  };
  validatecertification = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", { maxlength: 70 }, { minlength: 3 }],
      i18n
    );
    this.setState({
      iscertificationValid: res.isValid,
      certificationValidationMessage: res.message
    });
    return res.isValid;
  };

  validateschool = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isschoolValid: res.isValid,
      schoolValidationMessage: res.message
    });
    return res.isValid;
  };

  validateSummary = value => {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isSummaryValid: res.isValid,
      summaryValidationMessage: res.message
    });
    return res.isValid;
  };

  validateRefName = value => {
    const { i18n } = this.props;
    const res = validate(value, ["name"], i18n);
    this.setState({
      isRefNameValid: res.isValid,
      refNameValidationMessage: res.message
    });
    return res.isValid;
  };

  validateRefEmail = value => {
    const { i18n } = this.props;
    const res = validate(value, ["email"], i18n);
    this.setState({
      isRefEmailValid: res.isValid,
      refEmailValidationMessage: res.message
    });
    return res.isValid;
  };

  validateRefPhone = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      [{ maxlength: 13 }, { minlength: 8 }, "phoneNumber"],
      i18n
    );
    this.setState({
      isRefPhoneValid: res.isValid,
      refPhoneValidationMessage: res.message
    });
    return res.isValid;
  };

  validateStartDate = value => {
    const { i18n } = this.props;
    const { endDate, todayDate } = this.state;

    const res = validate(value, ["required"], i18n);
    if (res.isValid === false || Number.isNaN(value) === true) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }
    if (endDate === "" || Number.isNaN(endDate)) {
      if (value <= todayDate) {
        this.setState({
          isStartDateValid: true,
          startDateValidationMessage: ""
        });
        return true;
      }

      // this.setState({
      //   isStartDateValid: false,
      //   startDateValidationMessage: i18n._(
      //     t`Cette date ne peut pas être dans le future`
      //   )
      // });
      return false;
    }

    if (value <= endDate && endDate <= todayDate) {
      this.setState({
        isStartDateValid: true,
        startDateValidationMessage: ""
      });
      return true;
    }
    if (value && endDate && value >= todayDate && value >= endDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`La date de début doit être toujours antérieur à la date de fin`
        )
      });
      return false;
    }

    if (value >= todayDate) {
      this.setState({
        isStartDateValid: false,
        startDateValidationMessage: i18n._(
          t`Cette date ne peut pas être dans le future`
        )
      });
      return false;
    }

    this.setState({
      isStartDateValid: true,
      startDateValidationMessage: ""
    });
    return true;
  };

  validateEndDate = value => {
    const { i18n } = this.props;
    const { startDate, todayDate } = this.state;

    const res = validate(value, ["required"], i18n);
    if (res.isValid === false || Number.isNaN(value) === true) {
      // this.setState({
      //   isEndDateValid: false,
      //   endDateValidationMessage: i18n._(t`Champ requis`)
      // });
      return false;
    }
    if (startDate === "" || Number.isNaN(startDate)) {
      if (value <= todayDate) {
        this.setState({
          isEndDateValid: true,
          endDateValidationMessage: ""
        });
        return true;
      }

      // this.setState({
      //   isEndDateValid: false,
      //   endDateValidationMessage: i18n._(
      //     t`Cette date ne peut pas être dans le future`
      //   )
      // });
      return false;
    }

    // if (value >= todayDate) {
    //   this.setState({
    //     isEndDateValid: false,
    //     endDateValidationMessage: i18n._(
    //       t`Cette date ne peut pas être dans le future`
    //     )
    //   });
    //   return false;
    // }

    if (startDate <= value && value <= todayDate) {
      this.setState({
        isEndDateValid: true,
        endDateValidationMessage: ""
      });
      return true;
    }

    if (startDate >= value && value <= todayDate) {
      this.setState({
        isEndDateValid: false,
        endDateValidationMessage: i18n._(
          t`La date de fin doit être toujours ultérieur à la date de début`
        )
      });
      return false;
    }

    this.setState({
      isEndDateValid: true,
      endDateValidationMessage: ""
    });
    return true;
  };
  getModalData = i18n => {
    const data = [];

    return data;
  };
  disableStartDays = day => {
    const { endDate, todayDate } = this.state;

    if (endDate) {
      return day > endDate;
    }
    return day > todayDate;
  };

  disableEndDays = day => {
    const { startDate, todayDate } = this.state;
    return day < startDate || day > todayDate;
  };
  onToastHandler = () => {
    this.setState({ isVisible: true });
  };
  onToastHandlerClose = () => {
    this.setState({ isVisible: false });
  };
  displayButtons = () => {
    const { showButton, i18n } = this.props;
    const { isVisible } = this.state;
    if (showButton === "true") {
      return (
        <div className="experience-buttons">
          <Button size="small" onClick={this.onToastHandler}>
            <Trans>Supprimer</Trans>
          </Button>

          <Modal
            centred
            isVisible={isVisible}
            onClose={this.onToastHandlerClose}
          >
            <div className={classes.alertModal}>
              <div className={classes.header}>
                <div className={classes.headerIcon}>
                  <Icon
                    size="tiny"
                    type="bell"
                    color={theme.colors.mainColor}
                    color2={theme.colors.lightMainColor}
                  />
                </div>
                <Trans>Alerte Suppression</Trans>
              </div>
              <div className={classes.body}>
                <Row style={{ marginLeft: "30px" }}>
                  {i18n._(t`Êtes-vous sûr de vouloir supprimer ce certificat?`)}
                </Row>
              </div>
              <div className={classes.footer}>
                <Button
                  size="small"
                  variant="secondary"
                  onClick={this.onToastHandlerClose}
                >
                  <Trans>Annuler</Trans>
                </Button>
                <Button
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={this.onDeleteHandler}
                >
                  <Trans>Supprimer</Trans>
                </Button>
              </div>
            </div>
          </Modal>

          <Button variant="secondary" size="small" onClick={this.onEditHandler}>
            <Trans>Modifier</Trans>
          </Button>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      city,
      certification,
      school,
      startDate,
      endDate,
      iscertificationValid,
      isschoolValid,
      isStartDateValid,
      isEndDateValid,
      isdiplomeTypeValid,
      certificationValidationMessage,
      schoolValidationMessage,
      startDateValidationMessage,
      endDateValidationMessage,
      diplomeTypeValidationMessage,
      isAddressValid,
      isEditing,
      diplomeType
    } = this.state;
    const { i18n, appStore, showButton } = this.props;
    const isCertificationMobile = appStore.width <= 850;
    let certificationDegreeStyle = isCertificationMobile
      ? "pre-wrap"
      : "nowrap";
    const orientation = appStore.width <= 348 ? "vertical" : "horizontal";
    const { currentLanguage } = appStore;
    return (
      <FormCard>
        <Row className="rowExperience">
          <Col>
            {isEditing ? (
              <div>
                <div>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Nom de la certification</Trans>
                        </label>
                        <Validation
                          errorMessage={certificationValidationMessage}
                          valid={iscertificationValid}
                        >
                          <TextInput
                            type="text"
                            className="form-input"
                            icon="briefcase"
                            iconColor={theme.colors.lightMainColor}
                            placeholder=""
                            value={certification}
                            onChange={this.certificationChangeHandler}
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Organisme de délivrance</Trans>
                        </label>

                        <TextInput
                          type="text"
                          spellCheck="false"
                          className="form-input"
                          placeholder=""
                          value={school}
                          onChange={this.schoolChangeHandler}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Date de début</Trans>
                        </label>
                        <Validation
                          errorMessage={startDateValidationMessage}
                          valid={isStartDateValid}
                        >
                          <DatePicker
                            disabledDaysMatcher={day =>
                              this.disableStartDays(day)
                            }
                            dateFormat="DD/MM/YYYY"
                            defaultDate={
                              startDate
                                ? moment
                                    .unix(startDate / 1000)
                                    .format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={value =>
                              this.startDateChangeHandler(
                                moment(value).valueOf()
                              )
                            }
                            locale={currentLanguage || runtimeVars.APP_LANG}
                            acceptWhenOutsideClick
                            autoComplete="off"
                          />
                        </Validation>
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup>
                        <label className="form-label">
                          <Trans>Date de fin</Trans>
                        </label>
                        <Validation
                          errorMessage={endDateValidationMessage}
                          valid={isEndDateValid}
                        >
                          <DatePicker
                            // disabledDaysMatcher={day =>
                            //   this.disableEndDays(day)
                            // }
                            dateFormat="DD/MM/YYYY"
                            defaultDate={
                              endDate
                                ? moment
                                    .unix(endDate / 1000)
                                    .format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={value =>
                              this.endDateChangeHandler(moment(value).valueOf())
                            }
                            locale={currentLanguage || runtimeVars.APP_LANG}
                            acceptWhenOutsideClick
                            autoComplete="off"
                          />
                        </Validation>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div
                  className="experience-buttons"
                  style={{ marginTop: "10px" }}
                >
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={this.onCancelHandler}
                  >
                    <Trans>Annuler</Trans>
                  </Button>
                  <Button size="small" onClick={this.onSaveHandler}>
                    <Trans>Valider</Trans>
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <Row style={{ marginRight: "-15px", marginLeft: "-15px" }}>
                  <Col lg={4} xl={4} className="info">
                    <div
                      style={{
                        color: "#d3354a",
                        marginRight: "2%",
                        fontWeight: "bold",
                        fontSize: "15px",
                        whiteSpace: certificationDegreeStyle
                      }}
                    >
                      <Icon
                        className="icon-margin-right"
                        type="flag"
                        size="tiny"
                        width={20}
                        heigth={20}
                        color={theme.colors.mainColor}
                        color2={theme.colors.lightMainColor}
                      />
                      {certification}
                    </div>
                    <br />
                    <div>
                      <Icon
                        className="icon-margin-right"
                        type="calendar"
                        size="tiny"
                        width={20}
                        heigth={20}
                        color={theme.colors.mainColor}
                        color2={theme.colors.lightMainColor}
                      />
                      {moment.unix(startDate / 1000).format("DD/MM/YYYY")} -{" "}
                      {endDate
                        ? moment.unix(endDate / 1000).format("DD/MM/YYYY")
                        : i18n._(t`Aujourd’hui`)}
                    </div>
                    <br />
                    <div style={{ whiteSpace: certificationDegreeStyle }}>
                      <Icon
                        className="icon-margin-right"
                        type="map-marker"
                        size="tiny"
                        width={20}
                        heigth={20}
                        color={theme.colors.mainColor}
                        color2={theme.colors.lightMainColor}
                      />
                      {school}
                    </div>
                    <br />
                    {diplomeType && (
                      <div>
                        <Icon
                          className="icon-margin-right"
                          type="briefcase"
                          size="tiny"
                          width={20}
                          heigth={20}
                          color={theme.colors.mainColor}
                          color2={theme.colors.lightMainColor}
                        />
                        {diplomeType}
                      </div>
                    )}
                  </Col>
                </Row>
                <br />
                {this.displayButtons()}
              </div>
            )}
          </Col>
        </Row>
      </FormCard>
    );
  }
}

SingleCertification.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    candidateWorkHistory: PropTypes.shape({}),
    changeCandidateWorkHistory: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string,
    width: PropTypes.number
  }).isRequired,
  showButton: PropTypes.string,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  onValidate: PropTypes.func.isRequired
};

export default withI18n()(SingleCertification);
