import React, { Component } from "react";
import * as PropTypes from "prop-types";
import * as queryString from "query-string";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, Sorter, Modal, ItemMenu, Button } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Query } from "react-apollo";
import classes from "./styles.module.scss";
import RecommendedTalentsWithPagination from "./RecommendedTalentsWithPagination";
import AccessibleRecommendedTalentsWithPagination from "./AccessibleRecommendedTalentsWithPagination";
import PopUpClosedOffer from "../dashboard-offers/single-offer/pop-up/PopUpClosedOffer";
import SingleCandidate from "../dashboard-single-candidate";
import Routes from "../layout/routes";
// import theme from "../../configs/theme";

@inject("modalStore")
@observer
class DashboardTalents extends Component {
  constructor(props) {
    super(props);
    const queryObj = queryString.parse(props.location.search);
    this.state = {
      activeTab: queryObj.active,
      employmentType: ""
    };
  }

  tabChangeHandler = tabName => {
    const { history } = this.props;
    history.replace({ search: `?active=${tabName}` });
  };

  render() {
    const { i18n, modalStore, history } = this.props;
    const { isVisible, isVisible2 } = modalStore;
    const { activeTab, employmentType } = this.state;
    return (
      <div className={classes.content}>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          position="fixed"
        >
          <PopUpClosedOffer />
        </Modal>
        <Modal isVisible={isVisible2} withCloseButton={false} position="fixed">
          <div
            style={{
              width: "80%",
              margin: "auto"
            }}
          >
            <SingleCandidate />
          </div>
        </Modal>
        <div className={classes.pageHeader}>
          <div className={classes.sorterWithoutMenu}>
            <Sorter
              text={i18n._(t`Talents`)}
              menu={false}
              onChange={() => {}}
              chevronSize={0.00001}
              chevronColor="#000000"
              classNameText="font-style: normal;
                            font-weight: bold;
                            font-size: 36px;
                            color: #000000;
                            line-height: 1;"
              underline
            />
          </div>
          <div className={classes.buttonCreateOffer}>
            <Button
              variant="tertiary"
              className={classes.buttonStyle}
              size="small"
              onClick={() =>
                history.push(Routes.HbfLayoutRoutes.SearchCandidatesPage.path)
              }
            >
              <Trans>Rechercher un consultant</Trans>
            </Button>
            <img
              style={{ verticalAlign: "middle" }}
              src="/beta_red.png"
              alt="Beta"
              width="60px"
              height="27px"
            />
          </div>
        </div>
        <div className={classes.pageBody}>
          <div className={classes.tab}>
            <Tabs
              className={classes.dashboardTalents}
              defaultActiveTab="allTalents"
              activeTab={activeTab}
              type="transparentV2"
              onChange={this.tabChangeHandler}
            >
              <Tab name="Talents" tab={i18n._(t`Mes talents`)}>
                <RecommendedTalentsWithPagination
                  employmentType={employmentType}
                />
              </Tab>
              <Tab name="allTalents" tab={i18n._(t`Tous les talents`)}>
                <AccessibleRecommendedTalentsWithPagination
                  employmentType={employmentType}
                />
              </Tab>
            </Tabs>
          </div>
          <div className={classes.filter}>
            <Sorter
              text={i18n._(t`Freelance / Salarié`)}
              menu
              positionMenu="right"
              onChange={() => {}}
            >
              <ItemMenu
                className={classes.item}
                label={i18n._(t`tous`)}
                value={1}
                onClick={() => this.setState({ employmentType: "" })}
              />
              <ItemMenu
                className={classes.item}
                label="Freelance"
                value={2}
                onClick={() => this.setState({ employmentType: "Contract" })}
              />
              <ItemMenu
                className={classes.item}
                label={i18n._(t`Salarié`)}
                value={3}
                onClick={() => this.setState({ employmentType: "Permanent" })}
              />
            </Sorter>
          </div>
        </div>
      </div>
    );
  }
}

DashboardTalents.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    isVisible2: PropTypes.bool,
    changeIsVisible: PropTypes.func,
    changeIsVisible2: PropTypes.func,
    changeModalType: PropTypes.func,
    modalType: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(DashboardTalents));
