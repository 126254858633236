import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import {
  Button,
  FormCard,
  Icon,
  Row,
  Col,
  toaster,
  Spinner,
  Textarea,
  Validation,
  Checkbox
} from "cf-neo-ui";
import "./styles.scss";
import { Mutation } from "react-apollo";
import moment from "moment";
import classes from "../conclusion-section/ConclusionSection.module.scss";
import Email from "./icons/Email";
import Ok from "./icons/Ok";
import SEND_MESSAGE_TIME_SHEET from "../messenger/mutation";
import {
  FILL_TIME_SHEET_MUTATION,
  UPDATE_TIMESHEET_STATUS_MUTATION
} from "../mutations";
import Routes from "../../layout/routes";
import runtimeVars from "../../../configs/runTimeVars";
import emtyCSS from "./emty.module.scss";

@inject("modalStore", "timesheetStore", "sessionStore")
@observer
class PopUpSubmitTimeSheet extends Component {
  constructor(props) {
    super(props);
    moment.locale(runtimeVars.APP_LANG);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      isCommentValid: true,
      commentValidationMessage: "",
      check: false,
      buttonClicked: false
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  isEmpty = (selectedDays, SPHours) =>
    Boolean(
      (!selectedDays || !selectedDays.length) && (!SPHours || !SPHours.length)
    );

  getDateString = (month, year) => {
    const monthInTheRightLanguage = this.getMonthName(month);
    return `${monthInTheRightLanguage} ${year}`;
  };

  getMonthName = m => {
    const { i18n } = this.props;
    const month = [
      "",
      i18n._(t`janvier`),
      i18n._(t`février`),
      i18n._(t`mars`),
      i18n._(t`avril`),
      i18n._(t`mai`),
      i18n._(t`juin`),
      i18n._(t`juillet`),
      i18n._(t`août`),
      i18n._(t`septembre`),
      i18n._(t`octobre`),
      i18n._(t`novembre`),
      i18n._(t`décembre`)
    ];
    return month[m];
  };

  changeHandler = v => {
    const { timesheetStore, i18n } = this.props;
    const { changeComment } = timesheetStore;
    changeComment(v);
    if (v)
      this.setState({
        isCommentValid: true,
        commentValidationMessage: ""
      });
    else
      this.setState({
        isCommentValid: false,
        commentValidationMessage: i18n._(t`vous devez ajouter un commentaire`)
      });
  };

  clickHandler = () => {
    const { modalStore, history, timesheetStore, i18n } = this.props;
    const { changeTimesheetPopUp } = modalStore;
    timesheetStore.resetComment();
    changeTimesheetPopUp(0);
    history.push(Routes.LpbLayoutRoutes.Timesheets.path);
    toaster.success({
      title: i18n._(t`Timesheet envoyé au client`),
      description: i18n._(
        t`la feuille de temps a été soumise au client pour validation`
      )
    });
  };

  clickHandler2 = () => {
    const { modalStore, timesheetStore } = this.props;
    const { changeTimesheetPopUp } = modalStore;
    const { resetComment } = timesheetStore;
    this.setState({ buttonClicked: false });
    resetComment();
    changeTimesheetPopUp(0);
  };

  sendMessage = () => {
    const { timesheetStore } = this.props;
    const { comment, addMessage } = timesheetStore;
    addMessage({
      text: comment,
      date: moment().format("DD/MM/YY HH:mm"),
      speaker: null
    });
  };

  isCommentValid = () => {
    const { timesheetStore, i18n } = this.props;
    const { comment } = timesheetStore;
    if (comment) {
      this.setState({
        isCommentValid: true,
        commentValidationMessage: ""
      });
      return true;
    }
    this.setState({
      isCommentValid: false,
      commentValidationMessage: i18n._(t`vous devez ajouter un commentaire`)
    });
    return false;
  };

  getInfo = (card, infoSearched) => {
    if (infoSearched === "email") {
      if (card.approvingClient) return `${card.approvingClient.email}`;
      if (card.approvingClient2) return `${card.approvingClient2.email}`;
      return `${card.client.email}`;
    }
    if (card.approvingClient) return `${card.approvingClient.name}`;
    if (card.approvingClient2) return `${card.approvingClient2.name}`;
    return `${card.client.name}`;
  };

  formatTotal = () => {
    const { timesheetStore } = this.props;
    return {
      days: Math.floor(timesheetStore.total / 8),
      hours: Math.floor(timesheetStore.total % 8),
      minutes: Math.floor(((timesheetStore.total % 8) % 1) * 60)
    };
  };

  render() {
    const { timesheetStore, i18n, match } = this.props;
    const {
      commentValidationMessage,
      isCommentValid,
      check,
      buttonClicked
    } = this.state;
    const {
      selectedDays,
      specialHours,
      totalSpecial,
      timesheet,
      comment
    } = timesheetStore;
    const selectDaysFormated = [];
    selectedDays.map(async day => {
      const theDay = {};
      theDay.day = day.day;
      theDay.hours = await Math.trunc(day.hours);
      theDay.minutes = day.minutes
        ? (await Math.round((day.hours - Math.trunc(day.hours)) * 60)) +
          day.minutes
        : await Math.round((day.hours - Math.trunc(day.hours)) * 60);
      selectDaysFormated.push(theDay);
    });
    const total = this.formatTotal();
    const isEmpty = this.isEmpty(selectedDays, specialHours);
    if (isEmpty)
      return (
        <div className={emtyCSS.container}>
          <div className={emtyCSS.title}>
            <Icon
              className="icon"
              type="triangle-exclamation"
              color="#8D0417"
              color2="#D3354A"
              width={25}
              height={25}
            />
            <h3>
              <Trans>Timesheet vide</Trans>
            </h3>
          </div>
          <div className={emtyCSS.body}>
            <p>
              <Trans>
                Vous êtes sur le point de soumettre un Timesheet vide.
              </Trans>
              <br />
              <Trans>
                Merci de confirmer que vous n'avez pas travaillé ce mois
              </Trans>
            </p>
            <div>
              <Checkbox
                checked={check}
                value="accept"
                label={i18n._(
                  t`Je confirme n'avoir effectué aucun jour pour ce mois`
                )}
                onClickButton={() => this.setState({ check: !check })}
              />
            </div>
          </div>
          <div>
            <Mutation
              mutation={UPDATE_TIMESHEET_STATUS_MUTATION}
              variables={{
                id: Number.parseInt(match.params.id, 10),
                status: "pending"
              }}
              onCompleted={this.clickHandler}
              oonError={() =>
                toaster.error({
                  title: i18n._(t`Erreur`),
                  description: i18n._(t`Un problème est survenu`)
                })
              }
            >
              {(update, { loading }) => (
                <div className={emtyCSS.buttons}>
                  {loading ? (
                    <Button disabled>
                      <Spinner
                        type="pointed-circular"
                        color="#FFFFFF"
                        size={12}
                      />
                    </Button>
                  ) : (
                    <Button
                      disabled={!check}
                      icon="chevron-right"
                      iconPosition="right"
                      size="small"
                      onClick={update}
                    >
                      <Trans>Continuer</Trans>
                    </Button>
                  )}
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={() => this.clickHandler2()}
                  >
                    <Trans>Annuler</Trans>
                  </Button>
                </div>
              )}
            </Mutation>
          </div>
        </div>
      );
    return (
      <div className="pop-up-container">
        <FormCard className="formCard no-gutter">
          {!buttonClicked ? (
            <Row>
              <Col className="form-card-content center">
                <span className="center">
                  <span className="icon">
                    <Icon
                      className="icon"
                      type="triangle-exclamation"
                      color="#8D0417"
                      color2="#D3354A"
                      width={25}
                      height={25}
                    />
                  </span>
                  <span className="title">
                    <Trans>Merci d'indiquer votre activité</Trans>
                  </span>
                </span>
                <p>
                  <Trans>
                    Avant de soumettre votre Timesheet
                    {this.getDateString(timesheet.month, timesheet.year)}
                    au client, veuillez s'il vous plait indiquer en quelques
                    lignes les actions effectuées ce mois.
                  </Trans>
                </p>
                <p>
                  <Trans>
                    Note: ce bilan d'activité sera visible par le client sur
                    votre compte-rendu de Timesheet
                  </Trans>
                </p>
                <div>
                  <Row>
                    <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                      <a className={classes.labelStyle}>
                        <Trans>Commentaire</Trans>
                      </a>
                      <div style={{ marginTop: "10px", width: "100%" }}>
                        <Validation
                          errorMessage={commentValidationMessage}
                          valid={isCommentValid}
                        >
                          <Textarea
                            size="xLarge"
                            defaultValue={comment}
                            onChange={e => this.changeHandler(e.target.value)}
                            placeholder={i18n._(t`ajoutez un commentaire...`)}
                            resize={false}
                          />
                        </Validation>
                      </div>
                    </Col>
                  </Row>
                </div>

                <br />
                <div className="center">
                  <span className="button-left">
                    <span className="submitButton">
                      <Button
                        icon="chevron-right"
                        iconPosition="right"
                        size="small"
                        onClick={() => {
                          if (this.isCommentValid()) {
                            this.setState({ buttonClicked: true });
                          }
                        }}
                      >
                        <Trans>Continuer</Trans>
                      </Button>
                    </span>
                  </span>

                  <span className="button-right">
                    <Button
                      variant="tertiary"
                      size="small"
                      onClick={() => this.clickHandler2()}
                    >
                      <Trans>Annuler</Trans>
                    </Button>
                  </span>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="form-card-content center">
                <span className="center">
                  <span className="icon">
                    <Icon
                      className="icon"
                      type="triangle-exclamation"
                      color="#8D0417"
                      color2="#D3354A"
                      width={25}
                      height={25}
                    />
                  </span>
                  <span className="title">
                    <Trans>Merci de vérifier vos informations</Trans>
                  </span>
                </span>
                <p>
                  <Trans>
                    Vous êtes sur le point de soumettre votre Timesheet
                    {this.getDateString(timesheet.month, timesheet.year)}
                    au client. Une fois le Timesheet validé, vous ne pourrez
                    plus le modifier. Merci de vérifier vos informations avant
                    l’envoi.
                  </Trans>
                </p>
                <div>
                  <Row>
                    <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                      <a className={classes.labelStyle}>
                        {timesheet &&
                        (timesheet.approvingClient ||
                          timesheet.approvingClient2) ? (
                          <Trans>Informations sur le signataire</Trans>
                        ) : (
                          <Trans>Informations sur le client</Trans>
                        )}
                      </a>
                      {timesheet && (
                        <div style={{ marginTop: "10px" }}>
                          <div>
                            <span
                              style={{
                                verticalAlign: "sub",
                                marginRight: "20px"
                              }}
                            >
                              <Ok />
                            </span>
                            <span>{this.getInfo(timesheet, "name")}</span>
                          </div>
                          <div>
                            <span
                              style={{
                                verticalAlign: "sub",
                                marginRight: "20px"
                              }}
                            >
                              <Email />
                            </span>
                            <span>{this.getInfo(timesheet, "email")}</span>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                      <a className={classes.labelStyle}>
                        <Trans>Timesheet</Trans>
                      </a>
                      <div className={classes.conclusionBox1}>
                        <div className={classes.content}>
                          <div className={classes.item}>
                            <span>
                              <span>{total.days}</span>
                              <span>
                                <Trans>j</Trans>
                              </span>
                              <span>{total.hours}</span>
                              <span>h</span>
                              {total.minutes ? (
                                <span>{total.minutes}</span>
                              ) : null}
                              {total.minutes ? <span>mn</span> : null}
                            </span>
                            <p>
                              <Trans>normal</Trans>
                            </p>
                          </div>
                          <div className={classes.item}>
                            <span>
                              <span>{totalSpecial.hours}</span>
                              <span>h</span>
                              <span>{totalSpecial.minutes}</span>
                              <span>mn</span>
                            </span>
                            <p>
                              <Trans>special</Trans>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                      <a className={classes.labelStyle}>
                        <Trans>Commentaire</Trans>
                      </a>
                      <div style={{ marginTop: "10px", width: "100%" }}>
                        "{comment}".
                      </div>
                    </Col>
                  </Row>
                </div>

                <br />
                <div className="center">
                  <Mutation
                    mutation={SEND_MESSAGE_TIME_SHEET}
                    variables={{
                      timesheetID: Number.parseInt(match.params.id, 10),
                      date: new Date().getTime(),
                      message: comment
                    }}
                    onCompleted={() => {}}
                    onError={() =>
                      toaster.error({
                        title: i18n._(t`Erreur`),
                        description: i18n._(t`Un problème est survenu`)
                      })
                    }
                  >
                    {(commentMutation, { loading: commentMutationLoading }) => (
                      <Mutation
                        mutation={FILL_TIME_SHEET_MUTATION}
                        variables={{
                          id: Number.parseInt(match.params.id, 10),
                          selectedDays: selectDaysFormated,
                          specialHours: specialHours.slice()
                        }}
                        onCompleted={() => {}}
                        onError={() =>
                          toaster.error({
                            title: i18n._(t`Erreur`),
                            description: i18n._(t`Un problème est survenu`)
                          })
                        }
                      >
                        {(fillMutation, { loading: fillMutationLoading }) => (
                          <Mutation
                            mutation={UPDATE_TIMESHEET_STATUS_MUTATION}
                            variables={{
                              id: Number.parseInt(match.params.id, 10),
                              status: "pending"
                            }}
                            onCompleted={this.clickHandler}
                            refetchQueries={["myTsNotifs"]}
                            oonError={() =>
                              toaster.error({
                                title: i18n._(t`Erreur`),
                                description: i18n._(t`Un problème est survenu`)
                              })
                            }
                          >
                            {(
                              statusMutation,
                              { loading: statusMutationLoading }
                            ) => (
                              <span className="button-left">
                                <span className="submitButton">
                                  {fillMutationLoading ||
                                  commentMutationLoading ||
                                  statusMutationLoading ? (
                                    <Button
                                      disabled
                                      icon="chevron-right"
                                      iconPosition="right"
                                      size="small"
                                    >
                                      <div style={{ display: "inline-block" }}>
                                        <Spinner
                                          style={{
                                            display: "inline-block",
                                            marginRight: "10px"
                                          }}
                                          type="pointed-circular"
                                          color="#FFFFFF"
                                          size={8}
                                        />
                                        <Trans>Soumettre au client</Trans>
                                      </div>
                                    </Button>
                                  ) : (
                                    <Button
                                      icon="chevron-right"
                                      iconPosition="right"
                                      size="small"
                                      onClick={async () => {
                                        if (this.isCommentValid()) {
                                          this.sendMessage();
                                          await fillMutation();
                                          if (comment) {
                                            await commentMutation();
                                          }
                                          await statusMutation();
                                        }
                                      }}
                                    >
                                      <Trans>Soumettre au client</Trans>
                                    </Button>
                                  )}
                                </span>
                              </span>
                            )}
                          </Mutation>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                  <span className="button-right">
                    <Button
                      variant="tertiary"
                      size="small"
                      onClick={() => this.clickHandler2()}
                    >
                      <Trans>Annuler</Trans>
                    </Button>
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </FormCard>
      </div>
    );
  }
}

PopUpSubmitTimeSheet.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  timesheetStore: PropTypes.shape({
    total: PropTypes.number,
    totalSpecial: PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number
    }),
    resetComment: PropTypes.func,
    addMessage: PropTypes.func,
    changeComment: PropTypes.func,
    comment: PropTypes.string,
    updateSelectedDays: PropTypes.func,
    timesheet: PropTypes.shape({
      approvingClient: PropTypes.shape,
      approvingClient2: PropTypes.shape,
      status: PropTypes.string,
      month: PropTypes.string,
      year: PropTypes.string,
      info: PropTypes.shape({
        email: PropTypes.string
      })
    }),
    specialHours: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        date: PropTypes.number,
        hours: PropTypes.number,
        minutes: PropTypes.number,
        type: PropTypes.string
      })
    ),
    selectedDays: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.number,
        hours: PropTypes.number
      })
    )
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    changeTimesheetPopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(PopUpSubmitTimeSheet));
