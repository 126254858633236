import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./statusBar.module.scss";

class StatusBar extends Component {
  getCellClass = appStatus => {
    const { status } = this.props;

    let cellClass = classes.statusCell;
    if (status === appStatus) cellClass += ` ${classes.active}`;

    return cellClass;
  };

  render() {
    return (
      <div className={classes.statusBar}>
        <Row noGape>
          <Col noGutter className={this.getCellClass("Submitted")}>
            <Trans>Candidature</Trans>
          </Col>
          <Col noGutter className={this.getCellClass("CV Sent")}>
            <Trans>CV envoyé</Trans>
          </Col>
          <Col noGutter className={this.getCellClass("Interview")}>
            <Trans>Entretien</Trans>
          </Col>
          <Col noGutter className={this.getCellClass("Placement")}>
            <Trans>Démarrage</Trans>
          </Col>
          <Col noGutter className={this.getCellClass("Post Placement")}>
            <Trans>Retour d’expérience</Trans>
          </Col>
        </Row>
      </div>
    );
  }
}

StatusBar.propTypes = {
  status: PropTypes.string
};

StatusBar.defaultProps = {
  status: ""
};

export default StatusBar;
