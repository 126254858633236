import { toaster } from "cf-neo-ui";
import uniqid from "uniqid";
import runtimeVars from "../configs/runTimeVars";

/**
 * Returns a unique uid used as key prop for children nodes in a list
 */
export const generateKey = () => uniqid();

/**
 * Removes elements from an array for which the given function returns false
 * @param arr Array
 * @param func function
 * @returns {Array}
 */
export const remove = (arr, func) =>
  Array.isArray(arr)
    ? arr.filter(func).reduce((acc, val) => {
        arr.splice(arr.indexOf(val), 1);
        return acc.concat(val);
      }, [])
    : [];

export const scorePassword = pass => {
  if (!pass) return 0;
  const variations = [
    pass.length >= 8 ? 20 : 0, // 8 characters as minimum length
    /[a-z]/.test(pass) ? 20 : 0, // at least one minuscule character
    /[A-Z]/.test(pass) ? 20 : 0, // at least one uppercase character
    /\d/.test(pass) ? 20 : 0, // at least one number
    /\W/.test(pass) ? 20 : 0 // at least one special character
  ];
  return variations.reduce((a, c) => a + c, 0);
};

/**
 * Returns the symmetric difference between two arrays, without filtering out duplicate values.
 * @param a array
 * @param b array
 * @returns []
 */
export const symmetricDifference = (a, b) => {
  const sA = new Set(a);
  const sB = new Set(b);
  return [...a.filter(x => !sB.has(x)), ...b.filter(x => !sA.has(x))];
};

/**
 * Returns true if total can fit in page size, false otherwise
 * @param pageSize Number of elements per page
 * @param total Total number of items
 * @returns {boolean}
 */
export const isOnePage = (pageSize, total) => {
  const pagesNbr = Math.floor((total - 1) / pageSize) + 1;
  return pagesNbr <= 1;
};

export const notifyVersion = () =>
  runtimeVars.APP_HOST_ENV === "preprod" &&
  toaster.info({
    title: `VERSION: ${runtimeVars.VERSION}`
  });

/**
 * Returns object of the query params
 * @param queryString String
 */
export const queryStringToObj = queryString => {
  const result = {};
  if (!queryString) {
    return result;
  }

  const queryArr = queryString.substr(1).split("&");
  for (const item of queryArr) {
    const param = item.split("=");
    if (param.length === 2) {
      // eslint-disable-next-line prefer-destructuring
      result[param[0]] = param[1];
    }
  }
  return result;
};

export const setUrgent = str =>
  str && str.toLowerCase().trim() === "yes" ? "URGENT!" : "";
