import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Mutation, Query } from "react-apollo";
import { Button, Icon, toaster, H3 } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import classes from "./StickyHeader.module.scss";
import SocialMedia from "../../SocialMedia/SocialMedia";
import { CREATE_MISSION_APPLICATION } from "../../mutations";
import { USER_APPLICATIONS_QUERY } from "../../query";
import Routes from "../../../layout/routes";
import theme from "../../../../configs/theme";

@inject("sessionStore")
@observer
class StickyHeader extends Component {
  isBtnDisabled = userApplications => {
    const { id } = this.props;
    const arr = userApplications.filter(
      application => application.jobOrderId === id
    );
    return !!arr.length;
  };

  toLogin = () => {
    const { id, sessionStore, history } = this.props;
    sessionStore.setBackRef(id);
    history.push(Routes.HbLayoutRoutes.Login.path);
  };

  mutationCompleted = () => {
    const { sessionStore, i18n } = this.props;
    toaster.success({
      title: i18n._(t`Candidature créée avec succès`),
      description: i18n._(
        t`Vous pouvez suivre vos candidatures dans votre profil`
      ),
      id: "application_success"
    });
    sessionStore.setJobOrderReferer(null);
  };

  displayApplyBtn = i18n => {
    const { sessionStore, id } = this.props;

    if (sessionStore.authToken) {
      return (
        <Query query={USER_APPLICATIONS_QUERY} variables={{ jobOrderId: id }}>
          {({ loading, error: queryError, data }) => {
            if (loading) return null;
            if (queryError) return null;

            const { userApplications } = data;
            return (
              <Mutation
                mutation={CREATE_MISSION_APPLICATION}
                onCompleted={this.mutationCompleted}
                onError={error => {
                  const toastObj = {
                    title: i18n._(t`Erreur de création de candidature`),
                    description: i18n._(
                      t`Un problème est survenu lors de la création de votre candidature`
                    )
                  };
                  if (
                    error.graphQLErrors[0].message ===
                    "Application already exists"
                  ) {
                    toastObj.title = i18n._(
                      t`Vous avez déjà postulé pour cette mission`
                    );
                    toastObj.description = i18n._(
                      t`Vous pouvez suivre vos candidatures dans votre profil`
                    );
                  }
                  toaster.error(toastObj);
                }}
                ignoreResults
                refetchQueries={[{ query: USER_APPLICATIONS_QUERY }]}
              >
                {createMissionApplication => (
                  <Button
                    disabled={this.isBtnDisabled(userApplications)}
                    size="small"
                    onClick={() =>
                      createMissionApplication({
                        variables: {
                          jobOrderId: id,
                          origin: sessionStore.jobOrderReferer || "direct url"
                        }
                      })
                    }
                    icon="chevron-right"
                    name={i18n._(t`Postuler`)}
                  >
                    {this.isBtnDisabled(userApplications) ? (
                      <Trans>Postulé</Trans>
                    ) : (
                      <Trans>Postuler</Trans>
                    )}
                  </Button>
                )}
              </Mutation>
            );
          }}
        </Query>
      );
    }
    return (
      <Button
        name={i18n._(t`Postuler`)}
        size="small"
        onClick={this.toLogin}
        icon="chevron-right"
      >
        <Trans>Postuler</Trans>
      </Button>
    );
  };

  render() {
    const { id, title, place, businessSectors, i18n } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <H3 className={classes.title}>{title}</H3>
          <div className={classes.info}>
            <div className={classes.infoItem}>
              <Icon
                type="location"
                width={14}
                height={16}
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
              {place}
            </div>
            <div className={classes.infoItem}>
              <Icon
                type="briefcase"
                width={19}
                height={16}
                color={theme.colors.mainColor}
                color2={theme.colors.lightMainColor}
              />
              {businessSectors.join(" ")}
            </div>
            <div className={classes.social}>
              <SocialMedia id={id} />
            </div>
          </div>
          <div className={classes.applyButton}>
            {this.displayApplyBtn(i18n)}
          </div>
        </div>
      </div>
    );
  }
}

StickyHeader.wrappedComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  place: PropTypes.string,
  businessSectors: PropTypes.arrayOf(PropTypes.string),
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    setBackRef: PropTypes.func,
    authToken: PropTypes.string,
    jobOrderReferer: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

StickyHeader.defaultProps = {
  title: "",
  place: "",
  businessSectors: []
};

export default withI18n()(withRouter(StickyHeader));
