export default {
  loop: false,
  responsive: {
    0: {
      items: 1,
      margin: 20,
      stagePadding: 70
    },
    420: {
      items: 1.5,
      margin: 20,
      stagePadding: 40
    },
    600: {
      items: 2,
      margin: 20,
      stagePadding: 70
    },
    769: {
      items: 3,
      margin: 10,
      stagePadding: 70
    },
    1024: {
      items: 3,
      margin: 30,
      stagePadding: 70,
      nav: true
    },
    1440: {
      items: 3,
      margin: 40,
      stagePadding: 100,
      nav: true
    },
    1660: {
      items: 4,
      margin: 40,
      stagePadding: 100,
      nav: true
    }
  }
};
