import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Button, FormCard, Divider, Row, Col } from "cf-neo-ui";
import Routes from "../layout/routes/index";
import "./styles.scss";

@inject("sessionStore", "appStore", "modalStore")
@observer
class PostNeedSelection extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { i18n, modalStore } = this.props;
    const { changeIsVisible } = modalStore;
    return (
      <div className="session container signup-selection">
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className="form-card-content center">
              <div className="center">
                <Link to={Routes.HbLayoutRoutes.MyNeed.path}>
                  <Button
                    icon="chevron-right"
                    iconPosition="right"
                    variant="secondary"
                  >
                    <Trans>Poster un besoin via une fiche de poste</Trans>
                  </Button>
                </Link>
              </div>
              <br />
            </Col>
            <Divider
              orientation="horizontal"
              subText={i18n._(t`OU`)}
              circleBg="white"
            />

            <Col className="form-card-content center">
              <div className="center">
                <Link to={Routes.HbLayoutRoutes.Offer.path}>
                  <Button
                    icon="chevron-right"
                    iconPosition="right"
                    onClick={() => changeIsVisible(false)}
                  >
                    <Trans>Poster un besoin via le formulaire</Trans>
                  </Button>
                </Link>
              </div>
              <br />
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

PostNeedSelection.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    signUpUserRole: PropTypes.string,
    reset: PropTypes.func,
    changeSignUpUserRole: PropTypes.func,
    changeExpressSignUp: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(PostNeedSelection));
