import gql from "graphql-tag";

const SINGLE_OFFER_STATS_QUERY = gql`
  query SingleOfferStats($offerId: ID!) {
    clientVacancy(id: $offerId) {
      totalNotes
      totalShortListed
      totalCVSent
      totalInterviews
      totalPlacements
    }
  }
`;

export default SINGLE_OFFER_STATS_QUERY;
