import { t } from "@lingui/macro";
/**
 * configure only static header pages
 * for seeOffers any string entered will be concatenated by the sourcer name
 * pass null to any props you would like it to take the default values stores in "utils/editMetaTags"
 * */
const staticPagesMetaTags = i18n => ({
  home: {
    title: i18n._(
      t`Club Freelance | Partenaire des entreprises & freelances IT`
    ),
    description: i18n._(
      t`Trouvez les plus belles offres IT et les meilleurs consultants Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...`
    ),
    imageUrl: null,
    keywords: i18n._(
      t`Club Freelance, Freelance informatique, consultants Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP`
    )
  },
  consultant: {
    title: i18n._(
      t`Club Freelance - La communauté des Freelances Informatique`
    ),
    description: i18n._(
      t`Sélection d’offres adaptées à votre profil. Accompagnement personnalisé. Paiements à 30 jours. Pour tous les Freelances du digital, de la tech et de l’IT`
    ),
    imageUrl: null,
    keywords: i18n._(
      t`Freelance informatique, consultant IT, mission freelance, mission sap, mission développement, mission data`
    )
  },
  client: {
    title: i18n._(
      t`Club Freelance | Entourez-vous des meilleurs Talents Informatiques`
    ),
    description: i18n._(
      t`Nos équipes vous accompagnent pour recruter & intégrer les meilleurs freelances IT pour vos projets. Solution complète de Talent Management et conseils personnalisés`
    ),
    imageUrl: null,
    keywords: i18n._(
      t`Club Freelance, talent management, freelance informatique, trouver un freelance`
    )
  },
  missions: {
    title: i18n._(
      t`Club Freelance | Votre prochaine mission Freelance Informatique`
    ),
    description: i18n._(
      t`Retrouvez nos offres pour consultants IT : Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle...`
    ),
    imageUrl: null,
    keywords: i18n._(
      t`missions freelance, Data, Développement, Cloud, Gestion de projet, CRM, Support, BI, Webdesign, ERP, SAP, Oracle`
    )
  },
  sourcerOffers: {
    title: i18n._(t`Talent Community Specialist`),
    description: i18n._(
      t`Découvrez toutes les offres de votre Talent Community Speciallist`
    ),
    imageUrl: null,
    keywords: i18n._(
      t`missions, Talent Community Specialist, Freelance informatique`
    )
  },
  contact: {
    title: i18n._(t`Club Freelance | Nous contacter`),
    description: i18n._(
      t`Vous avez un besoin informatique ? Vous cherchez une mission ? N’hésitez pas à nous contacter. `
    ),
    imageUrl: null,
    keywords: i18n._(t`contact, club freelance, freelance informatique`)
  },
  privacyPolicy: {
    title: i18n._(t`Privacy Policy - Club Freelance`),
    description: null,
    imageUrl: null,
    keywords: i18n._(t`club freelance, privacy policy`)
  },
  about: {
    title: i18n._(t`Club Freelance | Expert du sourcing IT digitalisé`),
    description: i18n._(
      t`Des hommes et des algorithmes pour un matching parfait entre entreprises et freelances en informatique. Nous accompagnons les meilleurs talents et les entreprises les plus innovantes.`
    ),
    imageUrl: null,
    keywords: i18n._(
      t`Club Freelance, talent management, freelance informatique, trouver un freelance`
    )
  },
  dashboard: {
    title: i18n._(t`Club Freelance | Dashboard`),
    description: i18n._(t`user dashboard`),
    imageUrl: null,
    keywords: null
  },
  resume: {
    title: i18n._(t`Club Freelance | Mon CV`),
    description: i18n._(t`user dashboard`),
    imageUrl: null,
    keywords: null
  },
  details: {
    title: i18n._(t`Club Freelance | Mes Coordonnées`),
    description: i18n._(t`user dashboard`),
    imageUrl: null,
    keywords: null
  },
  job: {
    title: i18n._(t`Club Freelance | Mon métier`),
    description: i18n._(t`user dashboard`),
    imageUrl: null,
    keywords: null
  },
  experience: {
    title: i18n._(t`Club Freelance | Mon expérience`),
    description: i18n._(t`user dashboard`),
    imageUrl: null,
    keywords: null
  },
  training: {
    title: i18n._(t`Club Freelance | Ma formation`),
    description: i18n._(t`user dashboard`),
    imageUrl: null,
    keywords: null
  },
  search: {
    title: i18n._(t`Club Freelance | Ma recherche`),
    description: i18n._(t`user dashboard`),
    imageUrl: null,
    keywords: null
  },
  recommendedMissions: {
    title: i18n._(t`Mindquest | Offres recommandées`),
    description: null,
    imageUrl: null,
    keywords: null
  },
  appliedMissions: {
    title: i18n._(t`Mindquest | Mes candidatures`),
    description: null,
    imageUrl: null,
    keywords: null
  },
  favouriteMissions: {
    title: i18n._(t`Mindquest | Favoris`),
    description: null,
    imageUrl: null,
    keywords: null
  },
  alertMissions: {
    title: i18n._(t`Mindquest | Mes alertes`),
    description: null,
    imageUrl: null,
    keywords: null
  },
  dashboardCompany: {
    title: i18n._(t`Dashboard Enterprise`),
    description: i18n._(t`Acceder à votre espace entreprise`),
    imageUrl: null,
    keywords: i18n._(
      t`dashboard, club freelance, entreprise, talents, recrutement, `
    )
  },
  signUp: {
    title: i18n._(t`Club Freelance | Créer un compte`),
    description: i18n._(t`Rejoignez la communauté des Freelances IT`),
    imageUrl: null,
    keywords: i18n._(
      t`sign up, club freelance, communauté, compte, Freelances IT`
    )
  },
  login: {
    title: i18n._(t`Club Freelance | Se connecter`),
    description: i18n._(t`Connectez-vous à votre profil Club Freelance`),
    imageUrl: null,
    keywords: i18n._(t`login, club freelance, account, connexion`)
  },
  signUpThanks: {
    title: i18n._(t`Congrats`),
    description: null,
    imageUrl: null,
    keywords: null
  },
  blog: {
    main: {
      title: i18n._(t`Club Freelance Insights | Le blog des talents IT `),
      description: i18n._(
        t`Actualités, conseils pratiques, décryptages, et points de vue d’experts pour freelances informatiques et décideurs IT en quête de réussite.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`blog, club freelance, talents IT, actualités, freelances IT`
      )
    },
    news: {
      title: i18n._(t`Club Freelance Insights | L’actualité des talents IT`),
      description: i18n._(
        t`Retrouvez toute l’actualité de Club Freelance et notre sélection d’articles pour les Freelances informatiques et décideurs IT.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`blog, club freelance, actualités, freelance informatique, actualité freelance`
      )
    },
    decryption: {
      title: i18n._(
        t`Club Freelance Insights | Décryptages des métiers de l’IT`
      ),
      description: i18n._(
        t`Découvrez les tendances et évolution des métiers de l’informatique & du digital à l’heure du future of work. Fiches métiers détaillées et analyses.`
      ),
      imageUrl: null,
      keywords: i18n._(t`blog, club freelance, Décryptages`)
    },
    advicesConsultant: {
      title: i18n._(
        t`Club Freelance Insights | Conseils Pratiques pour Freelances Informatiques`
      ),
      description: i18n._(
        t`Découvrez tous nos conseils et points de vue d’experts pour réussir dans votre activité de freelance IT et décrocher les meilleures missions.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`blog, club freelance, Conseils pratiques, consultant IT, freelance informatique`
      )
    },
    advicesEnterprise: {
      title: i18n._(
        t`Club Freelance Insights | Conseils Pratiques pour Décideurs Informatiques`
      ),
      description: i18n._(
        t`Découvrez tous nos conseils et points de vue d’experts pour recruter les meilleurs talents et travailler efficacement avec des freelances IT.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`blog, club freelance, Conseils pratiques, entreprise IT, freelance informatique`
      )
    },
    successStories: {
      title: i18n._(t`Club Freelance Insights | Témoignages`),
      description: i18n._(
        t`Découvrez les tendances et évolution des métiers de l’informatique & du digital à l’heure du future of work. Fiches métiers détaillées et analyses.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`blog, club freelance, Success stories, freelance informatique`
      )
    },
    search: {
      title: i18n._(t`Club Freelance Insights | Rechercher un article`),
      description: i18n._(
        t`Recherchez un article au sein de notre sélection pour Freelance informatiques et Décideurs IT`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`blog, club freelance, recherche, freelance informatique`
      )
    }
  },
  blogEn: {
    main: {
      title: i18n._(
        t`Club Freelance's Mission Control Center — MCC: IT's resource hub `
      ),
      description: i18n._(
        t`MCC is a one-stop shop for IT career and talent acquisition advice, tech news, and in-depth analysis of industry trends. A guide for professional and business success.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, interesting reads, news, company announcements, short stories, sci-fi`
      )
    },
    "tips-and-tricks": {
      title: i18n._(
        t`Club Freelance's Mission Control Center — Career Tips & Tricks`
      ),
      description: i18n._(
        t`IT career advice at zero price. Valuable resources to help you navigate professional growth and get that contract or job you always wanted. `
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing,  IT contractors, IT freelancers, IT talent, career tips, tips and tricks`
      )
    },
    "its-who-is-who": {
      title: i18n._(
        t`Club Freelance's Mission Control Center — IT's Who is Who`
      ),
      description: i18n._(
        t`Ever wondered what it is that your colleagues in other areas of IT actually do? Curious about exploring new career paths in tech? Impove you awareness of the industy.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, who is who, IT`
      )
    },
    "business-lounge": {
      title: i18n._(
        t`Club Freelance's Mission Control Center — Business Lounge`
      ),
      description: i18n._(
        t`Sit back, relax and enjoy our in-depth articles on IT talent management and industry trends. We're here to help you build the best possible team for business success.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge  `
      )
    },
    Newsstand: {
      title: i18n._(t`Club Freelance's Mission Control Center — Newsstand`),
      description: i18n._(
        t`Extra, extra! IT and tech news from the past week, every week. From Cloud and IoT to SAP's ERP and all things Microsoft, these are news stories you should keep in mind.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, IT careers, IT jobs, tech jobs, tech, careers, UK, job vacancies, IT vacancies,  Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, , IT contractors, IT freelancers, IT talent, tech profiles, career paths, news, tech news, newsstand`
      )
    },
    "captains-log": {
      title: i18n._(t`Club Freelance's Mission Control Center — Captain's Log`),
      description: i18n._(
        t`Thoughts and announcements from our leadership team. Their IT and human expertise led to the birth of Club Freelance, and it can also help you. `
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, IT recruitment, IT staffing, UK, IT contractors, IT freelancers, IT talent, Mission Control Center, blog, business advice, resources, IT industry trends, IT trends, IT recruitment trends, talent management, business lounge, company announcements  `
      )
    },
    "interesting-reads": {
      title: i18n._(
        t`Club Freelance's Mission Control Center  — Interesting reads`
      ),
      description: i18n._(
        t`Quick and interesting reads for commuters and curious minds. Tech, IT and pop culture merge in this section for a quick break from work at any time of the day.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, interesting reads`
      )
    },
    "horizon-2050": {
      title: i18n._(t`Club Freelance's Mission Control Center — Horizon 2050`),
      description: i18n._(
        t`Embark on a journey to discover what lies ahead for IT and science. Short sci-fi stories posted every month for you to engage and especulate on the future of tech.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech`
      )
    },
    "behind-screen": {
      title: i18n._(
        t`Club Freelance's Mission Control Center — Behind the screen`
      ),
      description: i18n._(
        t`An insider's look at the tech industry through interviews with expert from all areas of it..`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, IT, IT news, tech, Tech news, Technilogy, science, science-fiction, sci-fi, future, pop culture, popular culture, fiction, short stories, horizon 2050, future tech`
      )
    },
    search: {
      title: i18n._(
        t`Club Freelance's Mission Control Center— Search all articles`
      ),
      description: i18n._(
        t`Find the advice and resources you're looking for at the Mission Control Center, your one-stop shop for IT talent management and career advice.`
      ),
      imageUrl: null,
      keywords: i18n._(
        t`Club Freelance, Mission Control Center, blog, career advice, resources, industry trends, IT trends, IT recruitment trends, IT recruitment, IT staffing, IT careers, IT jobs, tech jobs, tech, careers, UK, IT contractors, IT freelancers, IT talent, search`
      )
    }
  },
  SearchCandidatesPage: {
    title: i18n._(t`Club Freelance | Recherche des candidats`),
    description: i18n._(
      t`Trouvez votre consultant IT avec notre algorithme de matching SMATCH`
    ),
    imageUrl: null,
    keywords: null
  }
});

export default staticPagesMetaTags;
