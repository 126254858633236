import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import cookie from "react-cookies";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import {
  Row,
  Col,
  Divider,
  TextInput,
  FormGroup,
  FormCard,
  Button,
  Validation,
  Checkbox,
  Radio,
  RadioGroup,
  PasswordInput,
  toaster,
  Spinner
} from "cf-neo-ui";
import Routes from "../layout/routes/index";
import validate from "../../utils/validators";
import { scorePassword } from "../../utils/helpers";
import "./styles.scss";
import { cookieSaveOpt } from "../../configs/domainConfigs";
import ReactGA from "react-ga";
import runtimeVars from "../../configs/runTimeVars";

const { AUTH_TOKEN } = runtimeVars;

const SIGNUP_MUTATION = gql`
  mutation SignupMutation(
    $email: String!
    $password: String!
    $heardFrom: String
    $receiveNewsletter: Boolean
    $lastInfo: Boolean
    $siteLanguage: String
    $source: String!
    $role: String!
    $path: String
    $step: Int
  ) {
    signup(
      input: {
        email: $email
        password: $password
        heardFrom: $heardFrom
        receiveNewsletter: $receiveNewsletter
        lastInfo: $lastInfo
        siteLanguage: $siteLanguage
        source: $source
        role: $role
        path: $path
        step: $step
      }
    ) {
      token
      account {
        email
        step
        role
        siteLanguage
        path
      }
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class AccountCreation extends Component {
  constructor(props) {
    super(props);
    this.source = "uk";

    this.state = {
      isEmailValid: true,
      isPasswordValid: true,
      emailValidationMessage: "",
      passwordValidationMessage: "",
      role: "CANDIDATE",
      path: "/signup-confirm-code",
      step: 0
    };
  }

  componentDidMount() {
    const { host } = window.location;
    const webDomain = host.split(".").pop();
    if (webDomain === "com") this.source = "Website FR";
    if (webDomain === "uk") this.source = "Website UK";

    ReactGA.initialize("UA-56544271-1");

    ReactGA.pageview(window.location.pathname);
  }

  confirmationCodeChangeHandler = confirmationCode => {
    const { sessionStore } = this.props;
    sessionStore.changeConfirmationCode(confirmationCode);
  };

  idChangeHandler = _id => {
    const { sessionStore } = this.props;
    sessionStore.changeId(_id);
  };
  lastInfoChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeLastInfo(!sessionStore.lastInfo);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };
  handleEvaluator = v => {
    return scorePassword(v);
  };

  acceptTermsChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeAcceptTerms(!sessionStore.acceptTerms);
  };

  receiveNewsletterChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeReceiveNewsletter(!sessionStore.receiveNewsletter);
  };

  heardFromChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changeHeardFrom(v);
  };

  onErrorHandler = msg => {
    const { i18n } = this.props;
    if (
      msg ===
      'Unexpected error value: "Un compte a déjà été créé avec cet email"'
    ) {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`Un compte a déjà été créé avec cet email`)
      });
    } else {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Une erreur est survenue, veuillez réessayer ultérieurement`
        )
      });
    }
  };

  emailChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeEmail(String(e.target.value).toLowerCase());
    this.validateEmail(e.target.value);
  };

  passwordChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changePassword(v);
    this.validatePassword(v);
  };

  isFormValid() {
    const { sessionStore } = this.props;
    const { email, password, acceptTerms } = sessionStore;
    let valid = true;
    if (!this.validateEmail(email)) valid = false;
    if (!this.validatePassword(password)) valid = false;
    if (!valid) return false;
    if (!this.validateAcceptTerms(acceptTerms)) valid = false;
    return valid;
  }

  validateEmail(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required", "email"], i18n);
    this.setState({
      isEmailValid: res.isValid,
      emailValidationMessage: res.message
    });
    return res.isValid;
  }

  signupMutationCompletedHandler = async data => {
    const { signup } = data;
    const { ConfirmCode } = Routes.Hb2LayoutRoutes;
    const { token, account } = signup;
    const { sessionStore, history, postANeedStore, appStore } = this.props;
    const { changeLanguage } = appStore;

    localStorage.setItem(AUTH_TOKEN, data.signup.token);
    localStorage.setItem("PATH", signup.account.path);
    cookie.save(AUTH_TOKEN, data.signup.token, cookieSaveOpt);
    changeLanguage(account.siteLanguage);
    sessionStore.changeAuthToken(token);
    sessionStore.changePath(signup.account.path);
    history.push(signup.account.path);
  };

  validatePassword(value) {
    const { i18n } = this.props;
    if (!value) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Ce champs est requis`)
      });
      return false;
    }

    if (value.length < 8) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins 8 caractères`
        )
      });
      return false;
    }

    if (!/[a-z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une minuscule`
        )
      });
      return false;
    }
    if (!/[A-Z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une majuscule`
        )
      });
      return false;
    }
    if (!/\d/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Doit contenir au moins un chiffre`)
      });
      return false;
    }
    if (!/\W/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins un caractère spécial !*#@...`
        )
      });
      return false;
    }
    this.setState({
      isPasswordValid: true,
      passwordValidationMessage: ""
    });
    return true;
  }

  validateAcceptTerms(value) {
    const { i18n } = this.props;
    if (!value) {
      toaster.closeAll();
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(
          t`Veuillez accepter notre politique de confidentialité`
        )
      });
    }

    return value;
  }

  render() {
    const { PrivacyPolicy } = Routes.HbLayoutRoutes;
    const { ConfirmCode } = Routes.Hb2LayoutRoutes;
    const { i18n, sessionStore, appStore, history } = this.props;
    const orientation = appStore.width <= 990 ? "vertical" : "horizontal";
    const {
      changeStep,
      email,
      password,
      heardFrom,
      receiveNewsletter,
      lastInfo,
      acceptTerms,
      signUpUserRole
    } = sessionStore;

    const { currentLanguage } = appStore;

    changeStep(1);
    const {
      isEmailValid,
      isPasswordValid,
      emailValidationMessage,
      passwordValidationMessage
    } = this.state;
    const role = signUpUserRole;
    var heardFromArray = [""];
    if (heardFrom && heardFrom.length > 0)
      heardFromArray = heardFrom.split(",");

    const checkBoxLabel = (
      <Trans>
        J’accepte{" "}
        <Link
          to={PrivacyPolicy.path}
          target="_blank"
          style={{ color: "#d3354a" }}
        >
          {" "}
          la politique de confidentialités{" "}
        </Link>{" "}
        Club Freelance
      </Trans>
    );
    return (
      <div className="session account-creation">
        <br />

        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Créer un compte</Trans>
            </h1>
          </Col>
        </Row>

        <br />

        <FormCard
          className="formCard no-gutter account-form"
          style={{
            paddingTop: "50px",
            paddingLeft: "20px",
            paddingRight: "20px"
          }}
        >
          <Row>
            <Col className="center " lg={10} xl={10} md={10} sm={10} xs={5}>
              <FormGroup>
                <label className="form-label" htmlFor="email">
                  <Trans>Email</Trans>
                </label>
                <Validation
                  errorMessage={emailValidationMessage}
                  valid={isEmailValid}
                >
                  <TextInput
                    id="email"
                    type="email"
                    spellCheck="false"
                    className="form-input"
                    placeholder={i18n._(t`monemail@mail.com`)}
                    value={email}
                    onChange={this.emailChangeHandler}
                  />
                </Validation>
              </FormGroup>
              <br />
              <FormGroup>
                <label className="form-label" htmlFor="pass">
                  <Trans>Créer un mot de passe</Trans>
                </label>
                <Validation
                  errorMessage={passwordValidationMessage}
                  valid={isPasswordValid}
                >
                  <PasswordInput
                    id="pass"
                    autocomplete="new-password"
                    className="form-input"
                    message={i18n._(t`Niveau de sécurité`)}
                    placeholder={i18n._(t`Entrer un mot de passe`)}
                    passwordEvaluator={this.handleEvaluator}
                    value={password}
                    onChange={this.passwordChangeHandler}
                  />
                </Validation>
              </FormGroup>

              <br />

              <span className="contact-text">
                <Trans>Comment avez-vous entendu parlez de nous</Trans> ?
              </span>
              <br />

              <RadioGroup
                id="work-type"
                name="RadioGrp"
                defaultSelected={heardFromArray}
                onChange={this.heardFromChangeHandler}
                orientation={orientation}
                className="remote-radio-group"
              >
                <Radio
                  label={i18n._(t`Réseaux sociaux`)}
                  value="Social Networks"
                />
                <Radio
                  label={i18n._(t`Moteurs de recherche`)}
                  value="Search Engine"
                />
                <Radio
                  label={i18n._(t`Bouche à oreille`)}
                  value="Word of Mouth"
                />
                <Radio label={i18n._(t`Newsletter`)} value="Newsletter" />
                <Radio label={i18n._(t`Autre`)} value="Other" />
              </RadioGroup>
              <br />
              <Divider />
              <div className="accept_terms">
                <Checkbox
                  name="receive_newsletter"
                  value="receive_newsletter"
                  checked={receiveNewsletter}
                  label={i18n._(
                    t`Recevoir notre newsletter bimensuelle spéciale Tech & IT (conseils pratiques, dossiers spéciaux, sélection de missions, etc.).`
                  )}
                  onClickButton={this.receiveNewsletterChangeHandler}
                />
              </div>
              <div className="accept_terms">
                <Checkbox
                  name="receive_newsletter"
                  value="receive_newsletter"
                  checked={lastInfo}
                  label={i18n._(
                    t`Je souhaite recevoir les dernières informations ainsi que les avantages communautés de Club Freelance (webinaires, événements, blog, réductions, etc.)`
                  )}
                  onClickButton={this.lastInfoChangeHandler}
                />
              </div>
              <div className="accept_terms">
                <Checkbox
                  name="accept_terms"
                  value="accept_terms"
                  checked={acceptTerms}
                  label={checkBoxLabel}
                  onClickButton={this.acceptTermsChangeHandler}
                  changeableFontSize
                />
              </div>

              <div className="account-creation-submission-button">
                <Mutation
                  mutation={SIGNUP_MUTATION}
                  variables={{
                    email,
                    password,
                    heardFrom,
                    receiveNewsletter,
                    lastInfo,
                    acceptTerms,
                    source: this.source || "unknown",
                    siteLanguage: currentLanguage,
                    role: "CANDIDATE",
                    path: "/signup-confirm-code",
                    step: 0
                  }}
                  onCompleted={data => {
                    // history.push(ConfirmCode.path);
                    this.signupMutationCompletedHandler(data);
                  }}
                  onError={errors => {
                    if (errors)
                      errors.graphQLErrors.forEach(({ message }) => {
                        if (message) {
                          this.onErrorHandler(message);
                        }
                      });
                  }}
                >
                  {(mutation, { loading }) => (
                    <span>
                      {loading ? (
                        <Button disabled>
                          <Spinner
                            type="pointed-circular"
                            color="#FFFFFF"
                            size={12}
                          />
                        </Button>
                      ) : (
                        <Button
                          icon="chevron-right"
                          onClick={() =>
                            this.isFormValid() && mutation() && changeStep(3)
                          }
                        >
                          <Trans>Soumettre</Trans>
                        </Button>
                      )}
                    </span>
                  )}
                </Mutation>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

AccountCreation.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeConfirmationCode: PropTypes.func,
    changeId: PropTypes.func,
    reset: PropTypes.func,
    changeAuthToken: PropTypes.func,
    changeAcceptTerms: PropTypes.func,
    changeReceiveNewsletter: PropTypes.func,
    changeAccountCreationPageFilled: PropTypes.func,
    changePreferredContact: PropTypes.func,
    changeHeardFrom: PropTypes.func,
    changeStep: PropTypes.func,
    changeEmail: PropTypes.func,
    changePassword: PropTypes.func,
    email: PropTypes.string,
    password: PropTypes.string,
    signUpUserRole: PropTypes.string,
    acceptTerms: PropTypes.bool,
    receiveNewsletter: PropTypes.bool,
    heardFrom: PropTypes.arrayOf(PropTypes.string),
    confirmationCode: PropTypes.string,
    _id: PropTypes.string
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number,
    currentLanguage: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(AccountCreation));
