import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { Row, Col, SwitchButton, Sorter } from "cf-neo-ui";
import "./styles.scss";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";

@inject("modalStore", "talentStore", "jobSubmissionStore")
@observer
class Block extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      selected: false,
      ...initProps
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { title, description, legalInformation } = this.props;
    const { selected } = this.state;
    return (
      <Row>
        <Col>
          <div>
            <span className="c-left">
              <h4>{title}</h4>
            </span>
            <span className="c-right">
              <h4>
                <SwitchButton onSwitch={() => {}} checked />
              </h4>
            </span>
          </div>
          <p>{description}</p>

          <div>
            <span className="c-right">
              <Sorter
                text="Informations légales"
                onChange={() => {
                  this.setState({ selected: !selected });
                }}
                chevronSize={6}
                chevronColor="#000000"
                classNameText="
 font-family: Lato;
 font-style: normal;
 font-weight: bold;
 font-size: 12px;
 color: #000000;
 line-height: 1;"
              />
            </span>
          </div>
          {selected && <p>{legalInformation}</p>}
        </Col>
      </Row>
    );
  }
}

Block.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  legalInformation: PropTypes.string.isRequired
};

export default withI18n()(Block);
