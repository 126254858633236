import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Messenger } from "cf-neo-ui";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { withApollo } from "react-apollo";
import { withI18n } from "@lingui/react";
import { withRouter } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import SEND_MESSAGE_TIME_SHEET from "./mutation";
import classes from "./styles.module.scss";

@inject("timesheetStore", "sessionStore")
@observer
class Index extends Component {
  saveComment = comment => {
    const { timesheetStore } = this.props;
    if (comment != "" && comment != null && comment != " ") {
      timesheetStore.changeComment(comment);
    }
  };

  sendMessage = msg => {
    const { timesheetStore, match, client } = this.props;
    if (msg != "" && msg != " " && msg != null) {
      timesheetStore.addMessage({
        text: msg,
        date: moment().format("DD/MM/YY HH:mm"),
        speaker: null
      });
      client.mutate({
        mutation: SEND_MESSAGE_TIME_SHEET,
        variables: {
          timesheetID: Number.parseInt(match.params.id, 10),
          date: new Date().getTime(),
          message: msg
        }
      });
    }
  };

  render() {
    const { timesheetStore, sessionStore, i18n } = this.props;
    const { timesheet, changeComment } = timesheetStore;
    const { account } = sessionStore;
    const messages = [...timesheetStore.messages];
    let isDisabledForClient = true;
    let isDisabledForCandidate = true;
    if (timesheet.status === "pending") {
      isDisabledForClient = false;
    }
    if (
      timesheet.status === "open" ||
      timesheet.status === "rejected" ||
      timesheet.status === "draft"
    ) {
      isDisabledForCandidate = false;
    }

    let isDisable = true;
    if (account.role === "CLIENT") isDisable = isDisabledForClient;
    if (account.role === "CANDIDATE") isDisable = isDisabledForCandidate;

    return (
      <div className={classes.messengerHolder}>
        <Messenger
          height="350px"
          messages={messages}
          send={msg => this.sendMessage(msg)}
          unlimitedChat={false}
          getComment={comment => this.saveComment(comment)}
          disabled={isDisable}
          withInputSection={false}
          headerText={i18n._(t`Commentaires`)}
          tooltipText={i18n._(
            t`Ajouter un commentaire pour indiquer votre activité au client`
          )}
          withHeader
          withIcon
          withTooltip
          tooltipText={i18n._(
            t`Ajouter un commentaire pour indiquer votre activité au client`
          )}
          defaultText={i18n._(
            t`Vous pourrez ajouter un commentaire après avoir cliqué sur "Continuer"`
          )}
        />
      </div>
    );
  }
}

Index.wrappedComponent.propTypes = {
  client: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    timesheet: PropTypes.shape({
      status: PropTypes.string
    }),
    comment: PropTypes.string,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        date: PropTypes.string,
        speaker: PropTypes.shape({
          avatar: PropTypes.string,
          name: PropTypes.string
        })
      })
    ),
    changeComment: PropTypes.func,
    addMessage: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired
};

export default withApollo(withI18n()(withRouter(Index)));
