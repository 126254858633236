const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 *
 * @param email
 * @returns {boolean}
 */
const isEmail = email => {
  if (!email || typeof email !== "string") return false;
  return re.test(email);
};

export default isEmail;
