import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { BlogCardDisplaySkeleton } from "cf-neo-ui";
import classes from "./BlogCardsDisplay.module.scss";

class LoadingBlogCardDisplay extends PureComponent {
  render() {
    const { cardsNumber } = this.props;
    const res = [];
    for (let i = 0; i < cardsNumber; i += 1)
      res.push(
        <BlogCardDisplaySkeleton key={i} className={classes.blogCard} />
      );
    return <div className={classes.container}>{res}</div>;
  }
}

LoadingBlogCardDisplay.propTypes = {
  cardsNumber: PropTypes.number.isRequired
};

export default LoadingBlogCardDisplay;
