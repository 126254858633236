import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { H2, BlogPosterSkeleton } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { Query } from "react-apollo";
import classes from "./Resources.module.scss";
import BloggerSection from "../blogger-section/BloggerSection";
import Books from "../../../components/testimonial-carousel";
import BookCard from "./Book-card/BookCard";
import LoadingBookCard from "./Book-card/LoadingBookCard";
import { SinglePostQuery, latestPostsQuery, categoriesRefFr } from "../query";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import ldJson from "./ldJson";
import decode from "../../../utils/Utf8Text";

class Resources extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  toResources = id => {
    const { history } = this.props;
    history.push(`/blog/resources/${id}`);
  };

  postsToBooks = posts => {
    const { i18n } = this.props;
    const books = [];
    posts.map(post =>
      books.push({
        id: post.id,
        image: post.featured_media && post.featured_media.source_url,
        name: "",
        job: "",
        paragraph: decode(post.title),
        buttonText: i18n._(t`Accéder à la ressource`),
        onClickButton: () => this.toResources(post.id)
      })
    );
    return books;
  };

  render() {
    const { i18n, match } = this.props;
    return (
      <Query query={SinglePostQuery} variables={{ id: match.params.id }}>
        {({ loading, error, data }) => {
          if (error)
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <span>
                  Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment
                </span>
                <img src={"/assets/svg/download.svg"} alt="error" />
              </div>
            );
          if (loading) return <LoadingBookCard />;
          const { post } = data;
          return (
            <div>
              {metaTags(
                urls.resources(post.id),
                decode(post.title),
                post.meta_description,
                post.featured_media && post.featured_media.source_url,
                post.meta_keys,
                ldJson(i18n, post.id, decode(post.title))
              )}
              <section>
                <BookCard
                  title={decode(post.title)}
                  categories={post.categories}
                  description={post.content}
                  cover={
                    post.featured_media && post.featured_media.source_url
                      ? post.featured_media.source_url
                      : post.featured_image2_url
                      ? post.featured_image2_url
                      : "/public/cf.webp"
                  }
                  downloadLink={post.download_link}
                />
              </section>
              <section className={classes.sug}>
                <div className={classes.bloggerSection}>
                  <BloggerSection blogger={post.author} />
                  <H2 className={classes.header}>
                    <Trans>
                      D’autres ressources qui pourraient vous interesser
                    </Trans>
                  </H2>
                </div>
                <div>
                  {post.related_posts.length ? (
                    <Query
                      query={latestPostsQuery}
                      variables={{
                        include: post.related_posts.slice(0, 3).join(",")
                      }}
                    >
                      {({
                        loading: loadingSug,
                        error: errorSug,
                        data: dataSug
                      }) => {
                        if (errorSug) return <div>wiiw</div>;
                        if (loadingSug)
                          return (
                            <div className={classes.loadingCard}>
                              <BlogPosterSkeleton />
                            </div>
                          );
                        if (!dataSug.posts.length)
                          return (
                            <div className={classes.unavailable}>
                              <Trans>
                                Il n’y a pas des ressources disponibles pour le
                                moment
                              </Trans>
                            </div>
                          );
                        return (
                          <Books
                            testimonials={this.postsToBooks(dataSug.posts)}
                          />
                        );
                      }}
                    </Query>
                  ) : (
                    <Query
                      query={latestPostsQuery}
                      variables={{
                        page: 1,
                        perPage: 3,
                        categoryIds: categoriesRefFr.resources,
                        excludeBlog: match.params.id
                      }}
                    >
                      {({
                        loading: loadingSug,
                        error: errorSug,
                        data: dataSug
                      }) => {
                        if (errorSug) return <div>wiiw</div>;
                        if (loadingSug)
                          return (
                            <div className={classes.loadingCard}>
                              <BlogPosterSkeleton />
                            </div>
                          );
                        if (!dataSug.posts.length)
                          return (
                            <div className={classes.unavailable}>
                              <Trans>
                                Il n’y a pas des ressources disponibles pour le
                                moment
                              </Trans>
                            </div>
                          );
                        return (
                          <Books
                            testimonials={this.postsToBooks(dataSug.posts)}
                          />
                        );
                      }}
                    </Query>
                  )}
                </div>
              </section>
            </div>
          );
        }}
      </Query>
    );
  }
}

Resources.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Resources));
