import React, { Component } from "react";
import * as PropTypes from "prop-types";
import SiderMenuItem from "./SiderMenuItem";

class SiderMenu extends Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        <ul className="sider-menu">
          {React.Children.map(children, menuItem => {
            const { active, icon, title, ...restProps } = menuItem.props;
            return (
              <SiderMenuItem
                active={active}
                icon={icon}
                title={title}
                {...restProps}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

SiderMenu.propTypes = {
  /**  Provide a collection of <SiderMenuItem/> components to render */
  children: PropTypes.arrayOf((propValue, key) => {
    if (propValue[key].type.displayName !== SiderMenuItem.displayName) {
      return new Error(
        "RadioGroup: Invalid Prop children was given. All children must be of type SiderMenuItem"
      );
    }
  })
};

SiderMenu.defaultProps = {
  children: []
};

export default SiderMenu;
