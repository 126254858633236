import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { Container, Row, Col, MissionDisplay } from "cf-neo-ui";
import Banner from "../../../../components/Banner/Banner";
import routes from "../../../layout/routes/hbfLayoutRoutes";
import classes from "./MissionsList.module.scss";
import Routes from "../../../layout/routes";
import { setUrgent } from "../../../../utils/helpers";
import formatMissionDuration from "../../../../utils/formatMissionDuration";
import getContractType from "../../../../utils/getContractType";

@inject("appStore")
@observer
class GuestMissions extends Component {
  preferHandler = () => {
    const { history } = this.props;
    history.push(Routes.HbLayoutRoutes.Login.path);
  };

  toDetails = id => {
    const { history } = this.props;
    history.push(`${routes.MissionsPage.path}/${id}`);
  };

  getNumberOfCards = () => {
    const { appStore } = this.props;
    return appStore.width >= 1660 ? 8 : 6;
  };

  render() {
    const { i18n, jobOrders } = this.props;
    return (
      <div>
        <Container className={classes.missionsListing}>
          <Row>
            {jobOrders.map(
              (mission, index) =>
                index < this.getNumberOfCards() && (
                  <Col
                    xl={3}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={6}
                    key={mission.id}
                    className={classes.missionCard}
                  >
                    <MissionDisplay
                      title={mission.title}
                      location={mission.address.city}
                      duration={formatMissionDuration(
                        mission.duration,
                        i18n,
                        mission.employmentType
                      )}
                      contractType={getContractType(
                        i18n,
                        mission.employmentType
                      )}
                      field={mission.customText1}
                      urgent={setUrgent(mission.customText10)}
                      onShowDetails={() => this.toDetails(mission.id)}
                      showDetailsLabel={i18n._(t`Voir l’offre`)}
                      href={`${routes.MissionsPage.path}/${mission.id}`}
                      onPrefer={this.preferHandler}
                    />
                  </Col>
                )
            )}
          </Row>
        </Container>
        {jobOrders.length > this.getNumberOfCards() && (
          <Row noGape>
            <Banner />
          </Row>
        )}
        <Container className={classes.missionsListing}>
          <Row>
            {jobOrders
              .filter((mission, index) => index >= this.getNumberOfCards())
              .map(
                (mission, index) =>
                  index < this.getNumberOfCards() && (
                    <Col
                      xl={3}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={6}
                      key={mission.id}
                      className={classes.missionCard}
                    >
                      <MissionDisplay
                        title={mission.title}
                        location={mission.address.city}
                        duration={formatMissionDuration(
                          mission.duration,
                          i18n,
                          mission.employmentType
                        )}
                        contractType={getContractType(
                          i18n,
                          mission.employmentType
                        )}
                        field={mission.customText1}
                        urgent={setUrgent(mission.customText10)}
                        onShowDetails={() => this.toDetails(mission.id)}
                        showDetailsLabel={i18n._(t`Voir l’offre`)}
                        href={`${routes.MissionsPage.path}/${mission.id}`}
                        onPrefer={this.preferHandler}
                      />
                    </Col>
                  )
              )}
          </Row>
        </Container>
      </div>
    );
  }
}

GuestMissions.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    width: PropTypes.number
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  jobOrders: PropTypes.arrayOf(PropTypes.shape({}))
};

GuestMissions.defaultProps = {
  jobOrders: []
};

export default withI18n()(withRouter(GuestMissions));
