import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { CountCard, P, CountCardSkeleton } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import classes from "./styles.module.scss";
import CLIENT_ACTIONS_STATS_QUERY from "./query";

@inject("statsStore", "sessionStore")
@observer
class Index extends Component {
  loading = () => {
    return (
      <div className={classes.squares}>
        {[1, 2, 3, 4, 5].map(i => (
          <CountCardSkeleton key={i} className={classes.countCard} />
        ))}
      </div>
    );
  };

  render() {
    const { i18n, statsStore, sessionStore, history } = this.props;
    const { dateStart, dateEnd, setTotal } = statsStore;
    return (
      <div className={classes.container}>
        <Query
          query={CLIENT_ACTIONS_STATS_QUERY}
          variables={{
            id: Number.parseInt(sessionStore.id, 10),
            dateStart,
            dateEnd
          }}
        >
          {({ loading, error, data }) => {
            if (error) return null;
            if (loading) return this.loading();
            const { actionsStats } = data;
            setTotal(actionsStats.vacanciesCount);
            return (
              <div className={classes.squares}>
                <CountCard
                  className={classes.countCard}
                  number={actionsStats.vacanciesCount}
                  text={i18n._(t`Offres postées`)}
                  info={i18n._(
                    t`L’ensemble des offres ajoutés dans notre système pour votre entreprise, sur la période sélectionnée`
                  )}
                  onClick={() => history.push("/dashboard/company/offers")}
                />
                <CountCard
                  className={classes.countCard}
                  number={actionsStats.shortListedCount}
                  text={i18n._(t`CV sélectionnés`)}
                  info={i18n._(
                    t`Le nombre de candidats pre-sélectionnés par nos Talent Community Specialist pour répondre à vos offres, sur la période sélectionée`
                  )}
                  onClick={() => history.push("/dashboard/company/talents")}
                />
                <CountCard
                  className={classes.countCard}
                  number={actionsStats.cvSentCount}
                  text={i18n._(t`CV envoyés`)}
                  info={i18n._(
                    t`Le nombre de CV envoyés par nos responsables de comptes à vos équipes sur la période sélectionée`
                  )}
                  onClick={() => history.push("/dashboard/company/talents")}
                />
                <CountCard
                  className={classes.countCard}
                  number={actionsStats.interviewsCount}
                  text={i18n._(t`Entretiens`)}
                  info={i18n._(
                    t`Le nombre d’entretiens organisées avec vos équipes et les candidats envoyés, sur la période sélectionnée`
                  )}
                  onClick={() =>
                    history.push("/dashboard/company/offers?Accepting")
                  }
                />
                <CountCard
                  className={classes.countCard}
                  number={actionsStats.placementsCount}
                  text={i18n._(t`Placements`)}
                  info={i18n._(
                    t`Le nombre de candidats envoyés par nos équipes, que vous avez recruté sur la période sélectionnée`
                  )}
                  onClick={() =>
                    history.push("/dashboard/company/offers?status=placed")
                  }
                />
              </div>
            );
          }}
        </Query>
        <P className={classes.paragraph}>
          <Trans>
            Ces statistiques correspondent à l’ensemble des actions réalisées
            par nos équipes sur la période sélectionnée
          </Trans>
        </P>
      </div>
    );
  }
}

Index.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  statsStore: PropTypes.shape({
    dateStart: PropTypes.number,
    dateEnd: PropTypes.number,
    setTotal: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default withI18n()(withRouter(Index));
