import React, { Component } from "react";
import * as PropTypes from "prop-types";
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { Mutation } from "react-apollo";
import ReactGA from "react-ga";
import { inject, observer } from "mobx-react";
import uniqid from "uniqid";
import {
  Row,
  Col,
  FormCard,
  Button,
  Spinner,
  Modal,
  ToastCard
} from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import Routes from "../layout/routes/index";
import SingleExperience from "./singleExperience";
import "./styles.scss";
import PopUp from "./pop-up";

const UPDATE_MY_EXPERIENCE_MUTATION = gql`
  mutation updateMyExperienceCandidateMutation(
    $candidateWorkHistory: [ExperienceCreateInput]
  ) {
    updateMyExperienceCandidate(
      input: { candidateWorkHistory: $candidateWorkHistory }
    ) {
      account {
        id
        name
        path
        step
      }
      candidate {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

@inject("sessionStore", "modalStore")
@observer
class MyExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
    this.onAddHandler = this.onAddHandler.bind(this);
    this.onIgnoreStepHandler = this.onIgnoreStepHandler.bind(this);
    this.onNextStepHandler = this.onNextStepHandler.bind(this);
    const { sessionStore } = props;

    const { changeIsCandidateWorkHistoryValid } = sessionStore;
    changeIsCandidateWorkHistoryValid(false);
  }

  componentDidMount() {
    ReactGA.initialize("UA-56544271-1");
    ReactGA.pageview("/signup-my-experience");
  }

  onAddHandler() {
    const { sessionStore } = this.props;
    const { candidateWorkHistory, changeCandidateWorkHistory } = sessionStore;
    const newCandidateWorkHistory = candidateWorkHistory.slice();
    newCandidateWorkHistory.push({
      id: uniqid(),
      isEditing: true,
      isNew: true
    });
    changeCandidateWorkHistory(newCandidateWorkHistory);

    if (candidateWorkHistory.length === 0) {
      localStorage.setItem(
        "Experiences",
        JSON.stringify(newCandidateWorkHistory)
      );
    }
  }

  onIgnoreStepHandler() {
    const { sessionStore, history } = this.props;
    const { candidateWorkHistory } = sessionStore;
    if (candidateWorkHistory && candidateWorkHistory.length > 0)
      this.displayPopUp(true);
    else {
      this.cleanCandidateWorkHistory();
      const { MySearch } = Routes.Hb2LayoutRoutes;
      history.push(MySearch.path);
    }
  }

  onNextStepHandler() {
    const { sessionStore } = this.props;
    const { isCandidateWorkHistoryValid } = sessionStore;

    // the experience has to be saved before moving to the next step
    if (!isCandidateWorkHistoryValid) {
      this.setState({ isVisible: true });
      return;
    }
    const { history } = this.props;
    const { MySearch } = Routes.Hb2LayoutRoutes;
    history.push(MySearch.path);
  }

  displayPopUp = () => {
    const { modalStore } = this.props;
    modalStore.changeExperiencePopUp(true);
  };

  cleanCandidateWorkHistory() {
    const { sessionStore } = this.props;
    const { candidateWorkHistory } = sessionStore;

    candidateWorkHistory.forEach(experience => {
      if (experience.isNew) {
        candidateWorkHistory.remove(experience);
      }
    });
  }

  render() {
    const { sessionStore, modalStore, i18n } = this.props;
    const { isVisible } = this.state;
    const { experiencePopUp } = modalStore;
    const {
      changeStep,
      candidateWorkHistory,
      changeCandidateWorkHistory,
      isCandidateWorkHistoryValid,
      scrappedLinkdin,
      isScrapedExperiencesAdded
    } = sessionStore;
    let candidateWorkHistoryVar = [];

    if (typeof window !== "undefined") {
      candidateWorkHistoryVar = JSON.parse(localStorage.getItem("Experiences"))
        ? JSON.parse(localStorage.getItem("Experiences"))
        : [];
    }
    if (
      candidateWorkHistory &&
      candidateWorkHistory.length === 0 &&
      candidateWorkHistoryVar.length != 0
    ) {
      changeCandidateWorkHistory(candidateWorkHistoryVar);
    }
    const CWH = [];
    const ScrapedExperiences = scrappedLinkdin.experiences;
    if (
      candidateWorkHistoryVar.length === 0 &&
      typeof window !== "undefined" &&
      isCandidateWorkHistoryValid
    ) {
      sessionStore.changeIsScrapedExperiencesAdded(false);
    }
    // add scrapped experiences
    if (
      ScrapedExperiences &&
      ScrapedExperiences.length > 0 &&
      !isScrapedExperiencesAdded &&
      typeof window !== "undefined"
    ) {
      for (
        let keyscrapped = 0;
        keyscrapped < ScrapedExperiences.length;
        keyscrapped += 1
      ) {
        const wkHistory = ScrapedExperiences[keyscrapped];
        candidateWorkHistoryVar.push({
          id: keyscrapped.toString(),
          title: wkHistory.title,
          companyName: wkHistory.companyName,
          startDate: wkHistory.startDate,
          endDate: wkHistory.endDate ? wkHistory.endDate : null,
          companyAddress: { countryID: wkHistory.location },
          summary: wkHistory.description
        });
      }
      changeCandidateWorkHistory(candidateWorkHistoryVar);
      sessionStore.changeIsScrapedExperiencesAdded(true);
    }

    if (candidateWorkHistoryVar) {
      for (let key = 0; key < candidateWorkHistoryVar.length; key += 1) {
        const wkHistory = candidateWorkHistoryVar[key];
        CWH.push({
          id: wkHistory.id.toString(),
          title: wkHistory.title,
          companyName: wkHistory.companyName,
          startDate: wkHistory.startDate
            ? parseFloat(wkHistory.startDate)
            : null,
          endDate: wkHistory.endDate ? parseFloat(wkHistory.endDate) : null,
          summary: wkHistory.summary,
          refName: wkHistory.refName,
          refPhone: wkHistory.refPhone,
          refEmail: wkHistory.refEmail,
          skillExperience: wkHistory.skills,
          specialty: wkHistory.specialties,
          BusinessSector: wkHistory.businessSectors,
          companyAddress: wkHistory.companyAddress,
          isCurrent: wkHistory.isCurrent,
          contractType: wkHistory.contractType
        });
      }
    }
    changeStep(6);

    // the following code was made so that the add new experience form always shows on top of the list
    const newExperienceToAdd = candidateWorkHistoryVar.filter(
      exp => exp.isNew && exp.isEditing
    );
    const oldExperience = candidateWorkHistoryVar.filter(
      exp => !exp.isNew && !exp.isEditing
    );

    const experienceToBeShown = newExperienceToAdd.concat(oldExperience);
    return (
      <div className="session my-experience">
        <Modal
          centred
          isVisible={experiencePopUp}
          withCloseButton={false}
          position="fixed"
        >
          <PopUp />
        </Modal>
        <Modal
          centred
          isVisible={isVisible}
          withCloseButton={false}
          position="fixed"
        >
          <ToastCard
            title={i18n._(t`Erreur`)}
            description={i18n._(
              t`Veuillez enregistrer avant de passer à l’étape suivante`
            )}
            expiresIn={0}
            isVisible
            closable
            intent="error"
            showButton
            buttonText={i18n._(t`j'ai compris`)}
            onClose={() => this.setState({ isVisible: false })}
          />
        </Modal>
        <br />
        <Row>
          <Col className="content">
            <h1 className="title">
              <Trans>Mon expérience</Trans>
            </h1>
          </Col>
        </Row>
        <br />
        <FormCard className="formCard no-gutter with-padding scrollExperience">
          <Row>
            <Col />
            <Col>
              <div className="add-buttons">
                <Button
                  disabled={!isCandidateWorkHistoryValid}
                  onClick={this.onAddHandler}
                >
                  <Trans>Ajouter une expérience</Trans>
                </Button>
              </div>
            </Col>
          </Row>
          {experienceToBeShown && experienceToBeShown.length > 0
            ? experienceToBeShown.map(experience => (
                <div>
                  {" "}
                  <SingleExperience
                    {...experience}
                    key={experience.id}
                    showButton="true"
                  />
                  <br />
                </div>
              ))
            : null}
        </FormCard>
        <br />
        <div className="next-buttons" style={{ marginTop: "0px" }}>
          <Mutation
            mutation={UPDATE_MY_EXPERIENCE_MUTATION}
            variables={{
              candidateWorkHistory: CWH
            }}
            onCompleted={data => {
              this.onNextStepHandler(data);
            }}
          >
            {(mutation, { loading }) => (
              <span>
                {loading ? (
                  <Button disabled>
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  </Button>
                ) : (
                  <Button
                    icon="chevron-right"
                    onClick={() => {
                      this.onNextStepHandler();
                      mutation();
                    }}
                  >
                    <Trans>Étape suivante</Trans>
                  </Button>
                )}
              </span>
            )}
          </Mutation>
          <Button variant="secondary" onClick={this.onIgnoreStepHandler}>
            <Trans>Passer cette étape</Trans>
          </Button>
        </div>
      </div>
    );
  }
}

MyExperience.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    isCandidateWorkHistoryValid: PropTypes.bool,
    changeIsCandidateWorkHistoryValid: PropTypes.func,
    changeCandidateWorkHistory: PropTypes.func,
    changeStep: PropTypes.func,
    candidateWorkHistory: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    experiencePopUp: PropTypes.number,
    changeExperiencePopUp: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(MyExperience));
