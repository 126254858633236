import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { withI18n } from "@lingui/react/index";
import { inject, observer } from "mobx-react";
import { t, Trans } from "@lingui/macro";
import gql from "graphql-tag";
import { Mutation, ApolloConsumer } from "react-apollo";
import { GET_USER_INFO } from "../../components/hoc/queries";

import {
  Row,
  Col,
  Button,
  FormCard,
  IllustrationIcons,
  Divider,
  TextInput,
  Validation,
  Spinner,
  FormGroup,
  toaster
} from "cf-neo-ui";
import Routes from "../layout/routes/index";

import "./styles.scss";
import metaTags from "../../utils/editMetaData";
import urls from "../../utils/urls";
import staticMetaTags from "../../configs/staticPagesMetaTags";
import validate from "../../utils/validators";
import ReactGA from "react-ga";

const RESEND_VALIDATION_EMAIL_MUTATION = gql`
  mutation ResendValidationEmail($email: String!) {
    resendValidationEmail(email: $email) {
      token
    }
  }
`;
export const CODE_VERIFICATION = gql`
  mutation codeVerification($email: String!, $codeVerification: String!) {
    codeVerification(email: $email, codeVerification: $codeVerification) {
      token
      account {
        email
        step
        role
        siteLanguage
        path
      }
    }
  }
`;

@inject("sessionStore", "appStore")
@observer
class Confirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCodeValid: true,
      codeValidationMessage: ""
    };

    this.isFormValid = this.isFormValid.bind(this);
  }
  componentDidMount() {
    ReactGA.initialize("UA-56544271-1");
    ReactGA.pageview(window.location.pathname);
  }
  onNextStepHandler(data) {
    const { history, sessionStore } = this.props;
    const { MyProfile } = Routes.Hb2LayoutRoutes;
    sessionStore.changeAccount(data.codeVerification.account);
    sessionStore.changePath(data.codeVerification.account.path);
    history.push(MyProfile.path);
  }

  handleBackRef = () => {
    const { history, sessionStore } = this.props;
    const { MissionsPage } = Routes.HbfLayoutRoutes;
    history.push(`${MissionsPage.path}/${sessionStore.backRef}`);
    sessionStore.setBackRef(null);
  };

  codeChangeHandler = c => {
    const { sessionStore } = this.props;
    sessionStore.changeCode(c.target.value);
    this.validateCode(c.target.value);
  };

  isFormValid() {
    const { sessionStore } = this.props;
    const { code } = sessionStore;
    let valid = true;
    if (!this.validateCode(code)) valid = false;
    if (!valid) return false;
    return valid;
  }

  validateCode(value) {
    const { i18n } = this.props;
    const { sessionStore } = this.props;
    const { confirmationCode } = sessionStore;
    if (!value) {
      this.setState({
        isCodeValid: false,
        codeValidationMessage: i18n._(t`Ce champs est requis`)
      });
      return false;
    }
    this.setState({
      isCodeValid: true,
      codeValidationMessage: ""
    });
    return true;
  }
  clickHandler = client => {
    const { sessionStore, i18n } = this.props;
    const { account } = sessionStore;
    const { email } = account;
    try {
      client.mutate({
        mutation: RESEND_VALIDATION_EMAIL_MUTATION,
        variables: { email }
      });

      toaster.success({
        title: i18n._(t`Succès`),
        description: i18n._(t`Merci de consulter votre email`)
      });
    } catch (e) {
      toaster.error({
        title: i18n._(t`Erreur de création d’alerte`),
        description: i18n._(
          t`Un problème est survenu lors de la création d’alerte`
        )
      });
    }
  };
  onErrorHandler = msg => {
    const { i18n } = this.props;
    if (msg === 'Unexpected error value: "code invalide"') {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: i18n._(t`code invalide`)
      });
    } else {
      toaster.error({
        title: i18n._(t`Erreur`),
        description: msg
      });
    }
  };

  render() {
    const { sessionStore, i18n } = this.props;
    const { changeStep, code, authToken, account } = sessionStore;
    const { history } = this.props;
    const { email } = account;
    const { MyProfile } = Routes.HbLayoutRoutes;
    const { isCodeValid, codeValidationMessage } = this.state;
    const meta = staticMetaTags(i18n).signUp;
    changeStep(2);
    return (
      <ApolloConsumer>
        {client => (
          <div className="session container confirm">
            {metaTags(
              urls.signUp(),
              meta.title,
              meta.description,
              meta.imageUrl,
              meta.keywords
            )}
            <br />
            <Row>
              <div className="center">
                <br />
                <IllustrationIcons type="rocket" color2="#d3354a" />
              </div>
            </Row>
            <br />
            <FormCard className="formCard no-gutter">
              <Row className="with-padding">
                <Col lg={8} xl={8} className="center">
                  <br />
                  <span className="center-txt">
                    <span>
                      <Trans>Un</Trans>
                    </span>
                    <span> </span>
                    <span style={{ color: "#bf142a" }}>
                      <Trans>code de confirmation</Trans>
                    </span>
                    <span> </span>
                    <span>
                      <Trans>vous a été envoyé</Trans>
                    </span>
                    <span> </span>
                    <span style={{ color: "#bf142a" }}>
                      <Trans>par email</Trans>
                    </span>
                    <span> </span>
                    <Trans>pour poursuivre votre inscription.</Trans>
                  </span>
                  <br />
                  <span className="center-txt text-gray-bold">
                    <Trans>Pensez à consulter vos courriers indésirables</Trans>
                  </span>
                </Col>
              </Row>
              <br />
              <Divider />
              <br />
              <Row className="with-padding">
                <Col className="center " lg={10} xl={10} md={10} sm={10} xs={5}>
                  <FormGroup>
                    <Validation
                      errorMessage={codeValidationMessage}
                      valid={isCodeValid}
                    >
                      <TextInput
                        id="code"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Coller votre code ici`)}
                        // value={code}
                        onChange={this.codeChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                  <br />
                  <div style={{ margin: "0px auto" }}>
                    <span>
                      <Mutation
                        mutation={CODE_VERIFICATION}
                        variables={{ email, codeVerification: code }}
                        onCompleted={data => {
                          this.onNextStepHandler(data);
                        }}
                        refetchQueries={[
                          {
                            query: GET_USER_INFO,
                            variables: { token: authToken },
                            fetchPolicy: "network-only"
                          }
                        ]}
                        onError={errors => {
                          if (errors && errors.graphQLErrors) {
                            errors.graphQLErrors.forEach(({ message }) => {
                              if (message) {
                                this.onErrorHandler(message);
                              }
                            });
                          }
                        }}
                      >
                        {(mutation, { loading }) => (
                          <span>
                            {loading ? (
                              <Button disabled>
                                <Spinner
                                  type="pointed-circular"
                                  color="#FFFFFF"
                                  size={12}
                                />
                              </Button>
                            ) : (
                              <Button
                                icon="chevron-right"
                                onClick={
                                  () => mutation() // &&
                                }
                              >
                                <Trans>Confirmer</Trans>
                              </Button>
                            )}
                          </span>
                        )}
                      </Mutation>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="with-padding">
                <Col lg={8} xl={8} className="center">
                  <br />
                  <span className="center-txt">
                    <span>
                      <Trans>Vous n'avez pas reçu votre code ? </Trans>
                    </span>

                    <span
                      style={{
                        textDecoration: "underline",
                        color: "#d3354a",
                        cursor: "pointer",
                        marginLeft: "5px"
                      }}
                      onClick={() => {
                        this.clickHandler(client);
                      }}
                    >
                      <Trans>Renvoyer l’email de validation</Trans>
                    </span>
                  </span>
                </Col>
              </Row>
            </FormCard>
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

Confirm.wrappedComponent.propTypes = {
  sessionStore: PropTypes.shape({
    changeStep: PropTypes.func,
    changeCode: PropTypes.func,
    backRef: PropTypes.string,
    setBackRef: PropTypes.func,
    signUpUserRole: PropTypes.string,
    code: PropTypes.string,
    email: PropTypes.string,
    confirmationCode: PropTypes.string
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Confirm));
