import { t } from "@lingui/macro";
import configs from "../../configs/club-freelance-globals";
import runtimeVars from "../../configs/runTimeVars";

const defaultSourcer = i18n => {
  return {
    avatar: "/defaultAvatar.png",
    name: i18n._(t`inconnue`),
    position: i18n._(t`Sourcing Manager`),
    email: configs.contactEmail,
    phoneNumber: configs.phoneNumber[runtimeVars.APP_LANG],
    description: i18n._(t`Contact me to join our community!`),
    linkedIn: "https://www.linkedin.com/company/club-freelance/",
    headShot: "/defaultAvatar.png"
  };
};

export default defaultSourcer;
