import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Button, FormCard, H3 } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import classes from "./CandidateToClient.module.scss";
import Routes from "../layout/routes";

@inject("sessionStore", "candidatesSearchStore")
@observer
class CandidateToClient extends Component {
  disconnect = () => {
    const { sessionStore, history, candidatesSearchStore } = this.props;
    sessionStore.logout();
    candidatesSearchStore.clearSearch();
    history.push(Routes.HbLayoutRoutes.Login.path);
  };

  render() {
    const { sessionStore } = this.props;
    const { message, changeMessage } = sessionStore;
    const { i18n } = this.props;
    const text = message;
    if (message) changeMessage();
    const originalText = i18n._(
      t`Vous devez vous connecter avec un compte entreprise pour avoir poster un besoin`
    );

    return (
      <div className={classes.container}>
        <FormCard className={classes.card}>
          <H3 className={classes.heading}>
            <Trans>{text ? text : originalText}</Trans>
          </H3>
          <p>
            <Trans>
              En cliquant sur le bouton ci-dessous, vous serez déconnecté et
              redirigé vers la page de login
            </Trans>
          </p>
          <Button
            className={classes.disconnect}
            onClick={async () => await this.disconnect()}
          >
            <Trans>Se déconnecter</Trans>
          </Button>
        </FormCard>
      </div>
    );
  }
}

CandidateToClient.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    logout: PropTypes.func,
    changeMessage: PropTypes.func,
    message: PropTypes.string
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(CandidateToClient));
