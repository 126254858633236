import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import * as PropTypes from "prop-types";
import { Notification } from "cf-neo-ui";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import MY_TS_NOTIFS_QUERY from "./query";
import SET_N_TS_SEEN from "./mutation";
import { candidateNotisMessages, clientNotisMessages } from "./helpers";

@inject("sessionStore", "timesheetStore")
@observer
class Notifications extends Component {
  action = (type, timesheetId) => {
    const { history, timesheetStore, apolloClient } = this.props;
    const { changeTheTab } = timesheetStore;

    apolloClient.mutate({
      mutation: SET_N_TS_SEEN,
      variables: {
        timesheetID: Number.parseInt(timesheetId, 10),
        type
      }
    });

    if (
      type === "OPEN" ||
      type === "REMINDER" ||
      type === "REJECTED" ||
      type === "SUBMITTED"
    )
      history.push(`/dashboard/timesheets/${timesheetId}`);
    if (type === "REPORT_GENERATED" || type === "APPROVED") {
      changeTheTab("reports_tab");
      history.push(`/dashboard/timesheets`);
    }
  };

  render() {
    const { i18n, sessionStore } = this.props;
    const { account } = sessionStore;
    const isClient = account.role === "CLIENT";
    const queryTypes = isClient
      ? ["SUBMITTED", "REPORT_GENERATED"]
      : ["OPEN", "REJECTED", "APPROVED", "REPORT_GENERATED", "REMINDER"];
    return (
      <div>
        <Query
          query={MY_TS_NOTIFS_QUERY}
          variables={{ limit: 2, types: queryTypes }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) return null;
            const { myTsNotifs } = data;
            if (!myTsNotifs || !myTsNotifs.length) return null;
            return (
              <div>
                {myTsNotifs.map(notif => {
                  const notifMessages = isClient
                    ? clientNotisMessages(
                        i18n,
                        notif.month,
                        notif.year,
                        notif.placementID
                      )
                    : candidateNotisMessages(
                        i18n,
                        notif.month,
                        notif.year,
                        notif.placementID
                      );
                  const notifMessage = notifMessages[notif.type];
                  return (
                    <div key={notif._id}>
                      <Notification
                        notificationTitle={notifMessage.title}
                        text={notifMessage.message}
                        buttonLabel={notifMessage.buttonLabel}
                        onClickButton={() =>
                          this.action(notif.type, notif.timesheetID)
                        }
                      />
                      <br />
                    </div>
                  );
                })}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Notifications.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    changeTheTab: PropTypes.func
  }).isRequired,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Notifications));
