import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import * as PropTypes from "prop-types";
import { Icon } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import routes from "../../routes";
import HeaderMenu from "./header-menu/index";
import classes from "./BlogHeader.module.scss";

class BlogHeader extends Component {
  render() {
    const { location } = this.props;
    const activeAdvices =
      location.pathname.split("/")[2] === "advices" ? classes.activeHeader : "";
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.logo}>
            <NavLink to={routes.HbfLayoutRoutes.Home.path}>
              <picture>
                <source
                  type="image/webp"
                  media="(max-width: 1040px)"
                  srcSet="/assets/images/le_club_mobile.webp"
                />
                <source
                  type="image/png"
                  media="(max-width: 1040px)"
                  srcSet="/assets/images/le_club_mobile.png"
                />
                <source
                  type="image/webp"
                  media="(man-width: 1040px)"
                  srcSet="/assets/images/le_club.svg"
                />
                <img
                  src="/assets/images/le_club.svg"
                  alt="Mindquest"
                  style={{ height: "200px" }}
                />
                <img src="/assets/images/le_club.png" alt="club freelance" />
              </picture>
            </NavLink>
          </div>
          <div className={classes.headerItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeHeader}
              exact
              to={routes.BlogLayoutRoutes.Blog.path}
            >
              <Trans>Accueil</Trans>
            </NavLink>
          </div>
          <div className={classes.headerItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeHeader}
              exact
              to={routes.BlogLayoutRoutes.News.path}
            >
              <Trans>Actualité</Trans>
            </NavLink>
          </div>
          <div
            className={[classes.headerItem, classes.headerChildrenWrapper].join(
              " "
            )}
          >
            <span className={[classes.text, activeAdvices].join(" ")}>
              <Trans>Conseils pratiques</Trans>
            </span>
            <div className={classes.children}>
              <div className={classes.childCorrection}>
                <NavLink
                  className={classes.text}
                  activeClassName={classes.activeHeader}
                  exact
                  to={routes.BlogLayoutRoutes.AdvicesConsultant.path}
                >
                  <Trans>Consultant</Trans>
                </NavLink>
              </div>
              <div className={classes.childCorrection}>
                <NavLink
                  className={classes.text}
                  activeClassName={classes.activeHeader}
                  exact
                  to={routes.BlogLayoutRoutes.AdvicesEnterprise.path}
                >
                  <Trans>Entreprise</Trans>
                </NavLink>
              </div>
            </div>
          </div>
          <div className={classes.headerItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeHeader}
              exact
              to={routes.BlogLayoutRoutes.Decryption.path}
            >
              <Trans>Décryptages</Trans>
            </NavLink>
          </div>
          <div className={classes.headerItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeHeader}
              exact
              to={routes.BlogLayoutRoutes.SuccessStories.path}
            >
              <Trans>Success Stories</Trans>
            </NavLink>
          </div>
          <div className={classes.headerItem}>
            <NavLink
              className={classes.text}
              activeClassName={classes.activeHeader}
              exact
              to={routes.BlogLayoutRoutes.Search.path}
            >
              <Icon
                className={classes.searchIcon}
                type="search"
                width={23}
                height={23}
                color="#BBBABA"
              />
            </NavLink>
          </div>
          <div className={classes.barsItem}>
            <HeaderMenu />
          </div>
        </div>
      </div>
    );
  }
}

BlogHeader.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

export default withI18n()(withRouter(BlogHeader));
