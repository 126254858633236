import gql from "graphql-tag";

const FAV_MISSION = gql`
  mutation FavMission($jobOrderId: ID!) {
    favMission(jobOrderId: $jobOrderId) {
      id
    }
  }
`;

const UNFAV_MISSION = gql`
  mutation UnFavMission($jobOrderId: ID!) {
    unFavMission(jobOrderId: $jobOrderId) {
      id
    }
  }
`;

const DELETE_ALERT = gql`
  mutation DeleteAlert {
    deleteMissionAlert {
      id
    }
  }
`;

const SKIP_RECOMMENDED = gql`
  mutation SkipRecommended($id: ID!) {
    skipRecommended(id: $id) {
      id
    }
  }
`;

export { FAV_MISSION, UNFAV_MISSION, DELETE_ALERT, SKIP_RECOMMENDED };
