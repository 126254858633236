import { observable, action, computed, toJS } from "mobx";
import { symmetricDifference } from "../utils/helpers";

const noop = () => {};

export default class CandidatesSearchStore {
  @observable sortByScore;

  @observable sortByDistance;

  @observable limit;

  @observable page;

  @observable search;

  @observable id;

  @observable categories;

  @observable categoryOptions;

  @observable categoryInitOptions;

  @observable skills;

  @observable skillOptions;

  @observable skillInitOptions;

  @observable businessSectors;

  @observable businessSectorOptions;

  @observable businessSectorInitOptions;

  @observable specialties;

  @observable specialtyOptions;

  @observable specialtyInitOptions;

  @observable locations;

  @observable radius;

  @observable startDate;

  @observable startDateRange;

  @observable workType;

  @observable employmentPreference;

  @observable onSite;

  @observable resultsCount;

  @observable isAlertModalOpen;

  @observable isSideFilterOpen;

  @observable step;

  @observable fSkills;

  @observable fRadius;

  @observable fEmploymentPreference;

  @observable fLocations;

  @observable fExperience;

  @observable jobDescription;

  @observable description;

  @observable words;

  @observable primarySkills;

  @observable secondarySkills;

  @observable zipCode;

  @observable firstSearch;

  @observable disabled;

  @observable experience;

  @observable skillsSelectorData;

  constructor(initialState = {}) {
    this.search = initialState.search || "";
    this.sortByScore = initialState.sortByScore || "score_DESC";
    this.sortByDistance = initialState.sortByDistance || "distance_ASC";
    this.limit = initialState.limit || 12;
    this.page = initialState.page || 1;
    this.id = initialState.id || "";
    this.categories = initialState.categories || [];
    this.categoryOptions = initialState.categoryOptions || [];
    this.categoryInitOptions = initialState.categoryInitOptions || [];
    this.skills = initialState.skills || [];
    this.skillInitOptions = initialState.skillInitOptions || [];
    this.businessSectors = initialState.businessSectors || [];
    this.businessSectorOptions = initialState.businessSectorOptions || [];
    this.businessSectorInitOptions =
      initialState.businessSectorInitOptions || [];
    this.specialties = initialState.specialties || [];
    this.specialtyOptions = initialState.specialtyOptions || [];
    this.specialtyInitOptions = initialState.specialtyInitOptions || [];
    this.locations = initialState.locations || [];
    this.radius = initialState.radius || null;
    this.startDate = initialState.startDate || "";
    this.startDateRange = initialState.startDateRange || 0;
    this.workType = initialState.workType || "";
    this.employmentPreference = initialState.employmentPreference || null;
    this.experience = initialState.experience || null;
    this.onSite = initialState.onSite || "";
    this.resultsCount = initialState.resultsCount || 0;
    this.isAlertModalOpen = initialState.isAlertModalOpen || false;
    this.isSideFilterOpen = initialState.isSideFilterOpen || false;
    this.step = initialState.step || 1;
    this.fSkills = initialState.fSkills || [];
    this.fRadius = initialState.fRadius || null;
    this.fEmploymentPreference = initialState.fEmploymentPreference || null;
    this.fExperience = initialState.fExperience || null;
    this.fLocations = initialState.fLocations || [];
    this.jobDescription = initialState.jobDescription || "";
    this.description = initialState.description || "";
    this.words = initialState.words || [];
    this.primarySkills = initialState.primarySkills || [];
    this.secondarySkills = initialState.secondarySkills || [];
    this.zipCode = initialState.zipCode || null;
    this.firstSearch = initialState.firstSearch || true;
    this.disabled = initialState.disabled || false;
    this.skillsSelectorData = initialState.skillsSelectorData || null;
  }

  @computed
  get skip() {
    return (this.page - 1) * this.limit;
  }
  @computed
  get printResultsCount() {
    const categoriesJSArray = this.categories.toJS();
    const skillsJSArray = this.skills.toJS();
    const businessSectorsJSArray = this.businessSectors.toJS();
    const specialtiesSectorsJSArray = this.specialties.toJS();
    return (
      this.id ||
      this.search ||
      this.startDate ||
      categoriesJSArray.length > 0 ||
      skillsJSArray.length > 0 ||
      businessSectorsJSArray.length > 0 ||
      specialtiesSectorsJSArray.length > 0 ||
      this.cityLabels.length > 0
    );
  }

  @computed
  get canOpenAlertModal() {
    const categoriesJSArray = this.categories.toJS();
    const skillsJSArray = this.skills.toJS();
    const businessSectorsJSArray = this.businessSectors.toJS();
    const specialtiesSectorsJSArray = this.specialties.toJS();
    return (
      categoriesJSArray.length ||
      skillsJSArray.length ||
      businessSectorsJSArray.length ||
      specialtiesSectorsJSArray.length ||
      this.cityLabels.length ||
      this.workType ||
      this.employmentPreference ||
      this.onSite
    );
  }

  @computed
  get cityLabels() {
    const locationsJSArray = this.locations.toJS();
    return locationsJSArray.map(item => item.city);
  }

  @action
  changeSortByScore = (sorter, direction) => {
    this.sortByScore = sorter
      .toString()
      .concat("_")
      .concat(direction.toString());
  };

  @action
  changeSortByDistance = (sorter, direction) => {
    this.sortByDistance = sorter
      .toString()
      .concat("_")
      .concat(direction.toString());
  };

  @action
  changePage = current => {
    this.page = current;
  };

  @action
  changeFilterAppliqued = () => {
    this.page = 1;
    this.fSkills = this.skills;
    this.fEmploymentPreference = this.employmentPreference;
    this.fExperience = this.experience;
    this.fLocations = this.locations;
    this.fRadius = this.radius;
  };

  @action
  changeStep = step => {
    this.page = 1;
    this.step = step;
  };

  @action
  changeFirstSearch = firstSearch => {
    this.page = 1;
    this.firstSearch = firstSearch;
  };

  @action
  changeDisabled = disabled => {
    this.page = 1;
    this.disabled = disabled;
  };

  @action
  changeWords = words => {
    this.page = 1;
    this.words = words;
  };

  @action
  changePrimarySkills = primarySkills => {
    this.page = 1;
    this.primarySkills = primarySkills;
  };

  @action
  changeSecondarySkills = secondarySkills => {
    this.page = 1;
    this.secondarySkills = secondarySkills;
  };

  @action
  changeZipCode = zipCode => {
    this.page = 1;
    this.zipCode = zipCode;
  };

  @action
  changeSkillsSelectorData = skillsSelectorData => {
    this.skillsSelectorData = skillsSelectorData;
  };

  @action
  searchSubmit = value => {
    if (value[0] === "#") {
      this.id = value.substring(1);
      this.search = "";
      this.page = 1;
    } else {
      this.id = "";
      this.sortBy = null;
      this.search = value;
      this.page = 1;
    }
  };

  @action
  changeResultsCount = value => {
    this.resultsCount = value;
  };

  @action
  removeCategory = value => {
    // the remove function here is a MobX observable array built in function
    this.categories.remove(value);
    this.page = 1;
  };

  @action
  addCategory = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html
    this.page = 1;
    const arr = this.categories.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.categories = arr;
      this.setCategoryOptions();
    }
  };

  @action
  initCategoryOptions = options => {
    this.categoryInitOptions = options;
    this.setCategoryOptions();
  };

  @action
  setCategoryOptions = () => {
    const categoriesJSArray = this.categories.toJS();
    const categoryInitJSArray = this.categoryInitOptions.toJS();

    this.categoryOptions = symmetricDifference(
      categoryInitJSArray,
      categoriesJSArray
    );
  };

  @action
  removeSkill = value => {
    // the remove function here is a MobX observable array built in function
    const arr = this.skills.slice();
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    this.skills = arr;
    this.page = 1;
    this.setSkillOptions();
  };

  @action
  addSkill = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html
    this.page = 1;
    const arr = this.skills.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.skills = arr;
      this.setSkillOptions();
    }
  };

  @action
  initSkillOptions = options => {
    this.skillInitOptions = options;
    this.setSkillOptions();
  };

  @action
  setSkillOptions = () => {
    const skillsJSArray = this.skills.toJS();
    const skillInitJSArray = this.skillInitOptions.toJS();

    this.skillOptions = symmetricDifference(skillInitJSArray, skillsJSArray);
  };

  @action
  removeBusinessSector = value => {
    // the remove function here is a MobX observable array built in function
    this.businessSectors.remove(value);
    this.page = 1;
  };

  @action
  addBusinessSector = value => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html
    this.page = 1;
    const arr = this.businessSectors.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.businessSectors = arr;
      this.setBusinessSectorOptions();
    }
  };

  @action
  initBusinessSectorOptions = options => {
    this.businessSectorInitOptions = options;
    this.setBusinessSectorOptions();
  };

  @action
  setBusinessSectorOptions = () => {
    const businessSectorsJSArray = this.businessSectors.toJS();
    const businessSectorInitJSArray = this.businessSectorInitOptions.toJS();

    this.businessSectorOptions = symmetricDifference(
      businessSectorInitJSArray,
      businessSectorsJSArray
    );
  };

  @action
  removeSpecialty = value => {
    this.specialties.remove(value);
    this.page = 1;
  };

  @action
  addSpecialty = value => {
    this.page = 1;
    const arr = this.specialties.slice();
    if (!arr.includes(value)) {
      arr.push(value);
      this.specialties = arr;
      this.setSpecialtyOptions();
    }
  };

  @action
  initSpecialtyOptions = options => {
    this.specialtyInitOptions = options;
    this.setSpecialtyOptions();
  };

  @action
  setSpecialtyOptions = () => {
    const specialtiesJSArray = this.specialties.toJS();
    const specialtyInitJSArray = this.specialtyInitOptions.toJS();

    this.specialtyOptions = symmetricDifference(
      specialtyInitJSArray,
      specialtiesJSArray
    );
  };

  @action
  addLocation = address => {
    // NB: MobX observable arrays are not JS arrays. Therefore the use of slice
    // before applying certain function as push()
    // https://mobx.js.org/best/pitfalls.html

    const { city } = address;

    const formattedCity = city.slice(" ");
    if (!Number.isNaN(formattedCity[0])) {
      // This line can be optimized by removing the indexOf method
      address.city = city.substr(city.indexOf(" ") + 1);
    }
    this.page = 1;
    const arr = this.locations.slice();
    const cityArr = arr.map(item => item.city);
    if (!cityArr.includes(city)) {
      arr.push(address);
      this.locations = arr;
    }
  };

  @action
  removeLocation = city => {
    const arr = toJS(this.locations);
    const newArr = arr.filter(item => item.city !== city);

    this.page = 1;
    this.locations = newArr;
  };

  @action
  setRadius = val => {
    this.radius = val;
  };

  @action
  changeStartDate = value => {
    this.startDate = value;
  };

  @action
  changeStartDateRange = value => {
    this.page = 1;
    this.startDateRange = value;
  };

  @action
  changeWorkType = value => {
    this.page = 1;
    this.workType = value;
  };

  @action
  changeEmploymentPreference = value => {
    this.page = 1;
    this.employmentPreference = value.value;
  };

  @action
  changeExperience = value => {
    this.page = 1;
    this.experience = value.value;
  };

  @action
  changeOnSite = value => {
    this.page = 1;
    this.onSite = value;
  };

  @action
  changeJobDescription = value => {
    this.page = 1;
    this.jobDescription = value;
  };

  @action
  changeDescription = value => {
    this.page = 1;
    this.description = value;
  };

  @action
  addToFilters = (val, filter) => {
    switch (filter) {
      case "categories":
        this.addCategory(val);
        break;
      case "skills":
        this.addSkill(val);
        break;
      default:
        noop();
    }
  };

  @action
  setAlertModal = () => {
    this.isAlertModalOpen = !this.isAlertModalOpen;
  };

  @action
  setSideFilter = () => {
    this.isSideFilterOpen = !this.isSideFilterOpen;
  };

  @action
  clearFilters = () => {
    this.page = 1;
    this.skills = [];
    this.categories = [];
    this.specialties = [];
    this.locations = [];
    this.radius = null;
    this.businessSectors = [];
    this.startDate = "";
    this.workType = "";
    this.employmentPreference = null;
    this.experience = null;
    this.onSite = "";
    this.fSkills = [];
    this.fEmploymentPreference = null;
    this.fExperience = null;
    this.fLocations = [];
    this.fRadius = null;
    this.sortByScore = "score_DESC";
    this.sortByDistance = "distance_ASC";
  };

  @action
  clearSearch = () => {
    this.page = 1;
    this.skills = [];
    this.categories = [];
    this.specialties = [];
    this.locations = [];
    this.radius = null;
    this.businessSectors = [];
    this.startDate = "";
    this.workType = "";
    this.employmentPreference = null;
    this.experience = null;
    this.onSite = "";
    this.fSkills = [];
    this.fEmploymentPreference = null;
    this.fExperience = null;
    this.fLocations = [];
    this.fRadius = null;
    this.step = 1;
    this.sortByScore = "score_DESC";
    this.sortByDistance = "distance_ASC";
    this.jobDescription = "";
    this.description = "";
    this.words = [];
    this.primarySkills = [];
    this.secondarySkills = [];
    this.zipCode = null;
    this.firstSearch = true;
    this.disabled = false;
    this.skillsSelectorData = null;
  };
}
