import React, { Component } from "react";
import * as PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import { ApolloConsumer } from "react-apollo";
import Header from "./header";
import classes from "./styles.module.scss";
import Footer from "../footer/Footer";
import CookiesConsent from "../../../components/CookiesConsent/CookiesConsent";

class HbfLayout extends Component {
  render() {
    const { component } = this.props;
    const RenderComponent = component;
    return (
      <div className="layout">
        <Fade big>
          <div className={classes["sticky-top-header"]}>
            <ApolloConsumer>
              {client => <Header apolloClient={client} />}
            </ApolloConsumer>
          </div>
        </Fade>
        <RenderComponent />
        <Footer />
        <CookiesConsent />
      </div>
    );
  }
}

HbfLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
};

export default HbfLayout;
