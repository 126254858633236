import React, { Component } from "react";
import { Col, Container, Row } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import classes from "./banner.module.scss";

class Banner extends Component {
  render() {
    return (
      <footer>
        <div className={classes.bgImg}>
          <picture>
            <source type="image/webp" srcSet="/assets/images/footerbg.webp" />
            <img src="/assets/images/footerbg.png" alt="Club Freelance logo" />
          </picture>
        </div>
        <Container className={classes.container}>
          <div className={classes.content}>
            <h1>
              <Trans>Nos chiffres clefs</Trans>
            </h1>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <span>40</span>
                <p>
                  <Trans>Collaborateurs à temps plein</Trans>
                </p>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <span>3</span>
                <p>
                  <Trans>Localisations stratégiques</Trans>
                </p>
              </Col>
              <Col xs={12} sm={3} md={3} lg={3}>
                <span>21 M</span>
                <p>
                  <Trans>Revenue</Trans>
                </p>
              </Col>
              <Col xs={12} sm={3} md={3} lg={2}>
                <span>1000+</span>
                <p>
                  <Trans>Placements réussis chez nos clients</Trans>
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Banner;
