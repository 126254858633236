import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import {
  Row,
  Col,
  FormGroup,
  Button,
  Spinner,
  TextSkeleton,
  InputSkeleton
} from "cf-neo-ui";
import "./styles.scss";

class Skeleton extends Component {
  render() {
    return (
      <div className="profile my-information">
        <div className="formCard no-gutter with-padding">
          <Row>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="expertise">
                  <TextSkeleton />
                </label>
                <InputSkeleton />
              </FormGroup>
            </Col>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="expertise">
                  <TextSkeleton />
                </label>
                <InputSkeleton />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="expertise">
                  <TextSkeleton />
                </label>
                <InputSkeleton />
              </FormGroup>
            </Col>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="expertise">
                  <TextSkeleton />
                </label>
                <InputSkeleton />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="expertise">
                  <TextSkeleton />
                </label>
                <InputSkeleton />
              </FormGroup>
            </Col>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="expertise">
                  <TextSkeleton />
                </label>
                <InputSkeleton />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6} md={6} sd={6}>
              <FormGroup>
                <label className="form-label" htmlFor="expertise">
                  <TextSkeleton />
                </label>
                <InputSkeleton />
              </FormGroup>
            </Col>
          </Row>
          <br />

          <div className="next-buttons">
            <Button disabled>
              <Spinner type="pointed-circular" color="#FFFFFF" size={12} />
              <Trans>Enregistrer</Trans>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Skeleton.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};
export default withI18n()(Skeleton);
