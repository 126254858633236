import gql from "graphql-tag";

const DistanceMatrix = gql`
  query SingleMissionQuery(
    $missionId: ID!
    $address: AddressInput!
    $params: ParamsInput
  ) {
    distanceMatrix(missionId: $missionId, address: $address, params: $params) {
      status
      error
      rows {
        elements {
          status
          duration {
            value
            text
          }
          distance {
            value
            text
          }
        }
      }
    }
  }
`;
export default DistanceMatrix;
