import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as PropTypes from "prop-types";
import { inject, observer } from "mobx-react/index";
import { Mutation, Query } from "react-apollo";
import { Divider, SourcerContactCard, toaster } from "cf-neo-ui";
import { withI18n } from "@lingui/react/index";
import { t } from "@lingui/macro";
import { CREATE_MISSION_APPLICATION } from "../../mutations";
import { USER_APPLICATIONS_QUERY } from "../../query";
import Routes from "../../../layout/routes";
import routes from "../../../layout/routes/hbLayoutRoutes";
import classes from "./LeftSlide.module.scss";
import DistanceMatrix from "./distance-matrix/DistanceMatrix";

@inject("sessionStore")
@observer
class LeftSlide extends Component {
  constructor(props) {
    super(props);
    this.listKey = 0;
  }

  isBtnDisabled = userApplications => {
    const { id } = this.props;
    const arr = userApplications.filter(
      application => application.jobOrderId === id
    );
    return !!arr.length;
  };

  linkedInIconHandler = url => {
    window.open(url, "_blank");
  };

  toLogin = () => {
    const { id, sessionStore, history } = this.props;
    sessionStore.setBackRef(id);
    history.push(`${Routes.HbLayoutRoutes.Login.path}?origin=apply`);
  };

  contact = () => {
    const { sessionStore, sourcer, history } = this.props;
    sessionStore.setContactPageEmail({
      email: sourcer.email,
      name: sourcer.name
    });
    history.push(routes.contact.path);
  };

  mutationCompleted = () => {
    const { sessionStore, i18n } = this.props;
    toaster.success({
      title: i18n._(t`Candidature créée avec succès`),
      description: i18n._(
        t`Vous pouvez suivre vos candidatures dans votre profil`
      ),
      id: "application_success"
    });
    sessionStore.setJobOrderReferer(null);
  };

  displaySourcerCard = i18n => {
    const { sessionStore, sourcer, id, loading: loadingGeneral } = this.props;
    let iconClickHandler = null;

    if (sourcer.linkedIn) {
      iconClickHandler = () => this.linkedInIconHandler(sourcer.linkedIn);
    }

    if (sessionStore.authToken && sessionStore.account.role === "CANDIDATE") {
      return loadingGeneral ? null : (
        <Query query={USER_APPLICATIONS_QUERY} variables={{ jobOrderId: id }}>
          {({ loading, error: queryError, data }) => {
            if (queryError) return null;
            if (loading) return null;

            const { userApplications } = data;
            return (
              <Mutation
                mutation={CREATE_MISSION_APPLICATION}
                onCompleted={this.mutationCompleted}
                onError={error => {
                  const toastObj = {
                    title: i18n._(t`Erreur de création de candidature`),
                    description: i18n._(
                      t`Un problème est survenu lors de la création de votre candidature`
                    )
                  };
                  if (
                    error.graphQLErrors[0].message ===
                    "Application already exists"
                  ) {
                    toastObj.title = i18n._(
                      t`Vous avez déjà postulé pour cette mission`
                    );
                    toastObj.description = i18n._(
                      t`Vous pouvez suivre vos candidatures dans votre profil`
                    );
                  }
                  toaster.error(toastObj);
                }}
                ignoreResults
                refetchQueries={[{ query: USER_APPLICATIONS_QUERY }]}
              >
                {createMissionApplication => (
                  <SourcerContactCard
                    avatar={sourcer.avatar}
                    name={sourcer.name}
                    position={sourcer.position}
                    email={sourcer.email}
                    emailClick={this.contact}
                    phoneNumber={sourcer.phoneNumber}
                    description={sourcer.description}
                    onApply={() =>
                      createMissionApplication({
                        variables: {
                          jobOrderId: id,
                          origin:
                            sessionStore.jobOrderReferer || "website navigation"
                        }
                      })
                    }
                    applyButtonDisabled={this.isBtnDisabled(userApplications)}
                    onIconClick={iconClickHandler}
                    applyButtonLabel={
                      this.isBtnDisabled(userApplications)
                        ? i18n._(t`Postulé`)
                        : i18n._(t`Postuler`)
                    }
                  />
                )}
              </Mutation>
            );
          }}
        </Query>
      );
    }
    return loadingGeneral ? null : (
      <SourcerContactCard
        avatar={sourcer.avatar}
        name={sourcer.name}
        position={sourcer.position}
        email={sourcer.email}
        emailClick={this.contact}
        phoneNumber={sourcer.phoneNumber}
        description={sourcer.description}
        onApply={this.toLogin}
        onIconClick={iconClickHandler}
        applyButtonLabel={i18n._(t`Postuler`)}
        applyButtonDisabled={
          sessionStore.authToken && sessionStore.account.role === "CLIENT"
        }
      />
    );
  };

  render() {
    const { id, i18n, className } = this.props;
    return (
      <div className={[classes.slideBar, className].join(" ")}>
        <section>{this.displaySourcerCard(i18n)}</section>
        <Divider />
        <section>
          <DistanceMatrix missionId={id} />
        </section>
      </div>
    );
  }
}

LeftSlide.wrappedComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  sourcer: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    description: PropTypes.string,
    linkedIn: PropTypes.string
  }).isRequired,
  id: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.shape({
      role: PropTypes.string
    }),
    jobOrderReferer: PropTypes.string,
    setBackRef: PropTypes.func,
    setContactPageEmail: PropTypes.func,
    setJobOrderReferer: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  className: PropTypes.string
};

LeftSlide.defaultProps = {
  className: ""
};

export default withI18n()(withRouter(LeftSlide));
