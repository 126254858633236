import runtimeVars from "./runTimeVars";

const { DOMAIN } = runtimeVars;

// expiration date for the cookie: 1 year
const after1year = new Date();
after1year.setFullYear(after1year.getFullYear() + 1);

const cookieSaveOpt = {
  path: "/",
  sameSite: false,
  domain: DOMAIN,
  expires: after1year
};
const cookieRemoveOpt = {
  path: "/",
  domain: DOMAIN
};
export { cookieSaveOpt, cookieRemoveOpt };
