import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { H4, BlogPosterSkeleton } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { Query } from "react-apollo";
import Slider from "./slider/Slider";
import PlusButton from "../plus-button/PlusButton";
import DisplayBlogCards from "../blog-cards-display/BlogCardsDisplay";
import LoadingBlogCards from "../blog-cards-display/LoadingBlogCardDisplay";
import TwitterPosts from "./twitter-card/TwitterCard";
import DisplayBlogVideos from "../blog-videos-display/VideosDisplay";
import LoadingBlogVideos from "../blog-videos-display/LoadingVideosDisplay";
import Books from "../../../components/testimonial-carousel";
import classes from "./MainBlog.module.scss";
import decode from "../../../utils/Utf8Text";
import Routes from "../../layout/routes";

import { latestPostsQuery, categoriesRefFr } from "../query";
import staticPagesMetaTags from "../../../configs/staticPagesMetaTags";
import metaTags from "../../../utils/editMetaData";
import urls from "../../../utils/urls";
import ldJson from "./ldJson";

class MainBlog extends Component {
  constructor(props) {
    super(props);
    this.latestPostsPage = 2;
    this.latestVideoPage = 1;
    this.state = {
      render: false
    };
  }

  componentDidMount() {
    this.setState({ render: true });
    window.scroll(0, 0);
  }

  showMoreVideos = fetchMore => {
    this.latestVideoPage += 1;
    return fetchMore({
      fetchPolicy: "cache-and-network",
      query: latestPostsQuery,
      variables: {
        notifyOnNetworkStatusChange: true,
        page: this.latestVideoPage,
        categoryIds: categoriesRefFr.videos,
        perPage: 2
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, posts: [...prev.posts, ...fetchMoreResult.posts] };
      }
    });
  };

  showMoreArticles = fetchMore => {
    this.latestPostsPage += 1;
    return fetchMore({
      fetchPolicy: "cache-and-network",
      query: latestPostsQuery,
      variables: {
        notifyOnNetworkStatusChange: true,
        page: this.latestPostsPage,
        excludeCategories: `${categoriesRefFr.videos},${categoriesRefFr.featured},${categoriesRefFr.resources}`,
        perPage: 2
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, posts: [...prev.posts, ...fetchMoreResult.posts] };
      }
    });
  };

  toResources = id => {
    const { history } = this.props;
    history.push(`/blog/resources/${id}`);
  };

  postsToBooks = posts => {
    const { i18n } = this.props;
    const books = [];
    posts.map(post =>
      books.push({
        id: post.id,
        image: post.featured_media && post.featured_media.source_url,
        name: "",
        job: "",
        paragraph: decode(post.title),
        buttonText: i18n._(t`Accéder à la ressource`),
        onClickButton: () => this.toResources(post.id)
      })
    );
    return books;
  };

  render() {
    const { i18n } = this.props;
    const { render } = this.state;
    const meta = staticPagesMetaTags(i18n).blog.main;

    return (
      <div>
        {metaTags(
          urls.blog(),
          meta.title,
          meta.description,
          meta.imageUrl,
          meta.keywords,
          ldJson
        )}
        <div className={classes.limitedBody}>
          <section>
            {render ? (
              <Query
                query={latestPostsQuery}
                variables={{
                  categoryIds: categoriesRefFr.featured
                }}
              >
                {({ loading, error, data }) => {
                  if (error || loading)
                    return (
                      <BlogPosterSkeleton className={classes.loadingFeatured} />
                    );
                  return <Slider slides={data.posts} />;
                }}
              </Query>
            ) : (
              <BlogPosterSkeleton className={classes.loadingFeatured} />
            )}
          </section>
          <section className={classes.inline}>
            <section>
              <H4>Club Freelance</H4>
              <p className={classes.paragraph}>
                <Trans>
                  Analyse les tendances et évolutions du monde des talents tech
                  & IT.
                </Trans>
              </p>
              <H4 className={classes.cardsHeading}>
                <NavLink
                  to={Routes.HbfLayoutRoutes.about.path}
                  className={classes.cardsHeading}
                >
                  <Trans>Découvrir Club Freelance</Trans>
                </NavLink>
              </H4>
              <Query
                query={latestPostsQuery}
                variables={{
                  page: 1,
                  excludeCategories: `${categoriesRefFr.videos},${categoriesRefFr.featured},${categoriesRefFr.resources}`,
                  perPage: 4
                }}
                fetchPolicy="cache-and-network"
              >
                {// eslint-disable-next-line no-unused-vars
                ({ loading, error, data, fetchMore, networkStatus }) => {
                  if (error)
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <span>
                          Oups! il semblerait que vos données aient du mal à se
                          synchroniser. Veuillez rafraichir la page dans un
                          moment
                        </span>
                        <img src={"/assets/svg/download.svg"} alt="error" />
                      </div>
                    );
                  if (!loading && !data.posts.length)
                    return (
                      <div className={classes.carouselHloder}>
                        <Trans>
                          Il n’y a pas des posts pour le moment dans cette
                          section
                        </Trans>
                      </div>
                    );
                  if (networkStatus === 1)
                    return <LoadingBlogCards cardsNumber={4} />;
                  return (
                    <div>
                      {data && <DisplayBlogCards articles={data.posts} />}
                      {networkStatus === 3 && (
                        <LoadingBlogCards cardsNumber={2} />
                      )}
                      {data &&
                        data.posts[0] &&
                        this.latestPostsPage < data.posts[0].totalPages && (
                          <PlusButton
                            onClick={() => this.showMoreArticles(fetchMore)}
                          >
                            <Trans>Voir plus d’articles</Trans>
                          </PlusButton>
                        )}
                    </div>
                  );
                }}
              </Query>
            </section>
            <TwitterPosts className={classes.tweets} />
          </section>
          <section>
            <Query
              query={latestPostsQuery}
              variables={{
                page: 1,
                perPage: 2,
                categoryIds: categoriesRefFr.videos
              }}
              fetchPolicy="cache-and-network"
            >
              {({ data, fetchMore, networkStatus }) => {
                if (data && data.posts.length)
                  return (
                    <div>
                      <H4>
                        <Trans>Vidéos</Trans>
                      </H4>
                      <p className={classes.paragraph}>
                        <Trans>Nos conseils en vidéo</Trans>
                      </p>
                      <DisplayBlogVideos videos={data.posts} />
                      {networkStatus === 3 && (
                        <LoadingBlogVideos cardsNumber={2} />
                      )}
                      {this.latestVideoPage < data.posts[0].totalPages && (
                        <PlusButton
                          onClick={() => this.showMoreVideos(fetchMore)}
                        >
                          <Trans>Voir plus de vidéos</Trans>
                        </PlusButton>
                      )}
                    </div>
                  );
                return null;
              }}
            </Query>
          </section>
        </div>
        <Query
          query={latestPostsQuery}
          variables={{
            page: 1,
            perPage: 6,
            categoryIds: categoriesRefFr.resources
          }}
        >
          {({ data }) => {
            if (data && data.posts.length) {
              return (
                <div style={{ marginBottom: "30px" }}>
                  <div className={classes.limitedBody}>
                    <section>
                      <H4>
                        <Trans>Ressources</Trans>
                      </H4>
                      <p className={classes.paragraph}>
                        <Trans>À télécharger gratuitement</Trans>
                      </p>
                    </section>
                  </div>
                  <Books
                    testimonials={this.postsToBooks(data.posts)}
                    pLines={2}
                  />
                </div>
              );
            }
            return null;
          }}
        </Query>
      </div>
    );
  }
}

MainBlog.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(MainBlog));
