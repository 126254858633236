import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { IllustrationIcons } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import Routes from "../routes/index";
import "./styles.scss";
import theme from "../../../configs/theme";

class RightSide extends Component {
  render() {
    const { location } = this.props;
    const {
      MyInformationClient,
      MyCompany,
      MyNeed,
      Offer,
      Offer2,
      DesiredProfile
    } = Routes.HbLayoutRoutes;
    const {
      MyProfile,
      MyJob,
      MyExperience,
      MySearch,
      MyInformation
    } = Routes.Hb2LayoutRoutes;
    return (
      <div className="right-side">
        <div className="illustration-icons">
          <br />
          <IllustrationIcons
            type="search"
            color2={theme.colors.mainColorGradient}
          />
        </div>
        <div className="center-txt text-bold">
          <Trans>Bon à savoir</Trans>!{" "}
        </div>
        <br />
        <div className="center-txt text-normal">
          {location.pathname === MyNeed.path && (
            <div>
              <Trans>
                A fin de faciliter les recherches pensez à indiquer dans votre
                fiche de poste:
              </Trans>
              <li>
                <Trans>Un intitulé de poste</Trans>
              </li>
              <li>
                <Trans>Le type de contrat (CDI /Freelance)</Trans>
              </li>
              <li>
                <Trans>La localisation</Trans>
              </li>
              <li>
                <Trans>Les dates de démarrages et de fin contrat</Trans>
              </li>
              <li>
                <Trans>Le contexte du besoin</Trans>
              </li>
              <li>
                <Trans>Les mission à réaliser</Trans>
              </li>
              <li>
                <Trans>
                  Le profil recherché (compétences, séniorité,
                  certifications...)
                </Trans>
              </li>
              <li>
                <Trans>Le budget / package</Trans>
              </li>
            </div>
          )}
          {location.pathname === Offer.path && (
            <div>
              <Trans>
                Maximisez vos chances en indiquant un intitulé clair et
                identifiable
              </Trans>
            </div>
          )}
          {location.pathname === Offer2.path && (
            <div>
              <Trans>
                Les éléments de contexte comme la phase du projet, la
                composition des équipes, ou encore l’organisation interne nous
                aident mieux comprendre vos recherches
              </Trans>
            </div>
          )}

          {location.pathname === DesiredProfile.path && (
            <div>
              <Trans>Triez les compétances par ordre d’importance</Trans>
            </div>
          )}
          {location.pathname === MyProfile.path && (
            <div>
              <Trans>
                Un cv actualisé au format Word facilitera les échanges avec nos
                équipes
              </Trans>
            </div>
          )}
          {location.pathname === MyJob.path && (
            <div>
              <Trans>
                Maximisez vos changes en indiquant un intitulé clair et
                identifiable
              </Trans>
            </div>
          )}
          {location.pathname === MyExperience.path && (
            <div>
              <Trans>
                Ajoutez vos dernières expériences pour nous aider à vous trouver
                un job qui vous correspond. N’oubliez pas de renseigner vos
                références pour un process plus rapide
              </Trans>
            </div>
          )}
          {location.pathname === MySearch.path && (
            <div>
              <Trans>
                Bien que la tendance évolue, le travail en régie est toujours la
                norme dans de nombreuses entreprises. Choissiez
                &quot;indifferent&quot; pour ne manquer aucune opportunité.
              </Trans>
            </div>
          )}
          {location.pathname === MyInformation.path && (
            <div>
              <Trans>
                Notre newsletter bimensuelle comporte nos derniers articles, nos
                plus belles missions et du contenu spécialement sélectionné pour
                vous
              </Trans>
            </div>
          )}
          {location.pathname === MyCompany.path && (
            <div>
              <Trans>
                Un Key Account Manager dédié vous guidera dans vos projets
              </Trans>
            </div>
          )}
          {location.pathname === MyInformationClient.path && (
            <div>
              <Trans>
                Un Key Account Manager dédié vous guidera dans vos projets
              </Trans>
            </div>
          )}
        </div>
      </div>
    );
  }
}

RightSide.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

export default withRouter(RightSide);
