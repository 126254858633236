import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { TagList, ProgressBar } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { Trans } from "@lingui/macro";
import classes from "../SideFilters.module.scss";

@inject("candidatesSearchStore")
@observer
class LocationWidget extends Component {
  selectLocationHandler = (address, formattedAddress) => {
    const { candidatesSearchStore } = this.props;
    const { addLocation } = candidatesSearchStore;
    addLocation(formattedAddress);
  };

  closeTagHandler = label => {
    const { candidatesSearchStore } = this.props;
    const { removeLocation } = candidatesSearchStore;
    removeLocation(label);
  };

  render() {
    const { candidatesSearchStore } = this.props;
    const { setRadius, radius } = candidatesSearchStore;
    return (
      <div>
        <span>
          <p className={classes.widgetSubtitle}>
            <Trans>Dans un rayon de</Trans> {radius ? radius : 999} km
          </p>
          <ProgressBar
            min={0}
            max={999}
            step={10}
            defaultValue={radius ? radius : 999}
            unit="Km"
            onChange={val => setRadius(val)}
          />
        </span>
      </div>
    );
  }
}

LocationWidget.wrappedComponent.propTypes = {
  candidatesSearchStore: PropTypes.shape({
    cityLabels: PropTypes.shape,
    addLocation: PropTypes.func,
    removeLocation: PropTypes.func,
    setRadius: PropTypes.func,
    radius: PropTypes.number
  }).isRequired
};

export default LocationWidget;
