import runtimeVars from "../configs/runTimeVars";
import profile from "../containers/dashboard/profile";
import DashboardMissions from "../containers/dashboard-missions";
import DashboardSingleMission from "../containers/dashboard-single-mission";
//  TODO solve circle import & use src/containers/layout/routes/
const BlogLayoutRoutes = {
  Blog: { path: "/blog" },
  SingleBlog: { path: "/blog/news/:id" },
  News: { path: "/blog/news" },
  AdvicesConsultant: { path: "/blog/advices/consultant" },
  AdvicesEnterprise: { path: "/blog/advices/enterprise" },
  Decryption: { path: "/blog/decryption" },
  SuccessStories: { path: "/blog/stories" },
  Search: { path: "/blog/search" },
  Filter: { path: "/blog/filter" },
  Resources: { path: "/blog/resources/:id" },
  "tips-and-tricks": {
    path: "/blog/career-spa/tips-and-tricks"
  },
  "its-who-is-who": {
    path: "/blog/career-spa/its-who-is-who"
  },
  "business-lounge": {
    path: "/blog/business-lounge"
  },
  Newsstand: {
    path: "/blog/kiosk/Newsstand"
  },
  "captains-log": {
    path: "/blog/kiosk/captains-log"
  },
  "interesting-reads": {
    path: "/blog/kiosk/interesting-reads"
  },
  "horizon-2050": {
    path: "/blog/kiosk/horizon-2050"
  },
  "behind-screen": {
    path: "/blog/behind-screen"
  }
};

const HbfLayoutRoutes = {
  Home: { path: "/" },
  consultant: { path: "/consultant" },
  client: { path: "/enterprise" },
  MissionsPage: { path: "/missions" },
  SearchCandidatesPage: { path: "/candidates" },
  about: { path: "/about" },
  SingleMission: { path: "/missions/:id" },
  SingleCandidate: { path: "/candidates/:id" },
  sourcerOffers: { path: "/offers/:id" }
};
const HbLayoutRoutes = {
  Login: { path: "/login" },
  SignUp: { path: "/signup" },
  MyProfile: {
    path: "/signup-my-profile"
  },
  MyJob: { path: "/signup-my-job" },
  MyExperience: {
    path: "/signup-my-experience"
  },
  MySearch: {
    path: "/signup-my-search"
  },
  MyInformation: {
    path: "/signup-my-information"
  },
  Confirm: { path: "/signup-thanks" },
  ResetPassword: { path: "/reset-password" },
  contact: { path: "/contact" },
  PrivacyPolicy: { path: "/privacy-policy" },
  MyNeed: { path: "/my-need/post-a-need" },
  Offer: { path: "/my-need/offer" },
  Offer2: { path: "/my-need/offer2" },
  DesiredProfile: { path: "/my-need/desired-profile" },
  ConfirmPostANeed: { path: "/my-need/thanks" }
};
const LpbLayoutRoutes = {
  Dashboard: { path: "/dashboard" },
  DashboardCompany: { path: "/dashboard/company" },
  MyProfile: {
    path: "/dashboard/my-profile"
  },
  MyProfileResumeUploader: {
    path: "/dashboard/my-profile/CV-upload"
  },
  MyCertification: {
    path: "/dashboard/my-formation/certification"
  },
  MyFormation: {
    path: "/dashboard/my-formation/formation"
  },
  MyProfileResumeDownloader: {
    path: "/dashboard/my-profile/CV-download"
  },
  MyInformation: {
    path: "/dashboard/my-information"
  },
  MyEducation: {
    path: "/dashboard/my-formation"
  },
  MyJob: {
    path: "/dashboard/my-job"
  },
  MyExperience: {
    path: "/dashboard/my-experience"
  },
  MySearch: {
    path: "/dashboard/my-search"
  },
  missionsDashboard: {
    path: "/dashboard/missions"
  }
};

const baseUrl = runtimeVars.FRONTEND_BASE_URL;

const singleMission = id => {
  const { path } = HbfLayoutRoutes.SingleMission;
  return baseUrl + path.replace(":id", id);
};

const SingleCandidate = id => {
  const { path } = HbfLayoutRoutes.SingleCandidate;
  return baseUrl + path.replace(":id", id);
};

const missions = () => {
  const { path } = HbfLayoutRoutes.MissionsPage;
  return baseUrl + path;
};

const SearchCandidatesPage = () => {
  const { path } = HbfLayoutRoutes.SearchCandidatesPage;
  return baseUrl + path;
};

const sourcerOffers = id => {
  const { path } = HbfLayoutRoutes.sourcerOffers;
  return baseUrl + path.replace(":id", id);
};

const consultant = () => {
  const { path } = HbfLayoutRoutes.consultant;
  return baseUrl + path;
};

const contact = () => {
  const { path } = HbLayoutRoutes.contact;
  return baseUrl + path;
};

const privacyPolicy = () => {
  const { path } = HbLayoutRoutes.PrivacyPolicy;
  return baseUrl + path;
};

const home = () => {
  return baseUrl;
};

const client = () => {
  const { path } = HbfLayoutRoutes.client;
  return baseUrl + path;
};

const about = () => {
  const { path } = HbfLayoutRoutes.about;
  return baseUrl + path;
};

const login = () => {
  const { path } = HbLayoutRoutes.Login;
  return baseUrl + path;
};

const signUp = () => {
  const { path } = HbLayoutRoutes.SignUp;
  return baseUrl + path;
};

const postANeed = () => {
  const { path } = HbLayoutRoutes.Offer;
  return baseUrl + path;
};

const blog = () => {
  const { path } = BlogLayoutRoutes.Blog;
  return baseUrl + path;
};

const singleBlog = id => {
  const { path } = BlogLayoutRoutes.SingleBlog;
  return baseUrl + path.replace(":id", id);
};

const careerSpa = () => {
  return `${baseUrl}/career-spa`;
};

const tipsTricks = () => {
  const { path } = BlogLayoutRoutes["tips-and-tricks"];
  return baseUrl + path;
};

const iTsWho = () => {
  const { path } = BlogLayoutRoutes["its-who-is-who"];
  return baseUrl + path;
};

const businessLoung = () => {
  const { path } = BlogLayoutRoutes["business-lounge"];
  return baseUrl + path;
};

const kiosk = () => {
  return `${baseUrl}/kiosk`;
};

const Newsstand = () => {
  const { path } = BlogLayoutRoutes.Newsstand;
  return baseUrl + path;
};
const captainsLog = () => {
  const { path } = BlogLayoutRoutes["captains-log"];
  return baseUrl + path;
};
const interestingReads = () => {
  const { path } = BlogLayoutRoutes["interesting-reads"];
  return baseUrl + path;
};
const horizon2050 = () => {
  const { path } = BlogLayoutRoutes["horizon-2050"];
  return baseUrl + path;
};
const behindscreen = () => {
  const { path } = BlogLayoutRoutes["behind-screen"];
  return baseUrl + path;
};

const newsBlog = () => {
  const { path } = BlogLayoutRoutes.News;
  return baseUrl + path;
};

const advices = () => {
  return `${baseUrl}/advices`;
};

const advicesConsultant = () => {
  const { path } = BlogLayoutRoutes.AdvicesConsultant;
  return baseUrl + path;
};

const advicesEnterprise = () => {
  const { path } = BlogLayoutRoutes.AdvicesEnterprise;
  return baseUrl + path;
};

const decryption = () => {
  const { path } = BlogLayoutRoutes.Decryption;
  return baseUrl + path;
};

const successStories = () => {
  const { path } = BlogLayoutRoutes.SuccessStories;
  return baseUrl + path;
};

const search = () => {
  const { path } = BlogLayoutRoutes.Search;
  return baseUrl + path;
};

const resources = id => {
  const { path } = BlogLayoutRoutes.Resources;
  return baseUrl + path.replace(":id", id);
};

const dashboard = () => {
  const { path } = LpbLayoutRoutes.Dashboard;
  return baseUrl + path;
};
const myProfile = () => {
  const { path } = LpbLayoutRoutes.MyProfile;
  return baseUrl + path;
};
const myResumeUploader = () => {
  const { path } = LpbLayoutRoutes.MyProfileResumeUploader;
  return baseUrl + path;
};
const myResumeDownloader = () => {
  const { path } = LpbLayoutRoutes.MyProfileResumeDownloader;
  return baseUrl + path;
};
const myDetails = () => {
  const { path } = LpbLayoutRoutes.MyInformation;
  return baseUrl + path;
};
const myJob = () => {
  const { path } = LpbLayoutRoutes.MyJob;
  return baseUrl + path;
};
const myExperience = () => {
  const { path } = LpbLayoutRoutes.MyExperience;
  return baseUrl + path;
};
const myTraining = () => {
  const { path } = LpbLayoutRoutes.MyEducation;
  return baseUrl + path;
};
const mySearch = () => {
  const { path } = LpbLayoutRoutes.MySearch;
  return baseUrl + path;
};

const dashboardCompany = () => {
  const { path } = LpbLayoutRoutes.DashboardCompany;
  return baseUrl + path;
};

const missionsDashboard = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};

const recommendedMissions = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};
const appliedMissions = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};
const favouriteMissions = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};
const alertMissions = () => {
  const { path } = LpbLayoutRoutes.missionsDashboard;
  return baseUrl + path;
};

const urls = {
  home,
  myProfile,
  myResumeUploader,
  myResumeDownloader,
  myDetails,
  myJob,
  myExperience,
  myTraining,
  mySearch,
  client,
  consultant,
  singleMission,
  SingleCandidate,
  missions,
  sourcerOffers,
  contact,
  privacyPolicy,
  about,
  signUp,
  login,
  blog,
  singleBlog,
  "career-spa": careerSpa,
  "tips-and-tricks": tipsTricks,
  "its-who-is-who": iTsWho,
  "business-lounge": businessLoung,
  kiosk,
  Newsstand,
  "captains-log": captainsLog,
  "interesting-reads": interestingReads,
  "horizon-2050": horizon2050,
  "behind-screen": behindscreen,
  newsBlog,
  decryption,
  advices,
  advicesConsultant,
  advicesEnterprise,
  search,
  successStories,
  resources,
  dashboard,
  dashboardCompany,
  postANeed,
  SearchCandidatesPage,
  missionsDashboard,
  recommendedMissions,
  appliedMissions,
  favouriteMissions,
  alertMissions
};

export default urls;
