import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Row, Col } from "cf-neo-ui";

import Header from "./header";
import RightSide from "./rightSide";
import Routes from "../routes/index";
import "./styles.scss";

class HbLayout extends Component {
  withStepsBar = () => {
    const { location } = this.props;
    const {
      MyJob,
      MySearch,
      MyInformation,
      MyExperience,
      ConfirmCode,
      MyProfile
    } = Routes.Hb2LayoutRoutes;
    const routesWithStepsBar = [
      MyProfile.path,
      MyJob.path,
      MyInformation.path,
      MyExperience.path,
      MySearch.path,
      ConfirmCode.path
    ];
    return routesWithStepsBar.includes(location.pathname);
  };

  withRightSide = () => {
    const { location } = this.props;
    const {
      MyProfile,
      MyJob,
      MySearch,
      MyInformation,
      MyExperience,
      ConfirmCode
    } = Routes.Hb2LayoutRoutes;

    const routesWithRightSide = [
      MyProfile.path,
      MyJob.path,
      MyInformation.path,
      MyExperience.path,
      ConfirmCode.path,
      MySearch.path
    ];
    return routesWithRightSide.includes(location.pathname);
  };

  render() {
    const { component } = this.props;
    const RenderComponent = component;
    const withStepsBar = this.withStepsBar();
    const withRightSide = this.withRightSide();
    return (
      <div className={`${!withStepsBar ? "session-bg" : ""}`}>
        <Row noGape>
          <Col noGutter>
            <Header withStepsBar={withStepsBar} />
          </Col>
        </Row>
        <Row className="body" noGape>
          <Col className="content" md={9} lg={9} xl={9} sm={9} xs={6}>
            <RenderComponent />
          </Col>
          {withRightSide && (
            <Col md={3} lg={3} xl={3} noGutter className="right-side-column">
              <RightSide />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

HbLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

export default withRouter(HbLayout);
