import React, { Component } from "react";
import { withI18n } from "@lingui/react";
import * as PropTypes from "prop-types";
import { Collapse, DatePicker, Button } from "cf-neo-ui";
import { Trans, t } from "@lingui/macro";
import moment from "moment";
import { inject, observer } from "mobx-react";
import classes from "./Activities.module.scss";
import runtimeVars from "../../../../configs/runTimeVars";
import CountCards from "./count-cards";
import VacanciesDetails from "./vacancies-details";

@inject("statsStore", "appStore")
@observer
class Activities extends Component {
  constructor(props) {
    super(props);
    this.clearFilters();
    moment.locale(runtimeVars.APP_LANG);
  }

  clearFilters = () => {
    const { statsStore } = this.props;
    const { clear } = statsStore;
    clear();
  };

  disableFutureDate = day => {
    return moment(day).valueOf() > Date.now();
  };

  disableDaysBefore = (day, dateStart) => {
    const dayStamp = day ? moment(day).valueOf() : null;
    const tomorrow = moment()
      .add(1, "day")
      .startOf("day")
      .valueOf();
    if (!dateStart) {
      return dayStamp > tomorrow;
    }
    return dayStamp < moment(dateStart).valueOf() || dayStamp >= tomorrow;
  };

  displayFilters = classtoApply => {
    const { statsStore, appStore } = this.props;
    const { currentLanguage } = appStore;
    const { setDateStart, setDateEnd, dateStart, dateEnd } = statsStore;
    return (
      <div className={classes[classtoApply]}>
        <section className={classes.filterSection}>
          <label htmlFor="input_date">
            <Trans>Période</Trans>
          </label>
          <div id="input_date" key={dateStart + dateEnd}>
            <span>
              <Trans>Du</Trans>
            </span>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              defaultDate={
                dateStart ? moment(dateStart).format("DD/MM/YYYY") : ""
              }
              onChange={v => {
                if (v)
                  setDateStart(
                    moment(v)
                      .startOf("day")
                      .valueOf()
                  );
              }}
              disabledDaysMatcher={day => this.disableFutureDate(day)}
              locale={currentLanguage || runtimeVars.APP_LANG}
            />
            <span>
              <Trans>Au</Trans>
            </span>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              defaultDate={dateEnd ? moment(dateEnd).format("DD/MM/YYYY") : ""}
              onChange={v => {
                if (v)
                  setDateEnd(
                    moment(v)
                      .endOf("day")
                      .valueOf()
                  );
              }}
              disabledDaysMatcher={day =>
                this.disableDaysBefore(day, dateStart)
              }
              locale={currentLanguage || runtimeVars.APP_LANG}
            />
          </div>
        </section>
        {/* <section className={classes.filterSection}> */}
        {/* <label htmlFor="referent"> */}
        {/* <Trans>Référent</Trans> */}
        {/* </label> */}
        {/* <div> */}
        {/* <TextInput id="referent" /> */}
        {/* </div> */}
        {/* </section> */}
        <section>
          <Button size="small" variant="secondary" onClick={this.clearFilters}>
            <Trans>Réinitialiser</Trans>
          </Button>
        </section>
      </div>
    );
  };

  render() {
    const { i18n, appStore } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.part1}>
          {appStore.width > 1024 ? (
            this.displayFilters("filter")
          ) : (
            <Collapse title={i18n._(t`filters`)} initiallyOpen={false}>
              {this.displayFilters("smallFilters")}
            </Collapse>
          )}
          <CountCards />
        </div>
        <VacanciesDetails />
      </div>
    );
  }
}

Activities.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  statsStore: PropTypes.shape({
    dateStart: PropTypes.number,
    dateEnd: PropTypes.number,
    setDateStart: PropTypes.func,
    setDateEnd: PropTypes.func,
    setEmptyData: PropTypes.func,
    clear: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string,
    width: PropTypes.number
  }).isRequired
};

export default withI18n()(Activities);
