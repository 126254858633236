import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Row, Button, Col, AutoComplete } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import Fade from "react-reveal/Fade";
import Location from "../../../components/location/location";
import classes from "./FinderSection1.module.scss";

@inject("missionsSearchStore")
@observer
class FinderSection1 extends Component {
  constructor(props) {
    super(props);
    props.missionsSearchStore.initCategoryOptions(props.options);
    this.state = {
      category: "",
      location: ""
    };
  }

  selectCategoryHandler = category => {
    this.setState({ category });
  };

  selectLocationHandler = (address, formattedAddress) => {
    this.setState({ location: formattedAddress });
  };

  selectHandler = val => {
    const { missionsSearchStore } = this.props;
    missionsSearchStore.addCategory(val);
    missionsSearchStore.setCategoryOptions();
  };

  toDetails = () => {
    const { history, missionsSearchStore } = this.props;
    const { category, location } = this.state;
    if (category !== "") {
      missionsSearchStore.addCategory(category);
      missionsSearchStore.setCategoryOptions();
    }
    if (location !== "") {
      missionsSearchStore.addLocation(location);
    }
    history.push(`/missions`);
  };

  render() {
    const { i18n, options } = this.props;
    return (
      <div className={classes.finder}>
        <Fade>
          <h1>
            <Trans>Prêt à trouver une mission ?</Trans>
          </h1>
        </Fade>
        <Fade>
          <p className={classes.paragraph}>
            <Trans>Qui vous correspond</Trans>
          </p>
        </Fade>

        <Row className={classes.center2}>
          <Col lg={9} md={8} sm={12} xs={6} noGutter>
            <Row className={classes.block}>
              <Col lg={6} md={6} sm={6} xs={6} noGutter>
                <AutoComplete
                  className={classes.input1}
                  restrictedToList
                  options={options.map(o => o.name)}
                  iconColor="#BBBABA"
                  iconColor2="#BBBABA"
                  icon="search"
                  placeholder={i18n._(t`Votre domaine d’expertise`)}
                  onSelect={this.selectHandler}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} noGutter>
                <Location
                  className={classes.input2}
                  iconColor="#BBBABA"
                  iconColor2="#BBBABA"
                  getLocation={this.selectLocationHandler}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={4} sm={12} xs={6} noGutter>
            <div className={classes.butt1}>
              <Button
                iconColor="#54000C"
                icon="award"
                iconPosition="left"
                variant="secondary"
                onClick={() => this.toDetails()}
                id="cta_hf_sec4_offers_search"
              >
                <Trans>Trouver une offre</Trans>
              </Button>
            </div>
          </Col>
        </Row>
        <div className={classes.add} />
      </div>
    );
  }
}

FinderSection1.wrappedComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  missionsSearchStore: PropTypes.shape({
    addCategory: PropTypes.func,
    addLocation: PropTypes.func,
    initCategoryOptions: PropTypes.func,
    setCategoryOptions: PropTypes.func
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ).isRequired
};

export default withI18n()(withRouter(FinderSection1));
