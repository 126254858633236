import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { IconCircle, H4, Modal } from "cf-neo-ui";
import { t, Trans } from "@lingui/macro";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { withI18n } from "@lingui/react";
import routes from "../layout/routes";
import classes from "./Timesheet.module.scss";
import colors from "../../utils/timesheetStatusColors";
import TitleSubInfo from "./title-sub-info/TitleSubInfo";
import PlusHoures from "./plus-houres/PlusHoures";
import ConclusionSection from "./conclusion-section/ConclusionSection";
import TimeSheetCalendar from "./calendar/TimeSheetCalendar";
import Messenger from "./messenger";
import { SINGLE_TIME_SHEETS_QUERY } from "../timesheets/queries";
import PopUpValidationTimeSheet from "./pop-up/PopUpValidationTimeSheet";
import PopUpSubmitTimeSheet from "./pop-up/PopUpSubmitTimeSheet";
import PopUpRejectTimeSheet from "./pop-up/PopUpRejectTimeSheet";
import runtimeVars from "../../configs/runTimeVars";
import monthName from "../../utils/monthName";
import NotFoundPage from "../../components/error-pages/NotFoundPage";

@inject("timesheetStore", "sessionStore", "modalStore")
@observer
class Timesheet extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    moment.locale(runtimeVars.APP_LANG);
    this.state = {
      ...initProps,
      isFirstTime: true,
      isFirstTime2: true
    };
  }

  getStatusInTheRightLanguage = status => {
    const { i18n } = this.props;
    switch (status) {
      case "open":
        return i18n._(t`ouvert`);
      case "pending":
        return i18n._(t`en attente`);
      case "approved":
        return i18n._(t`validé`);
      case "rejected":
        return i18n._(t`rejeté`);
      default:
        return "";
    }
  };

  getNames = card => {
    const { sessionStore } = this.props;
    const { account } = sessionStore;
    if (account.role === "CLIENT") {
      return [`${card.candidate.name}`];
    }
    if (account.role === "CANDIDATE") {
      if (card.approvingClient2)
        return [
          `${card.approvingClient.name}`,
          `${card.approvingClient2.name}`
        ];
      return card.approvingClient ? [`${card.approvingClient.name}`] : [`N/A`];
    }
    return ["N/A"];
  };

  getEmails = card => {
    const { sessionStore } = this.props;
    const { account } = sessionStore;
    if (account.role === "CLIENT") {
      return [`${card.candidate.email}`];
    }
    if (account.role === "CANDIDATE") {
      if (card.approvingClient2)
        return [
          `${card.approvingClient.email}`,
          `${card.approvingClient2.email}`
        ];
      return card.approvingClient ? [`${card.approvingClient.email}`] : [`N/A`];
    }
    return ["N/A"];
  };

  displayConversation = timesheet => {
    const { timesheetStore, sessionStore, modalStore } = this.props;
    const { changeMessages, changeComment } = timesheetStore;
    const { timesheetPopUp } = modalStore;
    const { isFirstTime2 } = this.state;
    if (timesheet.conversation) {
      if (timesheetPopUp === 0 && isFirstTime2) {
        const msg = timesheet.conversation.messages;
        for (let i = 0; i < msg.length; i += 1) {
          if (
            sessionStore.account.role === "CANDIDATE" &&
            timesheet.candidate.id === msg[i].speaker
          )
            msg[i].speaker = null;
          if (
            sessionStore.account.role === "CLIENT" &&
            ((timesheet.client && timesheet.client.id === msg[i].speaker) ||
              (timesheet.approvingClient &&
                timesheet.approvingClient.id === msg[i].speaker) ||
              (timesheet.approvingClient2 &&
                timesheet.approvingClient2.id === msg[i].speaker))
          )
            msg[i].speaker = null;

          msg[i].date = moment(new Date(msg[i].date)).format("DD/MM/YY HH:mm");
        }
        changeComment(timesheet.conversation.comment);
        changeMessages(msg);
        this.setState({ isFirstTime2: false });
      }
    } else changeMessages([]);
    return (
      <div>
        <Messenger />
      </div>
    );
  };

  render() {
    const locale = runtimeVars.APP_LANG;
    const {
      timesheetStore,
      match,
      sessionStore,
      modalStore,
      i18n
    } = this.props;
    const { timesheetPopUp } = modalStore;
    const { isFirstTime } = this.state;

    const {
      changeSpecialHours,
      updateSelectedDays,
      updateTotalSpecial,
      changeTimesheet
    } = timesheetStore;
    return (
      <div className={classes.container}>
        <Modal
          centred
          isVisible={timesheetPopUp !== 0}
          withCloseButton={false}
          position="fixed"
          className={classes.scrolled}
        >
          {timesheetPopUp === 1 && <PopUpRejectTimeSheet />}
          {timesheetPopUp === 2 && <PopUpValidationTimeSheet />}
          {timesheetPopUp === 3 && <PopUpSubmitTimeSheet />}
        </Modal>
        <Query
          query={SINGLE_TIME_SHEETS_QUERY}
          variables={{ id: parseInt(match.params.id, 10) }}
          fetchPolicy="no-cache"
        >
          {({ loading, error, data }) => {
            if (loading) return <div>loading</div>;
            if (error) {
              if (
                error.graphQLErrors[0].message === "NOT_FOUND_OR_UNAUTHORIZED"
              ) {
                return (
                  <NotFoundPage
                    message="Not found or unauthorized"
                    withButton={false}
                  />
                );
              }
              if (error.graphQLErrors[0].message === "OPEN_TIMESHEET") {
                return (
                  <NotFoundPage
                    message="Not found or unauthorized"
                    withButton={false}
                  />
                );
              }
              return (
                <div className="error_container">
                  <span>
                    {i18n._(t` Oups! il semblerait que vos données aient du mal à se
                  synchroniser. Veuillez rafraichir la page dans un moment`)}
                  </span>
                  <img
                    style={{
                      margin: 20
                    }}
                    src={"/assets/svg/download.svg"}
                    alt="error"
                  />
                </div>
              );
            }
            const { timesheet } = data;
            if (timesheet) {
              if (timesheetPopUp === 0 && isFirstTime) {
                updateSelectedDays(timesheet.selectedDays);
                changeSpecialHours(timesheet.specialHours);
                updateTotalSpecial(timesheet.specialHours);
                this.setState({ isFirstTime: false });
              }
              changeTimesheet(timesheet);
            }

            return (
              <div>
                <div className={classes.topHeader}>
                  <Link
                    to={routes.LpbLayoutRoutes.Timesheets.path}
                    className={classes.backButton}
                  >
                    <IconCircle
                      type="chevron-left"
                      color="#8D0417"
                      color2="#D3354A"
                      dimension={30}
                      innerColor="#fff"
                      iconWidth={8.33}
                      iconHeight={8.33}
                    />
                    <span>
                      <Trans>Timesheets</Trans>
                    </span>
                  </Link>
                </div>
                <div className={classes.title}>
                  <H4>{`Timesheet ${monthName(timesheet.month, locale)} ${
                    timesheet.year
                  }`}</H4>
                  <span
                    style={{
                      color: colors.pending,
                      borderColor: colors.pending
                    }}
                  >
                    {this.getStatusInTheRightLanguage(timesheet.status)}
                  </span>
                </div>
                <TitleSubInfo
                  id={timesheet.placementID}
                  location={
                    sessionStore.account.role === "CANDIDATE"
                      ? timesheet.clientCorporation.name
                      : timesheet.candidate.city
                  }
                  name={this.getNames(timesheet)}
                  email={this.getEmails(timesheet)}
                  role={sessionStore.account.role}
                />
                <div className={classes.mainContent}>
                  <TimeSheetCalendar
                    month={timesheet.month}
                    year={timesheet.year}
                    plCountry={timesheet.country}
                  />
                  <aside className={classes.rightSide}>
                    {this.displayConversation(timesheet)}
                    <PlusHoures month={timesheet.month} year={timesheet.year} />
                    <ConclusionSection />
                  </aside>
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

Timesheet.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  timesheetStore: PropTypes.shape({
    updateSelectedDays: PropTypes.func,
    updateTotalSpecial: PropTypes.func,
    changeTimesheet: PropTypes.func,
    changeSpecialHours: PropTypes.func,
    changeMessages: PropTypes.func,
    timesheet: PropTypes.shape({
      status: PropTypes.string
    }),
    changeComment: PropTypes.func,
    selectedDays: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.number,
        hours: PropTypes.number
      })
    )
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  modalStore: PropTypes.shape({
    timesheetPopUp: PropTypes.number,
    changeIsVisible2: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(Timesheet));
