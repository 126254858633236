import { observable, action } from "mobx";

const defaultValues = {
  isVisible: false,
  isVisible2: false,
  timesheetPopUp: 0,
  experiencePopUp: false,
  modalType: "",
  signatairePopUp: false,
  signatairePopUp2: false
};

export default class ModalStore {
  @observable isVisible;

  @observable isVisible2;

  @observable postANeedFromFooter;

  @observable modalType;

  @observable timesheetPopUp;

  @observable experiencePopUp;

  @observable signatairePopUp;

  @observable signatairePopUp2;

  constructor(initialState = defaultValues) {
    this.isVisible = initialState.isVisible || false;
    this.isVisible2 = initialState.isVisible2 || false;
    this.modalType = initialState.modalType || "";
    this.timesheetPopUp = initialState.timesheetPopUp || 0;
    this.experiencePopUp = initialState.experiencePopUp || false;
    this.signatairePopUp = initialState.signatairePopUp || false;
    this.signatairePopUp2 = initialState.signatairePopUp2 || false;
    this.postANeedFromFooter = initialState.postANeedFromFooter || false;
  }

  @action
  changeIsVisible = isVisible => {
    this.isVisible = isVisible;
  };

  @action
  changeIsVisible2 = isVisible2 => {
    this.isVisible2 = isVisible2;
  };

  @action
  changeModalType = modalType => {
    this.modalType = modalType;
  };

  @action
  changeTimesheetPopUp = timesheetPopUp => {
    this.timesheetPopUp = timesheetPopUp;
  };

  @action
  changeExperiencePopUp = experiencePopUp => {
    this.experiencePopUp = experiencePopUp;
  };

  @action
  changeSignatairePopUp = signatairePopUp => {
    this.signatairePopUp = signatairePopUp;
  };

  @action
  changeSignatairePopUp2 = signatairePopUp2 => {
    this.signatairePopUp2 = signatairePopUp2;
  };

  @action
  changePostANeedFromFooter = postANeedFromFooter => {
    this.postANeedFromFooter = postANeedFromFooter;
  };

  @action
  reset = () => {
    this.isVisible = false;
    this.isVisible2 = false;
    this.modalType = "";
    this.timesheetPopUp = 0;
    this.experiencePopUp = false;
    this.signatairePopUp = false;
    this.signatairePopUp2 = false;
    this.postANeedFromFooter = false;
  };
}
