import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { P, Pagination, Spinner, Table } from "cf-neo-ui";
import { Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import classes from "./styles.module.scss";
import CLIENT_VAC_STATS_QUERY from "./query";
import theme from "../../../../../configs/theme";

@inject("statsStore", "sessionStore")
@observer
class Index extends Component {
  formatTable = vacancies => {
    if (!vacancies || !Array.isArray(vacancies) || !vacancies.length)
      return null;
    return vacancies.map(
      ({
        id,
        title,
        totalNotes,
        totalShortListed,
        totalCVSent,
        totalInterviews,
        totalPlacements
      }) => [
        `#${id}`,
        title,
        totalNotes,
        totalShortListed,
        totalCVSent,
        totalInterviews,
        totalPlacements
      ]
    );
  };

  loading = () => {
    return (
      <div className={classes.spinner}>
        <Spinner
          type="pointed-circular"
          color={theme.colors.mainColor}
          size={60}
        />
      </div>
    );
  };

  render() {
    const { i18n, statsStore, sessionStore, history } = this.props;
    const {
      dateStart,
      dateEnd,
      setEmptyData,
      skip,
      limit,
      total,
      toPage
    } = statsStore;
    return (
      <div className={classes.container}>
        <Query
          query={CLIENT_VAC_STATS_QUERY}
          variables={{
            id: parseInt(sessionStore.id, 10),
            dateStart,
            dateEnd,
            skip,
            limit
          }}
        >
          {({ loading, error, data }) => {
            if (error) return null;
            if (loading) return this.loading();

            const { vacanciesStats } = data;
            const { vacancies } = vacanciesStats;

            const tableBody = this.formatTable(vacancies);

            if (!tableBody) {
              setEmptyData(true);
              return (
                <div className={classes.empty}>
                  <Trans>Pas d’activité pendant la periode sélectionnée</Trans>
                </div>
              );
            }

            setEmptyData(false);

            const trClicks = [];
            vacancies.map(line => {
              trClicks.push(() => {
                history.push(`/dashboard/company/offers/${line.id}`);
              });
            });

            return (
              <div>
                <Table
                  head={[
                    "ID",
                    i18n._(t`Offres postées`),
                    i18n._(t`Appels`),
                    i18n._(t`CV sélectionnés`),
                    i18n._(t`CV envoyés`),
                    i18n._(t`Entretiens`),
                    i18n._(t`Placement`)
                  ]}
                  body={tableBody}
                  fixedColumns={1}
                  trClicks={trClicks}
                />
                {total > limit ? (
                  <div className={classes.pagination}>
                    <Pagination
                      total={total}
                      current={Math.floor(skip / limit) + 1}
                      onChange={current => toPage(current)}
                    />
                  </div>
                ) : null}
              </div>
            );
          }}
        </Query>
        <P className={classes.paragraph}>
          <Trans>
            Ces statistiques correspondent aux actions réalisées par nos équipes
            sur chaque offre ajoutée sur la periode sélectionnée.
          </Trans>
        </P>
      </div>
    );
  }
}

Index.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  statsStore: PropTypes.shape({
    dateStart: PropTypes.number,
    dateEnd: PropTypes.number,
    setDateStart: PropTypes.func,
    setDateEnd: PropTypes.func,
    setEmptyData: PropTypes.func,
    clear: PropTypes.func,
    skip: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    toPage: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default withI18n()(withRouter(Index));
