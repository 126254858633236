import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Mutation, Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { withI18n } from "@lingui/react";
import { MissionCardSkeleton } from "cf-neo-ui";

import USER_FAVED_MISSIONS_QUERY from "./queries";
import { UNFAVE_MISSION, FAVE_MISSION } from "./mutations";
import Routes from "../../containers/layout/routes";

@inject("sessionStore")
@observer
class FaveUnfave extends Component {
  queue = [];

  likeManager = mutation => {
    const { missionId } = this.props;
    if (mutation) this.queue.push(mutation);

    /* mutate only when the queue is empty */
    if (this.queue.length === 1)
      mutation({ variables: { jobOrderId: missionId } });

    /* if n > 2 reduce requests two by two */
    if (this.queue.length > 2)
      this.queue.splice(
        1,
        (this.queue.length - 1) % 2
          ? this.queue.length - 2
          : this.queue.length - 1
      );
  };

  mutationCompleted = () => {
    this.queue.shift();
    this.likeManager();
  };

  isFaved = favedMissions => {
    const { missionId } = this.props;
    const arr = favedMissions.filter(mission => mission.id === missionId);
    return !!arr.length;
  };

  guestDisplay = toLogin => {
    const { children, history } = this.props;
    return React.Children.map(children, child => {
      if (child && child.props) {
        if ("preferred" in child.props) delete child.props.preferred;
        if ("onPrefer" in child.props) delete child.props.onPrefer;
      }
      return React.cloneElement(child, {
        onPrefer: toLogin
          ? () => history.push(Routes.HbLayoutRoutes.Login.path)
          : null,
        preferred: false
      });
    });
  };

  userDisplay = () => {
    const { children } = this.props;
    return (
      <Query query={USER_FAVED_MISSIONS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <MissionCardSkeleton />;
          if (error) return null;
          const { userFavedMissions } = data;
          return (
            <Mutation
              mutation={FAVE_MISSION}
              onCompleted={this.mutationCompleted}
              refetchQueries={[{ query: USER_FAVED_MISSIONS_QUERY }]}
            >
              {favMission => (
                <Mutation
                  mutation={UNFAVE_MISSION}
                  onCompleted={this.mutationCompleted}
                  refetchQueries={[{ query: USER_FAVED_MISSIONS_QUERY }]}
                >
                  {unFavMission => {
                    return React.Children.map(children, child => {
                      if ("preferred" in child.props)
                        delete child.props.preferred;
                      if ("onPrefer" in child.props)
                        delete child.props.onPrefer;
                      return React.cloneElement(child, {
                        onPrefer: () =>
                          this.isFaved(userFavedMissions)
                            ? this.likeManager(unFavMission)
                            : this.likeManager(favMission),
                        preferred: this.isFaved(userFavedMissions)
                      });
                    });
                  }}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  };

  render() {
    const { sessionStore } = this.props;
    if (sessionStore.authToken) {
      if (sessionStore.account.role === "CANDIDATE") return this.userDisplay();
      return this.guestDisplay(false);
    }
    return this.guestDisplay(true);
  }
}

FaveUnfave.wrappedComponent.propTypes = {
  missionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default withI18n()(withRouter(FaveUnfave));
