export default {
  name: "Mindquest",
  foundingDate: "2020",
  globalLocationNumber: 3,
  locations: {
    london: {
      streetAddress: "100 Drummond Road",
      addressLocality: "Greater London",
      addressRegion: "England",
      postalCode: "SE16 4DG",
      addressCountry: "GB"
    },
    paris: {
      streetAddress: "44 Boulevard de Sébastopol",
      addressLocality: "Paris",
      addressRegion: "IDF",
      postalCode: "75003",
      addressCountry: "FR"
    },
    barcelone: {
      streetAddress: "Carrer de Pallars 99",
      addressLocality: "Barcelona",
      addressRegion: "CT",
      postalCode: "08018 Barcelona",
      addressCountry: "ES"
    }
  },
  contactEmail: "contact@club-freelance.com",
  phoneNumber: {
    fr: "+33 9 75 12 27 36",
    uk: "+44 20 3854 5788"
  },
  socialMedia: {
    facebook: "https://www.facebook.com/ClubFreelance/",
    twitter: "https://twitter.com/clubfreelance",
    twitterScreenName: "clubfreelance",
    linkedIn: "https://www.linkedin.com/company/club-freelance/",
    jungle: "https://www.welcometothejungle.com/en/companies/club-freelance"
  },
  address: {
    london: "AbiuUjfQLGiFfEGB9",
    paris: "NNeFbiQUEZDkdGu98",
    barcelone: "LJUbtC9bexsNCGcVA"
  },
  postNeed: {
    link_fr:
      "https://forms.office.com/Pages/ResponsePage.aspx?id=__MEfkdzvEWErRo52fbLlGXxrRB_qDBBo4AyblkL2PpURDdGMEZZRVczUkc4VkZCNkZOUzZQR0sxNC4u",
    link_uk:
      "https://forms.office.com/Pages/ResponsePage.aspx?id=__MEfkdzvEWErRo52fbLlGXxrRB_qDBBo4AyblkL2PpUQkU0SFBHMFNDVjNOVVFTNzBUWjlTVFlPNy4u"
  },
  cvTemplateUrl:
    "https://drive.google.com/drive/folders/0Bxmil0k-EOiiSlJqc1R4bUw4Q28?resourcekey=0-KP-ChSJhYanAeTT2j8aiVw",
  cvTemplateUrlEn:
    "https://drive.google.com/drive/u/0/folders/1VG9uGjC_OTg7HqVPn27306XpXCK1qv8F",
  advicesEnterprise_fr: "https://mindquest.io/blog/business-lounge",
  advicesEnterprise_uk: "https://mindquest.io/blog/business-lounge",
  marketIntel: "http://data.club-freelance.com/",
  cf_website: "https://club-freelance.com/",
  mq_website: "https://mindquest.io/",
  uk_blog: "https://club-freelance.co.uk/blog",
  partners: {
    lien1: "https://nos-partenaires.club-freelance.com",
    lien2: "https://visit.club-freelance.com/nos-partenaires-portage"
  }
};
