import { BrowserRouter } from "react-router-dom";
import React from "react";
import { hydrate, render } from "react-dom";
import { ApolloProvider } from "react-apollo";
import { Provider, useStaticRendering } from "mobx-react";
import { HelmetProvider } from "react-helmet-async";
import client from "./helpers/apolloClient";
import reInitiateStores from "./stores/createStores";
import App from "./App";
import runtimeVars from "./configs/runTimeVars";

const stores = reInitiateStores();

const element = document.getElementById("root");

useStaticRendering(false);

if (element) {
  const renderMethod = module.hot ? render : hydrate;
  renderMethod(
    <HelmetProvider>
      <ApolloProvider client={client}>
        <Provider {...stores}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    </HelmetProvider>,
    document.getElementById("root")
  );
}

if (module.hot) {
  module.hot.accept();
}

if (!runtimeVars.devMode && "serviceWorker" in navigator) {
  window.addEventListener(
    "load",
    () => {
      navigator.serviceWorker.register("/sw.js").catch(() => {});
    },
    { once: true }
  );
}
