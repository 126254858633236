import { t } from "@lingui/macro";

export default (i18n, employmentType) => {
  if (employmentType) {
    const et = employmentType.toLowerCase();
    if (et === "cdi" || et === "permanent") return i18n._(t`CDI`);
    if (et === "freelance" || et === "contract") return "Freelance";
  }
  return null;
};
