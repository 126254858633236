import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import {
  Button,
  FormCard,
  Icon,
  Row,
  Col,
  FormGroup,
  Validation,
  Textarea,
  toaster,
  Spinner
} from "cf-neo-ui";
import { CHANGE_VALIDATED_JOB_SUBMISSION_STATUS } from "../mutation";
import "./styles.scss";
import validate from "../../../../utils/validators";
import theme from "../../../../configs/theme";

@inject("modalStore", "talentStore", "jobSubmissionStore")
@observer
class PopUpForToMeet extends Component {
  constructor(props) {
    super(props);
    const initProps = { ...props };
    this.state = {
      ...initProps,
      isCommentValid: true,
      commentValidationMessage: "",
      comment: ""
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  clickHandler = () => {
    const { modalStore, talentStore } = this.props;
    const { changeIsVisible, changeModalType } = modalStore;
    const { talentDropped, lastParent } = talentStore;
    let parent = null;
    for (let i = 0; i < lastParent.childNodes.length; i += 1) {
      if (lastParent.childNodes[i].id === "card") {
        parent = lastParent.childNodes[i];
        break;
      }
    }
    parent.appendChild(talentDropped);
    changeIsVisible(false);
    changeModalType("");
  };

  commentChangeHandler = e => {
    this.setState({ comment: e.target.value });
    this.validateComment(e.target.value);
  };

  submit = () => {
    const { comment } = this.state;
    const valid = this.validateComment(comment);
    if (!valid) return;
    const { modalStore } = this.props;
    const { changeIsVisible, changeModalType } = modalStore;
    changeIsVisible(false);
    changeModalType("");
  };

  validateComment(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required"], i18n);
    this.setState({
      isCommentValid: res.isValid,
      commentValidationMessage: res.message
    });
    return res.isValid;
  }

  render() {
    const { i18n, jobSubmissionStore, match } = this.props;
    const { jobSubmission } = jobSubmissionStore;
    const { isCommentValid, commentValidationMessage, comment } = this.state;
    return (
      <div className="pop-up-container">
        <FormCard className="formCard no-gutter">
          <Row>
            <Col className="form-card-content center">
              <span className="center">
                <span className="icon">
                  <Icon
                    className="icon"
                    type="triangle-exclamation"
                    color={theme.colors.mainColor}
                    color2={theme.colors.lightMainColor}
                    width={25}
                    height={25}
                  />
                </span>
                <span className="title">
                  Notifier les équipes Club Freelance
                </span>
              </span>
              <p>
                <Trans>
                  En déplaçant ce candidat dans la colonne profils à rencontrer,
                  vous notifierez l'équipe Club Freelance de votre retour.
                </Trans>
              </p>
              <p>
                <Trans>
                  Afin d'organiser un entretien avec le candidat, pourriez-vous
                  nous donner des créneaux de disponibilité?
                </Trans>
              </p>

              <Row>
                <Col lg={12} xl={12} md={12} sd={12} xs={6}>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="experienceDescription"
                    >
                      <Trans>Commentaire</Trans>
                      <span className="asterisc">*</span>
                    </label>
                    <Validation
                      errorMessage={commentValidationMessage}
                      valid={isCommentValid}
                    >
                      <Textarea
                        id="missionDescription"
                        size="xLarge"
                        className="form-input"
                        onEnterPressed={this.commentChangeHandler}
                        onEscapePressed={this.commentChangeHandler}
                        onKeyDown={this.commentChangeHandler}
                        onChange={this.commentChangeHandler}
                        value={comment}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
              </Row>

              <br />
              <div className="center">
                <span className="button-left">
                  <Mutation
                    mutation={CHANGE_VALIDATED_JOB_SUBMISSION_STATUS}
                    variables={{
                      jobOrderID: parseInt(match.params.id),
                      candidateID: jobSubmission.candidateID,
                      status: "interview",
                      comment
                    }}
                    onCompleted={this.submit}
                    onError={() =>
                      toaster.error({
                        title: i18n._(t`Erreur`),
                        description: i18n._(t`Un problème est survenu`)
                      })
                    }
                  >
                    {(mutation, { loading }) => (
                      <span className="submitButton">
                        {loading ? (
                          <Button disabled>
                            <Spinner
                              type="pointed-circular"
                              color="#FFFFFF"
                              size={12}
                            />
                          </Button>
                        ) : (
                          <Button
                            icon="chevron-right"
                            iconPosition="right"
                            size="small"
                            onClick={() => {
                              if (this.validateComment(comment)) mutation();
                            }}
                          >
                            <Trans>Continuer</Trans>
                          </Button>
                        )}
                      </span>
                    )}
                  </Mutation>
                </span>
                <span className="button-right">
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={() => this.clickHandler()}
                  >
                    <Trans>Annuler</Trans>
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
        </FormCard>
      </div>
    );
  }
}

PopUpForToMeet.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  modalStore: PropTypes.shape({
    changeIsVisible: PropTypes.func,
    changeModalType: PropTypes.func
  }).isRequired,
  talentStore: PropTypes.shape({
    changeTalentDropped: PropTypes.func,
    changeLastParent: PropTypes.func,
    changeNewParent: PropTypes.func,
    talentDropped: PropTypes.object,
    newParent: PropTypes.object,
    lastParent: PropTypes.object
  }).isRequired,
  jobSubmissionStore: PropTypes.shape({
    changeJobSubmission: PropTypes.func,
    jobSubmission: PropTypes.object
  }).isRequired
};

export default withI18n()(withRouter(PopUpForToMeet));
