import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Query } from "react-apollo";
import { Row, Col, Collapse, InputSkeleton, Button, Sorter } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react/index";
import classes from "./SideFilters.module.scss";
import EmploymentPreferenceWidget from "./widgets/EmploymentPreferenceWidget";
import ExperienceWidget from "./widgets/ExperienceWidget";
import SkillsWidget from "./widgets/SkillsWidget";
import SearchResultWidget from "./widgets/SearchResultWidget";
import LocationWidget from "./widgets/LocationWidget";
import { GET_SKILLS } from "../../queries";

@inject("candidatesSearchStore")
@observer
class SideFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      randomKey: 0
    };
  }

  sorterChangeHandler = (value, direction) => {
    const { candidatesSearchStore } = this.props;
    const { changeSortByDistance, changeSortByScore } = candidatesSearchStore;
    if (value === "distance") {
      return direction === "up"
        ? changeSortByDistance(value, "ASC")
        : direction === "down"
        ? changeSortByDistance(value, "DESC")
        : changeSortByDistance(value, "NPEC");
    } else if (value === "score") {
      return direction === "up"
        ? changeSortByScore(value, "ASC")
        : direction === "down"
        ? changeSortByScore(value, "DESC")
        : changeSortByScore(value, "NPEC");
    }
  };

  clearFilters = () => {
    const { candidatesSearchStore } = this.props;
    candidatesSearchStore.clearFilters();
    this.setState({ randomKey: Math.random() });
  };

  appliqueFilters = () => {
    const { candidatesSearchStore } = this.props;
    candidatesSearchStore.changeFilterAppliqued();
  };

  render() {
    const { i18n, candidatesSearchStore } = this.props;
    const { randomKey } = this.state;
    const {
      isSideFilterOpen,
      sortByScore,
      sortByDistance
    } = candidatesSearchStore;
    return (
      <Col
        noGutter
        lg={3}
        xl={3}
        md={3}
        sm={12}
        xs={6}
        className={`${classes.sideFilter} ${
          isSideFilterOpen ? classes.active : ""
        }`}
      >
        <aside>
          <SearchResultWidget />
          <Collapse title={i18n._(t`Trier`)}>
            <div className={classes.widgetBody}>
              <Row>
                <Col>
                  <Sorter
                    key={randomKey}
                    className={classes.sorter}
                    text={i18n._(t`Score Smatch`)}
                    onChange={direction => {
                      this.sorterChangeHandler("score", direction);
                    }}
                    direction={
                      sortByScore === "score_DESC"
                        ? "down"
                        : sortByScore === "score_ASC"
                        ? "up"
                        : "none"
                    }
                    stateNumber={3}
                  />
                </Col>
                <Col>
                  <Sorter
                    key={randomKey}
                    className={classes.sorter}
                    text={i18n._(t`Distance`)}
                    onChange={direction =>
                      this.sorterChangeHandler("distance", direction)
                    }
                    direction={
                      sortByDistance === "distance_DESC"
                        ? "down"
                        : sortByDistance === "distance_ASC"
                        ? "up"
                        : "none"
                    }
                    stateNumber={3}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>
          <Collapse title={i18n._(t`Type de contract`)}>
            <div className={classes.widgetBody}>
              <EmploymentPreferenceWidget key={randomKey} />
            </div>
          </Collapse>
          <Collapse title={i18n._(t`Distance`)}>
            <div className={classes.widgetBody}>
              <LocationWidget key={randomKey} />
            </div>
          </Collapse>
          <Collapse title={i18n._(t`Compétence`)}>
            <div className={classes.widgetBody}>
              <Query query={GET_SKILLS}>
                {({ loading, error, data }) => {
                  if (loading) return <InputSkeleton />;
                  if (error) return `${i18n._(t`Erreur`)} !`;

                  const { skills } = data;
                  return (
                    <SkillsWidget
                      key={randomKey}
                      options={skills.map(item => item.name)}
                    />
                  );
                }}
              </Query>
            </div>
          </Collapse>
          <Collapse title={i18n._(t`Niveau d'expérience`)}>
            <div className={classes.widgetBody}>
              <ExperienceWidget key={randomKey} />
            </div>
          </Collapse>
          <Row>
            <Col xs={3} sm={6} md={6} lg={6} xl={6} noGutter>
              <div className={classes.applique}>
                <Button
                  variant="primary"
                  onClick={this.appliqueFilters}
                  size="small"
                  className={classes.buttonStyling}
                >
                  <Trans>Appliquer</Trans>
                </Button>
              </div>
            </Col>
            <Col xs={3} sm={6} md={6} lg={6} xl={6} noGutter>
              <div className={classes.clearAll}>
                <Button
                  variant="secondary"
                  onClick={this.clearFilters}
                  size="small"
                  className={classes.buttonStyling}
                >
                  <Trans>Réinitialiser</Trans>
                </Button>
              </div>
            </Col>
          </Row>
        </aside>
      </Col>
    );
  }
}

SideFilters.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    isSideFilterOpen: PropTypes.bool,
    clearFilters: PropTypes.func,
    changeFilterAppliqued: PropTypes.func,
    changeSortByDistance: PropTypes.func,
    changeSortByScore: PropTypes.func
  }).isRequired
};

export default withI18n()(SideFilters);
