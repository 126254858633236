import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { Button } from "cf-neo-ui";
import { Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { inject, observer } from "mobx-react";
import { generateKey } from "../../../utils/helpers";
import classes from "./footer.module.scss";
import Routes from "../routes";

@inject("sessionStore", "modalStore", "appStore")
@observer
class LinkWidget extends Component {
  verifySiteLanguage = url => {
    const { appStore } = this.props;
    const { currentLanguage } = appStore;
    if (url) {
      const res = url.split(",");
      if (currentLanguage === "fr") return res[0];
      return res[1];
    }
    return "#";
  };

  conditionalRedirect = () => {
    const { modalStore, sessionStore, history } = this.props;
    const { account } = sessionStore;
    if (account) {
      if (account.role === "CANDIDATE") {
        history.push(Routes.HbLayoutRoutes.UserRoleNotification.path);
      }
      if (account.role === "CLIENT") {
        modalStore.changeIsVisible(true);
        return "#";
      }
    } else {
      history.push(Routes.HbLayoutRoutes.Login.path);
    }
    return null;
  };

  getLinks = linksArray =>
    linksArray.map(item => {
      if (item.external)
        return (
          <a
            key={generateKey()}
            href={this.verifySiteLanguage(item.url)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.name}
          </a>
        );
      if (item.url && item.url === "conditionalRedirect")
        return <span onClick={this.conditionalRedirect}>{item.name}</span>;
      return (
        <NavLink
          key={generateKey()}
          to={item.url ? item.url : "#"}
          title={item.title}
        >
          {item.name}
        </NavLink>
      );
    });

  render() {
    const { title, links, OptionText, btnText, btnLink } = this.props;
    return (
      <div className={classes.widget}>
        <h5 className={classes.title}>{title}</h5>
        <div className={classes.linksWidget}>
          <div>{this.getLinks(links)}</div>
          <div>
            <p className="margTop0">{OptionText}</p>
            {btnText && (
              <Button
                className="btn_cf_footer"
                variant="secondary"
                size="small"
                onClick={() => window.open(btnLink)}
              >
                <Trans>{btnText}</Trans>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

LinkWidget.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      name: PropTypes.string.isRequired
    })
  ),
  OptionText: PropTypes.string,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape,
    authToken: PropTypes.string,
    signUpUserRole: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  modalStore: PropTypes.shape({
    isVisible: PropTypes.bool,
    changeIsVisible: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

LinkWidget.defaultProps = {
  title: "",
  links: [],
  OptionText: "",
  btnText: "",
  btnLink: ""
};

export default withI18n()(withRouter(LinkWidget));
