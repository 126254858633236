import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { TimesheetStatus } from "cf-neo-ui";
import { inject, observer } from "mobx-react";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import classes from "./DisplayStatusCards.module.scss";
import colors from "../../../../../utils/timesheetStatusColors";
import runtimeVars from "../../../../../configs/runTimeVars";
import monthName from "../../../../../utils/monthName";
import routes from "../../../../layout/routes";

@inject("appStore", "sessionStore", "timesheetStore")
@observer
class DisplayStatusCards extends Component {
  navigate = card => {
    const { history, timesheetStore } = this.props;
    timesheetStore.changeTimesheet(card);
    history.push(
      routes.LpbLayoutRoutes.TimesheetsSinglePage.path.replace(":id", card.id)
    );
  };

  getStatusInTheRightLanguage = status => {
    const { i18n } = this.props;
    switch (status) {
      case "open":
        return i18n._(t`ouvert`);
      case "pending":
        return i18n._(t`en attente`);
      case "approved":
        return i18n._(t`validé`);
      case "rejected":
        return i18n._(t`rejeté`);
      default:
        return "";
    }
  };

  getInfo = (card, info) => {
    const { sessionStore } = this.props;
    const { account } = sessionStore;
    if (account.role === "CLIENT") {
      if (info === "name")
        return card.candidate ? `${card.candidate.name}` : "";
      if (info === "location")
        return card.candidate ? `${card.candidate.city}` : "";
      if (info === "email")
        return card.candidate ? `${card.candidate.email}` : "";
    }
    if (account.role === "CANDIDATE") {
      if (info === "name")
        return card.approvingClient ? `${card.approvingClient.name}` : "";
      if (info === "location")
        return card.client ? `${card.client.companyName}` : "";
      if (info === "email")
        return card.approvingClient ? `${card.approvingClient.email}` : "";
    }
    return "";
  };

  render() {
    const locale = runtimeVars.APP_LANG;
    const { cards } = this.props;
    if (!cards) return null;
    return (
      <div className={classes.list}>
        {cards.map(card => (
          <TimesheetStatus
            key={card.id}
            timesheet={{
              id: card.placementID,
              title: card.title,
              date: `${monthName(card.month, locale)} ${card.year}`,
              location: this.getInfo(card, "location") || "",
              name: this.getInfo(card, "name") || "",
              email: this.getInfo(card, "email") || ""
            }}
            className={classes.item}
            onClick={() => this.navigate(card)}
            href={routes.LpbLayoutRoutes.TimesheetsSinglePage.path.replace(
              ":id",
              card.id
            )}
            status={{
              text: this.getStatusInTheRightLanguage(card.status),
              color: colors[card.status]
            }}
          />
        ))}
      </div>
    );
  }
}

DisplayStatusCards.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      date: PropTypes.string,
      location: PropTypes.string,
      info: PropTypes.shape({
        city: PropTypes.string,
        email: PropTypes.string
      }),
      status: PropTypes.string
    })
  ),
  timesheetStore: PropTypes.shape({
    changeTimesheet: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired
};

DisplayStatusCards.defaultProps = {
  cards: null
};

export default withI18n()(withRouter(DisplayStatusCards));
