import gql from "graphql-tag";

export const USER_FAVED_MISSIONS_QUERY = gql`
  query UserFavedMissions {
    userFavedMissions {
      id
    }
  }
`;

export const GET_CATEGORIES = gql`
  {
    categories {
      name
    }
  }
`;

export const GET_BUSINESS_SECTOR = gql`
  {
    businessSector {
      name
    }
  }
`;
export const GET_SKILLS = gql`
  {
    skills {
      name
    }
  }
`;

export const GET_BUSINESS_SECTORS = gql`
  {
    businessSectors {
      name
    }
  }
`;

export const GET_SPECIALTIES = gql`
  {
    specialties {
      name
    }
  }
`;

export const GET_KEY_WORDS = gql`
  query keyWords($description: String) {
    keyWords(description: $description)
  }
`;

export const ADD_NEW_KEY_WORDS = gql`
  query AddNewKeyWords($description: String) {
    addNewKeyWords(description: $description) {
      newWords
      keyWords
    }
  }
`;

export const GET_S_CANDIDATES = gql`
  query SCandidates(
    $primarySkills: [String]
    $secondarySkills: [String]
    $zipCode: String
    $limit: Int
    $skip: Int
    $sortByScore: SCandidatesSortByScoreInput
    $sortByDistance: SCandidatesSortByDistanceInput
    $filter: SCandidatesFilterInput
    $user: UserSMATCH
    $description: String
  ) {
    sCandidates(
      primarySkills: $primarySkills
      secondarySkills: $secondarySkills
      zipCode: $zipCode
      limit: $limit
      skip: $skip
      sortByDistance: $sortByDistance
      sortByScore: $sortByScore
      filter: $filter
      user: $user
      description: $description
    ) {
      candidate_id
      distance
      value
      countryCode
      employmentPreference
      occupation
      experience
      skills
      required_skills
      zipCode
      total
    }
  }
`;
