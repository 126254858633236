import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { inject, observer } from "mobx-react";
import PadlockIcon from "./icons/PadlockIcon";
import classes from "./Timesheets.module.scss";

@inject("sessionStore")
@observer
class OpaqueScreen extends Component {
  render() {
    const { sessionStore } = this.props;
    return (
      <div>
        <div className={classes.lockedUp} />
        <div className={classes.content}>
          <PadlockIcon />
          <p className={classes.message}>
            {sessionStore.account.role === "CANDIDATE" ? (
              <Trans>
                Pour débloquer les fonctionnalités de timesheet, vous devez
                démarrer une mission via nos services.
              </Trans>
            ) : (
              <Trans>
                Pour activer les fonctionnalités de timesheet, vous devez
                contractualiser avec nos services.
              </Trans>
            )}
          </p>
        </div>
      </div>
    );
  }
}

OpaqueScreen.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    account: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired
};

export default withI18n()(OpaqueScreen);
