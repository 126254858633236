import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  Divider,
  CheckboxGroup,
  TextInput,
  FormGroup,
  Button,
  Validation,
  Checkbox,
  PasswordInput,
  toaster,
  Spinner,
  ImportPhoto
} from "cf-neo-ui";

import validate from "../../../utils/validators";
import { scorePassword } from "../../../utils/helpers";
import { GET_USER_INFO } from "../../../components/hoc/queries";
import "./styles.scss";

const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClientMutation(
    $_id: ID!
    $password: String
    $firstName: String
    $lastName: String
    $phonenumber: String
    $preferredContact: String
    $receiveNewsletter: Boolean
    $lastInfo: Boolean
    $currentPosition: String
    $companyName: String
    $activityArea: [String]
    $sizeOfTheCompany: Int
    $isESN: Boolean
    $profilePhoto: Upload
    $pictureUrl: String
  ) {
    updateClient(
      input: {
        _id: $_id
        password: $password
        firstName: $firstName
        lastName: $lastName
        phonenumber: $phonenumber
        preferredContact: $preferredContact
        receiveNewsletter: $receiveNewsletter
        currentPosition: $currentPosition
        companyName: $companyName
        lastInfo: $lastInfo
        activityArea: $activityArea
        sizeOfTheCompany: $sizeOfTheCompany
        isESN: $isESN
        profilePhoto: $profilePhoto
        pictureUrl: $pictureUrl
      }
    ) {
      account {
        id
        name
      }
      client {
        _id
        id
        email
        companyName
        activityArea
        currentPosition
        sizeOfTheCompany
        profilePhoto
        isESN
        password
        firstName
        lastName
        phonenumber
        customTextBlock1
        preferredContact
        receiveNewsletter
        lastInfo
      }
    }
  }
`;

@inject("sessionStore", "appStore", "candidatesSearchStore")
@observer
class MyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: "",
      isfirstNameValid: true,
      islastNameValid: true,
      isCurrentPositionValid: true,
      isPhonenumberValid: true,
      isEmailValid: true,
      isPasswordValid: true,
      firstNameValidationMessage: "",
      lastNameValidationMessage: "",
      emailValidationMessage: "",
      currentPositionValidationMessage: "",
      phonenumberValidationMessage: "",
      passwordValidationMessage: "",
      isConfirmPasswordValid: true,
      confirmPasswordValidationMessage: "",
      saveCase: false
    };
    this.isFormValid = this.isFormValid.bind(this);
  }

  handleEvaluator = v => {
    return scorePassword(v);
  };

  isFormValid = () => {
    const { sessionStore, i18n } = this.props;
    const {
      firstName,
      lastName,
      currentPosition,
      phonenumber,
      password
    } = sessionStore;
    const { confirmPassword } = this.state;
    let valid = true;
    if (!this.validatefirstName(firstName)) valid = false;
    if (!this.validatelastName(lastName)) valid = false;
    if (!this.validateCurrentPosition(currentPosition)) valid = false;
    if (!this.validatePhonenumber(phonenumber)) valid = false;
    if (!this.validatePassword(password)) valid = false;
    if (!this.validateConfirmPassword(confirmPassword)) valid = false;
    if (confirmPassword !== password) {
      valid = false;
      this.setState({
        isConfirmPasswordValid: false,
        confirmPasswordValidationMessage: i18n._(
          t`Ce champs ne contient pas la même valeur`
        )
      });
    }
    return valid;
  };
  lastInfoChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeLastInfo(!sessionStore.lastInfo);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };
  onErrorHandler = msg => {
    const { i18n } = this.props;
    toaster.error({
      title: i18n._(t`Erreur`),
      description: msg
    });
  };

  validatefirstName = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isfirstNameValid: res.isValid,
      firstNameValidationMessage: res.message
    });
    return res.isValid;
  };

  validatelastName = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      islastNameValid: res.isValid,
      lastNameValidationMessage: res.message
    });
    return res.isValid;
  };

  validateCurrentPosition = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "name", { maxlength: 100 }, { minlength: 2 }],
      i18n
    );
    this.setState({
      isCurrentPositionValid: res.isValid,
      currentPositionValidationMessage: res.message
    });
    return res.isValid;
  };

  validatePhonenumber = value => {
    const { i18n } = this.props;
    const res = validate(
      value,
      ["required", "number", { maxlength: 13 }, { minlength: 8 }],
      i18n
    );
    this.setState({
      isPhonenumberValid: res.isValid,
      phonenumberValidationMessage: res.message
    });
    return res.isValid;
  };

  receiveNewsletterChangeHandler = () => {
    const { sessionStore } = this.props;
    sessionStore.changeReceiveNewsletter(!sessionStore.receiveNewsletter);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  preferredContactChangeHandler = v => {
    if (Array.isArray(v)) {
      const { sessionStore } = this.props;
      sessionStore.changePreferredContact(v.toString());
    }
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  firstNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changefirstName(e.target.value);
    this.validatefirstName(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  lastNameChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changelastName(e.target.value);
    this.validatelastName(e.target.value);

    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  emailChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeEmail(String(e.target.value).toLowerCase());
    this.validateEmail(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  currentPositionChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changeCurrentPosition(e.target.value);
    this.validateCurrentPosition(e.target.value);

    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  phonenumberChangeHandler = e => {
    const { sessionStore } = this.props;
    sessionStore.changePhonenumber(e.target.value);
    this.validatePhonenumber(e.target.value);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  passwordChangeHandler = v => {
    const { sessionStore } = this.props;
    sessionStore.changePassword(v);
    this.validatePassword(v);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  confirmPasswordChangeHandler = v => {
    this.setState({ confirmPassword: v });
    this.validateConfirmPassword(v);
  };

  handleUploadProfilePhoto = fileUploaded => {
    const { sessionStore } = this.props;
    sessionStore.changeProfilePhoto(fileUploaded);
    const { saveCase } = this.state;
    if (!saveCase) this.setState({ saveCase: true });
  };

  validatePassword(value) {
    const { i18n } = this.props;
    const { confirmPassword } = this.state;
    if (!value && confirmPassword !== "") {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Champ requis`)
      });
      return false;
    }

    if (!value && confirmPassword === "") {
      this.setState({
        isPasswordValid: true,
        passwordValidationMessage: ""
      });
      return true;
    }

    if (value.length < 8) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins 8 caractères`
        )
      });
      return false;
    }

    if (!/[a-z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une minuscule`
        )
      });
      return false;
    }
    if (!/[A-Z]/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins une majuscule`
        )
      });
      return false;
    }
    if (!/\d/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(t`Doit contenir au moins un chiffre`)
      });
      return false;
    }
    if (!/\W/.test(value)) {
      this.setState({
        isPasswordValid: false,
        passwordValidationMessage: i18n._(
          t`Doit contenir au moins un caractère spécial !*#@...`
        )
      });
      return false;
    }
    this.setState({
      isPasswordValid: true,
      passwordValidationMessage: ""
    });
    return true;
  }

  validateEmail(value) {
    const { i18n } = this.props;
    const res = validate(value, ["required", "email"], i18n);
    this.setState({
      isEmailValid: res.isValid,
      emailValidationMessage: res.message
    });
    return res.isValid;
  }

  validateConfirmPassword(value) {
    const { i18n, sessionStore } = this.props;
    const res = validate(value, [{ similarTo: sessionStore.password }], i18n);
    this.setState({
      isConfirmPasswordValid: res.isValid,
      confirmPasswordValidationMessage: res.message
    });
    return res.isValid;
  }

  updateClientCompletedHandler(data) {
    const { i18n, sessionStore, appStore } = this.props;
    toaster.success({
      title: i18n._(t`Mes informations`),
      description: i18n._(t`Enregistrement effectué avec succès`)
    });
    this.setState({ saveCase: false });
    if (data.updateClient) {
      sessionStore.changePictureUrl(data.updateClient.client.profilePhoto);
    }
    this.setState({ saveCase: false });
    appStore.refreshLayout();
  }

  render() {
    const { i18n, sessionStore, candidatesSearchStore } = this.props;
    const {
      _id,
      firstName,
      lastName,
      phonenumber,
      password,
      email,
      preferredContact,
      receiveNewsletter,
      lastInfo,
      authToken,
      companyName,
      activityArea,
      sizeOfTheCompany,
      profilePhoto,
      pictureUrl,
      isESN,
      currentPosition,
      logout
    } = sessionStore;
    const { clearSearch } = candidatesSearchStore;
    const {
      confirmPassword,
      isfirstNameValid,
      islastNameValid,
      isEmailValid,
      isCurrentPositionValid,
      isPhonenumberValid,
      isPasswordValid,
      isConfirmPasswordValid,
      firstNameValidationMessage,
      lastNameValidationMessage,
      emailValidationMessage,
      currentPositionValidationMessage,
      phonenumberValidationMessage,
      passwordValidationMessage,
      confirmPasswordValidationMessage,
      saveCase
    } = this.state;
    const preferredContactArray = preferredContact
      ? preferredContact.split(",")
      : [];

    return (
      <div className="profile my-information">
        <div className="formCard no-gutter with-padding">
          <Row className="photo-row">
            <Col lg={2} xl={2}>
              <FormGroup>
                <ImportPhoto
                  className="profile-photo"
                  dimension="114px"
                  src={pictureUrl || profilePhoto || "/defaultAvatar.png"}
                  errorMessageDescription={i18n._(
                    t`Format d’image invalide. Formats recommandés : .jpeg .png`
                  )}
                  errorMessageTitle={`${i18n._(t`Erreur`)} !`}
                  onChange={(e, fileUploaded) =>
                    this.handleUploadProfilePhoto(e, fileUploaded)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg={4} xl={4} md={4} sm={4} xs={6}>
              <Row noGutter>
                <Col lg={12} xl={12}>
                  <FormGroup>
                    <label className="form-label" htmlFor="user_first_name">
                      <Trans>Prénom</Trans>
                    </label>
                    <Validation
                      errorMessage={firstNameValidationMessage}
                      valid={isfirstNameValid}
                    >
                      <TextInput
                        id="user_first_name"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Prénom`)}
                        value={firstName}
                        onChange={this.firstNameChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label" htmlFor="user_name">
                      <Trans>Nom</Trans>
                    </label>
                    <Validation
                      errorMessage={lastNameValidationMessage}
                      valid={islastNameValid}
                    >
                      <TextInput
                        id="user_name"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Nom de famille`)}
                        value={lastName}
                        onChange={this.lastNameChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={6} xl={6}>
              <Row noGutter>
                <Col lg={12} xl={12}>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="user_currentPosition"
                    >
                      <Trans>Poste actuel</Trans>
                    </label>
                    <Validation
                      errorMessage={currentPositionValidationMessage}
                      valid={isCurrentPositionValid}
                    >
                      <TextInput
                        id="user_currentPosition"
                        type="text"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`Poste actuel`)}
                        value={currentPosition}
                        onChange={this.currentPositionChangeHandler}
                      />
                    </Validation>
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label" htmlFor="user_email">
                      <Trans>Email</Trans>
                    </label>
                    <Validation
                      errorMessage={emailValidationMessage}
                      valid={isEmailValid}
                    >
                      <TextInput
                        id="user_email"
                        type="email"
                        spellCheck="false"
                        className="form-input"
                        placeholder={i18n._(t`monemail@mail.com`)}
                        value={email}
                        disabled
                      />
                    </Validation>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={6} md={6} sm={6} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="user_phone">
                  <Trans>Téléphone</Trans>
                </label>
                <Validation
                  errorMessage={phonenumberValidationMessage}
                  valid={isPhonenumberValid}
                >
                  <TextInput
                    id="user_phone"
                    type="tel"
                    className="form-input"
                    placeholder="06XXXXXXXX"
                    value={phonenumber}
                    onChange={this.phonenumberChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
            <Col lg={6} xl={6} md={6} sm={6} xs={6}>
              <FormGroup>
                <label className="form-label" htmlFor="user_password">
                  <Trans>Créer un nouveau mot de passe</Trans>
                </label>
                <Validation
                  errorMessage={passwordValidationMessage}
                  valid={isPasswordValid}
                >
                  <PasswordInput
                    id="user_password"
                    autocomplete="new-password"
                    className="form-input"
                    message={i18n._(t`Niveau de sécurité`)}
                    placeholder={i18n._(t`Entrer un mot de passe`)}
                    passwordEvaluator={this.handleEvaluator}
                    value={password}
                    onChange={this.passwordChangeHandler}
                  />
                </Validation>
              </FormGroup>
              <FormGroup>
                <label className="form-label" htmlFor="user_confirm_pass">
                  <Trans>Confirmez le nouveau mot de passe</Trans>
                </label>
                <Validation
                  errorMessage={confirmPasswordValidationMessage}
                  valid={isConfirmPasswordValid}
                >
                  <PasswordInput
                    id="user_confirm_pass"
                    className="form-input"
                    message={i18n._(t`Niveau de sécurité`)}
                    placeholder={i18n._(t`Entrer un mot de passe`)}
                    value={confirmPassword}
                    onChange={this.confirmPasswordChangeHandler}
                  />
                </Validation>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <span
                className="contact-text"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Trans>Je préfère être contacté par</Trans>:
              </span>
              <CheckboxGroup
                style={{ marginTop: "10px", marginBottom: "10px" }}
                name="CheckboxGrp"
                defaultSelected={preferredContactArray}
                onChange={this.preferredContactChangeHandler}
                orientation="horizontal"
              >
                <Checkbox name="email" label={i18n._(t`Email`)} value="email" />
                <Checkbox
                  name="phone"
                  label={i18n._(t`Téléphone`)}
                  value="phone"
                />
                <Checkbox name="sms" label={i18n._(t`SMS`)} value="sms" />
              </CheckboxGroup>
            </Col>
          </Row>

          <Divider />
          <Row className="accept_terms">
            <Col>
              <Checkbox
                name="receive_newsletter"
                value="receive_newsletter"
                checked={receiveNewsletter}
                label={i18n._(
                  t`Recevoir notre newsletter bimensuelle spéciale Tech & IT (conseils pratiques, dossiers spéciaux, sélection de missions, etc.).`
                )}
                onClickButton={this.receiveNewsletterChangeHandler}
                changeableFontSize
              />
            </Col>
          </Row>
          <Row className="accept_terms">
            <Col>
              <Checkbox
                name="receive_newsletter"
                value="receive_newsletter"
                checked={lastInfo}
                label={i18n._(
                  t`Je souhaite recevoir les actualités, les nouveaux produits et services de Club Freelance`
                )}
                onClickButton={this.lastInfoChangeHandler}
              />
            </Col>
          </Row>
          <div className="next-buttons">
            <Mutation
              mutation={UPDATE_CLIENT_MUTATION}
              variables={{
                _id,
                firstName,
                lastName,
                phonenumber,
                password,
                preferredContact,
                receiveNewsletter,
                companyName,
                activityArea,
                sizeOfTheCompany,
                isESN,
                currentPosition,
                pictureUrl,
                lastInfo,
                ...(typeof profilePhoto === "object" && {
                  profilePhoto
                })
              }}
              refetchQueries={[
                {
                  query: GET_USER_INFO,
                  variables: { token: authToken }
                }
              ]}
              onCompleted={data => this.updateClientCompletedHandler(data)}
              onError={errors => {
                errors.graphQLErrors.forEach(({ message, data }) => {
                  if (message === "UNAUTHENTICATED") {
                    logout();
                    clearSearch();
                  }
                  if (data && data.isCustomError) {
                    this.onErrorHandler(message);
                  }
                });
              }}
            >
              {(mutation, { loading }) => (
                <Button
                  disabled={loading || !saveCase}
                  onClick={() => {
                    if (this.isFormValid()) return mutation();
                    return null;
                  }}
                >
                  {loading ? (
                    <Spinner
                      type="pointed-circular"
                      color="#FFFFFF"
                      size={12}
                    />
                  ) : (
                    <Trans>Enregistrer</Trans>
                  )}
                </Button>
              )}
            </Mutation>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

MyInformation.wrappedComponent.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired,
  appStore: PropTypes.shape({
    refreshLayout: PropTypes.func
  }).isRequired,
  candidatesSearchStore: PropTypes.shape({
    clearSearch: PropTypes.func
  }).isRequired,
  sessionStore: PropTypes.shape({
    changeReceiveNewsletter: PropTypes.func,
    changeLastInfo: PropTypes.func,
    changePreferredContact: PropTypes.func,
    changefirstName: PropTypes.func,
    changelastName: PropTypes.func,
    changeEmail: PropTypes.func,
    changePhonenumber: PropTypes.func,
    changePassword: PropTypes.func,
    changePictureUrl: PropTypes.func,
    changeProfilePhoto: PropTypes.func,
    changeCurrentPosition: PropTypes.func,
    receiveNewsletter: PropTypes.bool,
    lastInfo: PropTypes.bool,
    preferredContact: PropTypes.shape,
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    client: PropTypes.shape,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phonenumber: PropTypes.string,
    password: PropTypes.string,
    email: PropTypes.string,
    companyName: PropTypes.string,
    activityArea: PropTypes.arrayOf(PropTypes.string),
    sizeOfTheCompany: PropTypes.number,
    isESN: PropTypes.bool,
    currentPosition: PropTypes.string,
    authToken: PropTypes.string,
    profilePhoto: PropTypes.string,
    pictureUrl: PropTypes.string,
    logout: PropTypes.func
  }).isRequired
};
export default withI18n()(MyInformation);
