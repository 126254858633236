import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import gql from "graphql-tag";
import { toaster } from "cf-neo-ui";
import Routes from "../layout/routes/index";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
const RESEND_VALIDATION_EMAIL_MUTATION = gql`
  mutation ResendValidationEmail($email: String!) {
    resendValidationEmail(email: $email) {
      token
    }
  }
`;

class ResendValidationEmail extends Component {
  clickHandler = () => {
    const { apolloClient, history, email, i18n } = this.props;

    // window.history.pushState(Routes.Hb2LayoutRoutes.ConfirmCode.path);
    // window.history.push(Routes.Hb2LayoutRoutes.ConfirmCode.path);
    try {
      //  history.push(Routes.Hb2LayoutRoutes.ConfirmCode.path);
      apolloClient.mutate({
        mutation: RESEND_VALIDATION_EMAIL_MUTATION,
        variables: { email }
      });

      toaster.closeAll();
    } catch (e) {
      toaster.error({
        title: i18n._(t`Erreur de création d’alerte`),
        description: i18n._(
          t`Un problème est survenu lors de la création d’alerte`
        )
      });
    }
  };

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events

      <p
        style={{
          textDecoration: "underline",
          color: "blue",
          cursor: "pointer",
          margin: 0
        }}
        onClick={() => {
          this.clickHandler();
        }}
      >
        <Trans>Renvoyer l’email de validation</Trans>
      </p>
    );
  }
}

ResendValidationEmail.propTypes = {
  email: PropTypes.string.isRequired,
  apolloClient: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(ResendValidationEmail);
