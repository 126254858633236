import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const CREATE_OPPORTUNITY = gql`
  mutation CreateOpportunity($input: CreateOpportunityInput!) {
    createOpportunity(input: $input) {
      _id
      certifications
      workRate
      purchaseOrder
    }
  }
`;

export const CREATE_OPPORTUNITY_FROM_FILE = gql`
  mutation CreateOpportunityFromFile($title: String!, $file: Upload!) {
    createOpportunityFromFile(title: $title, file: $file) {
      _id
    }
  }
`;

export const UPDATE_OPPORTUNITY_FROM_FORM = gql`
  mutation UpdateOpportunity($input: UpdateOpportunityInput!) {
    updateOpportunity(input: $input) {
      _id
      title
      purchaseOrder
    }
  }
`;
