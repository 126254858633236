import { observable, action, computed } from "mobx";

export default class OffersSearchStore {
  @observable limit;

  @observable search;

  @observable page;

  constructor(initialState = {}) {
    this.search = initialState.search || "";
    this.limit = initialState.limit || 1000;
    this.page = initialState.page || 1;
  }

  @computed
  get skip() {
    return (this.page - 1) * this.limit;
  }

  @action
  changePage = page => {
    this.page = page;
  };

  @action
  searchSubmit = value => {
    if (value === "") {
      this.search = "";
      this.page = 1;
    } else {
      this.search = value;
      this.page = 1;
    }
  };
}
