import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react/index";
import { Button, toaster, Icon } from "cf-neo-ui";
import { withI18n } from "@lingui/react";
import { Trans, Plural, t } from "@lingui/macro";
import * as queryString from "query-string";
import Routes from "../../../../layout/routes/index";
import classes from "../SideFilters.module.scss";

@inject("missionsSearchStore", "sessionStore")
@observer
class AlertWidget extends Component {
  constructor(props) {
    super(props);
    const queryObj = queryString.parse(props.location.search);
    this.state = {
      isEdit: queryObj.action === "edit"
    };
  }

  createAlertHandler = () => {
    const { missionsSearchStore, sessionStore, history, i18n } = this.props;
    const { canOpenAlertModal, setAlertModal } = missionsSearchStore;
    const { authToken, setBackRef } = sessionStore;

    if (authToken) {
      if (canOpenAlertModal) {
        setAlertModal();
      } else {
        toaster.warning({
          title: i18n._(t`Filtres Vide`),
          description: i18n._(
            t`Veuillez selectionner au moin un filtre pour créer une alerte`
          )
        });
      }
    } else {
      setBackRef("missions");
      history.push(Routes.HbLayoutRoutes.Login.path);
    }
  };

  render() {
    const { missionsSearchStore, sessionStore } = this.props;
    const { account } = sessionStore;
    const { isEdit } = this.state;
    let isDisabled;
    if (account) {
      if (account.role === "CLIENT") isDisabled = true;
    } else isDisabled = false;
    return (
      <div className={classes.alertWidget}>
        <div style={{ float: "left", width: "100%" }}>
          <div className={classes.respTitle}>
            <Trans>Critères</Trans>
          </div>
          <div className={classes.closeBtn}>
            <Icon
              type="close"
              width={12}
              height={11}
              onClick={missionsSearchStore.setSideFilter}
            />
          </div>
        </div>
        <Button
          icon="bell"
          onClick={this.createAlertHandler}
          id="cta_mi_fi_alert"
          disabled={isDisabled}
        >
          {isEdit ? (
            <Trans>Modifier mon alerte avec ces critères</Trans>
          ) : (
            <Trans>Créer une alerte sur ces critères</Trans>
          )}
        </Button>
        {missionsSearchStore.printResultsCount && (
          <p className="center">
            <span style={{ color: "#C40B24" }}>
              {`${missionsSearchStore.resultsCount} `}
            </span>
            <Plural
              value={missionsSearchStore.resultsCount}
              one="Resultat"
              other="resultats"
            />
          </p>
        )}
      </div>
    );
  }
}

AlertWidget.wrappedComponent.propTypes = {
  missionsSearchStore: PropTypes.shape({
    printResultsCount: PropTypes.bool,
    canOpenAlertModal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    setAlertModal: PropTypes.func,
    setSideFilter: PropTypes.func,
    resultsCount: PropTypes.number
  }).isRequired,
  sessionStore: PropTypes.shape({
    authToken: PropTypes.string,
    account: PropTypes.object,
    setBackRef: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(withRouter(AlertWidget));
